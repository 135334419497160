import { cancellablePromise } from "./cancellable-promise";

const noop = () => {};

const delay = n => new Promise(resolve => setTimeout(resolve, n));

export {
  cancellablePromise,
  noop,
  delay
};