import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VideoEffectsItem from './VideoEffectsItem';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BlockIcon from '@material-ui/icons/Block';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "20px 20px"
  },
  iconSize: {
    fontSize: "60px"
  },
  title: {
    fontSize: "14px"
  }
}));

export const VideoEffectsContainer = ({
  isBlurEnabled, 
  stopCameraBlur, 
  startCameraBlur,
  handleClose, 
  isImageEnabled, 
  startCameraImage, 
  stopCameraImage, 
  imageSelected, 
  filterBackgrounds, 
  withBlur, 
  withBackground
}) => {
  const classes = useStyles();
  const globalState = useSelector(state => state);

  const handleNone = async () => {
    if(isBlurEnabled) {
      await stopCameraBlur();
    } else if(isImageEnabled) {
      await stopCameraImage();
    }

    clearLastPersistedEffect();
    handleClose();
  }
  
  const handleBlur = async () => {
    if(isImageEnabled) {
      await stopCameraImage();
    }

    await startCameraBlur();
    persistLastVideoEffect("blur");
    handleClose();
  }
  
  const handleImage = async (url) => {
    if(isImageEnabled) {
      await stopCameraImage();
    } else if(isBlurEnabled) {
      await stopCameraBlur();
    }
  
    await startCameraImage(url);
    persistLastVideoEffect(url);
    handleClose();
  }

  const persistLastVideoEffect = (effect) => {
    const storageKey = `videoeffect-${globalState.auth.deploymentCode}-${globalState.auth.userid}`;
    localStorage.setItem(storageKey, effect);
  }

  const clearLastPersistedEffect = () => {
    const storageKey = `videoeffect-${globalState.auth.deploymentCode}-${globalState.auth.userid}`;
    localStorage.removeItem(storageKey);
  }

  return (
    <>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        <FormattedMessage id={"VIDEO.EFFECTS.BACKGROUNDS"}/>
      </Typography>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justifyContent="start"
      >
        <Grid item xs={12} sm={6} md={4} onClick={handleNone}>
          <VideoEffectsItem 
            title={<FormattedMessage id={"VIDEO.EFFECTS.NONE"}/>} 
            selected={(!isBlurEnabled && !isImageEnabled)} 
            icon={<BlockIcon className={classes.iconSize}/>}
          />
        </Grid>
        
        {
          withBlur &&
          <Grid item xs={12} sm={6} md={4} onClick={handleBlur}>
            <VideoEffectsItem 
              title={<FormattedMessage id={"VIDEO.EFFECTS.BLUR"}/>} 
              selected={isBlurEnabled} 
              icon={<BlurOnIcon 
              className={classes.iconSize}/>}
            />
          </Grid>
        }
        
        {
          (filterBackgrounds && filterBackgrounds.length > 0 && withBackground) &&
          filterBackgrounds?.map((url) => {
            return(
              <Grid item xs={12} sm={6} md={4} key={url} onClick={() => handleImage(url)}>
                <VideoEffectsItem background={url} selected={imageSelected === url}/>
              </Grid>
            )
          })
        }
        
        
      </Grid>
    </>
  )
}
