import axios from "axios";
import {configUrls} from '../../../globalConfig';

export const PRODUCT_GET_URL = `${configUrls.base_url}/api/product?`;
export const PRODUCT_ACTIVATE = `${configUrls.base_url}/api/product/action/activate/`;
export const PRODUCT_INACTIVATE = `${configUrls.base_url}/api/product/action/inactivate/`;
export const PRODUCT_APPROVE = `${configUrls.base_url}/api/product/action/approve/`;
export const PRODUCT_PENDING = `${configUrls.base_url}/api/product/action/pending/`;
export const PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan?`;
export const CREATE_PRODUCT_CONTAINER = `${configUrls.base_url}/api/product/create`; 
export const GET_PRODUCT_EDIT = `${configUrls.base_url}/api/product/`; 
export const SAVE_META_DATA_PRODUCT_URL = `${configUrls.base_url}/api/product/update/`; 

export function saveMetaDataProduct(payload){
  let plansIdsArr = null;
  if(payload.plans && payload.plans.length > 0){
    plansIdsArr = [];
    payload.plans.forEach((plan) => {
      if(typeof plan === 'object'){
        plansIdsArr.push(plan.code);
      }else{
        plansIdsArr.push(plan);
      }
    })
  }

  return axios.put(SAVE_META_DATA_PRODUCT_URL + payload.code, 
    {
      name: payload.name,
      folder:payload.folder,
      url:payload.productName,
      mimeType:payload.mimeType,
      size:payload.size,
      externalCode: payload.externalCode,
      validFrom: payload.validFrom,
      validTo: payload.validTo,
      order: payload.order,
      plans: plansIdsArr,
      allow_share: payload.allow_share
    }
  );
} 

export function uploadProduct(payload){
  let newAxiosInstance = axios.create();
  const options = {
    headers: {
      'x-ms-blob-type': payload.blobType,
      'Content-Type': payload.ContentType
    }
  };

  return newAxiosInstance.put(
    payload.url, 
    payload.body,
    options  
  );
} 

export function getProductEdit(codeProduct){
  return axios.get(GET_PRODUCT_EDIT + `${codeProduct}?edit=true`);
}

export function createProductContainer(payload){
  return axios.post(CREATE_PRODUCT_CONTAINER, {
    type: payload.type,
    name: payload.name
  });
}

export function getPromotionalPlan(state) {
  return axios.get(PROMOTIONAL_PLAN_URL + "state_id="+ state);
}

export function getProducts(state, promplan){
  return axios.get(PRODUCT_GET_URL + "state_id="+ state + "&prom_plan="+promplan);
}

export function activateProduct(code){
  return axios.put(PRODUCT_ACTIVATE + code);
} 

export function inactivateProduct(code){
  return axios.put(PRODUCT_INACTIVATE + code);
} 

export function approveProduct(code){
  return axios.put(PRODUCT_APPROVE + code);
} 

export function pendingProduct(code){
  return axios.put(PRODUCT_PENDING + code);
} 
