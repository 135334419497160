import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getPromotionalPlan,
  getProducts,
  activateProduct,
  inactivateProduct,
  approveProduct,
  pendingProduct,
  createProductContainer,
  getProductEdit,
  uploadProduct,
  saveMetaDataProduct
} from './products.crud';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';

export const actionTypes = {
  FULLFILL_PROMOTIONAL_PLANS: "FULLFILL_PROMOTIONAL_PLANS",
  FULLFILL_PRODUCTS: "FULLFILL_PRODUCT",
  FILL_PROMOTIONAL_PLAN: "FILL_PROMOTIONAL_PLAN",
  FILL_PRODUCTS: "FILL_PRODUCTS",
  ACTIVATE_PRODUCT: "ACTIVATE_PRODUCT",
  INACTIVATE_PRODUCT: "INACTIVATE_PRODUCT",
  ACTIVATE_PRODUCT_RESULT: "ACTIVATE_PRODUCT_RESULT",
  INACTIVATE_PRODUCT_RESULT: "INACTIVATE_PRODUCT_RESULT",
  APPROVE_PRODUCT: "APPROVE_PRODUCT",
  PENDING_PRODUCT: "PENDING_PRODUCT",
  APPROVE_PRODUCT_RESULT: "APPROVE_PRODUCT_RESULT",
  PENDING_PRODUCT_RESULT: "PENDING_PRODUCT_RESULT",
  CREATE_PRODUCT_CONTAINER: "CREATE_PRODUCT_CONTAINER",
  FILL_RESULT_PRODUCT_CONTAINER: "FILL_RESULT_PRODUCT_CONTAINER",
  GET_PRODUCT_EDIT: "GET_PRODUCT_EDIT",
  UPLOAD_PRODUCT: "UPLOAD_PRODUCT",
  SAVE_META_DATA_PRODUCT: "SAVE_META_DATA_PRODUCT",
  EDIT_PRODUCT_RESULT: "EDIT_PRODUCT_RESULT",
  SET_ERROR_VALIDATION: "SET_ERROR_VALIDATION"
};


const initialState = {
  promotionalPlans: [],
  products: [],
  loading: false,
  activateProductResult: null,
  inactivateProductResult: null,
  approveProductResult: null,
  pendingProductResult: null,
  productContainerResponse: {},
  editProductResult: null,
  errorValidation: null
}

export const reducer = persistReducer(
  { storage, key: "product", whitelist: [""] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FULLFILL_PROMOTIONAL_PLANS:
          return Object.assign({}, state,  { promotionalPlans: action.payload, loading: false } );
      case actionTypes.FULLFILL_PRODUCTS:
        return Object.assign({}, state,  { products: action.payload.list, loading: false });
      case actionTypes.FILL_PROMOTIONAL_PLAN:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.FILL_PRODUCTS:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.ACTIVATE_PRODUCT: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.INACTIVATE_PRODUCT: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.ACTIVATE_PRODUCT_RESULT: {
          return { 
            ...state,
            loading: false,
            activateProductResult: action.payload
          };
      }
      case actionTypes.INACTIVATE_PRODUCT_RESULT: {
          return { 
            ...state,
            loading: false,
            inactivateProductResult: action.payload
          };
      }
      case actionTypes.APPROVE_PRODUCT: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.PENDING_PRODUCT: {
          return { 
            ...state,
            loading: true,
            productContainerResponse: {code: action.payload.code}
          };
      }
      case actionTypes.APPROVE_PRODUCT_RESULT: {
          return { 
            ...state,
            loading: false,
            approveProductResult: action.payload
          };
      }
      case actionTypes.PENDING_PRODUCT_RESULT: {
          return { 
            ...state,
            loading: false,
            pendingProductResult: action.payload
          };
      }
      case actionTypes.CREATE_PRODUCT_CONTAINER: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.FILL_RESULT_PRODUCT_CONTAINER: {
          return { 
            ...state,
            loading: false,
            redirection: (action.payload.redirection) ? action.payload.redirection : false,
            productContainerResponse: action.payload
          };
      }
      case actionTypes.SAVE_META_DATA_PRODUCT: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.EDIT_PRODUCT_RESULT: {
          return { 
            ...state,
            loading: false,
            editProductResult: action.payload
          };
      }
      case actionTypes.SET_ERROR_VALIDATION:
        return { 
          ...state,  
          errorValidation: action.payload 
        };
      default:
        return state
    }
  }
);

export const actions = {
  fillPromotionalPlan: (value) => ({ type: actionTypes.FILL_PROMOTIONAL_PLAN, payload: value}),
  fulfillPromotionalPlan: (value) => ({ type: actionTypes.FULLFILL_PROMOTIONAL_PLANS, payload: value}),
  fulfillProducts: (value) => ({ type: actionTypes.FULLFILL_PRODUCTS, payload: value}),
  fillProducts: (value) => ({ type: actionTypes.FILL_PRODUCTS, payload: value}),
  activateProduct: (value) => ({ type: actionTypes.ACTIVATE_PRODUCT, payload: value }),
  inactivateProduct: (value) => ({ type: actionTypes.INACTIVATE_PRODUCT, payload: value }),
  setActivateProductResult: (value) => ({ type: actionTypes.ACTIVATE_PRODUCT_RESULT, payload: value }),
  setInactivateProductResult: (value) => ({ type: actionTypes.INACTIVATE_PRODUCT_RESULT, payload: value }),
  approveProduct: (value) => ({ type: actionTypes.APPROVE_PRODUCT, payload: value }),
  pendingProduct: (value) => ({ type: actionTypes.PENDING_PRODUCT, payload: value }),
  setApproveProductResult: (value) => ({ type: actionTypes.APPROVE_PRODUCT_RESULT, payload: value }),
  setPendingProductResult: (value) => ({ type: actionTypes.PENDING_PRODUCT_RESULT, payload: value }),
  createProductContainer: (value) => ({ type: actionTypes.CREATE_PRODUCT_CONTAINER, payload: value }),
  fillResultProductContainer: (value) => ({ type: actionTypes.FILL_RESULT_PRODUCT_CONTAINER, payload: value }),
  getEditProduct: (value) => ({ type: actionTypes.GET_PRODUCT_EDIT, payload: value }),
  uploadProduct: (value) => ({type: actionTypes.UPLOAD_PRODUCT, payload: value}),
  saveMetaDataProduct: (value) => ({type: actionTypes.SAVE_META_DATA_PRODUCT, payload: value}),
  setEditProductResult: (value) => ({type: actionTypes.EDIT_PRODUCT_RESULT, payload: value}),
  setErrorValidation: (value) => ({ type: actionTypes.SET_ERROR_VALIDATION, payload: value})
}

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.SAVE_META_DATA_PRODUCT, function* saveMetaDataProductSaga({payload}) {
    const selector = yield select(getState);
    try{
      let newObj = JSON.parse(JSON.stringify(selector.product.productContainerResponse)); // deep copy
      newObj.validFrom = moment(newObj.validFrom).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      newObj.validTo = moment(newObj.validTo).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      
      if(payload.edit && newObj.url){
        let urlStr = newObj.url.split('/');
        newObj.fileName = urlStr[urlStr.length - 1];
      }
      //console.log({ ...newObj, ...payload})
      const {data, status} = yield saveMetaDataProduct({ ...newObj, ...payload});
      yield put(actions.fillResultProductContainer({}));
      if(status < 300){
        yield put(actions.setEditProductResult(true));
      }else{
        yield put(actions.setEditProductResult(false));
      }
    }catch(e){
      console.log(e);
      yield put(actions.fillResultProductContainer({}));
      yield put(actions.setEditProductResult(false))
    }    
  });

  yield takeLatest(actionTypes.UPLOAD_PRODUCT, function* uploadProductSaga({payload}) {
    try{
      const {data, status} = yield uploadProduct(payload);
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.GET_PRODUCT_EDIT, function* getProductEditSaga({payload}) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getProductEdit(payload);
      data.validFrom = moment(data.validFrom).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      data.validTo = moment(data.validTo).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      
      if(status < 300 && data.code){
        yield put(actions.fillResultProductContainer(data));
      }else{
        yield put(actions.fillResultProductContainer({}));
      }
    }catch(e){
      yield put(actions.fillResultProductContainer({}));
    }    
  });

  yield takeLatest(actionTypes.CREATE_PRODUCT_CONTAINER, function* createProductContainerSaga({payload}) {
    try{
      const {data, status} = yield createProductContainer(payload);
      
      if(status < 300 && data.code){
        yield put(actions.fillResultProductContainer({...data, redirection: true}));
      }else{
        yield put(actions.fillResultProductContainer({redirection: false}));
      }
    }catch(e){
      yield put(actions.fillResultProductContainer({redirection: false}));
    }    
  });

  yield takeLatest(actionTypes.INACTIVATE_PRODUCT, function* inactivateProductSaga({payload}) {
    try{
      const {data, status} = yield inactivateProduct(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setInactivateProductResult(true));
      }else{
        yield put(actions.setInactivateProductResult(false));
      }
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setInactivateProductResult(false));
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.ACTIVATE_PRODUCT, function* activateProductSaga({payload}) {
    try{
      const {data, status} = yield activateProduct(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setActivateProductResult(true));
      }else{
        yield put(actions.setActivateProductResult(false));
      }
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setActivateProductResult(false));
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.APPROVE_PRODUCT, function* approveProductSaga({payload}) {
    try{
      const {data, status} = yield approveProduct(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setApproveProductResult(true));
      }else{
        yield put(actions.setApproveProductResult(false));
      }
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setAproveProductResult(false));
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.PENDING_PRODUCT, function* pendingProductSaga({payload}) {
    try{
      const {data, status} = yield pendingProduct(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setPendingProductResult(true));
      }else{
        yield put(actions.setPendingProductResult(false));
      }
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setPendingProductResult(false));
      yield put(actions.fillProducts({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.FILL_PROMOTIONAL_PLAN, function* fillPromotionalPlanSaga({ payload}) {
    const { data } = yield getPromotionalPlan(payload);
    yield put(actions.fulfillPromotionalPlan(data));
  });

  yield takeLatest(actionTypes.FILL_PRODUCTS, function* fillProducts({ payload}) {
    try{
      const { data } = yield getProducts(payload.stateId,payload.code);
      //console.log(data);
      let products = { list:[] };
      if(data && data.list && data.list.length > 0){
        data.list.forEach((productItem, index) => {
          switch (productItem.status) {
            case 1:
              productItem.statusName = payload.intl.formatMessage({ id: "PRODUCTS.STATUS.APPROVED" });
              break;
            case 2:
              productItem.statusName = payload.intl.formatMessage({ id: "PRODUCTS.STATUS.PENDING" });
              break;
            case 3:
              productItem.statusName = payload.intl.formatMessage({ id: "PRODUCTS.STATUS.DRAFT" });
              break;
            case 4:
              productItem.statusName = payload.intl.formatMessage({ id: "PRODUCTS.STATUS.TEMP" });
              break;
            default:
              productItem.statusName = ''
              break;
          }
          products.list.push(productItem);
        });
      }
      
      yield put(actions.fulfillProducts(products)); 
    }catch(e){
      console.log(e);
    }
  });
}
