import React, { useEffect, useState } from 'react'
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";


const SlideListControls = (props) => {
    const {color, playSpecificSlide, playFlow, intl} = props;
    const globalState = useSelector(state => state);
    const [indexOfSelectedFlow, setIndexOfSelectedFlow] = useState(0);
    const [indexOfSelectedSlide, setIndexOfSelectedSlide] = useState(0);
    const [totalFlows, setTotalFlows] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const [isFlowsControls, setIsFlowsControls] = useState(false);

    const getCurrentFlowSlides = () => globalState.presentationBar.presentationFlows.currentFlow.slides || [];
    const getContentSlides = () => globalState.presentationBar.presentationSlides.content || [];

    const hasCurrentFlow = () => Object.keys(globalState.presentationBar.presentationFlows.currentFlow).length > 0;

    const findSlideIndexByCode = (slides, code) => slides.findIndex(s => s.code === code);

    useEffect(() => {
        setIsFlowsControls(totalFlows > 0 && !hasCurrentFlow());     
    },[totalFlows, globalState.presentationBar.presentationFlows])

    useEffect(() => {
        setIndexOfSelectedFlow(globalState.presentationBar.presentationFlows.indexOfSelectedFlow)
    },[globalState.presentationBar.presentationFlows.indexOfSelectedFlow])

    useEffect(() => {
        setIndexOfSelectedSlide(globalState.presentationBar.presentationSlides.indexOfSelectedSlide)
    },[globalState.presentationBar.presentationSlides.indexOfSelectedSlide])

    useEffect(() => {
        if(globalState.presentationBar.presentationFlows.flows) {
            setTotalFlows(globalState.presentationBar.presentationFlows.flows.length);
        }
        
    },[globalState.presentationBar.presentationFlows.flows])

    useEffect(() => {
        if(hasCurrentFlow()) {
            setTotalSlides(globalState.presentationBar.presentationFlows.currentFlow.slides.length);
        } else {
            setTotalSlides(globalState.presentationBar.presentationSlides.content.length)
        }
        
    },[globalState.presentationBar.presentationFlows.currentFlow.slides, globalState.presentationBar.presentationSlides.content])

    const selectPrevFlow = function(){
        const flows = globalState.presentationBar.presentationFlows.flows;
        let currentFlowIndex = globalState.presentationBar.presentationFlows.indexOfSelectedFlow;

        if(currentFlowIndex - 1 < 0) {
            currentFlowIndex = flows.length - 1;
        }
        else {
            currentFlowIndex--;
        }

        props.updateSelectedIndexFlow(currentFlowIndex);
    };

    const selectNextFlow = function(){
        const flows = globalState.presentationBar.presentationFlows.flows;
        let currentFlowIndex = globalState.presentationBar.presentationFlows.indexOfSelectedFlow;

        if(currentFlowIndex + 1 >= flows.length) {
            currentFlowIndex = 0;
        }
        else
        {
            currentFlowIndex++;
        }

        props.updateSelectedIndexFlow(currentFlowIndex);            
    };

    const selectPrevNextSlide = (direction) => {
        const slides = hasCurrentFlow() ? getCurrentFlowSlides() : getContentSlides();
        
        if (slides.length > 0) {
            const newIndex = direction === 'prev' ? indexOfSelectedSlide - 1 : indexOfSelectedSlide + 1;
    
            if (newIndex >= 0 && newIndex < slides.length) {
                const slide = slides[newIndex];
                selectSlide(slide.code);
            }
        }
    };
    
    const selectPrevSlide = () => selectPrevNextSlide('prev');
    const selectNextSlide = () => selectPrevNextSlide('next');
    
    const selectSlide = (slideCode) => {
        const slides = hasCurrentFlow() ? getCurrentFlowSlides() : getContentSlides();
    
        const index = findSlideIndexByCode(slides, slideCode);
    
        if (index !== -1) {
            props.updateSelectedIndexSlide(index);
            props.setSelectedSlide(slideCode);
        }
    };

    const handlePlaySlide = () => {
        if(isFlowsControls){
            playFlow(indexOfSelectedFlow);
        } else if(hasCurrentFlow()) {
            playFlow(indexOfSelectedFlow, indexOfSelectedSlide);
        } else {
            playSpecificSlide();
        }
    }

    return (
        <>
            <div className="presentation__controls__fowback__container">
                <div className="presentation__controls__left-arrow" id="btnSlideListPrevSlide" onClick={!isFlowsControls ? selectPrevSlide : selectPrevFlow}> <ArrowLeftIcon style={{ color: color }} /> </div>
                <div className="presentation__controls__slides-counter"> {!isFlowsControls ? indexOfSelectedSlide + 1 : indexOfSelectedFlow + 1}/{!isFlowsControls ? totalSlides : totalFlows} </div>
                <div className="presentation__controls__right-arrow" id="btnSlideListNextSlide" onClick={!isFlowsControls ? selectNextSlide : selectNextFlow}> <ArrowRightIcon style={{ color: color }} /> </div>
            </div>
            <div className="presentation__controls__playable__container">
                <div className="presentation__controls__play-slide" id="btnSlideListPlaySlide" onClick={handlePlaySlide} >
                    <SlideshowIcon style={{ color: color }} />
                    <span style={{ color: color }}>{intl.formatMessage({id: "PRESENTATION.BAR.PROJECT"})}</span>
                </div>
            </div>
        </>
    )
}

export default injectIntl(
    connect(
        null,
        presentationBarStorage.actions
    )(SlideListControls)
);