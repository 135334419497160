import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from "react-intl";
export default ({
    open,
    showWarning,
    title,
    text,
    buttonText
}) => {
    return (
        <div>
          <Dialog
            open={open}
            onClose={() => showWarning(false)} 
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {text}
              </DialogContentText>
            </DialogContent> 
            <DialogActions>
              <Button id="btnCloseTimeWarning" onClick={() => showWarning(false)} color="primary">
                {buttonText}
              </Button>
             
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    