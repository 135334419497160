import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import Timer from 'react-compound-timer';
import { FormattedMessage, injectIntl } from "react-intl";
import * as presentationBarStorage from '../../../app/store/ducks/presentationBar.duck';
import * as loggerStorage from "../../../app/store/ducks/logger.duck";
import moment from 'moment';
import './Header.scss';
import { toAbsoluteUrl } from "../../../_metronic";
import {logoName} from '../../../globalConfig';

class Header extends React.Component {
  headerCommonRef = React.createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const {
      headerLogo,
      menuHeaderDisplay,
      headerAttributes,
      headerClasses,
      displayHeaderLogo,
      history
    } = this.props;
    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        style={{display: 'flex', justifyContent: (this.props.center) ? '' : 'flex-end'}}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <AnimateLoading />
        {displayHeaderLogo && 
        <div className="kt-header__logo">
            <img alt="logo" src={headerLogo} />
        </div>
        }
        
        {/* <!-- begin: Header Menu --> */}
        {menuHeaderDisplay && <HMenu />}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
        {!menuHeaderDisplay && <div />}       
        <Topbar history={history} />
        {/* <!-- end:: Header Topbar --> */}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    headerLogo: toAbsoluteUrl(`/media/logos/${logoName}`),
    headerClasses: builder.selectors.getClasses(store, {
      path: "header",
      toString: true
    }),
    headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
    menuHeaderDisplay: objectPath.get(
      store.builder.layoutConfig,
      "header.menu.self.display"
    ),
    fluid: objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
    callTime: store.presentationBar.callTime,
    auth: store.auth,
    logger: store.logger
  }
}

export default injectIntl(
  connect(
    mapStateToProps,
    {...presentationBarStorage.actions, ...loggerStorage.actions}
  )(Header)
);
