import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Home() {
  const history = useHistory();
  const globalState = useSelector(state => state);
  
  useEffect(() => {
    if(globalState.auth.profileName && !(globalState.auth.profileName.toLowerCase().indexOf('admin') > -1)) {
      history.push("/dashboard");
    }
  }, [globalState.auth.role])

  return null;
}
