import React, {useEffect} from 'react';
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { getDeploymentParamValue, getFilesTypePreview } from './utils';
import { Container, ListItemText } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import './PresentationGrid.scss';
import PresentationGridItem from '../PresentationGridItem/PresentationGridItem';
import EmptyStateGrid from './EmptyStateGrid';
import ProductsCallDialog from '../ProductsCallDialog/ProductsCallDialog';
import { toAbsoluteUrl } from '../../../_metronic';

const PresentationGrid = ({
  deploymentStyle,
  side, 
  toggleDrawer, 
  showSlideList, 
  startPresentation, 
  stopPresentation,
  props
}) => {
  const globalState = useSelector(state => state);
  const { intl } = props;
  const [vaLogoUrl, setValogoUrl] = React.useState('');

  const productCreateEnable = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_ENABLE_CUSTOMER') == '1';

  useEffect(() => {
    if(globalState.auth.deployment.parameters){
      setValogoUrl(getDeploymentParamValue(globalState.auth.deployment.parameters, "VA_LOGO_URL"));
    }
  }, [globalState.presentationBar.presentationSlides]);

  const getPreview = function(va){
    let preview = vaLogoUrl || toAbsoluteUrl('/media/logos/600x260.png');
  
    if(va.preview && (va.type === 'slides' || va.type === 'sdva')){
      preview = va.baseUrl + "/" + va.preview;
    }
  
    return preview;
  };

  const viewFile = (file) => {
    window.open(file.url, '_blank');

    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'FILE_VIEW',
      log_text: `filecode: ${file.code}`
    })
  };

  const areListsEmpty = function() {
    if (!globalState.presentationBar) return true;
    
    const { presentationsListFiltered, fileListFiltered } = globalState.presentationBar;
    return (!presentationsListFiltered || presentationsListFiltered.length === 0) && (!fileListFiltered || !fileListFiltered[""] || fileListFiltered[""].length === 0);
  };

  return (
    <center className="wrapper" style={{alignSelf: 'flex-start', marginTop:'70px'}}>
     <div className="webkit" >
        <table className="wrapper" cellPadding="0" cellSpacing="0" border="0" width="100%" bgcolor="#f2f4fb"  >
          <tbody>
            <tr>
              { isMobile ?
                <div className="icons-with-text__group">
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <ListItemText primaryTypographyProps={{ style: { fontSize: '1.4rem' } }} style={{marginLeft:20}} primary={intl.formatMessage({id: "PRESENTATION.BAR.SELECT"})}/>
                </div>
                :
                null
              }
                  
              <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                  { globalState.presentationBar && globalState.presentationBar.presentationsListFiltered 
                    ? globalState.presentationBar.presentationsListFiltered.map((presentationSlide, index) => (
                      <PresentationGridItem item={presentationSlide} action={startPresentation(0, presentationSlide)} preview={getPreview(presentationSlide)} deploymentStyle={deploymentStyle} intl={intl}/>
                    )) : null
                  }

                  { globalState.presentationBar && globalState.presentationBar.fileListFiltered && globalState.presentationBar.fileListFiltered[""]
                    ? globalState.presentationBar.fileListFiltered[""].map((file) => (
                      <PresentationGridItem item={file} action={() => viewFile(file)} preview={getFilesTypePreview(file)} deploymentStyle={deploymentStyle} intl={intl}/>
                    )) : null
                  }

                  {
                    productCreateEnable && !globalState.presentationBar.uploadSignatureResult &&
                    <ProductsCallDialog gridItem={true}/>
                  }

                  {
                    areListsEmpty() && <EmptyStateGrid/>
                  }
                </Grid>
              </Container>

            </tr>
          </tbody>
        </table>
      </div >
    </center>
  );
}

export default injectIntl(
  connect(
    null,
    null
  )(PresentationGrid)
);