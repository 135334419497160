import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: "10.5px"
    }
}));

const PresentationGridActionItem = ({ action, buttonText, title, preview, deploymentStyle }) => {
    const classes = useStyles();

    const handleOnClick = () => {
        action();
    }

    return (
        <Grid onClick={action} item key={buttonText + '-' + title} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                className="preview_img"
                component="img"
                image={preview}
                />
                
                <CardContent sx={{ flexGrow: 1 }}>
                <Tooltip title={title} arrow classes={{ tooltip: classes.tooltip }}>
                    <Typography gutterBottom variant="h5" component="h2">
                        { title }
                    </Typography>
                </Tooltip>
                </CardContent>

                <CardActions>
                <Button 
                    style={{ border: '1px solid #d3d4d5', color: deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor}}  
                    id="btnActionItemGo" 
                    onClick={handleOnClick}
                >
                    { buttonText }
                </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default PresentationGridActionItem