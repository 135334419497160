import React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { useHistory } from "react-router-dom";

import * as promotionalPlansStorage from "../../store/ducks/promotionalPlans.duck";

const useStyles = makeStyles((theme) => ({
  stateControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 10
  },
  planControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PromotionalPlans = (props) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const history = useHistory();
  const classes = useStyles();
  const [stateSelected, setStateSelected] = React.useState(1);
  const [planSelected, setPlanSelected] = React.useState(-1);
  const globalState = useSelector(state => state);
  const { intl } = props;

  useEffect(() => {
    // obtener los datos de los planes
    props.getPromotionalPlans(stateSelected);
  }, [])

  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setSelectedRow(row);
    setMenuAnchor(anchorElement);
    setMenuOpen(true);
  };

  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
  }

  const handleChange = (event) => {
    setStateSelected(event.target.value);
    props.getPromotionalPlans(event.target.value);
  };

  const handleEdit = (dataRow) => (event) => {
    props.selectPlanCode(dataRow.code);
    history.push(`/promotionalplans/update/${dataRow.uuid}`);
  }

  let actionsColumnIndex = 2;
  let columns = [
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.NAME" }), field: 'name', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.EXTERNAL_CODE" }), field: 'externalCode', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.VALIDFROM" }), field: 'ValidFrom_UTC', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.VALIDTO" }), field: 'ValidTo_UTC', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.USERGROUPS" }), field: 'userGroupNames', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.CUSTOMERGROUPS" }), field: 'customerGroupNames', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.VISUALAIDS" }), field: 'vaNames', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.FILES" }), field: 'fileNames', hidden: true, export: true },
    { title: intl.formatMessage({ id: "PROMOTIONAL_PLANS.COLUMN.PRODUCTS" }), field: 'productNames', hidden: true, export: true },
  ];

  return(
    <div id="promotionalPlansList" style={{ width: '100%', boxShadow: 'none' }}>
      <FormControl className={classes.stateControl} >
        <InputLabel id="demo-simple-select-helper-label">{intl.formatMessage({ id: "PROMOTIONAL_PLANS.SELECTOR.STATEID" })}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={stateSelected}
          onChange={handleChange}
        >
          <MenuItem key={0} value={0}>{intl.formatMessage({ id: "PROMOTIONAL_PLANS.SELECTOR.STATEID.INACTIVE"})}</MenuItem>
          <MenuItem key={1} value={1}>{intl.formatMessage({ id: "PROMOTIONAL_PLANS.SELECTOR.STATEID.ACTIVE"})}</MenuItem>

        </Select>
      </FormControl> 
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.promotionalPlans.promotionalPlans}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left', 
            headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
            actionsColumnIndex: -1,
            exportButton: {
              csv: true
            },
            exportAllData: true,
            exportFileName: `plans_${(new Date()).toISOString().split('T')[0]}`
          }
        }
        actions={[
          {
            icon: 'edit',
            tooltip: intl.formatMessage({
              id: "PROMOTIONAL_PLANS.MENU.EDIT"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            }
          }
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            }),
            exportCSVName: intl.formatMessage({
              id: 'PROMOTIONAL_PLANS.ACTIONS.BUTTON.EXPORT.CSV'
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },

        }}
      />
      <Menu
        id="menu-visualaidactions"
        keepMounted
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
        >
        {
          (selectedRow)
          ?
            <MenuItem onClick={handleEdit(selectedRow)} >{intl.formatMessage({ id: "PROMOTIONAL_PLANS.MENU.ACTIONS.EDIT"})}</MenuItem>
          :
            null
        }
      </Menu>
  </div>
  )
}

export default injectIntl(
  connect(
    null,
    promotionalPlansStorage.actions
  )(PromotionalPlans)
);