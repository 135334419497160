import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import TagsInput from '../../../components/TagsInput';
import { getDeploymentParamValue } from '../../../../_metronic/utils/utils';
import SelectFolderButton from './SelectFolderButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import * as fileStorage from "../file.duck";

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: 280
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCheck: {
    fontSize: 16,
    fontWeight: 'lighter'
  }

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeaderFile = (props) => {
  const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [name, setName] = useState('');
  const [folder, setFolder] = useState('');
  const [codFolder, setCodFolder] = useState(-1);
  const [externalCode, setExternalCode] = useState('');
  const [validFrom, setValidFrom] = useState(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
  const [validTo, setValidTo] = useState(moment(new Date()).add(30, 'days').format('YYYY-MM-DDTHH:mm:ss'));
  const [order, setOrder] = useState(0);
  const [planName, setPlanName] = React.useState([]);
  const [allowShare, setAllowShare] = useState(0);
  const [allowUserShare, setAllowUserShare] = useState(1);
  const [automaticDownload, setAutomaticDownload] = useState(0);
  const [allowPresent, setAllowPresent] = useState(1);
  const [onlineVisit, setOnlineVisit] = useState(0);
  const [offlineVisit, setOfflineVisit] = useState(0);
  const [arrTags, setArrTags] = React.useState([]);
  const [folderSelector, setFolderSelector] = React.useState(false);
  const isFromPopUp = props.from == "popup";
  
  const CALL_PRESENT_ENABLE = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_PRESENT_ENABLE');
  const showOfflineVisit = CALL_PRESENT_ENABLE === 1 || CALL_PRESENT_ENABLE === '1';
  const defaultCallType = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_DEFAULT_CALLTYPE');

  useEffect(() => {
    return () => {
      props.fillResultFileContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.file.fileContainerResponse.name && globalState.file.fileContainerResponse.externalCode){
      setName(globalState.file.fileContainerResponse.name);
      setFolder(globalState.file.fileContainerResponse.folder);
      setAllowShare(globalState.file.fileContainerResponse.allow_share);
      setAllowUserShare(globalState.file.fileContainerResponse.allow_usershare);
      setAutomaticDownload(globalState.file.fileContainerResponse.automatic_download);
      setAllowPresent(globalState.file.fileContainerResponse.allow_present);
      setExternalCode(globalState.file.fileContainerResponse.externalCode);
      setValidFrom(globalState.file.fileContainerResponse.validFrom);
      setValidTo(globalState.file.fileContainerResponse.validTo);
      setOrder(globalState.file.fileContainerResponse.order);
      setCodFolder(globalState.file.fileContainerResponse.cod_folder);

      if(!folder && codFolder && !isFromPopUp) {
        props.getFolders();
      }

      let callType = globalState.file.fileContainerResponse.call_type || defaultCallType;
      switch (callType) {
        case 1: // Online
          setOnlineVisit(1);
          break;
        case 2: // Offline
          setOfflineVisit(1);
          break;
        case 3: // Las dos
          setOnlineVisit(1);
          setOfflineVisit(1);
          break;
        default:
          break;
      }

      if(globalState.file.fileContainerResponse.plans &&
        globalState.file.fileContainerResponse.plans.length > 0){
          let plansSelected = [];
          globalState.file.fileContainerResponse.plans.forEach((plan, index) =>{
            globalState.file.promotionalPlans.list.forEach((item, index) => {
              if(plan === item.code){
                plansSelected.push(item);
              }
            })
          })
          setPlanName(plansSelected);
      }

      if(globalState.file.fileContainerResponse.tags){
        let tagGroup = globalState.file.fileContainerResponse.tags.split(';');
        let arrTagsTemp = [];
        if(tagGroup.length){
          tagGroup.forEach((tagPar) => {
            let keyvalue = tagPar.split(':');
            
            if(keyvalue.length === 2){
              arrTagsTemp.push({key:keyvalue[0], value:keyvalue[1]});
            }
          });
          setArrTags([...arrTagsTemp, ...arrTags]);
        }
      }

    }

    const folderSelectorParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_FOLDER_ENABLE');
    setFolderSelector(folderSelectorParam);
  }, [globalState.file.fileContainerResponse.name, globalState.file.fileContainerResponse.externalCode]);

  useEffect(() => {
    if(codFolder && globalState.file.folders.length > 0) {
      const folderFinded = globalState.file.folders.find(f => f.code === codFolder);

      if(folderFinded) {
        setFolder(folderFinded.name);
      }
    }
  }, [globalState.file.folders])

  useEffect(() => {
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, call_type: checkCallType()})
  }, [onlineVisit, offlineVisit]);

  const checkCallType = () => {
    let callType = 0;

    if(onlineVisit && offlineVisit) {
      callType = 3;
    } else if(onlineVisit) {
      callType = 1;
    } else if(offlineVisit) {
      callType = 2;
    }

    return callType;
  }

  const handleChangePlanName = (event, values) => {
    setPlanName(values);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, plans: values})
  };

  const handleChangeName = (event) => {
    setName(event.target.value)
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, name: event.target.value})
  }
  
  const handleChangeFolder = (event) => {
    setFolder(event.target.value)
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, folder: event.target.value})
  }

  const handleExternalCode = (event) => {
    setExternalCode(event.target.value);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, externalCode: event.target.value})
  } 

  const handleChangeAllowShare = (event) => {
    setAllowShare(event.target.checked);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, allow_share:Number(event.target.checked)});
  }

  const handleChangeAllowUserShare = (event) => {
    setAllowUserShare(event.target.checked);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, allow_usershare:Number(event.target.checked)});
  }

  const handleChangeAutomaticDownload = (event) => {
    setAutomaticDownload(event.target.checked);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, automatic_download:Number(event.target.checked)});
  }

  const handleChangeAllowPresent = (event) => {
    setAllowPresent(event.target.checked);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, allow_present:Number(event.target.checked)});
  }

  const handleChangeOnlineVisit = (event) => {
    setOnlineVisit(event.target.checked);
  }

  const handleChangeOfflineVisit = (event) => {
    setOfflineVisit(event.target.checked);
  }

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, validFrom: event.target.value})
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, validTo: event.target.value})
  }

  const handleOrder = (event) => {
    setOrder(event.target.value);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, order: event.target.value})
  } 

  const handleChangeArrTags = (input) => {
    setArrTags(input);
    // aca tiene que pasar de array de objetos a string key:value;key:value
    if(input.length){
      let arrKeyValue = [];
      input.forEach((item) => {
        arrKeyValue.push(`${item.key}:${item.value}`);
      });
      let strtags = arrKeyValue.join(';');
      props.setArrTags(strtags);
    }else{
      props.setArrTags('');
    }
  } 

  const handleSelectFolder = (folder) => {
    setCodFolder(folder.code);
    // Seteo el nombre nomas para que se muestre en el input, este no se manda en el request porque la columna folder tiene que quedar vacia
    setFolder(folder.name);
    props.fillResultFileContainer({...globalState.file.fileContainerResponse, cod_folder: folder.code})
  }

  let statusText = ''; //(1=aprobado, 2=pendiente, 3= Borrador, 4=Temporal)
  if(globalState.file.fileContainerResponse.status){
    switch(globalState.file.fileContainerResponse.status){
      case 1:
        statusText = intl.formatMessage({ id: "VAID.STATUS.APPROVED" })
        break;
      case 2:
        statusText = intl.formatMessage({ id: "VAID.STATUS.PENDING" })
        break;
      case 3:
        statusText = intl.formatMessage({ id: "VAID.STATUS.DRAFT" })
        break;
      case 4:
        statusText = intl.formatMessage({ id: "VAID.STATUS.TEMP" })
        break;
      default:
        statusText = ''
    }
  }

  let labelPresentations = intl.formatMessage({
    id: "VAID.EDIT.FORM.PLANOS"
  });

  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id={"VAID.EDIT.FORM.NAME"} />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'name'}
            value={name}
            onChange={handleChangeName}
          />
        </span>
      </div>
      { !isFromPopUp && 
        <div className={classes.inRow}>
          <span className={classes.label}><FormattedMessage id={"VAID.EDIT.FORM.FOLDER"} />: </span>
          <span className={classes.data}>
            {
              folderSelector == 1 ?
              <>
                <TextField
                id="outlined-read-only-input"
                value={folder}
                className={classes.inputText}
                InputProps={{
                  readOnly: true,
                }}
                />
                <SelectFolderButton handleSelectFolder={handleSelectFolder} />
              </>
              :
              <TextField
              className={classes.inputText}
              error={globalState.file.errorValidation === 'folder'}
              value={folder}
              onChange={handleChangeFolder}
              />
            }
          </span>
        </div>
      }
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id={"VAID.EDIT.FORM.EXTERNAL_CODE"} />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={externalCode}
            onChange={handleExternalCode}
          />
        </span>
      </div>

      <div className={classes.rowContainer}>
            <div className={classes.row}>        
              <FormControlLabel
                value={allowShare}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowShare}
                  onChange={handleChangeAllowShare} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_SHARE"/>:
                  </Typography>
                }
                labelPlacement="start"
              />

              <FormControlLabel
                value={allowUserShare}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowUserShare}
                  onChange={handleChangeAllowUserShare} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_USERSHARE"/>:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              <FormControlLabel
                value={automaticDownload}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={automaticDownload}
                  onChange={handleChangeAutomaticDownload} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.AUTOMATIC_DOWNLOAD" />:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              <FormControlLabel
                value={allowPresent}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowPresent}
                  onChange={handleChangeAllowPresent} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_PRESENT" />:
                  </Typography>
                }
                labelPlacement="start"
              />
            </div>

            <div className={classes.row}>
              <FormControlLabel
                value={onlineVisit}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon} 
                  checked={onlineVisit}
                  onChange={handleChangeOnlineVisit} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE" />:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              {
                showOfflineVisit &&
                <FormControlLabel
                  value={offlineVisit}
                  control={
                    <Checkbox 
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={offlineVisit}
                    onChange={handleChangeOfflineVisit} 
                    />
                  }
                  label={
                    <Typography className={classes.labelCheck}>
                      <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.PRESENTATION" />:
                    </Typography>
                  }
                  labelPlacement="start"
                />
              }
            </div>
          </div>
      
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_FROM" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'validFrom' || globalState.file.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validFrom}
            onChange={handleChangeDateFrom}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_TO" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'validTo' || globalState.file.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validTo}
            onChange={handleChangeDateTo}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.ORDER" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={order}
            onChange={handleOrder}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.STATUS" />: </span>
        <span className={classes.data}>{statusText}</span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.PLANOS" />: </span>
        <span className={classes.data}>
          <Autocomplete
            multiple
            id="tags-standard-visual-aids"
            options={globalState.file.promotionalPlans.list}
            disableCloseOnSelect={true}
            onChange={handleChangePlanName}
            value={planName}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
               </React.Fragment>
               )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={labelPresentations}
                  />
              )}
           />
        </span>
      </div> 
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.TAGS" />: </span>
        <span className={classes.data}>
          <TagsInput 
            setArrTagsGlobal={handleChangeArrTags} 
            tagsItems={arrTags} 
            tagOptions={globalState.file.tagsOptions}
          />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    fileStorage.actions
  )(HeaderFile)
);