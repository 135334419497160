import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 16,
        width: '50%',
        textAlign: 'right'
    },
    data: {
        width: '50%',
        paddingLeft: 20
    },
    inRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    }
}));

const ImportLogHeader = (props) => {
  const classes = useStyles();

  return(
    <>
        <div className="row">
            <div className={classes.inRow}>
                <span id="file-code-label" className={classes.label}><FormattedMessage id="IMPORT_FILE.CODE" />: </span>
                <span className={classes.data}>{props.header.code}</span>
            </div>
            <div className={classes.inRow}>
                <span id="file-name-label" className={classes.label}><FormattedMessage id="IMPORT_FILE.NAME" />: </span>
                <span className={classes.data}>{props.header.original_Name}</span>
            </div>
        </div>

        <div className="row">
            <div className={classes.inRow}>
                <span id="status-label" className={classes.label}><FormattedMessage id="IMPORT_FILE.STATUS" />: </span>
                <span className={classes.data}>{props.header.statusText}</span>
            </div>
            <div className={classes.inRow}>
                <span id="last-executed-label" className={classes.label}><FormattedMessage id="IMPORT_FILE.LASTEXECUTION" />: </span>
                <span className={classes.data}>{props.header.lastExecutedAtUTC}</span>
            </div>
        </div> 
    </>
  )
}

export default injectIntl(
  connect(
    null,
    null
  )(ImportLogHeader)
);