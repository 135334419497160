import React from "react";
import { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as visualAidStorage from "../../store/ducks/visualAid.duck";
import MaterialTable from 'material-table'
import './VisualAids.scss';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { activateVisualAid, inactivateVisualAid } from "../../crud/visualAid.crud";
import { useHistory } from "react-router-dom";
import EditTagsPopUp from './EditTagsPopUp';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  stateControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 10
  },
  planControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const VisualAids = (props) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({name:''});
  const [displayDialog, setDisplayDialog] = React.useState(false);
  const history = useHistory();
  
  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setSelectedRow(row);
    setMenuAnchor(anchorElement);
    setMenuOpen(true);
  };

  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
  }

  const classes = useStyles();
  const [stateSelected, setStateSelected] = React.useState(1);
  const [planSelected, setPlanSelected] = React.useState(-1);
  const globalState = useSelector(state => state);
  const { intl } = props;

  useEffect(() => {
    props.fillVisualAids({ stateId: stateSelected, code: planSelected, intl: intl })
  }, [globalState.visualAid.promotionalPlans]);

  useEffect(() => {
    Object.assign(globalState.visualAid.visualAids, globalState.visualAid.visualAids);
  }, [globalState.visualAid.visualAids]);

  useEffect (() => {
    props.fillPromotionalPlan(stateSelected);
    props.getVisualAidsTagsOptions();
    props.fillResultVisualAidContainer({});
  }, [])

  const makeSearch = () => {
    props.fillVisualAids({ stateId: stateSelected, code: planSelected, intl: intl });
  }

  const handleChange = (event) => {
    setStateSelected(event.target.value);
    //props.fillPromotionalPlan(event.target.value);
    props.fillVisualAids({ stateId: event.target.value, code: planSelected, intl: intl });
  };

  const handleChangePlan = (event) => {

    setPlanSelected(event.target.value);
    props.fillVisualAids({ stateId: stateSelected, code: event.target.value, intl: intl });
  };

  const handleActivate = () => {
      handleMenuClose();
      props.activateVaid({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
  }

  const handleDeactivate = () => {
    let msgAlert = intl.formatMessage({ id: "VAID.ACTIONS.CONFIRM" });
    if (window.confirm(msgAlert)) {
      handleMenuClose();
      props.inactivateVaid({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
    }
  }

  const handleApprove = () => {
    handleMenuClose();
    props.approveVaid({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected , intl: intl});
  }

  const handlePending = () => {
    handleMenuClose();
    props.pendingVaid({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
  }

  const handleEdit = () => {
    handleMenuClose();
    props.fillResultVisualAidContainer({code: selectedRow.code})
    doRedirection(selectedRow.code)
  }

  const handleDisplayDialog = rowData => event => {
    setDisplayDialog(true);
    setSelectedRow(rowData);
  }

  const doRedirection = (code) => {
    if(selectedRow.type == 'slides'){
      history.push("/visualaid/slide/update/" + code);
    }else if(selectedRow.type == 'sdva'){
      history.push("/visualaid/sdva/update/" + code);
    }else if(selectedRow.type == 'video'){
      history.push("/visualaid/video/update/" + code);
    }
  }

  let actionsColumnIndex = 6;
  let columns = [
    { 
      title: intl.formatMessage({ id: "VAID.COLUMN.NAME" }), 
      field: 'name', 
      cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {},
      render: rowData => <a href='#' onClick={handleDisplayDialog(rowData)}> {rowData.name} </a>,
      export: true
    },
    { title: intl.formatMessage({ id: "VAID.COLUMN.CODE" }), field: 'code', hidden: true, export: true},
    { title: intl.formatMessage({ id: "VAID.COLUMN.TYPE" }), field: 'type', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}, export: true },
    { title: intl.formatMessage({ id: "VAID.COLUMN.LENGTH" }), field: 'length', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}, export: false },
    { title: intl.formatMessage({ id: "VAID.COLUMN.ORDER" }), field: 'order', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}, export: true },
    { title: intl.formatMessage({ id: "VAID.COLUMN.STATUS" }), field: 'statusName',  cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}, export: false},
      /*render: rowData => rowData.status == 1? intl.formatMessage({ id: "VAID.STATUS.APPROVED" }): 
                         rowData.status == 2? intl.formatMessage({ id: "VAID.STATUS.PENDING" }):
                         rowData.status == 3? intl.formatMessage({ id: "VAID.STATUS.DRAFT" }): 
  rowData.status == 4? intl.formatMessage({ id: "VAID.STATUS.TEMP" }):''*/
    { title: intl.formatMessage({ id: "VAID.COLUMN.PLANNAMES" }), field: 'planNames', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}, export: false },
    { title: intl.formatMessage({ id: "VAID.COLUMN.TAGS" }), field: 'tags', hidden: true, export: true},
    { title: intl.formatMessage({ id: "VAID.COLUMN.VALIDFROM" }), field: 'validFromUTC', hidden: true, export: true},
    { title: intl.formatMessage({ id: "VAID.COLUMN.VALIDTO" }), field: 'validToUTC', hidden: true, export: true}
  ];


  return (
    <div id="visualAidList" style={{ width: '100%', boxShadow: 'none' }}>
      {displayDialog &&
      <EditTagsPopUp displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} selected={selectedRow} />
      }
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
        <div>
          <FormControl className={classes.stateControl} >
            <InputLabel id="demo-simple-select-helper-label">{intl.formatMessage({ id: "VAID.SELECTOR.STATEID" })}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={stateSelected}
              onChange={handleChange}
            >
              <MenuItem key={0} value={0}>{intl.formatMessage({ id: "VAID.SELECTOR.STATEID.INACTIVE"})}</MenuItem>
              <MenuItem key={1} value={1}>{intl.formatMessage({ id: "VAID.SELECTOR.STATEID.ACTIVE"})}</MenuItem>

            </Select>
          </FormControl>
          <FormControl className={classes.planControl} >
            <InputLabel id="demo-simple-select-helper-label">{intl.formatMessage({ id: "VAID.SELECTOR.PLAN" })}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="promoPlanSelect"
              value={planSelected}
              onChange={handleChangePlan}
            >
              <MenuItem key={-1} value={-1}>{intl.formatMessage({ id: "VAID.SELECTOR.PLAN.ALL" })}</MenuItem>
              <MenuItem key={0} value={-2}>{intl.formatMessage({ id: "VAID.SELECTOR.PLAN.NOPLAN" })}</MenuItem>
              {globalState.visualAid.promotionalPlans.list ? globalState.visualAid.promotionalPlans.list.map((plan, index) => {
                return (<MenuItem key={index+1} value={plan.code}>{plan.name}</MenuItem>);
              }) : null}
            </Select>
          </FormControl>
        </div>
        <div>

          <Button onClick={makeSearch}><SearchIcon /><FormattedMessage id="VAID.BUTTON.SEARCH" /></Button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.visualAid.visualAids}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left', 
            headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
            actionsColumnIndex: -1,
            exportButton: {
              csv: true
            },
            exportAllData: true,
            exportFileName: `vas_${(new Date()).toISOString().split('T')[0]}`
          }
        }
        actions={[
          rowData => ({
            icon: 'edit',
            tooltip: intl.formatMessage({
              id: "DASHBOARD.MENU.EDIT"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            },
            disabled: (rowData.status == 5 || rowData.status == 8)
          })
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            }),
            exportCSVName: intl.formatMessage({
              id: 'VAID.ACTIONS.BUTTON.EXPORT.CSV'
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },

        }}
      />
      <Menu
         id="menu-visualaidactions"
         keepMounted
         anchorEl={menuAnchor}
         open={menuOpen}
         onClose={handleMenuClose}
         >
         
         {(selectedRow && selectedRow.state_id == 1)?
            <MenuItem onClick={handleDeactivate} >{intl.formatMessage({ id: "VAID.MENU.ACTIONS.DEACTIVATE"})}</MenuItem>
            :<MenuItem onClick={handleActivate}>{intl.formatMessage({ id: "VAID.MENU.ACTIONS.ACTIVATE"})}</MenuItem>}

        {(selectedRow && selectedRow.status == 2)?
            <MenuItem onClick={handleApprove} >{intl.formatMessage({ id: "VAID.MENU.ACTIONS.APPROVE"})}</MenuItem>
            :(selectedRow && (selectedRow.type=='slides' || selectedRow.type=='sdva' || selectedRow.type == 'html5animate' || selectedRow.type == 'video') && selectedRow.status == 3)?
            <>
            <MenuItem onClick={handlePending}>{intl.formatMessage({ id: "VAID.MENU.ACTIONS.PENDING"})}</MenuItem>
            <MenuItem onClick={handleEdit}>{intl.formatMessage({ id: "VAID.MENU.ACTIONS.EDIT"})}</MenuItem>
            </>
            :(selectedRow && (selectedRow.type=='slides' || selectedRow.type=='sdva' || selectedRow.type == 'html5animate' || selectedRow.type == 'video') && selectedRow.status == 4)?
            <MenuItem onClick={handleEdit}>{intl.formatMessage({ id: "VAID.MENU.ACTIONS.EDIT"})}</MenuItem>
            : null
          }
       </Menu>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(VisualAids)
);