import React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import CallActionEditMenu from '../CallActionEditMenu';
import CallActionShareMenu from '../CallActionShareMenu';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Button from '@material-ui/core/Button';
import { defaultStyles } from '../../../globalConfig';
import CallIcon from '@material-ui/icons/Call';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import CallDetailsPopUp from '../CallDetailsPopUp';
import * as authStorage from "../../store/ducks/auth.duck";

const selectIconCall = (status, callSesion, hasCustomerStarted) => {
  if (status == 2)
    return (hasCustomerStarted > 0) ? (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.CLOSED_WITH_CLIENT" />}><CheckCircleIcon style={{ fill: "green" }} fontSize="large" /></Tooltip>) : (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.CLOSED_WITHOUT_CLIENT" />}><CancelIcon style={{ fill: "red" }} fontSize="large" /></Tooltip>) //caso de que la llamada este cerrada
  else if (status == 1 && callSesion > 0)
    return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.INITED" />}><SlowMotionVideoIcon style={{ fill: "orange" }} fontSize="large" /></Tooltip>) //caso de que la llamada este iniciada
  else if (status == 1 && callSesion == 0)
    return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.NOTINITED" />}><AccountBoxIcon fontSize="large" /></Tooltip>) //caso de que la llamada este sin iniciar
  return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.NOTINITED" />}><AccountBoxIcon fontSize="large" /></Tooltip>) //caso default
}

const ItemOfCalls = (props) => {
  //let code = props.code;
  //console.log(moment(props.item.duedateUTC).format('HH:mm'))
  const {intl} = props;
  const globalState = useSelector(state => state);
  const callGuestQty = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_GUEST_QTY");
  
  let isAmPm = getDeploymentParamValue(globalState.auth.deployment.parameters, 'TIME_AMPM');
  let formatDateItemCall = 'HH:mm'
  if(isAmPm == 1){
    formatDateItemCall = 'hh:mm A';
  }

  const handleEnterClick = (item) => event => {
    //console.log(`/call/${item.item.code}/${globalState.auth.userId}`)
    if(callGuestQty && callGuestQty == '1' && item.item.organizerId != globalState.auth.userid && item.item.guestId == globalState.auth.userid){
      //GET TOKEN
      props.getUrlToken({codeCall: item.item.code, userId: globalState.auth.userid});
    }else{
      switch (item.item.type) {
        case 1:
          window.location.href = `/call/${item.item.code}/${globalState.auth.userid}`;
          break;
        case 2:
          window.location.href = `/presentation/${item.item.code}/${globalState.auth.userid}`;
          break;
        default:
          window.location.href = `/call/${item.item.code}/${globalState.auth.userid}`;
          break;
      }
      
    }
  }
  return (
    <div className="kt-widget4__item__wrapper" key={props.item.code}>
      <div className="kt-widget4__item ">
        <div className="kt-widget4__pic kt-widget4__pic--pic ">
          {
            selectIconCall(props.item.status, props.item.last_CallSession, props.item.hasCustomerStarted)
          }
        </div>
        <div className="kt-widget4__info ">
          <div style={{display: 'inline'}}>
            <a className="kt-widget4__username">{props.item.subscriberName} </a>
            {(callGuestQty && callGuestQty == '1' && props.item.guestId && props.item.guestId != '') ?
              <Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.ACCOMPANIED_VISIT" />}><SupervisorAccountIcon/></Tooltip>
              :null
            }
            {(props.item.archiveCount && props.item.archiveCount>0 ) ?
              <Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.RECORDED_CALL" />}><FiberManualRecordIcon style={{ fill: "red" }}/></Tooltip>
              :null
            }
          </div>
          <a className="kt-widget4__title" />
          
          <p className="kt-widget4__text "> {moment(props.item.duedateUTC).add(globalState.auth.timezone, 'hours').format(formatDateItemCall)} {(isAmPm) ? '' : 'hs'} | <FormattedMessage id="SCHEDULE.VISIT.CODE" />: {props.item.code} </p>
        </div>
        {props.item.status != 2 && props.item.type != 2 ?
          <Button
            className="btn btn-sm btn-label-brand"
            id="btnAgendaEnterCall"
            onClick={handleEnterClick(props)}
            style={{
              backgroundColor: defaultStyles.accent_color
            }}
          >
            <CallIcon />
            <span>&nbsp;</span>
            <FormattedMessage id="SCHEDULE.BUTTON.SIGN_IN" />
          </Button>
          : 
          <SlideshowIcon/>
        }
      </div>

      <div className="btn-group-agenda">
        <CallDetailsPopUp 
          item={props.item}
          intl={intl}
          showButton={true}
        />
        {props.item.organizerId == globalState.auth.userid && props.item.status != 2 &&
          <CallActionShareMenu item={props.item} />
        }
        {props.item.organizerId == globalState.auth.userid && props.item.status != 2 &&
          <CallActionEditMenu item={props.item} />
        }
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    authStorage.actions
  )(ItemOfCalls)
);