/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { 
  Button, Tooltip, makeStyles, IconButton, Dialog, DialogContent, DialogActions, DialogTitle 
} from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: '50vh'
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: '40vh',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  menuButton: {
      marginRight: theme.spacing(2),
  }
}));

const FeedbackCommentPopup = (props) => {
  const [modalStyle] = useState(getModalStyle);

  const [currentComment, setCurrentComment] = useState('');

  useEffect(() => {
    setCurrentComment(props.comment);
  }, [props.comment]); 

  const handleChangeText = (event) => {
    setCurrentComment(event.target.value);
  };

  const classes = useStyles();

  let textAreaStyle = { height: '100vh', width: '100%', padding: 10, margin: '10px' };  

  return (
    <div style={{ "display": "inline-block" }}>
      
      <Dialog open={props.open} id="feedbackPopup">

        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="PRESENTATION.NAVBAR.TITLE" />
        </DialogTitle>

        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <textarea value={currentComment} onChange={handleChangeText} style={textAreaStyle} maxLength={1000}/>
          </div>
        </DialogContent>

        <DialogActions>

          <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.LIKE" />}>
              { props.currentVote == 1 ?
                  <IconButton edge="start" className={classes.menuButton} color='primary' aria-label="next" onClick={() => { props.voteSlide(1) }}>
                      <ThumbUpIcon />
                  </IconButton>
                  :
                  <IconButton edge="start" className={classes.menuButton} color='primary' aria-label="next" onClick={() => { props.voteSlide(1) }}>
                      <ThumbUpOutlinedIcon />
                  </IconButton>
              }
          </Tooltip>
          
          <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.DISLIKE" />}>
              { props.currentVote == -1 ?
                  <IconButton edge="start" className={classes.menuButton} color='primary' aria-label="next" onClick={() => { props.voteSlide(-1) }}>
                      <ThumbDownIcon />
                  </IconButton>
                  :
                  <IconButton edge="start" className={classes.menuButton} color='primary' aria-label="next" onClick={() => { props.voteSlide(-1) }}>
                      <ThumbDownOutlinedIcon />
                  </IconButton>
              }
          </Tooltip>

          <Button onClick={ () => props.saveComment(currentComment) } color='primary'>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(FeedbackCommentPopup);