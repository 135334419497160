import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { isMobile, isIPad13, isIPhone13, isTablet } from 'react-device-detect';

const FilesViewInContainer = ({
    open,
    closeFilesViewInContainer,
    item
  }) => {

  const theme = useTheme();

  const handleClose = () => {
    closeFilesViewInContainer();
  };

  const events = ['pause', 'play', 'ended', 'volumechange', 'timeupdate', 'seeked'];

  let videoJsOptions = {};

  const videoPlayerLog = (result) => {

  };

  const onVideoReady = (eventData) => {                    
    
  };

  const onVideoDisposed = (eventData) => {
   
  };

  switch(item.mimeType){
    case 'application/pdf':
      
      break;
    case 'video/mp4':
       videoJsOptions = {
            fuid: true,
            fluid: true,
            fill: false,
            autoplay: false,
            controls: true,
            responsive: true,
            sources: [{
                src: item.url
        }]
       }
      break;
    default:
      
  }

  return (
    <React.Fragment>
    
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={closeFilesViewInContainer}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{item.name}</DialogTitle>
      
      <DialogContent>
        {item.mimeType == 'application/pdf' ?
        <iframe
            id="inlineFrame"
            title={item.name}
            src={`https://docs.google.com/viewer?url=${item.url}&embedded=true`}
            scrolling="no"
            width='100%'
            height={!isIPad13 && !isTablet && (isMobile || isIPhone13) ? '150px' : '500px'}
            allow="autoplay; fullscreen">
        </iframe>
        :
        <div style={{width: "83%", marginLeft: "8%"}}>
          <VideoPlayer events={events} logdelay={10000} onVideoReady={onVideoReady} onVideoDisposed={onVideoDisposed} onlog={videoPlayerLog} { ...videoJsOptions }/>
        </div> }
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            <FormattedMessage id="CALL.CANCEL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
  );
}

export default connect(
    null,
    {...presentationBarStorage.actions, ...loggerStorage.actions}
)(FilesViewInContainer);