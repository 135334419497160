import axios from "axios";
import {configUrls} from '../../globalConfig';

export const GET_USERGROUPS_BY_STATE_ID = `${configUrls.base_url}/api/usergroup?state_id=`;
export const CREATE_USERGROUP = `${configUrls.base_url}/api/usergroup/create`; 
export const GET_USERGROUP_ATRIBUTE = `${configUrls.base_url}/api/useratribute/0/1`;
export const USERGROUP_ASSIGN_BYATRIBUTE = `${configUrls.base_url}/api/usergroup/assign/byAtribute`; 


export function getUserGroupAtribute(param){
  return axios.get(GET_USERGROUP_ATRIBUTE);
}

export function getUserGroupsByStateId(type){
  return axios.get(GET_USERGROUPS_BY_STATE_ID + type);
}

export function createUserGroup(payload){
  return axios.post(CREATE_USERGROUP, {
    name: payload.name,
    externalCode: payload.externalCode,
    allow_guest: payload.allowGuest,
    atributeIndex: 1,
    atributeList: payload.atributeList.toString()
  });
}

export function assignByAtribute(payload){
  return axios.put(USERGROUP_ASSIGN_BYATRIBUTE, {
    codGroup: payload.codGroup,
    clear: payload.clear,
    insert: payload.insert,
    codEntity: 0,
    atributeIndex: 1,
    atributeList: payload.atributeList.toString()
  })
}