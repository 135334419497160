import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EmailIcon from '@material-ui/icons/Email';
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const ActionSelector = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return(
    <StyledMenu
      id={props.uuid}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        activar
      </MenuItem>
      <MenuItem onClick={handleClose} className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
        desactivar
      </MenuItem>
    </StyledMenu>
  )
  
}

export default ActionSelector;