import React, { Component } from "react";
import { connect } from "react-redux";
import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';
import PresentationBar from '../PresentationBar';
import {getActionsOutside, stopPresentation} from '../PresentationBar/PresentationActions';
import { injectIntl } from "react-intl";
import './VideoChat.scss';
import LobbyMask from '../LobbyMask';
import Presentation from '../Presentation';
import CancelCall from './CancelCall';
import ReloadCall from './ReloadCall';
import Warning from './Warning';
import Button from '@material-ui/core/Button';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fullscreen from "react-full-screen";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert';
import {
  isMobile, 
  osVersion, 
  osName, 
  browserVersion, 
  browserName, 
  mobileVendor,
  mobileModel, 
  getUA, 
  deviceType,
  isIPad13, 
  isIOS, 
  isIPhone13, 
  isTablet
} from 'react-device-detect';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Chat from '../Chat';
import { version } from '../../../globalConfig';
import CallTime from '../CallTime';
import FabMenu from '../FabMenu';
import CallNotes from '../CallNotes';
import {containerClasses} from './containerClasses';
import {customAlert} from './customAlert';
import {otCoreOptions} from './otCoreOptions';
import {
  getDeploymentParamValue,
  getDeviceInfo,
  getPublisherProps,
  replaceSpecialChars,
  replaceTags,
  trackException
} from './utils';
import FeedBackPopUp from '../FeedBackPopUp';
import FileDrawer from '../FileDrawer';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as MousePointer } from '../Presentation/assets/mousepointer.svg';
/***************************************** */
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import * as authStorage from "../../store/ducks/auth.duck";
/***************************************** */
import initLayoutContainer from 'opentok-layout-js';
import { appInsights } from '../../../TelemetryService';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { trackApp } from '../../../globalConfig';
import ProductsCallDialog from '../ProductsCallDialog';
import MoreVertexMenu from '../MoreVertexMenu';

import CustomerRequestDialog from '../CustomerRequestDialog';

import goBackHandler from "../../router/HistoryHandler";
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

let otCore;
let videoPublisher;
let autojoin = new URLSearchParams(window.location.search).get("autojoin");
let localvideo = new URLSearchParams(window.location.search).get("localvideo");
let localaudio = new URLSearchParams(window.location.search).get("localaudio");
let disablePublish = new URLSearchParams(window.location.search).get("disablePublish");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class VideoChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      stateButtonStartCall: 'buttonClickeable',
      disabledStartCallButton: false,
      sharingPresentation: false,
      getPresentationObject: null,
      imgContact: 'default.jpg',
      cancelCallDialog: false,
      reloadCallDialog: false,
      organizerNotConnected: false,
      logout: false,
      tryAgainButton: false,
      otErrCode: '',
      otErrMsg: '',
      signOutClick: false,
      timeWarningTitle: '',
      timeWarningText: '',
      timeWarningButtonText: '',
      showTimeWarning: false,
      currentSubscribers: [],
      isClickable: false,
      showFeedbackModal: false,
      callEnded: false,
      isFull: false,
      openMobileFullScreenAlert: false,
      vaidActive: false,
      publishersView: [],
      suscribersView: [],
      camerasView: [],
      participants: 0,
      rawStreamsArr: [],
      snackSyncVaid: false,
      isExpanded: true,
      positionX: 0,
      positionY: 0,
      showMouseMove: false,
      mouseMoveCounterOrg: 0,
      mouseMoveCounterSubs: 0,
      mouseMoveEvent: null,
      connectionIdFrom: null,
      snackRequest: {
        open: false,
        severity: '',
        msg: ''
      },
      confirmGoBackOpen: false,
      isBlurEnabled: false,
      isImageEnabled: false,
      imageSelected: ""
    };
    this.layout= null;

    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.togglePresentation = this.togglePresentation.bind(this);
    this.changeCallStatus = this.changeCallStatus.bind(this);
    this.syncSdva = this.syncSdva.bind(this);
    this.reloadCall = this.reloadCall.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.endCallWithoutRedirect = this.endCallWithoutRedirect.bind(this);
    this.onHideMovie = this.onHideMovie.bind(this);
    this.onVideoReady = this.onVideoReady.bind(this);
    this.onVideoDisposed = this.onVideoDisposed.bind(this);
    this.changeStateCamerasDisplay = this.changeStateCamerasDisplay.bind(this);
    this.initLayout = this.initLayout.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.changeIsFull = this.changeIsFull.bind(this);
    this.sendCoordinates = this.sendCoordinates.bind(this);
    this.stopMouseMove = this.stopMouseMove.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.startSharingScreen = this.startSharingScreen.bind(this);
    this.startWithAutoJoin = this.startWithAutoJoin.bind(this);
    this.onConfirm_Close = this.onConfirm_Close.bind(this);
    this.onConfirm_GoBack = this.onConfirm_GoBack.bind(this);
    this.addOverlay = this.addOverlay.bind(this);
    this.startCameraBlur = this.startCameraBlur.bind(this);
    this.stopCameraBlur = this.stopCameraBlur.bind(this);
    this.startCameraImage = this.startCameraImage.bind(this);
    this.stopCameraImage = this.stopCameraImage.bind(this);
    this.initializeCameraVideoFilter = this.initializeCameraVideoFilter.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    try{
      if (this.props.presentationBar.callTimeSeconds !== prevProps.presentationBar.callTimeSeconds) {
        Object.assign(this.props.presentationBar, { ...this.props.presentationBar, callTimeSeconds: this.props.presentationBar.callTimeSeconds });
      }
      if(prevState.active === false && this.state.active === true){
        setTimeout(() => {
          this.layout();
        }, 200)
      }

      if(this.state.showMouseMove && this.state.isFull){
        this.props.presentationBar.videoManager.session.signal({
          type: 'STOP_MOUSE_MOVE'
        }, (err) => {
          if(err){
            console.log(err);
          }
        });
      }
      
      /**
       * 
       *      
       */
      if(prevProps.sharingScreen != this.props.sharingScreen){
        if(this.props.sharingScreen){
          let camerasView = this.state.camerasView;
          camerasView.map((camera) => {
            let el = null;
            el = document.getElementById(camera.id);
            
            el.classList.remove('OT_ignore');
            if(camera.videoType === 'screen'){
              el.classList.add('hidden');
            }
          });
        }
      }

      if(prevProps.displayScreenPresentation != this.props.displayScreenPresentation)
      {
        let el = null;
        let camerasView = this.state.camerasView;

        if(this.props.displayScreenPresentation === true)
        {
          // si estaba transmitiendo pantalla compartida debe dejar de hacerlo
          if(this.state.sharingScreen){
            otCore.screenSharing.end();
          }

          camerasView.map((camera) => {
            let el = null;
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer')
            }else{
              el = document.getElementById(camera.id);
            }
            el.classList.remove('OT_ignore');
            if(camera.isBig === true){
              camera.isBig = false;
              camera.isSmall = true;
              el.classList.remove('OT_bigger');
              el.classList.add('small');
            }
            if(lowerThanTablet){
              el.classList.add('hidden');
            }
          });
        }
        else
        {
          let camerasView = this.state.camerasView;
          let el = null;
          camerasView.map((camera) => {
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer');
            }else{
              el = document.getElementById(camera.id);
            }
            camera.isReadOnly = false;
            if(el){
              el.classList.remove('hidden');
            }
          })
  
          if(this.state.camerasView.length == 2)
          {
            camerasView.map((camera) => {
              let el = null;
              if(camera.isPublisher === true){
                el = document.getElementById('publisherContainer')
              }else{
                el = document.getElementById(camera.id);
              }
              camera.isBig = false;
              camera.isSmall = false;
              el.classList.remove('OT_bigger');
              el.classList.remove('OT_ignore');
              el.classList.remove('small');
            })
          }
          else
          {
            if(this.props.auth.role === 'subscriber')
            {
              camerasView.map((camera) => {
                let el = null;
                if(camera.isPublisher === true){
                  el = document.getElementById('publisherContainer')
                }else{
                  el = document.getElementById(camera.id);
                }
                if(camera.isOrganizer === true){
                  camera.isBig = true;
                  el.classList.add('OT_bigger')
                }
              })
            }
            else
            {
              camerasView.map((camera) => {
                let el = null;
                if(camera.isPublisher === true){
                  el = document.getElementById('publisherContainer')
                }else{
                  el = document.getElementById(camera.id);
                }
                if(camera.isSuscriber === true){
                  camera.isBig = true;
                  el.classList.add('OT_bigger')
                }
              })
            }
          }
  
          if(lowerThanTablet)
          {
            camerasView.forEach((camera) => {
              let el = null;
              if(camera.isPublisher === true){
                el = document.getElementById('publisherContainer')
              }else{
                el = document.getElementById(camera.id);
              }
              el.classList.remove('hidden');
            })
          }
  
        }
        this.setState({camerasView: camerasView})
  
        setTimeout(() => {
          this.layout()
          camerasView.forEach((camera) => {
            
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer');
            }else{
              el = document.getElementById(camera.id);
            }
  
            if(el){
              if(this.props.displayScreenPresentation === true){
                el.style.pointerEvents = 'none';
              }else{
                el.style.pointerEvents = 'auto';
              }

            }
          })
        }, 200)
      }

      if(this.state.active && 
        this.state.meta && 
        prevState.meta && 
        this.state.meta.publisher &&
        prevState.meta.publisher &&
        this.state.meta.publisher.screen != prevState.meta.publisher.screen
      ){
        setTimeout(() => {
          this.layout()
          let el = null;
          let camerasView = this.state.camerasView;
          camerasView.forEach((camera) => {
            if(camera){
              if(camera.isPublisher === true){
                el = document.getElementById('publisherContainer');
              }else{
                el = document.getElementById(camera.id);
              }
            }
  
            if(el){
              if(
                this.state.meta.publisher &&
                this.state.meta.publisher.screen > 0){
                el.style.pointerEvents = 'none';
              }else{
                el.style.pointerEvents = 'auto';
              }
            }
          })
        }, 0)
      }
      
      if(this.state.camerasView != prevState.camerasView){
        let el = null;
        if(this.state.camerasView.length == 2){
          this.state.camerasView.forEach((camera) => {
            // decidimos si la clase se aplica al div del video o al div del publisherContainer
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer');
            }else{
              el = document.getElementById(camera.id);
            }
    
            if(camera.isBig === true){
              el.classList.add('OT_bigger');
            }else{
              el.classList.remove('OT_bigger')
            }
    
            if(camera.isSmall === true){
              el.classList.add('OT_ignore');
              el.classList.add('small');
      
              el.style.position = "absolute";
              el.style.left = "60px";
              el.style.top = "60px";
              el.style.width = "120px";
              el.style.height = "120px";
              el.style.zIndex = "4";
            }else{
              el.classList.remove('OT_ignore')
              el.classList.remove('small')
              el.style.zIndex = "0";
            }
          })
    
          setTimeout(() => {
            this.layout();
          }, 200);
  
        }
        if(this.state.camerasView.length > 2 && 
          !this.props.displayScreenPresentation && 
          !this.state.meta.subscriber.screen &&
          !this.state.sharingScreen
          ){
          let camerasView = this.state.camerasView
          camerasView.forEach((camera) => {
            let cameraDOMElement = null;
            if(camera.isPublisher){
              cameraDOMElement = document.getElementById('publisherContainer');
            }else{
              cameraDOMElement = document.getElementById(camera.id);
            }
            if(camera.isBig === true){
              cameraDOMElement.classList.add('OT_bigger');
            }else{
              cameraDOMElement.classList.remove('OT_bigger');
            }
          });
          setTimeout(() => {
            this.layout();
          }, 200);
        }
      }
  
      if(this.state.participants != prevState.participants){
        let camerasQuantity = this.state.participants;
        let camerasView = this.state.camerasView;
  
        camerasView.map((camera) => {
          let cameraDOMElement = null;
          if(camera.isPublisher){
            cameraDOMElement = document.getElementById('publisherContainer');
          }else{
            cameraDOMElement = document.getElementById(camera.id);
          }
          if(camera.isReadOnly){
            cameraDOMElement.style.pointerEvents = 'none';
          }
        })
  
        if(this.state.participants == 1){
            let el = document.getElementById('publisherContainer');
            el.classList.remove('OT_bigger');
            el.classList.remove('OT_ignore');
            el.classList.remove('small');
          setTimeout(() => {
            this.layout();
          }, 200);
        }
        if(prevState.participants > 2 && this.state.participants === 2){
          camerasView.map((camera) => {
            let cameraDOMElement = null;
            if(camera.isPublisher){
              cameraDOMElement = document.getElementById('publisherContainer');
            }else{
              cameraDOMElement = document.getElementById(camera.id);
            }
            camera.isBig = false;
            camera.isSmall = false;
            cameraDOMElement.classList.remove('OT_bigger');
            cameraDOMElement.classList.remove('small');
            cameraDOMElement.classList.remove('OT_ignore');
          })
        }
        if(camerasQuantity > 2 && !this.props.displayScreenPresentation && !this.state.sharingScreen){
          camerasView.map((camera) => {
            let cameraDOMElement = null;
            if(camera.isPublisher){
              cameraDOMElement = document.getElementById('publisherContainer');
            }else{
              cameraDOMElement = document.getElementById(camera.id);
            }
            camera.isBig = false;
            camera.isSmall = false;
            cameraDOMElement.classList.remove('OT_bigger');
            cameraDOMElement.classList.remove('small');
            cameraDOMElement.classList.remove('OT_ignore');
          })
          if(this.props.auth.role === 'subscriber'){
            camerasView.map((camera) => {
              if(camera.isOrganizer === true){
                camera.isBig = true;
                document.getElementById(camera.id).classList.add('OT_bigger')
              }
            })
          }else{
            camerasView.map((camera) => {
              if(camera.isSuscriber === true){
                camera.isBig = true;
                document.getElementById(camera.id).classList.add('OT_bigger')
              }
            })
          }
          
          this.setState({camerasView: camerasView})
        }
  
      }

      if((this.state && this.state.meta && this.state.meta.subscriber && this.state.meta.subscriber.screen > 0) ||
        (this.state.subscribers && this.state.subscribers.custom && Object.keys(this.state.subscribers.custom).length >= 1)
      ){
        // si esta reproduciendo un custom tiene que dejar de mostrar presentacion comun
        stopPresentation();
        let el = null;
        let camerasView = this.state.camerasView;
        camerasView.map((camera) => {
          if(camera.isPublisher === true){
            el = document.getElementById('publisherContainer');
          }else{
            el = document.getElementById(camera.id);
          }
          if(camera.videoType === 'screen' || camera.videoType === 'custom'){
            camera.isBig = true;
            camera.isSmall = false;
            el.classList.remove('OT_ignore');
            el.classList.add('OT_bigger');
          }else{
            camera.isBig = false;
            camera.isSmall = true;
            el.classList.remove('OT_bigger');
            el.classList.remove('OT_ignore');
            el.classList.add('small');
          }
        });
      }else if(this.state && this.state.meta && this.state.meta.subscriber && this.state.meta.subscriber.screen == 0 && !this.props.displayScreenPresentation){
        let el = null;
        let camerasView = this.state.camerasView;
        if(this.props.auth.role === 'subscriber' && this.state.participants > 2)
        {
          camerasView.map((camera) => {
            let el = null;
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer')
            }else{
              el = document.getElementById(camera.id);
            }
            if(camera.isOrganizer === true){
              camera.isBig = true;
              el.classList.add('OT_bigger')
            }
          })
        }
        else if(this.state.participants > 2 && !this.state.sharingScreen)
        {
          camerasView.map((camera) => {
            let el = null;
            if(camera.isPublisher === true){
              el = document.getElementById('publisherContainer')
            }else{
              el = document.getElementById(camera.id);
            }
            if(camera.isSuscriber === true){
              camera.isBig = true;
              el.classList.add('OT_bigger')
            }
          })
        }
        else if(this.state.participants > 2 && this.state.sharingScreen)
        {
          camerasView.map((camera) => {
            let el = null;
            el = document.getElementById(camera.id);
            el.classList.remove('OT_ignore')
            if(camera.videoType === 'screen'){
              camera.isBig = true;
              camera.isSmall = false;
              el.classList.remove('small')
              el.classList.add('OT_bigger')
            }else{
              camera.isBig = false;
              camera.isSmall = true;
              el.classList.remove('OT_bigger')
              el.classList.add('small')
            }
          })
        }
      }
      /**
       * 
       * 
       */
    }catch(err){
      trackException(err)
    }
    
    if(this.state.active == false){
      let layoutContainer = document.getElementById('layoutContainer');
      let publisherContainer = document.getElementById('publisherContainer');
      if(layoutContainer){
        layoutContainer.style.bottom = "auto";
      }
      if(publisherContainer){
        publisherContainer.style.width = 0;
        publisherContainer.style.height = 0;
      }
    }
    if(this.props.presentationBar.callClosedResult === true){
      //ROLE:GUEST vuelve a registration al colgar la llamada manualmente
      if (this.props.auth.role === 'organizer' || this.props.auth.role === 'guest') 
      {
        //console.log(this.state.signOutClick, 'estado del signoutClick')
        if(!this.state.cancelCallDialog){
          if(this.state.signOutClick){
            setTimeout(() => {
              //console.log('meejecute logoutTrue')
              this.setState({logout: true});
            }, 1000);
          }else{
            setTimeout(() => {
              this.props.nullAuthToken();
              setTimeout(() => {
                window.location.href = "/auth/registration";
              }, 100);
            }, 2000);
          }
        }
      }
      else
      {
          this.props.requestCallData(this.props.global.auth.deployment.callData.code);
          setTimeout(() => {
            if (this.props.global.auth.deployment.callData.status == 2) 
            {
              this.setState({
                active: false,
                stateButtonStartCall: 'buttonClickeable',
                disabledStartCallButton: true
              });
            }
          }, 5000);
      }
    }

    if(this.state.active != prevState.active && disablePublish){
      otCore.session.streams.forEach(strm => {
        if(strm && strm.publisher){
          strm.publisher.publishAudio(false);
          strm.publisher.publishVideo(false);
        }
      });
    }

    this.addOverlay(this.state, this.props)

    const [ publisherCamera ] = this.state.camerasView?.filter(camera => camera.isPublisher === true)
    this.publisher = this.state?.publishers?.camera[publisherCamera?.id]
  }

  /*
  shouldComponentUpdate(nextProps, nextState){
    if(
      this.state.connected && 
      this.state.active &&
      isEqual(
        _.omit(this.props.global.presentationBar, ['callTimeSeconds']),
        _.omit(nextProps.global.presentationBar, ['callTimeSeconds'])
      )
    ){
      return false;
    }else{
      return true;
    }
  }
  */

  async startCameraBlur() {
    if(this.publisher) {
      if(!this.publisher.getVideoFilter()) {
        await this.publisher.applyVideoFilter({ type: 'backgroundBlur' })
        this.setState({...this.state, isBlurEnabled: true})
        this.props.log({
          logtype: 3,
          log_category: 'BLUR_ENABLED',
          log_text: 'Camera Blur is enabled'
        })
      }
    }
  }

  async stopCameraBlur() {
    if(this.publisher) {
      if(this.publisher.getVideoFilter()) {
        await this.publisher.clearVideoFilter()
        this.setState({...this.state, isBlurEnabled: false})
        this.props.log({
          logtype: 3,
          log_category: 'BLUR_DISABLED',
          log_text: 'Camera Blur is disabled'
        })
      }
    }
  }

  async startCameraImage(url) {
    if(this.publisher) {
      if(!this.publisher.getVideoFilter()) {
        await this.publisher.applyVideoFilter({ type: 'backgroundReplacement', backgroundImgUrl: url})
        this.setState({...this.state, isImageEnabled: true, imageSelected: url})
        this.props.log({
          logtype: 3,
          log_category: 'IMAGE_ENABLED',
          log_text: 'Image filter is enabled'
        })
      }
    }
  }

  async stopCameraImage() {
    if(this.publisher) {
      if(this.publisher.getVideoFilter()) {
        await this.publisher.clearVideoFilter()
        this.setState({...this.state, isImageEnabled: false, imageSelected: ""})
        this.props.log({
          logtype: 3,
          log_category: 'IMAGE_DISABLED',
          log_text: 'Image Filter is disabled'
        })
      }
    }
  }

  initializeCameraVideoFilter() {
    try {
      if(this.publisher) {
        const storageKey = `videoeffect-${this.props.global.auth.deploymentCode}-${this.props.global.auth.userid}`;
        const lastEffect = localStorage.getItem(storageKey);
        
        if(lastEffect){
          const cameraBlurEnabled = getDeploymentParamValue(this.props.deployment.parameters, 'PUBLISHER_FILTER_BLUR_ENABLED') === '1';
          const cameraBackgroundEnabled = getDeploymentParamValue(this.props.deployment.parameters, 'PUBLISHER_FILTER_BACKGROUND') !== '';
  
          if(lastEffect === 'blur' && cameraBlurEnabled) {
            this.startCameraBlur();
          } else if(cameraBackgroundEnabled) {
            const filterBackgrounds = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_FILTER_BACKGROUND")?.split(";");
            const isValidBackground = filterBackgrounds?.find(background => background === lastEffect);
  
            if(isValidBackground) {
              this.startCameraImage(lastEffect);
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  addOverlay(state, props) {
    const overlayUrl = getDeploymentParamValue(props.deployment.parameters, "OVERLAY_URL");
    const overlayBack = getDeploymentParamValue(props.deployment.parameters, "OVERLAY_BACK");
    const overlayCss = getDeploymentParamValue(props.deployment.parameters, "OVERLAY_CSS");
    
    //El overlay se genera si se tiene una url y por lo menos los estilos de la imagen
    if(overlayUrl && overlayUrl !== '' && overlayCss && overlayCss !== '') {
      if(state.camerasView.length > 0) {
        const alreadyExist = document.getElementById('overlay-img')
        //Se busca la camara del origanizador para agregar el overlay
        const organizerCamera = state.camerasView.find(camera => camera.isOrganizer === true)

        const organizerContainer = organizerCamera && document.getElementById(organizerCamera.id)
        
        if(!alreadyExist && organizerContainer) {
            if(props.auth.role === 'subscriber') {
              organizerContainer.style.cssText = 'position: relative;'
            }
          
            const noSelectableStyle = '-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; pointer-events: none;'
            const overPoster = 'z-index: 1000'

            if(overlayBack && overlayBack !== '' && overlayCss && overlayCss !== '') {
                //Si se oobtienen los dos estilos se crea un contenedor con la imagen para generar el overlay                       
                const div = document.createElement('div')
                div.id = 'overlay'
                div.style.cssText = overlayBack + noSelectableStyle + overPoster //'position: absolute; width: 100%; height: 20vw; max-height: 24%; background-color: white; bottom: 10%; text-align: center; display: flex; align-items: center; justify-content: center;'
     
                const img = document.createElement('img')
                img.id = 'overlay-img'
                img.src = overlayUrl //'https://closeupcalldev.blob.core.windows.net/publicassets/999/overlays/logo_euro_transparente_1024x255.png'
                img.style.cssText = overlayCss + noSelectableStyle //'width: auto; height: auto; max-width: 100%; max-height: 100%;'
                div.appendChild(img)

                organizerContainer.appendChild(div)
            } else if (overlayCss) {
                // Si solo se obtiene el estilo de la imagen no se genera el background
                const img = document.createElement('img')
                img.id = 'overlay-img'
                img.src = overlayUrl //'https://closeupcalldev.blob.core.windows.net/publicassets/999/overlays/logo_euro_transparente_1024x255.png'
                img.style.cssText = overlayCss + noSelectableStyle + overPoster//'position: absolute; width: 50%; height: auto; max-height: 24%; white; bottom: 10%; margin-left: auto;margin-right: auto;left: 0;right: 0;'

                organizerContainer.appendChild(img)
            }
        }
      }  
    }
  }

  initLayout() {
          /**
       * 
       */
      var layoutContainer = document.getElementById("layoutContainer");

      // Initialize the layout container and get a reference to the layout method
      //console.log(initLayoutContainer)

      const optionsLayout = {
          maxRatio: 3/2,             // The narrowest ratio that will be used (default 2x3)
          minRatio: 9/16,            // The widest ratio that will be used (default 16x9)
          fixedRatio: false,         // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
          alignItems: 'center',      // Can be 'start', 'center' or 'end'. Determines where to place items when on a row or column that is not full
          bigClass: "OT_bigger",        // The class to add to elements that should be sized bigger
          bigPercentage: 0.8,         // The maximum percentage of space the big ones should take up
          bigFixedRatio: false,      // fixedRatio for the big ones
          bigAlignItems: 'center',   // How to align the big items
          smallAlignItems: 'center', // How to align the small row or column of items if there is a big one
          maxWidth: Infinity,        // The maximum width of the elements 
          maxHeight: Infinity,       // The maximum height of the elements
          smallMaxWidth: Infinity,   // The maximum width of the small elements 
          smallMaxHeight: Infinity,  // The maximum height of the small elements
          bigMaxWidth: Infinity,     // The maximum width of the big elements
          bigMaxHeight: Infinity,    // The maximum height of the big elements
          bigMaxRatio: 3/2,          // The narrowest ratio to use for the big elements (default 2x3)
          bigMinRatio: 9/16,         // The widest ratio to use for the big elements (default 16x9)
          bigFirst: false,            // Whether to place the big one in the top left (true) or bottom right (false). 
                                    // You can also pass 'column' or 'row' to change whether big is first when you are in a row (bottom) or a column (right) layout
          //animate: true,             // Whether you want to animate the transitions
          window: window,            // Lets you pass in your own window object which should be the same window that the element is in
          ignoreClass: 'OT_ignore',  // Elements with this class will be ignored and not positioned. This lets you do things like picture-in-picture
      };

      this.layout = initLayoutContainer(layoutContainer, optionsLayout).layout;
  }

  changeStateCamerasDisplay(id){
    
    /**
     * Este metodo lo que hace es cambiar el estado de las camaras
     * asi lo detecta el metodo didupdate.
     * Este metodo tiene el control del estado y de las reglas de layout
     */
    let cameraToChange = null;
    let counter = 0;
    let brotherElements = [];
    //console.log(this.state.camerasView)
    this.state.camerasView.forEach((camera, index) => {
      //console.log(camera.id, id)
      if(camera){
        if(camera.id === id){
          //console.log('entre al', camera.id, id)
          cameraToChange = camera;
          counter = index;
        }else{
          brotherElements.push(camera);
        }
      }
    });


    // si la llamada tiene solo 2 participantes
    // los pone picture and picture
    if(this.state.camerasView.length === 2){
      let brotherElement = brotherElements[0];
      if(cameraToChange.isBig === true){
        Object.assign(
          cameraToChange, 
          {
            isBig: false,
            isSmall: false,
          }
        );
        Object.assign(
          brotherElement,
          {
            isBig: false,
            isSmall: false,
          }
        )
      }else{
        Object.assign(
          cameraToChange, 
          {
            isBig: true,
            isSmall: false,
          }
        );
        Object.assign(
          brotherElement,
          {
            isBig: false,
            isSmall: true,
          }
        )
      }
      
      this.setState({ camerasView: [{...cameraToChange},{...brotherElement}] })

    }

    if(this.state.camerasView.length > 2){
      cameraToChange.isBig = !cameraToChange.isBig;
      
      this.setState({camerasView: [{ ...cameraToChange}, ...brotherElements ]})
    }

  }

  componentDidMount() {
    let self = this;

    const { intl } = this.props;
    let tapedTwice = false;

    goBackHandler(function(value){
      if(!self.state.connected || !self.state.active){
        return;
      }

      if(self.props.auth.role === 'organizer'){
        self.changeCallStatus(true);
      }else{
        self.setState({ confirmGoBackOpen: value });
      }
    });

    if (this.props.deployment.callData != null && this.props.deployment.callData.isError == false && 
      (this.props.deployment.callData.OT_TOKEN_ID && this.props.deployment.callData.OT_TOKEN_ID != '')
    ) {
      if(this.props.deployment.callData.servertimeUTC){ 
        let serverTimeUtc = moment.utc(this.props.deployment.callData.servertimeUTC);
        let browserTimeUtc = moment.utc();
        let formatedServerTimeUtc = serverTimeUtc.format("YYYY-MM-DD HH.mm.ss");
        let offset = browserTimeUtc.diff(serverTimeUtc,'seconds');
        let storageItem = undefined;
        let browserId;
        if (typeof(localStorage) !== "undefined") {  
          try{
            storageItem = localStorage.getItem('browserid');
          }catch(e){
            console.log(e)
          }
        }
        if(storageItem != undefined)
        {
          browserId = JSON.parse(storageItem);
        }else{
          browserId = Math.floor(Math.random() * 1000000000);
          localStorage.setItem('browserid', browserId);
        }

        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_LOBBY',
          log_text: 'servertimeUTC: ' + formatedServerTimeUtc + 
                ' | offset: ' + offset + ' | '  + getDeviceInfo() + 
                ' | browserid: ' + browserId + ' | version: '  + version
        })
      }
      otCoreOptions.credentials.apiKey = this.props.deployment.callData.OT_API_KEY;
      otCoreOptions.credentials.sessionId = this.props.deployment.callData.OT_SESSION_ID;
      otCoreOptions.credentials.token = this.props.deployment.callData.OT_TOKEN_ID;
      
      let fitMode = "cover";
      if(this.props.deployment.parameters){
        fitMode = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_FITMODE");
        if(fitMode && fitMode != ""){
          otCoreOptions.communication.callProperties.fitMode = fitMode;
        }
        let callGuestQty = getDeploymentParamValue(this.props.deployment.parameters, "CALL_GUEST_QTY");
        if(callGuestQty && callGuestQty != ""){
          otCoreOptions.communication.connectionLimit = otCoreOptions.communication.connectionLimit + parseInt(callGuestQty);
        }
        let noiseSuppression = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_NOISE_SUPPRESSION");
        if(noiseSuppression == "1"){
          otCoreOptions.communication.disableAudioProcessing = false;
          otCoreOptions.communication.noiseSuppression = true;
        }
      }
      if(this.props.auth.name && this.props.auth.name != ''){
        otCoreOptions.textChat.name = this.props.auth.name;
        otCoreOptions.communication.callProperties.name = this.props.auth.name;
        otCoreOptions.communication.callProperties.showControls = true;
        otCoreOptions.communication.callProperties.style = {   
          nameDisplayMode: "auto", 
          audioBlockedDisplayMode: "auto",
          audioLevelDisplayMode: "auto",  
          archiveStatusDisplayMode: "off"   
        };
        if(this.props.deployment.parameters)
        {
          let backgroundImage = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_BACKGROUND_IMAGE");      
          if(backgroundImage && backgroundImage != "") {
            otCoreOptions.communication.callProperties.style.backgroundImageURI = backgroundImage;
          }
          
          //Icono de recording
          if (this.props.auth.role === 'organizer' || this.props.auth.role === 'guest') 
          {
            let paramPUBLISHER_SHOW_ARCHIVING = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_SHOW_ARCHIVING");
            
            if(paramPUBLISHER_SHOW_ARCHIVING && paramPUBLISHER_SHOW_ARCHIVING != "" && paramPUBLISHER_SHOW_ARCHIVING=="1") 
            {
              otCoreOptions.communication.callProperties.style.archiveStatusDisplayMode = "auto";
            }
          }
          else
          {
            let paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_SHOW_ARCHIVING_CUSTOMER");
            
            if(paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER && paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER != "" && paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER=="1") 
            {
              otCoreOptions.communication.callProperties.style.archiveStatusDisplayMode = "auto";
            }
          }
        }
      } 
      otCore = new AccCore(otCoreOptions);


      this.initLayout();

      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.layout();
         
          if (this.state.showMouseMove && this.props.auth.role != 'organizer' && this.state.mouseMoveEvent) {
            this.onMouseMove(this.state.mouseMoveEvent, true);
          }
          
        }, 200);
      });
      
      otCore.on("streamCreated", (event) => {
        let subscribeObject = null;
        let that = this; 
        if(!event.stream.videoType){
          event.stream.videoType = 'camera';
        }
        if(this.state.connected && this.state.active === true){ // si la llamada esta en curso
          otCore.subscribe(event.stream, "layoutContainer", {
            insertMode: "append"
          }).then((res) =>{
            subscribeObject = res.id
            let cameraConnectionId = res.stream.connection.data.split('|')[1];      
            let camerasView = this.state.camerasView || [];
            
            document.getElementById(res.id).removeEventListener('dblclick', function(e) {
              //console.log('evento removido 2')
            });
            document.getElementById(res.id).addEventListener('dblclick', function(e) {
              //that.toggleCamerasDisplay(res.id);
              //res.element.outerText = res.stream.name+"\n";
              that.changeStateCamerasDisplay(res.id)
            });
            if(isMobile){

              document.getElementById(res.id).addEventListener('touchstart', function(e) {
                if(!tapedTwice) {
                  tapedTwice = true;
                  setTimeout( function() { tapedTwice = false; }, 300 );
                  return false;
                }
                //event.preventDefault();
                //that.toggleCamerasDisplay(res.id);
                //res.element.outerText = res.stream.name+"\n";
                that.changeStateCamerasDisplay(res.id);
                
              });
            }
      
            document.getElementById(res.id).addEventListener('mouseover', function(e) {
              if(res.element && res.element.children && res.element.children[2]){
                res.element.children[2].innerHtml = res.stream.name;
                res.element.children[2].innerText = res.stream.name;
              }
            });
      
            let isOrganizer = cameraConnectionId === this.props.deployment.callData.organizerId;
            let isSuscriber = cameraConnectionId === this.props.deployment.callData.subscriberId;
            let isGuest = cameraConnectionId === this.props.deployment.callData.guestId;


            //console.log(res.stream) // res.stream.videoType
            //console.log(subscribeObject)
            camerasView.push({
              id: subscribeObject,
              isBig: false || (res.stream.videoType === 'screen' || res.stream.videoType === 'custom'),
              isSmall: false,
              isReadOnly: this.props.displayScreenPresentation,
              isPublisher: false,
              isOrganizer: isOrganizer,
              isSuscriber: isSuscriber,
              isGuest: isGuest,
              videoType: res.stream.videoType
            });          
            
            this.setState({camerasView, participants: camerasView.length})
            setTimeout(() => {
              this.layout();
            }, 200)
      
          }).catch((err) => {
            console.log(err)
          })
        }else{ // sino agrega el stream a un array de streams para conectarse en el startCall
          this.setState({rawStreamsArr: [...this.state.rawStreamsArr, event]})
        }
      });

      otCore.on("streamDestroyed", (event) => {
        let newRawStreamsArr = this.state.rawStreamsArr.filter((eventRaw) => eventRaw.stream.id !== event.stream.id)
        
        let otCoreState = otCore.state();
        
        let counter = 0;
        let cameraFiltered = [];
        Object.values(otCoreState.streamMap).forEach((cameraState) => {
          cameraFiltered.push(this.state.camerasView.filter((item) => {
            //console.log(item)
            return item.id === cameraState
          })[0])
        })

        this.setState({ camerasView: cameraFiltered, participants: cameraFiltered.length, rawStreamsArr: newRawStreamsArr })
        setTimeout(() => {
          this.layout();
        }, 200)
      });
      
      otCore.connect().then(() => {
        this.setState({ connected: true });
        //this.props.getProductsByCall(this.state.callcode);
        //this.layout();
      }).catch((err) => {
        if(err && err.code && err.message){
          let otErrorConnectText = 'source: OT_CONNECT | ' + 'CODE: ' + err.code + ' | DESC: ' + replaceSpecialChars(err.message);
          this.props.log({
            logtype: 0,
            log_category: 'CALL_ERROR',
            log_text: otErrorConnectText
          })
          this.props.flush();
        }
        trackException("exception event. \n  code == " + err.code + "\n  message == " + err.message);
        this.setState({ reloadCallDialog: true, otErrCode: err.code, otErrMsg: err.message, connected: false });
      });

      const events = [
        'subscribeToCamera',
        'unsubscribeFromCamera',
        'subscribeToScreen',
        'unsubscribeFromScreen',
        'startScreenShare',
        'endScreenShare',
        'messageSent'
      ];

      events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta });
        let logType = '';
        let logText = '';
        switch (event) {
          case 'endScreenShare':
            logType = 'UI_SCREENSHARE_END';
            //console.log(publishers)
            //console.log(this.state)
            let newCamerasView = this.state.camerasView.filter((item) => {
              return item.videoType !== 'screen'
            });
            this.setState({camerasView: newCamerasView, participants: newCamerasView.length, sharingScreen: false})
            break;
          case 'startScreenShare':
            logType = 'UI_SCREENSHARE_START';
            let idScreenCamera = Object.keys(publishers.screen)[0];
            let cameraDOMElement = document.getElementById(idScreenCamera);
            let layoutContainerDOMElement = document.getElementById('layoutContainer');
            layoutContainerDOMElement.appendChild(cameraDOMElement);
            //console.log(this.state)
            let camerasView = this.state.camerasView || [];
            camerasView.push({
              id: idScreenCamera,
              isBig: true,
              isSmall: false,
              isReadOnly: true,
              isPublisher: true,
              isOrganizer: true,
              isSuscriber: false,
              isGuest: false,
              videoType: 'screen'
            });
            //ademas de setear la camara extra para el escritorio compartido tiene que frenar presentacion si es que esta mostrando una 
            if(this.props.displayScreenPresentation){
              //stopPresentation('sharingScreen');

            }
            
            this.setState({camerasView, participants: camerasView.length, sharingScreen: true})
            break;
          case 'subscribeToCamera':
            if (this.props.auth.role == 'organizer') {
              this.syncVaid();
            }
            this.subscribeEventLog(subscribers, 'CALL_SUBSCRIBE_CAM');
            break;
          case 'unsubscribeFromCamera':
            this.unsubscribeEventLog(subscribers, 'CALL_UNSUBSCRIBE_CAM');
            break;
          case 'subscribeToScreen':
            this.subscribeEventLog(subscribers, 'CALL_SUBSCRIBE_SCREEN');
            break;
          case 'unsubscribeFromScreen':
            this.unsubscribeEventLog(subscribers, 'CALL_UNSUBSCRIBE_SCREEN');
            break;
          default:
            break;
        }
        if (event != 'messageSent' && logType != '') {
          
          this.props.log({
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: logType,
            log_text: logText
          })
        }
      }));

      this.state.getPresentationObject = this.props.getPresentationObject;
      let getPresentationObject = this.state.getPresentationObject;
      otCore.on('messageSent', (event) => {
        let message = event.message;
        message = message.replace(/\./g, '');
        message = message.replace(/\,/g, '');
        message = message.replace(/\r?\n|\r/, " ");
        this.props.chatLog({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'SEND',
          log_text: message
        });
        this.props.flushChat();
      });
      otCore.on("streamPropertyChanged", (event) => {
        if(event.changedProperty === "hasAudio"){
          if(event.stream.connection.connectionId === otCore.session.connection.connectionId){
            this.setState({localAudioEnabled: event.newValue})
          }
        }
      })
      /*
      otCore.session.on('signal:FILE_ENABLE ', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          let data = JSON.parse(event.data);
          let filesArr = [];

          if(data.length > 0){
            data.forEach((listItem) => {
              if(filesArr[listItem.folder]){
                filesArr[listItem.folder].push(listItem);
              }else{
                filesArr[listItem.folder] = [];
                filesArr[listItem.folder].push(listItem);
              }
            })
          }
          this.props.setFileList(filesArr);
          Object.assign(this.props.presentationBar, { ...this.props.presentationBar, fileList: filesArr });
        }
      });
      */

      otCore.on("error", (event) => {
        if(event && event.code && event.name){
          let otOnErrorText = 'source: OT_ON_ERROR | ' + 'CODE: ' + event.code + ' | DESC: ' + replaceSpecialChars(event.name);
          this.props.log({
            logtype: 0,
            log_category: 'CALL_ERROR',
            log_text: otOnErrorText
          });
          this.props.flush();
        }
        this.setState({ reloadCallDialog: true, otErrCode: event.code, otErrMsg: event.name })
      });

      otCore.session.on("sessionDisconnected", (event) => {
        this.setState({ reloadCallDialog: true, otErrCode: 'sessionDisconnected', otErrMsg: event.reason })
      });

      otCore.session.on('signal:CHAT', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          let data = JSON.parse(event.data);
          
          this.props.recieveChatMessage(data);
        }
      });
      
      otCore.session.on('signal:CUSTOMER_REQUEST', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId && this.props.auth.role == 'subscriber') {
          let data = JSON.parse(event.data);

          if(data && data.cod_request){
            this.props.setCustomerRequestCode(data.cod_request);
            this.props.setCustomRequestDialog(true);
          }
        }

      });
      
      otCore.session.on('signal:CUSTOMER_REQUEST_REJECT', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          let data = JSON.parse(event.data);
          if(data && data.cod_request){
            if (this.props.auth.role == 'organizer') {
              this.setState({ snackRequest: {open: true , severity: 'error', msg: intl.formatMessage({id: 'PRODUCTS.CALL.DIALOG.REQUEST.ERROR'}, {requestcode: data.cod_request})}});
            }
            this.props.setCustomRequestDialog(false);
          }
        }
      });
      
      otCore.session.on('signal:CUSTOMER_REQUEST_ACCEPT', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          let data = JSON.parse(event.data);
          if(data && data.cod_request){
            if (this.props.auth.role == 'organizer') {
              this.setState({ snackRequest: {open: true , severity: 'success', msg: intl.formatMessage({id: 'PRODUCTS.CALL.DIALOG.REQUEST.SUCCESS'}, {requestcode: data.cod_request})}});
            }
            this.props.setCustomRequestDialog(false);
          }
        }
      });

      otCore.session.on('signal:CALL_END', (event) => {
        if (this.props.auth.role == 'subscriber' || this.props.auth.role == 'guest') {
          if(this.state.isFull)
            this.setState({ isFull: false });
          let messageAlert = intl.formatMessage({
            id: "CALL.END"
          });

          customAlert(messageAlert);

          if (this.props.presentationBar && this.props.presentationBar.displayScreenPresentation) {
            this.props.log({
              logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
              log_category: 'VISUALAID_END',
              log_text: 'reason: forced'
            })
          }
          // si el subscriber tiene habilitado el feedback no finaliza la llamada todavía
          // le cedemos la funcion de finalizar la llamada al modal de feedback 
          let haveFeedbackEnabled = getDeploymentParamValue(this.props.deployment.parameters, 'CALL_FEEDBACK_ENABLE');
          this.endCall();

          //ROLE:GUEST Recibe CALL_END pero no tiene feedback
          if(haveFeedbackEnabled === '1' && this.props.auth.role == 'subscriber'){
            this.setState({showFeedbackModal: true})
          }else{
            this.setState({logout: true});
          }

        }
      });

      if (this.props.auth.role == 'organizer') {
        otCore.session.on('signal:SERVER_TIME_WARN', (event) => {
          if(event && event.data){
            let data = JSON.parse(event.data);
            let servertimeUTC = data.servertimeUTC;
            servertimeUTC = servertimeUTC.replace(/\:/g, '.');
            let timeRemaining = data.timeRemaining;
            let endDateUTC = moment(data.endDateUTC).add(-1 * this.props.auth.timezone, 'hours');
            endDateUTC = data.endDateUTC.replace(/\:/g, '.');

            let timeWarnTitle = intl.formatMessage({ id: "CALL.TIMEWARN.TITLE" });

            let timeWarnTimeRemaining = intl.formatMessage({ id: "CALL.TIMEWARN.TIMEREMAINING" }, {
              timeRemaining: timeRemaining
            });

            let timeWarnEndDateUtc = intl.formatMessage({ id: "CALL.TIMEWARN.ENDDATEUTC" }, {
              endDateUTC: endDateUTC
            });

            let timeWarnButton = intl.formatMessage({ id: "CALL.TIMEWARN.BUTTON.OK" });

            let newLine = "\r\n";
 
            let timeWarnAlert =  timeWarnTitle + newLine + timeWarnTimeRemaining + newLine + timeWarnEndDateUtc;
            
            this.props.log({
              logtype: 2,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
              log_category: 'SERVER_TIME_WARN',
              log_text: "servertimeUTC:" + servertimeUTC + " | timeRemaining:" + timeRemaining +" | endDateutc:" + endDateUTC
            })

            this.setState({ showTimeWarning: true,timeWarningTitle: timeWarnTitle, timeWarningText: timeWarnAlert, timeWarningButtonText: timeWarnButton, isFull: false });
            
          }
        });
      }

      if (this.props.auth.role != 'organizer') {
        otCore.session.on('signal:MOUSE_MOVE', (event) => {
          let data = JSON.parse(event.data);
          this.setState({ showMouseMove: true, mouseMoveEvent: data, connectionIdFrom: event.from.connectionId });
          this.onMouseMove(data);
        });
      }

      otCore.session.on('signal:STOP_MOUSE_MOVE', (event) => {
        this.setState({ showMouseMove: false, positionX: 0, positionY: 0, mouseMoveCounterOrg: 0, mouseMoveCounterSubs: 0, mouseMoveEvent: null, connectionIdFrom: null });

      });

      otCore.session.on('signal:VISUALAID_START', (event) => {
        this.setState({ isExpanded: false });
        

        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          if (!this.state.isFull && !this.state.vaidActive && (isMobile || isIPad13 || isIPhone13)) {
            this.setState({openMobileFullScreenAlert:true,vaidActive:true});
          }
          let data = JSON.parse(event.data);

          //habilita puntero de mouse para que el customer pueda cliquear en el micrositio
          let VISUALAID_POINTER_CUSTOMER_ENABLE = getDeploymentParamValue(this.props.deployment.parameters, "VISUALAID_POINTER_CUSTOMER_ENABLE");

          if (data.type) {
            switch (data.type) {
              case 'html5animate':
                this.props.log({
                  logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                  log_category: 'VISUALAID_START',
                  log_text: `code: ${data.code} | url: ${data.entryPoint} | type: ${data.type}`
                })
                
                // Esta funcion obtiene la estructura del vaid y los slides del lado del subscriber
                getPresentationObject(data.code, data.startAtOrder);

                this.props.setIframePresentation({ code: data.code, entryPoint: data.entryPoint, type: data.type });

                if(VISUALAID_POINTER_CUSTOMER_ENABLE && VISUALAID_POINTER_CUSTOMER_ENABLE=='1')
                {
                  this.setState({
                    isClickable: true
                  });
                }

                break;
              case 'sdva':
                this.props.log({
                  logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                  log_category: 'VISUALAID_START',
                  log_text: `code: ${data.code} | url: ${data.entryPoint} | type: ${data.type}`
                })

                // Esta funcion obtiene la estructura del vaid y los slides del lado del subscriber
                getPresentationObject(data.code, data.startAtOrder);

                this.props.setIframePresentation({ code: data.code, entryPoint: data.entryPoint, type: data.type, initialSlideIndex: data.startAtOrder });
                if(VISUALAID_POINTER_CUSTOMER_ENABLE && VISUALAID_POINTER_CUSTOMER_ENABLE=='1')
                {
                  this.setState({
                    isClickable: true
                  });
                }
                break;
              case 'video':
                this.props.log({
                  logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                  log_category: 'VISUALAID_START',
                  log_text: `code: ${data.code} | url: ${data.entryPoint} | type: ${data.type}`
                });
                break;
              case 'slides':
              default:
                this.props.log({
                  logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                  log_category: 'VISUALAID_START',
                  log_text: `code: ${data.code} | startAtOrder: ${data.startAtOrder} | startAtCode: ${data.startAtCode} | type: ${data.type}`
                })
                getPresentationObject(data.code, data.startAtOrder);
                
                this.props.setPresentationFocusedClient(data.startAtOrder)

            }

          }
        }
      });

      otCore.session.on('signal:VISUALAID_GOTO', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          let data = JSON.parse(event.data);
          if (this.props.presentationBar.focusedPresentationSlides.content != null && this.props.presentationBar.focusedPresentationSlides.content[data.gotoPosition] != null) {
            this.props.log({
              logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
              log_category: 'VISUALAID_GOTO',
              log_text: `code: ${data.code} | gotoPosition: ${data.gotoPosition} | goToCode: ${this.props.presentationBar.focusedPresentationSlides.content[data.gotoPosition].code}`
            })
          }
          this.props.updateSlideActive({
            numSlide: data.gotoPosition
          })
          getPresentationObject(data.code, data.gotoPosition);
        }
      })

      otCore.session.on('signal:SDVA', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          if (!this.state.isFull && !this.state.vaidActive && (isMobile || isIPad13 || isIPhone13)) {
            this.setState({openMobileFullScreenAlert:true,vaidActive:true});
          }
          let data = JSON.parse(event.data);

          this.props.log(
            {
              logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
              log_category: 'SDVA_' + data.command,
              log_text: `code: ${data.code} | payload: ${data.payload}`
            }
          );

          let iframeWin = document.getElementById("inlineFrame").contentWindow;

          let messageToSend = "SDVA_IN:" + data.command + ":" + data.payload;

          if (!this.state.isClickable)
          {
            this.setState({
              isClickable: data.command == 'SHOWFULLSCREENMOVIE'
            });
          }

          iframeWin.postMessage(messageToSend, "*");
        }
      })

      otCore.session.on('signal:AAN', (event) => {
        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          if (!this.state.isFull && !this.state.vaidActive && (isMobile || isIPad13 || isIPhone13)) {
            this.setState({openMobileFullScreenAlert:true,vaidActive:true});
          }
          let data = JSON.parse(event.data);

          this.props.log(
            {
              logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
              log_category: 'AAN_' + data.command,
              log_text: `code: ${data.code} | payload: ${data.payload}`
            }
          );

          let iframeWin = document.getElementById("inlineFrameHtml5").contentWindow;

          let messageToSend = "AAN_IN:" + data.command + ":" + data.payload;

          iframeWin.postMessage(messageToSend, "*");
        }
      })

      otCore.session.on('signal:VISUALAID_END', (event) => {
        this.setState({ isExpanded: true });

        if (this.state.active && otCore.session.connection.connectionId !== event.from.connectionId) {
          if (isMobile || isIPad13 || isIPhone13) {
            this.setState({ vaidActive:false });
            if(this.state.isFull){
              this.setState({ isFull: !this.state.isFull});
            }
            this.unlockOrientation();
          }
          let data = JSON.parse(event.data);
          this.props.log({
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: 'VISUALAID_END',
            log_text: `code: ${data.code} | reason: ${data.reason}`
          })

          this.props.clientEndPresentation({
            displayScreenPresentation: false,
            numSlide: 0,
            presentationFocused: {},
            focusedPresentationSlides: {}
          })
        }

        this.props.setVaSize(null);
      })
      this.props.publishVideoObjectManager(otCore);
      this.props.getPresentations(this.props.auth.callCode);
      
      this.setState({organizerNotConnected: false});

    } 
    else if (this.props.deployment.callData != null && this.props.deployment.callData.isError == true && (this.props.deployment.callData.OT_TOKEN_ID == null || this.props.deployment.callData.OT_TOKEN_ID == '')) 
    {
      this.callDataByError(intl);
    } 
    else 
    {
      let invalidTokenText = 'source: INVALID_CALL_DATA';
      this.props.log({
        logtype: 0,
        log_category: 'CALL_ERROR',
        log_text: invalidTokenText
      });
      this.props.flush();
      //trackException("exception event Invalid call data")
      this.setState({ reloadCallDialog: true, otErrMsg: 'Invalid call data' });
    }
  }

  onMouseMove(data, resize) {
    let presentationDiv;
    switch (data.type) {
      case 'slides':
        presentationDiv = document.getElementById('presentation__container');
        break;
      case 'sdva':
        presentationDiv = document.getElementById('presentation__container__sdva');
        //window.alert('x:'+data.x+'y:'+data.y+'counteorg:'+data.mouseMoveCounter+'countersubs:'+this.state.mouseMoveCounterSubs);
        break;
      case 'html5animate':
        presentationDiv = document.getElementById('presentation__container__html5');
        break;
      default:
        break;
    }
    if ((data.mouseMoveCounter > this.state.mouseMoveCounterSubs || resize) && presentationDiv && this.state.active && this.props.auth.role != 'organizer') {
      let e_posx = 0;
      let e_posy = 0;

      //Offset del padre del lado del cliente
      e_posx = parseInt(presentationDiv.offsetParent.offsetLeft);
      e_posy = parseInt(presentationDiv.offsetParent.offsetTop);

      // size del canvas del customer
      let vaSize = this.props.presentationBar.vaCanvasSize;

      if(vaSize == null){
        vaSize = { width: presentationDiv.clientWidth,
                   height: presentationDiv.clientHeight,
                   marginLeft: 0,
                   marginTop: 0 }
      }
      

      let vaHeight = vaSize.height;
      let vaWidth = vaSize.width;

      /* 
        La posicion y que me llega del organizador "data.y" tengo que restarle el margenTop del organizador
        Y el marginTop del customer se lo sumo al total
      */
     let pixelRatio = 1;
     if(isMobile || isTablet || isIPad13 || isIPhone13){
      pixelRatio = window.devicePixelRatio;

      if(data.type == 'slides' || data.type == 'sdva'){
        vaHeight = vaSize.height * pixelRatio;
        vaWidth = vaSize.width * pixelRatio;
      }
     }
     
      // Data es el size del canvas del rep
      //Se obtiene el porcentaje con la posicion y el width del rep + offset del padre del cliente
      let x = (parseInt(vaWidth) * (parseInt(data.x - data.marginLeft) / parseInt(data.width))) + (e_posx * pixelRatio) + (vaSize.marginLeft * pixelRatio);
      let y = (parseInt(vaHeight) * (parseInt(data.y - data.marginTop) / parseInt(data.height))) + (e_posy * pixelRatio) + (vaSize.marginTop * pixelRatio);
      
      
      if(pixelRatio > 0){
        x = x / pixelRatio;
        y = y / pixelRatio;
      }


      this.setState({ positionX: x, positionY: y, mouseMoveCounterSubs: data.mouseMoveCounter });
    
    }
  }

  syncVaid(){
   setTimeout(() => {
    if(this.props.presentationBar.presentationFocused){
      if(this.props.presentationBar.presentationFocused.type){
          switch (this.props.presentationBar.presentationFocused.type) {
            case 'slides':
              this.props.sendSlideActive({
                 'numSlide': this.props.presentationBar.numSlide,
                  code: this.props.presentationBar.presentationFocused.code,
                  videoManager: this.props.presentationBar.videoManager
                })
                break;
              case 'sdva':
                this.setState({ snackSyncVaid: true });
                break;
              case 'video':
                //TODO:agregar logica aca para sincronizar video con los nuevos participantes
              default:
                break;
            }
          }
      }
    }, 2000)
  }

  closeSyncVaid = () => {
    this.setState({ snackSyncVaid: false });
  }

  syncSdva() {
    this.closeSyncVaid();

    this.props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_END',
      log_text: `code: ${this.props.presentationBar.presentationFocused.code} | reason: manual`
    });

    let presentationFocused = this.props.presentationBar.presentationFocused;
    this.props.closePresentation(Object.assign(this.props.presentationBar, {
      displayScreenPresentation: false,
      videoManager: this.props.presentationBar.videoManager,
      code: this.props.presentationBar.presentationFocused.code,
      presentationFocused: {},
      focusedPresentationSlides: {},
      presentationsListFiltered: this.props.presentationBar.presentationsList.list,
      reason: 'SyncVaid'
    }));
    
    setTimeout(() => {
      this.props.presentationBar.videoManager.session.signal({
        type: 'VISUALAID_START',
        data: JSON.stringify({
          code: presentationFocused.code,//  --> codigo del slide
          type: presentationFocused.type, // -->
          entryPoint: presentationFocused.entryPoint
        })
      }, (err) => {
        if(err)
        {
          console.log(err);
        }        
      });
  
      this.props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'VISUALAID_START',
        log_text: `code: ${presentationFocused.code} | url: ${presentationFocused.entryPoint} | type: ${presentationFocused.type}`
      })
      this.props.updateScreenPresentationSdva(
        Object.assign(this.props.presentationBar, {
          displayScreenPresentation: true,
          entryPoint: presentationFocused.entryPoint,
          presentationFocused: presentationFocused, // sirve para saber cual esta siendo mostrada en la barra de la izquierda
          showSlideList: false
        })
      )
    }, 1000);

  }

  unsubscribeEventLogOnCallEnd(){
    let currentSubs = this.state.currentSubscribers;
    let logText = '';
    let logType = '';
    for (let k = 0; k < currentSubs.length; k++) {
      const element = currentSubs[k];
      if(element.type == 'camera'){
        logText = 'camid: ' + element.id; 
        logType = 'CALL_UNSUBSCRIBE_CAM';
      }else{
        logText = 'scrid: ' + element.id;
        logType = 'CALL_UNSUBSCRIBE_SCREEN';
      } 
      this.props.log({
        logtype: 3,
        log_category: logType,
        log_text: logText
      });
    }
    this.setState({ currentSubscribers: [] });
  }

  unsubscribeEventLog(subscribers, logType) {
    let currentSubs = this.state.currentSubscribers;
    for (let index = 0; index < currentSubs.length; index++) {
      const element = currentSubs[index];
      let found = false;
      let logText = '';
      if (subscribers && subscribers.length > 0) {
        let subscriberType;
        if(logType == 'CALL_UNSUBSCRIBE_CAM'){
          subscriberType = subscribers.camera;
        }else{
          subscriberType = subscribers.screen;
        }
        Object.keys(subscriberType).map((key, index) => {
          let subscriberDevice = null;
          if(logType == 'CALL_UNSUBSCRIBE_CAM'){
            subscriberDevice = subscribers.camera[key];
          }else if(logType == 'CALL_UNSUBSCRIBE_SCREEN'){
            subscriberDevice = subscribers.screen[key];
          }
          if (subscriberDevice.id == element.id) {
            found = true;
          }
        });
      }
      if (!found) {
        logType == 'CALL_UNSUBSCRIBE_CAM' ? logText = 'camid: ' + element.id + ' | strid: ' + element.streamId: logText = 'scrid: ' + element.id + ' | strid: ' + element.streamId
        this.props.log({
          logtype: 3,
          log_category: logType,
          log_text: logText
        });
        currentSubs.splice(index, 1);
        this.setState({ currentSubscribers: currentSubs });
      }
    }
    this.props.addCurrentSubscribers(this.state.currentSubscribers);
  }

  subscribeEventLog(subscribers, logType) {
    if (subscribers) {
      let subscriberType;
      if(logType == 'CALL_SUBSCRIBE_CAM'){
        subscriberType = subscribers.camera;
      }else{
        subscriberType = subscribers.screen;
      }
      Object.keys(subscriberType).map((key, index) => {
        let currentSubs = null;
        let subscriberDevice = null;
        let type = '';
        let logText = '';
        if(logType == 'CALL_SUBSCRIBE_CAM'){
          subscriberDevice = subscribers.camera[key];
          type = 'camera';
          logText = 'camid: ' + subscriberDevice.id + ' | strid: ' + subscriberDevice.streamId;
        }else if(logType == 'CALL_SUBSCRIBE_SCREEN'){
          subscriberDevice = subscribers.screen[key];
          type = 'screen';
          logText = 'scrid: ' + subscriberDevice.id + ' | strid: ' + subscriberDevice.streamId;
        }
        
        let found = false;
        let auxSubs = this.state.currentSubscribers;
        for (let k = 0; k < auxSubs.length; k++) {
          const element = auxSubs[k];
          if(element.id == subscriberDevice.id){
            found = true;
            break;
          }
        }
        if(!found){
          currentSubs = { id: subscriberDevice.id, streamId: subscriberDevice.streamId,  type: type};
          this.props.log({
            logtype: 3,
            log_category: logType,
            log_text: logText
          });
          this.setState(prevState => ({
            currentSubscribers: [...prevState.currentSubscribers, currentSubs]
          }));
        }
      });
      this.props.addCurrentSubscribers(this.state.currentSubscribers);
    }
  }

  showWarning(show){
    this.setState({showTimeWarning: show});
  }

  callDataByError(intl){
    let otErrMsg = '';
      switch (this.props.deployment.callData.errorCode) {
        case 'ORGANIZER_NOT_CONNECTED':
          otErrMsg = intl.formatMessage({
            id: "CALL.ORGANIZER_NOT_CONNECTED"
          });
          this.props.log({
            logtype: 2,
            log_category: 'ORGANIZER_NOT_CONNECTED',
            log_text: otErrMsg
          });
          this.setState({ organizerNotConnected: true, reloadCallDialog: false, otErrCode: 'noToken', otErrMsg: otErrMsg });
          break;
        case 'CALL_NOT_IN_RANGE':
          otErrMsg = intl.formatMessage({
            id: "CALL.NOT_IN_RANGE"
          });
          this.props.log({
            logtype: 2,
            log_category: 'CALL_NOT_IN_RANGE',
            log_text: otErrMsg
          });
          this.setState({ reloadCallDialog: true, otErrCode: 'noToken', otErrMsg: otErrMsg });
          break;
        case 'SUBSCRIBER_ALREADY_CONNECTED':
            otErrMsg = intl.formatMessage({
              id: "CALL.SUBSCRIBER_ALREADY_CONNECTED"
            });
            this.props.log({
              logtype: 2,
              log_category: 'SUBSCRIBER_ALREADY_CONNECTED',
              log_text: otErrMsg
            });
            this.setState({ reloadCallDialog: true, otErrCode: 'noToken', otErrMsg: otErrMsg });
            break;
        case 'ORGANIZER_ALREADY_CONNECTED':
              otErrMsg = intl.formatMessage({
                id: "CALL.ORGANIZER_ALREADY_CONNECTED"
              });
              this.props.log({
                logtype: 2,
                log_category: 'ORGANIZER_ALREADY_CONNECTED',
                log_text: otErrMsg
              });
              this.setState({ reloadCallDialog: true, otErrCode: 'noToken', otErrMsg: otErrMsg });
              break;
        case 'CALL_CLOSED':
          otErrMsg = intl.formatMessage({
            id: "CALL.CLOSED"
          });
          this.customAlert(otErrMsg);
          this.setState({
            logout: true
          });
          break;
        default:
          break;
    }
  }

  lockOrientation(orientation) {
    try {
      var screen = window.screen;
      if(this.state.isFull && screen && screen.orientation && typeof screen.orientation.lock === 'function'){
        screen.orientation.lock(orientation);
      }
    } catch (error) {
      console.log(error);
    }
  }

  unlockOrientation() {
    try {
      var screen = window.screen;
      if(screen && screen.orientation && typeof screen.orientation.unlock === 'function'){
        screen.orientation.unlock();
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    //console.log('unmounting');
    Object.assign(this.props.presentationBar, { ...this.props.presentationBar, displayScreenPresentation: false, presentationFocused: {}, focusedPresentationSlides: {} });

    this.props.resetPresentationVars(this.props.presentationBar);
    this.props.resetChatVars();

    if (otCore && !this.state.logout) {
      
      try{
        otCore.endCall();
      }catch(e){
        console.log(e);
      }
      
      if (this.props.auth.role == 'organizer') {
        otCore.session.signal({
          type: 'CALL_END',
          data: JSON.stringify({ source: "LOG_OUT" })
        });
      }
      otCore.disconnect();
      otCore.triggerEvent('disconnected');
    }
    this.props.flush();
    this.props.flushChat();
  }

  startCall() {
    let subscribeObject = null;
    let that = this; 
    let tapedTwice = false;
    this.setState({
      stateButtonStartCall: 'buttonClickeable--busy kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light',
      disabledStartCallButton: true
    });

    let publisherProperties={};

    if(disablePublish){
      publisherProperties.audioSource = null;
    }
    
    if(!this.state.constraintsNotSatisfied){
      publisherProperties = getPublisherProps(publisherProperties,this.props.deployment.parameters);
    }
    otCore.startCall(publisherProperties)
      .then(({ publishers, subscribers, meta }) => {
        // llama a la api callsession 
        this.props.callSession();

        try{                
          //////////////////////////////////
          // recorre el array de streams y se suscribe para darle un div en la pantalla + propiedades de video
          this.state.rawStreamsArr.forEach((event) => { 
            otCore.subscribe(event.stream, "layoutContainer", {
              insertMode: "append"
            }).then((res) =>{
              subscribeObject = res.id
              let cameraConnectionId = res.stream.connection.data.split('|')[1];
              /**
               * Setea listener en el padre para todos los hijos
               */
              
              let camerasView = this.state.camerasView || [];
              
              document.getElementById(res.id).removeEventListener('dblclick', function(e) {
                //console.log('evento removido 2')
              });
              document.getElementById(res.id).addEventListener('dblclick', function(e) {
                //that.toggleCamerasDisplay(res.id);
                //res.element.outerText = res.stream.name+"\n";
                that.changeStateCamerasDisplay(res.id)
              });

              if(isMobile){

                document.getElementById(res.id).addEventListener('touchstart', function(e) {
                  if(!tapedTwice) {
                    tapedTwice = true;
                    setTimeout( function() { tapedTwice = false; }, 300 );
                    return false;
                  }
                  e.preventDefault();
                  //that.toggleCamerasDisplay(res.id);
                  //res.element.outerText = res.stream.name+"\n";
                  that.changeStateCamerasDisplay(res.id);
                });
              }
        
              //Se agrego el listener de mouseover por bug en la lib https://github.com/opentok/accelerator-core-js/issues/89
              document.getElementById(res.id).addEventListener('mouseover', function(e) {
                if(res.element && res.element.children && res.element.children[2]){
                  res.element.children[2].innerHtml = res.stream.name;
                  res.element.children[2].innerText = res.stream.name;
                }
              });
        
              let isOrganizer = cameraConnectionId === this.props.deployment.callData.organizerId;
              let isSuscriber = cameraConnectionId === this.props.deployment.callData.subscriberId;
              let isGuest = cameraConnectionId === this.props.deployment.callData.guestId;

              camerasView.push({
                id: subscribeObject,
                isBig: false || (res.stream.videoType === 'screen' || res.stream.videoType === 'custom'),
                isSmall: false,
                isReadOnly: false,
                isPublisher: false,
                isOrganizer: isOrganizer,
                isSuscriber: isSuscriber,
                isGuest: isGuest,
                videoType: res.stream.videoType
              });
        
              
              this.setState({camerasView, participants: camerasView.length})
              this.props.analyticsLogEvent('callStart');
              setTimeout(() => {
                this.layout();
              }, 200)
        
            }).catch((err) => {
              console.log(err)
            })
          })
          
          /////////////////////////////////


          console.log('llamada iniciada ' + this.props.global.auth.deployment.callData.code);
        }catch(e){
          console.log(e);
        }


        let otCoreState = otCore.state();
        

        let camerasView = [];
        let cameraPublisher = Object.values(otCoreState['publishers']['camera'])[0];
        let cameraPublisherConnectionId = cameraPublisher.stream.connection.data.split('|')[1];
        let that = this;
        let isOrganizer = cameraPublisherConnectionId === this.props.deployment.callData.organizerId;
        let isSuscriber = cameraPublisherConnectionId === this.props.deployment.callData.subscriberId;
        let isGuest = cameraPublisherConnectionId === this.props.deployment.callData.guestId;

        camerasView.push({
          //poner el id de este objeto y sacar el objeto camara completo
          id: cameraPublisher.id,
          isBig: false || (cameraPublisher.stream.videoType === 'screen' || cameraPublisher.stream.videoType === 'custom'),
          isSmall: false,
          isReadOnly: false,
          isPublisher: true,
          isOrganizer: isOrganizer,
          isSuscriber: isSuscriber,
          isGuest: isGuest,
          videoType: cameraPublisher.stream.videoType
        });

        console.log(cameraPublisher.stream.videoType);

        document.getElementById('publisherContainer').addEventListener('dblclick', function(e) {
          //that.toggleCamerasDisplay(cameraPublisher.id, true);
          that.changeStateCamerasDisplay(cameraPublisher.id)
        });  
        if(isMobile){

          document.getElementById('publisherContainer').addEventListener('touchstart', function(e) {
            if(!tapedTwice) {
              tapedTwice = true;
              setTimeout( function() { tapedTwice = false; }, 300 );
              return false;
            }
            e.preventDefault();
            //that.toggleCamerasDisplay(res.id);
            //res.element.outerText = res.stream.name+"\n";
            that.changeStateCamerasDisplay(cameraPublisher.id);
          });
        }

        if(Object.keys(otCoreState['subscribers']['camera']).length > 0){
          Object.values(otCoreState['subscribers']['camera']).forEach((camera) => {
            //console.log(camera)
            let cameraConnectionId = camera.stream.connection.data.split('|')[1];
            let isOrganizer = cameraConnectionId === this.props.deployment.callData.organizerId;
            let isSuscriber = cameraConnectionId === this.props.deployment.callData.subscriberId;
            let isGuest = cameraConnectionId === this.props.deployment.callData.guestId;

            camerasView.push({
              id: camera.id,
              isBig: false || (camera.stream.videoType === 'screen' || camera.stream.videoType === 'custom'),
              isSmall: false,
              isReadOnly: false,
              isPublisher: false,
              isOrganizer: isOrganizer,
              isSuscriber: isSuscriber,
              isGuest: isGuest,
              videoType: camera.stream.videoType
            });
          })
        }
        
        this.setState({
          publishers,
          subscribers,
          meta,
          active: true,
          camerasView,
          participants: camerasView.length
        });

        this.props.setTimer(true);

        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_START',
          log_text: 'camera: ' + this.state.localVideoEnabled + " | mic: " + this.state.localAudioEnabled
        })

        Object.assign(this.props.presentationBar, { callActive: true });
        this.props.setCallActive(true);
        window.addEventListener("beforeunload", this.onUnload);

        this.initializeCameraVideoFilter();

        setTimeout(() => {
          this.layout();
        }, 200);
      }).catch((err) => {
        const { intl } = this.props;
        switch (err.name) {
          case 'OT_USER_MEDIA_ACCESS_DENIED':
            let accessDeniedMsg = intl.formatMessage({
              id: "OT_USER_MEDIA_ACCESS_DENIED"
            });
            //window.alert(accessDeniedMsg);
            this.setState({
              disabledStartCallButton: false,
              stateButtonStartCall: 'buttonClickeable',
              otErrCode: err.name,
              otErrMsg: accessDeniedMsg
            });
            break;
          case 'OT_HARDWARE_UNAVAILABLE':
            let hardwareUnavailableMsg = intl.formatMessage({
              id: "OT_HARDWARE_UNAVAILABLE"
            });
            window.alert(hardwareUnavailableMsg);
            this.setState({
              disabledStartCallButton: false,
              stateButtonStartCall: 'buttonClickeable',
              otErrCode: err.name,
              otErrMsg: hardwareUnavailableMsg              
            });
            break;
          case 'OT_NOT_CONNECTED':
            this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
            break;
          case 'OT_NO_DEVICES_FOUND':
            this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
            break;
          case 'OT_CONSTRAINTS_NOT_SATISFIED':
            if(this.state.constraintsNotSatisfied){
              this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
            }else{
              this.setState({constraintsNotSatisfied:true});
              window.alert(err.message);
              this.startCall();
            }
            break;
          case 'UNABLE_TO_SUBSCRIBE':
            this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
            break;
          case 'OT_NOT_SUPPORTED':
              this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
              break;
          case 'Error':
              this.setState({ reloadCallDialog: true, otErrCode: err.name, otErrMsg: err.message, isFull: false })
            break;
          default:
            break;
        }
        console.log(err);

        if(err && err.name && err.message){
          let otErrorStartCallText = 'source: OT_START_CALL | ' + 'CODE: ' + err.name + ' | DESC: ' + replaceSpecialChars(err.message);
          this.props.log({
            logtype: 0,
            log_category: 'CALL_ERROR',
            log_text: otErrorStartCallText
          });
          this.props.flush();
        }
        trackException(err);
      }
      );
  
  }

  onUnload = e => {
    //TODO: Failed to execute 'send' on 'XMLHttpRequest' Synchronous XHR in page dismissal
    /*this.props.log({
      logtype: 3,
      log_category: 'WINDOW_QUIT',
      log_text: 'quit forced'
    })
    this.props.flush();*/
    const { intl } = this.props;
    e.preventDefault();
    return e.returnValue = intl.formatMessage({
      id: "CALL.PREVENT.DEFAULT"
    });
  };

  changeIsFull(isFull){
    this.setState({isFull: isFull});
  }

  changeCallStatus(open, cancelCall, signOutClick) {
    let tryAgainState = false;
    let valueNotes = '';
    this.props.setTimer(false);
    this.setState({callEnded: true});
    
    if (this.props.auth.role == 'organizer') 
    {
      if (cancelCall == true) {
        try{
          valueNotes = localStorage.getItem("callnotes_" + this.props.global.auth.deployment.callData.code);
        }catch(e){
          console.log(e);
        }
        this.props.log({
          logtype: 3,
          log_category: 'CALL_CLOSED',
          log_text: 'CALL_CLOSED'
        })
        otCore.session.signal({
          type: 'CALL_END',
          data: JSON.stringify({ source: "LOG_OUT" })
        });
        this.props.closeCall({ callcode: this.props.global.auth.deployment.callData.code, valueNotes: valueNotes });
        //console.log('signOutClick', signOutClick);
        this.endCall(signOutClick)
        // this.setState({ logout: true });
      } else if (cancelCall == "backdropClick") {
        tryAgainState = true;
        open = false;
      }
      this.setState({ cancelCallDialog: open, tryAgainButton: tryAgainState, signOutClick: signOutClick, isFull: false });
    } 
    else 
    {
      let haveFeedbackEnabled = getDeploymentParamValue(this.props.deployment.parameters, 'CALL_FEEDBACK_ENABLE');
      
      if (otCore && otCore.session) 
      {
        otCore.communication.callProperties.publishAudio = true;
        otCore.communication.callProperties.publishVideo = true;
        
        try{
          otCore.endCall();
        }catch(e){
          console.log(e);
        }
      }

      this.setState({
        active: false,
        stateButtonStartCall: 'buttonClickeable',
        disabledStartCallButton: false,
        cancelCallDialog: false,
        localAudioEnabled: true,
        localVideoEnabled: true
      });
      this.props.flush();
      this.props.flushChat();
      window.removeEventListener("beforeunload", this.onUnload);
      
      //Si salgo de la llamada cuando estaban proyectando un VA de tipo video, me desubscribo del mismo
      if(this.state.subscribers && this.state.subscribers.custom)
      {
        let customs = Object.values(this.state.subscribers.custom)
        if(customs && customs.length > 0){
          otCore.session.unsubscribe(customs[0]);
        }
      }

      if(haveFeedbackEnabled === '1' && this.props.auth.role == 'subscriber')
      {
        this.setState({
          showFeedbackModal: true
        })
      }
      else
      {
        this.endCall()
      }
    }
  }

  reloadCall(open, reload, autojoin, video, audio) {
    let tryAgainState = false;
    if (reload == true) {
      window.removeEventListener("beforeunload", this.onUnload);
      if (this.props.auth.urlToken && this.props.auth.urlToken != '') {
        if(autojoin){
          window.location.href = `/call/${this.props.auth.callCode}/${this.props.auth.userid}?autojoin=${autojoin}&localvideo=${video}&localaudio=${audio}&token=${this.props.auth.urlToken}`;
        }else{
          window.location.href = `/call/${this.props.auth.callCode}/${this.props.auth.userid}?token=${this.props.auth.urlToken}`;
        }
      }else{
        if(autojoin){
          window.location.href = `/call/${this.props.auth.callCode}/${this.props.auth.userid}?autojoin=${autojoin}&localvideo=${video}&localaudio=${audio}`;
        }else{
          window.location.href = `/call/${this.props.auth.callCode}/${this.props.auth.userid}`;
        }
      }
    } else {
      open = false;
      tryAgainState = true;
    }
    this.setState({ reloadCallDialog: open, disabledStartCallButton: false, stateButtonStartCall: 'buttonClickeable',tryAgainButton: tryAgainState });

  }

  mousemove = (e) => {
    var m_posx = 0, m_posy = 0, e_posx = 0, e_posy = 0, obj;
    switch (this.props.presentationBar.presentationFocused.type) {
      case 'slides':
        obj = document.getElementById('presentation__container');
        break;
      case 'sdva':
        obj = document.getElementById('presentation__container__sdva');
        break;
      case 'html5animate':
        obj = document.getElementById('presentation__container__html5');
        break;
      default:
        break;
    }

    //Se obtiene la posicion del mouse
    if (!e) { e = window.event; }
    if (e.pageX || e.pageY) {
      m_posx = e.pageX;
      m_posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      m_posx = e.clientX + document.body.scrollLeft
        + document.documentElement.scrollLeft;
      m_posy = e.clientY + document.body.scrollTop
        + document.documentElement.scrollTop;
    }

    //offset de los padres acumulado
    if (obj.offsetParent) {
      do {
        e_posx += obj.offsetLeft;
        e_posy += obj.offsetTop;
      } while (obj = obj.offsetParent);
    }
    //window.alert('x:'+(m_posx - e_posx)+'y:'+(m_posy - e_posy));
    let presentationAppBar = document.getElementById('presentation_appbar');
    let mouseMoveCounter = this.state.mouseMoveCounterOrg + 1;
    this.setState({ showMouseMove: true, mouseMoveCounterOrg: mouseMoveCounter });

    let vaSize = this.props.presentationBar.vaCanvasSize;

    this.props.presentationBar.videoManager.session.signal({
        type: 'MOUSE_MOVE',
        data: JSON.stringify({
          x: (m_posx - e_posx),
          y: (m_posy - e_posy /*- parseInt(presentationAppBar.clientHeight)*/),
          width: vaSize ? vaSize.width: e.currentTarget.clientWidth,
          height: vaSize ? vaSize.height: e.currentTarget.clientHeight,
          marginTop: vaSize ? vaSize.marginTop: 0,
          marginLeft: vaSize ? vaSize.marginLeft: 0,
          mouseMoveCounter: mouseMoveCounter,
          type: this.props.presentationBar.presentationFocused.type
        })
      }, (err) => {
        if (err) {
          console.log(err);
        }
    });
  
  }

  stopMouseMove() {
    let presentationDiv;
    switch (this.props.presentationBar.presentationFocused.type) {
      case 'slides':
        presentationDiv = document.getElementById('presentation__container');
        break;
      case 'sdva':
        presentationDiv = document.getElementById('presentation__container__sdva');
        document.getElementById("inlineFrame").style.pointerEvents = "block";
        break;
      case 'html5animate':
        presentationDiv = document.getElementById('presentation__container__html5');
        document.getElementById("inlineFrameHtml5").style.pointerEvents = "block";
        break;
      default:
        break;
    }
    if (presentationDiv) {
      presentationDiv.removeEventListener('mousemove', this.mousemove);
      this.props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'MOUSE_END',
        log_text: `type: ${this.props.presentationBar.presentationFocused.type}`
      })
    }
    this.props.presentationBar.videoManager.session.signal({
      type: 'STOP_MOUSE_MOVE'
    }, (err) => {
      if(err){
        console.log(err);
      }
    });
    this.setState({ showMouseMove: false, positionX: 0, positionY: 0, mouseMoveCounterOrg: 0, mouseMoveCounterSubs: 0, mouseMoveEvent: null, connectionIdFrom: null });
  }

  sendCoordinates() {
    if (this.props.presentationBar.presentationFocused && this.props.presentationBar.presentationFocused.code) {
      let presentationDiv;
      switch (this.props.presentationBar.presentationFocused.type) {
        case 'slides':
          presentationDiv = document.getElementById('presentation__container');
          break;
        case 'sdva':
          presentationDiv = document.getElementById('presentation__container__sdva');
          document.getElementById("inlineFrame").style.pointerEvents = "none";
          break;
        case 'html5animate':
          presentationDiv = document.getElementById('presentation__container__html5');
          document.getElementById("inlineFrameHtml5").style.pointerEvents = "none";
          break;
        default:
          break;
      }

      if (presentationDiv) {
        presentationDiv.addEventListener('mousemove', this.mousemove);
        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'MOUSE_START',
          log_text: `type: ${this.props.presentationBar.presentationFocused.type}`
        })
      }
    }
  }

  endCall(signOutClick) {
    //console.log('entre a endcall comun')
    this.unsubscribeEventLogOnCallEnd();
    if (isMobile || isIPad13 || isIPhone13) {
      this.unlockOrientation();
    }
    if (otCore && otCore.session) {
      otCore.communication.callProperties.publishAudio = true;
      otCore.communication.callProperties.publishVideo = true;
      
      try{
        otCore.endCall();
      }catch(e){
        console.log(e);
      }

    }
    this.setState({
      active: false,
      stateButtonStartCall: 'buttonClickeable',
      disabledStartCallButton: false,
      cancelCallDialog: false,
      localAudioEnabled: true,
      localVideoEnabled: true,
      callEnded: true
    });

    //this.props.presentationBar.displayScreenPresentation = false;
    this.props.setTimer(false);
    // solo si soy organizador deberia tener permisos para realizar esto
    if (this.props.auth.role == 'organizer') {
      otCore.session.signal({
        type: 'CALL_END',
        data: JSON.stringify({ source: "END_BUTTON" })
      });
      //console.log('estoy por activar callend')
      this.layout();
      // si hay una presentacion activa
      if (this.props.presentationBar.presentationFocused.code) {
        
        // el middleware del redux se encarga de cerrar la presentacion, enviar la señal y loguear el evento
        let fnCallbackClosePresentation = () => {
          this.props.closePresentation({
            displayScreenPresentation: false,
            videoManager: this.props.presentationBar.videoManager,
            code: this.props.presentationBar.presentationFocused.code,
            presentationFocused: {},
            focusedPresentationSlides: {},
            reason: 'endcall'
          });
        }

        // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
        if(this.props.presentationBar.presentationFocused.type == 'sdva'){
          this.props.sendGotoSdvaSlide(
              {
                  type: 'SDVA',
                  command: 'HIDEFULLSCREENMOVIE',
                  payload: 'NA'
              }
          );

          setTimeout(() => {
              fnCallbackClosePresentation();
          }, 1200);
        }else{
            fnCallbackClosePresentation();
        }

        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'VISUALAID_END',
          log_text: `code: ${this.props.presentationBar.presentationFocused.code} | reason: endcall`
        })
      }
    } else if(this.props.auth.role == 'guest'){
      this.setState({ logout: true });
    }
    
    // actualizo en el objeto global las variables que para cerrar la presentacion
    //TODO: usar las variables de globales para cosas de comunicacion, sino usar el estado interno
    Object.assign(this.props.presentationBar, { 
      ...this.props.presentationBar, 
      displayScreenPresentation: false, 
      presentationFocused: {}, 
      focusedPresentationSlides: {}, 
      callTime: false 
    });

    // se limpia el storage por posibles menues abiertos y demas 
    //TODO: refactorear esto porque le esta enviando todo el objeto y no esta centrado en su tarea en concreto si se cambio un estado en el medio también lo guarda
    this.props.resetPresentationVars(this.props.presentationBar);
    // se limpia los contenedores de texto del chat
    this.props.resetChatVars();

    Object.assign(this.props.presentationBar, { callActive: false });
    this.props.setCallActive(false);
    window.removeEventListener("beforeunload", this.onUnload);
    this.props.flush();
    this.props.flushChat();
    this.props.setSignOutClick(signOutClick);
    this.setState({signOutClick: signOutClick});
  }

  //TODO: codigo duplicado, se resuelve en el branch de refactorizacion
  endCallWithoutRedirect() {
    //console.log('entre a withoutredirect')
    let valueNotes = '';
    if (this.props.auth.role == 'organizer') {
      try{
        valueNotes = localStorage.getItem("callnotes_" + this.props.global.auth.deployment.callData.code);

        localStorage.removeItem('feedback_' + this.props.global.auth.deployment.callData.code);
      }catch(e){
        console.log(e);
      }
      if (otCore && otCore.session) {
        otCore.communication.callProperties.publishAudio = true;
        otCore.communication.callProperties.publishVideo = true;
        try{
          otCore.endCall();
        }catch(e){
          console.log(e);
        }
      }
      this.props.closeCall({ callcode: this.props.global.auth.deployment.callData.code, valueNotes: valueNotes });
      this.props.log({
        logtype: 3,
        log_category: 'CALL_CLOSED',
        log_text: 'CALL_CLOSED'
      })
      otCore.session.signal({
        type: 'CALL_END',
        data: JSON.stringify({ source: "LOG_OUT" })
      });
       
      this.setState({ cancelCallDialog: true, tryAgainButton: false});
    }
  }

  // terminar la llamada tokbox
  // pasar al stado global el estado de la aplicacion y loguear
  // cambiar el status de la llamada
  // redirigir



  /**startWithAutoJoin setea los controles de audio y video de acuerdo a los params por url localvideo=true&localaudio=false */
  startWithAutoJoin(video, audio){

    let localvideo = false;
    let localaudio = false;
    if(audio == 'true'){
      localaudio = true;
    }
    if(video == 'true'){
      localvideo = true;
    }
   
    otCore.communication.callProperties.publishAudio = localaudio;
    otCore.toggleLocalAudio(localaudio);

    otCore.communication.callProperties.publishVideo = localvideo;
    otCore.toggleLocalVideo(localvideo);
    this.setState({ localAudioEnabled: localaudio, localVideoEnabled:localvideo });

  }

  toggleLocalAudio() {
    if(!this.state.callEnded){
      otCore.communication.callProperties.publishAudio = !this.state.localAudioEnabled;
      otCore.toggleLocalAudio(!this.state.localAudioEnabled);
      this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
      let logType = (this.state.localAudioEnabled) ? 'UI_MIC_DISABLE' : 'UI_MIC_ENABLE'
      this.props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: logType,
        log_text: ''
      })
    }
  }

  toggleLocalVideo() {
    if(!this.state.callEnded){
      otCore.communication.callProperties.publishVideo = !this.state.localVideoEnabled;
      otCore.toggleLocalVideo(!this.state.localVideoEnabled);
      this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
      let logType = (this.state.localVideoEnabled) ? 'UI_CAMERA_DISABLE' : 'UI_CAMERA_ENABLE'
      this.props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: logType,
        log_text: ''
      })
    }
  }
  togglePresentation() {
    //this.setState({ sharingPresentation: !this.state.sharingPresentation });
  }

  startSharingScreen() {
    if(otCore.screenSharing){
      otCore.screenSharing.start();
    }
  }

  onHideMovie(){
    this.setState({
      isClickable: false
    });
  }

  //Esta funcion es llamada cuando el player de video esta listo para darle play
  onVideoReady(eventData)
  {
    //TODO: recibir el ID por parametros eventData
    const videoPlayerElement = document.querySelector('#videoPresentationPlayer_html5_api');

    if(videoPlayerElement)
    {
      if (!videoPlayerElement.captureStream && !videoPlayerElement.mozCaptureStream) 
      {
        let errorStreamCapture = this.props.intl.formatMessage({
          id: "VIDEOCHAT.ERROR.STREAM.CAPTURE"
        });
        this.props.log({
          logtype: 0,
          log_category: 'VA_ERROR',
          log_text: errorStreamCapture
        })
        alert(errorStreamCapture);

        this.props.closePresentation({
          displayScreenPresentation: false,
          videoManager: this.props.presentationBar.videoManager,
          code: this.props.presentationBar.presentationFocused.code,
          presentationFocused: {},
          focusedPresentationSlides: {},
          reason: 'VA_ERROR'
        });
        return;
      }

      const session = otCore.getSession();

      if(session)
      {
        const stream = videoPlayerElement.mozCaptureStream ? videoPlayerElement.mozCaptureStream() : videoPlayerElement.captureStream();

        /*
        if (!videoPlayerElement.captureStream && !videoPlayerElement.mozCaptureStream) {
          //Se utiliza para navegadores que no soportan captureStream ni mozCaptureStream, por ejemplo safari, Bug 24772
          navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
          }).then(stm => {
            stream = stm;
            stream.addEventListener('addtrack', publish);
            publish();
          }).catch(e => console.error(e));
        }else{
          stream = videoPlayerElement.mozCaptureStream ? videoPlayerElement.mozCaptureStream() : videoPlayerElement.captureStream();
        }*/


        const publish = () => {
          const videoTracks = stream.getVideoTracks();
          const audioTracks = stream.getAudioTracks();

          if (videoTracks.length > 0 && audioTracks.length > 0) 
          {
            stream.removeEventListener('addtrack', publish);

            let videopublisherProperties ={
              videoSource: videoTracks[0],
              audioSource: audioTracks[0],
              fitMode: 'contain',
              width: 320,
              height: 240,
              mirror: false,
              
              name: 'VIDEO',
              publishVideo : true,
              publishAudio : true
            };

            /* se tuvo que hacer el publisher manualmente ya que el otCore.startCall 
               ubica el elemento en streamcontainers['publisher']['camera]'
               y no permite pasarle un elemento

              otCore.startCall(videopublisherProperties)
              .then(({ publishers, subscribers, meta }) => {

                setTimeout(() => {
                  videoPlayerElement.play();
                }, 500);
              }
              ).catch((err) => {
                  alert(err);
                }
              );
            */
            
            videoPublisher = window.OT.initPublisher('publisherVideo', videopublisherProperties, (err) => {
              if (err) 
              {
                videoPlayerElement.pause();
                this.props.log({
                  logtype: 0,
                  log_category: 'CALL_ERROR',
                  log_text: err.message
                })
                stream.removeEventListener('addtrack', publish);
                if(err.code === 1500){
                  let errorStreamCustom = this.props.intl.formatMessage({
                    id: "VIDEOCHAT.ERROR.STREAM.CUSTOM"
                  });
                  alert(errorStreamCustom);
                }else{
                  alert(err.message);
                }
                this.props.closePresentation({
                  displayScreenPresentation: false,
                  videoManager: this.props.presentationBar.videoManager,
                  code: this.props.presentationBar.presentationFocused.code,
                  presentationFocused: {},
                  focusedPresentationSlides: {},
                  reason: 'CALL_ERROR'
                });
              } 
              else 
              {
                session.publish(videoPublisher, (err) => {
                  if (err)
                  {
                    this.props.log({
                      logtype: 0,
                      log_category: 'CALL_ERROR',
                      log_text: err.message
                    })
                    stream.removeEventListener('addtrack', publish);
                    if(err.code === 1500){
                      let errorStreamCustom = this.props.intl.formatMessage({
                        id: "VIDEOCHAT.ERROR.STREAM.CUSTOM"
                      });
                      alert(errorStreamCustom);
                    }else{
                      alert(err.message);
                    }
                    
                    this.props.closePresentation({
                      displayScreenPresentation: false,
                      videoManager: this.props.presentationBar.videoManager,
                      code: this.props.presentationBar.presentationFocused.code,
                      presentationFocused: {},
                      focusedPresentationSlides: {},
                      reason: 'CALL_ERROR'
                    });
                    //videoPublisher.publishVideo(false)
                    //this.toggleLocalVideo()
                    return;
                  }
                  else
                  {                    
                    setTimeout(() => {
                      videoPlayerElement.play();                      
                    }, 500);
                  }
                });
              }
            });

            videoPublisher.on('destroyed', () => {              
              //aca llega cuando se destruye el publisher (se remueve del dom)
              //TODO: loguear en call_log que se finalizo este pubisher
            });

          }
        };
        
        stream.addEventListener('addtrack', publish);
        publish();
      }    
    }
    else
    {
      alert("no video player found");
    }
  };

  
  //Este evento es invocado cuando se cierra la presentacion de tipo video, entonces hay que hacer un unpublish del stream
  onVideoDisposed()
  {
    const session = otCore.getSession();    

    if(session)
    {
      if(videoPublisher)
      {
        session.unpublish(videoPublisher);
      }

      /*
        Este codigo estaba cuando se llamaba a otCore.startCall

      let otCoreState = otCore.state();
      let publisherToDispose;

      //buscar un stream con nombre VIDEO
      if(otCoreState.publishers.custom)
      {
        Object.keys(otCoreState.publishers.custom).map((key, index) => {
          if(!publisherToDispose)
          {
            let temPublisher = otCoreState.publishers.custom[key];
            if(temPublisher && temPublisher.stream)
            {
              if(temPublisher.stream.name=='VIDEO')
              {
                publisherToDispose = temPublisher;
              }
            }
          }
        }
       );
      }

      if(!publisherToDispose && otCoreState.publishers && otCoreState.publishers.camera)
      {
        Object.keys(otCoreState.publishers.camera).map((key, index) => {
          if(!publisherToDispose)
          {
            let temPublisher = otCoreState.publishers.camera[key];
            if(temPublisher && temPublisher.stream)
            {
              if(temPublisher.stream.name=='VIDEO')
              {
                publisherToDispose = temPublisher;
              }
            }
          }
        }
       );
      }

      if(publisherToDispose)
      {
        session.unpublish(publisherToDispose);
      }
      */
    }

  };

  goFullLandScape = () => {
    this.setState({ isFull: !this.state.isFull, openMobileFullScreenAlert: false });
    if(isMobile || isIPhone13 || isIPad13){
      setTimeout(() => {
        if(this.state.isFull)
          this.lockOrientation("landscape-primary");
      }, 1000);
    }
  }

  handleCloseMobileFullScreenAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openMobileFullScreenAlert: false });
  };

  resetSnackBarFile = () => {
    this.props.resetSnackFile();
  }
  
  resetSnackBarRequest = () => {
    this.setState({snackRequest:{ open: false, severity: '', msg: ''}});
  }

  toggleExpand = () => {
    this.setState({isExpanded: !this.state.isExpanded})
  }
  
  onConfirm_Close() {
    this.setState({ confirmGoBackOpen: false });
  }

  onConfirm_GoBack() {
    this.setState({ confirmGoBackOpen: false });
    
    this.changeCallStatus(true);
  }
  
  render() {
    if (this.state.logout && !this.state.cancelCallDialog) {
      return <Redirect to="/logout" />
    } else {
      var { deployment, presentationBar, presentationSlides, getPresentationObject, auth, intl, global } = this.props;
      var btnClassStartCall = this.state.stateButtonStartCall;
      var disabledStartCallButton = this.state.disabledStartCallButton;

      const {
        connected,
        active,
        imgContact
      } = this.state;

      const deploymentStyle = {
        'accentColor': getDeploymentParamValue(deployment.parameters, "ACCENT_COLOR"),
      };

      const {
        localAudioClass,
        localVideoClass,
        localCallClass,
        controlClass,
        cameraPublisherClass,
        cameraSubscriberClass,
        presentationClass,
        hiddenCameras,
        camerasContainerPresentation,
        screenPublisherClass,
        screenSubscriberClass,
        hiddenSharingPresentationClass,
        minHeightSdva
      } = containerClasses(this.state, presentationBar, presentationSlides, auth.role, auth.deployment, disablePublish);

      let notesIsEnabled = false;
      if(this.props.deployment.parameters){
        notesIsEnabled = (getDeploymentParamValue(deployment.parameters, "CALL_NOTES_ENABLE") === "1");
      }

      let networkTestIsEnabled = false;
      if(this.props.deployment.parameters){
        networkTestIsEnabled = (getDeploymentParamValue(deployment.parameters, "CALL_TEST_ENABLE") === "1");
      }

      let mediaControlsIsEnabled = false;
      if(this.props.deployment.parameters){
        mediaControlsIsEnabled = (getDeploymentParamValue(deployment.parameters, "LOBBY_MEDIA_CONTROLS_ENABLE") === "1");
      }
      
      let lobbyVideoOptions = {};
      let customInviteVideo = this.props.deployment.callData.CUSTOM_INVITE_VIDEO_URL;
     
      let lobbyLogoUrl = "";
      if(this.props.deployment.parameters){
        lobbyLogoUrl = getDeploymentParamValue(deployment.parameters, "LOBBY_LOGO_URL");
      }
      
      if(customInviteVideo || (lobbyLogoUrl && lobbyLogoUrl.length > 0 && lobbyLogoUrl.slice(-4) === ".mp4")) {
        lobbyVideoOptions = {
          fluid: true,
          fill: false,
          autoplay: false,
          controls: true,
          aspectRatio: "4:3",
          sources: [{
            src: customInviteVideo || lobbyLogoUrl
          }]
        }
      }

      let lobbyTitle = "";
      if(this.props.deployment.parameters){
        lobbyTitle = getDeploymentParamValue(deployment.parameters, "TERMS_TITLE");
      }

      let isAmPm = "";
      let lobbyBody = "";
      isAmPm = getDeploymentParamValue(deployment.parameters, "TIME_AMPM")
      if(this.state.organizerNotConnected){
        lobbyBody = getDeploymentParamValue(deployment.parameters, "LOBBY_BODY_WAITING");
        if(lobbyBody != ""){
          lobbyBody = replaceTags(lobbyBody,this.props.auth.callCode,this.props.auth.timezone, this.props.deployment.name,
            this.props.deployment.callData.duedateUTC, this.props.deployment.callData.organizerName,
            this.props.deployment.callData.subscriberName, this.props.deployment.callData.subscriberId, isAmPm);
        }
      }else{
        if(this.props.deployment.parameters && this.props.deployment.callData){
          lobbyBody = getDeploymentParamValue(deployment.parameters, "LOBBY_BODY");
          if(lobbyBody != ""){
            lobbyBody = replaceTags(lobbyBody,this.props.auth.callCode,this.props.auth.timezone, this.props.deployment.name,
              this.props.deployment.callData.duedateUTC, this.props.deployment.callData.organizerName,
              this.props.deployment.callData.subscriberName, this.props.deployment.callData.subscriberId, isAmPm);
          }
        }
      }

      let chatEnabled = false;
      if(this.props.deployment.parameters){
        chatEnabled = (getDeploymentParamValue(deployment.parameters, "CHAT_ENABLE") === "1");
      }

      let filesEnabled = false;
      if(this.props.deployment.parameters){
        filesEnabled = (getDeploymentParamValue(deployment.parameters, "FILES_ENABLE") === "1");
      }

      let publisherLogStats = false;
      if(this.props.deployment.parameters){
        publisherLogStats = (getDeploymentParamValue(deployment.parameters, "PUBLISHER_LOG_STATS") === "1");
      }

      let fileDrawerTitle = intl.formatMessage({
        id: "FILE.DRAWER.TITLE"
      });

      const lobbyContainer = (
        <div className="row lobby-row">
          <div className="col-xl-12 d-flex justify-content-center lobby-container" style={{ marginBottom: 0 }}>
              <LobbyMask
                callEnded={this.state.callEnded}
                connected={connected}
                history={this.props.history}
                intl={this.props.intl}
                tryAgainButton={this.state.tryAgainButton} reloadCall={this.reloadCall} organizerNotConnected={this.state.organizerNotConnected}
                lobbyLogoUrl={lobbyLogoUrl}
                lobbyBody={lobbyBody}
                mediaControlsIsEnabled={mediaControlsIsEnabled} 
                networkTestIsEnabled={networkTestIsEnabled} 
                deployment={deployment} 
                toggleLocalAudio={this.toggleLocalAudio} 
                toggleLocalVideo={this.toggleLocalVideo} 
                controlClass={controlClass} 
                localAudioClass={localAudioClass} 
                localVideoClass={localVideoClass} 
                localAudioEnabled={this.state.localAudioEnabled} 
                localVideoEnabled={this.state.localVideoEnabled} 
                timezone={this.props.auth.timezone} 
                role={this.props.auth.role} 
                callData={this.props.deployment.callData} 
                auth={this.props.auth} 
                log={this.props.log} 
                getNetworkTest={this.props.getNetworkTest} 
                deploymentStyle={deploymentStyle} 
                start={this.startCall} 
                btnClassStartCall={btnClassStartCall} 
                disabledStartCallButton={(disabledStartCallButton || this.state.callEnded)} 
                imgContact={imgContact} 
                nullAuthToken={this.props.nullAuthToken}
                requestCallData={this.props.requestCallData}
                startWithAutoJoin={this.startWithAutoJoin}
                disablePublish={disablePublish}
                lobbyVideoOptions={lobbyVideoOptions}
              />
          </div>
        </div>
      );

      let recordingLabelText = document.getElementsByClassName('OT_archiving-status');
      if(recordingLabelText.length){
        if (this.props.auth.role === 'organizer' || this.props.auth.role === 'guest') 
        {
          let paramPUBLISHER_SHOW_ARCHIVING = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_SHOW_ARCHIVING");
          
          if(paramPUBLISHER_SHOW_ARCHIVING && paramPUBLISHER_SHOW_ARCHIVING != "" && paramPUBLISHER_SHOW_ARCHIVING=="1") 
          {
            recordingLabelText[0].innerText = intl.formatMessage({
              id: "ARCHIVING.ICON.LABEL"
            });
          }
        }
        else
        {
          let paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER = getDeploymentParamValue(this.props.deployment.parameters, "PUBLISHER_SHOW_ARCHIVING_CUSTOMER");
          
          if(paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER && paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER != "" && paramPUBLISHER_SHOW_ARCHIVING_CUSTOMER=="1") 
          {
            recordingLabelText[0].innerText = intl.formatMessage({
              id: "ARCHIVING.ICON.LABEL_CUSTOMER"
            });
          }
        }
      }

      return (
        <>
          {/*<div id='logDevice' style={{position:'absolute', top: 150, left: 150, backgroundColor:'green', width:250, height:250,color:'white', fontSize:20, zIndex:2500}}></div>*/}
          <Snackbar
            anchorOrigin={{ vertical:'top', horizontal:'right' }}
            open={this.state.snackRequest.open}
            key={'top' + 'right'}
            id="snackRequest"
            autoHideDuration={5000}
            onClose={this.resetSnackBarRequest}
          >
            <Alert id="alertSnackRequest" severity={this.state.snackRequest.severity}>
              {this.state.snackRequest.msg}
            </Alert>
          </Snackbar>
          {this.props.presentationBar.customerRequestDialogOpen &&
          <CustomerRequestDialog />
          }
          {connected && active &&
          <FabMenu 
            deployment={deployment} 
            accentColor={deploymentStyle.accentColor} 
            endCall={this.changeCallStatus} 
            organizer={(this.props.auth.role === 'organizer')} 
          />}
          {this.props.auth.role != 'organizer' && this.state.showMouseMove && this.state.positionX != 0 && this.state.positionY != 0 ?
            <Box
              bgcolor="transparent"
              color={deploymentStyle.accentColor}
              p={1}
              position="absolute"
              top={this.state.positionY - 8}
              left={this.state.positionX - 9}
              padding={0}
              zIndex="tooltip"
            >
              <SvgIcon fill={deploymentStyle.accentColor} stroke={deploymentStyle.accentColor} component={MousePointer} viewBox="0 0 481 653" />
            </Box> : null}
          <Fullscreen
            enabled={this.state.isFull} 
            onChange={isFull => this.setState({ isFull })}
          >

            {
              !active &&
                lobbyContainer 
            }

            <div id="callContainer" className="Call">
              <div className="App-main">
                <div className={`App-video-container ${minHeightSdva}`}>
                  <ReloadCall 
                    intl={intl} 
                    title={this.props.auth.callCode} 
                    errCode={this.state.otErrCode} 
                    errMsg={this.state.otErrMsg} 
                    open={this.state.reloadCallDialog} 
                    reloadCall={this.reloadCall} 
                    lang={this.props.auth.lang}
                  />
                  {this.props.auth.role == 'organizer' && <CancelCall open={this.state.cancelCallDialog} callStatus={this.changeCallStatus} endCall={this.endCall} signOutClick={this.state.signOutClick} callcode={this.props.global.auth.deployment.callData.code} notesIsEnabled={notesIsEnabled} endCallWithoutRedirect={this.endCallWithoutRedirect} />}
                  {this.props.auth.role == 'subscriber' && this.state.showFeedbackModal && <FeedBackPopUp setLogout={() => { this.setState({logout: true})} } />}
                  {connected && active && this.props.auth.role == 'organizer' && <Warning open={this.state.showTimeWarning} showWarning={this.showWarning} title={this.state.timeWarningTitle} text={this.state.timeWarningText}  buttonText={this.state.timeWarningButtonText}/>}
                  <div className={`${camerasContainerPresentation} ${hiddenCameras}`}>
                    <div id="cameraPublisherContainer" className={`${cameraPublisherClass} hidden`} />
                    <div id="cameraSubscriberContainer" className={`${cameraSubscriberClass} hidden`}/>
                  </div>
                  <div id="layoutContainer" style={{ bottom: (connected && active) ? 0 : 'none'}}>
                    <div id="publisherContainer" />
                    <div id="screenPublisherContainer" className={screenPublisherClass} />
                    <div id="screenSubscriberContainer" className={screenSubscriberClass} />
                    <div id="presentationContainer" className={presentationClass} style={{userSelect: 'none'}}>
                      <Presentation showMouseMove={this.state.showMouseMove} stopMouseMove={this.stopMouseMove} sendCoordinates={this.sendCoordinates} isClickable={this.state.isClickable} onHideMovie={this.onHideMovie} onVideoReady={this.onVideoReady} onVideoDisposed={this.onVideoDisposed} showLabel={this.state.isExpanded}  />
                    </div>
                  </div>

                  <div id="controls" className={controlClass} style={{ animationName: this.state.isExpanded ? 'fadeInUp' : 'fadeOutDown',animationDuration: '1.5s', animationDelay: 300, animationFillMode: 'forwards', zIndex: 9999 }}>
                    <a onClick={this.toggleExpand}>
                      <ClearIcon id="btnCollapse" style={{ color: '#FFFFFF', height: 30, width: 30, marginLeft: lowerThanTablet ? 0 : 7, marginRight: lowerThanTablet ? -6 : 7 }} />  
                    </a>
                      
                    <div className={localAudioClass} id="btnVideoChatSwitchAudio" onClick={this.toggleLocalAudio} />
                    <div className={localVideoClass} id="btnVideoChatSwitchVideo" onClick={this.toggleLocalVideo} />
                    <div className={localCallClass} id="btnVideoCancelCallPopup" onClick={() => this.changeCallStatus(true)} />
                    {auth.role === 'organizer' &&
                      <div className={`ots-video-control circle presentation ${hiddenSharingPresentationClass}`} onClick={this.togglePresentation}>
                        <PresentationBar deploymentStyle={deploymentStyle} changeIsFull={this.changeIsFull}/>
                      </div>
                    }
                    {chatEnabled ? <Chat isFull={this.state.isFull} /> : null}
                    {this.props.auth.role === 'organizer' && 
                    filesEnabled && 
                    <FileDrawer 
                      fileDrawerTitle={fileDrawerTitle} 
                      updateStateChatDrawer={this.props.updateStateChatDrawer} 
                      getFileList={this.props.getFileList}
                    />
                    }
                    <Snackbar
                      anchorOrigin={{ vertical:'top', horizontal:'left' }}
                      open={this.state.snackSyncVaid}
                      id="snackSyncVaid"
                      autoHideDuration={5000}
                      onClose={this.closeSyncVaid}
                      message={ intl.formatMessage({id: "CALL.SYNC.VAID.SNACK"})}
                      action={<React.Fragment>
                                <Button color="inherit" size="small" onClick={() => this.syncSdva()}> { intl.formatMessage({id: "PRESENTATION.BAR.PROJECT"})}</Button>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={this.closeSyncVaid}
                                >
                                <CloseIcon />
                                </IconButton>
                              </React.Fragment>}
                    />
                    <Snackbar
                      anchorOrigin={{ vertical:'top', horizontal:'right' }}
                      open={this.props.presentationBar.snackbarFile.open}
                      key={'top' + 'right'}
                      id="snackEmail"
                      autoHideDuration={5000}
                      onClose={this.resetSnackBarFile}
                    >
                      <Alert id="alertEmail" severity={this.props.presentationBar.snackbarFile.snackBarSeverity}>
                        {this.props.presentationBar.snackbarFile.snackMsg}
                      </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.openMobileFullScreenAlert && !(lowerThanTablet && (isIOS || isIPhone13))} autoHideDuration={10000} onClose={this.handleCloseMobileFullScreenAlert}>
                      <Alert severity="info" style={{ backgroundColor: '#A9A9A9' }} onClick={this.goFullLandScape} onClose={this.handleCloseMobileFullScreenAlert}>
                        {intl.formatMessage({id: "CALL.FULLSCREEN.ALERT"})}
                      </Alert>
                    </Snackbar>
                    {auth.role === 'organizer' && notesIsEnabled &&
                    <CallNotes callcode={this.props.global.auth.deployment.callData.code} isFull={this.state.isFull}/>
                    }
                    <div id="fullScreenButton" style={{cursor:"pointer"}}>
                    {this.state.isFull ?
                      <FullscreenExitIcon style={{ color: '#FFFFFF', height: 30, width: 30, marginLeft: lowerThanTablet ? -6 : 7 }} onClick={() => this.setState({isFull: !this.state.isFull})} />
                      :
                      <FullscreenIcon style={{ color: '#FFFFFF', height: 30, width: 30, marginLeft: lowerThanTablet ? -6 : 7 }} onClick={() => this.setState({isFull: !this.state.isFull})} /> 
                    }
                    </div>
                    {(auth.role === 'organizer' || auth.role === 'guest') && 
                    <MoreVertexMenu 
                      className="controls__item__more-vertex" 
                      params={this.props.deployment.parameters} 
                      role={this.props.auth.role} 
                      displayScreenPresentation={this.props.presentationBar.displayScreenPresentation}
                      startSharingScreen={this.startSharingScreen}
                      isBlurEnabled={this.state.isBlurEnabled}
                      startCameraBlur={this.startCameraBlur}
                      stopCameraBlur={this.stopCameraBlur}
                      isImageEnabled={this.state.isImageEnabled}
                      startCameraImage={this.startCameraImage}
                      stopCameraImage={this.stopCameraImage}
                      imageSelected={this.state.imageSelected}
                    />
                    }
                  </div>
                
                  <div id="controls" className={controlClass} style={{ animationName: !this.state.isExpanded ? 'fadeInUp' : 'fadeOutDown', animationDuration: '1.5s', animationDelay: 300, animationFillMode: 'forwards', bottom: '2%' }} >
                    <a onClick={this.toggleExpand}>
                      <KeyboardArrowUpIcon id="btnExpand" style={{ color: '#FFFFFF', height: 30, width: 30, marginLeft: 7, marginRight:7 }} />  
                    </a>
                  </div>
                  
                  <div className="timer__container">
                    
                    {connected && active && 
                      <CallTime publishers={this.state.publishers} publisherLogStats={publisherLogStats} isVisible={this.state.isExpanded} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Fullscreen>

          <ConfirmDialog
              open={this.state.confirmGoBackOpen}
              onClose={this.onConfirm_Close}
              handleAccept={this.onConfirm_GoBack}
              title={intl.formatMessage({id: "CONFIRM_DIALOG.GOBACK_CONFIRM.MESSAGE"})}
            />
        </>
      );
    }
  }


}

//const mapStateToProps = ({auth: deployment, PresentationBar}) => (deployment, PresentationBar);
const mapStateToProps = (state) => {
  return {
    global: state,
    deployment: state.auth.deployment,
    auth: state.auth,
    presentationBar: state.presentationBar,
    presentationSlides: state.presentationSlides,
    displayScreenPresentation: state.presentationBar.displayScreenPresentation,
    callClosedResult: state.presentationBar.callClosedResult
  }
}

export default injectIntl(
  connect(
    mapStateToProps,
    { ...presentationBarStorage.actions, ...loggerStorage.actions, ...authStorage.actions }
  )(VideoChat)
);