import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import './SearchBar.scss';

// Importando el duck de presentationBar cambiar logica de duck
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const CustomizedInputBase = function (props) {
  const { intl } = props;
  const globalState = useSelector(state => state);
  const [searchText, setSearchText] = useState(globalState.presentationBar.searchText);
  
  useEffect(() => {
    if(globalState.presentationBar.filterReset){
      setSearchText('')
    }
  }, [globalState.presentationBar.filterReset])

  useEffect(() => {
    if(searchText.length >= 1){
      props.filterSearch(searchText, true);
    }else{
      props.filterSearch('', false);
    }
  }, [searchText])
 
  const searchManager = () => event => {
    Object.assign(globalState.presentationBar, {
      searchText: event.target.value
    });

    setSearchText(event.target.value)
  }
 
  return (
    <Paper className="rootPaper">
      <InputBase
        className="inputBase"
        placeholder={intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        })}
        inputProps={{ 'aria-label': intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        }) }}
        onChange={searchManager()}
        value={searchText}
      />
      <IconButton type="submit" className="iconButton" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

/*const CustomizedInputBase = function (props) {
  const { intl } = props;
  const [search, setSearch] = useState("");
  const searchManager = () => event => {
    if(event.target.value.length >= 2){
      setSearch({search: event.target.value });
      props.filterSearch(event.target.value, true);
    }else{
      props.filterSearch('', false);
    }
  }

  return (
    <Paper className="rootPaper">
      <InputBase
        className="inputBase"
        placeholder={intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        })}
        inputProps={{ 'aria-label': intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        }) }}
        onChange={searchManager()}
      />
      <IconButton type="submit" className="iconButton" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}*/

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(CustomizedInputBase)
);