import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

// ESTO ES UN MOCK POR FAVOR SACARLO DEBUG
//import PresentationsObject from '../../../_metronic/__mocks__/mockPresentations';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import SearchBar from '../SearchBar/SearchBar';
import FilterBar from '../FilterBar/FilterBar';
import SlideList from '../SlideList/SlideList'
import PresentationList from '../PresentationList';
import './PresentationBar.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {isMobile,isIPad13, isIPhone13} from 'react-device-detect';

// Impoorting action types and methods from local actions file
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import * as authStorage from "../../store/ducks/auth.duck";

const useStyles = makeStyles((theme) => ({
  inRow: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    width: '90%',
  },
  filter: {
    width: '10%',
    paddingLeft: 10,
    paddingRight: 10
  }
}));

function PresentationBar(props) {
  // prueba de redux con hook DEBUG
  const globalState = useSelector(state => state)

  const classes = useStyles();

  const cleanStates = () => {
    props.updateCurrentFlow({});
    props.setFlows([]);
    props.updateSelectedIndexFlow(0);
    props.updateSelectedIndexSlide(0);
  }

  const toggleDrawer = (side, open, secondSideToClose) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') && event.type === 'click') {
      return;
    }

    //props.filterSearch('', false);
    Object.assign(globalState.presentationBar, { [side]: open, [secondSideToClose]: false })
    props.updateStateDrawers({ [side]: open, [secondSideToClose]: false });
  };

  const goBack = () => event => {
    if (!globalState.presentationBar.displayScreenPresentation) {
      props.goBack(Object.assign(globalState.presentationBar, { presentationFocused: {}, focusedPresentationSlides: {} }));
    } else {
      props.goBack(globalState.presentationBar);
    }

    cleanStates();
    toggleDrawer('showPresentationList', true, 'showSlideList')();
  }

  const showSlideList = (presentationSlide, open) => event => {
    if(event != undefined){

      if(presentationSlide.code !== globalState.presentationBar.presentationSlides.code){
        cleanStates();
      }

      toggleDrawer('showPresentationList', false);
        props.fillSlidesDrawer(presentationSlide.code);

        Object.assign(globalState.presentationBar, {
          showSlideList: open,
          presentationFocusedSlides: presentationSlide
        })
        props.updateStateDrawers({
          showSlideList: open,
          presentationFocusedSlides: presentationSlide
        });
    }
  };

  const stopPresentation = (reason) => event => {
    //console.log('entre por stop presentation del presentationBar')
    if(isMobile || isIPad13 || isIPhone13){
      unlockOrientation();
    }
    event.stopPropagation();
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_END',
      log_text: `code: ${globalState.presentationBar.presentationFocused.code} | reason: manual`
    })
    props.analyticsLogEvent('stop presentation');

    let fnCallbackClosePresentation = () => {
      props.closePresentation(Object.assign(globalState.presentationBar, {
        displayScreenPresentation: false,
        videoManager: globalState.presentationBar.videoManager,
        code: globalState.presentationBar.presentationFocused.code,
        presentationFocused: {},
        focusedPresentationSlides: {},
        //presentationsListFiltered: globalState.presentationBar.presentationsList.list,
        reason: reason
      }));
    }
    
    // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
    if(globalState.presentationBar.presentationFocused.type == 'sdva'){
      props.sendGotoSdvaSlide(
          {
              type: 'SDVA',
              command: 'HIDEFULLSCREENMOVIE',
              payload: 'NA'
          }
      );

      setTimeout(() => {
          fnCallbackClosePresentation();
      }, 1200);
    }else{
        fnCallbackClosePresentation();
    }

    // TODO: Analizar porque hay veces que no existe esta funcion, se agrega esta validacion para evitar que pinche
    if(props.stopMouseMove){
      props.stopMouseMove();
    }
  }

  const playFlow = (flowIndex, slideIndex = 0) => {
    const { presentationFlows, presentationFocusedSlides, presentationSlides } = globalState.presentationBar;
    const slides = presentationFlows.flows[flowIndex].slides;

    const focusedPresentationSlides = {
      ...presentationSlides, 
      content: slides,
      flows: (presentationFlows.flows && presentationFlows.flows.length > 0) ? presentationFlows.flows : []
    }

    props.setFocusedPresentationFlows({...presentationFlows, currentFlow: presentationFlows.flows[flowIndex]});
    props.setFocusedPresentationSlides(focusedPresentationSlides);

    props.fulfillPresentationBar({
      presentationFocused: {...presentationFocusedSlides, length: slides.length.toString()},
      code: focusedPresentationSlides.code,
      numSlide: slideIndex,
      displayScreenPresentation: true,
      initialSlideIndex: slideIndex
    });

    props.sendSlideFromGrid({
      presentationFocused: {...presentationFocusedSlides, length: slides.length.toString()},
      code: focusedPresentationSlides.code,
      numSlide: slideIndex,
      displayScreenPresentation: true
    });
    
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_START',
      log_text: `code: ${focusedPresentationSlides.code} | startAtOrder: ${slideIndex} | startAtCode: ${slides[0].code} `
    })
    props.analyticsLogEvent(`specific slide code: ${focusedPresentationSlides.code} | startAtOrder: ${slideIndex} | startAtCode: ${slides[0].code}`);

    if(slideIndex > 0) {
      setTimeout(() => {
        gotoSlideSDVA(slides[slideIndex]);
      }, [400]);
    }
    
    closeDrawer();
  }

  const hasCurrentFlow = () => Object.keys(globalState.presentationBar.presentationFlows.currentFlow).length > 0;

  const playSpecificSlide = () => {
    var indexOfSelectedSlide = globalState.presentationBar.presentationSlides.indexOfSelectedSlide;
    var indexOfSelectedFlow = globalState.presentationBar.presentationFlows.indexOfSelectedFlow;

    if(hasCurrentFlow()){
      playFlow(indexOfSelectedFlow, indexOfSelectedSlide);
      return;
    }

    globalState.presentationBar.focusedPresentationSlides = globalState.presentationBar.presentationSlides;
    Object.assign(
      globalState.presentationBar.focusedPresentationSlides,
      globalState.presentationBar.focusedPresentationSlides
    );

    Object.assign(globalState.presentationBar, {
      presentationFocused: globalState.presentationBar.presentationFocusedSlides,
      code: globalState.presentationBar.focusedPresentationSlides.code,
      numSlide: indexOfSelectedSlide,
      displayScreenPresentation: true,
      initialSlideIndex: indexOfSelectedSlide
    })

    props.sendSlideFromGrid({
      presentationFocused: globalState.presentationBar.presentationFocusedSlides,
      code: globalState.presentationBar.focusedPresentationSlides.code,
      numSlide: indexOfSelectedSlide,
      displayScreenPresentation: true
    });
    
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_START',
      log_text: `code: ${globalState.presentationBar.focusedPresentationSlides.code} | startAtOrder: ${indexOfSelectedSlide} | startAtCode: ${globalState.presentationBar.focusedPresentationSlides.content[indexOfSelectedSlide].code} `
    })
    props.analyticsLogEvent(`specific slide code: ${globalState.presentationBar.focusedPresentationSlides.code} | startAtOrder: ${indexOfSelectedSlide} | startAtCode: ${globalState.presentationBar.focusedPresentationSlides.content[indexOfSelectedSlide].code}`);

    closeDrawer();
  }

  const gotoSlideSDVA = (slide) => {
    let iframeWin = document.getElementById("inlineFrame").contentWindow;
    
    if(slide) {
        props.sendGotoSdvaSlide(
            {
                type: 'SDVA',
                command: 'GOTOSLIDE',
                payload: slide.name,
                isPreview: false
            }
        );
        
        let messageToSend = "SDVA_IN:GOTOSLIDE:" + slide.name;

        iframeWin.postMessage(messageToSend, "*");
    }
  } 

  function unlockOrientation () {
    /*var screen = window.screen;
    screen.orientation.unlock();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }*/
  }

  function lockOrientation(orientation) {
    /*var screen = window.screen;
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
    screen.orientation.lock(orientation);*/
  }

  // TODO: This method is passed to child components, refactor this architecture please 
  const startPresentation = (numSlide, presentationSlide, vaLogoUrl = "") => event => {
    if(isMobile || isIPad13 || isIPhone13){
      lockOrientation("landscape-primary");
    }

    switch(presentationSlide.type){
      case 'html5animate':
      case 'sdva':
        Object.assign(globalState.presentationBar, {
          initialSlideIndex: 0
        });
        
        props.updateStateScreenPresentation(Object.assign(globalState.presentationBar, {
          displayScreenPresentation: true,
          numSlide: numSlide,
          presentationFocused: presentationSlide,
          focusedPresentationSlides: presentationSlide,
          entryPoint: presentationSlide.entryPoint,
          config: presentationSlide.config,
          type: presentationSlide.type,
          vaLogoUrl: vaLogoUrl
        }));
        props.analyticsLogEvent(`start_presentation`);
      break;
      case 'video':
      case 'slides':
      default:
        props.updateStateScreenPresentation(Object.assign(globalState.presentationBar, {
          displayScreenPresentation: true,
          numSlide: numSlide,
          presentationFocused: presentationSlide,
          focusedPresentationSlides: presentationSlide,
          entryPoint: presentationSlide.entryPoint
        }));

    }
    
    //props.filterSearch('', false);
    closeDrawer();
  }

  function closeDrawer() {
    setTimeout(() => {
      var closeList = document.getElementById('closeSlideList');
      var closePresentationList = document.getElementById('closePresentationList');
      
      if(closeList){
        document.getElementById('closeSlideList').click();
      }
      if(closePresentationList){
        document.getElementById('closePresentationList').click();
      }
    }, 1000);
  }

  return (
    <div style={{ height: '100%' }}>
      {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData.type ==2) &&
        <Button id="btnPresentationBarShowList" onClick={toggleDrawer('showPresentationList', true)} style={{ height: '100%' }}> </Button>
      }
      <SwipeableDrawer
        open={globalState.presentationBar.showPresentationList}
        onClose={toggleDrawer('showPresentationList', false)}
        onOpen={toggleDrawer('showPresentationList', true)}
        ModalProps={{
          container: document.getElementById('callContainer'),
          style: { position: 'absolute' }
        }}
      >
        <div className="presentationList__header">
          <div className="presentationList__header__text">
            <FormattedMessage id="PRESENTATION.BAR.TITLE" />
            <span> (
              {globalState.presentationBar.presentationsListFiltered && globalState.presentationBar.presentationsListFiltered.length}
              /
              {globalState.presentationBar.presentationsList && globalState.presentationBar.presentationsList.list ? globalState.presentationBar.presentationsList.list.length : null}
            )</span>
          </div>
          <IconButton id="closePresentationList" onClick={toggleDrawer('showPresentationList', false)} className="presentationList__header__close">
            <CloseIcon style={{ color: props.deploymentStyle.accentColor }} />
          </IconButton>
        </div>
        
        <div className={classes.inRow}>
          <div className={classes.search}>
            <SearchBar />
          </div>
          
          <div className={classes.filter}>
            <FilterBar accentColor={props.deploymentStyle.accentColor} changeIsFull={props.changeIsFull} />
          </div>
        </div>

        <PresentationList 
          deploymentStyle={props.deploymentStyle} 
          side="showPresentationList" 
          toggleDrawer={toggleDrawer} 
          showSlideList={showSlideList} 
          startPresentation={startPresentation} 
          stopPresentation={stopPresentation} 
          props={props}
        />
      </SwipeableDrawer>

      {/*----------------------------------------------------------------------------------------------------*/}

      {/*----------------------------------------------------------------------------------------------------*/}
      <Drawer
        open={globalState.presentationBar.showSlideList}
        onClose={toggleDrawer('showSlideList', false)}
      >
        <div className="presentationList__header-sliders">
          <IconButton id="btnPresentationBarGoBack" onClick={goBack()} className="presentationList__header__left">
            <KeyboardBackspaceIcon style={{ color: props.deploymentStyle.accentColor }} />
          </IconButton>
          <div className="presentationList__header__text-sliders">
            {globalState.presentationBar.presentationFocusedSlides != undefined ? globalState.presentationBar.presentationFocusedSlides.name : <FormattedMessage id="PRESENTATION.BAR.TITLE.SLIDES" />}
          </div>
          <IconButton id="closeSlideList" onClick={toggleDrawer('showSlideList', false)} className="presentationList__header__close">
            <CloseIcon style={{ color: props.deploymentStyle.accentColor }} />
          </IconButton>
        </div>
        {<SlideList deploymentStyle={props.deploymentStyle} playSpecificSlide={playSpecificSlide} playFlow={playFlow}/>}
      </Drawer>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    {...presentationBarStorage.actions, ...loggerStorage.actions, ...authStorage.actions}
  )(PresentationBar)
);