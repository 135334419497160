import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { register } from "../../crud/auth.crud";
import { Redirect } from "react-router-dom";
import {version} from '../../../globalConfig';
import { metronic } from "../../../_metronic";
import moment from 'moment';
import { CircularProgress } from "@material-ui/core";
import './Registration.scss';
import {defaultStyles} from '../../../globalConfig'

function Registration(props) {
  const { intl } = props;

  const globalState = useSelector(state => state);

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    backgroundColor: defaultStyles.accent_color,
    borderColor: defaultStyles.accent_color
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ 
      paddingRight: "3.5rem",
      backgroundColor: defaultStyles.accent_color,
      borderColor: defaultStyles.accent_color
    });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ 
      paddingRight: "2.5rem",
      backgroundColor: defaultStyles.accent_color,
      borderColor: defaultStyles.accent_color
    });
  };

  const history = useHistory();
  
  let haveStorageItem = false;
  let storageItem = undefined;
  if (typeof(localStorage) !== "undefined") {  
    try{
      storageItem = localStorage.getItem('auth');
    }catch(e){
      console.log(e)
    }
  }

  if(storageItem != undefined)
  {
    storageItem = JSON.parse(storageItem);
    if(storageItem && storageItem.authToken)
    {
      if(
        storageItem.expires && 
        moment(storageItem.expires).utc().isAfter(moment().utc()) &&
        storageItem.email && 
        storageItem.deployment && 
        storageItem.role && 
        storageItem.userid && 
        //storageItem.timezone &&
        storageItem.lang &&
        storageItem.name &&
        storageItem.profileName
      )
      {
        haveStorageItem = true;
      }
      else
      {
        try{
          localStorage.removeItem('auth');
          storageItem = null;
        }catch(e){
          console.log(e);
        }
      }      
    }
    else
    {
      try{
        localStorage.removeItem('auth');
        storageItem = null;
      }catch(e){
        console.log(e)
      }
    }
  }

  useEffect(() => {
    
    if(haveStorageItem){
      if(
        storageItem.expires && 
        moment(storageItem.expires).utc().isAfter(moment().utc()) &&
        storageItem.email && 
        storageItem.deployment && 
        storageItem.role && 
        storageItem.userid && 
        //storageItem.timezone &&
        storageItem.lang &&
        storageItem.name &&
        storageItem.profileName
      ){
        try{
          props.setLanguage(storageItem.lang);
          props.register(
            storageItem.authToken, 
            storageItem.email, 
            storageItem.deployment, 
            storageItem.role, 
            storageItem.userid, 
            storageItem.timezone,
            storageItem.expires,
            storageItem.lang,
            storageItem.name,
            storageItem.profileName
          );
          props.getMenuAside();
        } catch(e) {
          console.log(e.message);
          haveStorageItem = false;
          try{
            localStorage.removeItem('auth');
          }catch(e){
            console.log(e);
          }
        }
        
      } else {
        haveStorageItem = false;
        try{
          localStorage.removeItem('auth');
        }catch(e){
          console.log(e);
        }
      }
    }
        
    
  }, [])
  

  if(globalState.auth.deploymentCode && globalState.auth.authToken){
//    return(<Redirect from="/auth/registration" to="/dashboard" />);
  }

  return (
    <>
    <div className="kt-login__head">
      <span className="kt-login__signup-label"></span>        
      <Link 
        id="btnLoginGoToLogin" 
        to="/auth/login" 
        className="kt-link kt-login__signup-link" 
        style={{color: defaultStyles.accent_color}}
      >
        <FormattedMessage id="AUTH.LINK.TO.LOGIN" />
      </Link>
    </div>
    <div className="kt-login__body">
      {haveStorageItem && 
      <div className="circularProgress">
        <CircularProgress className="kt-splash-screen__spinner" />
      </div>
      }
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            email: process.env.REACT_APP_EMAIL,
            password: process.env.REACT_APP_PASSWORD
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {

            enableLoading();

            setTimeout(() => {
              register(values.email, values.password)
                .then(({data}) => {
                  disableLoading();
                  props.setLanguage(data.lang)
                  props.register(
                    data.accesstoken, 
                    values.email, 
                    data.deployment, 
                    data.role, 
                    data.userid, 
                    data.timezone,
                    data.expires,
                    data.lang,
                    data.name,
                    data.profileName
                  );
                  props.getMenuAside();
                })
                .catch(() => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_LOGIN"
                    })
                  );
                });

                
            }, 100);


          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <div className="form-group mb-0">
                <TextField
                  label="Email"
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                  autoFocus
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD"
                  })}
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="kt-login__actions">
                <Link 
                    id="btnForgotPassword" 
                    to="/auth/forgot-password" 
                    className="kt-link kt-login__link-forgot" 
                    style={{color: defaultStyles.accent_color}}>
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>

                <button id="btnRegistrationEnter"
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={loadingButtonStyle}

                >
                  {intl.formatMessage({
                      id: "LOGIN.PAGE.LOGIN.BUTTON"
                  })}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
    <div className="kt_login_version">{version}</div>
    </>                
  );
}

export default injectIntl(
  connect(
    null,
    { ...auth.actions , ...metronic.i18n.actions }
  )(Registration)
);
