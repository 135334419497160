import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  log ,
  chatLog
} from "../../crud/logger.crud";
import moment from 'moment';


export const actionTypes = {
  INIT: "INIT",
  LOG: "LOG",
  CHATLOG: "CHATLOG",
  FILL_LOG: "FILL_LOG",
  FILL_LOG_ARR: "FILL_LOG_ARR",
  FILL_CHAT_LOG: "FILL_CHAT_LOG",
  FILL_CHAT_LOG_ARR: "FILL_CHAT_LOG_ARR",
  UPDATE_SEQUENCE: "UPDATE_SEQUENCE",
  UPDATE_CHAT_SEQUENCE: "UPDATE_CHAT_SEQUENCE",
  FLUSH: "FLUSH",
  FLUSHCHAT: "FLUSHCHAT"
};

const initialAuthState = {
  log_sequence: 0,
  chatLog_sequence: 0,
  session: Math.random().toString(36).substring(7), //genero un random por session de log asi es mas facil relacionar eventos
  log:{
    dateutc: new Date(), //DateTime.UtcNow.ToString("yyyy-MM-dd HH:mm:ss")
    userId: 0, // sacarlo del userid Logueado
    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
    log_category: '',
    log_text: ''
  },
  logArr: [],
  chatLogArr: [],
  chatLog:{
    dateutc: new Date(), //DateTime.UtcNow.ToString("yyyy-MM-dd HH:mm:ss")
    userId: 0, // sacarlo del userid Logueado
    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
    log_category: '',
    log_text: ''
  }
};


export const reducer = persistReducer(
    { storage, key: "demo1-logger", whitelist: ["logger"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.INIT: {
          return { ...state, ...action.payload };
        }
        case actionTypes.FILL_LOG: {
          return { ...state, log: action.payload };
        }
        case actionTypes.FILL_LOG_ARR: {
          return { ...state, logArr: action.payload };
        }
        case actionTypes.FILL_CHAT_LOG: {
          return { ...state, chatLog: action.payload };
        }
        case actionTypes.FILL_CHAT_LOG_ARR: {
          return { ...state, chatLogArr: action.payload };
        }
        case actionTypes.UPDATE_SEQUENCE: {
          return { ...state, log_sequence: action.payload };
        }
        case actionTypes.UPDATE_CHAT_SEQUENCE: {
          return { ...state, chatLog_sequence: action.payload };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  init: (value) => ({ type: actionTypes.INIT, payload: value }),
  log: (value) => ({ type: actionTypes.LOG, payload: value}),
  chatLog: (value) => ({ type: actionTypes.CHATLOG, payload: value}),
  fillLog: (value) => ({type: actionTypes.FILL_LOG, payload: value}),
  fillLogArr: (value) => ({ type: actionTypes.FILL_LOG_ARR, payload: value }),
  fillChatLog: (value) => ({type: actionTypes.FILL_CHAT_LOG, payload: value}),
  fillChatLogArr: (value) => ({ type: actionTypes.FILL_CHAT_LOG_ARR, payload: value }),
  updateSequence: (value) => ({type: actionTypes.UPDATE_SEQUENCE, payload: value}),
  updateChatSequence: (value) => ({type: actionTypes.UPDATE_CHAT_SEQUENCE, payload: value}),
  flush: () => ({type: actionTypes.FLUSH, payload: ''}),
  flushChat: () => ({type: actionTypes.FLUSHCHAT, payload: ''})
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LOG, function* initSaga({payload}) {
   try{
    const selector = yield select(getState);

    let callSession = '';
    let logArr = selector.logger.logArr;

    if(selector.auth.deployment.callData.callSession)
    {
      callSession = selector.auth.deployment.callData.callSession.toString();
    }

    payload.log_text = payload.log_text.replace(/\;/g, ''); 
    payload.log_text = payload.log_text.replace(/\,/g, ''); 
    payload.log_text = payload.log_text.replace(/\r?\n|\r/, " ");

    let objToSend = {
      dateutc: moment.utc().format("YYYY-MM-DD HH:mm:ss"), //DateTime.UtcNow.ToString("yyyy-MM-dd HH:mm:ss")
      userId: selector.auth.userid, // sacarlo del userid Logueado
      log_sequence: selector.logger.log_sequence + 1, // es un nro incremental que lleva el cliente por cada llamada, comienza en 1
      logtype: payload.logtype,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: payload.log_category,
      log_session: callSession + '_' + selector.logger.session,
      log_text: payload.log_text
    }

    logArr.push(objToSend);
    yield put(actions.updateSequence(selector.logger.log_sequence + 1));
    
    if(logArr.length >= 5)
    {
      let strBody = '';

      logArr.forEach(element => {
        for(let prop in element){
          strBody += element[prop];
          if(prop != 'log_text'){
            strBody += ';'
          }
        }
        strBody += String.fromCharCode(10);    
      });

      yield log(selector.auth.deployment.callData.CALL_LOG_URL, strBody);
      yield put(actions.fillLogArr([]));
    }
    else
    {
      yield put(actions.fillLogArr(logArr));
    }
   }catch(e){
     //console.log(e)
   }
    
  });

  yield takeLatest(actionTypes.CHATLOG, function* chatLogSaga({payload}) {
   try{
      const selector = yield select(getState);

      let callSession = '';
  
      if(selector.auth.deployment.callData.callSession)
      {
        callSession = selector.auth.deployment.callData.callSession.toString();
      }

      let chatLogArr = selector.logger.chatLogArr;
  
      let objToSend = {
        dateutc: moment.utc().format("YYYY-MM-DD HH:mm:ss"), //DateTime.UtcNow.ToString("yyyy-MM-dd HH:mm:ss")
        userId: selector.auth.userid, // sacarlo del userid Logueado
        chatLog_sequence: selector.logger.chatLog_sequence + 1, // es un nro incremental que lleva el cliente por cada llamada, comienza en 1
        logtype: payload.logtype,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: payload.log_category,
        log_session: callSession + '_' + selector.logger.session,
        log_text: payload.log_text
      }
  
      chatLogArr.push(objToSend);
      yield put(actions.updateChatSequence(selector.logger.chatLog_sequence + 1));
      
      if(chatLogArr.length >= 5){
        let strBody = '';
  
        chatLogArr.forEach(element => {
          for(let prop in element){
            strBody += element[prop];
            if(prop != 'log_text'){
              strBody += ';'
            }
          }
          strBody += String.fromCharCode(10);    
        });
  
        //console.log(strBody);
  
        yield log(selector.auth.deployment.callData.CHAT_LOG_URL, strBody);
        yield put(actions.fillChatLogArr([]));
      }else{
        yield put(actions.fillChatLogArr(chatLogArr));
      }
    }catch(e){
      //console.log(e)
    }   
  });
  
  yield takeLatest(actionTypes.FLUSH, function* flushSaga() {
    try{
      const selector = yield select(getState);

      let logArr = selector.logger.logArr;

      let strBody = '';

      if(logArr.length > 0){
        logArr.forEach(element => {
          for(let prop in element){
            strBody += element[prop];
            if(prop != 'log_text'){
              strBody += ';'
            }
          }
          strBody += String.fromCharCode(10);    
        });
        yield log(selector.auth.deployment.callData.CALL_LOG_URL, strBody);
        yield put(actions.fillLogArr([]));
      }else{
        //console.log('no habia elementos');
      }

      //console.log(strBody);
    }catch(e){
      //console.log(e)
    }
  });

  yield takeLatest(actionTypes.FLUSHCHAT, function* flushSaga() {
    try{
      const selector = yield select(getState);

      //console.log(selector);
      let chatLogArr = selector.logger.chatLogArr;
  
      let strBody = '';
  
      //console.log('entre al ciclo');
  
      if(chatLogArr.length > 0){
        chatLogArr.forEach(element => {
          for(let prop in element){
            strBody += element[prop];
            if(prop != 'log_text'){
              strBody += ';'
            }
          }
          strBody += String.fromCharCode(10);    
        });
        yield chatLog(selector.auth.deployment.callData.CHAT_LOG_URL, strBody);
        yield put(actions.fillChatLogArr([]));
      }else{
        //console.log('no habia elementos');
      }
  
      //console.log(strBody);
    }catch(e){
      //console.log(e)
    }
    

});
}
