import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import axios from "axios";
import {configUrls} from '../../../globalConfig';
import {LayoutSplashScreen} from '../../../_metronic'
import { Redirect, Route, Switch } from "react-router-dom";
import {lang} from '../../../globalConfig';

const VideoPlayerPage = (props) => {
  let {callcode, archiveId} = useParams();
  const [videoJsOptions, setVideoJsOptions] = useState({
    fuid: true,
    fill: true,
    autoplay: false,
    controls: true,
    sources: [{
      src: ""
    }]
  });
  const [loading, setLoading] = useState(true);

  let token = new URLSearchParams(props.history.location.search).get("token");
  let language = new URLSearchParams(props.history.location.search).get("lang");
  if(!language || language == ""){
    language = lang;
  }

  useEffect(() => {
    let newAxiosInstance = axios.create();
    if(token && callcode && archiveId){
      const GET_FILE_URL = `${configUrls.base_url}/api/callarchive/${callcode}/${archiveId}/getfile?token=${token}&lang=${language}`;
      newAxiosInstance.get(GET_FILE_URL).then((response) => {
        if(response.data && response.data.url){
          setVideoJsOptions({
              ...videoJsOptions, 
              sources: [{
                src:response.data.url
              }]
            }
          );
          setLoading(false);
        }
      })
      .catch(error => {
        props.history.push('/error');
      });
    }else{
      props.history.push('/error');
    }
  }, [])

  const events = ['pause', 'play', 'ended', 'volumechange', 'timeupdate', 'seeked'];

  const categoryEventTypes = {
    play: 'VIDEO_PLAY',
    pause: 'VIDEO_PAUSE',
    ended: 'VIDEO_STOP',
    mute: 'VIDEO_MUTE',
    unmute: 'VIDEO_UNMUTE',
    seeked: 'VIDEO_SEEK',
    timeupdate: 'VIDEO_PROGRESS'
  };

  // validar los 3 parametros callcode - archiveId - token 
  // parametro lang es opcional cualquier cosa usamos el de globalConfig
  
  return(
    <div style={{height:'100%'}}>
      {loading &&
      <LayoutSplashScreen />
      }
      {videoJsOptions.sources[0].src != "" &&
      <VideoPlayer fullScreen={true} events={events} logdelay={10000} { ...videoJsOptions } />
      }
    </div>
  )
}

export default VideoPlayerPage;