import React, { useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';

import * as visualAidStorage from "../../store/ducks/visualAid.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const CreateVisualAidButton = (props) => {

  const {intl} = props;
  
  const options = [
    {
      name:'Slides', 
      code:'slides'
    },
    {
      name:'Micrositio - Sales Driver', 
      code:'sdva'
    },
    {
      name: 'Html5 - Animate',
      code: 'html5animate'
    },
    {
      name: intl.formatMessage({id: "VAID.OPTIONS.VIDEO"}),
      code: 'video'
    }
  ]

  const globalState = useSelector(state => state);

  let typesDisabled = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_TYPES_DISABLED");
  //let typesDisabled = 'slides,sdva'
  typesDisabled = typesDisabled.split(',');
  let optionsSaned = [];

  if(typesDisabled.length > 0){
    options.forEach((option) => {
      let flag = false;
      typesDisabled.forEach((typeDisabled) => {
        if(typeDisabled === option.code){
          flag = true;
        }
      });
      if(flag === false){
        optionsSaned.push(option)
      }
    });
  }else{
    optionsSaned = options;
  }


  const classes = useStyles();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [inputText, setInputText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [typeSelected, setTypeSelected] = React.useState(optionsSaned[0].code);
  
  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  useEffect(() => {
    if(globalState.visualAid.visualAidContainerResponse.code && 
      globalState.visualAid.visualAidContainerResponse.code != null && 
      globalState.visualAid.visualAidContainerResponse.redirection === true){
      doRedirection(globalState.visualAid.visualAidContainerResponse.code);
      setOpen(false);
    }
  }, [globalState.visualAid.visualAidContainerResponse])
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setInputText('');

    setOpen(false);
  }
  
  const handleChange = (event) => {
    setIsCreateDisabled(false);

    setTypeSelected(event.target.value);
  };
  
  const handleChangeText = (event) => {
    setInputText(event.target.value);
  }

  const handleAccept = () => {
    setIsCreateDisabled(true);

    props.createVisualaidContainer({
      type: typeSelected,
      name: inputText
    });
  }

  const doRedirection = (code) => {
    if(typeSelected == 'slides'){
      history.push("/visualaid/slide/update/" + code);
    }else if(typeSelected == 'sdva'){
      history.push("/visualaid/sdva/update/" + code);
    }else if(typeSelected == 'html5animate'){
      history.push("/visualaid/html5animate/update/" + code);
    }else if(typeSelected == 'video'){
      history.push("/visualaid/video/update/" + code);
    }
  }

  return(
    <>
      <Button className="btn btn-sm btn-label-success" id="btnCreateVisualAid" onClick={handleOpen}>
        <AddCircleIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="VAID.LIST_PAGE.CREATE_BUTTON" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { setIsDisabled(true) }}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="VAID.POPUP.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className="popup__input__container">
              <FormControl className={classes.formControl} disabled={globalState.presentationBar.customerSelected.disabled}>
                <InputLabel id="demo-simple-select-helper-label"><FormattedMessage id="VAID.POPUP.SELECTOR.LABEL" /></InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={typeSelected}
                  onChange={handleChange}
                >
                  {optionsSaned.map((item, index) => {
                    return (<MenuItem key={index} value={item.code}>{item.name}</MenuItem>);
                  })}
                </Select>
                <TextField
                  id="datetime-local"
                  label={intl.formatMessage({
                    id: "VAID.POPUP.INPUT"
                  })}
                  type="text"
                  value={inputText}
                  onChange={handleChangeText}
                  fullWidth
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnNewVisitCreate" onClick={handleAccept} disabled={isCreateDisabled}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnNewVisitClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(CreateVisualAidButton)
);