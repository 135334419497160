import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getSlides,
  getPresentationObject,
  getCallsByDate,
  createCall,
  sendEmail,
  closeCall,
  getCustomerTypeList,
  getCustomerListByType,
  getCustomerEntireList,
  saveNote,
  getNetworkTest,
  sendFeedback,
  getFileList,
  sendFileEmail,
  sendVaidFileEmail,
  getCallHistory,
  callSession,
  sendSlideFeedback,
  getCallInfo,
  getProductsByCall,
  getTermsByCall,
  makeProductsRequest,
  makeTermsRequest,
  getCustomerRequestData,
  getTermByCode,
  uploadSignatureCrud,
  rejectCustomerRequest,
  acceptCustomerRequestWithoutSign
} from '../../crud/presentationBar.crud';
import * as moment from 'moment';
import * as loggerStorage from "./logger.duck";
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';
import {
  trackException
} from '../../components/VideoChat/utils';
import { getFlowSlides, getFlowUrl } from "../../components/SlideList/utils";

export const actionTypes = {
  UPDATE_STATE_DRAWERS: "UPDATE_STATE_DRAWERS",
  UPDATE_SCREEN_PRESENTATION: "UPDATE_SCREEN_PRESENTATION",
  UPDATE_FOCUSED_PRESENTATION_SLIDES: "UPDATE_FOCUSED_PRESENTATION_SLIDES",
  GET_PRESENTATION_OBJECT: "GET_PRESENTATION_OBJECT",
  PUBLISH_VIDEO_MANAGER: "PUBLISH_VIDEO_MANAGER",
  UPDATE_SLIDE_ACTIVE: "UPDATE_SLIDE_ACTIVE",
  RESET_PRESENTATION_VARS: "RESET_PRESENTATION_VARS",
  SEND_SLIDE_ACTIVE: "SEND_SLIDE_ACTIVE",
  CLOSE_PRESENTATION_SCREEN: "CLOSE_PRESENTATION_SCREEN",
  CLIENT_END_PRESENTATION: "CLIENT_END_PRESENTATION",
  GO_BACK: "GO_BACK",
  GET_PRESENTATIONS: "GET_PRESENTATIONS",
  FULLFILL_PRESENTATIONS: "FULLFILL_PRESENTATIONS",
  FILL_SLIDES_DRAWER: "FILL_SLIDES_DRAWER",
  FILL_SLIDES: "FILL_SLIDES",
  SET_SELECTED_SLIDE: "SET_SELECTED_SLIDE",
  SEND_SLIDE_FROM_GRID: "SEND_SLIDE_FROM_GRID",
  FILTER_SEARCH: "FILTER_SEARCH",
  UPDATE_FILTERED_LIST: "UPDATE_FILTERED_LIST",
  GET_CALLS_BY_DATE: "GET_CALLS_BY_DATE",
  GET_CALL_HISTORY: "GET_CALL_HISTORY",
  FILL_CALL_HISTORY: "FILL_CALL_HISTORY",
  SET_CALL_HISTORY_REQUESTED: "SET_CALL_HISTORY_REQUESTED",
  SET_CALL_ITEM_SELECTED: "SET_CALL_ITEM_SELECTED",
  FILL_CALLS_BY_DATE: "FILL_CALLS_BY_DATE",
  CREATE_CALL: "CREATE_CALL",
  GET_RESULT_CREATE_CALL: "GET_RESULT_CREATE_CALL",
  UPDATE_NEW_CALL: "UPDATE_NEW_CALL",
  RESET_CALL_NEW: "RESET_CALL_NEW",
  GET_CUSTOMER_TYPE_LIST: "GET_CUSTOMER_TYPE_LIST",
  FILL_CUSTOMER_TYPE_LIST: "FILL_CUSTOMER_TYPE_LIST",
  GET_CUSTOMER_LIST_BY_TYPE: "GET_CUSTOMER_LIST_BY_TYPE",
  FILL_CUSTOMER_LIST_BY_TYPE: "FILL_CUSTOMER_LIST_BY_TYPE",
  GET_CUSTOMER_ENTIRE_LIST: "GET_CUSTOMER_ENTIRE_LIST",
  FILL_CUSTOMER_ENTIRE_LIST: "FILL_CUSTOMER_ENTIRE_LIST",
  UPDATE_CUSTOMER_SELECTED: "UPDATE_CUSTOMER_SELECTED",
  UPDATE_VISITTYPE_SELECTED: "UPDATE_VISITTYPE_SELECTED",
  SET_CUSTOMERTYPE_SELECTED: "SET_CUSTOMERTYPE_SELECTED",
  RESET_ALL: "RESET_ALL",
  SET_CREATE_CALL_ERROR_VISIBLE: "SET_CREATE_CALL_ERROR_VISIBLE",
  SET_CREATE_CALL_SUCCESS_VISIBLE: "SET_CREATE_CALL_SUCCESS_VISIBLE",
  SET_EMAIL_RESULT: "SET_EMAIL_RESULT",
  SET_TIMER: "SET_TIMER",
  SET_CALL_TIME: "SET_CALL_TIME",
  SEND_EMAIL: "SEND_EMAIL",
  CLOSE_CALL: "CLOSE_CALL",
  UPDATE_PRESENTATION_SDVA: "UPDATE_PRESENTATION_SDVA",
  SET_IFRAME_PRESENTATION: "SET_IFRAME_PRESENTATION",
  SEND_GOTO_SDVA_SLIDE: "SEND_GOTO_SDVA_SLIDE",
  SET_PRESENTATION_CLIENT_FOCUSED: "SET_PRESENTATION_CLIENT_FOCUSED",
  UPDATE_STATE_CHAT_DRAWER: "UPDATE_STATE_CHAT_DRAWER",
  SEND_CHAT_MESSAGE: "SEND_CHAT_MESSAGE",
  RECIEVE_CHAT_MESSAGE: "RECIEVE_CHAT_MESSAGE",
  PUSH_TO_MESSAGE_ARRAY: "PUSH_TO_MESSAGE_ARRAY",
  RESET_CHAT_VARS: "RESET_CHAT_VARS",
  SET_CALL_ACTIVE: "SET_CALL_ACTIVE",
  SET_LOBBY_STATE: "SET_LOBBY_STATE",
  ADD_CURRENT_SUBSCRIBER: "ADD_CURRENT_SUBSCRIBER",
  GET_NETWORK_TEST: "GET_NETWORK_TEST",
  FILL_NETWORK_TEST: "FILL_NETWORK_TEST",
  SEND_FEEDBACK: "SEND_FEEDBACK",
  GET_FILE_LIST: "GET_FILE_LIST",
  SET_FILE_LIST: "SET_FILE_LIST",
  SEND_FILE_EMAIL: "SEND_FILE_EMAIL",
  PUBLISH_OTCORE: "PUBLISH_OTCORE",
  ADD_FILE_TO_LIST: "ADD_FILE_TO_LIST",
  REMOVE_FILE_FROM_LIST: "REMOVE_FILE_FROM_LIST",
  SEND_SIGNAL_FILE: "SEND_SIGNAL_FILE",
  RECEIVE_FILE_SHARED: "RECEIVE_FILE_SHARED",
  FILE_SEARCH: "FILE_SEARCH",
  SET_FILTERED_FILE_SEARCH: "SET_FILTERED_FILE_SEARCH",
  SET_FILE_EMAIL_POPUP_OPEN: "SET_FILE_EMAIL_POPUP_OPEN",
  SET_OPEN_SNACKBAR_FILE_SHARED: "SET_OPEN_SNACKBAR_FILE_SHARED",
  SET_SNACK_FILE: "SET_SNACK_FILE",
  RESET_SNACK_FILE: "RESET_SNACK_FILE",
  SET_STATS: "SET_STATS",
  SEND_VISUAL_AID_EMAIL: "SEND_VISUAL_AID_EMAIL",
  SET_LOADING: "SET_LOADING",
  EXECUTE_ACTION_AAN: "EXECUTE_ACTION_AAN",
  CALL_CLOSED_RESULT: "CALL_CLOSED_RESULT",
  CALL_SESSION: "CALL_SESSION",
  FILTER_RESET: "FILTER_RESET",
  SET_SIGN_OUT_CLICK: "SET_SIGN_OUT_CLICK",
  SEND_SLIDE_FEEDBACK: "SEND_SLIDE_FEEDBACK",
  SET_SEND_SLIDE_FEEDBACK_RESULT: "SET_SEND_SLIDE_FEEDBACK_RESULT",
  SET_VA_SIZE: "SET_VA_SIZE",
  GET_CALL_FEEDBACK: "GET_CALL_FEEDBACK",
  FILL_CALL_FEEDBACK: "FILL_CALL_FEEDBACK",
  RESET_CALL_FEEDBACK: "RESET_CALL_FEEDBACK",
  FILL_RESPONSE_SLIDES_DRAWER: "FILL_RESPONSE_SLIDES_DRAWER",
  RESET_SELECTED_AND_ACTIVE_SLIDE: "RESET_SELECTED_AND_ACTIVE_SLIDE",
  ERROR_GETTING_DATA: "ERROR_GETTING_DATA",
  GET_PRODUCTS_BY_CALL: "GET_PRODUCTS_BY_CALL",
  FILL_PRODUCTS_BY_CALL: "FILL_PRODUCTS_BY_CALL",
  GET_TERMS_BY_CALL : "GET_TERMS_BY_CALL",
  FILL_TERMS_BY_CALL: "FILL_TERMS_BY_CALL",
  MAKE_PRODUCTS_REQUEST: "MAKE_PRODUCTS_REQUEST",
  MAKE_PRODUCTS_REQUEST_RESULT: "MAKE_PRODUCTS_REQUEST_RESULT",
  MAKE_TERMS_REQUEST: "MAKE_TERMS_REQUEST",
  MAKE_TERMS_REQUEST_RESULT: "MAKE_TERMS_REQUEST_RESULT",
  SEND_CUSTOMER_REQUEST_SIGNAL: "SEND_CUSTOMER_REQUEST_SIGNAL",
  SET_CUSTOMER_REQUEST_DIALOG_OPEN: "SET_CUSTOMER_REQUEST_DIALOG_OPEN",
  GET_CUSTOMER_REQUEST_DATA: "GET_CUSTOMER_REQUEST_DATA",
  FILL_CUSTOMER_REQUEST_DATA: "FILL_CUSTOMER_REQUEST_DATA",
  GET_TERM_BY_CODE: "GET_TERM_BY_CODE",
  FILL_TERM_DATA: "FILL_TERM_DATA",
  FILL_SIGNATURE: "FILL_SIGNATURE",
  UPLOAD_SIGNATURE: "UPLOAD_SIGNATURE",
  FILL_UPLOAD_SIGNATURE_RESULT: "FILL_UPLOAD_SIGNATURE_RESULT",
  SET_CUSTOMER_REQUEST_CODE: "SET_CUSTOMER_REQUEST_CODE",
  REJECT_CUSTOMER_REQUEST: "REJECT_CUSTOMER_REQUEST",
  ACCEPT_CUSTOMER_REQUEST_WITHOUT_SIGN: "ACCEPT_CUSTOMER_REQUEST_WITHOUT_SIGN",
  FILL_ACCEPT_REQUEST_RESULT: "FILL_ACCEPT_REQUEST_RESULT",
  SET_NOTES_DRAWER_OPEN_STATE: "SET_NOTES_DRAWER_OPEN_STATE",
  SET_FLOWS: "SET_FLOWS",
  UPDATE_CURRENTFLOW: "UPDATE_CURRENTFLOW",
  UPDATE_SELECTED_INDEX_FLOW: "UPDATE_SELECTED_INDEX_FLOW",
  UPDATE_SELECTED_INDEX_SLIDE: "UPDATE_SELECTED_INDEX_SLIDE",
  SET_FOCUSEDPRESENTATION_FLOWS: "SET_FOCUSEDPRESENTATION_FLOWS",
  SET_FOCUSEDPRESENTATION_SLIDES: "SET_FOCUSEDPRESENTATION_SLIDES",
  FULFILL_PRESENTATIONBAR: "FULFILL_PRESENTATIONBAR",
  UPDATE_PRESENTATIONFOCUSED_LENGTH: "UPDATE_PRESENTATIONFOCUSED_LENGTH",
  SEND_FLOW_ACTIVE: "SEND_FLOW_ACTIVE",
  SET_IS_REQUEST_FROM_CUSTOMER: "SET_IS_REQUEST_FROM_CUSTOMER"
};

const initialState = {
  presentationFocused: {
    length: ""
  },
  focusedPresentationSlides: {},
  focusedPresentationFlows: {
    indexOfSelectedFlow: 0,
    currentFlow: {},
    flows: []
  },
  displayScreenPresentation: false,
  showPresentationList: false,
  showSlideList: false,
  numSlide: 0,
  presentationsList: {},
  presentationsListFiltered: [],
  presentationSlides: {
    selectedSlideCode: null,
    indexOfSelectedSlide: 0
  },
  presentationFlows: {
    indexOfSelectedFlow: 0,
    currentFlow: {},
    flows: []
  },
  calls: [],
  emailSuccess: false,
  emailResult: '',
  callNew: {
    date: new Date(),
    open: false,
    error: false
  },
  loading: false,
  customerTypeList: [],
  customerSelected: {},
  visitTypeSelected: {
    name:'online visit', 
    code:1
  },
  customerTypeSelected: null,
  customerListByType: [],
  customerList: [],
  callHistoryList: [],
  currentSubscribersGlobal: [],
  callItemSelected: null,
  callHistoryRequested: false,
  createCallSuccessVisible: false,
  createCallErrorVisible: false,
  callCreated: {},
  callTime: false,
  callTimeSeconds: 0,
  showChatDrawer: false,
  chat:{
    messages: []
  },
  firebase: {},
  callActive: false,
  lobbyState: false,
  selectedFrom: null,
  selectedTo: null,
  networkTest: {},
  showFilesDrawer: false,
  fileList:[],
  fileListShare: [],
  fileListFiltered: [],
  otCore: null,
  fileEmailPopUpOpen: false,
  openSnackBarFileShared: false,
  snackbarFile: {
    open: false, 
    snackMsg: '',
    snackBarSeverity: ''
  },
  stats: null,
  callClosedResult: null,
  filterText: '',
  filterReset: false,
  sigOutClick: false,
  slideFeedbackResult: { codeCall: null, codeSlide: null, message: null },
  feedback:{},
  responseSlidesDrawer: null,
  errorGettingData: null,
  products: [],
  selectedTagFilter: null,
  filters: {},
  aggregations : {},
  searchText: '',
  terms: [],
  makeProductsRequestResult: {status:null},
  makeTermsRequestResult: {status:null},
  customerRequestDialogOpen: false,
  customerRequestCode: 0,
  customerRequestData: {},
  getTermByCodeResult: {},
  signature: "",
  uploadSignatureResult: null,
  acceptRequestResult: null,
  notesDrawerOpenState: false,
  isRequestFromCustomer: false
}

export const reducer = persistReducer(
  { storage, key: "appState", whitelist: ["state"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.UPDATE_STATE_DRAWERS:
//        console.log('UPDATE_STATE_DRAWERS', action.payload)
        return Object.assign({}, state, { ...action.payload })
      case actionTypes.RESET_PRESENTATION_VARS:
//        console.log('RESET_PRESENTATION_VARS', action.payload)
        return Object.assign({}, state, { ...action.payload })
      case actionTypes.UPDATE_SCREEN_PRESENTATION:
//        console.log('UPDATE_SCREEN_PRESENTATION', action.payload)
        return Object.assign({}, state, { ...action.payload })
      case actionTypes.UPDATE_FOCUSED_PRESENTATION_SLIDES:
//        console.log('UPDATE_FOCUSED_PRESENTATION_SLIDES', action.payload)
        return Object.assign({}, state, {
          focusedPresentationSlides: { ...action.payload.data },
          numSlide: action.payload.numSlide
        });
      case actionTypes.SET_PRESENTATION_CLIENT_FOCUSED:
//        console.log('SET_PRESENTATION_CLIENT_FOCUSED', action.payload)
        return Object.assign({}, state, {
          presentationFocused: { ...state.focusedPresentationSlides },
          numSlide: action.payload.numSlide
        });
      case actionTypes.PUBLISH_VIDEO_MANAGER:
//        console.log('PUBLISH_VIDEO_MANAGER', action.payload)
        return Object.assign({}, state, { videoManager: { ...action.payload } });
      case actionTypes.UPDATE_SLIDE_ACTIVE:
//        console.log('UPDATE_SLIDE_ACTIVE', action.payload)
        return Object.assign({}, state, { numSlide: action.payload.numSlide });
      case actionTypes.SEND_SLIDE_ACTIVE:
//        console.log('SEND_SLIDE_ACTIVE', action.payload)
        return Object.assign({}, state, { numSlide: action.payload.numSlide });
      case actionTypes.CLOSE_PRESENTATION_SCREEN:
//        console.log('CLOSE_PRESENTATION_SCREEN', action.payload)
        return Object.assign({}, state, { ...action.payload });
      case actionTypes.CLIENT_END_PRESENTATION:
//        console.log('CLIENT_END_PRESENTATION', action.payload)
        return Object.assign({}, state, { ...action.payload });
      case actionTypes.GO_BACK:
//        console.log('GO_BACK', action.payload)
        return Object.assign({}, state, { ...action.payload });
      case actionTypes.GET_PRESENTATIONS:
//        console.log('GET_PRESENTATIONS', action.payload)
        return Object.assign({}, state, { ...action.payload });
      case actionTypes.FULLFILL_PRESENTATIONS:
//        console.log('FULLFILL_PRESENTATIONS', action.payload)
        return Object.assign({}, state, {
          presentationsList: action.payload,
          numSlide: action.payload.numSlide,
          presentationsListFiltered: action.payload.list
        });
      case actionTypes.FILL_SLIDES:
//        console.log('FILL_SLIDES', action.payload)
        return Object.assign({}, state, { presentationSlides: { ...state.presentationSlides, ...action.payload } });
      case actionTypes.SET_SELECTED_SLIDE:
        return Object.assign({}, state, { presentationSlides: { ...state.presentationSlides, selectedSlideCode: action.payload }});
      case actionTypes.SEND_SLIDE_FROM_GRID:
        return Object.assign({}, state, { ...state, ...action.payload });
      case actionTypes.UPDATE_FILTERED_LIST:
//        console.log('UPDATE_FILTERED_LIST', action.payload)
        return Object.assign({}, state, { presentationsListFiltered: action.payload });
      case actionTypes.FILTER_SEARCH:
        //console.log('FILTER_SEARCH', action.payload)
        //presentationsListFiltered
        let presentationsListFiltered = state.presentationsList.list;
        if (action.payload.value.length >= 1 && presentationsListFiltered) {
          presentationsListFiltered = state.presentationsList.list.filter((presentation) => {
            let sanedName = presentation.name.split('_').join(' ');
            return sanedName.toLowerCase().includes(action.payload.value.toLowerCase())
          });
        }
        
        return Object.assign({}, state, { presentationsListFiltered: presentationsListFiltered, filterText: action.payload.value.toLowerCase() });
      case actionTypes.FILL_CALLS_BY_DATE:
//        console.log('FILL_CALLS_BY_DATE', action.payload)
        return Object.assign({}, state, { calls: action.payload, loading: false });
      case actionTypes.CREATE_CALL:
//        console.log('CREATE_CALL', action.payload)
        return Object.assign({}, state, { loading: true });
      case actionTypes.CLOSE_CALL:
//        console.log('CLOSE_CALL', action.payload)
          return Object.assign({}, state, { loading: true });
      case actionTypes.SEND_EMAIL:
//        console.log('SEND_EMAIL', action.payload)
        return Object.assign({}, state, { loading: true });
      case actionTypes.SET_LOADING:
        return Object.assign({}, state, { loading: action.payload });
      case actionTypes.GET_CALLS_BY_DATE:
//        console.log('GET_CALLS_BY_DATE', action.payload)
        return Object.assign({}, state, { 
          loading: true,
          selectedFrom: moment(action.payload.selectedFrom).format('YYYY-MM-DD'),
          selectedTo: moment(action.payload.selectedTo).format('YYYY-MM-DD')
        });
      case actionTypes.GET_CALL_HISTORY:
        return Object.assign({}, state, {
          loading: true,
          customerId: action.payload.customerId,
          callHistoryRequested: false,
          callItemSelected: action.payload.itemSelected
        });
      case actionTypes.FILL_CALL_HISTORY:
        return Object.assign({}, state, { ...state, loading: false, callHistoryList: action.payload, callHistoryRequested: true });
      case actionTypes.SET_CALL_HISTORY_REQUESTED:
        return Object.assign({}, state, { ...state, loading: false, callHistoryRequested: action.payload });
      case actionTypes.GET_RESULT_CREATE_CALL:
//        console.log('GET_RESULT_CREATE_CALL', action.payload)
        return Object.assign({}, state, { callCreated: action.payload, loading: false, customerSelected: { disabled: false } });
      case actionTypes.UPDATE_NEW_CALL:
//        console.log('UPDATE_NEW_CALL', action.payload)
        return Object.assign({}, state, { callNew: action.payload });
      case actionTypes.RESET_CALL_NEW:
//        console.log('RESET_CALL_NEW', action.payload)
        return Object.assign({}, state, { ...state, customerSelected: { disabled: false } });
      case actionTypes.FILL_CUSTOMER_TYPE_LIST:
//        console.log('FILL_CUSTOMER_TYPE_LIST', action.payload)
        return Object.assign({}, state, { ...state, customerTypeList: action.payload });
      case actionTypes.GET_CUSTOMER_LIST_BY_TYPE:
//        console.log('GET_CUSTOMER_LIST_BY_TYPE', action.payload)
        return Object.assign({}, state, { loading: true });
      case actionTypes.FILL_CUSTOMER_LIST_BY_TYPE:
//        console.log('FILL_CUSTOMER_LIST_BY_TYPE', action.payload)
        return Object.assign({}, state, { ...state, customerListByType: action.payload, loading: false });
      case actionTypes.GET_CUSTOMER_ENTIRE_LIST:
//        console.log('GET_CUSTOMER_ENTIRE_LIST', action.payload)
        return Object.assign({}, state, { loading: true });
      case actionTypes.FILL_CUSTOMER_ENTIRE_LIST:
//        console.log('FILL_CUSTOMER_ENTIRE_LIST', action.payload)
        return Object.assign({}, state, { customerList: action.payload, loading: false });
      case actionTypes.ADD_CURRENT_SUBSCRIBER:
        return Object.assign({}, state, { currentSubscribersGlobal: action.payload});
      case actionTypes.UPDATE_CUSTOMER_SELECTED:
//        console.log('UPDATE_CUSTOMER_SELECTED', action.payload)
        return Object.assign({}, state, { ...state, customerSelected: action.payload });
      case actionTypes.UPDATE_VISITTYPE_SELECTED:
        return Object.assign({}, state, { ...state, visitTypeSelected: action.payload });
      case actionTypes.SET_CUSTOMERTYPE_SELECTED:
//        console.log('SET_CUSTOMERTYPE_SELECTED', action.payload)
        return Object.assign({}, state, { ...state, customerTypeSelected: action.payload });
      case actionTypes.RESET_ALL:
//        console.log('RESET_ALL', action.payload)
        return Object.assign({}, state, { ...initialState });
      case actionTypes.SET_CREATE_CALL_ERROR_VISIBLE:
//        console.log('SET_CREATE_CALL_ERROR_VISIBLE', action.payload)
        return Object.assign({}, state, { ...state, createCallErrorVisible: action.payload });
      case actionTypes.SET_CREATE_CALL_SUCCESS_VISIBLE:
//        console.log('SET_CREATE_CALL_SUCCESS_VISIBLE', action.payload)
        return Object.assign({}, state, { ...state, createCallSuccessVisible: action.payload });
      case actionTypes.SET_EMAIL_SUCCESS:
//        console.log('SET_EMAIL_SUCCESS', action.payload)
        return Object.assign({}, state, { ...state, emailSuccess: action.payload, loading: false });
      case actionTypes.SET_EMAIL_RESULT:
        return Object.assign({}, state, { ...state, emailResult: action.payload, loading: false });
      case actionTypes.SET_TIMER:
//        console.log('SET_TIMER', action.payload)
        return Object.assign({}, state, { ...state, callTime: action.payload });
      case actionTypes.SET_CALL_TIME:
//        console.log('SET_CALL_TIME', action.payload)
        return Object.assign({}, state, {...state, callTimeSeconds: action.payload});
      case actionTypes.UPDATE_PRESENTATION_SDVA:
//        console.log('UPDATE_PRESENTATION_SDVA', action.payload)
        return Object.assign({}, state, { 
          //focusedPresentationSlides: {...action.payload},
          presentationFocusedSlides: action.payload.presentationFocused,
          focusedPresentationSlides: action.payload.presentationFocused
        });
      case actionTypes.SET_IFRAME_PRESENTATION:
//        console.log('ACTION STATE', action)
        return Object.assign({}, state, 
          { 
            displayScreenPresentation: true, 
            presentationFocused: {code: action.payload.code, entryPoint: action.payload.entryPoint, type: action.payload.type},
            focusedPresentationSlides: { code: action.payload.code, entryPoint: action.payload.entryPoint, type: action.payload.type},
            initialSlideIndex: action.payload.initialSlideIndex
          });
      case actionTypes.UPDATE_STATE_CHAT_DRAWER:
        return Object.assign({}, state, action.payload.showChatDrawer)
      case actionTypes.PUSH_TO_MESSAGE_ARRAY:
        //console.log(action.payload)
        state.chat.messages.push(action.payload)
        return Object.assign({}, state, { ...state })
      case actionTypes.RESET_CHAT_VARS:
        state.chat.messages.push(action.payload)
        return Object.assign({}, state, { ...state, chat:{ messages:[] }, showChatDrawer: false, })
      case actionTypes.SET_CALL_ACTIVE:
        return Object.assign({}, state, { ...state, callActive: action.payload})
      case actionTypes.SET_LOBBY_STATE:
        return Object.assign({}, state, { ...state, lobbyState: action.payload})
      case actionTypes.GET_NETWORK_TEST:
        return Object.assign({}, state, { loading: true });
      case actionTypes.GET_PRODUCTS_BY_CALL:
        return Object.assign({}, state, { loading: true });
      case actionTypes.GET_TERMS_BY_CALL:
        return Object.assign({}, state, { loading: true });
      case actionTypes.FILL_NETWORK_TEST:
        return Object.assign({}, state, { ...state, networkTest: action.payload, loading: false });
      case actionTypes.GET_FILE_LIST:
        return Object.assign({}, state, { ...state, loading: true });
      case actionTypes.SET_FILE_LIST:
        return Object.assign({}, state, { ...state, fileList: action.payload, fileListFiltered: action.payload, loading: false });
      case actionTypes.PUBLISH_OTCORE:
        return Object.assign({}, state, { ...state, otCore: action.payload });
      case actionTypes.ADD_FILE_TO_LIST:
        return Object.assign({}, state, { ...state, fileListShare: [...state.fileListShare, action.payload] });
      case actionTypes.REMOVE_FILE_FROM_LIST:
        let newFileShareArray = state.fileListShare;
        for(let i = 0; i < newFileShareArray.length; i++){
          if(newFileShareArray[i].code === action.payload.code){
            newFileShareArray.splice(i, 1);
            break;
          }
        }
        return Object.assign({}, state, { ...state, fileListShare: newFileShareArray });
      case actionTypes.RECEIVE_FILE_SHARED:
        return Object.assign({}, state, { ...state, fileListShare: action.payload });
      case actionTypes.SET_FILTERED_FILE_SEARCH:
        return Object.assign({}, state, { fileListFiltered: action.payload });
      case actionTypes.SET_FILE_EMAIL_POPUP_OPEN:
        return Object.assign({}, state, { fileEmailPopUpOpen: action.payload });
      case actionTypes.SET_OPEN_SNACKBAR_FILE_SHARED:
        return Object.assign({}, state, { openSnackBarFileShared: action.payload });
      case actionTypes.SET_STATS:
          return Object.assign({}, state, { stats: action.payload });
      case actionTypes.FILTER_RESET:
          return Object.assign({}, state, { filterReset: !state.filterReset });
      case actionTypes.SET_SNACK_FILE:
        return Object.assign({}, state, {
          snackbarFile: action.payload
        });
      case actionTypes.RESET_SNACK_FILE:
        return Object.assign({}, state, {
          snackbarFile: {
            open: false, 
            snackMsg: '',
            snackBarSeverity: ''
          }
        });
      case actionTypes.CALL_CLOSED_RESULT:
        return Object.assign({}, state, {
          callClosedResult: action.payload
        });
      case actionTypes.SET_SIGN_OUT_CLICK:
        return Object.assign({}, state, {
          signOutClick: action.payload
        });
      case actionTypes.SET_SEND_SLIDE_FEEDBACK_RESULT:
        return Object.assign({}, state, { ...state, slideFeedbackResult: action.payload, loading: false });
      case actionTypes.SET_VA_SIZE:
        return Object.assign({}, state, { ...state, vaCanvasSize: action.payload, loading: false });
      case actionTypes.GET_CALL_FEEDBACK:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            loading: true
          }
        );
      case actionTypes.FILL_CALL_FEEDBACK:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            loading: false,
            feedback: action.payload
          }
        );
      case actionTypes.RESET_CALL_FEEDBACK:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            feedback: initialState.feedback
          }
        );
      case actionTypes.FILL_SLIDES_DRAWER:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            loading: true
          }
        );
      case actionTypes.FILL_RESPONSE_SLIDES_DRAWER:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            loading: false,
            responseSlidesDrawer: action.payload
          }
        );
      case actionTypes.RESET_SELECTED_AND_ACTIVE_SLIDE:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            loading: false,
            presentationSlides: action.payload
          }
        );
      case actionTypes.ERROR_GETTING_DATA:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            errorGettingData: action.payload
          }
        );
      case actionTypes.FILL_PRODUCTS_BY_CALL:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            products: action.payload
          }
        );
      case actionTypes.FILL_TERMS_BY_CALL:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            terms: action.payload
          }
        );
      case actionTypes.MAKE_PRODUCTS_REQUEST:
      case actionTypes.MAKE_TERMS_REQUEST:
        //        console.log('MAKE_PRODUCTS_REQUEST', action.payload)
        return Object.assign(
          {}, 
          state, 
          { 
            ...state, 
            loading: true 
          }
        );
      case actionTypes.MAKE_PRODUCTS_REQUEST_RESULT:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            makeProductsRequestResult: action.payload
          }
        );
      case actionTypes.MAKE_TERMS_REQUEST_RESULT:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            makeTermsRequestResult: action.payload
          }
        );
      case actionTypes.SET_CUSTOMER_REQUEST_DIALOG_OPEN:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            customerRequestDialogOpen: action.payload
          }
        );
      case actionTypes.FILL_CUSTOMER_REQUEST_DATA:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            customerRequestData: action.payload
          }
        );
      case actionTypes.FILL_TERM_DATA:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            getTermByCodeResult: action.payload
          }
        );
      case actionTypes.FILL_SIGNATURE:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            signature: action.payload
          }
        );
      case actionTypes.UPLOAD_SIGNATURE:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            loading: true
          }
        );
      case actionTypes.FILL_UPLOAD_SIGNATURE_RESULT:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            loading: false,
            uploadSignatureResult: action.payload
          }
        );
      case actionTypes.SET_CUSTOMER_REQUEST_CODE:
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            customerRequestCode: action.payload
          }
        );
      case actionTypes.FILL_ACCEPT_REQUEST_RESULT:
        //console.log('FILL_ACCEPT_REQUEST_RESULT', action.payload);
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            acceptRequestResult: action.payload
          }
        );
      case actionTypes.SET_NOTES_DRAWER_OPEN_STATE:
        //console.log('SET_NOTES_DRAWER_OPEN_STATE', action.payload);
        return Object.assign(
          {}, 
          state, 
          { 
            ...state,
            notesDrawerOpenState: action.payload
          }
        );
      case actionTypes.UPDATE_CURRENTFLOW:
        return Object.assign(
          {}, 
          state, 
          {
            ...state,
            presentationFlows: {...state.presentationFlows, currentFlow: action.payload}
          }
        );
        case actionTypes.SET_FLOWS:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              presentationFlows: {...state.presentationFlows, flows: action.payload}
            }
          );
        case actionTypes.UPDATE_SELECTED_INDEX_FLOW:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              presentationFlows: {...state.presentationFlows, indexOfSelectedFlow: action.payload}
            }
          );
        case actionTypes.UPDATE_SELECTED_INDEX_SLIDE:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              presentationSlides: {...state.presentationSlides, indexOfSelectedSlide: action.payload}
            }
          );
        case actionTypes.SET_FOCUSEDPRESENTATION_FLOWS:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              focusedPresentationFlows: {...action.payload}
            }
          );
        case actionTypes.SET_FOCUSEDPRESENTATION_SLIDES:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              focusedPresentationSlides: {...action.payload}
            }
          );
        case actionTypes.FULFILL_PRESENTATIONBAR:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              ...action.payload
            }
          );
        case actionTypes.UPDATE_PRESENTATIONFOCUSED_LENGTH:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              presentationFocused: {...state.presentationFocused, length: action.payload}
            }
          );
        case actionTypes.SEND_FLOW_ACTIVE:
          return Object.assign(
            {}, 
            state, 
            {
              ...state,
              focusedPresentationFlows: {...state.focusedPresentationFlows, indexOfSelectedFlow: action.payload.numFlow},
              numSlide: action.payload.numSlide
            }
          );
        case actionTypes.SET_IS_REQUEST_FROM_CUSTOMER:
        return Object.assign(
          {}, 
          state, 
          {
            ...state,
            isRequestFromCustomer: true
          }
        );
      default:
        return state
    }
  }
);

export const actions = {
  resetAll: () => ({ type: actionTypes.RESET_ALL, payload: {} }),
  updateSlideActive: (value) => ({ type: actionTypes.UPDATE_SLIDE_ACTIVE, payload: value }),
  sendSlideActive: (value) => ({ type: actionTypes.SEND_SLIDE_ACTIVE, payload: value }),
  publishVideoObjectManager: (videoManager) => ({ type: actionTypes.PUBLISH_VIDEO_MANAGER, payload: videoManager }),
  getPresentationObject: (code, startAtOrder, vaLogoUrl) => ({ type: actionTypes.GET_PRESENTATION_OBJECT, payload: { code: code, startAtOrder: startAtOrder, vaLogoUrl: vaLogoUrl } }),
  updateStateDrawers: (value) => ({ type: actionTypes.UPDATE_STATE_DRAWERS, payload: value }),
  updateStateScreenPresentation: (value) => ({ type: actionTypes.UPDATE_SCREEN_PRESENTATION, payload: value }),
  fulfillPresentation: (data, startAtOrder) => ({ type: actionTypes.UPDATE_FOCUSED_PRESENTATION_SLIDES, payload: { data, numSlide: startAtOrder } }),
  resetPresentationVars: (value) => ({ type: actionTypes.RESET_PRESENTATION_VARS, payload: value }),
  closePresentation: (value) => ({ type: actionTypes.CLOSE_PRESENTATION_SCREEN, payload: value }),
  clientEndPresentation: (value) => ({ type: actionTypes.CLIENT_END_PRESENTATION, payload: value }),
  goBack: (value) => ({ type: actionTypes.GO_BACK, payload: value }),
  getPresentations: (value) => ({ type: actionTypes.GET_PRESENTATIONS, payload: value }),
  fulfillPresentationList: (value) => ({ type: actionTypes.FULLFILL_PRESENTATIONS, payload: value }),
  fillSlidesDrawer: (value) => ({ type: actionTypes.FILL_SLIDES_DRAWER, payload: value }),
  fulfillPresentationSlides: (value) => ({ type: actionTypes.FILL_SLIDES, payload: value }),
  setSelectedSlide: (value) => ({ type: actionTypes.SET_SELECTED_SLIDE, payload: value }),
  sendSlideFromGrid: (value) => ({ type: actionTypes.SEND_SLIDE_FROM_GRID, payload: value }),
  filterSearch: (value, isFiltering) => ({ type: actionTypes.FILTER_SEARCH, payload: { value, isFiltering } }),
  updateFilteredList: (value) => ({ type: actionTypes.UPDATE_FILTERED_LIST, payload: value }),
  getCallsByDate: (value) => ({ type: actionTypes.GET_CALLS_BY_DATE, payload: value }),
  fillCallsByDate: (value) => ({ type: actionTypes.FILL_CALLS_BY_DATE, payload: value }),
  getCallHistory: (value) => ({ type: actionTypes.GET_CALL_HISTORY, payload: value}),
  fillCallHistory: (value) => ({ type: actionTypes.FILL_CALL_HISTORY, payload: value}),
  setCallHistoryRequested: (value) => ({ type: actionTypes.SET_CALL_HISTORY_REQUESTED, payload: value}),
  setCallItemSelected: (value) => ({ type: actionTypes.SET_CALL_ITEM_SELECTED, payload: value}),
  createCall: (value) => ({ type: actionTypes.CREATE_CALL, payload: value }),
  sendEmail: (value) => ({ type: actionTypes.SEND_EMAIL, payload: value }),
  setLoading: (value) => ({ type: actionTypes.SET_LOADING, payload: value}),
  getResultCreateCall: (value) => ({ type: actionTypes.GET_RESULT_CREATE_CALL, payload: value }),
  updateNewCall: (value) => ({ type: actionTypes.UPDATE_NEW_CALL, payload: value }),
  resetCallNew: (value) => ({ type: actionTypes.RESET_CALL_NEW, payload: value }),
  getCustomerTypeList: (value) => ({ type: actionTypes.GET_CUSTOMER_TYPE_LIST, payload: value }),
  fillCustomerTypeList: (value) => ({ type: actionTypes.FILL_CUSTOMER_TYPE_LIST, payload: value }),
  getCustomerListByType: (value) => ({ type: actionTypes.GET_CUSTOMER_LIST_BY_TYPE, payload: value }),
  fillCustomerListByType: (value) => ({ type: actionTypes.FILL_CUSTOMER_LIST_BY_TYPE, payload: value }),
  getCustomerEntireList: (value) => ({ type: actionTypes.GET_CUSTOMER_ENTIRE_LIST, payload: value }),
  fillCustomerEntireList: (value) => ({ type: actionTypes.FILL_CUSTOMER_ENTIRE_LIST, payload: value }),
  addCurrentSubscribers: (value) => ({ type: actionTypes.ADD_CURRENT_SUBSCRIBER, payload: value}),
  updateCustomerSelected: (value) => ({ type: actionTypes.UPDATE_CUSTOMER_SELECTED, payload: value }),
  updateVisitTypeSelected: (value) => ({ type: actionTypes.UPDATE_VISITTYPE_SELECTED, payload: value }),
  setCustomerTypeSelected: (value) => ({ type: actionTypes.SET_CUSTOMERTYPE_SELECTED, payload: value }),
  setCreateCallErrorVisible: (value) => ({ type: actionTypes.SET_CREATE_CALL_ERROR_VISIBLE, payload: value }),
  setCreateCallSuccessVisible: (value) => ({ type: actionTypes.SET_CREATE_CALL_SUCCESS_VISIBLE, payload: value }),
  setEmailResult: (value) => ({ type: actionTypes.SET_EMAIL_RESULT, payload: value }),
  setTimer: (value) => ({ type: actionTypes.SET_TIMER, payload: value }),
  setCallTime: (value) => ({ type: actionTypes.SET_CALL_TIME, payload: value }),
  closeCall: (value) => ({type: actionTypes.CLOSE_CALL, payload: value}),
  updateScreenPresentationSdva: (value) => ({ type: actionTypes.UPDATE_PRESENTATION_SDVA, payload: value }),
  setIframePresentation: (value) => ({ type: actionTypes.SET_IFRAME_PRESENTATION, payload: value }),
  sendGotoSdvaSlide: (value) => ({type: actionTypes.SEND_GOTO_SDVA_SLIDE, payload: value}),
  setPresentationFocusedClient: (value) => ({type: actionTypes.SET_PRESENTATION_CLIENT_FOCUSED, payload: value}),
  updateStateChatDrawer: (value) => ({type: actionTypes.UPDATE_STATE_CHAT_DRAWER, payload: value}),
  sendChatMessage:(value) => ({type: actionTypes.SEND_CHAT_MESSAGE, payload: value}),
  recieveChatMessage:(value) => ({type: actionTypes.RECIEVE_CHAT_MESSAGE, payload: value}),
  pushToMessageArray:(value) => ({type: actionTypes.PUSH_TO_MESSAGE_ARRAY, payload: value}),
  resetChatVars:() => ({type: actionTypes.RESET_CHAT_VARS, payload: ''}),
  setCallActive:(value) => ({type: actionTypes.SET_CALL_ACTIVE, payload: value}),
  setLobbyState: (value) => ({ type: actionTypes.SET_LOBBY_STATE, payload: value}),
  getNetworkTest: (value) => ({ type: actionTypes.GET_NETWORK_TEST, payload: value}),
  fillNetworkTest: (value) => ({ type: actionTypes.FILL_NETWORK_TEST, payload: value}),
  sendFeedback: (value) => ({ type: actionTypes.SEND_FEEDBACK, payload: value}),
  getFileList: (value) => ({type: actionTypes.GET_FILE_LIST, payload: value}),
  setFileList: (value) => ({type: actionTypes.SET_FILE_LIST, payload: value}),
  sendFileEmail: (value) => ({ type: actionTypes.SEND_FILE_EMAIL, payload: value }),
  publishOtCore: (value) => ({ type: actionTypes.PUBLISH_OTCORE, payload: value }),
  addFileToList: (value) => ({ type: actionTypes.ADD_FILE_TO_LIST, payload: value }),
  removeFileFromList: (value) => ({ type: actionTypes.REMOVE_FILE_FROM_LIST, payload: value }),
  sendSignalFile: () => ({ type: actionTypes.SEND_SIGNAL_FILE, payload: null }),
  receiveFileShared: (value) => ({ type: actionTypes.RECEIVE_FILE_SHARED, payload: value }),
  fileSearch: (value) => ({ type: actionTypes.FILE_SEARCH, payload: value }),
  setFilteredFileSearch: (value) => ({ type: actionTypes.SET_FILTERED_FILE_SEARCH, payload: value }),
  setFileEmailPopUpOpen: (value) => ({ type: actionTypes.SET_FILE_EMAIL_POPUP_OPEN, payload: value }),
  setOpenSnackBarFileShared: (value) => ({ type: actionTypes.SET_OPEN_SNACKBAR_FILE_SHARED, payload: value }),
  setSnackFile: (value) => ({ type: actionTypes.SET_SNACK_FILE, payload: value }),
  resetSnackFile: () => ({ type: actionTypes.RESET_SNACK_FILE, payload: null }),
  setStats: (value) => ({ type: actionTypes.SET_STATS, payload: value}),
  sendVisualAidEmail: (value) => ({ type: actionTypes.SEND_VISUAL_AID_EMAIL, payload: value }),
  ExecuteActionAAN: (value) => ({type: actionTypes.EXECUTE_ACTION_AAN, payload: value}),
  callClosedResult: (value) => ({type: actionTypes.CALL_CLOSED_RESULT, payload: value}),
  callSession: () => ({type: actionTypes.CALL_SESSION, payload: null}),
  filterReset: () =>  ({type: actionTypes.FILTER_RESET, payload: true}),
  setSignOutClick: (signOutClick) =>  ({type: actionTypes.SET_SIGN_OUT_CLICK, payload: signOutClick}),
  sendSlideFeedback: (value) => ({type: actionTypes.SEND_SLIDE_FEEDBACK, payload: value}),
  setSendSlideFeedbackResult: (value) => ({ type: actionTypes.SET_SEND_SLIDE_FEEDBACK_RESULT, payload: value }),
  setVaSize: (value) => ({ type: actionTypes.SET_VA_SIZE, payload: value }),
  getCallFeedback: (value) => ({ type: actionTypes.GET_CALL_FEEDBACK, payload: value}),
  fillCallFeedback: (value) => ({ type: actionTypes.FILL_CALL_FEEDBACK, payload: value}),
  resetCallFeedback: () => ({ type: actionTypes.RESET_CALL_FEEDBACK, payload: null}),
  fillResponseSlidesDrawer: (value) => ({ type: actionTypes.FILL_RESPONSE_SLIDES_DRAWER, payload: value}),
  resetSelectedAndActiveSlide: (value) => ({ type: actionTypes.RESET_SELECTED_AND_ACTIVE_SLIDE, payload: value}),
  errorGettingData: (value) => ({ type: actionTypes.ERROR_GETTING_DATA, payload: value}),
  getProductsByCall: (value) => ({ type: actionTypes.GET_PRODUCTS_BY_CALL, payload: value}),
  fillProductsByCall: (value) => ({ type: actionTypes.FILL_PRODUCTS_BY_CALL, payload: value}),
  getTermsByCall: (value) => ({ type: actionTypes.GET_TERMS_BY_CALL, payload: value}),
  fillTermsByCall: (value) => ({ type: actionTypes.FILL_TERMS_BY_CALL, payload: value}),
  makeProductRequest: (products, customerUuid, callcode, type, intl, sendSignal) => ({ type: actionTypes.MAKE_PRODUCTS_REQUEST, payload: [products, customerUuid, callcode, type, intl, sendSignal]}),
  makeProductRequestResult: (value) => ({ type: actionTypes.MAKE_PRODUCTS_REQUEST_RESULT, payload: value}),
  makeTermsRequest: (value) => ({ type: actionTypes.MAKE_TERMS_REQUEST, payload: value}),
  makeTermsRequestResult: (value) => ({ type: actionTypes.MAKE_TERMS_REQUEST_RESULT, payload: value}),
  sendCustomerRequestSignal: (value) => ({ type: actionTypes.SEND_CUSTOMER_REQUEST_SIGNAL, payload: value}),
  setCustomRequestDialog: (value) => ({ type: actionTypes.SET_CUSTOMER_REQUEST_DIALOG_OPEN, payload: value}),
  getCustomerRequestData: (value) => ({ type: actionTypes.GET_CUSTOMER_REQUEST_DATA, payload: value}),
  fillCustomerRequestData: (value) => ({ type: actionTypes.FILL_CUSTOMER_REQUEST_DATA, payload: value}),
  getTermByCode: (value) => ({ type: actionTypes.GET_TERM_BY_CODE, payload: value}),
  fillTermData: (value) => ({ type: actionTypes.FILL_TERM_DATA, payload: value}),
  fillSignature: (value) => ({ type: actionTypes.FILL_SIGNATURE, payload: value}),
  uploadSignature: (value) => ({ type: actionTypes.UPLOAD_SIGNATURE, payload: value}),
  fillSignatureResult: (value) => ({ type: actionTypes.FILL_UPLOAD_SIGNATURE_RESULT, payload: value}),
  setCustomerRequestCode: (value) => ({ type: actionTypes.SET_CUSTOMER_REQUEST_CODE, payload: value}),
  rejectCustomerRequest: (value) => ({ type: actionTypes.REJECT_CUSTOMER_REQUEST, payload: value}),
  acceptCustomerRequestWithoutSign: (value) => ({ type: actionTypes.ACCEPT_CUSTOMER_REQUEST_WITHOUT_SIGN, payload: value}),
  fillAcceptRequestResult: (value) => ({ type: actionTypes.FILL_ACCEPT_REQUEST_RESULT, payload: value}),
  setNotesDrawerOpenState: (value) => ({ type: actionTypes.SET_NOTES_DRAWER_OPEN_STATE, payload: value}),
  updateCurrentFlow: (value) => ({ type: actionTypes.UPDATE_CURRENTFLOW, payload: value }),
  setFlows: (value) => ({ type: actionTypes.SET_FLOWS, payload: value }),
  updateSelectedIndexFlow: (value) => ({ type: actionTypes.UPDATE_SELECTED_INDEX_FLOW, payload: value }),
  updateSelectedIndexSlide: (value) => ({ type: actionTypes.UPDATE_SELECTED_INDEX_SLIDE, payload: value }),
  setFocusedPresentationFlows: (value) => ({ type: actionTypes.SET_FOCUSEDPRESENTATION_FLOWS, payload: value }),
  setFocusedPresentationSlides: (value) => ({ type: actionTypes.SET_FOCUSEDPRESENTATION_SLIDES, payload: value }),
  fulfillPresentationBar: (value) => ({ type: actionTypes.FULFILL_PRESENTATIONBAR, payload: value }),
  updatePesentationFocusedLength: (value) => ({ type: actionTypes.UPDATE_PRESENTATIONFOCUSED_LENGTH, payload: value }),
  sendFlowActive: (value) => ({ type: actionTypes.SEND_FLOW_ACTIVE, payload: value }),
  setIsRequestFromCustomer: (value) => ({ type: actionTypes.SET_IS_REQUEST_FROM_CUSTOMER, payload: value })
}

export const getState = (state) => state;

export function* saga() {
  
  yield takeLatest(actionTypes.ACCEPT_CUSTOMER_REQUEST_WITHOUT_SIGN, function* acceptCustomerRequestWithoutSignSaga({ payload }) {
    try{
      const selector = yield select(getState);
      const { status, data } = yield acceptCustomerRequestWithoutSign({
        callcode: payload.callcode,
        description: payload.description
      }, selector.presentationBar.customerRequestCode);
      if(status < 300){
        // enviar un signal al representante
        yield put(actions.sendChatMessage({
          from: 'me',
          message: payload.intl.formatMessage({ id: "CUSTOMER.REQUEST.DIALOG.ACCEPTED" }, {requestcode: selector.presentationBar.customerRequestCode})
        }));
        yield put(actions.fillAcceptRequestResult(true));
        selector.presentationBar.videoManager.session.signal({
          type: 'CUSTOMER_REQUEST_ACCEPT',
          data: JSON.stringify({cod_request: selector.presentationBar.customerRequestCode })
        }, (err) => {
          console.log(err);
        });
      }else{
        yield put(actions.fillAcceptRequestResult(false));
      }
    }catch(e){
      yield put(actions.fillAcceptRequestResult(false));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.REJECT_CUSTOMER_REQUEST, function* rejectCustomerRequestSaga({ payload }) {
    try{
      const selector = yield select(getState);
      const { status, data } = yield rejectCustomerRequest({
        callcode: payload.callcode,
        description: payload.description
      }, selector.presentationBar.customerRequestCode);
      if(status < 300){
        // enviar un signal al representante
        if(payload.sendSignal) {
          yield put(actions.sendChatMessage({
            from: 'me',
            message: payload.intl.formatMessage({ id: "CUSTOMER.REQUEST.DIALOG.REJECTED" }, {requestcode: selector.presentationBar.customerRequestCode})
          }));
          selector.presentationBar.videoManager.session.signal({
            type: 'CUSTOMER_REQUEST_REJECT',
            data: JSON.stringify({cod_request: selector.presentationBar.customerRequestCode })
          }, (err) => {
            console.log(err);
          });
        }
      }
    }catch(e){
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.UPLOAD_SIGNATURE, function* uploadSignatureSaga({ payload }) {
    try{
      const selector = yield select(getState);
      
      const requestObject = {
        code: selector.presentationBar.customerRequestData.code,
        signerName: selector.auth.name,
        log: payload.log,
        signature: payload.trimmedDataURL
      }

      const { status, data } = yield uploadSignatureCrud(requestObject);
      
      if (data && status < 300) {
        if(payload.sendSignal) {
          selector.presentationBar.videoManager.session.signal({
            type: 'CUSTOMER_REQUEST_ACCEPT',
            data: JSON.stringify({cod_request: selector.presentationBar.customerRequestData.code })
          }, (err) => {
            console.log(err);
          });
  
          yield put(actions.sendChatMessage({
            from: 'me',
            message: payload.intl.formatMessage({ id: "CUSTOMER.REQUEST.DIALOG.ACCEPTED" }, {requestcode: selector.presentationBar.customerRequestData.code})
          }));
        }

        yield put(actions.fillAcceptRequestResult(true));
        yield put(actions.fillSignatureResult(data));
        yield put(actions.setIsRequestFromCustomer(false));
      }else{
        yield put(actions.fillAcceptRequestResult(false));
      }
    }catch(e){
      yield put(actions.fillAcceptRequestResult(false));
      yield put(actions.fillSignatureResult(false));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_TERM_BY_CODE, function* getTermByCodeSaga({ payload }) {
    try{
      const { status, data } = yield getTermByCode(payload);
      
      if (data && status < 300) {
        yield put(actions.fillTermData(data));
      }
    }catch(e){
      yield put(actions.fillTermData({}));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_CUSTOMER_REQUEST_DATA, function* getCustomerRequestDataSaga({ payload }) {
    try{
      const { status, data } = yield getCustomerRequestData(payload);
      
      if (data && status < 300) {
        yield put(actions.fillCustomerRequestData(data));
      }
    }catch(e){
      yield put(actions.fillCustomerRequestData({}));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.SEND_CUSTOMER_REQUEST_SIGNAL, function* makeCustomerRequestSignalSaga({ payload }) {
    try{
      const selector = yield select(getState);

      yield put(actions.sendChatMessage({
        from: 'me',
        message: payload.intl.formatMessage({ id: "PRODUCTS.CALL.DIALOG.SENT_TEXT" }, {requestcode: payload.code})
      }));

      selector.presentationBar.videoManager.session.signal(
        {
          type: 'CUSTOMER_REQUEST',
          data: JSON.stringify({cod_request: payload.code })
        }, (err) => {
          console.log(err);
      });
    }catch(e){
      
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.MAKE_TERMS_REQUEST, function* makeTermsRequestSaga({ payload }) {
    try{
      if(
        payload.callcode &&
        payload.customerId && 
        payload.type &&
        payload.code
      ){
        const { status, data } = yield makeTermsRequest(payload);
  
        if (data.code && status < 300) {
          yield put(actions.makeTermsRequestResult({code: data.code, status: true}));
          yield put(actions.sendCustomerRequestSignal({code: data.code, intl: payload.intl}));
        }
      }else{
        yield put(actions.makeTermsRequestResult({status: false}));
      }
    }catch(e){
      yield put(actions.makeTermsRequestResult({status: false}));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.MAKE_PRODUCTS_REQUEST, function* makeProductsRequestSaga({ payload }) {
    try{
      if(
        Object.keys(payload[0]).length > 0 &&
        payload[1] && 
        payload[2]
      ){
        const { status, data } = yield makeProductsRequest(payload[0], payload[1], payload[2], payload[3]);
  
        if (data.code && status < 300) {
          yield put(actions.makeProductRequestResult({code: data.code, status: true}));

          if(payload[5]) {
            yield put(actions.sendCustomerRequestSignal({code : data.code, intl: payload[4]}));
          } else {
            yield put(actions.setIsRequestFromCustomer(true));
            yield put(actions.setCustomerRequestCode(data.code));
            yield put(actions.setCustomRequestDialog(true));
            yield put(actions.setLoading(false));
          }
        }
      }else{
        yield put(actions.makeProductRequestResult({status: false}));
      }
    }catch(e){
      yield put(actions.makeProductRequestResult({status: false}));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_TERMS_BY_CALL, function* getProductsByCallSaga({ payload }) {
    try{
      const { status, data } = yield getTermsByCall(payload);
      
      if (data && status < 300) {
        yield put(actions.fillTermsByCall(data.list));
      }
    }catch(e){
      yield put(actions.fillTermsByCall([]));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_PRODUCTS_BY_CALL, function* getProductsByCallSaga({ payload }) {
    try{
      const { status, data } = yield getProductsByCall(payload);

      if (data && status < 300) {
        let arrWithQty = data.list.map((element) => {
          element.quantity = 1;
          return element;
        })
        yield put(actions.fillProductsByCall(arrWithQty));
      }
    }catch(e){
      yield put(actions.fillProductsByCall([]));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_CALL_FEEDBACK, function* getCallFeedbackSaga({ payload }) {
    try{
      const { status, data } = yield getCallInfo(payload);
      let feedback = {};
      if(data.properties && data.properties.length > 0){
        data.properties.forEach((property) => {
          feedback[property['key']] = property['value']
        })
      }
      if (data && status < 300) {
        yield put(actions.fillCallFeedback(feedback));
      }
    }catch(e){
      yield put(actions.fillCallFeedback({}));
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
    //yield put(actions.setCallHistoryRequested(true));
  });

  yield takeLatest(actionTypes.FILE_SEARCH, function* fileSearchSaga({ payload }) {

    try{
      const selector = yield select(getState);
      let filtered = [];
      for (let folder in selector.presentationBar.fileList) {
        selector.presentationBar.fileList[folder].forEach((item) => {
          item.searchableName = item.name.split('_').join(' ');
          if(item.searchableName.toLowerCase().indexOf(payload.toLowerCase()) >= 0){
            if(filtered[folder]){
              filtered[folder].push(item);
            } else {
              filtered[folder] = [];
              filtered[folder].push(item);
            }
          }
        })
      }
  
      yield put(actions.setFilteredFileSearch(filtered));
    }catch(e){
      console.log();
    }

    
  });

  yield takeLatest(actionTypes.SEND_SIGNAL_FILE, function* sendSignalFileSaga({ payload }) {
    const selector = yield select(getState);
    try {
      selector.presentationBar.videoManager.session.signal(
        {
          type: 'FILE_ENABLE',
          data: JSON.stringify(selector.presentationBar.fileListShare)
        }, (err) => {
          console.log(err);
        }
      );
    } catch (err) {
      console.log(err);
    }
  });

  yield takeLatest(actionTypes.SEND_FILE_EMAIL, function* sendFileEmailSaga({ payload }) {
    try {
      const { data } = yield sendFileEmail(payload);
      yield put(actions.setEmailResult('ok'));
    } catch (err) {
      if(err.response && err.response.data && err.response.data != ''){
        yield put(actions.setEmailResult(err.response.data));
        console.log(err.response.data);
      }else{
        yield put(actions.setEmailResult('error'));
      }
    }
  });

  yield takeLatest(actionTypes.GET_FILE_LIST, function* getFileListSaga({payload}){
    try{
      const { data } = yield getFileList(payload.callCode);
      let filesArr = [];

      // aca organizar la respuesta por carpeta asi el componente solo se dedica a 
      // recorrerla y mostrarla
      if(data.list){
        data.list.forEach((listItem) => {
          if(filesArr[listItem.folder]){
            filesArr[listItem.folder].push(listItem);
          }else{
            filesArr[listItem.folder] = [];
            filesArr[listItem.folder].push(listItem);
          }
        })
      }

      yield put(actions.setFileList(filesArr));
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
      console.log(e)
    }
    
  });

  yield takeLatest(actionTypes.SEND_FEEDBACK, function* sendFeedbackSaga({payload}){
    //console.log(payload)
    try{
      let message = payload.valueText.replace(/[|&;"<>()+,]/g, "");
      const { data } = yield sendFeedback(payload.callCode, payload.starsValue, message);
    }catch(e){
      console.log(e)
    }
    
  });

  yield takeLatest(actionTypes.RECIEVE_CHAT_MESSAGE, function* recieveChatMessageSaga({payload}){
    try{
      //console.log(payload);
      let message = payload.text.replace(/[|&;"<>()+,]/g, "");
      yield put(actions.pushToMessageArray({from: 'other', message: message, date: payload.sentOn, name:payload.sender.name}));
    }catch(e){
      console.log(e)
    }
    
  });

  yield takeLatest(actionTypes.SEND_CHAT_MESSAGE, function* sendChatMessageSaga({payload}){

    const selector = yield select(getState);
    let senderName = "";
    if(selector.auth.name && selector.auth.name != ''){
      senderName = selector.auth.name;
    }
    try{
      let messageData = {
        text: payload.message,
        sender: {
          id: selector.auth.userid,
          name: senderName,
          alias: selector.auth.deployment.callData[selector.auth.deployment.role],
          timezone: selector.auth.timezone
        },
        sentOn: Date.now()
      };
  
      let logText = `text:${payload.message}|sentOn:${Date.now()}`;
      yield put(loggerStorage.actions.chatLog({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'SEND',
        log_text: logText
      }));
  
      selector.presentationBar.videoManager.session.signal(
        {
          type: 'CHAT',
          data: JSON.stringify(messageData)
        }, (err) => {
          console.log(err);
      });

      //console.log(messageData)
      yield put(actions.pushToMessageArray({from: 'me', message: payload.message, date: messageData.sentOn, name: senderName}));
    }catch(e){
      console.log(e)
    }
    
  });

  yield takeLatest(actionTypes.SEND_GOTO_SDVA_SLIDE, function* sendGotoSdvaSlideSaga({payload}){
    if(!payload.isPreview){
      // SDVA:GOTOSLIDE:va04 (TYPE:ACTION:SLIDE)
      const selector = yield select(getState);
      //console.log(payload)
  
      //console.log(selector);
      //console.log('ñdsgojdfijgfidkgjdfkljgdflkjgdkfl------------', selector.presentationBar.presentationFocused.code);
      let payloadLog = payload;
      if(payload && payload.payload){
        payloadLog = payload.payload;
      }
      yield put(loggerStorage.actions.log(
        {
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: payload.type + '_' + payload.command,
            log_text: `code: ${selector.presentationBar.presentationFocused.code} | payload: ${payloadLog}`
        }
      ));

      if(selector.auth.deployment.callData.type != 2){
        selector.presentationBar.videoManager.session.signal(
        {
          type: payload.type,
          data: JSON.stringify(
            {
              code: selector.presentationBar.presentationFocused.code,//  --> codigo del slide
              command: payload.command, // 
              payload: payload.payload
            }
          )
        }, (err) => {
          console.log(err);
        });
      }
    }
  });

  yield takeLatest(actionTypes.GET_CUSTOMER_ENTIRE_LIST, function* getCustomerEntireListSaga({payload}){
    try{
      const {data} = yield getCustomerEntireList(payload);
      yield put(actions.fillCustomerEntireList(data.list));
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_NETWORK_TEST, function* getNetworkTestSaga({payload}){
    try{
      const {data} = yield getNetworkTest(payload);
      yield put(actions.fillNetworkTest(data));
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_CALL_HISTORY, function* getCallHistorySaga({ payload }) {
    try{
      const { data } = yield getCallHistory(payload.customerId);
      if (data.list) {
        yield put(actions.fillCallHistory(data.list));
      } else {
        console.log('there no data');
      }
      //yield put(actions.setCallHistoryRequested(true));
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });
  
  yield takeLatest(actionTypes.GET_CUSTOMER_LIST_BY_TYPE, function* getCustomerListByTypeSaga({ payload }) {
    try{
      const { data } = yield getCustomerListByType(payload.code, payload.name, '');
      if (data.list) {
        yield put(actions.fillCustomerListByType(data.list));
      } else {
        console.log('there no data');
      }
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_CUSTOMER_TYPE_LIST, function* getCustomerTypeListSaga({ payload }) {
    try{
      const { data } = yield getCustomerTypeList(payload);
      if (data.list) {
        yield put(actions.fillCustomerTypeList(data.list));
      } else {
        console.log('there no data');
      }
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.SEND_EMAIL, function* sendEmailSaga({ payload }) {
    try {
      const { data } = yield sendEmail(payload);
      yield put(actions.setEmailResult('ok'));
    } catch (err) {
      if(err.response && err.response.data && err.response.data != ''){
        yield put(actions.setEmailResult(err.response.data));
        console.log(err.response.data);
      }else{
        yield put(actions.setEmailResult('error'));
      }
    }
  });

  yield takeLatest(actionTypes.CLOSE_CALL, function* closeCallSaga({ payload }) {
    const selector = yield select(getState);
    try {
      let notesIsEnabled = false;
      if(selector.auth.deployment.parameters){
        notesIsEnabled = selector.auth.deployment.parameters.filter((parameter) =>{
          return (parameter.key === "CALL_NOTES_ENABLE")
        });
        if(notesIsEnabled.length > 0){
          notesIsEnabled = notesIsEnabled[0]['value'];
          notesIsEnabled = (notesIsEnabled && notesIsEnabled === "1") ? true : false;
        }
      }

      let savedResult;
      if(notesIsEnabled && payload.valueNotes && payload.valueNotes.length){
        const noteSaved = yield saveNote(payload);
        if(noteSaved.status > 299){
          console.log('error')
        }else{
          localStorage.removeItem("callnotes_" + payload.callcode)
        }
        savedResult = yield closeCall(payload);
      }else{
        savedResult = yield closeCall(payload);
      }
      
      if(savedResult.data == 'ok'){
        yield put(actions.callClosedResult(true));
      }else{
        yield put(actions.callClosedResult(false));
      }

    } catch (err) {
      console.log(err);
    }
    
  });

  yield takeLatest(actionTypes.CREATE_CALL, function* createCallSaga({ payload }) {
    try {
      const { data } = yield createCall(payload);
      yield put(actions.getCallsByDate({ selectedFrom: payload.selectedFrom, selectedTo: payload.selectedTo }))
      yield put(actions.getResultCreateCall(data));
      yield put(actions.setCreateCallSuccessVisible(true));
    } catch (err) {
      yield put(actions.setCreateCallErrorVisible(true));
      yield put(actions.errorGettingData(true));
      trackException(err);
      console.log(err);
    }
  });

  yield takeLatest(actionTypes.GET_CALLS_BY_DATE, function* getCallsByDateSaga({ payload }) {
    const selector = yield select(getState);

    const { data } = yield getCallsByDate(moment(payload.selectedFrom).format('YYYY-MM-DD'), moment(payload.selectedTo).format('YYYY-MM-DD'));

    let list = [];
    moment.locale(selector.i18n.lang);
    if (data && data.list.length) {
      data.list.forEach(item => {
        let dateUtc = moment(item.duedateUTC).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        let dayStr = moment(dateUtc.split('T')[0]).format('MMMM DD');
        if (list[dayStr] === undefined) {
          list[dayStr] = [item];
        } else {
          list[dayStr].push(item);
        }
      });
    }

    yield put(actions.fillCallsByDate(list));
  });

  yield takeLatest(actionTypes.SEND_SLIDE_FROM_GRID, function* sendSlideFromGridSaga({ payload }) {
    const selector = yield select(getState);
    //TODO: Verificar type != 2
    selector.presentationBar.videoManager.session.signal({
      type: 'VISUALAID_START',
      data: JSON.stringify({
        code: payload.code,//  --> codigo del slide
        type: selector.presentationBar.focusedPresentationSlides.type,
        startAtOrder: payload.numSlide,//  --> posicion de comienzo
        startAtCode: selector.presentationBar.focusedPresentationSlides.content[payload.numSlide].code,
        entryPoint: payload.entryPoint
      })
    }, (err) => {
      if(err){
        console.log(err);
      }
    });

  });

  yield takeLatest(actionTypes.FILL_SLIDES_DRAWER, function* fillSlidesDrawerObjectSaga({ payload }) {

    try{
      const { data, status } = yield getSlides(payload);
      //console.log(data, status);
      if(status < 300){
        if(!data.entryPoint){
          data.entryPoint = data.baseUrl;
        }

        if(data.config) {
          data.config = JSON.parse(data.config);
        }
        
        //console.log('FILL_SLIDES_DRAWER', data)
        yield put(actions.fillResponseSlidesDrawer(true));
        yield put(actions.fulfillPresentationSlides(data));
      }else{
        yield put(actions.fillResponseSlidesDrawer(false));
      }
    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
    }
  });

  yield takeLatest(actionTypes.GET_PRESENTATIONS, function* getPresentationsObjectSaga({ payload }) {
    try{
      const { data } = yield getPresentationObject(payload);
  
      data.list.forEach((item) => {
        item.searchableName = item.name.split('_').join(' ');
      });
      yield put(actions.fulfillPresentationList(data, payload.startAtOrder));

    }catch(e){
      yield put(actions.errorGettingData(true));
      trackException(e);
      console.log(e.message)
    }
  });

  yield takeLatest(actionTypes.CLOSE_PRESENTATION_SCREEN, function* closePresentationScreenSaga({ payload }) {
    // VISUALAID_END
   const selector = yield select(getState);
   if(selector.auth.deployment.callData.type != 2){
    if(!payload.isPreview){
      payload.videoManager.session.signal({
        type: 'VISUALAID_END',
        data: JSON.stringify({
          code: payload.code,//  --> codigo de la presentacion
          reason: payload.reason
        })
      }, (err) => {
        //console.log(err);
      });
     }
    }
    yield put(actions.setVaSize(null));
  });

  yield takeLatest(actionTypes.GET_PRESENTATION_OBJECT, function* presentationBarSaga({ payload }) {
    try{
      
      const { data } = yield getSlides(payload.code);

      if(!data.entryPoint){
        data.entryPoint = data.baseUrl;
      }
      
      if(data.config) {
        data.config = JSON.parse(data.config);
      }

      let {flows, assets} = data.config;

      if (flows && flows.length > 0 && assets && assets.length > 0 && data.content && payload.vaLogoUrl) {
        flows.forEach((f) => {
          let flowUrl = getFlowUrl(f, data.content, assets);
          f.url = flowUrl ? flowUrl : payload.vaLogoUrl;
          f.slides = getFlowSlides(f, data.content, assets);
            
        });
        
        data.content = flows[0].slides;
        yield put(actions.setFocusedPresentationFlows({flows: flows, indexOfSelectedFlow: 0, currentFlow: flows[0]}));
        yield put(actions.updatePesentationFocusedLength(flows[0].slides.length.toString()));
      }

      yield put(actions.fulfillPresentation(data, payload.startAtOrder));
      yield put(actions.updateStateDrawers({ displayScreenPresentation: true }));
    }catch (e){
      console.log(e)
    }
    //console.log(data)
    // ahora hay que rellenar el estado de los drawers para que renderice la pantalla de la presentacion
  });

  yield takeLatest(actionTypes.SEND_SLIDE_ACTIVE, function* updateSlideActiveSaga({ payload }) {
    //console.log(payload)
    //TODO: Verificar type !=2
    const selector = yield select(getState);
    if(payload.isPreview === false && selector.auth.deployment.callData.type != 2){
      payload.videoManager.session.signal({
        type: 'VISUALAID_GOTO',
        data: JSON.stringify({
          code: payload.code,//  --> codigo del slide
          type: payload.type,
          gotoPosition: payload.numSlide//  --> posicion de comienzo
        })
      }, (err) => {
        //console.log(err);
      });
    }
  });

  yield takeLatest(actionTypes.SEND_FLOW_ACTIVE, function* updateFlowActiveSaga({ payload }) {
    //console.log(payload)
    //TODO: Verificar type !=2
    try{
      const selector = yield select(getState);
      
      yield put(actions.setFocusedPresentationFlows({...selector.presentationBar.focusedPresentationFlows, indexOfSelectedFlow: payload.numFlow, currentFlow: payload.currentFlow}));
      yield put(actions.setFocusedPresentationSlides({...selector.presentationBar.focusedPresentationSlides, content: payload.currentFlow.slides}));
      if(payload.isPreview === false){
        if(loggerStorage){
          yield put( loggerStorage.actions.log({
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: 'VISUALAID_GOTO',
            log_text: `code: ${payload.code} | startAtOrder: ${payload.numSlide} | startAtCode: ${payload.currentFlow.flow[payload.numSlide].code} | type: ${payload.type}`
          }));
        }
        
        if(selector.auth.deployment.callData.type != 2){
          payload.videoManager.session.signal({
            type: 'VISUALAID_GOTO',
            data: JSON.stringify({
              code: payload.code,//  --> codigo del slide
              type: payload.type,
              gotoPosition: payload.numSlide//  --> posicion de comienzo
            })
          }, (err) => {
            //console.log(err);
          });
        }
      }
    }
    catch(err) {
      console.log(err)
    }
    
  });

  yield takeLatest(actionTypes.UPDATE_SCREEN_PRESENTATION, function* presentationBarSaga({ payload }) {
    try{
      const { data } = yield getSlides(payload.focusedPresentationSlides.code);
      const selector = yield select(getState);
      const auth = selector.auth;
      const type = auth.type;

      yield put(actions.setFocusedPresentationFlows({flows: [], indexOfSelectedFlow: 0, currentFlow: {}}));

      if(!data.entryPoint){
        data.entryPoint = data.baseUrl;
      }
      
      if(data.config) {
        data.config = JSON.parse(data.config);
      }

      let {flows, assets} = data.config;

      if (flows && flows.length > 0 && assets && assets.length > 0 && data.content && payload.vaLogoUrl) {
        flows.forEach((f) => {
          let flowUrl = getFlowUrl(f, data.content, assets);
          f.url = flowUrl ? flowUrl : payload.vaLogoUrl;
          f.slides = getFlowSlides(f, data.content, assets);
            
        });
        
        data.content = flows[0].slides;
        yield put(actions.setFocusedPresentationFlows({flows: flows, indexOfSelectedFlow: 0, currentFlow: flows[0]}));
        yield put(actions.updatePesentationFocusedLength(flows[0].slides.length.toString()));
      }

      if(loggerStorage){
        yield put( loggerStorage.actions.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'VISUALAID_START',
          log_text: `code: ${payload.presentationFocused.code} | startAtOrder: ${payload.numSlide} | startAtCode: ${data.content[payload.numSlide].code} | type: ${payload.presentationFocused.type}`
        }));
      }
      if(selector.auth.deployment.callData.type != 2){
       if(!payload.preview){
        payload.videoManager.session.signal({
          type: 'VISUALAID_START',
          data: JSON.stringify({
            code: payload.presentationFocused.code,//  --> codigo del slide
            type: payload.presentationFocused.type,
            startAtOrder: payload.numSlide,//  --> posicion de comienzo
            startAtCode: data.content[payload.numSlide].code,
            entryPoint: payload.entryPoint
          })
        }, (err) => {
          //console.log(err);
        });
       }
      }
      yield put(actions.fulfillPresentation(data, payload.numSlide));
    }catch(e){
      console.log(e)
    }
  });

  yield takeLatest(actionTypes.SEND_VISUAL_AID_EMAIL, function* sendVisualAidEmailSaga({ payload }) {
    try {
      const selector = yield select(getState);

      payload.callcode = selector.auth.callCode;
      payload.vacode = selector.presentationBar.presentationFocused.code;

      const { data } = yield sendVaidFileEmail(payload);
      yield put(actions.setEmailResult('ok'));
    } catch (err) {
        if(err.response && err.response.data && err.response.data != ''){
            yield put(actions.setEmailResult(err.response.data));
        }else{
            yield put(actions.setEmailResult('error'));
        }
    }
  });

  yield takeLatest(actionTypes.EXECUTE_ACTION_AAN, function* ExecuteActionAAN({payload}){
    // AAN:GOTOANDSTOP:210 (TYPE:ACTION:FRAME)
    const selector = yield select(getState);

    if(!payload.isPreview){
      let payloadLog = payload;
      if(payload && payload.payload){
        payloadLog = payload.payload;
      }
      yield put(loggerStorage.actions.log(
        {
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: payload.type + '_' + payload.command,
            log_text: `code: ${selector.presentationBar.presentationFocused.code} | payload: ${payloadLog}`
        }
      ));
      if(selector.auth.deployment.callData.type != 2){
       selector.presentationBar.videoManager.session.signal(
        {
          type: payload.type,
          data: JSON.stringify(
            {
              code: selector.presentationBar.presentationFocused.code,//  --> codigo del slide
              command: payload.command, // 
              payload: payload.payload
            }
          )
        }, (err) => {
          console.log(err);
      });
     }
    }
  });

  yield takeLatest(actionTypes.CALL_SESSION, function* callSessionSaga() {
    const selector = yield select(getState);
    let dateUtc = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
    try{
      const { data } = yield callSession(
        {
          "category": "START",
          "description": "",
          "dateUTC": dateUtc
        },
        selector.auth.deployment.callData.code,
        selector.auth.deployment.callData.callSession
      );
    }catch(e){
      console.log(e.message);
      if(loggerStorage){
        yield put( loggerStorage.actions.log({
          logtype: 1,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_ERROR',
          log_text: `source: call_session_log | desc: ${e.message}`
        }));
      }      
    }

  });

  yield takeLatest(actionTypes.SEND_SLIDE_FEEDBACK, function* sendSlideFeedbackSaga({payload}){
    try{
      const { data } = yield sendSlideFeedback(payload.callCode, payload.data);

      yield put(actions.setSendSlideFeedbackResult({ message: 'ok', result: { callCode: payload.callCode, data: payload.data } }));
    }catch(err){
      if(err.response && err.response.data && err.response.data != ''){
            yield put(actions.setSendSlideFeedbackResult(err.response.data));
        }else{
            yield put(actions.setSendSlideFeedbackResult('error'));
        }
    }
  });
}