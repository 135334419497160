let usedSlideCodes = new Set();

export const getDeploymentParamValue = (parameters, name) => {
  let value = '';
  for (let index = 0; index < parameters.length; index++) {
    const element = parameters[index];
    if (element.key.includes(name)) {
      value = element.value;
      break;
    }
  }
  return value;
}

// Función para obtener el preview del flow
export const getFlowUrl = (flow, slides, assets) => {
  let preview = '';
  preview = processAssets(flow, slides, assets, false);
  usedSlideCodes.clear();
  return preview;
}

// Función para encontrar una slide por nombre
const findSlide = (asset, slides) => {
  if (slides && slides.length > 0) {
    return slides.find(function (slide) {
      return slide.name === asset.name && !usedSlideCodes.has(slide.code);
    });
  }
}

// Función para obtener un asset por su id
const getAsset = (id, assets) => {
  if (assets && assets.length > 0) {
    for (let i = 0; i < assets.length; i++) {
      let asset = assets[i];

      if (asset.id === id) {
        return asset;
      }
    }
  }
}

// Función para armar el array de slides
const processAssets = (currentFlow, slides, assets, returnSlides) => {
  let order = 1;
  let slidesArr = [];

  for (let i = 0; i < currentFlow.flow.length; i++) {
    let flowElement = currentFlow.flow[i];
    let asset = getAsset(flowElement, assets);
    let slideFound = findSlide(asset, slides);
    if (slideFound) {
      if (returnSlides) {
        let slideFoundCopy = { ...slideFound, order: order };
        usedSlideCodes.add(slideFoundCopy.code);
        slidesArr.push(slideFoundCopy);
        order++;
      } else {
        return slideFound.url;
      }
    }
  }

  return slidesArr;
}

// Función para obtener slides de un flow
export const getFlowSlides = (currentFlow, slides, assets) => {
  let slidesArr = processAssets(currentFlow, slides, assets, true);
  usedSlideCodes.clear();
  return slidesArr;
}