import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '80%',
    minWidth: 120,
    marginTop: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const VisitTypeSelector = (props) => {
  const globalState = useSelector(state => state);
  const [types, setTypes] = React.useState([]);
  const classes = useStyles();
  const { intl } = props;
  const [typeSelected, setTypeSelected] = React.useState({
    name:intl.formatMessage({
      id: "DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE"
    }), 
    code:1
  });
  
  useEffect(() => {
    //listener will be added on component mounted
    const typeOnline = {name: intl.formatMessage({
      id: "DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE"
    }), code: 1};
    const typeOffline = {name: intl.formatMessage({
      id: "DASHBOARD.NEW.VISIT.SELECT.TYPE.PRESENTATION"
    }), code: 2};
    let typesArray = [];
    typesArray.push(typeOnline);
    typesArray.push(typeOffline);
    setTypes(typesArray);
    const name = intl.formatMessage({
      id: "DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE"
    });
    setTypeSelected({
      name:name, 
      code:1
    })
    props.updateVisitTypeSelected({
      name:name, 
      code:1
    });
  }, []);


  

  const handleChange = (event,newValue) => {
    //props.selectCustomer(event.target.value);
    //Object.assign(globalState.presentationBar.customerSelected, event.target.value);
    //console.log(typeSelected);
    if(newValue != null){
      setTypeSelected(newValue);
      props.updateVisitTypeSelected(newValue);
    }else{
      const name = intl.formatMessage({
        id: "DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE"
      });
      setTypeSelected({
        name:name, 
        code:1
      })
    }
  };

  return (
    <FormControl className={classes.formControl} disabled={false}>
      <Autocomplete
        value={typeSelected}
        onChange={handleChange}
        inputValue={typeSelected.name}
        getOptionLabel={option => option.name}
        options={types}
        sx={{ width: 300 }}
        disableClearable
        renderInput={(params) => <TextField {...params} />}
      />

    </FormControl>
  )

}

export default injectIntl(
  connect(
      null,
      presentationBarStorage.actions
  )(VisitTypeSelector)
)