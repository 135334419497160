import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getPromotionalPlans,
  getUserGroups,
  getCustomerGroups,
  getVisualAids,
  getFiles,
  getProducts,
  savePromotionalPlans,
  getOnePromotionalPlan
} from '../../crud/promotionalPlans.crud';
import * as moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';


export const actionTypes = {
  GET_PROMOTIONAL_PLANS: "GET_PROMOTIONAL_PLANS",
  FILL_PROMOTIONAL_PLANS: "FILL_PROMOTIONAL_PLANS",
  GET_USER_GROUPS_PROMOTIONAL_PLANS: "GET_USER_GROUPS_PROMOTIONAL_PLANS",
  FILL_USER_GROUPS_PROMOTIONAL_PLANS: "FILL_USER_GROUPS_PROMOTIONAL_PLANS",
  GET_CUSTOMER_GROUPS_PROMOTIONAL_PLANS: "GET_CUSTOMER_GROUPS_PROMOTIONAL_PLANS",
  FILL_CUSTOMER_GROUPS_PROMOTIONAL_PLANS: "FILL_CUSTOMER_GROUPS_PROMOTIONAL_PLANS",
  GET_VISUAL_AIDS_PROMOTIONAL_PLANS: "GET_VISUAL_AIDS_PROMOTIONAL_PLANS",
  FILL_VISUAL_AIDS_PROMOTIONAL_PLANS: "FILL_VISUAL_AIDS_PROMOTIONAL_PLANS",
  GET_FILES_PROMOTIONAL_PLANS: "GET_FILES_PROMOTIONAL_PLANS",
  FILL_FILES_PROMOTIONAL_PLANS: "FILL_FILES_PROMOTIONAL_PLANS",
  SAVE_PROMOTIONAL_PLANS: "SAVE_PROMOTIONAL_PLANS",
  FILL_NEW_PROMOTIONAL_PLAN_OBJECT: "FILL_NEW_PROMOTIONAL_PLAN_OBJECT",
  SAVE_RESULT_PROMOTIONAL_PLANS: "SAVE_RESULT_PROMOTIONAL_PLANS",
  GET_ONE_PROMOTIONAL_PLANS: "GET_ONE_PROMOTIONAL_PLANS",
  FILL_ONE_PROMOTIONAL_PLANS: "FILL_ONE_PROMOTIONAL_PLANS",
  RESET_NEW_PROMOTIONAL_PLAN_OBJECT: "RESET_NEW_PROMOTIONAL_PLAN_OBJECT",
  RESET_PROMOTIONAL_PLANS: "RESET_PROMOTIONAL_PLANS",
  SET_ERROR_VALIDATION: "SET_ERROR_VALIDATION",
  GET_PRODUCTS_PROMOTIONAL_PLANS: "GET_PRODUCTS_PROMOTIONAL_PLANS",
  FILL_PRODUCTS_PROMOTIONAL_PLANS: "FILL_PRODUCTS_PROMOTIONAL_PLANS",
  SELECT_PLAN_CODE: "SELECT_PLAN_CODE"
};


const initialState = {
  promotionalPlans: [],
  promotionalPlansResult: null,
  userGroups: [],
  userGroupsResult: null,
  customerGroups: [],
  customerGroupsResult: null,
  visualAids: [],
  visualAidsResult: null,
  files: [],
  products: [],
  filesResult: null,
  productsResult: null,
  newPromotionalPlan: {
    name: "",
    externalCode: "",
    validFrom: null,
    validTo: null,
    userGroups: [],
    customerGroups: [],
    visualAids: [],
    files: [],
    uuid: null,
    products: []
  },
  errorValidation: null,
  loading: false,
  resultGetOnePromotionalPlan: null,
  resultSavePromotionalPlan: null,
  planCodeSelected: null
}

export const reducer = persistReducer(
  { storage, key: "appState", whitelist: [""] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_PROMOTIONAL_PLANS:
      case actionTypes.GET_USER_GROUPS:
      case actionTypes.GET_CUSTOMER_GROUPS:
      case actionTypes.GET_VISUAL_AIDS:
      case actionTypes.GET_FILES:
      case actionTypes.GET_FILES_PROMOTIONAL_PLANS:
      case actionTypes.SAVE_PROMOTIONAL_PLANS:
      case actionTypes.GET_ONE_PROMOTIONAL_PLANS:
      case actionTypes.GET_PRODUCTS_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.FILL_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { promotionalPlans: action.payload.data, promotionalPlansResult: action.payload.result, loading: false } );
      case actionTypes.FILL_USER_GROUPS_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { userGroups: action.payload.data, userGroupsResult:action.payload.result, loading: false } );
      case actionTypes.FILL_CUSTOMER_GROUPS_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { customerGroups: action.payload.data, customerGroupsResult: action.payload.result, loading: false } );
      case actionTypes.FILL_VISUAL_AIDS_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { visualAids: action.payload.data, visualAidsResult: action.payload.result, loading: false } );
      case actionTypes.FILL_FILES_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { files: action.payload.data, filesResult: action.payload.result, loading: false } );
      case actionTypes.FILL_PRODUCTS_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { products: action.payload.data, productsResult: action.payload.result, loading: false } );
      case actionTypes.FILL_NEW_PROMOTIONAL_PLAN_OBJECT:
        return Object.assign({}, state,  { newPromotionalPlan: {...state.newPromotionalPlan, ...action.payload}, loading: false } );
      case actionTypes.RESET_NEW_PROMOTIONAL_PLAN_OBJECT:
        return Object.assign({}, state,  { newPromotionalPlan: {}, loading: false } );
      case actionTypes.FILL_ONE_PROMOTIONAL_PLANS:
        
        return Object.assign({}, state,  {  
          newPromotionalPlan: {
            code: action.payload.data.code,
            name: action.payload.data.name,
            externalCode: action.payload.data.externalCode,
            validFrom: action.payload.data.validFrom_UTC, //moment(action.payload.selectedFrom).format('YYYY-MM-DD')
            validTo: action.payload.data.validTo_UTC,
            userGroups: action.payload.data.userGroups || [],
            customerGroups: action.payload.data.customerGroups || [],
            visualAids: action.payload.data.visualAids || [],
            files: action.payload.data.files || [],
            products: action.payload.data.products || [],
            uuid: action.payload.data.uuid || null
          },
          loading: false, 
          resultGetOnePromotionalPlan: action.payload.resultStatus 
        });
      case actionTypes.SAVE_RESULT_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { resultSavePromotionalPlan: action.payload } );
      case actionTypes.RESET_PROMOTIONAL_PLANS:
        return Object.assign({}, state, {
          promotionalPlans: [],
          promotionalPlansResult: null,
          userGroups: [],
          userGroupsResult: null,
          customerGroups: [],
          customerGroupsResult: null,
          visualAids: [],
          visualAidsResult: null,
          files: [],
          filesResult: null,
          newPromotionalPlan: {
            name: "",
            externalCode: "",
            validFrom: null,
            validTo: null,
            userGroups: [],
            customerGroups: [],
            visualAids: [],
            files: [],
            products: [],
            uuid: null
          },
          loading: false,
          resultGetOnePromotionalPlan: null,
          planCodeSelected: null
        });
      case actionTypes.SET_ERROR_VALIDATION:
        return Object.assign({}, state,  { errorValidation: action.payload } );
      case actionTypes.SELECT_PLAN_CODE:
        return Object.assign({}, state,  { planCodeSelected: action.payload } );
      default:
        return state
    }
  }
);

export const actions = {
  getPromotionalPlans: (value) => ({ type: actionTypes.GET_PROMOTIONAL_PLANS, payload: value}),
  fillPromotionalPlans: (value) => ({ type: actionTypes.FILL_PROMOTIONAL_PLANS, payload: value}),
  getUserGroups: (value) => ({ type: actionTypes.GET_USER_GROUPS_PROMOTIONAL_PLANS, payload: value}),
  fillUserGroups: (value) => ({ type: actionTypes.FILL_USER_GROUPS_PROMOTIONAL_PLANS, payload: value}),
  getCustomerGroups: (value) => ({ type: actionTypes.GET_CUSTOMER_GROUPS_PROMOTIONAL_PLANS, payload: value}),
  fillCustomerGroups: (value) => ({ type: actionTypes.FILL_CUSTOMER_GROUPS_PROMOTIONAL_PLANS, payload: value}),
  getVisualAids: (value) => ({ type: actionTypes.GET_VISUAL_AIDS_PROMOTIONAL_PLANS, payload: value}),
  fillVisualAids: (value) => ({ type: actionTypes.FILL_VISUAL_AIDS_PROMOTIONAL_PLANS, payload: value}),
  getFiles: (value) => ({ type: actionTypes.GET_FILES_PROMOTIONAL_PLANS, payload: value}),
  fillFiles: (value) => ({ type: actionTypes.FILL_FILES_PROMOTIONAL_PLANS, payload: value}),
  getProducts: (value) => ({ type: actionTypes.GET_PRODUCTS_PROMOTIONAL_PLANS, payload: value}),
  fillProducts: (value) => ({ type: actionTypes.FILL_PRODUCTS_PROMOTIONAL_PLANS, payload: value}),
  savePromotionalPlans: (value) => ({ type: actionTypes.SAVE_PROMOTIONAL_PLANS, payload: value}),
  saveResultPromotionalPlans: (value) => ({ type: actionTypes.SAVE_RESULT_PROMOTIONAL_PLANS, payload: value}),
  fillNewPromotionalPlanObject: (value) => ({ type: actionTypes.FILL_NEW_PROMOTIONAL_PLAN_OBJECT, payload: value}),
  resetNewPromotionalPlanObject: (value) => ({ type: actionTypes.RESET_NEW_PROMOTIONAL_PLAN_OBJECT, payload: value}),
  getOnePromotionalPlan: (value) => ({ type: actionTypes.GET_ONE_PROMOTIONAL_PLANS, payload: value}),
  fillOnePromotionalPlan: (value) => ({ type: actionTypes.FILL_ONE_PROMOTIONAL_PLANS, payload: value}),
  resetPromotionalPlan: (value) => ({ type: actionTypes.RESET_PROMOTIONAL_PLANS, payload: value}),
  setErrorValidation: (value) => ({ type: actionTypes.SET_ERROR_VALIDATION, payload: value}),
  selectPlanCode: (value) => ({ type: actionTypes.SELECT_PLAN_CODE, payload: value})
}

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.SAVE_PROMOTIONAL_PLANS, function* getSavePromotionalPlansSaga({payload}) {
    const selector = yield select(getState);
    let visualAidsuuidArr = [];
    let filesuuidArr = [];
    let productsuuidArr = [];
    let userGroupsUuidArr = [];
    let customerGroupUuidArr = [];

    
    if(selector.promotionalPlans.newPromotionalPlan.visualAids.length > 0){
      selector.promotionalPlans.newPromotionalPlan.visualAids.forEach(visualAid => {
        visualAidsuuidArr.push(visualAid.code)
      });
    }
    if(selector.promotionalPlans.newPromotionalPlan.files.length > 0){
      selector.promotionalPlans.newPromotionalPlan.files.forEach(file => {
        filesuuidArr.push(file.code)
      });
    }
    if(selector.promotionalPlans.newPromotionalPlan.products.length > 0){
      selector.promotionalPlans.newPromotionalPlan.products.forEach(product => {
        productsuuidArr.push(product.code)
      });
    }
    if(selector.promotionalPlans.newPromotionalPlan.userGroups.length > 0){
      selector.promotionalPlans.newPromotionalPlan.userGroups.forEach(group => {
        userGroupsUuidArr.push(group.uuid)
      });
    }
    if(selector.promotionalPlans.newPromotionalPlan.customerGroups.length > 0){
      selector.promotionalPlans.newPromotionalPlan.customerGroups.forEach(customerGroup => {
        customerGroupUuidArr.push(customerGroup.uuid)
      });
    }
    //data.validFrom = moment(data.validFrom).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    //data.validTo = moment(data.validTo).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    let objToSend = {
      name: selector.promotionalPlans.newPromotionalPlan.name,
      externalCode: selector.promotionalPlans.newPromotionalPlan.externalCode,
      validFrom: moment(selector.promotionalPlans.newPromotionalPlan.validFrom).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      validTo: moment(selector.promotionalPlans.newPromotionalPlan.validTo).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
      userGroups: userGroupsUuidArr,
      customerGroups: customerGroupUuidArr,
      visualAids: visualAidsuuidArr,
      files: filesuuidArr,
      products: productsuuidArr,
      uuid: (selector.promotionalPlans.newPromotionalPlan.uuid) ? selector.promotionalPlans.newPromotionalPlan.uuid : null
    }
    
    try{
      if(objToSend.name){
        const {data, status} = yield savePromotionalPlans(objToSend, payload);
        
  
        if(status < 300){
          yield put(actions.saveResultPromotionalPlans(true));
        }else{
          yield put(actions.saveResultPromotionalPlans(false));
        }
      }else{
        throw new Error('validator');
      }
    }catch(e){
      yield put(actions.saveResultPromotionalPlans(false));
    }
    
  })

  yield takeLatest(actionTypes.GET_ONE_PROMOTIONAL_PLANS, function* getOnePromotionalPlansSaga({payload}) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getOnePromotionalPlan(payload);
      
      if(status < 300){
        data.validFrom_UTC = moment(data.ValidFrom_UTC).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        data.validTo_UTC = moment(data.ValidTo_UTC).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
        yield put(actions.fillOnePromotionalPlan({
          data: data,
          resultStatus: true
        }));
      }else{
        yield put(actions.fillOnePromotionalPlan({
          data: {},
          resultStatus: false
        }));
      }
    }catch(e){
      yield put(actions.fillOnePromotionalPlan({
        data: {},
        resultStatus: false
      }));
    }    
  })

  yield takeLatest(actionTypes.GET_PROMOTIONAL_PLANS, function* getPromotionalPlansSaga({payload}) {
    try{
      const {data, status} = yield getPromotionalPlans(payload); 
      if(status < 300){
        yield put(actions.fillPromotionalPlans({data:data.list, result: true}));
      }else{
        yield put(actions.fillPromotionalPlans({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillPromotionalPlans({data:[], result: false}));
    }    
  })
  
  yield takeLatest(actionTypes.GET_USER_GROUPS_PROMOTIONAL_PLANS, function* getUserGroupsSaga({payload}) {
    try{
      const {data, status} = yield getUserGroups(payload);

      if(status < 300){
        yield put(actions.fillUserGroups({data:data.list, result: true}));
      }else{
        yield put(actions.fillUserGroups({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillUserGroups({data:[], result: false}));
    }    
  })

  yield takeLatest(actionTypes.GET_CUSTOMER_GROUPS_PROMOTIONAL_PLANS, function* getCustomerGroupsSaga({payload}) {
    try{
      const {data, status} = yield getCustomerGroups(payload); 
      if(status < 300){
        yield put(actions.fillCustomerGroups({data:data.list, result: true}));
      }else{
        yield put(actions.fillCustomerGroups({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillCustomerGroups({data:[], result: false}));
    }    
  })

  yield takeLatest(actionTypes.GET_VISUAL_AIDS_PROMOTIONAL_PLANS, function* getGetVisualAidsSaga({payload}) {
    try{
      const {data, status} = yield getVisualAids(payload); 
      if(status < 300){
        yield put(actions.fillVisualAids({data:data.list, result: true}));
      }else{
        yield put(actions.fillVisualAids({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillVisualAids({data:[], result: false}));
    }    
  })
  
  yield takeLatest(actionTypes.GET_FILES_PROMOTIONAL_PLANS, function* getFilesSaga({payload}) {
    try{
      const {data, status} = yield getFiles(payload); 
      if(status < 300){
        yield put(actions.fillFiles({data:data.list, result: true}));
      }else{
        yield put(actions.fillFiles({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillFiles({data:[], result: false}));
    }    
  })
  
  yield takeLatest(actionTypes.GET_PRODUCTS_PROMOTIONAL_PLANS, function* getProductsSaga({payload}) {
    try{
      const {data, status} = yield getProducts(1, payload); 
      //console.log(data)
      if(status < 300){
        yield put(actions.fillProducts({data:data.list, result: true}));
      }else{
        yield put(actions.fillProducts({data:[], result: false}));
      }
    }catch(e){
      yield put(actions.fillProducts({data:[], result: false}));
    }    
  })
}

