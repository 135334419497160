import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import StopIcon from '@material-ui/icons/Stop';

// ESTO ES UN MOCK POR FAVOR SACARLO DEBUG
//import PresentationsObject from '../../../_metronic/__mocks__/mockPresentations';


import './PresentationBarGrid.scss';
import { useSelector } from 'react-redux';
import {isMobile,isIPad13, isIPhone13} from 'react-device-detect';

// Impoorting action types and methods from local actions file
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import * as authStorage from "../../store/ducks/auth.duck";
import PresentationGrid from '../PresentationGrid/PresentationGrid';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { getDeploymentParamValue } from '../VideoChat/utils';

const useStyles = makeStyles((theme) => ({
  inRow: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    marginTop: 20,
    width: '90%',
  },
  filter: {
    width: '10%',
    paddingLeft: 10,
    paddingRight: 10
  }
}));

function PresentationBarGrid(props) {
  const [vaLogoUrl, setValogoUrl] = React.useState('');
  // prueba de redux con hook DEBUG
  const globalState = useSelector(state => state)
  const { intl } = props;

  const classes = useStyles();

  useEffect(() => {
    if(globalState.auth.deployment.parameters){
      setValogoUrl(getDeploymentParamValue(globalState.auth.deployment.parameters, "VA_LOGO_URL"));
    }
  }, [globalState.presentationBar.presentationSlides]);

  const toggleDrawer = (side, open, secondSideToClose) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') && event.type === 'click') {
      return;
    }

    //props.filterSearch('', false);

    Object.assign(globalState.presentationBar, { [side]: open, [secondSideToClose]: false })
    props.updateStateDrawers({ [side]: open, [secondSideToClose]: false });
  };

  const goBack = () => event => {
    if (!globalState.presentationBar.displayScreenPresentation) {
      props.goBack(Object.assign(globalState.presentationBar, { presentationFocused: {}, focusedPresentationSlides: {} }));
    } else {
      props.goBack(globalState.presentationBar);
    }
    toggleDrawer('showPresentationList', true, 'showSlideList')();
  }

  const showSlideList = (presentationSlide, open) => event => {
    if(event != undefined){
      toggleDrawer('showPresentationList', false);
        props.fillSlidesDrawer(presentationSlide.code);

        Object.assign(globalState.presentationBar, {
          showSlideList: open,
          presentationFocusedSlides: presentationSlide
        })
        props.updateStateDrawers({
          showSlideList: open,
          presentationFocusedSlides: presentationSlide
        });
    }
  };

  const stopPresentation = (reason) => event => {
    //console.log('entre por stop presentation del presentationBar')
    if(isMobile || isIPad13 || isIPhone13){
      unlockOrientation();
    }
    event.stopPropagation();
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_END',
      log_text: `code: ${globalState.presentationBar.presentationFocused.code} | reason: manual`
    })
    props.analyticsLogEvent('stop presentation');

    let fnCallbackClosePresentation = () => {
      props.closePresentation(Object.assign(globalState.presentationBar, {
        displayScreenPresentation: false,
        videoManager: globalState.presentationBar.videoManager,
        code: globalState.presentationBar.presentationFocused.code,
        presentationFocused: {},
        focusedPresentationSlides: {},
        //presentationsListFiltered: globalState.presentationBar.presentationsList.list,
        reason: reason
      }));
    }
    
    // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
    if(globalState.presentationBar.presentationFocused.type == 'sdva'){
      props.sendGotoSdvaSlide(
          {
              type: 'SDVA',
              command: 'HIDEFULLSCREENMOVIE',
              payload: 'NA'
          }
      );

      setTimeout(() => {
          fnCallbackClosePresentation();
      }, 1200);
    }else{
        fnCallbackClosePresentation();
    }

    // TODO: Analizar porque hay veces que no existe esta funcion, se agrega esta validacion para evitar que pinche
    if(props.stopMouseMove){
      props.stopMouseMove();
    }
  }

  const playSpecificSlide = () => event => {
    var indexOfSelectedSlide = null;
    var indexPreviousActive = null;
    globalState.presentationBar.presentationSlides.content.forEach(function (item, index) {
      if (item.state === 'selected') {
        indexOfSelectedSlide = index;
      }
      if (item.active === 'active') {
        indexPreviousActive = index;
      }
    });

    if (indexPreviousActive >= 0 && globalState.presentationBar.presentationSlides.content[indexPreviousActive]) {
      globalState.presentationBar.presentationSlides.content[indexPreviousActive].active = "";
    }

    globalState.presentationBar.focusedPresentationSlides = globalState.presentationBar.presentationSlides;
    Object.assign(
      globalState.presentationBar.focusedPresentationSlides,
      globalState.presentationBar.focusedPresentationSlides
    );

    Object.assign(globalState.presentationBar, {
      presentationFocused: globalState.presentationBar.presentationFocusedSlides,
      code: globalState.presentationBar.focusedPresentationSlides.code,
      numSlide: indexOfSelectedSlide,
      displayScreenPresentation: true,
      initialSlideIndex: indexOfSelectedSlide
    })

    props.sendSlideFromGrid({
      presentationFocused: globalState.presentationBar.presentationFocusedSlides,
      code: globalState.presentationBar.focusedPresentationSlides.code,
      numSlide: indexOfSelectedSlide,
      displayScreenPresentation: true
    });
    
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_START',
      log_text: `code: ${globalState.presentationBar.focusedPresentationSlides.code} | startAtOrder: ${indexOfSelectedSlide} | startAtCode: ${globalState.presentationBar.focusedPresentationSlides.content[indexOfSelectedSlide].code} `
    })
    props.analyticsLogEvent(`specific slide code: ${globalState.presentationBar.focusedPresentationSlides.code} | startAtOrder: ${indexOfSelectedSlide} | startAtCode: ${globalState.presentationBar.focusedPresentationSlides.content[indexOfSelectedSlide].code}`);

    closeDrawer();
  }

  function unlockOrientation () {
    /*var screen = window.screen;
    screen.orientation.unlock();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }*/
  }

  function lockOrientation(orientation) {
    /*var screen = window.screen;
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
    screen.orientation.lock(orientation);*/
  }

  // TODO: This method is passed to child components, refactor this architecture please 
  const startPresentation = (numSlide, presentationSlide) => event => {
    if(isMobile || isIPad13 || isIPhone13){
      lockOrientation("landscape-primary");
    }
    
    switch(presentationSlide.type){
      case 'html5animate':
      case 'sdva':
        Object.assign(globalState.presentationBar, {
          initialSlideIndex: 0
        });
        
        props.updateStateScreenPresentation(Object.assign(globalState.presentationBar, {
          displayScreenPresentation: true,
          numSlide: numSlide,
          presentationFocused: presentationSlide,
          focusedPresentationSlides: presentationSlide,
          entryPoint: presentationSlide.entryPoint,
          config: presentationSlide.config,
          type: presentationSlide.type,
          vaLogoUrl: vaLogoUrl
        }));
        props.analyticsLogEvent(`start_presentation`);
      break;
      case 'video':
      case 'slides':
      default:
        props.updateStateScreenPresentation(Object.assign(globalState.presentationBar, {
          displayScreenPresentation: true,
          numSlide: numSlide,
          presentationFocused: presentationSlide,
          focusedPresentationSlides: presentationSlide,
          entryPoint: presentationSlide.entryPoint
        }));

    }
    
    //props.filterSearch('', false);
    closeDrawer();
  }

  function closeDrawer() {
    setTimeout(() => {
      var closeList = document.getElementById('closeSlideList');
      var closePresentationList = document.getElementById('closePresentationList');
      
      if(closeList){
        document.getElementById('closeSlideList').click();
      }
      if(closePresentationList){
        document.getElementById('closePresentationList').click();
      }
    }, 1000);
  }

  return (
    <>
   
         
         
      {!globalState.presentationBar.displayScreenPresentation?
  
        <PresentationGrid
          deploymentStyle={props.deploymentStyle} 
          side="showPresentationList" 
          toggleDrawer={toggleDrawer} 
          showSlideList={showSlideList} 
          startPresentation={startPresentation} 
          stopPresentation={stopPresentation} 
          props={props}
        /> 
        :null
       
        }
       
      </>
  );
}

export default injectIntl(
  connect(
    null,
    {...presentationBarStorage.actions, ...loggerStorage.actions, ...authStorage.actions}
  )(PresentationBarGrid)
);