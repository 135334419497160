import React, { createContext } from "react";
import { reactPlugin } from "./TelemetryService";

const TelemetryProviderContext = createContext(reactPlugin);

const TelemetryProviderContextProvider = ({ children }) => {
    return (
        <TelemetryProviderContext.Provider value={reactPlugin}>
            {children}
        </TelemetryProviderContext.Provider>
    );
};

export { TelemetryProviderContext, TelemetryProviderContextProvider };