import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";

const ConfirmDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  const handleAccept = () =>{
    props.handleAccept();
  }
  
  const handleClose = () => {
    props.onClose();
  };

  return(
    <div style={{width: '100%'}} id="form-dialog-title-schedule-modifier">
      <Dialog
        open={open}
        onClose={handleClose}
      >
          {props.title &&
            <DialogTitle>{props.title}</DialogTitle>
          }
          
        <DialogContent className="schedule__modifier__popup__content">
          <br />
          <br />
          <span>{props.message}</span>
        </DialogContent>
        <DialogActions>
          <Button class="btn btn-primary" id="btnAccept" onClick={handleAccept}>
            <FormattedMessage id="CONFIRM_DIALOG.ACCEPT" />
          </Button>
          <Button class="btn btn-secondary" id="btnClose" onClick={handleClose}>
            <FormattedMessage id="CONFIRM_DIALOG.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog;