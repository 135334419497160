import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { defaultStyles } from '../../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import * as folderStorage from "../../../store/ducks/folders.duck";
import Folders from '../../../components/Folders/Folders';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  }
}));

const SelectFolderPopUp = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [isSelectDisabled, setIsSelectDisabled] = React.useState(true);
  
  useEffect(() => {
    if(Object.entries(globalState.folders.folderSelected).length !== 0) {
        setIsSelectDisabled(false);
    }
  }, [globalState.folders.folderSelected]) 

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleAccept = () => {
    if(!isSelectDisabled){
        const folder = {
          name: globalState.folders.folderSelected.name,
          code: globalState.folders.folderSelected.code
        };
        props.handleSelectFolder(folder);

        props.setSelectedFolder({});
        setOpen(false);
    }
  }

  return(
    <div>
      <Button className="btn btn-sm btn-label-default" id="btnSelectFolder" onClick={() => handleOpen()}>
        <FormattedMessage id="FILE.SELECT.BUTTON" />
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <FormattedMessage id="FILE.SELECT.FOLDER" />    
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <Folders isFromPopUp={true} height={200}/>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnSelectFolderAccept" onClick={handleAccept} disabled={isSelectDisabled}>
            <FormattedMessage id="FILE.SELECT.BUTTON" />
          </Button>
          <Button className={classes.button} id="btnSelectFolderClose" onClick={handleClose}>
            <FormattedMessage id="FILE.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    folderStorage.actions
  )(SelectFolderPopUp)
);