import React from "react";


import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
import MyCart from "../../../app/partials/layout/MyCart";
import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
import QuickPanelToggler from "./QuickPanelToggle";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import Notifications from '../../../app/components/Notifications';
import { isMobile } from 'react-device-detect';

/*
entonces el flujo para las notificaciones es el siguiente:
voy al backend, busco notificaciones. La primera con el codigo 0
el backend me devuelve las notificaciones no expiradas
si el count de eso es mas de cero ponemos el pulse en true -> easy
la request va a poner en el estado de la aplicacion las notificaciones
para que recorra el dropdown levantando el notificationItem
*/

const Topbar = (props) => {
  // el atributo history viene desde el Routes.js
  const fromDashboard = (props.history.location.pathname === '/customers' || 
                         props.history.location.pathname === '/dashboard');
  return(
    <div className="kt-header__topbar" style={(isMobile) ? {position:'static', width: '100%'} : {}}>
      {fromDashboard && 
      <Notifications
        bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        pulseLight="false"
        skin="dark"
        iconType=""
        type="success"
        useSVG="true"
        dot="false"
      />
      }      
      <UserProfile showAvatar={true} showHi={true} showBadge={false} history={props.history} />
    </div>
  );
}

export default Topbar;