import React, { useMemo, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import * as authStorage from "../../store/ducks/auth.duck";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as powerbi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage, injectIntl } from "react-intl";
import $ from 'jquery';
import { getDeploymentParamValue } from '../../../_metronic/utils/utils';

function ReportPBIPage(props) {
  let params = useParams();
  const globalState = useSelector(state => state);
  const [report, setReport] = React.useState(null);

  const pbiFilter1 = getDeploymentParamValue(globalState.auth.deployment.parameters, "PBI_FILTER_1");
  let filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "",
      column: ""
    },
    operator: "",
    values: [""]
  };
  let table = "";
  let column = "";
  let operator = "";
  let valueToReplace = ""; 
  if (pbiFilter1) {
    let filterArrayValues = pbiFilter1.split(".");
    if (filterArrayValues[0]) {
      table = filterArrayValues[0];
    }
    if (filterArrayValues[1]) {
      column = filterArrayValues[1];
    }
    if (filterArrayValues[2]) {
      operator = filterArrayValues[2];
    }
    if (filterArrayValues[3]) {
      valueToReplace = pbiFilter1.substring(pbiFilter1.indexOf(pbiFilter1.split(".")[3]), pbiFilter1.length);
      valueToReplace = valueToReplace.replace(valueToReplace, globalState.auth.identification);
    }
    
    filter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: table,
        column: column
      },
      operator: operator,
      values: [valueToReplace]
    };
  }

  useEffect(() => {
    if (params.guid /* && globalState.auth.report == null*/){
      props.getReport({ reportid: params.guid });
    }
    props.setIsReportLoading(true);
  }, [props.history.location])

  let width = '100%';
  let height = '90%';
  if (globalState.auth.report && globalState.auth.report.report && globalState.auth.report.report.reportParameters) {
    for (let index = 0; index < globalState.auth.report.report.reportParameters.length; index++) {
      const element = globalState.auth.report.report.reportParameters[index];
      switch (element.key) {
        case 'width':
          width = element.value;
          break;
        case 'height':
          height = element.value;
          break;
        default:
          break;
      }
    }
  }

  const useStyles = makeStyles((theme) =>
    createStyles({
      container: { height: '90%', marginLeft: '90px' },
      report: { height: height, width: width }
    }),
  );


  const classes = useStyles();

  return (
    <div className={classes.container}>
      {(globalState.auth.isReportLoading) && <LinearProgress className="progressBar" />}
      {(globalState.auth.report != null && globalState.auth.report.report != null && globalState.auth.isReportLoading == false) ?
        <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: globalState.auth.report.report.reportId,
            embedUrl: globalState.auth.report.report.reportUrl,
            accessToken: globalState.auth.report.report.reportAccessToken,
            tokenType: powerbi.models ? powerbi.models.TokenType.Embed : null,
            filters: [filter],
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
            }
          }}
          cssClassName={classes.report}
          style={{ width: width, height: height }}

          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport);
          }}
        />

        : null}
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...authStorage.actions }
  )(ReportPBIPage)
);