import React from 'react';
import { connect, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage, injectIntl } from "react-intl";
import ReactHtmlParser from 'react-html-parser';
import '../Terms/Terms.scss';
import { termsAction } from "../../crud/auth.crud";
import {
  getDeviceInfo
} from '../VideoChat/utils';
import {
  isMobile, osVersion, osName, browserVersion, browserName, mobileVendor,
  mobileModel, getUA, deviceType, isIPad13, isIPhone13
} from 'react-device-detect';
// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";


const Terms = ({
  requiersTerms,
  log,
  history,
  nullAuthToken,
  termCode,
  termsTitle,
  termsBody
}) => {

  const globalState = useSelector(state => state);
  const [open, setOpen] = useState(requiersTerms);

  let termsBodyParsed = ReactHtmlParser(termsBody);

  let storageItem = undefined;
  let browserId;
  if (typeof (localStorage) !== "undefined") {
    try {
      storageItem = localStorage.getItem('browserid');
    } catch (e) {
      console.log(e)
    }
  }
  if (storageItem != undefined) {
    browserId = JSON.parse(storageItem);
  } else {
    browserId = Math.floor(Math.random() * 1000000000);
    localStorage.setItem('browserid', browserId);
  }

  if(termsTitle != ""){
    termsTitle = replaceTitleChars(termsTitle);
  }

  function replaceTitleChars(termsTitle){
    termsTitle = termsTitle.replace(/\|/g, '');
    termsTitle = termsTitle.replace(/\;/g, '');
    termsTitle = termsTitle.replace(/\:/g, '');
    termsTitle = termsTitle.replace(/\r?\n|\r/, " ");
    return termsTitle;
  }

  function acceptTerms() {
    setOpen(false);
    let logText = 'termCode: ' + termCode + ' | termTitle: ' + termsTitle + ' | ' + getDeviceInfo() + ' | browserid: ' + browserId;
    log({
      logtype: 3,
      log_category: 'TERMS_ACCEPT',
      log_text: logText
    });
    termsAction('accept',termCode,globalState.auth.callCode,logText)
    Object.assign(globalState.auth, {termsAccepted: true} );

  }

  function cancelTerms() {
    setOpen(false);
    let logText = 'termCode: ' + termCode + ' | termTitle: ' +termsTitle + ' | ' + getDeviceInfo() + ' | browserid: ' + browserId;
    log({
      logtype: 3,
      log_category: 'TERMS_REJECT',
      log_text: logText
    });
    termsAction('reject',termCode,globalState.auth.callCode,logText)
    setTimeout(() => {
      nullAuthToken();
      setTimeout(() => {
        history.push("/auth/login");
      }, 100);
    }, 2000);
  }

  return (
    <div>
      <Dialog
        open={open}
        // onClose={setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="dialogCloseCall"
      >
        <DialogTitle id="alert-dialog-title">{termsTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <table className="module" width="100%" cellPadding="0" cellSpacing="0" border="0" style={{ tableLayout: 'fixed' }}>
              <tbody>
                <tr>
                  <td style={{ padding: '5px 5px 5px 5px', backgroundColor: '#ffffff' }} height="100%" valign="top" >
                    <div className="terms-body">
                      {termsBodyParsed}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button id="btnAcceptTerms" onClick={() => acceptTerms()} color="primary">
            <FormattedMessage id="TERMS.BUTTON.ACCEPT" />
          </Button>
          <Button id="btnCancelTerms" onClick={() => cancelTerms()} color="primary" autoFocus>
            <FormattedMessage id="TERMS.BUTTON.REJECT" />
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...scheduleStorage.actions, ...presentationBarStorage.actions }
  )(Terms)
);