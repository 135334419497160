import axios from "axios";
import {configUrls} from '../../globalConfig';

export const VISUAL_AID_GET_URL = `${configUrls.base_url}/api/visualaid?`;
export const VISUAL_AID_ACTIVATE = `${configUrls.base_url}/api/visualaid/action/activate/`;
export const VISUAL_AID_INACTIVATE = `${configUrls.base_url}/api/visualaid/action/inactivate/`;
export const VISUAL_AID_APPROVE = `${configUrls.base_url}/api/visualaid/action/approve/`;
export const VISUAL_AID_PENDING = `${configUrls.base_url}/api/visualaid/action/pending/`;
export const PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan?`;
export const CREATE_VISUALAID_CONTAINER = `${configUrls.base_url}/api/visualaid/create`; 
export const GET_VISUALAID_EDIT = `${configUrls.base_url}/api/visualaid/`; 
export const SAVE_META_DATA_VAID_URL = `${configUrls.base_url}/api/visualaid/update/`; 
export const VISUAL_AID_GET_TAGS_OPTIONS_URL = `${configUrls.base_url}/api/visualaidtag`; 
export const VA_QUICK_EDITION_URL = `${configUrls.base_url}/api/visualaid/update/`; //editar esto por favor sino no va a andar saveVaQuickEdition
export const LIST_VAID_BY_CALL_URL = `${configUrls.base_url}/api/visualaid/listbycall/`;

export function saveVaQuickEdition(payload){
  return axios.put(VA_QUICK_EDITION_URL + payload.code + '?onlyValidity=true', {
    ...payload
  });
}

export function getVaidTagsOptions(){
  return axios.get(VISUAL_AID_GET_TAGS_OPTIONS_URL);
}

export function listVaidByCall(code){
  return axios.get(LIST_VAID_BY_CALL_URL + code);
}

export function saveMetaDataVaid(payload){
  let imagesArr = [];
  payload.images.forEach((element, index) => {
    imagesArr.push({
      code : (element.code) ? element.code : 0,
      order: index + 1, 
      fileName: (element.fileName) ? element.fileName : element.name,
      url: element.source
    })
  });

  let plansIdsArr = null;
  if(payload.plans && payload.plans.length > 0){
    plansIdsArr = [];
    payload.plans.forEach((plan) => {
      if(typeof plan === 'object'){
        plansIdsArr.push(plan.code);
      }else{
        plansIdsArr.push(plan);
      }
    })
  }

  return axios.put(SAVE_META_DATA_VAID_URL + payload.code, 
    {
      name: payload.name,
      externalCode: payload.externalCode,
      validFrom: payload.validFrom,
      validTo: payload.validTo,
      order: payload.order,
      content: imagesArr,
      plans: plansIdsArr,
      tags: payload.tags,
      call_type: payload.call_type
    }
  );
} 

export function uploadFile(payload){
  let newAxiosInstance = axios.create();
  const options = {
    headers: {
      'x-ms-blob-type': payload.blobType,
      'Content-Type': payload.ContentType
    }
  };

  return newAxiosInstance.put(
    payload.url, 
    payload.body,
    options  
  );
} 

export function getVisualAidEdit(codeVisualAid){
  return axios.get(GET_VISUALAID_EDIT + `${codeVisualAid}?edit=true`);
}

export function createVisualAidContainer(payload){
  return axios.post(CREATE_VISUALAID_CONTAINER, {
    type: payload.type,
    name: payload.name
  });
}

export function getPromotionalPlan(state) {
  return axios.get(PROMOTIONAL_PLAN_URL + "state_id="+ state);
}

export function getVisualAids(state, promplan){
  return axios.get(VISUAL_AID_GET_URL + "state_id="+ state + "&prom_plan="+promplan);
}

export function activateVisualAid(code){
  return axios.put(VISUAL_AID_ACTIVATE + code);
} 

export function inactivateVisualAid(code){
  return axios.put(VISUAL_AID_INACTIVATE + code);
} 

export function approveVisualAid(code){
  return axios.put(VISUAL_AID_APPROVE + code);
} 

export function pendingVisualAid(code){
  return axios.put(VISUAL_AID_PENDING + code);
} 
