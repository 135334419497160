import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const TermsSelector = (props) => {

  const handleChange = (event) => {
    props.setTermsValue(parseInt(event.target.value, 10));
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="terms" name="terms" value={props.termsValue} onChange={handleChange}>
        {props.terms.map((term) => {
          return <FormControlLabel value={term.code} control={<Radio />} label={term.title} />
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default TermsSelector;