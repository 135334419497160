import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { configUrls } from '../../../globalConfig';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import { getDeploymentParamValue } from "../../../_metronic/utils/utils";
import { useSelector } from "react-redux";

const LinkCopyClientButton = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);

  const handleClientLinkClick = () => event => {
    let shortUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_PRESENTATION_SHORT_URL") === "1";
    const el = document.createElement('textarea');
    switch (parseInt(props.item.type)) {
      case 1:
        el.value = `${configUrls.web_url}/call/${props.item.code}/${props.item.subscriberId}`;
        break;
      case 2:
        if(shortUrl){
          el.value = `${configUrls.web_url}/p/${props.item.code}C`;
        } else {
          el.value = `${configUrls.web_url}/presentation/${props.item.code}/${props.item.subscriberId}`;
        }
        break;
      default:
        el.value = `${configUrls.web_url}/call/${props.item.code}/${props.item.subscriberId}`;
        break;
    }
    
    let elementOfDom;
    
    if (props.popup) {
      elementOfDom = document.querySelector(`#call-action-share-menu-popup-${props.item.code} div:nth-of-type(3)`)
      elementOfDom.appendChild(el)
    } else {
      elementOfDom = document.querySelector(`#call-action-share-menu-${props.item.code} div:nth-of-type(3)`)
      //elementOfDom = document.body;
      elementOfDom.appendChild(el)
    }
    el.select();
    document.execCommand('copy');
    elementOfDom.removeChild(el);


    let css = document.createElement("style");
    let estilo = document.createTextNode(`
      #aviso {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: #ffb822;
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
        text-decoration: underline;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(estilo);
    document.head.appendChild(css);
    let aviso = document.createElement("div");
    aviso.setAttribute("id", "aviso");
    /*
    intl.formatMessage({
      id: "AUTH.VALIDATION.REQUIRED_FIELD"
    });
    */
    let textContenido = intl.formatMessage({
      id: "LINK.COPY.TEXT"
    });
    let contenido = document.createTextNode(textContenido);
    let urlVisible = document.createElement('a');
    urlVisible.setAttribute('href', el.value);
    urlVisible.setAttribute('class', 'toast-white');
    urlVisible.setAttribute('target', '_blank');
    urlVisible.appendChild(document.createTextNode(el.value));
    aviso.appendChild(contenido);
    aviso.appendChild(document.createElement('br'));
    aviso.appendChild(urlVisible);
    document.body.appendChild(aviso);
    window.load = setTimeout("if(typeof(aviso) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(aviso)){for (var i = 0; i < aviso.length; i++) {document.body.removeChild(aviso[i])}}else{document.body.removeChild(aviso)}}", 5000);
    //window.load = setTimeout("document.body.removeChild(aviso)", 5000);
    props.reactors[1](null)    
  }

  let style={marginLeft:1};
  if (props.popup) {
    style = {marginLeft:'10%',marginRight:'10%'}
  }

  return (
    <>
    <div id="btnAgendaLinkClient" onClick={handleClientLinkClick()}>
      <span>&nbsp;</span>
      <FormattedMessage id="LINK.COPY.BUTTON"  />
    </div>
    </>
  )
}

export default injectIntl(LinkCopyClientButton);