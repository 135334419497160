import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FileEmailButton from './FileEmailButton';
import FileWhatsAppButton from  './FileWhatsAppButton';
import { FormattedMessage, injectIntl } from "react-intl";

const FileShareButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  let idOfElement = '';
  if(props.item && props.item.code){
    idOfElement = `call-action-share-menu-${props.item.code}`;
  }

  return(
    <div style={{display: 'inline'}}>
      <Button 
        aria-controls={idOfElement}
        aria-haspopup="true" 
        onClick={handleClick} 
        color="primary" 
        id="fileShareMenu"
        className="btn"
      >
        <ShareIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="DASHBOARD.MENU.SHARE" />
      </Button>
      <StyledMenu
        id={idOfElement}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <FileEmailButton item={props.item} reactors={[anchorEl, setAnchorEl]} />
        </MenuItem>
        <MenuItem onClick={handleClose} className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <FileWhatsAppButton item={props.item} />
        </MenuItem>
      </StyledMenu>
    </div>
  )
}

export default React.memo(injectIntl(FileShareButton));