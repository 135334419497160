import React, { Component } from "react";
import { connect } from "react-redux";
import 'opentok-solutions-css';
import PresentationBar from '../PresentationBar';
import { FormattedMessage, injectIntl } from "react-intl";
import './VisitOfflineGrid.scss';
import PresentationLobby from '../PresentationLobby';
import Presentation from '../Presentation';
import CancelCall from '../VideoChat/CancelCall';
import ReloadCall from '../VideoChat/ReloadCall';
import Warning from '../VideoChat/Warning';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fullscreen from "react-full-screen";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import CallEnd from '@material-ui/icons/CallEnd';

import {
  isMobile, 
  isIPad13, 
  isIOS, 
  isIPhone13} from 'react-device-detect';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { version } from '../../../globalConfig';
import CallTime from '../CallTime';
import FabMenu from '../FabMenu';
import CallNotes from '../CallNotes';
import {containerClasses} from './containerClasses';
import {
  getDeploymentParamValue,
  getDeviceInfo,
  replaceTags} from './utils';
import FeedBackPopUp from '../FeedBackPopUp';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as MousePointer } from '../Presentation/assets/mousepointer.svg';
/***************************************** */
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import * as authStorage from "../../store/ducks/auth.duck";

import CustomerRequestDialog from '../CustomerRequestDialog';

import goBackHandler from "../../router/HistoryHandler";
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import PresentationBarGrid from "../PresentationBarGrid/PresentationBarGrid";
import { AppBar, Button, ListItemText, Toolbar } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";

let disablePublish = new URLSearchParams(window.location.search).get("disablePublish");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class VisitOfflineGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      stateButtonStartCall: 'buttonClickeable',
      disabledStartCallButton: false,
      sharingPresentation: false,
      getPresentationObject: null,
      imgContact: 'default.jpg',
      cancelCallDialog: false,
      reloadCallDialog: false,
      organizerNotConnected: false,
      logout: false,
      tryAgainButton: false,
      signOutClick: false,
      timeWarningTitle: '',
      timeWarningText: '',
      timeWarningButtonText: '',
      showTimeWarning: false,
      currentSubscribers: [],
      isClickable: false,
      showFeedbackModal: false,
      callEnded: false,
      isExpanded: true,
      isFull: false,
      openMobileFullScreenAlert: false,
      vaidActive: false,
      snackRequest: {
        open: false,
        severity: '',
        msg: ''
      },
      confirmGoBackOpen: false,
      isBlurEnabled: false
    };
    this.layout= null;

    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.changeCallStatus = this.changeCallStatus.bind(this);
    this.reloadCall = this.reloadCall.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.endCallWithoutRedirect = this.endCallWithoutRedirect.bind(this);
  
    this.toggleExpand = this.toggleExpand.bind(this);
    this.changeIsFull = this.changeIsFull.bind(this);
    this.onHideMovie = this.onHideMovie.bind(this);
    this.onConfirm_Close = this.onConfirm_Close.bind(this);
    this.onConfirm_GoBack = this.onConfirm_GoBack.bind(this);

  }

  componentDidMount() {
    let self = this;

    const { intl } = this.props;
    let tapedTwice = false;

    goBackHandler(function(value){
      if(!self.state.connected || !self.state.active){
        return;
      }

      if(self.props.auth.role === 'organizer'){
        self.changeCallStatus(true);
      }else{
        if(self.props.presentationBar.displayScreenPresentation){
          self.props.closePresentation({
            displayScreenPresentation: false,
            videoManager: null,
            code: self.props.presentationBar.presentationFocused.code,
            presentationFocused: {},
            focusedPresentationSlides: {},
            reason: 'manual',
            isPreview: false
          });
        }else{
          self.setState({ confirmGoBackOpen: value });
        }
      }
    });

    
    if (this.props.deployment.callData != null/* && this.props.deployment.callData.isError == false*/) {
      if(this.props.deployment.callData.servertimeUTC){ 
        let serverTimeUtc = moment.utc(this.props.deployment.callData.servertimeUTC);
        let browserTimeUtc = moment.utc();
        let formatedServerTimeUtc = serverTimeUtc.format("YYYY-MM-DD HH.mm.ss");
        let offset = browserTimeUtc.diff(serverTimeUtc,'seconds');
        let storageItem = undefined;
        let browserId;
        if (typeof(localStorage) !== "undefined") {  
          try{
            storageItem = localStorage.getItem('browserid');
          }catch(e){
            console.log(e)
          }
        }
        if(storageItem != undefined)
        {
          browserId = JSON.parse(storageItem);
        }else{
          browserId = Math.floor(Math.random() * 1000000000);
          localStorage.setItem('browserid', browserId);
        }

        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_LOBBY',
          log_text: 'servertimeUTC: ' + formatedServerTimeUtc + 
                ' | offset: ' + offset + ' | '  + getDeviceInfo() + 
                ' | browserid: ' + browserId + ' | version: '  + version
        })
      }
      
      this.state.getPresentationObject = this.props.getPresentationObject;
      let getPresentationObject = this.state.getPresentationObject;

     
      this.props.getPresentations(this.props.auth.callCode);
      this.props.getFileList({callCode: this.props.auth.callCode});
      
      this.setState({organizerNotConnected: false});

    } 
    else if (this.props.deployment.callData != null && this.props.deployment.callData.isError == true && (this.props.deployment.callData.OT_TOKEN_ID == null || this.props.deployment.callData.OT_TOKEN_ID == '')) 
    {
      this.callDataByError(intl);
    } 
    else 
    {
      let invalidTokenText = 'source: INVALID_CALL_DATA';
      this.props.log({
        logtype: 0,
        log_category: 'CALL_ERROR',
        log_text: invalidTokenText
      });
      this.props.flush();
      //trackException("exception event Invalid call data")
      this.setState({ reloadCallDialog: true, otErrMsg: 'Invalid call data' });
    }
  }



  showWarning(show){
    this.setState({showTimeWarning: show});
  }

  callDataByError(intl){
    let otErrMsg = '';
      switch (this.props.deployment.callData.errorCode) {
        case 'CALL_NOT_IN_RANGE':
          otErrMsg = intl.formatMessage({
            id: "CALL.NOT_IN_RANGE"
          });
          this.props.log({
            logtype: 2,
            log_category: 'CALL_NOT_IN_RANGE',
            log_text: otErrMsg
          });
          this.setState({ reloadCallDialog: true, otErrCode: 'noToken', otErrMsg: otErrMsg });
          break;
        case 'SUBSCRIBER_ALREADY_CONNECTED':
            otErrMsg = intl.formatMessage({
              id: "CALL.SUBSCRIBER_ALREADY_CONNECTED"
            });
            this.props.log({
              logtype: 2,
              log_category: 'SUBSCRIBER_ALREADY_CONNECTED',
              log_text: otErrMsg
            });
            this.setState({ reloadCallDialog: true, otErrCode: 'noToken', otErrMsg: otErrMsg });
            break;
        case 'CALL_CLOSED':
          otErrMsg = intl.formatMessage({
            id: "CALL.CLOSED"
          });
          this.customAlert(otErrMsg);
          this.setState({
            logout: true
          });
          break;
        default:
          break;
    }
  }

  lockOrientation(orientation) {
    try {
      var screen = window.screen;
      if(this.state.isFull && screen && screen.orientation && typeof screen.orientation.lock === 'function'){
        screen.orientation.lock(orientation);
      }
    } catch (error) {
      console.log(error);
    }
  }

  unlockOrientation() {
    try {
      var screen = window.screen;
      if(screen && screen.orientation && typeof screen.orientation.unlock === 'function'){
        screen.orientation.unlock();
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    //console.log('unmounting');
    Object.assign(this.props.presentationBar, { ...this.props.presentationBar, displayScreenPresentation: false, presentationFocused: {}, focusedPresentationSlides: {} });

    this.props.resetPresentationVars(this.props.presentationBar);
    this.props.resetChatVars();

    this.props.flush();
    this.props.flushChat();
  }

  startCall() {
    // llama a la api callsession 
    this.props.callSession();

    this.setState({
      stateButtonStartCall: 'buttonClickeable--busy kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light',
      disabledStartCallButton: true,
      connected: true,
      active: true
    });

    Object.assign(this.props.presentationBar, { callActive: true , showPresentationList: true});
    this.props.setCallActive(true);
    window.addEventListener("beforeunload", this.onUnload);
  
  }

  onUnload = e => {
    //TODO: Failed to execute 'send' on 'XMLHttpRequest' Synchronous XHR in page dismissal
    /*this.props.log({
      logtype: 3,
      log_category: 'WINDOW_QUIT',
      log_text: 'quit forced'
    })
    this.props.flush();*/
    const { intl } = this.props;
    e.preventDefault();
    return e.returnValue = intl.formatMessage({
      id: "CALL.PREVENT.DEFAULT"
    });
  };

  changeIsFull(isFull){
    this.setState({isFull: isFull});
  }

  changeCallStatus(open, cancelCall, signOutClick) {
    let tryAgainState = false;
    let valueNotes = '';
    this.props.setTimer(false);
    this.setState({callEnded: true});
    
    if (cancelCall == true) {
      try{
          valueNotes = localStorage.getItem("callnotes_" + this.props.global.auth.deployment.callData.code);
      }catch(e){
          console.log(e);
      }
      this.props.log({
        logtype: 3,
        log_category: 'CALL_CLOSED',
        log_text: 'CALL_CLOSED'
      })
      if(this.props.auth.role == 'organizer'){
        this.props.closeCall({ callcode: this.props.global.auth.deployment.callData.code, valueNotes: valueNotes });
      }
        //console.log('signOutClick', signOutClick);
      this.endCall(signOutClick)
        // this.setState({ logout: true });
    } else if (cancelCall == "backdropClick") {
        tryAgainState = true;
        open = false;
    }
    // si el subscriber tiene habilitado el feedback no finaliza la llamada todavía
    // le cedemos la funcion de finalizar la llamada al modal de feedback 
    let haveFeedbackEnabled = getDeploymentParamValue(this.props.deployment.parameters, 'CALL_FEEDBACK_ENABLE');

    if(haveFeedbackEnabled === '1' && this.props.auth.role == 'subscriber'){
        this.setState({showFeedbackModal: true})
    }
    
    this.setState({ cancelCallDialog: open, tryAgainButton: tryAgainState, signOutClick: signOutClick, isFull: false });
  }

  reloadCall(open, reload, autojoin, video, audio) {
    let tryAgainState = false;
    if (reload == true) {
      window.removeEventListener("beforeunload", this.onUnload);
      if (this.props.auth.urlToken && this.props.auth.urlToken != '') {
        if(autojoin){
          window.location.href = `/presentation/${this.props.auth.callCode}/${this.props.auth.userid}?autojoin=${autojoin}&localvideo=${video}&localaudio=${audio}&token=${this.props.auth.urlToken}`;
        }else{
          window.location.href = `/presentation/${this.props.auth.callCode}/${this.props.auth.userid}?token=${this.props.auth.urlToken}`;
        }
      }else{
        if(autojoin){
          window.location.href = `/presentation/${this.props.auth.callCode}/${this.props.auth.userid}?autojoin=${autojoin}&localvideo=${video}&localaudio=${audio}`;
        }else{
          window.location.href = `/presentation/${this.props.auth.callCode}/${this.props.auth.userid}`;
        }
      }
    } else {
      open = false;
      tryAgainState = true;
    }
    this.setState({ reloadCallDialog: open, disabledStartCallButton: false, stateButtonStartCall: 'buttonClickeable',tryAgainButton: tryAgainState });

  }

  endCall(signOutClick) {
    //console.log('entre a endcall comun')

    if (isMobile || isIPad13 || isIPhone13) {
      this.unlockOrientation();
    }
   
    this.setState({
      active: false,
      stateButtonStartCall: 'buttonClickeable',
      disabledStartCallButton: false,
      cancelCallDialog: false,
      callEnded: true
    });

    //this.props.presentationBar.displayScreenPresentation = false;
    this.props.setTimer(false);
    //console.log('estoy por activar callend')
    //this.layout();
      // si hay una presentacion activa
    if (this.props.presentationBar.presentationFocused.code) {
        
        // el middleware del redux se encarga de cerrar la presentacion, enviar la señal y loguear el evento
        let fnCallbackClosePresentation = () => {
          this.props.closePresentation({
            displayScreenPresentation: false,
            videoManager: this.props.presentationBar.videoManager,
            code: this.props.presentationBar.presentationFocused.code,
            presentationFocused: {},
            focusedPresentationSlides: {},
            reason: 'endcall'
          });
        }

        // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
        if(this.props.presentationBar.presentationFocused.type == 'sdva'){
          this.props.sendGotoSdvaSlide(
              {
                  type: 'SDVA',
                  command: 'HIDEFULLSCREENMOVIE',
                  payload: 'NA'
              }
          );

          setTimeout(() => {
              fnCallbackClosePresentation();
          }, 1200);
        }else{
            fnCallbackClosePresentation();
        }

        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'VISUALAID_END',
          log_text: `code: ${this.props.presentationBar.presentationFocused.code} | reason: endcall`
        })
    }
    
    
    // actualizo en el objeto global las variables que para cerrar la presentacion
    //TODO: usar las variables de globales para cosas de comunicacion, sino usar el estado interno
    Object.assign(this.props.presentationBar, { 
      ...this.props.presentationBar, 
      displayScreenPresentation: false, 
      presentationFocused: {}, 
      focusedPresentationSlides: {}, 
      callTime: false 
    });

    // se limpia el storage por posibles menues abiertos y demas 
    //TODO: refactorear esto porque le esta enviando todo el objeto y no esta centrado en su tarea en concreto si se cambio un estado en el medio también lo guarda
    this.props.resetPresentationVars(this.props.presentationBar);
    // se limpia los contenedores de texto del chat
    this.props.resetChatVars();

    Object.assign(this.props.presentationBar, { callActive: false });
    this.props.setCallActive(false);
    window.removeEventListener("beforeunload", this.onUnload);
    this.props.flush();
    this.props.flushChat();
    this.props.setSignOutClick(signOutClick);
    this.setState({signOutClick: signOutClick});
  }

  //TODO: codigo duplicado, se resuelve en el branch de refactorizacion
  endCallWithoutRedirect() {
    //console.log('entre a withoutredirect')
    let valueNotes = '';

    try{
        valueNotes = localStorage.getItem("callnotes_" + this.props.global.auth.deployment.callData.code);

        localStorage.removeItem('feedback_' + this.props.global.auth.deployment.callData.code);
    }catch(e){
      console.log(e);
    }
      
    this.props.closeCall({ callcode: this.props.global.auth.deployment.callData.code, valueNotes: valueNotes });
    this.props.log({
        logtype: 3,
        log_category: 'CALL_CLOSED',
        log_text: 'CALL_CLOSED'
    })
     
    this.setState({ cancelCallDialog: true, tryAgainButton: false});
    
  }

  goFullLandScape = () => {
    this.setState({ isFull: !this.state.isFull, openMobileFullScreenAlert: false });
    if(isMobile || isIPhone13 || isIPad13){
      setTimeout(() => {
        if(this.state.isFull)
          this.lockOrientation("landscape-primary");
      }, 1000);
    }
  }

  handleCloseMobileFullScreenAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openMobileFullScreenAlert: false });
  };

  resetSnackBarFile = () => {
    this.props.resetSnackFile();
  }
  
  resetSnackBarRequest = () => {
    this.setState({snackRequest:{ open: false, severity: '', msg: ''}});
  }

  toggleExpand = () => {
    this.setState({isExpanded: !this.state.isExpanded})
  }
  
  onConfirm_Close() {
    this.setState({ confirmGoBackOpen: false });
  }

  onConfirm_GoBack() {
    this.setState({ confirmGoBackOpen: false });
    
    this.changeCallStatus(true);
  }
  
  onHideMovie(){
    this.setState({
      isClickable: false
    });
  }

  render() {
    if (this.state.logout && !this.state.cancelCallDialog) {
      return <Redirect to="/logout" />
    } else {
      var { deployment, presentationBar, presentationSlides, getPresentationObject, auth, intl, global } = this.props;
      var btnClassStartCall = this.state.stateButtonStartCall;
      var disabledStartCallButton = this.state.disabledStartCallButton;

      const {
        connected,
        active,
        imgContact
      } = this.state;

      const deploymentStyle = {
        'accentColor': getDeploymentParamValue(deployment.parameters, "ACCENT_COLOR"),
      };

      const {
        localCallClass,
        controlClass,
        presentationClass,
        hiddenSharingPresentationClass,
        minHeightSdva
      } = containerClasses(this.state, presentationBar, presentationSlides, auth.role, auth.deployment, disablePublish);

      let notesIsEnabled = false;
      if(this.props.deployment.parameters){
        notesIsEnabled = (getDeploymentParamValue(deployment.parameters, "CALL_NOTES_ENABLE") === "1");
      }

      let lobbyLogoUrl = "";
      if(this.props.deployment.parameters){
        lobbyLogoUrl = getDeploymentParamValue(deployment.parameters, "LOBBY_LOGO_URL_PRESENTATION")
        if(!lobbyLogoUrl || lobbyLogoUrl == "" ){
          lobbyLogoUrl = getDeploymentParamValue(deployment.parameters, "LOBBY_LOGO_URL");
        }
      }
    
      let lobbyVideoOptions = {};
      let customInviteVideo = this.props.deployment.callData.CUSTOM_INVITE_VIDEO_URL;
     
      if(customInviteVideo || (lobbyLogoUrl && lobbyLogoUrl.length > 0 && lobbyLogoUrl.slice(-4) === ".mp4")) {
        lobbyVideoOptions = {
          fluid: true,
          fill: false,
          autoplay: false,
          controls: true,
          aspectRatio: "4:3",
          sources: [{
            src: customInviteVideo || lobbyLogoUrl
          }]
        }
      }
      
      let logoUrl = "";
      if(this.props.deployment.parameters){
        logoUrl = getDeploymentParamValue(deployment.parameters, "LOGO_URL");
      }

      let lobbyTitle = "";
      if(this.props.deployment.parameters){
        lobbyTitle = getDeploymentParamValue(deployment.parameters, "TERMS_TITLE");
      }

      let isAmPm = "";
      let lobbyBody = "";
      let shortUrl = false;
      
      if(this.props.deployment.parameters && this.props.deployment.callData){
          isAmPm = getDeploymentParamValue(deployment.parameters, "TIME_AMPM")
          lobbyBody = getDeploymentParamValue(deployment.parameters, "LOBBY_BODY_PRESENTATION");
          shortUrl = getDeploymentParamValue(deployment.parameters, "CALL_PRESENTATION_SHORT_URL") === "1";
          if(!lobbyBody || lobbyBody == "") {
            lobbyBody = getDeploymentParamValue(deployment.parameters, "LOBBY_BODY");
          }
          if(lobbyBody != ""){
            lobbyBody = replaceTags(lobbyBody,this.props.auth.callCode,this.props.auth.timezone, this.props.deployment.name,
              this.props.deployment.callData.duedateUTC, this.props.deployment.callData.organizerName,
              this.props.deployment.callData.subscriberName, this.props.deployment.callData.subscriberId, isAmPm, shortUrl);
          }
      }
      
      let publisherLogStats = false;
      if(this.props.deployment.parameters){
        publisherLogStats = (getDeploymentParamValue(deployment.parameters, "PUBLISHER_LOG_STATS") === "1");
      }

      const lobbyContainer = (
        <div className="row lobby-row">
          <div className="col-xl-12 d-flex justify-content-center lobby-container" style={{ marginBottom: 0 }}>
              <PresentationLobby
                callEnded={this.state.callEnded}
                connected={true}
                history={this.props.history}
                intl={this.props.intl}
                tryAgainButton={this.state.tryAgainButton} reloadCall={this.reloadCall} organizerNotConnected={this.state.organizerNotConnected}
                lobbyLogoUrl={lobbyLogoUrl}
                lobbyVideoOptions={lobbyVideoOptions}
                lobbyBody={lobbyBody}
                deployment={deployment} 
                timezone={this.props.auth.timezone} 
                role={this.props.auth.role} 
                callData={this.props.deployment.callData} 
                auth={this.props.auth} 
                log={this.props.log} 
                deploymentStyle={deploymentStyle} 
                start={this.startCall} 
                btnClassStartCall={btnClassStartCall} 
                disabledStartCallButton={(disabledStartCallButton || this.state.callEnded)} 
                imgContact={imgContact} 
                nullAuthToken={this.props.nullAuthToken}
                requestCallData={this.props.requestCallData}
                startWithAutoJoin={this.startWithAutoJoin}
                disablePublish={disablePublish}
              />
          </div>
        </div>
      );

      return (
        <>
        <Fullscreen
            enabled={this.state.isFull} 
            onChange={isFull => this.setState({ isFull })}
          >
          {/*<div id='logDevice' style={{position:'absolute', top: 150, left: 150, backgroundColor:'green', width:250, height:250,color:'white', fontSize:20, zIndex:2500}}></div>*/}
          {!this.props.presentationBar.displayScreenPresentation?
          <>
          <AppBar position="absolute" style={{background: deploymentStyle.accentColor}}>
            
            <Toolbar>
            
              <div id="controls" className={controlClass} style={{animationName: this.state.isExpanded ? 'fadeInUp' : 'fadeOutDown',animationDuration: '1.5s', animationDelay: 300, animationFillMode: 'forwards', zIndex: 9999 }}>
                
                  <div style={{marginTop: '10px', marginLeft: '10px'}}>
                 
                    <img  height={44} alt="Pic" src={logoUrl} />
                   
                  </div>
                  {!isMobile?
                  <div className="icons-with-text__group">
                     <span>&nbsp;&nbsp;&nbsp;</span>
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '1.2rem' } }} style={{marginLeft:10, marginTop: 10}} primary={intl.formatMessage({id: "PRESENTATION.BAR.SELECT"})}/>
                  </div> : null}
                    <Snackbar open={this.state.openMobileFullScreenAlert && !(lowerThanTablet && (isIOS || isIPhone13))} autoHideDuration={10000} onClose={this.handleCloseMobileFullScreenAlert}>
                      <Alert severity="info" style={{ backgroundColor: '#A9A9A9' }} onClick={this.goFullLandScape} onClose={this.handleCloseMobileFullScreenAlert}>
                        {intl.formatMessage({id: "CALL.FULLSCREEN.ALERT"})}
                      </Alert>
                    </Snackbar>
                    {auth.role === 'organizer' && notesIsEnabled &&
                    <CallNotes callcode={this.props.global.auth.deployment.callData.code} isFull={this.state.isFull}/>
                    }
                   <div class="kt-header-mobile__toolbar" style={{marginTop: '10px',marginRight: '10px',position: 'fixed', right: '0', display:'flex'}}>
                    <div id="fullScreenButton" style={{cursor:"pointer"}}>
                    {this.state.isFull ?
                    <Button
                      className="btn btn-sm btn-label-brand"
                      style={{ backgroundColor: 'transparent'}}
                      id="btnFullScreenExit"
                      onClick={() => this.setState({isFull: !this.state.isFull})}
                    >
                      <FullscreenExitIcon style={{ color: '#FFFFFF', height: 30, width: 30 }} onClick={() => this.setState({isFull: !this.state.isFull})} />
                      <span>&nbsp;Exit Fullscreen</span>
                     
                    </Button>
                      : 
                      <Button 
                      className="btn btn-sm btn-label-brand"
                      style={{backgroundColor: 'transparent'}}
                      id="btnFullScreen"
                      onClick={() => this.setState({isFull: !this.state.isFull})}
                    >
                      <FullscreenIcon style={{ color: '#FFFFFF', height: 30, width: 30}} onClick={() => this.setState({isFull: !this.state.isFull})} /> 
                     <span>&nbsp;Fullscreen</span>
                     
                    </Button>
                    }
                    </div>
                    <Button
                      className="btn btn-sm btn-label-brand"
                      style={{backgroundColor: '#b10349'}}
                      id="btnVideoCancelCallPopup"
                      onClick={() => this.changeCallStatus(false,true,true)}
                    >
                      <CallEnd />
                      <span>&nbsp;</span>
                    <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
                  </Button>
                  </div>
              </div>
            </Toolbar>
          </AppBar>
          {connected && active && this.state.isFull && lowerThanTablet?
          <PerfectScrollbar options={{
            wheelSpeed: 2,
            wheelPropagation: false
          }} style={{ maxHeight: "100vh", position: "relative" }}>
            <PresentationBarGrid deploymentStyle={deploymentStyle} changeIsFull={this.changeIsFull}/>
          </PerfectScrollbar>
          : connected && active ? 
            <PresentationBarGrid deploymentStyle={deploymentStyle} changeIsFull={this.changeIsFull}/>
          : null}
          </>
          :null}
          {this.props.presentationBar.customerRequestDialogOpen &&
          <CustomerRequestDialog />
          }
         
          

            {
              !active &&
                lobbyContainer 
            }

            <div id="callContainer" className="Call">
              <div className="App-main">
                <div className={this.props.presentationBar.displayScreenPresentation? `App-video-container ${minHeightSdva}`: 'App-container-grid'}>
                  <ReloadCall 
                    intl={intl} 
                    title={this.props.auth.callCode} 
                    errCode={this.state.otErrCode} 
                    errMsg={this.state.otErrMsg} 
                    open={this.state.reloadCallDialog} 
                    reloadCall={this.reloadCall} 
                    lang={this.props.auth.lang}
                  />
                  {this.props.auth.role == 'organizer' && <CancelCall open={this.state.cancelCallDialog} callStatus={this.changeCallStatus} endCall={this.endCall} signOutClick={this.state.signOutClick} callcode={this.props.global.auth.deployment.callData.code} notesIsEnabled={notesIsEnabled} endCallWithoutRedirect={this.endCallWithoutRedirect} />}
                  {this.props.auth.role == 'subscriber' && this.state.showFeedbackModal && <FeedBackPopUp setLogout={() => { this.setState({logout: true})} } />}
                  {connected && active && this.props.auth.role == 'organizer' && <Warning open={this.state.showTimeWarning} showWarning={this.showWarning} title={this.state.timeWarningTitle} text={this.state.timeWarningText}  buttonText={this.state.timeWarningButtonText}/>}

                 <Presentation showLabel={this.state.isExpanded} onHideMovie={this.onHideMovie}/>
                
                  <div className="timer__container">
                    
                    {connected && active && 
                      <CallTime publishers={this.state.publishers} publisherLogStats={publisherLogStats} isVisible={this.state.isExpanded} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Fullscreen>

          <ConfirmDialog
              open={this.state.confirmGoBackOpen}
              onClose={this.onConfirm_Close}
              handleAccept={this.onConfirm_GoBack}
              title={intl.formatMessage({id: "CONFIRM_DIALOG.GOBACK_CONFIRM.MESSAGE"})}
            />
        </>
      );
    }
  }


}

//const mapStateToProps = ({auth: deployment, PresentationBar}) => (deployment, PresentationBar);
const mapStateToProps = (state) => {
  return {
    global: state,
    deployment: state.auth.deployment,
    auth: state.auth,
    presentationBar: state.presentationBar,
    presentationSlides: state.presentationSlides,
    displayScreenPresentation: state.presentationBar.displayScreenPresentation,
    callClosedResult: state.presentationBar.callClosedResult
  }
}

export default injectIntl(
  connect(
    mapStateToProps,
    { ...presentationBarStorage.actions, ...loggerStorage.actions, ...authStorage.actions }
  )(VisitOfflineGrid)
);