import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getFolders,
  createFolder,
  inactivateFolder,
  updateFolder,
  getFiles,
  getPromotionalPlan,
  getFileTagsOptions
} from '../../crud/folders.crud';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';

export const actionTypes = {
  GET_FOLDERS: "GET_FOLDERS",
  FILL_FOLDERS: "FILL_FOLDERS",
  CREATE_FOLDER: "CREATE_FOLDER",
  FILL_RESULT_FOLDER: "FILL_RESULT_FOLDER",
  SET_SELECTED_FOLDER: "SET_SELECTED_FOLDER",
  INACTIVATE_FOLDER: "INACTIVATE_FOLDER",
  INACTIVATE_FOLDER_RESULT: "INACTIVATE_FOLDER_RESULT",
  UPDATE_FOLDER: "UPDATE_FOLDER",
  UPDATE_FOLDER_RESULT: "UPDATE_FOLDER_RESULT",
  SET_ERROR_VALIDATION: "SET_ERROR_VALIDATION",
  FULLFILL_FILES: "FULLFILL_FILES",
  GET_FILES: "GET_FILES",
  RESET_FOLDERS: "RESET_FOLDERS",
  SET_SELECTED_FILE: "SET_SELECTED_FILE",
  FULLFILL_PROMOTIONAL_PLANS: "FULLFILL_PROMOTIONAL_PLANS",
  FILL_PROMOTIONAL_PLAN: "FILL_PROMOTIONAL_PLAN",
  GET_FILE_TAGS_OPTIONS: "GET_FILE_TAGS_OPTIONS",
  FILL_FILE_TAGS_OPTIONS: "FILL_FILE_TAGS_OPTIONS",
  SET_ARR_TAGS: "SET_ARR_TAGS"
};

const initialState = {
  folders: [],
  folderResponse: {},
  loading: false,
  folderSelected: {},
  inactivateFolderResult: null,
  updateFolderResult: null,
  errorValidation: null,
  files: [],
  fileSelected: {},
  promotionalPlans: [],
  tagsOptions: []
}


export const reducer = persistReducer(
  { storage, key: "appState", whitelist: ["state"] },
  (state = initialState, action) => {
    switch (action.type) { 
      case actionTypes.FULLFILL_PROMOTIONAL_PLANS:
        return Object.assign({}, state,  { promotionalPlans: action.payload, loading: false } );
      case actionTypes.FILL_PROMOTIONAL_PLAN:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.FULLFILL_FILES: {
        return{
          ...state,
          files: action.payload,
          loading:false
        }
      }
      case actionTypes.GET_FILES: {
        return { 
          ...state,
          loading: true
        };
      }  
      case actionTypes.GET_FOLDERS: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_FOLDERS: {
        return { 
          ...state,
          loading: false,
          folders: action.payload
        };
      }
      case actionTypes.CREATE_FOLDER: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_RESULT_FOLDER: {
        return { 
          ...state,
          loading: false,
          redirection: (action.payload.redirection) ? action.payload.redirection : false,
          folderResponse: action.payload
        };
      }
      case actionTypes.SET_SELECTED_FOLDER: {
        return { 
          ...state,
          loading: false,
          folderSelected: action.payload
        };
      }
      case actionTypes.INACTIVATE_FOLDER: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.INACTIVATE_FOLDER_RESULT: {
        return { 
          ...state,
          loading: false,
          inactivateFolderResult: action.payload
        };
      }
      case actionTypes.UPDATE_FOLDER: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.UPDATE_FOLDER_RESULT: {
        return { 
          ...state,
          loading: false,
          updateFolderResult: action.payload
        };
      }
      case actionTypes.SET_ERROR_VALIDATION: {
        return { 
          ...state,  
          errorValidation: action.payload 
        };
      }
      case actionTypes.RESET_FOLDERS: {
        return {
          ...state,
          folders: []
        };
      }
      case actionTypes.SET_SELECTED_FILE: {
        return { 
          ...state,
          loading: false,
          fileSelected: action.payload
        };
      }
      case actionTypes.SET_ARR_TAGS: {
        return { 
          ...state,
          fileContainerResponse: { 
            ...state.fileContainerResponse, 
            tags: action.payload
          }
        };
      }
      case actionTypes.GET_FILE_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_FILE_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: false,
          tagsOptions: action.payload
        };
      }
      default:
        return state
    }
  }
);

export const actions = {
  getFolders: (value) => ({type: actionTypes.GET_FOLDERS, payload: value}),
  fillFolders: (value) => ({type: actionTypes.FILL_FOLDERS, payload: value}),
  createFolder: (value) => ({ type: actionTypes.CREATE_FOLDER, payload: value }),
  fillResultFolder: (value) => ({ type: actionTypes.FILL_RESULT_FOLDER, payload: value }),
  setSelectedFolder: (value) => ({ type: actionTypes.SET_SELECTED_FOLDER, payload: value }),
  inactivateFolder: (value) => ({ type: actionTypes.INACTIVATE_FOLDER, payload: value }),
  setInactivateFolderResult: (value) => ({ type: actionTypes.INACTIVATE_FOLDER_RESULT, payload: value }),
  updateFolder: (value) => ({ type: actionTypes.UPDATE_FOLDER, payload: value }),
  setErrorValidation: (value) => ({ type: actionTypes.SET_ERROR_VALIDATION, payload: value}),
  fulfillFiles: (value) => ({ type: actionTypes.FULLFILL_FILES, payload: value}),
  getFiles: (value) => ({ type: actionTypes.GET_FILES, payload: value}),
  resetFolders: (value) => ({ type: actionTypes.RESET_FOLDERS, payload: value}),
  setSelectedFile: (value) => ({ type: actionTypes.SET_SELECTED_FILE, payload: value }),
  fillPromotionalPlan: (value) => ({ type: actionTypes.FILL_PROMOTIONAL_PLAN, payload: value}),
  fulfillPromotionalPlan: (value) => ({ type: actionTypes.FULLFILL_PROMOTIONAL_PLANS, payload: value}),
  setArrTags: (value) => ({type: actionTypes.SET_ARR_TAGS, payload: value}),
  getFileTagsOptions: (value) => ({type: actionTypes.GET_FILE_TAGS_OPTIONS, payload: value}),
  fillFileTagsOptions: (value) => ({type: actionTypes.FILL_FILE_TAGS_OPTIONS, payload: value}),
}

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.GET_FOLDERS, function* getFoldersSaga({payload}) {
    try{
      const {data, status} = yield getFolders();
      if(status < 300){
        yield put(actions.fillFolders(data.list));
      }
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.CREATE_FOLDER, function* createFolderSaga({payload}) {
    try{
      const {data, status} = yield createFolder(payload);
      
      if(status < 300 && data.code){
        yield put(actions.fillResultFolder({...data, redirection: true}));
      }else{
        yield put(actions.fillResultFolder({redirection: false}));
      }
    }catch(e){
      yield put(actions.fillResultFolder({redirection: false}));
    }    
  });

  yield takeLatest(actionTypes.INACTIVATE_FOLDER, function* inactivateFolderSaga({payload}) {
    try{
      const {data, status} = yield inactivateFolder(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setInactivateFolderResult(true));
      }else{
        yield put(actions.setInactivateFolderResult(false));
      }
    }catch(e){
      yield put(actions.setInactivateFolderResult(false));
      
    }    
  });

  yield takeLatest(actionTypes.UPDATE_FOLDER, function* updateFolderSaga({payload}) {
    try{
      const {data, status} = yield updateFolder(payload);
      
      if(status < 300 && data.code){
        yield put(actions.fillResultFolder({...data, redirection: true}));
      }else{
        yield put(actions.fillResultFolder({redirection: false}));
      }
    }catch(e){
      yield put(actions.fillResultFolder({redirection: false}));
    }    
  });

  yield takeLatest(actionTypes.GET_FILES, function* getFilesSaga({ payload}) {
    try{
      const { data, status } = yield getFiles(payload.stateId,payload.code);
      if(status < 300){
        yield put(actions.fulfillFiles(data.list)); 
      }
    }catch(e){
      console.log(e);
    }
  });

  yield takeLatest(actionTypes.GET_FILE_TAGS_OPTIONS, function* getFileTagsOptionsSaga({payload}) {
    try{
      const {data, status} = yield getFileTagsOptions();
      if(status < 300){
        yield put(actions.fillFileTagsOptions(data.list));
      }
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.FILL_PROMOTIONAL_PLAN, function* fillPromotionalPlanSaga({ payload}) {
    const { data } = yield getPromotionalPlan(payload);
    yield put(actions.fulfillPromotionalPlan(data));
  });
}
