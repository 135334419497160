import React from 'react';
import { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import {
  getDeploymentParamValue,
  validateEmail
} from '../../../../_metronic/utils/utils';
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../../../partials/content/CustomDropdowns/HeaderDropdownToggle";
import clsx from "clsx";

import Autocomplete from '@material-ui/lab/Autocomplete';

import * as userStorage from "../users.duck";
import * as PromotionalPlansStorage from "../../../store/ducks/promotionalPlans.duck";

import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: '50%',
    maxWidth:'100%'
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rootTags: {
    minWidth: '50%',
    maxWidth: '100%',
    paddingLeft: 18,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeaderUser = (props) => {

//
  const globalState = useSelector(state => state);

  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [identification, setIdentification] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState(globalState.auth.lang);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [timezone, setTimezone] = useState(globalState.auth.timezone);
  const [attribute, setAttribute] = useState('');
  const [codProfile, setCodProfile] = useState('');
  const [profilesOptions, setProfilesOptions] = useState([]);
  const [allowGuest, setAllowGuest] = useState(false);
  const [userGroupDetail, setUserGroupDetail] = useState([]);

  useEffect(() => {
    props.fillNewUserObject({language:globalState.auth.lang});
    props.fillNewUserObject({timezone:globalState.auth.timezone});
    props.getUserGroups();
    let languages = getDeploymentParamValue(globalState.auth.deployment.parameters, 'LANGUAGES');
    if(languages){
      let languagesArr = languages.split(',').map((langPair) => {
        if(langPair){
          langPair = langPair.split(':');
          if(langPair.length === 2){
            return {key: langPair[0], value: langPair[1]}
          }
        }
        return [];
      })
      setLanguageOptions(languagesArr);
    }
    let profiles = getDeploymentParamValue(globalState.auth.deployment.parameters, 'PROFILES');
    if(profiles){
      let profilesArr = profiles.split(',').map((profilePair) => {
        if(profilePair){
          profilePair = profilePair.split(':');
          if(profilePair.length === 2){
            return {key: profilePair[0], value: profilePair[1]}
          }
        }
        return [];
      })
      setProfilesOptions(profilesArr);
      setCodProfile(profilesArr[0].key);
      props.fillNewUserObject({cod_Profile:profilesArr[0].key});
    }
    if(props.edit === true){
      props.getUser(props.uuid);
    }
  }, []);

  useEffect(() => {
    if(props.edit === true){
      if(
        globalState.users.resultGetUser === true && 
        globalState.users.newUserObject.groups &&
        globalState.users.newUserObject.groups.length > 0 && 
        globalState.promotionalPlans.userGroupsResult === true
      ){
        if(globalState.promotionalPlans.userGroups.length > 0){
          let userGroupsArr = [];
          globalState.users.newUserObject.groups.forEach((userGroup) => {
            userGroupsArr.push(globalState.promotionalPlans.userGroups.filter((item) => {
              return item.externalCode.toLowerCase().trim() === userGroup.toLowerCase().trim()
            })[0])
          })
          handleChangeUserGroupDetail(userGroupsArr);
        }
      }

      if(globalState.users.newUserObject.name){
        setName(globalState.users.newUserObject.name);
      }
      if(globalState.users.newUserObject.email){
        setEmail(globalState.users.newUserObject.email);
      }
      if(globalState.users.newUserObject.identification){
        setIdentification(globalState.users.newUserObject.identification);
      }
      if(globalState.users.newUserObject.externalCode){
        setExternalCode(globalState.users.newUserObject.externalCode);
      }
      if(globalState.users.newUserObject.timezone || globalState.users.newUserObject.timezone == 0){
        setTimezone(globalState.users.newUserObject.timezone);
      }
      if(globalState.users.newUserObject.allow_Guest){
        setAllowGuest(globalState.users.newUserObject.allow_Guest);
      }
      if(globalState.users.newUserObject.language){
        setLanguage(globalState.users.newUserObject.language);
      }
      if(globalState.users.newUserObject.atribute_1){
        setAttribute(globalState.users.newUserObject.atribute_1);
      }
      if(globalState.users.newUserObject.cod_Profile){
        setCodProfile(globalState.users.newUserObject.cod_Profile);
      }
    }
  }, [
    globalState.promotionalPlans.userGroupsResult,
    globalState.users.resultGetUser
  ]);

  const handleChangeName = (event) => {
    setName(event.target.value);
    props.fillNewUserObject({name:event.target.value});
    //props.fillNewPromotionalPlanObject({name:event.target.value});
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    props.fillNewUserObject({email:event.target.value});
  }

  const handleChangeIdentification = (event) => {
    setIdentification(event.target.value);
    props.fillNewUserObject({identification:event.target.value});
  }

  const handleChangeExternalCode = (event) => {
    setExternalCode(event.target.value);
    props.fillNewUserObject({externalCode:event.target.value});
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    props.fillNewUserObject({password:event.target.value});
  }

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    props.fillNewUserObject({language:event.target.value});
  }

  const handleChangeTimeZone = (event) => {
    if(event.target.value >= -14 && event.target.value <= 14){
      setTimezone(event.target.value);
      props.fillNewUserObject({timezone:event.target.value});
    }
  }

  const handleChangeAttribute = (event) => {
    setAttribute(event.target.value);
    props.fillNewUserObject({atribute_1:event.target.value});
  }

  const handleChangeCodProfile = (event) => {
    setCodProfile(event.target.value);
    props.fillNewUserObject({cod_Profile:event.target.value});
  }

  const handleChangeAllowGuest = (event) => {
    setAllowGuest(event.target.checked);
    props.fillNewUserObject({allow_Guest:Number(event.target.checked)});
  }

  const handleChangeUserGroupDetail = (event, values) => {
    let val = (Array.isArray(event)) ? event : values;
    setUserGroupDetail(val);
    props.fillNewUserObject({groups:val});
  }

  let labelPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.PRESENTATION"
  });

  let placeHolderPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.PRESENTATION"
  });
  
  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.NAME" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.promotionalPlans.errorValidation === 'name' && !name}
            className={classes.inputText}
            autoComplete="false"
            inputProps={{ 
              maxLength: 500,
              form: {
                autocomplete: 'off',
              }
            }}
            value={name}
            onChange={handleChangeName}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.EMAIL" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.users.errorValidation === 'email' || !email}
            className={classes.inputText}
            autoComplete="false"
            inputProps={{ 
              maxLength: 320,
              form: {
                autocomplete: 'off'
              }
            }}
            value={email}
            onChange={handleChangeEmail}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.IDENTIFICATION" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.users.errorValidation === 'identification' && !identification}
            className={classes.inputText}
            autoComplete="false"
            inputProps={{ 
              maxLength: 100,
              form: {
                autocomplete: 'off'
              }
            }}
            value={identification}
            onChange={handleChangeIdentification}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.EXTERNAL_CODE" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.users.errorValidation === 'externalCode' && !externalCode}
            className={classes.inputText}
            autoComplete="false"
            inputProps={{ 
              maxLength: 50,
              form: {
                autocomplete: 'off'
              }
            }}
            value={externalCode}
            onChange={handleChangeExternalCode}
          />
        </span>
      </div>
      {!props.edit &&
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.PASSWORD" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.users.errorValidation === 'password' && !password}
            className={classes.inputText}
            autoComplete="false"
            value={password}
            type="password"
            inputProps={{
              form: {
                autocomplete: 'off'
              },
            }}
            onChange={handleChangePassword}
            disabled={(props.edit === true)}
          />
        </span>
      </div>
      }
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.LANGUAGE" />: </span>
        <span className={classes.data}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label"></InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={language}
            onChange={handleChangeLanguage}
          >
            {languageOptions.map((language, index) => {
              return (<MenuItem key={index} value={language.key}>{language.value}</MenuItem>);
            })}
          </Select>
        </FormControl>
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.TIME_ZONE" />: </span>
        <span className={classes.data}>
          <TextField
            required
            type="number"
            error={globalState.users.errorValidation === 'timezone' && !timezone}
            className={classes.inputText}
            autoComplete="false"
            InputProps={{ inputProps: { min: -14, max: 14 } }}
            value={timezone}
            onChange={handleChangeTimeZone}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.ATTRIBUTE" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.users.errorValidation === 'attribute' && !attribute}
            className={classes.inputText}
            autoComplete="false"
            inputProps={{ maxLength: 100}}
            value={attribute}
            onChange={handleChangeAttribute}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.COD_PROFILE" />: </span>
        <span className={classes.data}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label"></InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={codProfile}
            onChange={handleChangeCodProfile}
          >
            {profilesOptions.map((profile, index) => {
              return (<MenuItem key={index} value={profile.key}>{profile.value}</MenuItem>);
            })}
          </Select>
        </FormControl>
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.ALLOW_GUEST" />: </span>
        <span className={classes.data}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={allowGuest}
            onChange={handleChangeAllowGuest}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="USER.EDIT.FORM.GROUP_DETAIL" />: </span>
        <span className={classes.data}>
          <Autocomplete
            multiple
            id="tags-standard-user-groups"
            options={globalState.promotionalPlans.userGroups} //este valor sale de promotionalplans objects a proposito
            onChange={handleChangeUserGroupDetail}
            value={userGroupDetail}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelPresentations}
                placeholder={placeHolderPresentations}
              />
            )}
          />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    {
      ...userStorage.actions,
      ...PromotionalPlansStorage.actions
    }
  )(HeaderUser)
);