import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  getCustomerGroupsByStateId,
  createCustomerGroup,
  getCustomerGroupAtribute,
  assignByAtributeCus
} from "../../crud/customerGroups.crud";

export const actionTypes = {
  GET_CUSTOMERGROUPS: "GET_CUSTOMERGROUPS",
  SET_CUSTOMERGROUPS_RESULT: "SET_CUSTOMERGROUPS_RESULT",
  SET_STATE_ID: "SET_STATE_ID",
  UPDATE_CUSTOMERGROUP_SELECTED: "UPDATE_CUSTOMERGROUP_SELECTED",
  SET_OPEN_CALL_DETAIL: "SET_OPEN_CALL_DETAIL",
  CREATE_CUSTOMERGROUP: "CREATE_CUSTOMERGROUP",
  FILL_CUSTOMERGROUP_RESULT: "FILL_CUSTOMERGROUP_RESULT",
  CREATE_CUSTOMERGROUP_RESULT: "CREATE_CUSTOMERGROUP_RESULT",
  GET_CUSTOMERGROUP_ATRIBUTE: "GET_CUSTOMERGROUP_ATRIBUTE",
  FILL_CUSTOMERGROUP_ATRIBUTE: "FILL_CUSTOMERGROUP_ATRIBUTE",
  ASSIGN_BY_ATRIBUTE_CUS: "ASSIGN_BY_ATRIBUTE_CUS",
  ASSIGN_BY_ATRIBUTE_CUS_RESULT: "ASSIGN_BY_ATRIBUTE_CUS_RESULT"
};

const initialCustomerGroupsState = {
  loading: false,
  customerGroups: [],
  stateId: 1, // state_id=1
  customerGroupSelected: {},
  createCustomerGroupResponse: {},
  createCustomerGroupResult: null,
  assignByAtributeCusResult: null,
  customerGroupAtributeList: [],
  openCallDetail: false
};

export const reducer = persistReducer(
    { storage, key: "demo1-customerGroups", whitelist: [""] },
    (state = initialCustomerGroupsState, action) => {
      switch (action.type) {
        case actionTypes.CREATE_CUSTOMERGROUP_RESULT: {
          return {
            ...state,
            loading: false,
            createCustomerGroupResult: action.payload
          };
        }
        case actionTypes.CREATE_CUSTOMERGROUP: {
          return {
            ...state,
            loading: true
          };
        }
        case actionTypes.FILL_CUSTOMERGROUP_RESULT: {
          return {
            ...state,
            loading: false,
            createCustomerGroupResponse: action.payload
          };
        }
        case actionTypes.GET_CUSTOMERGROUPS: {
          return { ...state, loading: true, assignByAtributeCusResult: null };
        }
        case actionTypes.SET_CUSTOMERGROUPS_RESULT: {
          return { 
            ...state, 
            loading: false,
            customerGroups: action.payload
          };
        }
        case actionTypes.GET_CUSTOMERGROUP_ATRIBUTE: {
          return { ...state, loading: true };
        }
        case actionTypes.FILL_CUSTOMERGROUP_ATRIBUTE: {
          return {
            ...state,
            loading: false,
            customerGroupAtributeList: action.payload
          };
        }
        case actionTypes.ASSIGN_BY_ATRIBUTE_CUS: {
          return {
            ...state,
            loading: true
          };
        }
        case actionTypes.ASSIGN_BY_ATRIBUTE_CUS_RESULT: {
          return {
            ...state,
            loading: false,
            assignByAtributeCusResult: action.payload
          };
        }
        case actionTypes.SET_STATE_ID: {
          return { 
            ...state,
            stateId: action.payload
          };
        }
       
        case actionTypes.UPDATE_CUSTOMERGROUP_SELECTED: {
          return { 
            ...state,
            loading: false,
            customerGroupSelected: action.payload
          };
        }
       
        case actionTypes.SET_OPEN_CALL_DETAIL: {
          return  {
             ...state,
             openCallDetail: action.payload,
             loading: true 
          };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  getCustomerGroups: () => ({ type: actionTypes.GET_CUSTOMERGROUPS, payload: null }),
  setCustomerGroupsResult: (value) => ({ type: actionTypes.SET_CUSTOMERGROUPS_RESULT, payload: value }),
  getCustomerGroupAtribute: () => ({ type: actionTypes.GET_CUSTOMERGROUP_ATRIBUTE, payload: null }),
  fillCustomerGroupsAtribute: (value) => ({ type: actionTypes.FILL_CUSTOMERGROUP_ATRIBUTE, payload: value }),
  assignByAtributeCus: (value) => ({ type: actionTypes.ASSIGN_BY_ATRIBUTE_CUS, payload: value }),
  setAssignByAtributeCusResult: (value) => ({ type: actionTypes.ASSIGN_BY_ATRIBUTE_CUS_RESULT, payload: value }),
  setStateId: (value) => ({ type: actionTypes.SET_STATE_ID, payload: value }),
  updateCustomerGroupSelected: (value) => ({ type: actionTypes.UPDATE_CUSTOMERGROUP_SELECTED, payload: value }),
  setOpenCallDetail: (value) => ({ type: actionTypes.SET_OPEN_CALL_DETAIL, payload: value }),
  createCustomerGroup: (value) => ({ type: actionTypes.CREATE_CUSTOMERGROUP, payload: value }),
  fillCustomerGroupResult: (value) => ({ type: actionTypes.FILL_CUSTOMERGROUP_RESULT, payload: value }),
  createCustomerGroupResult: (value) => ({ type: actionTypes.CREATE_CUSTOMERGROUP_RESULT, payload: value }),
};

export const getState = (state) => state;

export function* saga() {
  
  yield takeLatest(actionTypes.ASSIGN_BY_ATRIBUTE_CUS, function* assignByAtributeCusSaga({ payload }) {
    try {
      const { data, status } = yield assignByAtributeCus(payload);

      if (status < 300 && data === 'OK') {
        yield put(actions.setAssignByAtributeCusResult(true));
      } else {
        yield put(actions.setAssignByAtributeCusResult(false));
      }
    } catch (e) {
      yield put(actions.setAssignByAtributeCusResult(false));
    }
  });

  yield takeLatest(actionTypes.GET_CUSTOMERGROUPS, function* getCustomerGroupsSaga({}) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getCustomerGroupsByStateId(selector.customerGroups.stateId);
      
      if(status < 300){
        yield put(actions.setCustomerGroupsResult(data.list));
      }else{
        yield put(actions.setCustomerGroupsResult([]));
      }
    }catch(e){
      yield put(actions.setCustomerGroupsResult([]));
    }
    
  });

  yield takeLatest(actionTypes.GET_CUSTOMERGROUP_ATRIBUTE, function* getCustomerGroupAtributeSaga({ }) {
    const selector = yield select(getState);
    try {
      const { data, status } = yield getCustomerGroupAtribute();

      if (status < 300) {
        yield put(actions.fillCustomerGroupsAtribute(data.list));
      } else {
        yield put(actions.fillCustomerGroupsAtribute([]));
      }
    } catch (e) {
      yield put(actions.fillCustomerGroupsAtribute([]));
      //console.log(e)
    }

  });

  yield takeLatest(actionTypes.CREATE_CUSTOMERGROUP, function* createCustomerGroupSaga({ payload }) {
    try {
      const { data, status } = yield createCustomerGroup(payload);

      if (status < 300 && data) {
        yield put(actions.fillCustomerGroupResult({ ...data }));
        yield put(actions.createCustomerGroupResult(true));
      } else {
        yield put(actions.createCustomerGroupResult(false));
      }
    } catch (e) {
      yield put(actions.createCustomerGroupResult(false));
    }
  });

}

