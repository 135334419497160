import React, { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '../../components/VisualAids/UploadIcon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HeaderPromotionalPlans from '../../components/PromotionalPlans/HeaderPromotionalPlans';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import HeaderImportCustomer from '../../components/ImportCustomers/HeaderImportCustomer';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import { BlobServiceClient } from "@azure/storage-blob";

import * as importCustomersStorage from "../../store/ducks/importCustomers.duck";

const ImportCustomersCreatePage = (props) => {
  const {intl} = props;
  const globalState = useSelector(state => state);
  const history = useHistory();
  let params = useParams();

  const [name, setName] = useState('');
  const [size, setSize] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState(-1);
  //const [uploadResult, setUploadResult] = React.useState(-1);

  const backToimportCustomerList = () => {
    let messageAlert = intl.formatMessage({
      id: "IMPORT_CUSTOMERS.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      history.push("/importlist");
    }
  }

  const validate = () => {
    if(!file){
      return false;
    }
    return true;
  }

  const saveImportCustomers = () => {
    //validar
    if (validate()){
      props.saveObject({
        cod_Entity: globalState.presentationBar.customerTypeSelected.code, 
        original_Name: name, 
        size:size
      })
    }
  }

  const uploadFileProgress = (progress) => {
    let fileSize = file.size;
    let percentaje = Math.round(progress.loadedBytes * 100 / fileSize);
    if(percentaje === 100){
      setUploadProgress(-1);
    }else{
      setUploadProgress(percentaje);
    }
  }

  useEffect(() => {
    if(globalState.importCustomers.resultUpload !== null){
      props.uploadToBlobComplete();
      history.push("/importlist");
    }
  }, [globalState.importCustomers.resultUpload])

  useEffect(() => {
    if(globalState.importCustomers.createResponse !== null &&
      globalState.importCustomers.createResponse.upload_Url){
        let fileUrl = globalState.importCustomers.createResponse.upload_Url.replace('{FileName}', '');

        const blobServiceClient = new BlobServiceClient(fileUrl);
        const containerClient = blobServiceClient.getContainerClient('');
        const blobName = globalState.importCustomers.createResponse.code + '.csv';
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const options = {blobContentType:'application/vnd.ms-excel'}
        const UploadFileToBlob = async () => {
          let result = await blockBlobClient.upload(file, file.size, {onProgress: uploadFileProgress, blobHTTPHeaders: options});
          setUploadProgress(-1)
          props.setResultUploadFileToBlob(result);
        }
        UploadFileToBlob();
    }

  }, [globalState.importCustomers.createResponse]);
  
  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {uploadProgress > 0 && <CircularProgressWithLabel size={68} value={uploadProgress} />}
        {globalState.importCustomers.loading && <LinearProgress className="progressBar" />}
        {globalState.importCustomers.loading && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="IMPORT_CUSTOMERS.TITLE" /></h3>
          </div>
          <div>
          <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backVisualAid" onClick={backToimportCustomerList}>
            <ArrowBackIcon />
            <FormattedMessage id="IMPORT_CUSTOMERS.BACK_BUTTON" />
          </Button>
          <Button size="medium" className="btn btn-sm btn-label-success" id="saveImportCustomers" onClick={saveImportCustomers} disabled={!file}>
            <SaveIcon />
            <FormattedMessage id="IMPORT_CUSTOMERS.EDIT.PAGE.SAVE_BUTTON" />
          </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>
              <div>
                <HeaderImportCustomer edit={props.edit} uuid={params.uuid} setName={setName} setSize={setSize} setFile={setFile} />
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    importCustomersStorage.actions
  )(ImportCustomersCreatePage)
);