import React, {useEffect} from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { pt, es, en, fr, enUS } from 'date-fns/locale';
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import './ScheduleModifierPopUp.scss';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import { bool } from 'prop-types';
import MomentUtils from "@date-io/moment";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ScheduleModifierPopUp = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);
  //console.log(props.intl.locale)
  //console.log(eval(props.intl.locale))
  //debugger;
  const [selectedDate, setSelectedDate] = React.useState(moment(props.item.duedateUTC).add(globalState.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm'));
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  let locale = pt;
  let formatDatePicker = 'DD/MM/YYYY hh:mm ';
   
  let isAmPm = getDeploymentParamValue(globalState.auth.deployment.parameters, 'TIME_AMPM');
  
  if (globalState && globalState.auth.lang) {
    switch (globalState.auth.lang) {
      case "es":
        locale = es;
        break;
      case "en":
        locale = enUS;
        formatDatePicker = 'YYYY/MM/DD hh:mm ';
        break;
      default:
        break;
    }
  }
  formatDatePicker += (isAmPm == 1) ? 'a' : '';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.reactors[1](null)
  };

  const classes = useStyles();

  useEffect(() => {
    if(globalState.schedule.callcode && globalState.schedule.callcode === props.item.code){
      let msg = ''
      let backgroundColor = 'green';
      if (globalState.schedule.requestStateModify != null) {
        if (globalState.schedule.requestStateModify === true) {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.MODIFY.OK"
          });
  
        } else {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.MODIFY.ERROR"
          });
          backgroundColor = 'red';
        }
        if (msg != ''){
          showPopupModifyCall(msg, backgroundColor);
        }
        props.setRequestStateModify({state: null, callcode: null});
        props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo})
      }
    }
  }, [globalState.schedule.requestStateModify])

  const showPopupModifyCall = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #avisoModifyCall {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "avisoModifyCall");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);
    
    window.load = setTimeout("if(typeof(avisoModifyCall) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(avisoModifyCall)){for (var i = 0; i < avisoModifyCall.length; i++) {document.body.removeChild(avisoModifyCall[i])}}else{document.body.removeChild(avisoModifyCall)}}", 5000);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  }

  const handleAccept = () =>{
    setDisabled(true);
    props.modifyDateOfCall({dueDateUTC: selectedDate, callcode: props.item.code, guestid: props.item.guestId? props.item.guestId: ""});
  }
  let style={marginLeft:1, width: '100%'};

  let dateTimeInputText = intl.formatMessage({
    id: "DASHBOARD.MODIFY.VISIT.DIALOG.ACTION.INPUT"
  });

  return(
    <div style={{width: '100%'}} id="form-dialog-title-schedule-modifier">
      {props.popup ?
      <Button className="btn btn-sm btn-label-success" id="btnNewVisitOpenPopUp" onClick={handleOpen} style={style}>
        <span>&nbsp;</span>
        <FormattedMessage id="DASHBOARD.EDIT.CALL.SCHEDULE" />
      </Button>
      :
      <div id="btnNewVisitOpenPopUp" onClick={handleOpen}>
        <span>&nbsp;</span>
        <FormattedMessage id="DASHBOARD.EDIT.CALL.SCHEDULE" />
      </div>
      }
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><FormattedMessage id="DASHBOARD.EDIT.CALL.SCHEDULE" /></DialogTitle>
        <DialogContent className="schedule__modifier__popup__content">
          <MuiPickersUtilsProvider libInstance={moment} locale={globalState.auth.lang} utils={MomentUtils}>
            <Grid container justify="space-around" alignItems="flex-end">
              <KeyboardDateTimePicker
                variant="inline"
                margin="normal"
                id="mui-pickers-date-from"
                label={dateTimeInputText}
                format={formatDatePicker}
                ampm={Boolean(isAmPm == 1)}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                cancelLabel={intl.formatMessage({
                  id: "SCHEDULE.EDIT.DATE_RANGE.CLOSE"
                })}
              />
              </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnModifyCall" onClick={handleAccept} disabled={disabled}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.APPLY" />
          </Button>
          <Button className={classes.button} id="btnModifyCallClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(ScheduleModifierPopUp)
);