import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import ItemOfCalls from './ItemOfCalls';

const ListOfCallsContainer = (props) => {
  const [containerState, setContainerState] = React.useState([])
  const globalState = useSelector(state => state);

  React.useEffect(() => {
    let container = [];
    for (let byDay in globalState.presentationBar.calls) {
      let children = [];
      globalState.presentationBar.calls[byDay].forEach((item, index) => {
        children.push(<ItemOfCalls item={item} key={index} />)
      });
      container.push(<div className="kt-widget4__item__date-group list__date" key={byDay}> <p><b>{byDay.charAt(0).toUpperCase() + byDay.substring(1)}</b></p> {children} </div>);
    }
    
    setContainerState(container)
    props.setLoading(false);
  }, [globalState.presentationBar.calls]) //eslint-disable-line
  
  return containerState;
}

export default injectIntl(
  connect(
    null,
    null
  )(ListOfCallsContainer)
);