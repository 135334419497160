import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import CustomerTypeSelector from '../../components/CustomerTypeSelector';
import CustomerList from '../../components/CustomerList';
import { FormattedMessage, injectIntl } from "react-intl";

const Customers = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-6 relative">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="CUSTOMER.TITLE" /></h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex">
            <div>
              <CustomerTypeSelector />
            </div>
          </div>
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <CustomerList />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(Customers);