import React,{ useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Countdown from 'react-countdown';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic";
import KTUtil from "../../../_metronic/_assets/js/util";
//import moment from 'moment';
import {
  browserName,
  isChrome,
  isFirefox
} from 'react-device-detect';
import {lowerThanTablet} from '../../../_metronic/utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));


const ReloadCall = ({
  intl, 
  title, 
  errCode, 
  errMsg, 
  open, 
  reloadCall,
  lang
}) => {
  const classes = useStyles();
  const tick = ({seconds}) => {
    if(seconds == 0){
      reloadCall(false,true);
    }
  }

  const Completionist = () => <span>Recargando...</span>;

  const renderer = ({  seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <span>{intl.formatMessage({ id: "CALL.RELOAD.RETRY.TEXT" }, {seconds:seconds})}</span>;
    }
  };

  const DialogContentComponent = ({
    errCode
  }) => {
    
    let browser = '';

    if(isChrome && (lowerThanTablet || KTUtil.isInResponsiveRange("tablet"))){
      browser = 'mobilechrome';
    }else{
      browser = browserName.toLowerCase().replace(' ', '');
    }

    browser = browser.toLowerCase();
    
    if(
      browser != 'safari' && 
      browser != 'mobilesafari' && 
      browser != 'firefox' &&
      browser != 'chrome' && 
      browser != 'mobilechrome'
    ){
      browser = 'chrome';
    }

    const idOfMsg1 = `ACCESS_DENIED_${browser.toUpperCase()}_MSG_1`;
    const idOfMsg2 = `ACCESS_DENIED_${browser.toUpperCase()}_MSG_2`;
    const urlImg1 = toAbsoluteUrl(`/media/error/camera/${browser.toLowerCase()}/${browser.toLowerCase()}_${lang.toLowerCase()}_1.png`);
    const urlImg2 = toAbsoluteUrl(`/media/error/camera/${browser.toLowerCase()}/${browser.toLowerCase()}_${lang.toLowerCase()}_2.png`);
    switch (errCode) {
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        return(
          <>
          <DialogContentText id="alert-dialog-description">
            <FormattedHTMLMessage id="OT_USER_MEDIA_ACCESS_DENIED" />
            <br />
            <br />
            <FormattedHTMLMessage id={idOfMsg1} />
            <div style={{maxWidth : 450}}>
              <img src={urlImg1} style={{maxWidth: '100%'}}/>
            </div>
            <br />
            <br />
            <FormattedHTMLMessage id={idOfMsg2} />
            {!isFirefox &&
            <div style={{maxWidth : 450}}>
              <img src={urlImg2} style={{maxWidth: '100%'}}/>
            </div>
            }
            <br />
            <br />
          </DialogContentText>
          <div className={classes.root}>
            <CircularProgress /><span>&nbsp;</span>
            
            <Countdown
              date={Date.now() + 60000}
              intervalDelay={0}
              precision={3}
              renderer={renderer}
              controlled={false}
              onTick={tick}
            />
          </div>
          </>
        )
      default:
        return(
          <>
          <DialogContentText id="alert-dialog-description">
            {errCode == 'sessionDisconnected' ?
              <FormattedMessage id="CALL.RECONNECT.DESC" />
            : errCode == 'noToken' ?
              <FormattedMessage id="CALL.NOTOKEN.DESC" />                  
            :
              <FormattedMessage id="CALL.RELOAD.DESC" />
            }
          </DialogContentText>
          <DialogContentText id="alert-dialog-errCode">
            {errCode != 'noToken' ? errCode:''}
          </DialogContentText>
          <DialogContentText id="alert-dialog-errMsg">
            {errMsg}
          </DialogContentText>
          <div className={classes.root}>
            <CircularProgress /><span>&nbsp;</span>
            
            <Countdown
              date={Date.now() + 15000}
              intervalDelay={0}
              precision={3}
              renderer={renderer}
              controlled={false}
              onTick={tick}
            />
          </div>
          </>
        )
    }
  }

  return (
      <div>
        <Dialog
          open={open}
          onClose={reloadCall}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentComponent errCode={errCode}/>
          </DialogContent> 
          <DialogActions>
            <Button id="btnReconnectCall" onClick={() => reloadCall(false,true)} color="primary">
              <FormattedMessage id="CALL.RELOAD.ACCEPT.MODAL" />
            </Button>
            <Button id="btnCancelCallIgnore" onClick={() => reloadCall(false,false)} color="primary" autoFocus>
                <FormattedMessage id="CALL.RELOAD.CANCEL.MODAL" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
    
export default ReloadCall;