import React from 'react';
import { connect, useSelector } from "react-redux";
import {useStyles} from './styles';
import FileShareButton from './FileShareButton';
import DownloadButton from './DownloadButton';
import FilesViewInContainer from './FilesViewInContainer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MovieIcon from '@material-ui/icons/Movie';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';

import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

const FileItem = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  const [visibility, setVisibility] = React.useState(false);
  const [openFilesViewInContainer, setOpenFilesViewInContainer] = React.useState(false);
  let delayFlag = false;
  let FileIcon = null;
  switch(props.item.mimeType){
    case 'application/pdf':
      FileIcon = () => (<PictureAsPdfIcon />)
      break;
    case 'video/mp4':
      FileIcon = () => (<MovieIcon />)
      break;
    case 'image/jpeg':
    case 'image/png':
      FileIcon = () => (<ImageIcon />)
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      FileIcon = () => (<ViewCarouselIcon />)
      break;
    default:
      FileIcon = () => (<AttachFileIcon />)
  }

  const StateEyeIcon = () => {
    if(visibility){
      return(<VisibilityIcon />)
    }else{
      return(<VisibilityOffIcon />)
    }
  };

  /*
  const handleVisibility = () => {
    if(visibility){
      setVisibility(false);
      props.removeFileFromList(props.item);
      props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'FILE_SHARED',
        log_text: JSON.stringify({ 
          callcode: globalState.auth.callCode, 
          userId: globalState.auth.userId,
          subscriberId: globalState.auth.deployment.callData.subscriberId, 
          filecode: props.item.code,
          type: 'visibility_false'
        })
      })
    }else{
      setVisibility(true);
      props.addFileToList(props.item);
      props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'FILE_SHARED',
        log_text: JSON.stringify({ 
          callcode: globalState.auth.callCode, 
          userId: globalState.auth.userId,
          subscriberId: globalState.auth.deployment.callData.subscriberId, 
          filecode: props.item.code,
          type: 'visibility_true'
        })
      })
    }
    if(!delayFlag){
      delayFlag = true;
      window.intervalFile = window.setTimeout(() => {
        props.sendSignalFile();
        props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'FILE_SHARED',
          log_text: JSON.stringify({ 
            callcode: globalState.auth.callCode, 
            userId: globalState.auth.userId,
            subscriberId: globalState.auth.deployment.callData.subscriberId, 
            type: 'signal'
          })
        })
        delayFlag = false;
      }, 5000); //TODO: verificar si metemos una variable de entorno aca
    }
  }
  */

  const handleDownloadClick = () => {
    const filesViewInContainerParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_VIEW_IN_CONTAINER');
    if(filesViewInContainerParam == '1' && (props.item.mimeType == 'application/pdf' || props.item.mimeType == 'video/mp4'))
    {
        setOpenFilesViewInContainer(true);
    }
    else
    {
        window.open(props.item.url, '_blank');
    }
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'FILE_DOWNLOAD',
      log_text: 
        `callcode: ${globalState.auth.callCode} |
        userId: ${globalState.auth.userId} |
        filecode: ${props.item.code} |`
    })
  }

  const closeFilesViewInContainer = () => {
    setOpenFilesViewInContainer(false);
  }

  const filesCustomerEnabled = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_CUSTOMER_ENABLE');
  return(
    <>
    <div className={classes.container}>
      <div className={classes.row}>
        <div>
          <FileIcon />&nbsp;
          <span className={classes.fileName}> {props.item.name}</span>
        </div>
        {/*filesCustomerEnabled === '1' &&
        <div className={classes.eye} onClick={handleVisibility}><StateEyeIcon /></div>
        */}
      </div>
      <div className={classes.row}>
        {props.item.allow_share ?
        <div><FileShareButton item={props.item} /></div> :  <div style={{display: 'inline'}}></div>
        }
        <div onClick={handleDownloadClick}><DownloadButton item={props.item} /></div>
        
      </div>
    </div>
    <div><FilesViewInContainer open={openFilesViewInContainer} closeFilesViewInContainer={closeFilesViewInContainer} item={props.item}/></div>
    </>
  )
}


export default connect(
  null,
  {...presentationBarStorage.actions, ...loggerStorage.actions}
)(FileItem);