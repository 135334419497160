import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import './CustomerGroups.scss';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import ActionSelector from './ActionsSelector';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CallIcon from '@material-ui/icons/Call';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import * as customerGroupsStorage from "../../store/ducks/customerGroups.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
   // marginTop: 10,
    width: 500,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 350,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: 'red'
  },
  label: {
    fontSize: 16,
    //width: '30%',
    //textAlign: 'right'
  },
  data: {
    width: '-webkit-fill-available',
    marginTop:5,
    paddingLeft: 10
  },
  inRow: {
    width: 350,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row'
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomerGroups = (props) => {
  const globalState = useSelector(state => state);
  const [customerGroupSelected, setCustomerGroupSelected] = React.useState(null);
  const [atributesList,setAtributesList] = React.useState([]);
  const [listSelected, setListSelected] = React.useState([]);
  const [modalStyle] = React.useState(getModalStyle);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { intl } = props;

  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setCustomerGroupSelected(row);
    setMenuAnchor(anchorElement);
    props.getCustomerGroupAtribute();
    setMenuOpen(true);
  };
  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
    setCustomerGroupSelected(null);
    setListSelected([]);
  }

  const handleMenuAssignAtr = (clear,insert) => {

    props.assignByAtributeCus({ 
      codGroup: customerGroupSelected.code,
      clear: clear,
      insert: insert,
      codEntity: 0,
      atributeIndex: 1,
      atributeList: listSelected
    })

  }
  
  useEffect(() => {
    if(globalState.customerGroups.createCustomerGroupResult){
      props.getCustomerGroups();
    }
  }, [globalState.customerGroups.createCustomerGroupResult])

  useEffect(() => {
    if (globalState.customerGroups.assignByAtributeCusResult) {
      props.getCustomerGroups();
      handleMenuClose();
    }
  }, [globalState.customerGroups.assignByAtributeCusResult])

  useEffect(() => {
    if (globalState.customerGroups.customerGroupAtributeList.length > 0) {
      setAtributesList(globalState.customerGroups.customerGroupAtributeList);
    }
  }, [globalState.customerGroups.customerGroupAtributeList])

  useEffect (() => {
    props.getCustomerGroups();
  }, [])

  useEffect(() => {
    props.getCustomerGroups();
  }, [globalState.customerGroups.stateId]);

  let actionsColumnIndex = 2;
  let columns = [
    { 
      title: intl.formatMessage({id: "CUSTOMERGROUPS.NAME"}), 
      field: 'name',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "CUSTOMERGROUPS.EXTERNAL_CODE"}), 
      field: 'externalCode',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "CUSTOMERGROUPS.UUID"}), 
      field: 'uuid',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "CUSTOMERGROUPS.DETAILCOUNT"}), 
      field: 'detailCount',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    }
  ];

  const classes = useStyles();
  return(
    <div id="customerGroups" style={{ width: '100%', boxShadow: 'none' }}>
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.customerGroups.customerGroups}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left',
            headerStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {},
            actionsColumnIndex: -1
          }
        }
        actions={[
          {
            icon: 'edit', //ponerle 
            tooltip: intl.formatMessage({
              id: "CUSTOMERGROUPS.ACTIONS.BUTTON"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            }
          }
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },
          
        }}
      />
      {menuOpen ?
        <div style={{ "display": "inline-block" }}>
          <Dialog
            open={menuOpen}
            onClose={handleMenuClose}
            id="popup"
          >
            <DialogTitle id="form-dialog-title">
              <FormattedMessage id="CUSTOMERGROUPS.ASSIGN.TITLE" />
              <span className={classes.data}> - {customerGroupSelected.name}</span>
            </DialogTitle>
            <DialogContent className={classes.dialogtop}>
              <div style={modalStyle} className={classes.paper}>
                <div className={classes.inRow}>
                  <span className={classes.label}><FormattedMessage id="CUSTOMERGROUPS.DETAILCOUNT" />: </span><span className={classes.data}>{customerGroupSelected.detailCount}</span>
                </div>

                <div className={classes.inRow}>

                  <span className={classes.label}><FormattedMessage id="CUSTOMERGROUPS.ASSIGN.FILTER" />:</span>
                  <span 
                    className={classes.data}
                    style={{
                      overflowY:(listSelected.length >= 5) ? 'scroll' : 'hidden', 
                      overflowX: 'hidden',
                      maxHeight: 180
                    }}
                  >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={atributesList}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    onChange={(event, newValue) => {
                      setListSelected(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Seleccione"
                        //placeholder="Especialidad"
                      />
                    )}
                  /></span>
                </div>
              </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button className={classes.button} id="btnCustomerGroupsDelete" onClick={() => handleMenuAssignAtr('true','false')}>
                <FormattedMessage id="CUSTOMERGROUPS.BUTTON.DELETE" />
              </Button>
              <Button className={classes.button} id="btnCustomerGroupsDeleteAssign" onClick={() => handleMenuAssignAtr('true','true')}>
                <FormattedMessage id="CUSTOMERGROUPS.BUTTON.DELETE.ASSIGN" />
              </Button>
              <Button className={classes.button} id="btnCustomerGroupsAssign" onClick={() => handleMenuAssignAtr('false','true')}>
                <FormattedMessage id="CUSTOMERGROUPS.BUTTON.ASSIGN" />
              </Button>
              <Button className={classes.button} id="btnCustomerGroupsCancel" onClick={handleMenuClose}>
                <FormattedMessage id="CUSTOMERGROUPS.BUTTON.CANCEL" />
              </Button>
            </DialogActions>
          </Dialog>
        </div> : null}
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...customerGroupsStorage.actions}
  )(CustomerGroups)
);