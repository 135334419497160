import * as images from './assets/index';

export const getDeploymentParamValue = (parameters, name) => {
  let value = '';
  for (let index = 0; index < parameters.length; index++) {
    const element = parameters[index];
    if (element.key.includes(name)) {
      value = element.value;
      break;
    }
  }
  return value;
}

export const getFilesTypePreview = function(file){
  var preview = '';
  var typeSplited = file.mimeType.split('/');
  var type = '';
  var subType = '';

  if(typeSplited && typeSplited.length >= 1) {
    type = typeSplited[0];
    subType = typeSplited[1];
  }

  switch (type) {
    case 'application':
      if(subType == 'pdf'){
        preview = images.pdfImage;
      } else if(subType.indexOf('sheet') != -1 || subType.indexOf('ms-excel') != -1){
        preview = images.xlsImage;
      } else if(subType.indexOf('vnd.openxmlformats-officedocument.wordprocessingml.document') != -1 || subType.indexOf('msword') != -1 || subType.indexOf('application/vnd.ms-word.document.macroEnabled.12') != -1){
        preview = images.docImage;
      } else if(subType.indexOf('ms-powerpoint') != -1 || subType.indexOf('presentation') != -1 || subType.indexOf('vnd.openxmlformats-officedocument.presentationml.presentation') != -1){
        preview = images.pptImage;
      }
    break;
    case 'image':
      preview = file.url;
    break;
    case 'video':
      preview = images.videoImage;
    break;
  }

  return preview;
};