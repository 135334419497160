import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { getDeploymentParamValue } from '../../../_metronic/utils/utils';

import * as visualAidStorage from "../../store/ducks/visualAid.duck";
import TagsInput from '../TagsInput';

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: 280
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rowContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCheck: {
    fontSize: 16,
    fontWeight: 'lighter'
  }
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeaderVisualAid = (props) => {
  const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [name, setName] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [order, setOrder] = useState(0);
  const [planName, setPlanName] = React.useState([]);
  const [arrTags, setArrTags] = React.useState([]);
  const [onlineVisit, setOnlineVisit] = useState(0);
  const [offlineVisit, setOfflineVisit] = useState(0);

  const CALL_PRESENT_ENABLE = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_PRESENT_ENABLE');
  const showOfflineVisit = CALL_PRESENT_ENABLE === 1 || CALL_PRESENT_ENABLE === '1';
  
  const defaultCallType = getDeploymentParamValue(globalState.auth.deployment.parameters, 'VISUALAID_DEFAULT_CALLTYPE');

  useEffect(() => {
    if(globalState.visualAid.visualAidContainerResponse.name && globalState.visualAid.visualAidContainerResponse.externalCode){
      setName(globalState.visualAid.visualAidContainerResponse.name);
      setExternalCode(globalState.visualAid.visualAidContainerResponse.externalCode);
      setValidFrom(globalState.visualAid.visualAidContainerResponse.validFrom);
      setValidTo(globalState.visualAid.visualAidContainerResponse.validTo);
      setOrder(globalState.visualAid.visualAidContainerResponse.order);

      if(globalState.visualAid.visualAidContainerResponse.plans &&
        globalState.visualAid.visualAidContainerResponse.plans.length > 0){
          let plansSelected = [];
          globalState.visualAid.visualAidContainerResponse.plans.forEach((plan, index) =>{
            globalState.visualAid.promotionalPlans.list.forEach((item, index) => {
              if(plan === item.code){
                plansSelected.push(item);
              }
            })
          })
          setPlanName(plansSelected);
      }

      if(globalState.visualAid.visualAidContainerResponse.tags){
        let tagGroup = globalState.visualAid.visualAidContainerResponse.tags.split(';');
        let arrTagsTemp = [];
        if(tagGroup.length){
          tagGroup.forEach((tagPar) => {
            let keyvalue = tagPar.split(':');
            
            if(keyvalue.length == 2){
              arrTagsTemp.push({key:keyvalue[0], value:keyvalue[1]});
            }
          });
          setArrTags([...arrTagsTemp, ...arrTags]);
        }
      }

      let callType = globalState.visualAid.visualAidContainerResponse.call_type || defaultCallType;
      
      switch (callType) {
        case 1: // Online
          setOnlineVisit(1);
          break;
        case 2: // Offline
          setOfflineVisit(1);
          break;
        case 3: // Las dos
          setOnlineVisit(1);
          setOfflineVisit(1);
          break;
        default:
          break;
      }
      
    }
  }, [
    globalState.visualAid.visualAidContainerResponse.name,
    globalState.visualAid.visualAidContainerResponse.externalCode
  ]);

  useEffect(() => {
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, call_type: checkCallType()})
  }, [onlineVisit, offlineVisit]);

  const handleChangePlanName = (event, values) => {
    setPlanName(values);
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, plans: values})
  };

  const handleChangeName = (event) => {
    setName(event.target.value)
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, name: event.target.value})
  }

  const handleExternalCode = (event) => {
    setExternalCode(event.target.value);
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, externalCode: event.target.value})
  } 

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, validFrom: event.target.value})
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, validTo: event.target.value})
  }

  const handleOrder = (event) => {
    setOrder(event.target.value);
    props.fillResultVisualAidContainer({...globalState.visualAid.visualAidContainerResponse, order: event.target.value})
  } 
  const handleChangeArrTags = (input) => {
    setArrTags(input);
    // aca tiene que pasar de array de objetos a string key:value;key:value
    if(input.length){
      let arrKeyValue = [];
      input.forEach((item) => {
        arrKeyValue.push(`${item.key}:${item.value}`);
      });
      let strtags = arrKeyValue.join(';');
      props.setArrTags(strtags);
    }else{
      props.setArrTags('');
    }
  } 

  const handleChangeOnlineVisit = (event) => {
    setOnlineVisit(event.target.checked);
  }

  const handleChangeOfflineVisit = (event) => {
    setOfflineVisit(event.target.checked);
  }

  const checkCallType = () => {
    let callType = 0;

    if(onlineVisit && offlineVisit) {
      callType = 3;
    } else if(onlineVisit) {
      callType = 1;
    } else if(offlineVisit) {
      callType = 2;
    }
    return callType;
  }

  let statusText = ''; //(1=aprobado, 2=pendiente, 3= Borrador, 4=Temporal, 5=Procesando)
  if(globalState.visualAid.visualAidContainerResponse.status){
    switch(globalState.visualAid.visualAidContainerResponse.status){
      case 1:
        statusText = intl.formatMessage({ id: "VAID.STATUS.APPROVED" })
        break;
      case 2:
        statusText = intl.formatMessage({ id: "VAID.STATUS.PENDING" })
        break;
      case 3:
        statusText = intl.formatMessage({ id: "VAID.STATUS.DRAFT" })
        break;
      case 4:
        statusText = intl.formatMessage({ id: "VAID.STATUS.TEMP" })
        break;
      case 5:
        statusText = intl.formatMessage({ id: "VAID.STATUS.PROCESSING" })
        break;
      case 8:
        statusText = intl.formatMessage({ id: "VAID.STATUS.PROCESSING_ERROR" })
        break;
      default:
        statusText = ''
    }
  }

  let labelPresentations = intl.formatMessage({
    id: "VAID.EDIT.FORM.PLANOS"
  });

  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.TYPE" />: </span>
        <span className={classes.data}>{props.type.label}</span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.NAME" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={name}
            onChange={handleChangeName}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.EXTERNAL_CODE" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={externalCode}
            onChange={handleExternalCode}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_FROM" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            id="datetime-local"
            type="datetime-local"
            value={validFrom}
            onChange={handleChangeDateFrom}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_TO" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            id="datetime-local"
            type="datetime-local"
            value={validTo}
            onChange={handleChangeDateTo}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.ORDER" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            type="number"
            value={order}
            onChange={handleOrder}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.STATUS" />: </span>
        <span className={classes.data}>{statusText}</span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.PLANOS" />: </span>
        <span className={classes.data}>
          <Autocomplete
            multiple
            id="tags-standard-visual-aids"
            options={globalState.visualAid.promotionalPlans.list}
            disableCloseOnSelect={true}
            onChange={handleChangePlanName}
            value={planName}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
               </React.Fragment>
               )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={labelPresentations}
                  />
              )}
           />
        </span>
      </div>

      <div className={classes.rowContainer}>
        <FormControlLabel
          value={onlineVisit}
          control={
            <Checkbox 
            icon={icon}
            checkedIcon={checkedIcon} 
            checked={onlineVisit}
            onChange={handleChangeOnlineVisit} 
            />
          }
          label={
            <Typography className={classes.labelCheck}>
              <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE" />:
            </Typography>
          }
          labelPlacement="start"
        />
        
        {
          showOfflineVisit &&
          <FormControlLabel
            value={offlineVisit}
            control={
              <Checkbox 
              icon={icon}
              checkedIcon={checkedIcon}
              checked={offlineVisit}
              onChange={handleChangeOfflineVisit} 
              />
            }
            label={
              <Typography className={classes.labelCheck}>
                <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.PRESENTATION" />:
              </Typography>
            }
            labelPlacement="start"
          />
        }
      </div>         

      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.TAGS" />: </span>
        <span className={classes.data}>
          <TagsInput 
            setArrTagsGlobal={handleChangeArrTags} 
            tagsItems={arrTags} 
            tagOptions={globalState.visualAid.tagsOptions}
          />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(HeaderVisualAid)
);