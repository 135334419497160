// Utility functions to display test results in the sample app UI
import createChart from './chart.js';
import iconPassSvg from './assets/icon_pass.svg';
import iconWarningSvg from './assets/icon_warning.svg';
import iconErrorSvg from './assets/icon_error.svg';

var charts = {};
var audioOnlyTest;
var startTime = null;
const resultCount = {
  audio: 0,
  video: 0
};
const prevBitsReceived = {
  audio: 0,
  video: 0
};
var stopBtnTimeout;

export function resetCharts() {
  charts = {};
  stopBtnTimeout = undefined;
}

export function init(audioOnly) {
  startTime = new Date().getTime();
  audioOnlyTest = audioOnly;
  document.getElementById('quality_status_container').style.display = 'block';
  if (audioOnlyTest) {
    document.getElementById('video').style.display = 'none';
  }
}

export function checkToDisplayStopButton() {
  var stopTestBtn = document.getElementById('stop_test');
  var closeTestBtn = document.getElementById('close_test');
  if (!stopBtnTimeout) {
    stopBtnTimeout = window.setTimeout(function () {
      stopTestBtn.style.display = 'block';
      closeTestBtn.style.display = 'block';
    }, 4000);
  }
}

export function hideStopButton() {
  var stopTestBtn = document.getElementById('stop_test');

  stopTestBtn.style.display = 'none';

}

export function displayTestConnectivityResults(results) {
  const statusContainer = document.getElementById('connectivity_status_container');
  const statusMessageEl = statusContainer.querySelector('p');
  const statusIconEl = statusContainer.querySelector('img');
  statusMessageEl.style.display = 'block';

  let statusText;
  if (results.success) {
    statusText = 'Passed';
    statusIconEl.src = iconPassSvg;
  } else {
    statusText = 'Failed tests: ' + convertFailedTestsToString(results.failedTests);
    statusIconEl.src = iconErrorSvg;
  }
  statusMessageEl.textContent = " " + statusText;
}

function convertFailedTestsToString(failedTests) {
  const failureTypes = [];
  for (var i = 0; i < failedTests.length; i++) {
    failureTypes.push(failedTests[i].type);
  }
  var mappedFailures = [];
  if (failureTypes.indexOf('api') > -1) {
    mappedFailures.push('OpenTok API server');
  }
  if (failureTypes.indexOf('messaging') > -1) {
    mappedFailures.push('OpenTok messaging WebSocket');
  }
  if (failureTypes.indexOf('media') > -1) {
    mappedFailures.push('OpenTok Media Router');
  }
  if (failureTypes.indexOf('logging') > -1) {
    mappedFailures.push('OpenTok logging server');
  }
  return mappedFailures.join(', ');
}

function rateMosScore(mos,intl) {
  if (mos >= 3.8) {
    return intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.EXCELLENT"});
  }
  if (mos >= 3.1) {
    return intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.GOOD"});
  }
  if (mos >= 2.4) {
    return intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.FAIR"});
  }
  if (mos >= 1.7) {
    return intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.POOR"});
  }
  return intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.BAD"});;
}

export function displayTestQualityResults(error, results, intl, log) {
  hideStopButton();
  const statusContainerEl = document.getElementById('quality_status_container');
  const statusEl = statusContainerEl.querySelector('p');
  const statusIconEl = statusContainerEl.querySelector('img');
  statusContainerEl.querySelector('#audio .results').style.display = 'block';
  statusContainerEl.querySelector('#video .results').style.display = 'block';

  if (error) {
    statusEl.textContent = error.message;
    statusIconEl.src = iconErrorSvg;
    return;
  }

  logResults(log, results, intl);

  statusEl.textContent = intl.formatMessage({ id: "CALL.NETWORK.TEST.COMPLETE.TEXT" });
  let resultsEl = statusContainerEl.querySelector('#audio .results');
  resultsEl.style.display = 'block';
  resultsEl.querySelector('#audio-supported').textContent = "  " + results.audio.supported ? intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.YES" }) : intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.NO" });
  const audioMos = results.audio.mos;
  resultsEl.querySelector('#audio-mos').textContent = "  " + audioMos.toFixed(2)
    + ' (' + rateMosScore(audioMos,intl) + ')';
  resultsEl.querySelector('#audio-bitrate').textContent = "  " + results.audio.bitrate ?
    (results.audio.bitrate / 1000).toFixed(2) + ' kbps' : '--';
  resultsEl.querySelector('#audio-plr').textContent = "  " + results.audio.supported ?
    (results.audio.packetLossRatio / 100).toFixed(2) + '%' : '--';
  resultsEl = statusContainerEl.querySelector('#video .results');
  resultsEl.querySelector('#video-supported').textContent = "  " + results.video.supported ? intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.YES" }) : intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.NO" });
  const videoMos = results.video.mos;
  resultsEl.querySelector('#video-mos').textContent = "  " + videoMos.toFixed(2)
    + ' (' + rateMosScore(videoMos,intl) + ')';
  resultsEl.querySelector('#video-bitrate').textContent = "  " + results.video.bitrate ?
    (results.video.bitrate / 1000).toFixed(2) + ' kbps' : '--';
  resultsEl.querySelector('#video-plr').textContent = " " + results.video.supported ?
    (results.video.packetLossRatio / 100).toFixed(2) + '%' : '--';
  resultsEl.querySelector('#video-recommendedResolution').textContent = "  " + 
    results.video.recommendedResolution || '--';
  resultsEl.querySelector('#video-recommendedFrameRate').textContent = " " + 
    results.video.recommendedFrameRate ? results.video.recommendedFrameRate + ' fps' : '--';
  var reasonEl = resultsEl.querySelector('#video-unsupported-reason');
  if (results.audio.supported) {
    if (results.video.supported || audioOnlyTest) {
      reasonEl.style.display = 'none';
      statusIconEl.src = iconPassSvg;
    } else {
      statusIconEl.src = iconWarningSvg;
      reasonEl.style.display = 'block';
      reasonEl.querySelector('span').textContent = " " + results.video.reason;
    }
  } else if (!results.video.supported) {
    statusIconEl.src = iconErrorSvg;
  }
}

function logResults(log, results, intl) {
  let storageItem = undefined;
  let browserId;
  if (typeof (localStorage) !== "undefined") {
    try {
      storageItem = localStorage.getItem('browserid');
    }
    catch (e) {
      console.log(e);
    }
  }
  if (storageItem != undefined) {
    browserId = JSON.parse(storageItem);
  }
  else {
    browserId = Math.floor(Math.random() * 1000000000);
    localStorage.setItem('browserid', browserId);
  }

  var endTime = new Date().getTime();
  var testTimeMs = endTime - startTime;
  var testTimeSec = ((testTimeMs % 60000) / 1000).toFixed(0);

  let audioSupported = results.audio.supported ? "1" : "0";
  let videoSupported = results.video.supported ? "1" : "0";
  let audioBitrate = results.audio.bitrate ? (results.audio.bitrate / 1000).toFixed(2) + ' kbps' : '--';
  let audioPlr = results.audio.supported ? (results.audio.packetLossRatio / 100).toFixed(2) + '%' : '--';
  let audioMos = results.audio.mos.toFixed(2);
  let videoBitrate = results.video.bitrate ? (results.video.bitrate / 1000).toFixed(2) + ' kbps' : '--';
  let videoPlr = results.video.supported ? (results.video.packetLossRatio / 100).toFixed(2) + '%' : '--';
  let videoRecommendedResolution = results.video.recommendedResolution || '--';
  let videoRecommendedFrameRate = results.video.recommendedFrameRate ? results.video.recommendedFrameRate + ' fps' : '--';
  let videoMos = results.video.mos.toFixed(2);
  let videoReason =  !videoSupported ? ' | reason: ' +results.video.reason: '';
  let audioReason =  !audioSupported ? ' | reason: ' +results.audio.reason: '';

  log({
    logtype: 4,
    log_category: 'NETWORK_TEST',
    log_text: 'audioSupported: ' + audioSupported +
      ' | videoSupported: ' + videoSupported +
      ' | browserid: ' + browserId + ' | runtime: ' + testTimeSec
  });
  log({
    logtype: 4,
    log_category: 'TEST_AUDIO',
    log_text: 'audioSupported: ' + audioSupported + ' | audioMos: ' + audioMos +
      ' | audioBitrate: ' + audioBitrate +
        ' | audioPlr: ' + audioPlr + audioReason
  });
  log({
    logtype: 4,
    log_category: 'TEST_VIDEO',
    log_text: 'videoSupported: ' + videoSupported + ' | videoMos: ' + videoMos +
      ' | videoBitrate: ' + videoBitrate +
        ' | videoPlr: ' + videoPlr +
          ' | recommendedResolution: ' + videoRecommendedResolution +
          ' | recommendedFrameRate: ' + videoRecommendedFrameRate + videoReason
  });
}

export function graphIntermediateStats(mediaType, stats, intl) {
  const mediaStats = stats[mediaType];
  if (!charts[mediaType]) {
    charts[mediaType] = createChart(mediaType,intl);
  }
  const bitsReceived = mediaStats && mediaStats.bytesReceived ? mediaStats.bytesReceived * 8 : 0;
  resultCount[mediaType]++;
  if (charts[mediaType] && charts[mediaType].series) {
    charts[mediaType].series[0].addPoint({
      x: resultCount[mediaType],
      y: bitsReceived - prevBitsReceived[mediaType]
    }, true, false);
    const chartTitle = (stats.phase === 'audio-only') && (mediaType === 'video') ?
      intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.TESTING.AUDIO.ONLY"}) :
      intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.BITRATE.OVER"}) + " " + resultCount[mediaType] + 'sec';
    //charts[mediaType].setTitle(null, { text: chartTitle });
    prevBitsReceived[mediaType] = bitsReceived;
  }
}
