import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import { useSelector } from "react-redux";
import FileFolder from './FileFolder';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Badge from '@material-ui/core/Badge';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import SearchFile from './SearchFile';
import _, {isEqual} from 'lodash';

// Impoorting action types and methods from local actions file
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import { areEqual } from 'react-window';

const useStyles = makeStyles((theme) => ({
  header:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029'
  }
}));

const FileDrawer = (props) => {
  const auth = useSelector(state => state.auth);
  const presentationBar = useSelector(state => state.presentationBar);
  const [badgeInvisible, setBadgeInvisible] = useState(true);
  const classes = useStyles();
  const accentColor = getDeploymentParamValue(auth.deployment.parameters, 'ACCENT_COLOR_1')
  useEffect(() => {
    
    if(auth.role === 'organizer'){
      props.getFileList({callCode: auth.callCode});
    }
  }, [])

  const toggleFilesDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') && event.type === 'click') {
      return;
    }
    if(open){
      setBadgeInvisible(true);
    }
    Object.assign(presentationBar, { ...presentationBar, showFilesDrawer: open })
    props.updateStateChatDrawer({ showFilesDrawer: open });
  };
  
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const filesEnabled = getDeploymentParamValue(auth.deployment.parameters, 'FILES_ENABLE');
  const filesCustomerEnabled = getDeploymentParamValue(auth.deployment.parameters, 'FILES_CUSTOMER_ENABLE');

  return(
    <>
      {auth.role === 'organizer' && filesEnabled === '1' &&
      <div id="buttonFiles" className={`ots-video-control circle`}>
        <Button onClick={toggleFilesDrawer(true)} style={{ height: '100%'}}>
          <CloudDownloadIcon fontSize="large"/>
        </Button>
      </div>
      }
      
      {/*auth.role === 'subscriber' && filesCustomerEnabled === '1' &&
      <div id="buttonFiles" className={`ots-video-control circle`}>
        <Button onClick={toggleFilesDrawer(true)} style={{ height: '100%'}}>
          <Badge style={{color: accentColor}} badgeContent=" " invisible={badgeInvisible}>
            <CloudDownloadIcon fontSize="large"/>
          </Badge>
        </Button>
      </div>
      */}
      <SwipeableDrawer
        anchor="left"
        open={presentationBar.showFilesDrawer}
        onClose={toggleFilesDrawer(false)}
        onOpen={toggleFilesDrawer(true)}
        disableBackdropTransition={!iOS} 
        disableDiscovery={iOS}
        ModalProps={{
          container: document.getElementById('callContainer'),
          style: { position: 'absolute' }
        }}
      >
        <div className="files__container">
          <div className={classes.header}>
            <div></div>
            <div className="files__header__text" style={{color: '#000000'}}>
              {props.fileDrawerTitle}
            </div>
            <IconButton id="closePresentationList" onClick={toggleFilesDrawer(false)} className="files__header__close" style={{color: '#000000'}}>
              <CloseIcon />
            </IconButton>
          </div>
          <SearchFile />
          <div className="files__body"> {/* contenedor de todo el files */}
            <FileFolder fileListFiltered={presentationBar.fileListFiltered} />
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default FileDrawer;