import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/pt";
import "@formatjs/intl-relativetimeformat/dist/locale-data/it";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import {appNameCode} from '../../globalConfig';

import enNomadMessages from "./nomad/messages/en";
import esNomadMessages from "./nomad/messages/es";
import frNomadMessages from "./nomad/messages/fr";
import ptNomadMessages from "./nomad/messages/pt";  
import itNomadMessages from "./nomad/messages/it";
import deNomadMessages from "./nomad/messages/de";
import enDefaultMessages from "./default/messages/en";
import esDefaultMessages from "./default/messages/es";
import frDefaultMessages from "./default/messages/fr";
import ptDefaultMessages from "./default/messages/pt";
import itDefaultMessages from "./default/messages/it";
import deDefaultMessages from "./default/messages/de";

let allMessages = {}
switch(appNameCode){
  case 'nomad':
    allMessages = {
      en: enNomadMessages,
      es: esNomadMessages,
      fr: frNomadMessages,
      pt: ptNomadMessages,
      it: itNomadMessages,
      de: deNomadMessages
    };
    break;
    default:
      allMessages = {
        en: enDefaultMessages,
        es: esDefaultMessages,
        fr: frDefaultMessages,
        pt: ptDefaultMessages,
        it: itDefaultMessages,
        de: deDefaultMessages
      };
      break;
}

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
  );
}
