import React, {useState, useRef} from 'react';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { FormattedMessage, injectIntl } from "react-intl";
import MaterialTable from 'material-table';
import {DropzoneArea} from 'material-ui-dropzone'

const ImportCsv = (props) => {
  const { intl } = props;
  const inputFileRef = useRef();
  const [fileContent, setFileContent] = useState(null);
  const [tabletColumns, setTableColumns] = useState([]);
  const [keyDropZone, setKeyDropZone] = useState(0);
  //const setFileName
  const reader = new FileReader();
  const acceptedFiles = [".csv"];
  const requiredColumns = ["USER_EXTERNALCODE","CUSTOMER_EXTERNALCODE","NOMBRE CUSTOMER_","IDENTIFICACION CUSTOMER_","CUSTOMER_EMAIL","CUSTOMER_ATRIBUTE_1","CUSTOMER_TIMEZONE","CUSTOMER_LANGUAGE","CUSTOMER_ISACTIVE"];

  const readFile = (data) => {
    if(!data.length){
      props.setName('');
      props.setSize(0);
      props.setFile(null);
      setTableColumns([]);
      setFileContent([]);
      return;
    } 
    const slice_size = 1024;
    const start = 0;
    var next_slice = start + 2 * slice_size + 1;
    
    const file = data[0];

    props.setName(file.name);
    props.setSize(file.size);
    props.setFile(file)
    var blob = file.slice(start, next_slice);
    reader.readAsDataURL(blob);

    var handleBlob = function () {
      var data = reader.result;
      var base64 = data.split('base64,')[1];
      var csv = atob(base64);
      const csvSplitted = csv.split("\n");

      //definimos las columnas
      const headerRowArr = new String(csvSplitted[0]).split(";");
      if(headerRowArr.length !== 9){
        props.setName('');
        props.setSize(0);
        props.setFile(null);
        setTableColumns([])
        setFileContent([]);
        setKeyDropZone(keyDropZone + 1);

        showToastImportantNotification(intl.formatMessage({id: "IMPORT.ERROR.FILE_STRUCTURE_WRONG"}), 'red');
      }else{
        let tableColumn = [];
        headerRowArr.forEach((item, i) => {
          const itemSplitted = item.split('\r')
          if(itemSplitted[0] === requiredColumns[i]) {
            tableColumn.push({ 
              title: item, 
              field: item, 
              cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} 
            });
          } else {
            props.setName('');
            props.setSize(0);
            props.setFile(null);
            setTableColumns([])
            setFileContent([]);
            setKeyDropZone(keyDropZone + 1);
    
            showToastImportantNotification( intl.formatMessage(
              {id: "IMPORT.ERROR.FILE_COLUMN_INVALID"},
              {
                item: item,
              }
            ), 'red');
          }         
        });
  
        const limit = csvSplitted.length;
        let tableContent = [];
        for(let i=1; i<=limit - 1; i++){
          if(csvSplitted[i]){
            let row = csvSplitted[i].split(";");
            let objRow = {};
            for(let j=0; j<=headerRowArr.length; j++) {
              objRow[headerRowArr[j]] = row[j];
            }
            tableContent.push(objRow);
          }
        }
        setTableColumns(tableColumn)
        setFileContent(tableContent);
      }
    }
    reader.onloadend = handleBlob;

  }

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }

  return(
    <>
    <DropzoneArea 
      ref={inputFileRef}
      onChange={readFile}
      acceptedFiles={[".csv"]} 
      filesLimit={1} 
      showAlerts={false} 
      showFileNames={true} 
      maxFileSize={200 * 1000000} 
      dropzoneText={intl.formatMessage({id: "VAID.DROPZONE.TEXT"})}
      key={keyDropZone}
    />
    <p><strong>{intl.formatMessage({id: "VAID.DROPZONE.FOOTER.EXTENSIONS"})}: </strong>{acceptedFiles.join(', ')}</p>
    <p><strong>{intl.formatMessage({id: "VAID.DROPZONE.FOOTER.FORMAT.CSV"})}</strong></p>
    <p><strong>{intl.formatMessage({id: "VAID.DROPZONE.FOOTER.COLUMNS.REQUIRED"})}: </strong>{requiredColumns.join(', ')}</p>
    { fileContent &&
      <MaterialTable
      columns={tabletColumns}
      title=""
      data={fileContent}
      options={
        { 
          actionsColumnIndex: 2, 
          pageSize: 10, 
          search: false,
          sorting: false,
          headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
          actionsColumnIndex: -1
        }
      }
      localization={{
        header: {
          actions: ''
        },
        body: {
          emptyDataSourceMessage: intl.formatMessage({
            id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
          })
        },
        pagination: {
          labelDisplayedRows: intl.formatMessage({
            id: "MATERIAL_TABLE.PAGINATION.TEXT"
          }, {
            from: '{from}',
            to: '{to}',
            count: '{count}'
          }),
          labelRowsSelect: intl.formatMessage({
            id: "MATERIAL_TABLE.PAGINATION.LABEL"
          })
        }
      }}
    />
    }
  </>
  )
}

export default injectIntl(ImportCsv);