import { Exception } from "@microsoft/applicationinsights-web";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  getUsersByStateId,
  activateUser,
  deactivateUser,
  sendEmailGuest,
  saveNewUser,
  getUser,
  editUser
} from "./users.crud";

export const actionTypes = {
  GET_USERS: "GET_USERS",
  GET_USER: "GET_USER",
  SET_USERS_RESULT: "SET_USERS_RESULT",
  SET_STATE_ID: "SET_STATE_ID",
  ACTIVATE_USER: "ACTIVATE_USER",
  DEACTIVATE_USER: "DEACTIVATE_USER",
  ACTIVATE_USER_RESULT: "ACTIVATE_USER_RESULT",
  DEACTIVATE_USER_RESULT: "DEACTIVATE_USER_RESULT",
  UPDATE_USER_SELECTED: "UPDATE_USER_SELECTED",
  SET_EMAIL_RESULT_GUEST: "SET_EMAIL_RESULT_GUEST",
  SEND_EMAIL_GUEST: "SEND_EMAIL_GUEST",
  SET_OPEN_CALL_DETAIL: "SET_OPEN_CALL_DETAIL",
  SET_DELETE_GUEST: "SET_DELETE_GUEST",
  SET_NEW_USER_OBJECT: "SET_NEW_USER_OBJECT",
  SET_ERROR_VALIDATION: "SET_ERROR_VALIDATION",
  SAVE_NEW_USER: "SAVE_NEW_USER",
  SET_SAVE_USER_RESULT: "SET_SAVE_USER_RESULT",
  RESET_NEW_USER: "RESET_NEW_USER",
  SET_RESULT_GET_USER: "SET_RESULT_GET_USER",
  EDIT_USER: "EDIT_USER",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE"
};

const initialUsersState = {
  loading: false,
  users: [],
  stateId: 1, // state_id=1
  activateUserResult: null,
  deactivateUserResult: null,
  userSelected: {},
  emailResultGuest: '',
  openCallDetail: false,
  deleteGuest: false,
  newUserObject: {
    name: '',
    email: '',
    identification: '',
    externalCode: '',
    timezone: 0,
    language: 'pt',
    atribute_1: '',
    password: '',
    groups: [],
    cod_Profile: 0
  },
  errorValidation: '',
  resultSaveNewUser: null,
  resultGetUser: null,
  errorMessage: ""
};

export const reducer = persistReducer(
    { storage, key: "demo1-users", whitelist: [""] },
    (state = initialUsersState, action) => {
      switch (action.type) {
        case actionTypes.GET_USERS: {
          return { ...state, loading: true };
        }
        case actionTypes.GET_USER: {
          return { ...state, loading: true };
        }
        case actionTypes.SET_USERS_RESULT: {
          return { 
            ...state, 
            loading: false,
            users: action.payload
          };
        }
        case actionTypes.SET_STATE_ID: {
          return { 
            ...state,
            stateId: action.payload
          };
        }
        case actionTypes.ACTIVATE_USER: {
          return { 
            ...state,
            loading: true
          };
        }
        case actionTypes.DEACTIVATE_USER: {
          return { 
            ...state,
            loading: true
          };
        }
        case actionTypes.ACTIVATE_USER_RESULT: {
          return { 
            ...state,
            loading: false,
            activateUserResult: action.payload
          };
        }
        case actionTypes.DEACTIVATE_USER_RESULT: {
          return { 
            ...state,
            loading: false,
            deactivateUserResult: action.payload
          };
        }
        case actionTypes.UPDATE_USER_SELECTED: {
          return { 
            ...state,
            loading: false,
            userSelected: action.payload,
            deleteGuest: !action.payload
          };
        }
        case actionTypes.SET_EMAIL_RESULT_GUEST: {
            return { 
              ...state, 
              emailResultGuest: action.payload, 
              loading: false 
            };
        }
        case actionTypes.SEND_EMAIL_GUEST: {
            return  {
               ...state,
               loading: true 
            };
        }
        case actionTypes.SET_DELETE_GUEST: {
           return {
             ...state,
             deleteGuest: action.payload
           }
        }
        case actionTypes.SET_OPEN_CALL_DETAIL: {
          return  {
             ...state,
             openCallDetail: action.payload,
             loading: true 
          };
        }
        case actionTypes.SET_NEW_USER_OBJECT: {
          return Object.assign({}, state,  { newUserObject: {...state.newUserObject, ...action.payload}} );
        }
        case actionTypes.SET_ERROR_VALIDATION: {
          return  {
             ...state,
             errorValidation: action.payload,
             loading: false
          };
        }
        case actionTypes.SAVE_NEW_USER: {
          return  {
             ...state,
             loading: true
          };
        }
        case actionTypes.SET_SAVE_USER_RESULT: {
          return  {
             ...state,
             resultSaveNewUser: action.payload,
             loading: false
          };
        }
        case actionTypes.RESET_NEW_USER: {
          return  {
             ...state,
             newUserObject: initialUsersState.newUserObject,
             resultSaveNewUser: null,
             resultGetUser: null,
             loading: false
          };
        }
        case actionTypes.SET_RESULT_GET_USER: {
          return  {
             ...state,
             resultGetUser: action.payload,
             loading: false
          };
        }
        case actionTypes.SET_ERROR_MESSAGE: {
          return  {
             ...state,
             errorMessage: action.payload,
             loading: false
          };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  getUsers: (value) => ({ type: actionTypes.GET_USERS, payload: value }),
  getUser: (value) => ({ type: actionTypes.GET_USER, payload: value }),
  setUsersResult: (value) => ({ type: actionTypes.SET_USERS_RESULT, payload: value }),
  setStateId: (value) => ({ type: actionTypes.SET_STATE_ID, payload: value }),
  activateUser: (value) => ({ type: actionTypes.ACTIVATE_USER, payload: value }),
  deactivateUser: (value) => ({ type: actionTypes.DEACTIVATE_USER, payload: value }),
  setActivateUserResult: (value) => ({ type: actionTypes.ACTIVATE_USER_RESULT, payload: value }),
  setdeactivateUserResult: (value) => ({ type: actionTypes.DEACTIVATE_USER_RESULT, payload: value }),
  updateUserSelected: (value) => ({ type: actionTypes.UPDATE_USER_SELECTED, payload: value }),
  setEmailResultGuest: (value) => ({ type: actionTypes.SET_EMAIL_RESULT_GUEST, payload: value }),
  sendEmailGuest: (value) => ({ type: actionTypes.SEND_EMAIL_GUEST, payload: value }),
  setOpenCallDetail: (value) => ({ type: actionTypes.SET_OPEN_CALL_DETAIL, payload: value }),
  setDeleteGuest: (value) => ({ type: actionTypes.SET_DELETE_GUEST, payload: value }),
  fillNewUserObject: (value) => ({ type: actionTypes.SET_NEW_USER_OBJECT, payload: value }),
  setErrorValidation: (value) => ({ type: actionTypes.SET_ERROR_VALIDATION, payload: value }),
  saveNewUser: () => ({ type: actionTypes.SAVE_NEW_USER, payload: null }),
  setSaveUserResult: (value) => ({ type: actionTypes.SET_SAVE_USER_RESULT, payload: value }),
  resetNewUser: () => ({ type: actionTypes.RESET_NEW_USER, payload: null }),
  setResultGetUser: (value) => ({ type: actionTypes.SET_RESULT_GET_USER, payload: value }),
  editUser: () => ({ type: actionTypes.EDIT_USER, payload: null }),
  setErrorMessage: (value) => ({ type: actionTypes.SET_ERROR_MESSAGE, payload: value })
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.SAVE_NEW_USER, function* saveNewUserSaga() {
    const selector = yield select(getState);
    try{
      let objectToSave = selector.users.newUserObject;
      objectToSave.groups = objectToSave.groups?.map((group) => {
        return group.externalCode
      });

      const {data, status} = yield saveNewUser(objectToSave);
      
      if(status < 300){
        yield put(actions.setSaveUserResult(true));
      }else{
        yield put(actions.setErrorMessage(data));
        yield put(actions.setSaveUserResult(false));
      }
      yield put(actions.getUsers(false));
    }catch(e){
      yield put(actions.setSaveUserResult(false));
      yield put(actions.getUsers(false));
    }    
  });
  
  yield takeLatest(actionTypes.EDIT_USER, function* editUserSaga() {
    const selector = yield select(getState);
    try{
      let objectToSave = selector.users.newUserObject;
      objectToSave.groups = objectToSave.groups?.map((group) => {
        return group.externalCode
      });
      objectToSave.state_Id = 1;
      delete objectToSave.password;

      const {data, status} = yield editUser(objectToSave);
      
      if(status < 300){
        yield put(actions.setSaveUserResult(true));
      }else{
        yield put(actions.setSaveUserResult(false));
      }
      yield put(actions.getUsers(false));
    }catch(e){
      yield put(actions.setSaveUserResult(false));
      yield put(actions.getUsers(false));
    }    
  });

  yield takeLatest(actionTypes.DEACTIVATE_USER, function* deactivateUserSaga({payload}) {
    try{
      const {data, status} = yield deactivateUser(payload.uuid);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setdeactivateUserResult(true));
      }else{
        yield put(actions.setdeactivateUserResult(false));
      }
      yield put(actions.getUsers(false));
    }catch(e){
      yield put(actions.setdeactivateUserResult(false));
      yield put(actions.getUsers(false));
    }    
  });

  yield takeLatest(actionTypes.ACTIVATE_USER, function* activateUserSaga({payload}) {
    try{
      const {data, status} = yield activateUser(payload.uuid);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setActivateUserResult(true));
      }else{
        yield put(actions.setActivateUserResult(false));
      }
      yield put(actions.getUsers(false));
    }catch(e){
      yield put(actions.setActivateUserResult(false));
      yield put(actions.getUsers(false));
    }    
  });

  yield takeLatest(actionTypes.GET_USERS, function* getUsersSaga({ payload }) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getUsersByStateId(selector.users.stateId, payload.allow_guest);
      
      if(status < 300){
        yield put(actions.setUsersResult(data.list));
      }else{
        yield put(actions.setUsersResult([]));
      }
     //yield put(actions.setPasswordResult(false));
    }catch(e){
      yield put(actions.setUsersResult([]));
      //console.log(e)
    }
    
  });
  yield takeLatest(actionTypes.GET_USER, function* getUserSaga({ payload }) {
    try{
      const {data, status} = yield getUser(payload);
      if(status < 300){
        yield put(actions.fillNewUserObject(data));
        yield put(actions.setResultGetUser(true));
      }else{
        yield put(actions.setResultGetUser(false));
      }
     //yield put(actions.setPasswordResult(false));
    }catch(e){
      yield put(actions.setUsersResult([]));
      //console.log(e)
    }
    
  });

  yield takeLatest(actionTypes.SEND_EMAIL_GUEST, function* sendEmailGuestSaga({ payload }) {
    try {
      const { data } = yield sendEmailGuest(payload);
      yield put(actions.setEmailResultGuest('ok'));
    } catch (err) {
      if(err.response && err.response.data && err.response.data != ''){
        yield put(actions.setEmailResultGuest(err.response.data));
        console.log(err.response.data);
      }else{
        yield put(actions.setEmailResultGuest('error'));
      }
    }
  });
}
