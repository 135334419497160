import axios from "axios";
import {configUrls} from '../../../globalConfig';

export const GET_USERS_BY_STATE_ID = `${configUrls.base_url}/api/user?state_id=`;
export const GET_USER = `${configUrls.base_url}/api/user/`;
export const ACTIVATE_USER_BY_ID = `${configUrls.base_url}/api/user/action/activate/`;
export const INACTIVATE_USER_BY_ID = `${configUrls.base_url}/api/user/action/inactivate/`;
export const SEND_EMAIL_URL = `${configUrls.base_url}/api/call/action/sendemail/`;
export const SAVE_NEW_USER_URL = `${configUrls.base_url}/api/user/create`;
export const EDIT_USER_URL = `${configUrls.base_url}/api/user/update/`;

export function getUsersByStateId(type,allow_guest = -1){
  return axios.get(GET_USERS_BY_STATE_ID + type + "&allow_guest=" + allow_guest);
}
export function getUser(uuid){
  return axios.get(GET_USER + uuid);
}

export function activateUser(id){
  return axios.put(ACTIVATE_USER_BY_ID + id);
}

export function deactivateUser(id){
  return axios.put(INACTIVATE_USER_BY_ID + id);
}

export function sendEmailGuest(payload){
  if(payload.email && payload.email != ''){
    return axios.post(SEND_EMAIL_URL + payload.callcode + "/"+ payload.userid + "?email=" + payload.email);
  }
  return axios.post(SEND_EMAIL_URL + payload.callcode + "/"+ payload.userid);
}

export async function saveNewUser(payload){
  return axios.post(SAVE_NEW_USER_URL, payload)
  .then(response => response)
  .catch(e => e.response);
}

export function editUser(payload){
  let uuid = payload.uuid;
  delete payload.uuid;
  return axios.put(EDIT_USER_URL + uuid, payload);
}