import React, {useRef, useEffect} from 'react';
import RUG, { Card, DragArea, DropArea } from 'react-upload-gallery'
import HeaderVisualAid from '../../components/VisualAids/HeaderVisualAid';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '../../components/VisualAids/UploadIcon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from "axios";
import VaidCanvasDialog from '../../components/VaidCanvasDialog';

import * as visualAidStorage from "../../store/ducks/visualAid.duck";

import { BlobServiceClient } from "@azure/storage-blob";
import { DropzoneArea } from 'material-ui-dropzone';
import './VisualAidsCreatePage.scss';

// Add style manually
import 'react-upload-gallery/dist/style.css' // or scss
import { initial, set } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';

const VisualAidsCreatePage = (props) => {
  const {intl} = props;
  const globalState = useSelector(state => state);
  const [images, setImages] = React.useState([]);
  const [initialState, setInitialState] = React.useState([]);
  const [delayed, setDelayed] = React.useState(true);  
  const [pdfAttachment, setPdfAttachment] = React.useState();
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false);
  const [pdfName, setPdfName] = React.useState('');
  const [canvasArr, setCanvasArr] = React.useState([]);
  const [selectedSlide, setSelectedSlide] = React.useState({});
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);
  const [pdfDependencyLoaded, setPdfDependencyLoaded] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const history = useHistory();
  const ref = useRef();
  const refInput = useRef();


  let typeSelected = { key: '', label: '' };
  const [fileSelected, setFileSelected] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState(-1);
  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  if(history && history.location && history.location.pathname){
    if(history.location.pathname.indexOf('slide') != -1){
      typeSelected = { key: 'slide', label: 'Slide' };
    }else if(history.location.pathname.indexOf('sdva') != -1){
      typeSelected = { key: 'sdva', label: 'Micrositio' };
    }else if(history.location.pathname.indexOf('html5animate') != -1){
      typeSelected = { key: 'html5animate', label: 'Html5 - Animate' };
    }else if(history.location.pathname.indexOf('video') != -1){
      typeSelected = { key: 'video', label: intl.formatMessage({id: "VAID.OPTIONS.VIDEO"}) };
    }
  }

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }
  
  useEffect(() => {
    // llamar por get a /visualaid/{visualaidCode}?edit=true
    
    const script = document.createElement("script");    
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.min.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      setPdfDependencyLoaded(true);
    }
    props.getEditVisualAid(globalState.visualAid.visualAidContainerResponse.code);
    return () => {
      props.fillResultVisualAidContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.visualAid.visualAidContainerResponse && 
      globalState.visualAid.visualAidContainerResponse.code && 
      globalState.visualAid.visualAidContainerResponse.content){
        if(globalState.visualAid.visualAidContainerResponse.content.length > 0){
          globalState.visualAid.visualAidContainerResponse.content.map(item => {
            item.source = item.url;
          })
        }
        setImages(globalState.visualAid.visualAidContainerResponse.content);
        setInitialState(globalState.visualAid.visualAidContainerResponse.content);
        setDelayed(false);
    }
  }, [globalState.visualAid.visualAidContainerResponse.content])

  useEffect(() => {
    if(globalState.visualAid.editVaidResult && globalState.visualAid.editVaidResult === true){
      history.push("/visualaids");
    }
  }, [globalState.visualAid.editVaidResult])

  const handleFileChosen = (file) => {
    if(isCreateDisabled){
      return;
    }

    if(file){
      setFileSelected(file);
    }
  };

  const handleOnAlert = (msg, type) => {
    if(type == 'success'){
      showToastImportantNotification(intl.formatMessage({id: "VAID.DROPZONE.ALERT.SUCCESS"}), 'green');
    }else if(type == 'error'){
      showToastImportantNotification(intl.formatMessage({id: "VAID.DROPZONE.ALERT_TYPE_SIZE.ERROR"}), 'red');
    } 
  };

  const uploadFileProgress = (progress) => {
    if(!fileSelected[0] || fileSelected[0].size <= 0){
      setUploadProgress(-1);
      return;
    }

    let fileSize = fileSelected[0].size;

    setUploadProgress(Math.round(progress.loadedBytes * 100 / fileSize));
  };

  const uploudFile = async (files) => {
    if(!files || files.length <= 0 || !files[0] || files[0].size <= 0){
      return;
    }

    let fileUrl = globalState.visualAid.visualAidContainerResponse.upload_Url.replace('{FileName}', '');

    const blobServiceClient = new BlobServiceClient(fileUrl);
  
    const containerClient = blobServiceClient.getContainerClient('');
    
    const blobName = typeSelected.key == 'video' ? 'video.mp4' : globalState.visualAid.visualAidContainerResponse.code + '.zip';
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    let payload = { onProgress: uploadFileProgress };
    if(typeSelected.key == 'video'){
      payload = {
        onProgress: uploadFileProgress, 
        blobHTTPHeaders: {
          blobContentType: "video/mp4"
        }
      }
    }

    // Retorno el promise del upload, para que al momento de guardar el vaid, se espere a que este subido antes de volver al listado de presentaciones
    return blockBlobClient.upload(files[0], files[0].size, payload);
  };

  const customRequest = ({
    uid,
    file,
    data, // blob
    send,
    action,
    headers,
    onProgress,
    onSuccess,
    onError
  }) => {
    const form = new FormData();

    // send file 
    form.append('file', file)

    let fileUrl = globalState.visualAid.visualAidContainerResponse.upload_Url.replace('{FileName}', file.name);
    const response = { url:  fileUrl}

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let newAxiosInstance = axios.create();
    const options = {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': file.type
      },
      onUploadProgress: ({ total, loaded }) => {
        onProgress(uid, Math.round(loaded / total * 100));
      },
      cancelToken: source.token
    };
  
    let alertFlag = false;
    images.forEach((image, index) => {
      if(file.name === image.fileName){
        alertFlag = true;
      }
    });

    let duplicatedClientFile = intl.formatMessage({
      id: "VAID.CREATE.DUPLICATED_CLIENT_FILE.ERROR"
    });
    if(alertFlag){
      alert(duplicatedClientFile)
      onError(uid, {
        action,
        status: 0,
        response: 'duplicated'
    })
      //quitar el archivo de la grilla
    }else{
      newAxiosInstance.put(
        fileUrl, 
        file,
        options  
      ).then((response) => {
        onSuccess(uid, {source:fileUrl});
      })
      .catch(error => {
        let duplicatedFileServer = intl.formatMessage({
          id: "VAID.CREATE.DUPLICATED_SERVER_FILE.ERROR"
        });
          alert(duplicatedFileServer)
          onError(uid, {
              action,
              status: error.request,
              response: error.response
          })
      })
    }

    return {
      abort() {
          console.log('abort')
      }
    }
  }

  const showWarning = (type, rules) => {
    switch(type) {
      case 'accept':
        console.log(`Only ${rules.accept.join(', ')}`)
        
      case 'limit':
        console.log('limit <= ', rules.limit)
        
      case 'size':
        console.log('max size <= ', rules.size)
        const sizeMsg = intl.formatMessage({
          id: "VAID.UPLOAD_GALLERY.MAX_SIZE"
        })
        if(!alertOpen){
          setAlertOpen(false);
          showToastImportantNotification(sizeMsg, 'green');
        }
        
      case 'minWidth': case 'minHeight':
        console.log('Dimensions > ', `${rules.width.min}x${rules.height.min}`)
        
      case 'maxWidth': case 'maxHeight':
        console.log('Dimensions < ', `${rules.width.max}x${rules.height.max}`)
        
      default:
    }
  }

  const handleChange = (images) => {
    setImages(images);
  }

  const saveMetaData = async() => {
    setIsCreateDisabled(true);

    if((typeSelected.key == 'sdva' || typeSelected.key == 'html5animate' || typeSelected.key == 'video') && fileSelected != null){
      await uploudFile(fileSelected);
    }

    await props.saveMetaDataVisualAid(images);    
  }

  const backToVaidList = () => {
    let messageAlert = intl.formatMessage({
      id: "VAID.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      props.fillResultVisualAidContainer({});
      history.push("/visualaids");
    }
  }

  const deleteMessage = intl.formatMessage({
    id: "VAID.CREATE.GALLERY.CONFIRM.DELETE"
  });

  const handlePdfImportImplementation = (files) => {
    setSelectedSlide({});
    setPdfDialogOpen(true);
    setPdfName(files.name.replace(/[^a-zA-Z0-9-_\.]/g, ''));
    setLoadingSpinner(true);
    //Step 2: Read the file using file reader
    var fileReader = new FileReader();  

    fileReader.onload = async function() {

        //Step 4:turn array buffer into typed array
        var typedarray = new Uint8Array(this.result);

        const pdfjsLib = window.pdfjsLib;
        const pdfjsWorker = window.pdfjsWorker;
        pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
        //Step 5:PDFJS should be able to read this
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        var loadingTask = pdfjsLib.getDocument({data: typedarray});
        loadingTask.promise.then(async (pdf) => {
          //console.log('pdf', pdf._pdfInfo.numPages);

          let arrPagesTemp = [];
          for(let i = 0; i <= pdf._pdfInfo.numPages - 1; i++){
            arrPagesTemp.push(i);
          }
          setCanvasArr(arrPagesTemp);
          let selectedSlidesAux = {};
          for(let i = 1; i <= pdf._pdfInfo.numPages; i++){
            const page = await pdf.getPage(i);
            selectedSlidesAux[i] = i;
            const viewport = page.getViewport({scale: 1});
            
            const desiredWidth = 100;
            const scale = desiredWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale, });

            if(document.querySelector(`#renderPdfCanvas${i}`)){
                // holds viewport properties where page will be rendered
                let ctxScaled = document.querySelector(`#renderPdfCanvas${i}`).getContext('2d');
                let ctxOriginal = document.querySelector(`#renderPdfCanvasOriginal${i}`).getContext('2d');
                ctxOriginal.canvas.width = viewport.width;
                ctxOriginal.canvas.height = viewport.height;
                const render_context = {
                  canvasContext: ctxScaled,
                  viewport: scaledViewport
                };
                const render_context_original_size = {
                  canvasContext: ctxOriginal,
                  viewport: viewport
                };
  
                // wait for the page to render
                await page.render(render_context_original_size);
                await page.render(render_context);
            }
            
          }
          setSelectedSlide(selectedSlidesAux);
          setLoadingSpinner(false);
        }); 
    };
    //Step 3:Read the file as ArrayBuffer
    fileReader.readAsArrayBuffer(files);
  }

  const handlePdfImport = async (event) => {
    const files = Array.from(event.target.files)[0];
    if(pdfDependencyLoaded === true){
      handlePdfImportImplementation(files);
    }else{
      setTimeout((files) => {
        handlePdfImportImplementation(files);
      }, 5000, files);
    }
    
  }

  const handleAcceptSlides = () => {
    let arrFiles = [];
    for(let i in selectedSlide){
      let img = document.getElementById(`renderPdfCanvasOriginal${i}`).toDataURL();
      let pageNum = (i <= 9) ? '0' + i : i;
      let pdfNameEscaped = escape(pdfName.split('.')[0].split(' ').join(''))
      arrFiles.push(dataURLtoFile(img, `${pdfNameEscaped}_page_${pageNum}.jpg`));
    }  
    window.rugComponent.uploadFiles(arrFiles);
    setPdfDialogOpen(false);
    refInput.current.value = null;
  }

  const dataURLtoFile = (dataurl, filename) => {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  const selectSlide = (index) => event => {
    if(selectedSlide[index]){
      let newObj = selectedSlide;
      delete newObj[index];
      setSelectedSlide({...newObj});
    }else{
      setSelectedSlide({...selectedSlide, [index]: index});
    }
  }

  const inputPdfClose = () => {
    setPdfDialogOpen(false);
    refInput.current.value = null;
  }

  const pdfDialogSelectAll = () => {
    let auxSelectedSlides = {};
    for(let i = 1; i <= canvasArr.length; i++){
      auxSelectedSlides[i] = i;
    }
    setSelectedSlide(auxSelectedSlides);
  }

  const pdfDialogClearSelection = () => {
    setSelectedSlide({});
  }

  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {uploadProgress > 0 && <CircularProgressWithLabel size={68} value={uploadProgress} />}

        {(globalState.visualAid.loading) && <LinearProgress className="progressBar" />}
        {(globalState.visualAid.loading) && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="VAID.EDIT.PAGE.TITLE" /></h3>
          </div>

          <div>
          <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backVisualAid" onClick={backToVaidList}>
            <ArrowBackIcon />
            <FormattedMessage id="VAID.BACK_BUTTON" />
          </Button>
          <Button size="medium" className="btn btn-sm btn-label-success" id="saveVisualAid" onClick={saveMetaData} disabled={isCreateDisabled}>
            <SaveIcon />
            <FormattedMessage id="VAID.EDIT.PAGE.SAVE.BUTTON" />
          </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>

              {(typeSelected.key == 'sdva' || typeSelected.key == 'html5animate' || typeSelected.key == 'video') && uploadProgress > 0 &&
                <div className="note warning">
                  <FormattedMessage id="VAID.CREATE.ALERT.FILE_UPLOADING" />
                </div>
              }

              <div>
                <HeaderVisualAid type={typeSelected} />
              </div>

              <hr/>

              {!delayed && (typeSelected.key == 'sdva' || typeSelected.key == 'html5animate') &&
                <div>
                  <DropzoneArea onChange={handleFileChosen} onAlert={handleOnAlert}
                    acceptedFiles={[".zip"]} filesLimit={1} showAlerts={false} showFileNames={true} maxFileSize={50 * 1000000} dropzoneText={intl.formatMessage({id: "VAID.DROPZONE.TEXT"})}/>
                </div>
              }

              {!delayed && (typeSelected.key == 'video') &&
                <div>
                  <DropzoneArea onChange={handleFileChosen} onAlert={handleOnAlert}
                    acceptedFiles={[".mp4"]} filesLimit={1} showAlerts={false} showFileNames={true} maxFileSize={500 * 1000000} dropzoneText={intl.formatMessage({id: "VAID.DROPZONE.TEXT"})}/>
                </div>
              }

              {!delayed && typeSelected.key == 'slide' &&
              <>

              <Button
                variant="contained"
                component="label"
              >
                <input
                  ref={refInput}
                  type="file"
                  accept="application/pdf"
                  onChange={handlePdfImport}
                  className="custom-file-input-import"
                />
                <FormattedMessage id="VAID.EDIT.PAGE.PDF.BUTTON" />
              </Button>
              <VaidCanvasDialog 
                open={pdfDialogOpen} 
                setPdfDialogOpen={setPdfDialogOpen} 
                inputPdfClose={inputPdfClose}
                handleAcceptSlides={handleAcceptSlides}
                total={canvasArr.length}
                selected={Object.keys(selectedSlide).length}
                fileName={pdfName}
                loadingSpinner={loadingSpinner}
                selectAll={pdfDialogSelectAll}
                clearSelection={pdfDialogClearSelection}
              >
                {
                  canvasArr.map((item, index) => {
                    return(
                      <>
                      <div className="thumb-canvas-container">
                        <canvas 
                          className={`thumb-canvas ${(selectedSlide[index+1]) ? 'canvas-selected' : ''}`} 
                          id={`renderPdfCanvas${index+1}`} 
                          width="100px" 
                          height="100px" 
                          onClick={selectSlide(index+1)} 
                          key={index+1}></canvas>
                        {selectedSlide[index+1] &&
                        <CheckIcon fontSize="large" className="checkIcon" onClick={selectSlide(index+1)} key={`check-${index+1}`}/>
                        }
                      </div>
                      </>
                    )
                  })
                }   
                {
                  canvasArr.map((item, index) => {
                    return(
                      <canvas id={`renderPdfCanvasOriginal${index+1}`} style={{display:'none'}} key={index+1}></canvas>
                    )
                  })
                }             
              </VaidCanvasDialog>

              <Divider style={{margin: '30px 0'}} />
              <RUG
                ref={(element) => {window.rugComponent = element}}

                inOrder={true}
                header={({ openDialogue, uploadFiles }) => (
                  <DropArea>
                  {
                    (isDrag) => (
                    <div 
                      className="rug-handle"
                      style={{ 
                        display: 'flex',
                        background: isDrag ? '#f6ffff' : '#fff',
                        height: 260,
                        borderRadius: 5,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{
                        textAlign: 'center'
                      }}>
                        <UploadIcon />
                        <div><FormattedMessage id="VAID.DROP_CONTAINER.TEXT_ONE" /></div>
                        <div><FormattedMessage id="VAID.DROP_CONTAINER.CONNECTOR" /></div>
                        <Button size="medium" className="btn btn-sm btn-label-success"  
                          onClick={openDialogue}
                          style={{
                            height: 40
                          }}
                        >
                          <FormattedMessage id="VAID.DROP_CONTAINER.BUTTON" />
                        </Button>
                      </div>
                    </div>
                    )
                  }
                  </DropArea>
                )}
                onConfirmDelete={(imageDeleted) => {
                  if(imageDeleted.uploading === true || imageDeleted.error === true){
                    return true;
                  }else{
                    return window.confirm(deleteMessage)
                  }
                }}
                customRequest={customRequest}
                initialState={initialState}
                source={response => response.source} // response image source
                accept={['jpg', 'jpeg', 'png']}
                rules={{
                  limit: 100,
                  size: 5000
                }}
                onError={(response) => { 
                  response.image.remove() 
                }}
                onWarning={(type, rules) => { 
                  showWarning(type, rules)
                }}
                onChange={handleChange}
                footer={({ 
                  images, 
                  accept, 
                  setSort, 
                  uploadFiles, 
                  openDialogue 
                }) => <div style={{marginTop: 30}}>
                  <span style={{ marginRight: 20 }}><FormattedMessage id="VAID.UPLOAD_GALLERY.FOOTER.QUANTITY" />:{ images.length }</span>
                  <span><FormattedMessage id="VAID.UPLOAD_GALLERY.FOOTER.EXTENSIONS" />: { accept.join(', ') }</span>
                </div>
              }
              ></RUG>
              </>
              }
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(VisualAidsCreatePage)
);