/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { FormattedMessage, injectIntl } from "react-intl";
import './UserProfile.scss';
import Button from '@material-ui/core/Button';
import * as authStorage from '../../store/ducks/auth.duck';
import { defaultStyles } from '../../../globalConfig';
import PasswordPopUp from '../../components/PasswordPopUp';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import UserProfileMenu from './UserProfileMenu';

const UserProfile = (props) => {
  var {
    auth,
    deployment,
    showHi,
    showAvatar,
    lobbyState,
    organizer,
    history,
    nullAuthToken,
    passwordResult
  } = props;

  return (
    <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">

          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {deployment.name}
            </span>
          )}

          {showAvatar && <img alt="Pic" src={deployment.parameters[0].value} />}

        </div>
      </Dropdown.Toggle>      
      {!lobbyState ?
        <UserProfileMenu auth={auth} deployment={deployment} lobbyState={lobbyState} />
        :
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
            <div className="kt-notification__custom">
              <Link
                id="btnSignOut"
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
                style={{
                  backgroundColor: defaultStyles.accent_color
                }}
              >
                <CloseIcon />
                <FormattedMessage id="AUTH.LOGOUT" />
              </Link>
            </div>
          </div>
          {!lobbyState &&
            <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
              <div className="kt-notification__custom">
                <PasswordPopUp accentColor={defaultStyles.accent_color} />
              </div>
            </div>
          }

        </Dropdown.Menu>}
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deployment: state.auth.deployment,
    lobbyState: state.presentationBar.lobbyState,
    organizer: (state.auth.role === 'organizer')
  }
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...authStorage.actions }
  )(UserProfile)
);
