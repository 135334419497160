import React from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Button from '@material-ui/core/Button';
import { isMobileOnly, isIPhone13, isAndroid } from 'react-device-detect';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';

import * as loggerStorage from "../../store/ducks/logger.duck";

const FileWhatsAppButton = (props) => {
  
  const { intl } = props;
  const globalState = useSelector(state => state);
  let fileUrl = (props.item) ? props.item.url : null;
  let fileName = (props.item) ? props.item.name : null;
  let repName = globalState.auth.deployment.callData.organizerName;
  let style = { marginLeft: 1 };

  let msg = intl.formatMessage({ id: "WHATSAPP.MESSAGE_FILE" }, {
    repname: repName,
    fileurl: encodeURI(encodeURI(fileUrl)),
    filename: fileName
  });
  
  let whatsAppMessageParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'WHATSAPP.MESSAGE_FILE');
  if(whatsAppMessageParam && whatsAppMessageParam != ''){
    whatsAppMessageParam = whatsAppMessageParam.replace('{fileurl}' ,encodeURI(encodeURI(fileUrl)));
    whatsAppMessageParam = whatsAppMessageParam.replace('{filename}' ,fileName);
    whatsAppMessageParam = whatsAppMessageParam.replace('{repname}' ,repName);
    msg = whatsAppMessageParam;
  }
  
  //MANTIS 32050: no funciona el link de compartir de whatsapp
  //let whatsappLink = 'https://wa.me/?text=' + msg;  
  //este es el link universal, pero cuando es web pide 2 clicks mas al user
  
  let whatsappLink = 'https://web.whatsapp.com/send?text=' + msg;
  if (lowerThanTablet || isAndroid) //en el ipad14 la variable isIphone13 da true, entonces solo se puede preguntar por el tamaño de pantalla
  {
    whatsappLink = 'whatsapp://send?text=' + msg;
  }

  const handleClick = () => {
    window.open(whatsappLink, '_blank');
    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'FILE_SHARED',
      log_text: 
       `callcode: ${globalState.auth.callCode} |
        userId: ${globalState.auth.userid} |
        subscriberId: ${globalState.auth.deployment.callData.subscriberId} |
        filecode: ${props.item.code} |
        type: whatsapp`
    })
  }

  return(
    <>
    <div id="btnWhatsApp" onClick={handleClick}>
      <WhatsAppIcon  />
      <span>&nbsp;</span>
      WhatsApp
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    { ...loggerStorage.actions }
  )(FileWhatsAppButton)
);
