import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './CallActionEditMenu.scss';
import ScheduleModifierPopUp from '../ScheduleModifierPopUp';
import ScheduleInactivateCallPopUp from '../ScheduleInactivateCallPopUp';
import ScheduleCloseCallPopUp from '../ScheduleCloseCallPopUp';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinkIcon from '@material-ui/icons/Link';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { FormattedMessage, injectIntl } from "react-intl";
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';

const CallActionEditMenu = (props) => {
  const [menuEl, setMenuEl] = React.useState(null);

  const handleClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '.MuiListItemIcon-root': {
        color: '#0000008a !important'
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white !important',
        },
      },
    },
  }))(MenuItem);


  return (
    <div style={{display: 'inline', margin: '0 0'}}>
      <Button 
        aria-controls="call-action-edit-menu" 
        aria-haspopup="true" 
        onClick={handleClick} 
        color="primary"
        id="callEditMenu"
        className="btn btn-sm btn-label-warning"
      >
        <EditAttributesIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="DASHBOARD.MENU.EDIT" />
      </Button>
      <StyledMenu
        id="call-action-edit-menu"
        anchorEl={menuEl}
        keepMounted
        open={Boolean(menuEl)}
        onClose={handleClose}
      >
        {props.item.type != 2?
        <StyledMenuItem className="menuItem__buttons">
          <ListItemIcon>
            <ScheduleIcon fontSize="small" />
          </ListItemIcon>
          <ScheduleModifierPopUp item={props.item} reactors={[menuEl, setMenuEl]} />
        </StyledMenuItem>: null}
        <StyledMenuItem className="menuItem__buttons">
          <ListItemIcon>
            <BlockIcon fontSize="small" />
          </ListItemIcon>
          <ScheduleCloseCallPopUp item={props.item} reactors={[menuEl, setMenuEl]} />
        </StyledMenuItem>
        <StyledMenuItem className="menuItem__buttons">
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ScheduleInactivateCallPopUp item={props.item} reactors={[menuEl, setMenuEl]} />
        </StyledMenuItem>
          {/*<MenuItem onClick={handleClose}>Inativar</MenuItem>*/}
          {/*<MenuItem onClick={handleClose}>Encerrar</MenuItem>*/}
      </StyledMenu>
    </div>
  );
}

export default injectIntl(CallActionEditMenu);