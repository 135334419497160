import * as utils from "../../_metronic/utils/utils";

const localStorageLastLocationKey = "demo1-lastLocation";

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== "/" &&
    lastLocation.pathname.indexOf("auth") === -1 &&
    lastLocation.pathname !== "/logout"
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = localStorateLocations
    ? JSON.parse(localStorateLocations)
    : [];

  if (acceptLocation(lastLocation)) {
    _useLocations.push(lastLocation.pathname);
    try{
      utils.setStorage(
        localStorageLastLocationKey,
        JSON.stringify(_useLocations),
        120
      );
    }catch(e){
      console.log(e)
    }
    
  }
}

export function forgotLastLocation() {
  try{
    utils.removeStorage(localStorageLastLocationKey);
  }catch(e){
    console.log(e)
  }
}

export function getLastLocation() {
  let localStorateLocations=null;
  try{
    localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  }catch(e){
    console.log(e);
  }
  if (!localStorateLocations) {
    return "/";
  }

  const _userLocations = JSON.parse(localStorateLocations);
  const result = _userLocations.length > 0 ? _userLocations.pop() : "/";
  return result;
}
