import React, { useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from "react-router-dom";

import * as customerGroupsStorage from "../../store/ducks/customerGroups.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const CreateCustomerGroupsButton = (props) => {

  const globalState = useSelector(state => state);
  const classes = useStyles();
  const history = useHistory();
  const [nameText, setNameText] = React.useState('');
  const [externalCodeText, setExternalCodeText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const {intl} = props;

  useEffect(() => {
    if(globalState.customerGroups.createCustomerGroupResponse && globalState.customerGroups.createCustomerGroupResponse.uuid){
      setOpen(false);
    }
  }, [globalState.customerGroups.createCustomerGroupResponse])
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setNameText('');
    setExternalCodeText('');
  }
  
  const handleChangeNameText = (event) => {
    setNameText(event.target.value);
  }

  const handleChangeExternalCodeText = (event) => {
    setExternalCodeText(event.target.value);
  }

  const handleAccept = () => {
    props.createCustomerGroup({
      name: nameText,
      externalCode: externalCodeText
    });
  }

  return(
    <>
      <Button className="btn btn-sm btn-label-success" id="btnCreateCustomerGroup" onClick={handleOpen}>
        <AddCircleIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="CUSTOMERGROUPS.CREATE_BUTTON" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { }}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="CUSTOMERGROUPS.POPUP.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className="popup__input__container">
              <FormControl className={classes.formControl} >
                
                <TextField
                  id="customerroups-change-name"
                  label={intl.formatMessage({
                    id: "CUSTOMERGROUPS.NAME"
                  })}
                  type="text"
                  inputProps={{ maxLength: 100}}
                  value={nameText}
                  inputProps={{ maxLength: 100}}
                  onChange={handleChangeNameText}
                  fullWidth
                />
                <TextField
                  id="customergroups-change-external-code"
                  label={intl.formatMessage({
                    id: "CUSTOMERGROUPS.EXTERNAL_CODE"
                  })}
                  type="text"
                  value={externalCodeText}
                  onChange={handleChangeExternalCodeText}
                  fullWidth
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnNewCustomerGroup" onClick={handleAccept}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnCustomerGroupClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    customerGroupsStorage.actions
  )(CreateCustomerGroupsButton)
);