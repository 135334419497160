import React from "react";
import './UploadIcon.scss'

export default () => {
  return (
    <svg viewBox="0 -5 32 52" class="rug-handle-icon">
      <g>
        <polyline points="1 19 1 31 31 31 31 19" />
        <polyline className="__arrow" points="8 9 16 1 24 9" />
        <line class="__arrow" x1="16" x2="16" y1="1" y2="25" />
      </g>
    </svg>
  );
};