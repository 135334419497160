/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import LinkCopyClientButton from '../LinkCopyClientButton';
import SendEmailButton from '../SendEmailButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CallIcon from '@material-ui/icons/Call';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleModifierPopUp from '../ScheduleModifierPopUp';
import UserSelector from '../Guest/UserSelector';
import { isMobile } from 'react-device-detect';
import WhatsAppButton from '../WhatsAppButton';
import CallActionEditMenu from '../CallActionEditMenu';
import CallActionShareMenu from '../CallActionShareMenu';
import { defaultStyles } from '../../../globalConfig';
import * as userStorage from "../../modules/Users/users.duck";
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as authStorage from "../../store/ducks/auth.duck";
import { getDeploymentParamValue } from '../../../_metronic/utils/utils';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    marginTop: 10,
    width: 400,
    height: 310
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const UserGroupsActionsPopUp = (props) => {
  const [open, setOpen] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);
  const { intl } = props;
  const globalState = useSelector(state => state);

  const showPopupModifyCall = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #avisoModifyCall {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "avisoModifyCall");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(avisoModifyCall) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(avisoModifyCall)){for (var i = 0; i < avisoModifyCall.length; i++) {document.body.removeChild(avisoModifyCall[i])}}else{document.body.removeChild(avisoModifyCall)}}", 5000);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleMenuClose();
  };

  const handleSave = (item) => event => {
    props.setLoading(true);

    handleClose();
  };

  const classes = useStyles();

  setOpen(props.open);
  return (
    <div style={{ "display": "inline-block" }}>
      <Dialog
        open={props.open}
        onClose={props.handleMenuClose}
        id="popup"
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="CALL.DETAILS.SCHEDULE" />
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="CALL.DETAILS.CODE" />: </span><span className={classes.data}>{props.item.name}</span>
            </div>

            <div className={classes.inRow}>
              {/*<UserSelector intl={props.intl} item={props.item} />*/}

            </div>
            <div className={classes.mtop20}>
              <Button size="small" style={{ marginLeft: '10%', marginRight: '10%', marginTop: '1%', backgroundColor: defaultStyles.accent_color }} className="btn btn-sm btn-label-brand" id="btnAgendaEnterCall">
                <CallIcon />
                <span>&nbsp;</span>
                <FormattedMessage id="CALL.DETAILS.BUTTON.SIGN_IN" />
              </Button>

            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={handleClose}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
          <Button className={classes.button} id="btnCallDetailsSave" onClick={handleSave(props.item)}>
              <FormattedMessage id="CALL.DETAILS.BUTTON.SAVE" />
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...userStorage.actions, ...authStorage.actions, ...scheduleStorage.actions, ...presentationBarStorage.actions }
  )(UserGroupsActionsPopUp)
);