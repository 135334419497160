import React, { useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import * as userGroupsStorage from "../../store/ducks/userGroups.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateUserGroupsButton = (props) => {

  const globalState = useSelector(state => state);
  const classes = useStyles();
  const history = useHistory();
  const [nameText, setNameText] = React.useState('');
  const [externalCodeText, setExternalCodeText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [allowGuest, setAllowGuest] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [userGroupSelected, setUserGroupSelected] = React.useState(null);
  const [listSelected, setListSelected] = React.useState([]);
  const [atributesList,setAtributesList] = React.useState([]);
  const [error, setError] = React.useState({
    name: false,
    externalCode : false
  });
  const {intl} = props;

  useEffect(() => {
    props.getUserGroupAtribute();
  }, []);

  useEffect(() => {
    if (globalState.userGroups.userGroupAtributeList.length > 0) {
      setAtributesList(globalState.userGroups.userGroupAtributeList);
    }
  }, [globalState.userGroups.userGroupAtributeList])

  useEffect(() => {
    if(globalState.userGroups.createUserGroupResponse && globalState.userGroups.createUserGroupResponse.uuid){
      // una vez creamos el grupo reinicializamos las variables del popup
      setNameText('');
      setExternalCodeText('');
      setAllowGuest(false);
      setListSelected([]);
      setOpen(false);
    }
  }, [globalState.userGroups.createUserGroupResponse])
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setNameText('');
    setExternalCodeText('');
  }

  const handleChangeAllowGuest = (event) => {
    setAllowGuest(event.target.checked ? 1:0);
  };
  
  const handleChangeNameText = (event) => {
    setNameText(event.target.value);
  }

  const handleChangeExternalCodeText = (event) => {
    setExternalCodeText(event.target.value);
  }

  const validate = () => {
    if(nameText.length <= 0){
      setError({name: true});
      return false;
    }else{
      setError({name: false});
    } 

    if(externalCodeText.length <= 0){
      setError({externalCode: true});
      return false;
    }else{
      setError({externalCode: false});
    }
    return true;
  }

  const handleAccept = () => {
    if(validate()){
      props.createUserGroup({
        name: nameText,
        externalCode: externalCodeText,
        allowGuest: allowGuest,
        atributeIndex: 1, // siempre con valor 1
        atributeList: listSelected
      });
    }
    //console.log(listSelected);
  }

  /*const doRedirection = (code) => {
    history.push("/visualaid/slide/update/" + code);
  }*/

  return(
    <>
      <Button className="btn btn-sm btn-label-success" id="btnCreateVisualAid" onClick={handleOpen}>
        <AddCircleIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="USERGROUPS.CREATE_BUTTON" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { }}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="USERGROUPS.POPUP.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className="popup__input__container">
              <FormControl className={classes.formControl} >
                <FormGroup>
                  <TextField
                  id="usergroups-change-name"
                  label={intl.formatMessage({
                    id: "USERGROUPS.NAME"
                  })}
                  type="text"
                  inputProps={{ maxLength: 100}}
                  value={nameText}
                  error={error.name}
                  onChange={handleChangeNameText}
                  fullWidth
                />
                <TextField
                  id="usergroups-change-external-code"
                  label={intl.formatMessage({
                    id: "USERGROUPS.EXTERNAL_CODE"
                  })}
                  type="text"
                  value={externalCodeText}
                  error={error.externalCode}
                  onChange={handleChangeExternalCodeText}
                  fullWidth
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleChangeAllowGuest} name="allowGuest" />}
                  label={intl.formatMessage({
                    id: "USERGROUPS.ENABLE_GUEST"
                  })}
                />
                <span className={classes.data}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={atributesList}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    onChange={(event, newValue) => {
                      setListSelected(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Seleccione"
                        //placeholder="Especialidad"
                      />
                    )}
                  />
                </span>
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnNewVisitCreate" onClick={handleAccept}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnNewVisitClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    userGroupsStorage.actions
  )(CreateUserGroupsButton)
);