import React, { useMemo, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from "react-router-dom";
import ImportCustomers from '../../components/ImportCustomers';

import * as importCustomersStorage from "../../store/ducks/importCustomers.duck";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ImportCustomersPage = (props) => {
  const globalState = useSelector(state => state);
  const history = useHistory();

  const handleImportButtonClick = () => {
    history.push("/importlist/create/" + globalState.presentationBar.customerTypeSelected.code);
  }

  const handleCloseSnackBar = () => {
    props.resetResultUpload(null);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.importCustomers.loading && <LinearProgress className="progressBar" />}
      {globalState.importCustomers.loading && <div className="progressBackground"></div>}
      {globalState.importCustomers.resultUpload != null &&
      <Snackbar 
        open={(globalState.importCustomers.resultUpload !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.importCustomers.resultUpload) ? 'success' : 'error'}>
          {globalState.importCustomers.resultUpload ? 
            <FormattedMessage id="PROMOTIONAL_PAGE.CREATE.SUCCESS" />
            :
            <FormattedMessage id="PROMOTIONAL_PAGE.CREATE.ERROR" />
          }
        </Alert>
      </Snackbar>
      }
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <FormattedMessage id="IMPORT_CUSTOMERS.TITLE" />
          </h3>
        </div>
        <div>
          <Button 
            className="btn btn-sm btn-label-success" 
            id="btnImportCustomers" 
            onClick={handleImportButtonClick}
          >
            <AddCircleIcon />
            <span>&nbsp;</span>
            <FormattedMessage id="IMPORT_CUSTOMERS.CREATE_BUTTON" />
          </Button>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <ImportCustomers />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    importCustomersStorage.actions
  )(ImportCustomersPage)
);