import React from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { useHistory } from "react-router-dom";
import CustomerTypeSelector from '../../components/CustomerTypeSelector';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

import * as importCustomersStorage from "../../store/ducks/importCustomers.duck";
import ImportLogPopUp from '../ImportLogPopUp/ImportLogPopUp';

const useStyles = makeStyles((theme) => ({
  stateControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 10
  },
  planControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ImportCustomers = (props) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const history = useHistory();
  const classes = useStyles();
  const [stateSelected, setStateSelected] = React.useState(1);
  const [planSelected, setPlanSelected] = React.useState(-1);
  const [from, setFrom] = React.useState(moment(new Date).subtract(30, 'days').format('YYYY-MM-DD'));
  const [to, setTo] = React.useState(moment(new Date).format('YYYY-MM-DD'));
  const globalState = useSelector(state => state);
  const { intl } = props;

  useEffect(() => {
    if(globalState.presentationBar.customerTypeSelected &&
      globalState.presentationBar.customerTypeSelected.code){
        props.getImportList({from: from, to: to});
    }
  }, [globalState.presentationBar.customerTypeSelected])
  
  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setSelectedRow(row);
    setMenuAnchor(anchorElement);
    setMenuOpen(true);
  };

  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
  }

  const handleChange = (event) => {
    setStateSelected(event.target.value);
    //props.getPromotionalPlans(event.target.value);
  };

  const handleChangeFrom = (event) => {
    //console.log(event.target.value)
    setFrom(moment(event.target.value).format('YYYY-MM-DD'));
    props.getImportList({from: moment(event.target.value).format('YYYY-MM-DD'), to: to});
  }

  const handleChangeTo = (event) => {
    //console.log(event.target.value)
    setTo(moment(event.target.value).format('YYYY-MM-DD'));
    props.getImportList({from: from, to: moment(event.target.value).format('YYYY-MM-DD')});
  }

  let actionsColumnIndex = 2;
  let columns = [
    { title: intl.formatMessage({ id: "IMPORT_CUSTOMERS.COLUMN.CREATION_DATE" }), field: 'createdAtUTC', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "IMPORT_CUSTOMERS.COLUMN.NAME" }), field: 'original_Name', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "IMPORT_CUSTOMERS.COLUMN.SIZE" }), field: 'size', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "IMPORT_CUSTOMERS.COLUMN.STATUS" }), field: 'statusText', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "IMPORT_CUSTOMERS.COLUMN.LAST_EXECUTION_DATE" }), field: 'lastExecutedAtUTC', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} }
  ];

  const dateTimeFrom = intl.formatMessage({ id: "IMPORT_CUSTOMERS.SELECT.FROM" });
  const dateTimeTo = intl.formatMessage({ id: "IMPORT_CUSTOMERS.SELECT.TO" });

  globalState.importCustomers.importList.map(item => {
    switch(item.status){
      case 1:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_1" });
        break;
      case 2:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_2" });
        break;
      case 3:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_3" });
        break;
      case 4:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_4" });
        break;
      case 8:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_8" });
        break;
      case 9:
        item.statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_9" });
        break;
      default:
        break;
    }
  })

  return(
    <div id="promotionalPlansList" style={{ width: '100%', boxShadow: 'none' }}>
      <CustomerTypeSelector />
      <TextField
        id="datetime-local"
        label={dateTimeFrom}
        type="date"
        value={from}
        onChange={handleChangeFrom}
        style={{margin: 8}}
        />
      <TextField
        id="datetime-local2"
        label={dateTimeTo}
        type="date"
        value={to}
        onChange={handleChangeTo}
        style={{margin: 8}}
      />
      
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.importCustomers.importList}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left', 
            headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
            actionsColumnIndex: -1
          }
        }
        actions={[
          rowData => ({
            icon: 'edit',
            tooltip: intl.formatMessage({
              id: "DASHBOARD.MENU.EDIT"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            },
            disabled: (rowData.status !== 1 && rowData.status !== 2 && rowData.status !== 8 && rowData.status !== 9)
          })
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },

        }}
      />
      <Menu
        id="menu-visualaidactions"
        keepMounted
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {
          ( selectedRow && (selectedRow.status == 1 || selectedRow.status == 2 || selectedRow.status == 8 || selectedRow.status == 9))
          ?
            <ImportLogPopUp uuid={selectedRow.uuid}></ImportLogPopUp>
            :
            null
        }
      </Menu>
  </div>
  )
}

export default injectIntl(
  connect(
    null,
    importCustomersStorage.actions
  )(ImportCustomers)
);