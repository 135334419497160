export const truncate = (input) => input.length > 21 ? `${input.substring(0, 21)}...` : input;

const checkIfFileExists = function (url) {
    return new Promise((resolve, reject) => {
        fetch(url, { method: 'HEAD' })
        .then(response => {
        if (response.ok) {
            resolve(true);
        } else {
            resolve(false);
        }
        })
        .catch(error => {
        reject(error);
        });
    });
}

export const getPreviewFromConfig = async function(va) {
    let vaConfig;
    let preview = undefined;

    try {
        const response = await fetch(va.baseUrl + "/script.json");
        vaConfig = await response.json();

        if(vaConfig && vaConfig.art && vaConfig.art.precall_logo) {
            let url = va.baseUrl + "/" + vaConfig.art.precall_logo;
            const fileExists = await checkIfFileExists(url);

            if (fileExists) {
                preview = url;
                return preview;
            }
        }
    } catch (error) {
        console.log("Error: ", error);
    }

    return preview;
}