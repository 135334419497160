/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './NewVisitPopUp.scss';
import moment from 'moment';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CustomerSelector from '../CustomerSelector';
import UserSelector from '../Guest/UserSelector';
import { useHistory } from "react-router-dom";
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { defaultStyles } from '../../../globalConfig';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { pt, es, enUS } from 'date-fns/locale'
import VisitTypeSelector from "../VisitTypeSelector/VisitTypeSelector";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 350
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const showToastImportantNotification = (msg, backgroundColor) => {
  let css = document.createElement("style");
  let style = document.createTextNode(`
    #toastImportantNotification {
      position:fixed; z-index: 9999999; 
      widht: 120px; 
      top:15%;
      right:1%;
      margin-left: -60px;
      padding: 20px; 
      background: ${backgroundColor};
      border-radius: 8px;
      font-size: 14px;
      font-family: sans-serif;
      color: #ffffff;
      text-align: center;
    }
    .toast-white{
      color: #ffffff;
    }
    .toast-white:hover{
      color: #ffffff;
    }
  `);
  css.appendChild(style);
  document.head.appendChild(css);
  let advisement = document.createElement("div");
  advisement.setAttribute("id", "toastImportantNotification");

  let content = document.createTextNode(msg);
  advisement.appendChild(content);
  advisement.appendChild(document.createElement('br'));
  document.body.appendChild(advisement);

  window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);  //eslint-disable-line
}


const NewVisitPopUp = (props) => {
  const { intl } = props;
  const [open, setOpen] = React.useState(false);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);
  const [minTimeParam, setMinTimeParam] = React.useState({hour: 0, minutes: 0})
  const [maxTimeParam, setMaxTimeParam] = React.useState({hour: 0, minutes: 0})
  const [showTypeSelector, setShowTypeSelector] = React.useState(false);
  const [error, setError] = React.useState(false)
  const [values, setValues] = React.useState({
    date: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    open: false
  });

  const history = useHistory();
  const globalState = useSelector(state => state);
  const classes = useStyles();

  
  useEffect(() => {
    let minTime = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_CREATE_MIN_TIME')
    let maxTime = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_CREATE_MAX_TIME')
    const CALL_PRESENT_ENABLE = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_PRESENT_ENABLE');
    const showOfflineVisit = CALL_PRESENT_ENABLE === 1 || CALL_PRESENT_ENABLE === '1';
    
    setShowTypeSelector(showOfflineVisit);
    
    if(!minTime){
      minTime = '800';
    }
    if(!maxTime){
      maxTime = '2000';
    }

    const formatParam = (param) => {
      let formatedParam
      if(param.length === 3){
        formatedParam = '0' + param
      } else if (param.length === 4) {
        formatedParam = param
      } else {
        formatedParam = ''
        showToastImportantNotification(intl.formatMessage({ id: "CREATE.CALL.INVALIDPARAMS.ERROR" }), 'red')
        setError(true)
      }

      if(formatedParam) {
        const objTimeParam = {
          hour: parseInt(formatedParam.slice(0,2)),
          minutes: parseInt(formatedParam.slice(2,4))
        }
  
        return objTimeParam
      }  
    }

    if(minTime && minTime > 0 && minTime !== 2359) {
      const minTimeFormated = formatParam(minTime) ? formatParam(minTime) : 0

      if(minTimeFormated) {
        setMinTimeParam(minTimeFormated)
      }
    }

    if(maxTime && maxTime > 0 && maxTime !== 2359) {
      const maxTimeFormated = formatParam(maxTime) ? formatParam(maxTime) : 0

      if(maxTimeFormated) {
        setMaxTimeParam(maxTimeFormated)
      }
    }

    return (() => {
      setError(false)
      setMinTimeParam({ hour: 0, minutes: 0})
      setMaxTimeParam({ hour: 0, minutes: 0})
    })
  }, [globalState.auth.deployment.parameters])  //eslint-disable-line

  const validateTime = (moment) => {
    const LocalDate = moment
    if(minTimeParam) {
      if(LocalDate.hour() < minTimeParam.hour) {
        LocalDate.hour(minTimeParam.hour).minutes(minTimeParam.minutes)
        showToastImportantNotification(intl.formatMessage({ id: "CREATE.CALL.MINTIME.ERROR" }, {time: `${minTimeParam.hour}:${minTimeParam.minutes.toString().length === 1 ?  `0${minTimeParam.minutes.toString()}` : minTimeParam.minutes}`}), 'red')
      }
    }
    if(maxTimeParam){
      if (LocalDate.hour() > maxTimeParam.hour || (LocalDate.hour() === maxTimeParam.hour && LocalDate.minutes() > maxTimeParam.minutes)) {
        LocalDate.hour(maxTimeParam.hour).minutes(maxTimeParam.minutes)
        showToastImportantNotification(intl.formatMessage({ id: "CREATE.CALL.MAXTIME.ERROR" }, {time: `${maxTimeParam.hour}:${maxTimeParam.minutes.toString().length === 1 ?  `0${maxTimeParam.minutes.toString()}` : maxTimeParam.minutes}`}), 'red')
  
      }
    }
    
    return LocalDate
  }

  const handleChangeDate = date => event => {
    let dateToFormat = event
    if((minTimeParam.hour || minTimeParam.minutes) || (maxTimeParam.hour || maxTimeParam.minutes)) {
      dateToFormat = validateTime(event)
    }
    setValues({ ...values, [date]: moment(dateToFormat).format('YYYY-MM-DDTHH:mm') });
    //Object.assign(globalState.presentationBar.callNew, {...globalState.presentationBar.callNew, date : moment(event.target.value).format('YYYY-MM-DDTHH:mm')});
    //props.updateNewCall(globalState.presentationBar.callNew);
  };

  let locale = pt;

  let formatDatePicker = 'DD/MM/YYYY hh:mm ';
   
  let isAmPm = getDeploymentParamValue(globalState.auth.deployment.parameters, 'TIME_AMPM');
  

  if (globalState && globalState.auth.lang) {
    switch (globalState.auth.lang) {
      case "es":
        locale = es;
        break;
      case "en":
        locale = enUS;
        formatDatePicker = 'YYYY/MM/DD hh:mm ';
        break;
      default:
        break;
    }
  }
  formatDatePicker += (isAmPm == 1) ? 'a' : '';

  useEffect(() => {
    if(globalState.presentationBar.customerSelected.name){
      setIsDisabled(false);
    }
  }, [globalState.presentationBar.customerSelected]);
  

  const handleOpen = (start, view) => {
    if(!error) {
      setValues({
        ...values,
        [open]: true,
        name: (globalState.presentationBar.callNew.name !== '') ? globalState.presentationBar.callNew.name : '',
        email: (globalState.presentationBar.callNew.email !== '') ? globalState.presentationBar.callNew.email : '',
        date: view ? 
          moment(start).set(
              view === 'dayGridMonth' ?
              { hour: (new Date()).getHours(), minutes: (new Date()).getMinutes()} :
              start
            ).format('YYYY-MM-DDTHH:mm') : 
          moment(new Date()).format('YYYY-MM-DDTHH:mm')
      });
      setOpen(true);
      Object.assign(
        globalState.presentationBar.callNew,
        {
          ...globalState.presentationBar.callNew,
          open: true,
          name: (globalState.presentationBar.callNew.name !== '') ? globalState.presentationBar.callNew.name : '',
          email: (globalState.presentationBar.callNew.email !== '') ? globalState.presentationBar.callNew.email : '',
          error: false
        }
      );
      props.resetCallNew(false);
      props.updateNewCall(globalState.presentationBar.callNew)
      setOpen(globalState.presentationBar.callNew.open);
    }    
  };

  useImperativeHandle(props.innerRef, () => {
    return {
      handleOpen
    }
  })

  const handleRedirection = () => {
    history.push("/dashboard");
  }

  const handleClose = () => {
    setValues({ ...values, [open]: false, name: '', email: '', date: moment(new Date()).format('YYYY-MM-DDTHH:mm') });
    setOpen(false);
    setIsDisabled(true);
    Object.assign(globalState.presentationBar.callNew, { ...globalState.presentationBar.callNew, open: false, name: '', values: '' });
    props.updateNewCall(globalState.presentationBar.callNew)
    setOpen(globalState.presentationBar.callNew.open);
  };

  useEffect(() => {
    Object.assign(globalState.presentationBar.callNew.error, globalState.presentationBar.callNew.error);
  }, [globalState.presentationBar.callNew.error]);

  useEffect(() => {
    Object.assign(globalState.presentationBar.createCallErrorVisible, globalState.presentationBar.createCallErrorVisible);
    setTimeout(() => {
      Object.assign(globalState.presentationBar, { ...globalState.presentationBar, createCallErrorVisible: false });
      props.setCreateCallErrorVisible(false);
    }, 3000);
  }, [globalState.presentationBar.createCallErrorVisible]); //eslint-disable-line

  useEffect(() => {
    Object.assign(globalState.presentationBar.createCallSuccessVisible, globalState.presentationBar.createCallSuccessVisible);
    setTimeout(() => {
      Object.assign(globalState.presentationBar, { ...globalState.presentationBar, createCallSuccessVisible: false });
      props.setCreateCallSuccessVisible(false);
    }, 3000);
  }, [globalState.presentationBar.createCallSuccessVisible]);  //eslint-disable-line

  useEffect(() => {
    Object.assign(globalState.presentationBar.callCreated, globalState.presentationBar.callCreated);
  }, [globalState.presentationBar.callCreated]);

  useEffect(() => {
    setValues({ ...values });
  }, [props.rerender]);  //eslint-disable-line

  let dateTimeInputText = intl.formatMessage({
    id: "DASHBOARD.NEW.VISIT.DIALOG.ACTION.INPUT"
  });

  const callGuestQty = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_GUEST_QTY");

  return (
    <div>
      {globalState.presentationBar.createCallErrorVisible &&
        <div style={{
          position: 'fixed',
          top: '9%',
          right: '1%',
          backgroundColor: 'red',
          marginLeft: -60,
          padding: 20,
          borderRadius: 8,
          fontSize: 14,
          fontFamily: 'sans-serif',
          color: '#ffffff',
          textAlign: 'center'
        }}><FormattedMessage id="CREATE.CALL.ERROR" /></div>
      }
      {globalState.presentationBar.createCallSuccessVisible &&
        <div style={{
          position: 'fixed',
          top: '9%',
          right: '1%',
          backgroundColor: 'green',
          marginLeft: -60,
          padding: 20,
          borderRadius: 8,
          fontSize: 14,
          fontFamily: 'sans-serif',
          color: '#ffffff',
          textAlign: 'center'
        }}>
          <FormattedMessage id="CREATE.CALL.SUCCESS" /><br />
          <div>
            <FormattedMessage id="CREATE.CALL.CODE" />:<b>{globalState.presentationBar.callCreated.code}</b>
          </div>
          <div>
            <a onClick={handleRedirection} style={{ textDecoration: 'underline' }}><FormattedMessage id="CREATE.CALL.SUCCESS_REDIRECT" /></a>
          </div>
        </div>
      }

      {props.displayButton &&
        <Button className="btn btn-sm btn-label-success" id="btnNewVisitOpenPopUp" onClick={handleOpen}>
          <AddCircleIcon />
          <span>&nbsp;</span>
          <FormattedMessage id="DASHBOARD.NEW.VISIT.TITLE" />
        </Button>
      }
      <Dialog
        open={globalState.presentationBar.callNew.open}
        onClose={handleClose}
        onExit={() => { 
          setValues({ ...values, [open]: false, name: '', email: '', date: moment(new Date()).format('YYYY-MM-DDTHH:mm') });
          setIsDisabled(true) 
        }}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="DASHBOARD.NEW.VISIT.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            {globalState.presentationBar.callNew.error &&
              <div className={classes.textRed}>
                <FormattedMessage id="DASHBOARD.NEW.VISIT.ERROR" />
              </div>
            }
            <form className={(isMobile || isIPad13 || isIPhone13) ? classes.mobileContainer : classes.container}>
              <div className="popup__input__container">
              <MuiPickersUtilsProvider libInstance={moment} locale={globalState.auth.lang} utils={MomentUtils}>
                <Grid container justify="space-around" alignItems="flex-end">
                  <KeyboardDateTimePicker  
                    variant="inline"
                    margin="normal"
                    format={formatDatePicker}
                    ampm={Boolean(isAmPm == 1)}
                    id="date-picker-inline"
                    label={dateTimeInputText}
                    value={values.date}
                    onChange={handleChangeDate('date')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              
              </div>
              {
                showTypeSelector &&
                  <div className="popup__input__container">
                    <VisitTypeSelector />
                  </div>
              }
              <div className="popup__input__container">
                <CustomerSelector />
              </div>
              {(callGuestQty && callGuestQty == '1' && globalState.presentationBar.visitTypeSelected.code == 1 )  ? 
                <div className="popup__input__container">
                  <UserSelector intl={intl}/>
                </div>:null
              }
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnNewVisitCreate" onClick={props.handleSubmit(values.date, globalState.presentationBar.customerSelected)} disabled={isDisabled}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnNewVisitClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(NewVisitPopUp)
);