import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: 290,
    height: 160,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.3s', 
    "&:hover": {
      border: '3px solid #5d78ff'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    marginBottom: "10px"
  },
  pos: {
    marginBottom: 12
  },
  selected: {
    border: '3px solid #5d78ff',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.2)'
  },
  doneIcon: {
    position: 'absolute',
    top: '5px', 
    right: '5px', 
    color: "#5d78ff",
    fontSize: "28px"
  }
});

const VideoEffectsItem = ({title, icon, background, selected}) => {
  const classes = useStyles();

  let cardStyle = {};
  if (background) {
    cardStyle = { backgroundImage: `url(${background})` };
  }

  return (
    <Card 
      className={`${classes.root} ${selected ? classes.selected : ""}`} 
      style={cardStyle} 
      elevation={0}
    > 
      <CardContent>
        { icon && icon } 
        
        { 
          title &&
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        }
      </CardContent> 

      {
        selected &&
        <DoneIcon className={classes.doneIcon} />
      }
      
    </Card>
  );
};

export default VideoEffectsItem;
