import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './CircularProgressWithLabel.scss';

export default function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex" className="circularProgress circular-progress-custom">
          <CircularProgress variant="static" {...props} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center">

            <Typography variant="caption" component="div" color="textSecondary" className="progress-label">{`${props.value}%`}</Typography>
          </Box>
        </Box>
      );
}