import React, {useEffect, useState} from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from "react-intl";
import TagsInput from '../../../components/TagsInput';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import './EditTagsPopUp.scss';
import SelectFolderButton from './SelectFolderButton';
import { getDeploymentParamValue } from '../../../../_metronic/utils/utils';
import * as fileStorage from "../file.duck";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: 280
  },
  label: {
    fontSize: 16,
    width: '30%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  buttonInactivate: {
    '&:hover': {
      backgroundColor: 'rgba(253, 57, 122, 0.1)',
    },
    color: 'red'
  },
  rowContainer: {
    display: 'flex',
    gap: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCheck: {
    fontSize: 16,
    fontWeight: 'lighter'
  }

}));

const EditTagsPopUp = (props) => {
  const globalState = useSelector(state => state);
  const [arrTags, setArrTags] = React.useState([]);
  const [rawArrTags, setRawArrTags] = React.useState([]);
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [order, setOrder] = useState(0);
  const [fileResultKeeper, setFileResultKeeper] = useState(null);
  const [openSnackError, setOpenSnackError] = useState(false);
  const [allowShare, setAllowShare] = useState(0);
  const [allowUserShare, setAllowUserShare] = useState(0);
  const [automaticDownload, setAutomaticDownload] = useState(0);
  const [allowPresent, setAllowPresent] = useState(0);
  const [onlineVisit, setOnlineVisit] = useState(0);
  const [offlineVisit, setOfflineVisit] = useState(0);
  const classes = useStyles();
  const [folderSelector, setFolderSelector] = useState(false);
  const [folder, setFolder] = useState('');
  const [codFolder, setCodFolder] = useState(-1);
  const [planName, setPlanName] = useState([]);
  
  const CALL_PRESENT_ENABLE = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_PRESENT_ENABLE');
  const showOfflineVisit = CALL_PRESENT_ENABLE === 1 || CALL_PRESENT_ENABLE === '1';
  const defaultCallType = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_DEFAULT_CALLTYPE');
  
  const { intl } = props;

  useEffect(() => {
    // llamar por get a /file/{fileCode}?edit=true
    props.getEditFile(props.selected.code);
    return () => {
      props.fillResultFileContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.file.fileContainerResponse.tags && arrTags.length == 0){
      let tagGroup = globalState.file.fileContainerResponse.tags.split(';');

      let arrTagsTemp = [];
      if(tagGroup.length){
        tagGroup.forEach((tagPar) => {
          let keyvalue = tagPar.split(':');
          
          if(keyvalue.length == 2){
            arrTagsTemp.push({key:keyvalue[0], value:keyvalue[1]});
          }
        });
        setArrTags([...arrTagsTemp, ...arrTags]);
      }
      setRawArrTags(globalState.file.fileContainerResponse.tags);
    }

    if(globalState.file.fileContainerResponse.plans &&
      globalState.file.fileContainerResponse.plans.length > 0){
        let plansSelected = [];
        globalState.file.fileContainerResponse.plans.forEach((plan, index) =>{
          globalState.file.promotionalPlans.list.forEach((item, index) => {
            if(plan === item.code){
              plansSelected.push(item);
            }
          })
        })
        setPlanName(plansSelected);
    }

    if(globalState.file.fileContainerResponse.order){
      setOrder(globalState.file.fileContainerResponse.order);
    }
    if(globalState.file.fileContainerResponse.validFrom){
      setValidFrom(globalState.file.fileContainerResponse.validFrom);
    }
    if(globalState.file.fileContainerResponse.validTo){
      setValidTo(globalState.file.fileContainerResponse.validTo);
    }
    if(globalState.file.fileContainerResponse.allow_share){
      setAllowShare(globalState.file.fileContainerResponse.allow_share);
    }
    if(globalState.file.fileContainerResponse.allow_usershare){
      setAllowUserShare(globalState.file.fileContainerResponse.allow_usershare);
    }
    if(globalState.file.fileContainerResponse.automatic_download){
      setAutomaticDownload(globalState.file.fileContainerResponse.automatic_download);
    }
    if(globalState.file.fileContainerResponse.allow_present){
      setAllowPresent(globalState.file.fileContainerResponse.allow_present);
    }  
    if(globalState.file.fileContainerResponse.folder) {
      setFolder(globalState.file.fileContainerResponse.folder)
    }
    if(globalState.file.fileContainerResponse.cod_folder) {
      setCodFolder(globalState.file.fileContainerResponse.cod_folder);
    }

    let callType = globalState.file.fileContainerResponse.call_type || defaultCallType;
    switch (callType) {
      case 1: // Online
        setOnlineVisit(1);
        break;
      case 2: // Offline
        setOfflineVisit(1);
        break;
      case 3: // Las dos
        setOnlineVisit(1);
        setOfflineVisit(1);
        break;
      default:
        break;
    }

    if(!folder && codFolder) {
      props.getFolders();
    }

    const folderSelectorParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'FILES_FOLDER_ENABLE');
    setFolderSelector(folderSelectorParam);
  }, [globalState.file.fileContainerResponse]);

  useEffect(() => {
    if(globalState.file.editFileResult != null && !fileResultKeeper ){
      props.getEditFile(props.selected.code);
      setFileResultKeeper(true);
    }
    if(
      globalState.file.editFileResult == null &&
      fileResultKeeper == true
    ){
      props.setEditFileResult(null);
      setFileResultKeeper(false);
      handleClose();  
    }
    if(
      globalState.file.editFileResult != null &&
      fileResultKeeper
    ) {
      props.setEditFileResult(null);
    }
  }, [globalState.file.editFileResult, fileResultKeeper])

  useEffect(() => {
    if(codFolder && globalState.file.folders.length > 0) {
      const folderFinded = globalState.file.folders.find(f => f.code === codFolder);

      if(folderFinded) {
        setFolder(folderFinded.name);
      }
    }
  }, [globalState.file.folders])

  const handleClose = () => {
    props.setDisplayDialog(false);
    props.fillResultFileContainer({});

  }

  const handleDeactivate = () => {
    let msgAlert = intl.formatMessage({ id: "FILE.ACTIONS.CONFIRM" });
    if (window.confirm(msgAlert)) {
      handleClose();
      props.inactivateFile({ code: globalState.file.fileContainerResponse.code, fromEditPopUp: true });
    }
  }

  const handleChangeArrTags = (input) => {
    setArrTags(input);
    
    if (input.length) {
      let arrKeyValue = [];
      input.forEach((item) => {
        arrKeyValue.push(`${item.key}:${item.value}`);
      });
      let strtags = arrKeyValue.join(';');
      setRawArrTags(strtags);
    } else {
      setRawArrTags('');
    }
  }

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
  }

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  }

  const handleChangeAllowShare = (event) => {
    setAllowShare(event.target.checked);
  }

  const handleChangeAllowUserShare = (event) => {
    setAllowUserShare(event.target.checked);
  }

  const handleChangeAutomaticDownload = (event) => {
    setAutomaticDownload(event.target.checked);
  }

  const handleChangeAllowPresent = (event) => {
    setAllowPresent(event.target.checked);
  }

  const handleChangeOnlineVisit = (event) => {
    setOnlineVisit(event.target.checked);
  }

  const handleChangeOfflineVisit = (event) => {
    setOfflineVisit(event.target.checked);
  }

  const handleSelectFolder = (folder) => {
    setCodFolder(folder.code);
    // Seteo el nombre nomas para que se muestre en el input, este no se manda en el request porque la columna folder tiene que quedar vacia
    setFolder(folder.name);
  }

  const handleChangePlanName = (event, values) => {
    setPlanName(values);
  };

  let labelPresentations = intl.formatMessage({
    id: "VAID.EDIT.FORM.PLANOS"
  });

  const checkCallType = () => {
    let callType = 0;

    if(onlineVisit && offlineVisit) {
      callType = 3;
    } else if(onlineVisit) {
      callType = 1;
    } else if(offlineVisit) {
      callType = 2;
    }

    return callType;
  }

  const saveChanges = () => {
    if (validateDates()) {
      props.saveMetaDataFile({
        ...globalState.file.fileContainerResponse,
        code: props.selected.code,
        validFrom: ( validFrom ) ? validFrom : new Date(),
        validTo: ( validTo ) ? validTo : new Date(),
        tags: (rawArrTags && rawArrTags.length) ? rawArrTags : "",
        order,
        plans: planName,
        allow_share: allowShare ? 1 : 0,
        allow_usershare: allowUserShare ? 1 : 0,
        automatic_download: automaticDownload ? 1 : 0,
        allow_present: allowPresent ? 1 : 0,
        cod_folder: codFolder,
        call_type: checkCallType(),
        queryString: "?onlyValidity=true"
      });
    } else {
      setOpenSnackError(true);
    }
  }

  const handlePreview = () => {
    window.open(globalState.file.fileContainerResponse.url);
  }

  const validateDates = () => {
    let fromDate = moment(validFrom);
    let toDate = moment(validTo);
    return(toDate.diff(fromDate) > 0);
  }

  return (
    <div style={{width: '100%'}}>
      <Dialog
        open={props.displayDialog}
        onClose={handleClose}
        className="edit-tags-popup__container"
      >
        <Snackbar
          open={(openSnackError)}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={1500}
          onClose={() => { setOpenSnackError(false) }}
        >
          <Alert severity='error'>
            <FormattedMessage id="POPUP_EDIT_VA.ERROR_DATES" />
          </Alert>
        </Snackbar>
        <DialogTitle>{props.selected.name}</DialogTitle>
          
        <DialogContent className="schedule__modifier__popup__content">
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.CODE" />: </span>
            <span className={classes.data}>
              {props.selected.code}
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.VALID_FROM" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                id="datetime-local"
                type="datetime-local"
                value={validFrom}
                onChange={handleChangeDateFrom}
              />
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.VALID_TO" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                id="datetime-local"
                type="datetime-local"
                value={validTo}
                onChange={handleChangeDateTo}
              />
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.ORDER" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                value={order}
                onChange={handleChangeOrder}
              />
            </span>
          </div>

          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.PLANOS" />: </span>
            <span className={classes.data}>
              <Autocomplete
                multiple
                id="tags-standard-visual-aids"
                options={globalState.file.promotionalPlans.list}
                disableCloseOnSelect={true}
                onChange={handleChangePlanName}
                value={planName}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                  </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={labelPresentations}
                      />
                  )}
              />
            </span>
          </div> 

          <div className={classes.inRow}>
            <span className={classes.label}><FormattedMessage id={"VAID.EDIT.FORM.FOLDER"} />: </span>
            <span className={classes.data}>
              {
                folderSelector == 1 &&
                <>
                  <TextField
                  id="outlined-read-only-input"
                  value={folder}
                  className={classes.inputText}
                  InputProps={{
                    readOnly: true,
                  }}
                  />
                  <SelectFolderButton handleSelectFolder={handleSelectFolder} />
                </>
              }
            </span>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.row}>        
              <FormControlLabel
                value={allowShare}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowShare}
                  onChange={handleChangeAllowShare} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_SHARE"/>:
                  </Typography>
                }
                labelPlacement="start"
              />

              <FormControlLabel
                value={allowUserShare}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowUserShare}
                  onChange={handleChangeAllowUserShare} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_USERSHARE"/>:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              <FormControlLabel
                value={automaticDownload}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={automaticDownload}
                  onChange={handleChangeAutomaticDownload} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.AUTOMATIC_DOWNLOAD" />:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              <FormControlLabel
                value={allowPresent}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={allowPresent}
                  onChange={handleChangeAllowPresent} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="FILE.COLUMN.ALLOW_PRESENT" />:
                  </Typography>
                }
                labelPlacement="start"
              />
            </div>

            <div className={classes.row}>
              <FormControlLabel
                value={onlineVisit}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon} 
                  checked={onlineVisit}
                  onChange={handleChangeOnlineVisit} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE" />:
                  </Typography>
                }
                labelPlacement="start"
              />
              
              {
                showOfflineVisit &&
                <FormControlLabel
                  value={offlineVisit}
                  control={
                    <Checkbox 
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={offlineVisit}
                    onChange={handleChangeOfflineVisit} 
                    />
                  }
                  label={
                    <Typography className={classes.labelCheck}>
                      <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.PRESENTATION" />:
                    </Typography>
                  }
                  labelPlacement="start"
                />
              }
            </div>
          </div>

          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.TAGS" />: </span>
            <span className={classes.data}>
              <TagsInput 
              setArrTagsGlobal={handleChangeArrTags}
              tagsItems={arrTags}
              tagOptions={globalState.file.tagsOptions}
            />
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="btnEditInactivate" onClick={handleDeactivate} className={classes.buttonInactivate} autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.INACTIVATE" />
          </Button>
          <Button id="btnEditPreview" onClick={handlePreview} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.PREVIEW" />
          </Button>
          <Button id="btnEditAccept" onClick={saveChanges} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.ACCEPT" />
          </Button>
          <Button id="btnEditCancel" onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    fileStorage.actions
  )(EditTagsPopUp)
);