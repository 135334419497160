import axios from "axios";
import {configUrls} from '../../globalConfig';

export const LOGIN_URL = `${configUrls.base_url}/api/auth`;
export const REGISTER_URL = `${configUrls.base_url}/api/login`;
//export const REGISTER_URL = "api/auth/register";
export const LOGIN_URL_DIRECT = `${configUrls.base_url}/api/AuthByUserId`;
export const REQUEST_PASSWORD_URL = `${configUrls.base_url}/api/user/resetpassword`;
export const CHANGE_PASSWORD_URL = `${configUrls.base_url}/api/user/changepasswordwithtoken`;
export const REQUEST_CALL_URL = `${configUrls.base_url}/api/call/`;
export const REQUEST_TERMS = `${configUrls.base_url}/api/Term/`;
export const REQUEST_MENU = `${configUrls.base_url}/api/Menu/`;
export const REQUEST_REPORT = `${configUrls.base_url}/api/ReportConfig/`;
export const ME_URL = "api/me";
export const DEPLOYMENT_URL = `${configUrls.base_url}/api/deployment`;
export const GENERATE_TOKEN_URL = `${configUrls.base_url}/api/call/action/generatetoken/`;

export function login(user, callcode, direct = false, token) {
  if(token){
    return loginWithToken(user, callcode, direct, token);
  }else{
    if(direct){
      return axios.post(LOGIN_URL_DIRECT, { userid: user, callcode, clientid: 'xserverCallWebApp' });
    }else{
      return axios.post(LOGIN_URL, { email: user, callcode, clientid: 'xserverCallWebApp' });
    }
  }
}

export function loginWithToken(user, callcode, direct = false, token) {
  if(direct){
    return axios.post(LOGIN_URL_DIRECT, { userid: user, callcode, clientid: 'xserverCallWebApp', token: token });
  }
}

export function register(email, password) {
  return axios.post(REGISTER_URL, { email, password, clientid: 'xserverCallWebApp' });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(cod_Deployment, token, email, password){
  return axios.put(CHANGE_PASSWORD_URL, { cod_Deployment: cod_Deployment, token: token, email: email, password: password})
}

export function termsAction(action,termcode,code,desc){
  return axios.post(REQUEST_TERMS + 'action/'+action+'/'+termcode,{callcode: code, description: desc});
}

export function getUrlToken(callCode, userId){
  return axios.post(GENERATE_TOKEN_URL + callCode + '/' + userId);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getDeploymentByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(DEPLOYMENT_URL);
}

export function getCallDataByToken(code) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(REQUEST_CALL_URL + code);
}

export function getTerms(code){
  return axios.get(REQUEST_TERMS + code);
}

export function getMenu() {
  return axios.get(REQUEST_MENU);
}

export function getReportById(reportid) {
  return axios.get(REQUEST_REPORT + reportid);
}