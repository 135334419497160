import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getPromotionalPlan,
  getVisualAids,
  activateVisualAid,
  inactivateVisualAid,
  approveVisualAid,
  pendingVisualAid,
  createVisualAidContainer,
  getVisualAidEdit,
  uploadFile,
  saveMetaDataVaid,
  getVaidTagsOptions,
  saveVaQuickEdition,
  listVaidByCall
} from '../../crud/visualAid.crud';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';

export const actionTypes = {
  FULLFILL_PROMOTIONAL_PLANS: "FULLFILL_PROMOTIONAL_PLANS",
  FULLFILL_VISUAL_AIDS: "FULLFILL_VISUAL_AIDS",
  FILL_PROMOTIONAL_PLAN: "FILL_PROMOTIONAL_PLAN",
  FILL_VISUAL_AIDS: "FILL_VISUAL_AIDS",
  ACTIVATE_VAID: "ACTIVATE_VAID",
  INACTIVATE_VAID: "INACTIVATE_VAID",
  ACTIVATE_VAID_RESULT: "ACTIVATE_VAID_RESULT",
  INACTIVATE_VAID_RESULT: "INACTIVATE_VAID_RESULT",
  APPROVE_VAID: "APPROVE_VAID",
  PENDING_VAID: "PENDING_VAID",
  APPROVE_VAID_RESULT: "APPROVE_VAID_RESULT",
  PENDING_VAID_RESULT: "PENDING_VAID_RESULT",
  CREATE_VISUALAID_CONTAINER: "CREATE_VISUALAID_CONTAINER",
  FILL_RESULT_VISUALAID_CONTAINER: "FILL_RESULT_VISUALAID_CONTAINER",
  GET_VAID_EDIT: "GET_VAID_EDIT",
  UPLOAD_FILE: "UPLOAD_FILE",
  SAVE_META_DATA_VAID: "SAVE_META_DATA_VAID",
  EDIT_VAID_RESULT: "EDIT_VAID_RESULT",
  SET_ARR_TAGS: "SET_ARR_TAGS",
  GET_VAID_TAGS_OPTIONS: "GET_VAID_TAGS_OPTIONS",
  FILL_VAID_TAGS_OPTIONS: "FILL_VAID_TAGS_OPTIONS",
  SAVE_QUICK_VA_EDITION: "SAVE_VA_EDITION",
  LIST_VAID_BY_CALL: "LIST_VAID_BY_CALL",
  FILL_VAID_BY_CALL: "FILL_VAID_BY_CALL"
};


const initialState = {
  promotionalPlans: [],
  visualAids: [],
  loading: false,
  activateVaidResult: null,
  inactivateVaidResult: null,
  approveVaidResult: null,
  pendingVaidResutl: null,
  visualAidContainerResponse: {},
  editVaidResult: null,
  tagsOptions:[],
  resultVaidByCall: []
}

export const reducer = persistReducer(
  { storage, key: "appState", whitelist: ["state"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FULLFILL_PROMOTIONAL_PLANS:
          return Object.assign({}, state,  { promotionalPlans: action.payload, loading: false } );
      case actionTypes.FULLFILL_VISUAL_AIDS:
        return Object.assign({}, state,  { visualAids: action.payload.list, loading: false } );
      case actionTypes.FILL_PROMOTIONAL_PLAN:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.FILL_VISUAL_AIDS:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.ACTIVATE_VAID: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.INACTIVATE_VAID: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.ACTIVATE_VAID_RESULT: {
          return { 
            ...state,
            loading: false,
            activateVaidResult: action.payload
          };
      }
      case actionTypes.INACTIVATE_VAID_RESULT: {
          return { 
            ...state,
            loading: false,
            inactivateVaidResult: action.payload
          };
      }
      case actionTypes.APPROVE_VAID: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.PENDING_VAID: {
          return { 
            ...state,
            loading: true,
            visualAidContainerResponse: {code: action.payload.code}
          };
      }
      case actionTypes.APPROVE_VAID_RESULT: {
          return { 
            ...state,
            loading: false,
            approveVaidResult: action.payload
          };
      }
      case actionTypes.PENDING_VAID_RESULT: {
          return { 
            ...state,
            loading: false,
            pendingVaidResult: action.payload
          };
      }
      case actionTypes.CREATE_VISUALAID_CONTAINER: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.FILL_RESULT_VISUALAID_CONTAINER: {
          return { 
            ...state,
            loading: false,
            redirection: (action.payload.redirection) ? action.payload.redirection : false,
            visualAidContainerResponse: action.payload
          };
      }
      case actionTypes.SAVE_META_DATA_VAID: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.EDIT_VAID_RESULT: {
          return { 
            ...state,
            loading: false,
            editVaidResult: action.payload
          };
      }
      case actionTypes.SET_ARR_TAGS: {
          return { 
            ...state,
            visualAidContainerResponse: { 
              ...state.visualAidContainerResponse, 
              tags: action.payload
            }
          };
      }
      case actionTypes.GET_VAID_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_VAID_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: false,
          tagsOptions: action.payload
        };
      }
      case actionTypes.LIST_VAID_BY_CALL: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_VAID_BY_CALL: {
        return { 
          ...state,
          loading: false,
          resultVaidByCall: action.payload
        };
      }
      default:
        return state
    }
  }
);

export const actions = {
  fillPromotionalPlan: (value) => ({ type: actionTypes.FILL_PROMOTIONAL_PLAN, payload: value}),
  fulfillPromotionalPlan: (value) => ({ type: actionTypes.FULLFILL_PROMOTIONAL_PLANS, payload: value}),
  fulfillVisualAids: (value) => ({ type: actionTypes.FULLFILL_VISUAL_AIDS, payload: value}),
  fillVisualAids: (value) => ({ type: actionTypes.FILL_VISUAL_AIDS, payload: value}),
  activateVaid: (value) => ({ type: actionTypes.ACTIVATE_VAID, payload: value }),
  inactivateVaid: (value) => ({ type: actionTypes.INACTIVATE_VAID, payload: value }),
  setActivateVaidResult: (value) => ({ type: actionTypes.ACTIVATE_VAID_RESULT, payload: value }),
  setInactivateVaidResult: (value) => ({ type: actionTypes.INACTIVATE_VAID_RESULT, payload: value }),
  approveVaid: (value) => ({ type: actionTypes.APPROVE_VAID, payload: value }),
  pendingVaid: (value) => ({ type: actionTypes.PENDING_VAID, payload: value }),
  setApproveVaidResult: (value) => ({ type: actionTypes.APPROVE_VAID_RESULT, payload: value }),
  setPendingVaidResult: (value) => ({ type: actionTypes.PENDING_VAID_RESULT, payload: value }),
  createVisualaidContainer: (value) => ({ type: actionTypes.CREATE_VISUALAID_CONTAINER, payload: value }),
  fillResultVisualAidContainer: (value) => ({ type: actionTypes.FILL_RESULT_VISUALAID_CONTAINER, payload: value }),
  getEditVisualAid: (value) => ({ type: actionTypes.GET_VAID_EDIT, payload: value }),
  uploadFile: (value) => ({type: actionTypes.UPLOAD_FILE, payload: value}),
  saveMetaDataVisualAid: (value) => ({type: actionTypes.SAVE_META_DATA_VAID, payload: value}),
  setEditVaidResult: (value) => ({type: actionTypes.EDIT_VAID_RESULT, payload: value}),
  setArrTags: (value) => ({type: actionTypes.SET_ARR_TAGS, payload: value}),
  getVisualAidsTagsOptions: (value) => ({type: actionTypes.GET_VAID_TAGS_OPTIONS, payload: value}),
  fillVisualAidsTagsOptions: (value) => ({type: actionTypes.FILL_VAID_TAGS_OPTIONS, payload: value}),
  saveVaEdition: (value) => ({type: actionTypes.SAVE_QUICK_VA_EDITION, payload: value}),
  listVaidByCall: (value) => ({type: actionTypes.LIST_VAID_BY_CALL, payload: value}),
  fillVaidByCall: (value) => ({type: actionTypes.FILL_VAID_BY_CALL, payload: value})
}

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_VAID_BY_CALL, function* listVaidByCallSaga({payload}) {
    const {data, status} = yield listVaidByCall(payload);
    if(status < 300 && data.list){
      yield put(actions.fillVaidByCall(data.list));
    }   
  });

  yield takeLatest(actionTypes.SAVE_QUICK_VA_EDITION, function* saveVaEditionSaga({payload}) {
    const selector = yield select(getState);
    try{
      payload.validFrom = moment(payload.validFrom).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      payload.validTo = moment(payload.validTo).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      const {data, status} = yield saveVaQuickEdition(payload);
      if(status < 300){
        yield put(actions.setEditVaidResult(true));
      }else{
        yield put(actions.setEditVaidResult(false));
      }
    }catch(e){
      yield put(actions.setEditVaidResult(false));
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.GET_VAID_TAGS_OPTIONS, function* getVaidTagsOptionsSaga({payload}) {
    try{
      const {data, status} = yield getVaidTagsOptions();
      if(status < 300){
        yield put(actions.fillVisualAidsTagsOptions(data.list));
      }
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.SAVE_META_DATA_VAID, function* saveMetaDataVaidSaga({payload}) {
    const selector = yield select(getState);
    try{
      let newObj = JSON.parse(JSON.stringify(selector.visualAid.visualAidContainerResponse)); // deep copy
      
      newObj.validFrom = moment(newObj.validFrom).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      newObj.validTo = moment(newObj.validTo).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');

      const {data, status} = yield saveMetaDataVaid({ ...newObj, images:payload});
      yield put(actions.fillResultVisualAidContainer({}));
      if(status < 300){
        yield put(actions.setEditVaidResult(true));
      }else{
        yield put(actions.setEditVaidResult(false));
      }
    }catch(e){
      console.log(e);
      yield put(actions.fillResultVisualAidContainer({}));
      yield put(actions.setEditVaidResult(false))
    }    
  });

  yield takeLatest(actionTypes.UPLOAD_FILE, function* uploadFileSaga({payload}) {
    try{
      const {data, status} = yield uploadFile(payload);
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.GET_VAID_EDIT, function* getVisualAidEditSaga({payload}) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getVisualAidEdit(payload);
      data.validFrom = moment(data.validFrom).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      data.validTo = moment(data.validTo).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');

      if(status < 300 && data.code){
        yield put(actions.fillResultVisualAidContainer(data));
      }else{
        yield put(actions.fillResultVisualAidContainer({}));
      }
    }catch(e){
      yield put(actions.fillResultVisualAidContainer({}));
    }    
  });

  yield takeLatest(actionTypes.CREATE_VISUALAID_CONTAINER, function* createVisualAidContainerSaga({payload}) {
    try{
      const {data, status} = yield createVisualAidContainer(payload);
      
      if(status < 300 && data.code){
        yield put(actions.fillResultVisualAidContainer({...data, redirection: true}));
      }else{
        yield put(actions.fillResultVisualAidContainer({redirection: false}));
      }
    }catch(e){
      yield put(actions.fillResultVisualAidContainer({redirection: false}));
    }    
  });

  yield takeLatest(actionTypes.INACTIVATE_VAID, function* inactivateVaidSaga({payload}) {
    try{
      const {data, status} = yield inactivateVisualAid(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setInactivateVaidResult(true));
      }else{
        yield put(actions.setInactivateVaidResult(false));
      }
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setInactivateVaidResult(false));
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.ACTIVATE_VAID, function* activateVaidSaga({payload}) {
    try{
      const {data, status} = yield activateVisualAid(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setActivateVaidResult(true));
      }else{
        yield put(actions.setActivateVaidResult(false));
      }
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setActivateVaidResult(false));
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.APPROVE_VAID, function* approveVaidSaga({payload}) {
    try{
      const {data, status} = yield approveVisualAid(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setApproveVaidResult(true));
      }else{
        yield put(actions.setApproveVaidResult(false));
      }
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setAproveVaidResult(false));
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.PENDING_VAID, function* pendingVaidSaga({payload}) {
    try{
      const {data, status} = yield pendingVisualAid(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setPendingVaidResult(true));
      }else{
        yield put(actions.setPendingVaidResult(false));
      }
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setPendingVaidResult(false));
      yield put(actions.fillVisualAids({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.FILL_PROMOTIONAL_PLAN, function* fillPromotionalPlanSaga({ payload}) {
    const { data } = yield getPromotionalPlan(payload);
    yield put(actions.fulfillPromotionalPlan(data));
  });

  yield takeLatest(actionTypes.FILL_VISUAL_AIDS, function* fillVisualAids({ payload}) {
    try{
      const { data } = yield getVisualAids(payload.stateId,payload.code);
      let vaids = { list:[] };
      if(data && data.list && data.list.length > 0){
        data.list.forEach((vaid, index) => {
          switch (vaid.status) {
            case 1:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.APPROVED" });
              break;
            case 2:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.PENDING" });
              break;
            case 3:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.DRAFT" });
              break;
            case 4:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.TEMP" });
              break;
            case 5:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.PROCESSING" })
              break;
            case 8:
              vaid.statusName = payload.intl.formatMessage({ id: "VAID.STATUS.PROCESSING_ERROR" })
              break;
            default:
              vaid.statusName = ''
              break;
          }
          vaids.list.push(vaid);
        });
      }
      
      yield put(actions.fulfillVisualAids(vaids)); 
    }catch(e){
      console.log(e);
    }
  });
}
