import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FileEmailButton = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);
  const [openFillEmail, setOpenFillEmail] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('success');
  const [inputValue, setInputValue] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  
  const handleOpenFillEmail = () => {
    setOpenFillEmail(true);
  };

  const handleCloseFillEmail = () => {
    setOpenFillEmail(false);
    props.reactors[1](null);
  };

  useEffect(() => {
    if (globalState.presentationBar.emailResult != '') {
      if (globalState.presentationBar.emailResult == 'ok') {
        props.setEmailResult('');
        props.setSnackFile({
          open: true, 
          snackMsg: intl.formatMessage({
            id: "EMAIL.SEND.OK"
          }),
          snackBarSeverity: 'success'
        })
      } else {
        props.setEmailResult('');
        props.setSnackFile({
          open: true, 
          snackMsg: intl.formatMessage({
            id: "EMAIL.SEND.ERROR"
          }),
          snackBarSeverity: 'error'
        })
      }
    }
  }, [globalState.presentationBar.emailResult]);

  const handleSendEmailClick = () => {
    setOpenFillEmail(true);
    try {
      if (globalState.auth.deployment.callData.subscriberEmail){
        setInputValue(globalState.auth.deployment.callData.subscriberEmail);
      }
    } catch (error) {
      setInputValue("");
    }
    
  }

  const handleSendEnteredEmail = () => event => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (inputValue != '' && expression.test(String(inputValue).toLowerCase())) {
      props.sendFileEmail({ 
        callcode: globalState.auth.callCode, 
        subscriberId: globalState.auth.deployment.callData.subscriberId, 
        filecode: props.item.code, 
        email: inputValue 
      });
      props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'FILE_SHARED',
        log_text:  
          `callcode: ${globalState.auth.callCode} |
          userId: ${globalState.auth.userid} |
          subscriberId: ${globalState.auth.deployment.callData.subscriberId} |
          filecode: ${props.item.code} |
          type: email |
          email: ${inputValue}`
      })
      setOpenFillEmail(false);
      props.reactors[1](null);
    } else {
      let errorMsg = intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_FIELD"
      });
      window.alert(errorMsg);
    }
  }

  const handleCloseSnackBar = () => {
    console.log('ejecute el onclose')
    setOpenSnackBar(false);
    setSnackMsg('');
    //props.reactors[1](null);
  };

  return(
    <>
      <div onClick={handleSendEmailClick} id="menuItem__sendEmail">
        <span>&nbsp;</span>
        <FormattedMessage id="SCHEDULE.BUTTON.EMAIL" />
      </div>
      <Dialog 
          open={openFillEmail}
          onClose={handleCloseFillEmail}
          id="popup"
        >
          <DialogTitle id="form-dialog-title">
            EMAIL
          </DialogTitle>
          <DialogContent style={{width: '100%',height: '100%'}}>
            {globalState.auth.deployment.callData && globalState.auth.deployment.callData.subscriberEmail ?
            <FormattedMessage id="DASHBOARD.EMAIL.ENTER_FILLED"/>
            :
            <FormattedMessage id="DASHBOARD.EMAIL.ENTER"/>
            }
            <br></br>
            <br></br>
            <input style={{width: 250}}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={inputValue.length<1} onClick={handleSendEnteredEmail()}>
              <FormattedMessage id="DASHBOARD.EMAIL.SEND" />
            </Button>
            <Button onClick={handleCloseFillEmail}>
              <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
            </Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    { ...presentationBarStorage.actions, ...loggerStorage.actions }
  )(FileEmailButton)
);