import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import FlowItem from '../FlowItem/FlowItem';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}));

function FlowItemContainer() {
    const globalState = useSelector(state => state);
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {
                globalState.presentationBar.presentationFlows.flows && globalState.presentationBar.presentationFlows.flows.length > 0 &&
                globalState.presentationBar.presentationFlows.flows.map((flow, index) => (
                    <FlowItem flow={flow} index={index} key={index} selectedIndex={globalState.presentationBar.presentationFlows.indexOfSelectedFlow}/>
                ))
            }
            
        </List>
    );
}

export default injectIntl(
    connect(
        null,
        presentationBarStorage.actions
    )(FlowItemContainer)
);