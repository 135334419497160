export const customAlert = (message)  => {
  let css = document.createElement("style");
  let estilo = document.createTextNode(`
        #aviso {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: red;
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
      `);
  css.appendChild(estilo);
  document.head.appendChild(css);
  let aviso = document.createElement("div");
  aviso.setAttribute("id", "aviso");
  let contenido = document.createTextNode(message);
  let urlVisible = document.createElement('a');
  urlVisible.setAttribute('class', 'toast-white');
  urlVisible.setAttribute('target', '_blank');
  aviso.appendChild(contenido);
  aviso.appendChild(document.createElement('br'));
  aviso.appendChild(urlVisible);
  document.body.appendChild(aviso);
  window.load = setTimeout("if(typeof(aviso) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(aviso)){for (var i = 0; i < aviso.length; i++) {document.body.removeChild(aviso[i])}}else{document.body.removeChild(aviso)}}", 5000);
}