import React, {useEffect, useCallback} from 'react';
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import './Presentation.scss';
import {getDeploymentParamValue} from './utils';
import {appEnvironment} from '../../../globalConfig';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import { makeStyles } from '@material-ui/core/styles';
import PresentationNavBar from '../PresentationNavBar/PresentationNavBar.js';

const Presentation = (props) => {
    const globalState = useSelector(state => state);
    Object.assign(globalState.presentationBar, { ...globalState.presentationBar, loadingImg: true });

    const [srcSetCurrentImage, setSrcSetCurrentImage] = React.useState('');
    const [sendFeedbackTimeout, setSendFeedbackTimeout] = React.useState(null);

    // Guardo el currentAssetName ya que en presentaciones SDVA cuando me llega un asset por el incomingMessage el arry de slides es null
    // Entonces despues lo que hago es si se modifico currentAssetName actualizo el numSlide
    const [currentAssetCode, setCurrentAssetCode] = React.useState(null);

    let isPreview = (props.preview) ? props.preview : false;

    const gotoPreviousSlideSDVA = () => {
        let iframeWin = document.getElementById("inlineFrame").contentWindow;

        let currentSlide = getFlowSlide('PREVIOUS');
        if (currentSlide) {

            props.sendGotoSdvaSlide(
                {
                    type: 'SDVA',
                    command: 'GOTOSLIDE',
                    payload: currentSlide.name,
                    isPreview: isPreview
                }
            );
            
            let messageToSend = "SDVA_IN:GOTOSLIDE:" + currentSlide.name;

            iframeWin.postMessage(messageToSend, "*");
        }
    }

    const gotoNextSlideSDVA = () => {
        let iframeWin = document.getElementById("inlineFrame").contentWindow;

        let currentSlide = getFlowSlide('NEXT');
        
        if (currentSlide) {
            props.sendGotoSdvaSlide(
                {
                    type: 'SDVA',
                    command: 'GOTOSLIDE',
                    payload: currentSlide.name,
                    isPreview: isPreview
                }
            );
            
            let messageToSend = "SDVA_IN:GOTOSLIDE:" + currentSlide.name;

            iframeWin.postMessage(messageToSend, "*");
        }
    }

    const gotoSlideSDVA = (slide) => {
        let iframeWin = document.getElementById("inlineFrame").contentWindow;
        
        setCurrentAssetCode(slide.code);
        if(slide) {
            props.sendGotoSdvaSlide(
                {
                    type: 'SDVA',
                    command: 'GOTOSLIDE',
                    payload: slide.name,
                    isPreview: isPreview
                }
            );
            
            let messageToSend = "SDVA_IN:GOTOSLIDE:" + slide.name;

            iframeWin.postMessage(messageToSend, "*");
        }
    }

    const getFlowSlide = function(type){
        let slide;

        if(!globalState.presentationBar.focusedPresentationSlides || !globalState.presentationBar.focusedPresentationSlides.content 
                || globalState.presentationBar.focusedPresentationSlides.content.length <= 0){
            return;
        }

        let flow = globalState.presentationBar.focusedPresentationSlides.content;

        let currentAsset = flow[globalState.presentationBar.numSlide].code;

        // if(currentAssetName){
        //     currentAsset = currentAssetName;
        // }

        for(let j = 0; j < flow.length; j++){
            if(currentAsset.toString() == flow[j].code.toString()){
                if(type == 'NEXT' && j < flow.length){
                    slide = flow[j + 1];
                }else if(type == 'PREVIOUS' && j > 0){
                    slide = flow[j - 1];
                }
                break;
            }
        }

        if(slide){
            setCurrentAssetCode(slide.code);
        }

        return slide;
    }

    const updateCurrentSlide = (assetCode) => {
        let slide = { order: 1 };
        if(assetCode){
            for(let j = 0; j < focusedPresentationSlides.content.length; j++){
                if(assetCode.toString() == focusedPresentationSlides.content[j].code.toString()){
                    slide = focusedPresentationSlides.content[j];
                    break;
                } else if(assetCode.toString() == focusedPresentationSlides.content[j].name.toString()){
                    slide = focusedPresentationSlides.content[j];
                    break;
                }
            }
        }

        props.updateSlideActive({
            numSlide: slide.order - 1
        });
    }

    const toggleDrawer = (side, open, secondSideToClose) => {
        props.filterSearch('', false);

        Object.assign(globalState.presentationBar, { [side]: open, [secondSideToClose]: false })
        props.updateStateDrawers({ [side]: open, [secondSideToClose]: false });
    };

    const showSlideList = (presentationSlide, open) => {
        if(!isPreview){
            toggleDrawer('showPresentationList', false);
    
            if(presentationSlide.type === 'slides' || presentationSlide.type === 'sdva' || presentationSlide.type === 'html5animate'){
                props.fillSlidesDrawer(presentationSlide.code);
                Object.assign(globalState.presentationBar, {
                    showSlideList: open,
                    presentationFocusedSlides: presentationSlide
                })
                props.updateStateDrawers({
                    showSlideList: open,
                    presentationFocusedSlides: presentationSlide
                });
            }
        }
    };

    const nextFlow = function(){
        navigateFlow("NEXT");
    };

    const previousFlow = function(){
        navigateFlow("PREVIOUS");
    };

    const navigateFlow = (direction) => {
        const { focusedPresentationFlows } = globalState.presentationBar;
        const flows = focusedPresentationFlows.flows;
        let currentFlowIndex = focusedPresentationFlows.indexOfSelectedFlow;

        if(direction == "PREVIOUS") {
            if(currentFlowIndex - 1 < 0) {
                currentFlowIndex = flows.length - 1;
            }
            else {
                currentFlowIndex--;
            }
        } else if(direction == "NEXT") {
            if(currentFlowIndex + 1 >= flows.length) {
                currentFlowIndex = 0;
            }
            else
            {
                currentFlowIndex++;
            }
        }

        props.log({
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: 'VISUALAID_GOTOFLOW',
            log_text: `code: ${globalState.presentationBar.presentationFocused.code} | gotoFlowPosition: ${currentFlowIndex} | goToCode: ${focusedPresentationFlows.flows[currentFlowIndex].slides[0].code}`
        });

        props.sendFlowActive({
            'numSlide': 0,
            'numFlow': currentFlowIndex,
            code: globalState.presentationBar.presentationFocused.code,
            type: globalState.presentationBar.presentationFocused.type,
            currentFlow: focusedPresentationFlows.flows[currentFlowIndex],
            videoManager: globalState.presentationBar.videoManager,
            isPreview: isPreview
        });

        props.updatePesentationFocusedLength(focusedPresentationFlows.flows[currentFlowIndex].slides.length);

        if(isPreview) {
            let iframeWin = document.getElementById("inlineFrame").contentWindow;
            let messageToSend = "SDVA_IN:GOTOSLIDE:" + focusedPresentationFlows.flows[currentFlowIndex].slides[0].name;
            iframeWin.postMessage(messageToSend, "*");
        }

        setTimeout(() => {
            gotoSlideSDVA(focusedPresentationFlows.flows[currentFlowIndex].slides[0]);
        }, [300]);
    }

    const gotoPreviousSlideAAN = () => {
        let iframeWin = document.getElementById("inlineFrameHtml5").contentWindow;

        let currentSlide = getFlowSlide('PREVIOUS');
        if (currentSlide) {

            props.ExecuteActionAAN(
                {
                    type: 'AAN',
                    command: 'GOTOSLIDE',
                    payload: currentSlide.code,
                    isPreview: isPreview
                }
            );

            let messageToSend = "AAN_IN:GOTOSLIDE:" + currentSlide.fileName;

            iframeWin.postMessage(messageToSend, "*");
        }
    }

    const gotoNextSlideAAN = () => {
        let iframeWin = document.getElementById("inlineFrameHtml5").contentWindow;

        let currentSlide = getFlowSlide('NEXT');
        if (currentSlide) {

            props.ExecuteActionAAN(
                {
                    type: 'AAN',
                    command: 'GOTOSLIDE',
                    payload: currentSlide.code,
                    isPreview: isPreview
                }
            );
            
            let messageToSend = "AAN_IN:GOTOSLIDE:" + currentSlide.fileName;

            iframeWin.postMessage(messageToSend, "*");
        }
    }

    const gotoPreviousSlide = () => {
        let actualSlide = globalState.presentationBar.numSlide;

        if (actualSlide > 0) {
            if (
                globalState.presentationBar.focusedPresentationSlides.content != null && 
                globalState.presentationBar.focusedPresentationSlides.content[actualSlide - 1] != null &&
                !isPreview
            ) {
                props.log({
                    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                    log_category: 'VISUALAID_GOTO',
                    log_text: `code: ${globalState.presentationBar.presentationFocused.code} | gotoPosition: ${actualSlide - 1} | goToCode: ${globalState.presentationBar.focusedPresentationSlides.content[actualSlide - 1].code}`
                })
            }
            props.sendSlideActive({
                'numSlide': actualSlide - 1,
                code: globalState.presentationBar.presentationFocused.code,
                type: 'slides',
                videoManager: globalState.presentationBar.videoManager,
                isPreview: isPreview
            });

            //props.stopMouseMove();
        }
    }

    const gotoNextSlide = () => {
        let actualSlide = globalState.presentationBar.numSlide;
        
        if (globalState.presentationBar.focusedPresentationSlides.content != null && actualSlide < globalState.presentationBar.focusedPresentationSlides.content.length - 1) {
            if (globalState.presentationBar.focusedPresentationSlides.content[actualSlide + 1] != null && !isPreview) {
                props.log({
                    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                    log_category: 'VISUALAID_GOTO',
                    log_text: `code: ${globalState.presentationBar.presentationFocused.code} | gotoPosition: ${actualSlide + 1} | goToCode: ${globalState.presentationBar.focusedPresentationSlides.content[actualSlide + 1].code}`
                })
            }
            
            props.sendSlideActive({
                'numSlide': actualSlide + 1,
                code: globalState.presentationBar.presentationFocused.code,
                type: 'slides',
                videoManager: globalState.presentationBar.videoManager,
                isPreview: isPreview
            })

            //props.stopMouseMove();
        }
    }

    const showCurrentSlide = () => {
        let showLabel = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_DEBUG_SHOWLABEL");
        
        if(appEnvironment.indexOf('local') != -1 || showLabel){
            setTimeout(function(){
                let slideImageElement = document.getElementById("slideImage");
    
                if(slideImageElement && slideImageElement.currentSrc != ''){
                    let currentSrc = slideImageElement.currentSrc;
    
                    let imageIndex = currentSrc.indexOf(globalState.presentationBar.focusedPresentationSlides.code + '/');
                    imageIndex += globalState.presentationBar.focusedPresentationSlides.code.length + 1;
    
                    let currentSrcName = currentSrc.substring(imageIndex, currentSrc.length);
                    currentSrcName = currentSrcName.replace(/%20/g, ' ');
    
                    setSrcSetCurrentImage(currentSrcName);
                }
            }, 200);
        }else{
            setSrcSetCurrentImage('');
        }
    }

    const closePresentation = (reason) => {
        // aca quitar el listener de arrows 

        if(!isPreview){
            props.log({
                logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                log_category: 'VISUALAID_END',
                log_text: `code: ${globalState.presentationBar.presentationFocused.code} | reason: ${reason}`
            })
        }

        let fnCallbackClosePresentation = () => {
            props.closePresentation({
                displayScreenPresentation: false,
                videoManager: globalState.presentationBar.videoManager,
                code: globalState.presentationBar.presentationFocused.code,
                presentationFocused: {},
                focusedPresentationSlides: {},
                focusedPresentationFlows: { indexOfSelectedFlow: 0, currentFlow: {}, flows: []},
                reason: reason,
                isPreview: isPreview
            });
        }

        // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
        if(globalState.presentationBar.presentationFocused.type == 'sdva'){
            props.sendGotoSdvaSlide(
                {
                    type: 'SDVA',
                    command: 'HIDEFULLSCREENMOVIE',
                    payload: 'NA',
                    isPreview: isPreview
                }
            );
    
            setTimeout(() => {
                fnCallbackClosePresentation();
            }, 1200);
        }else{
            fnCallbackClosePresentation();
        }
        if(globalState.auth.deployment.callData?.type !=2 ){
           props.stopMouseMove();
        }else{
            toggleDrawer('showPresentationList', true);
        }
    }

    const handleIncomingMessage = (event) => {
        //va a llegar: SDVA_OUT:gotoSlide:va08
        //console.log('EVENTO SDVA', event.data)
        if (event.data && event.data.length > 0) {
            let commandArr = event.data.split(':')
            /*
            let type = commandArr[0];
            let action = commandArr[1];
            let sdvaSlide = commandArr[2];
            */
            let type = '';
            let command = '';
            let payload = '';

            if (commandArr && commandArr.length > 0) {
                if (commandArr[0] == 'SDVA_OUT') {
                    type = 'SDVA';

                    if (commandArr.length > 1) {
                        command = commandArr[1].toUpperCase();

                        if (commandArr.length > 2) {
                            payload = commandArr[2];
                        }

                        //por si vienen multiples payloads los concateno
                        if (commandArr.length > 3) {
                            for (var i = 3; i < commandArr.length; i++) {
                                payload = payload + ":" + commandArr[i];
                            }
                        }

                        if(command == 'HIDEFULLSCREENMOVIE'){
                            props.onHideMovie();
                        }

                        if(command == 'SENDDOCUMENT' && commandArr.length > 3){
                            props.sendVisualAidEmail({
                                subscriberId: globalState.auth.deployment.callData?.subscriberId,
                                email: commandArr[2],
                                asset: commandArr[3],
                                file: commandArr[4]
                              });
                        }

                        if(command == 'GOTOSLIDE'){
                            setCurrentAssetCode(payload);
                        }else if(command == 'VARESIZED'){
                            var values = payload.split(',');

                            var _marginTop = values[2] != null ? parseInt(values[2].replace('px', '')) : 0;
                            var _marginLeft = values[3] != null ? parseInt(values[3].replace('px', '')) : 0;

                            props.setVaSize({ height: values[0], width: values[1], marginTop: _marginTop, marginLeft: _marginLeft });
                        }

                        //console.log('desde el handleEvent', globalState)
                        props.sendGotoSdvaSlide(
                            {
                                type: type,
                                command: command,
                                payload: payload,
                                isPreview: isPreview
                            }
                        );

                    }
                }else if (commandArr[0] == 'AAN_OUT') {
                    type = 'AAN';

                    if (commandArr.length > 1) {
                        command = commandArr[1].toUpperCase();

                        if (commandArr.length > 2) {
                            payload = commandArr[2];
                        }

                        //por si vienen multiples payloads los concateno
                        if (commandArr.length > 3) {
                            for (var i = 3; i < commandArr.length; i++) {
                                payload = payload + ":" + commandArr[i];
                            }
                        }

                        if(command == 'VARESIZED'){
                            var values = payload.split(',');

                            var _marginTop = values[2] != null ? parseInt(values[2].replace('px', '')) : 0;
                            var _marginLeft = values[3] != null ? parseInt(values[3].replace('px', '')) : 0;

                            props.setVaSize({ height: values[0], width: values[1], marginTop: _marginTop, marginLeft: _marginLeft });
                        }

                        //console.log('desde el handleEvent', globalState)
                        props.ExecuteActionAAN(
                            {
                                type: type,
                                command: command,
                                payload: payload,
                                isPreview: isPreview
                            }
                        );

                    }
                }
            }
        }
    }

    const sendFeedback = () => {
        if(!globalState || !globalState.auth.deployment || !globalState.auth.deployment.callData || !globalState.auth.deployment.callData.code)
        {
            return;
        }

        var feedBacks = getFeedbacksPendingToSave();

        if(feedBacks && feedBacks.length > 0){
            props.sendSlideFeedback(
                {
                    callCode: globalState.auth.deployment.callData?.code,
                    data: { 
                        feedbacks: feedBacks 
                    }
                }
            );
        }
    }

    const startSendFeedback = () => {
        if(!sendFeedbackTimeout){
            let timeOut = setTimeout(() => {

                sendFeedback();

                clearTimeout(sendFeedbackTimeout);
                setSendFeedbackTimeout(null);

                startSendFeedback();
            }, 15000);

            setSendFeedbackTimeout(timeOut);
        }
    }

    const stopSendFeedback = () => {
        if(sendFeedbackTimeout){
            clearTimeout(sendFeedbackTimeout);
            setSendFeedbackTimeout(null);
        }

        sendFeedback();
    }

    const isPresentationFeedbackAllowed = (type) =>{
        return type == 'slides' || type == 'sdva' || type == 'html5animate' || type == 'video'
    }

    const getFeedbacksPendingToSave = () => {
        let result = [];

        let key = 'feedback_' + globalState.auth.deployment.callData?.code;
        let presentationFeedback = localStorage.getItem(key);

        if (presentationFeedback) {
            presentationFeedback = JSON.parse(presentationFeedback);
        }

        if(presentationFeedback && presentationFeedback.length > 0){
            let item;
            for(let key in presentationFeedback){
                item = presentationFeedback[key];

                if(!item.isSaved){
                    result.push(
                        {
                            cod_Slide: item.codeSlide,
                            feedback: item.vote,
                            comment: item.comment
                        }
                    );
                }
            }
        }
        
        return result;
    }

    const setFeedbacksStatus = (result) => {
        let key = 'feedback_' + result.callCode;
        let presentationFeedback = localStorage.getItem(key);

        if (presentationFeedback) {
            presentationFeedback = JSON.parse(presentationFeedback);
        }

        if(presentationFeedback && presentationFeedback.length > 0){
            let item;
            for(let i in presentationFeedback){
                item = presentationFeedback[i];

                if(isFeedbackSave(result.data.feedbacks, item.codeSlide)){
                    item.isSaved = true;
                }
            }
        }
        
        localStorage.setItem(key, JSON.stringify(presentationFeedback));
    }
    
    const isFeedbackSave = (responseFeedbacks, codSlide) => {
        let isSaved = false;

        let item;
        for(var key in responseFeedbacks){
            item = responseFeedbacks[key];

            if(item.cod_Slide == codSlide){
                isSaved = true;
                break;
            }
        }

        return isSaved;
    }

    useEffect(() => {
        if(globalState.presentationBar.slideFeedbackResult && globalState.presentationBar.slideFeedbackResult.message == 'ok' && globalState.presentationBar.slideFeedbackResult.result){
            setFeedbacksStatus(globalState.presentationBar.slideFeedbackResult.result);
        }

    }, [globalState.presentationBar.slideFeedbackResult]);

    const arrowHandler = useCallback((event) => {
        //console.log('arrow', event.key);
        switch (event.key) {
            case 'ArrowRight': {
                let elem = document.getElementById('gotoNext');
                if(elem){
                    elem.click()
                }
                break;
            }
            case 'ArrowLeft': {
                let elem = document.getElementById('gotoPrevious');
                if(elem){
                    elem.click();
                }
                break;
            }
            default:
          }
    }, []);
    // Cuando inicia una presentacion inicio/detengo el envio de feedback
    useEffect(() => {
        if(
            globalState.presentationBar.displayScreenPresentation && 
            globalState.presentationBar.focusedPresentationSlides && 
            focusedPresentationSlides.type == 'slides'
        ){
            document.addEventListener('keydown', arrowHandler);
        }else{
            document.removeEventListener('keydown', arrowHandler);
        }

        if((globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2) && isPresentationFeedbackAllowed(focusedPresentationSlides.type)){
            if(globalState.presentationBar.displayScreenPresentation == true){
                startSendFeedback();

                setCurrentAssetCode(null);
            }else{
                stopSendFeedback();
            }    
        }

    }, [globalState.presentationBar.displayScreenPresentation]);

    useEffect(() => {
        if(
            globalState.presentationBar.showFilesDrawer ||
            globalState.presentationBar.showChatDrawer ||
            globalState.presentationBar.showPresentationList ||
            globalState.presentationBar.showSlideList ||
            globalState.presentationBar.notesDrawerOpenState 
        ){
            document.removeEventListener('keydown', arrowHandler);
        }else{
            if(
                globalState.presentationBar.displayScreenPresentation &&
                globalState.presentationBar.focusedPresentationSlides && 
                focusedPresentationSlides.type == 'slides'
            ){
                document.addEventListener('keydown', arrowHandler);
            }
        }

    }, [
        globalState.presentationBar.showFilesDrawer,
        globalState.presentationBar.showChatDrawer,
        globalState.presentationBar.showPresentationList,
        globalState.presentationBar.showSlideList,
        globalState.presentationBar.notesDrawerOpenState
    ])

    useEffect(() => {
        if((globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2) && isPresentationFeedbackAllowed(focusedPresentationSlides.type) && globalState.presentationBar.callClosedResult){
            stopSendFeedback();
        }
    }, [globalState.presentationBar.callClosedResult])

    useEffect(() => {
        if(currentAssetCode && globalState.presentationBar.focusedPresentationSlides && globalState.presentationBar.focusedPresentationSlides.content 
                && globalState.presentationBar.focusedPresentationSlides.content.length > 0){
            updateCurrentSlide(currentAssetCode);
        }

    }, [currentAssetCode]);

    useEffect(() => {
        if(globalState.presentationBar.initialSlideIndex && globalState.presentationBar.initialSlideIndex > 0){
            setCurrentAssetCode(null);
        }

    }, [globalState.presentationBar.initialSlideIndex]);
    
    useEffect(() => {
        window.addEventListener('message', handleIncomingMessage);

        /*
            Cuando se activa el toast se esta agregando el listener handleIncomingMessage
            cada vez que se llmama el sendDocument, y se envian mensajes duplicados
        */
        /*if (globalState.presentationBar.emailResult != '') {
            if (globalState.presentationBar.emailResult == 'ok') {
                props.setEmailResult('');
                props.setSnackFile({
                open: true, 
                snackMsg: intl.formatMessage({
                    id: "EMAIL.SEND.OK"
                }),
                snackBarSeverity: 'success'
                })
            } else {
                props.setEmailResult('');
                props.setSnackFile({
                open: true, 
                snackMsg: intl.formatMessage({
                    id: "EMAIL.SEND.ERROR"
                }),
                snackBarSeverity: 'error'
                })
            }
        }*/
    }, [/*globalState.presentationBar.emailResult*/])

    const {
        focusedPresentationSlides,
        focusedPresentationFlows,
        videoManager,
        numSlide,
        presentationFocused,
        initialSlideIndex
    } = globalState.presentationBar;

    let srcSetValues = '';
    let urlToDisplay = '';
    let nameToDisplay = '';
    let totalSlides = 0;
    //console.log(focusedPresentationSlides);
    if (focusedPresentationSlides.entryPoint) {
        nameToDisplay = presentationFocused.name;
    }

    if(focusedPresentationSlides.isOptimized == 1){
        let count = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_COUNT");
        let resizeCount = count != '' ? parseInt(count) : 0;
        
        if(resizeCount > 0){
            for(var i = 1; i <= resizeCount; i++){
                let sizeName = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_" + i + "_NAME");
                let sizeWidth = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_" + i + "_W");

                if (focusedPresentationSlides && focusedPresentationSlides.content && numSlide >= 0 && focusedPresentationSlides.content[numSlide]) {
                    let baseUrl = focusedPresentationSlides.baseUrl;
                    baseUrl = baseUrl.substring(baseUrl.length - 1, baseUrl.length) == '/' ? baseUrl.substring(0, baseUrl. length - 1) : baseUrl;
                    
                    let fileName = focusedPresentationSlides.content[numSlide].fileName.replace(/ /g, '%20');

                    srcSetValues += baseUrl + '/' + sizeName + '/' + fileName + ' ' + sizeWidth + 'w, ';
                }
            }

            srcSetValues = srcSetValues.substring(0, srcSetValues.length - 2);
        }
    }

    const appBar = {
        variant: 'dense'
    };

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        title: {
          flexGrow: 1,
        },
        slideCounter: {
            flexGrow: 0,
        },
        presentationContainerAbsolute: {
            height: appBar.variant == 'dense' ? 'calc(100% - 48px)' : 'calc(100% - 56px)',
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                height: appBar.variant == 'dense' ? 'calc(100% - 48px)' : 'calc(100% - 48px)',
            },
            [theme.breakpoints.up('sm')]: {
                height: appBar.variant == 'dense' ? 'calc(100% - 48px)' : 'calc(100% - 64px)',
            },
            width: '100%'
        }
      }));

      const classes = useStyles();

      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
        
      const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

    if (focusedPresentationSlides && focusedPresentationSlides.type) {

        let lang = '&lang=';
        if(globalState && globalState.auth.lang){
            lang += globalState.auth.lang;
        }else{
            lang += 'pt';
        }

        let email = '';
        if(globalState && globalState.auth.deployment && globalState.auth.deployment.callData && globalState.auth.deployment.callData.subscriberEmail){
            email = '&email=' + globalState.auth.deployment.callData.subscriberEmail;
        }

        let callCode = '';

        if(globalState && globalState.auth.deployment && globalState.auth.deployment.callData && globalState.auth.deployment.callData.code)
        {
            callCode = '&callcode=' + globalState.auth.deployment.callData.code;
        }

        let _initialSlide = '';
        if(focusedPresentationSlides && focusedPresentationSlides.content && focusedPresentationSlides.content.length > 0 && focusedPresentationSlides.content[initialSlideIndex]){
            if(focusedPresentationSlides.type == 'sdva'){
                _initialSlide = '&asset=' + focusedPresentationSlides.content[initialSlideIndex].name;
            }else if(focusedPresentationSlides.type == 'html5animate'){
                _initialSlide = '&asset=' + focusedPresentationSlides.content[initialSlideIndex].fileName;
            }
        }

        switch (focusedPresentationSlides.type) {
            case 'sdva':
                if (focusedPresentationSlides && focusedPresentationSlides.content && numSlide >= 0 && focusedPresentationSlides.content[numSlide]){
                    totalSlides = focusedPresentationSlides.content.length;

                    return (
                        <div className="presentation__container__main">
                            {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2 || globalState.auth.role === 'user') &&
                                <PresentationNavBar
                                    showMouseMove={props.showMouseMove} stopMouseMove={props.stopMouseMove} sendCoordinates={props.sendCoordinates} 
                                    type={focusedPresentationSlides.type} label={focusedPresentationSlides.name}
                                    variant={appBar.variant} numSlide={numSlide} totalSlides={totalSlides}
                                    focusedPresentationFlows={focusedPresentationFlows} previousFlow={previousFlow} nextFlow={nextFlow}
                                    goToPrevious={gotoPreviousSlideSDVA} goToNext={gotoNextSlideSDVA} closePresentation={closePresentation} showSlideList={showSlideList}
                                    preview={isPreview}
                                    gotoSlideSDVA={gotoSlideSDVA}
                                >
                                    
                                </PresentationNavBar>
                            }
    
                            <div className={classes.presentationContainerAbsolute}>
                                <div className="presentation__container__iframe">
    
                                    <div id="presentation__container__sdva" className="presentation__display__iframe">
                                        {((globalState.auth.role !== 'organizer' && globalState.auth.deployment.callData?.type !=2) || globalState.auth.role === 'user') && !props.preview &&
                                            <iframe
                                                id="inlineFrame"
                                                title={nameToDisplay}
                                                src={`${focusedPresentationSlides.entryPoint}&mode=viewer${lang}${email}${callCode}${_initialSlide}`}
                                                scrolling="no"
                                                style={{pointerEvents: props.isClickable == true ? "auto" : "none"}}
                                                allow="autoplay; fullscreen"
                                            >
                                            </iframe>
                                        }
                                        {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2 || globalState.auth.role === 'user') &&
    
                                            <div className={globalState.auth.deployment.callData?.type ==2? "presentation__display__image__centered": "presentation__display__image"}>
                                                <iframe
                                                    id="inlineFrame"
                                                    title={nameToDisplay}
                                                    src={`${focusedPresentationSlides.entryPoint}&mode=presenter${lang}${email}${callCode}${_initialSlide}`}
                                                    scrolling="no"
                                                    allow="autoplay; fullscreen">
                                                </iframe>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }else{
                    return ('');
                }
            case 'html5animate':
                if (focusedPresentationSlides && focusedPresentationSlides.content && numSlide >= 0 && focusedPresentationSlides.content[numSlide]){
                    totalSlides = focusedPresentationSlides.content.length;

                    return (
                        <div className="presentation__container__main">
                            {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2 || globalState.auth.role === 'user') &&
                                <PresentationNavBar 
                                    showMouseMove={props.showMouseMove} stopMouseMove={props.stopMouseMove} sendCoordinates={props.sendCoordinates}
                                    type={focusedPresentationSlides.type} label={focusedPresentationSlides.name}
                                    variant={appBar.variant} numSlide={numSlide} totalSlides={totalSlides}
                                    goToPrevious={gotoPreviousSlideAAN} goToNext={gotoNextSlideAAN} closePresentation={closePresentation} showSlideList={showSlideList}
                                    preview={isPreview}
                                    >
                                </PresentationNavBar>
                            }
                            
                            <div className={classes.presentationContainerAbsolute}>
                                <div className="presentation__container__iframe">
    
                                    <div id="presentation__container__html5" className="presentation__display__iframe">
                                        {(globalState.auth.role !== 'organizer' && !isPreview) && 
                                            <iframe
                                                id="inlineFrameHtml5"
                                                title={nameToDisplay}
                                                src={`${focusedPresentationSlides.entryPoint}&mode=viewer${lang}${email}${callCode}${_initialSlide}`}
                                                scrolling="no"
                                                style={{pointerEvents: props.isClickable == true ? "auto" : "none"}}
                                                allow="autoplay; fullscreen" >
                                            </iframe>
                                        }
                                        {(globalState.auth.role === 'organizer' || (globalState.auth.role === 'user' && isPreview)) &&
                                            <div className="presentation__display__image">
                                                <iframe
                                                    id="inlineFrameHtml5"
                                                    title={nameToDisplay}
                                                    src={`${focusedPresentationSlides.entryPoint}&mode=presenter${lang}${email}${callCode}${_initialSlide}`}
                                                    scrolling="no"
                                                    allow="autoplay; fullscreen">
                                                </iframe>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }else{
                    return('');
                }
            case 'video':
                if (focusedPresentationSlides && focusedPresentationSlides.content && numSlide >= 0 && focusedPresentationSlides.content[numSlide]) {
                    totalSlides = focusedPresentationSlides.content.length;
                
                    const videoJsOptions = {
                        fuid: true,
                        fill: true,
                        autoplay: false,
                        controls: true,
                        sources: [{
                            src: focusedPresentationSlides.baseUrl
                        }]
                    }
    
                    const events = ['pause', 'play', 'ended', 'volumechange', 'timeupdate', 'seeked'];
    
                    const categoryEventTypes = {
                        play: 'VIDEO_PLAY',
                        pause: 'VIDEO_PAUSE',
                        ended: 'VIDEO_STOP',
                        mute: 'VIDEO_MUTE',
                        unmute: 'VIDEO_UNMUTE',
                        seeked: 'VIDEO_SEEK',
                        timeupdate: 'VIDEO_PROGRESS'
                    };
    
                    const videoPlayerLog = (result) => {
                        if(!isPreview){
                            if(categoryEventTypes[result.type] == categoryEventTypes.timeupdate){
                                props.log({
                                    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                                    log_category: categoryEventTypes[result.type],
                                    log_text: `code: ${globalState.presentationBar.presentationFocused.code} | currentTime: ${result.currentTime} | duration: ${result.duration} | volume: ${result.volume} | isMuted: ${result.isMuted} | height: ${result.height} | width: ${result.width}`
                                });
                            }else{
                                props.log({
                                    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
                                    log_category: categoryEventTypes[result.type],
                                    log_text: `code: ${globalState.presentationBar.presentationFocused.code} | currentTime: ${result.currentTime} | duration: ${result.duration} | volume: ${result.volume} | isMuted: ${result.isMuted}`
                                });
                            }
                        }
                    };
    
                    const onVideoReady = (eventData) => {       
                        if(globalState.auth.deployment.callData?.type !=2){       
                            props.onVideoReady();
                        }
                    };
    
                    const onVideoDisposed = (eventData) => {
                        if(globalState.auth.deployment.callData?.type !=2){
                            props.onVideoDisposed();
                        }
                    };
    
                    if (!nameToDisplay) {
                        nameToDisplay = presentationFocused.name;
                    }
    
                    return (
                        <div className="presentation__container__main">
                            {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2 || globalState.auth.role === 'user') &&
                                <>
                                <PresentationNavBar
                                    showMouseMove={props.showMouseMove} stopMouseMove={props.stopMouseMove} sendCoordinates={props.sendCoordinates} 
                                    type={focusedPresentationSlides.type} label={focusedPresentationSlides.name} variant={appBar.variant}
                                    numSlide={numSlide} totalSlides={totalSlides} closePresentation={closePresentation}
                                    preview={isPreview}
                                >
                                </PresentationNavBar>
                                <VideoPlayer events={events} logdelay={10000} onVideoReady={onVideoReady} onVideoDisposed={onVideoDisposed} onlog={videoPlayerLog} { ...videoJsOptions } />
                                </>
                            }
                        </div>
                    )
                }
            default:
                
                if (focusedPresentationSlides && focusedPresentationSlides.content && numSlide >= 0 && focusedPresentationSlides.content[numSlide]) {
                    urlToDisplay = focusedPresentationSlides.content[numSlide].url;
                    nameToDisplay = focusedPresentationSlides.name;
                    totalSlides = focusedPresentationSlides.content.length;

                    return (

                        <div className="presentation__container__main">

                            {(globalState.auth.role === 'organizer' || globalState.auth.deployment.callData?.type ==2 || globalState.auth.role === 'user') &&
                                <PresentationNavBar
                                    showMouseMove={props.showMouseMove} stopMouseMove={props.stopMouseMove} sendCoordinates={props.sendCoordinates}
                                    type={focusedPresentationSlides.type} label={focusedPresentationSlides.name}
                                    variant={appBar.variant} numSlide={numSlide} totalSlides={totalSlides} 
                                    goToPrevious={gotoPreviousSlide} goToNext={gotoNextSlide} closePresentation={closePresentation} showSlideList={showSlideList}
                                    preview={isPreview}
                                    >
                                </PresentationNavBar>
                            }

                            <div className={classes.presentationContainerAbsolute}>
                                <div className="presentation__container">
                                    <div id="presentation__container" className={globalState.auth.deployment.callData?.type ==2? "presentation__display__image__centered": "presentation__display__image"}>
                                        {focusedPresentationSlides.isOptimized == 1 &&
                                            <div>
                                                {srcSetCurrentImage != '' &&
                                                    <span className="visualaid__current__slide">
                                                        {srcSetCurrentImage}
                                                    </span>
                                                }
                                                
                                                <img
                                                    id="slideImage"
                                                    onLoad={() => { showCurrentSlide() }}
                                                    srcSet={srcSetValues}
                                                    src={urlToDisplay}
                                                    alt=""
                                                />
                                            </div>
                                        }
                                        {focusedPresentationSlides.isOptimized == 0 &&
                                            <img
                                                src={urlToDisplay}
                                                alt=""
                                            />
                                        }
                                    </div>                           
                                </div>
                            </div>

                        </div>
                    )
                } else {
                    return (null)
                }
        }
    } else {
        return (null);
    }


}

export default injectIntl(
    connect(
        null,
        { ...presentationBarStorage.actions, ...loggerStorage.actions }
    )(Presentation)
);