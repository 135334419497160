import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import * as fileStorage from "../../../modules/Files/file.duck";
import { FormattedMessage, injectIntl } from "react-intl";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const DropzoneButtons = (props) => {
  const globalState = useSelector(state => state);
  const { fileSelected, setFileSelected, deleteFileInDropzone } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if(globalState.file.fileUploaded) {
      setShowSpinner(false);
      setDisabled(false);
    }
  }, [globalState.file.fileUploaded])

  const handleAccept = () => {
    if(Object.entries(fileSelected).length !== 0) {
      setShowSpinner(true);
      setDisabled(true);

      const filename = nameWithoutExt(fileSelected[0].name);
      // console.log("1")
      props.createFileContainer({
        name: filename
      });
    }
  }
  
  const handleCancel = () => {
    deleteFileInDropzone();
    setFileSelected({});
  }

  return (
    <ThemeProvider theme={theme}> 
      <div className="d-flex align-items-center justify-content-center mt-4">
        <Button 
          onClick={handleAccept}
          size="large" 
          color="primary" 
          id="btnFileAccept"
          disabled={disabled}
        >
          <FormattedMessage id="FOLDERS.FILE_ACCEPT" />
          {showSpinner && <CircularProgress size={20} color="inherit" style={{marginLeft: 5}}/>}
        </Button>
    
        <Button 
          onClick={handleCancel} 
          size="large" 
          color="secondary" 
          id="btnFileCancel" 
          disabled={disabled}
        >
          <FormattedMessage id="FOLDERS.FILE_CANCEL" />
        </Button>
      </div>
    </ThemeProvider>                
  );
}

const theme = createTheme({
  palette: {
    secondary: {
      main: "#fd397a"
    },
    primary: {
      main: "#0abb87"
    }
  },
});

const nameWithoutExt = (filename) => {
  return filename.substring(0, filename.lastIndexOf(".")); 
}

export default injectIntl(
  connect(
    null,
    fileStorage.actions
  )(DropzoneButtons)
);