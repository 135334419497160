/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from 'react';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import NewVisitPopUp from '../NewVisitPopUp';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import DateFnsUtils from '@date-io/date-fns';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as authStorage from "../../store/ducks/auth.duck";
import * as dashboardStorage from "../../store/ducks/dashboard.duck";
import CallDetailsPopUp from '../CallDetailsPopUp';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { pt, es, enUS } from 'date-fns/locale'
import CallIcon from '@material-ui/icons/Call';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './Agenda.scss';
import LinkCopyClientButton from '../LinkCopyClientButton';
import SendEmailButton from '../SendEmailButton';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Moment from "react-moment";
import { isMobile, isIPad13, isIPhone13, isSafari } from 'react-device-detect';
import WhatsAppButton from '../WhatsAppButton';
import CallActionEditMenu from '../CallActionEditMenu';
import CallActionShareMenu from '../CallActionShareMenu';
import CallIndicators from '../CallIndicators/CallIndicators';
import { defaultStyles } from '../../../globalConfig';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import { getAppInsights } from '../../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import * as userStorage from "../../modules/Users/users.duck";
import Tooltip from '@material-ui/core/Tooltip';
import ArchivePopUp from './ArchivePopUp';
import ListOfCallsContainer from './ListOfCallsContainer';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Agenda = (props) => {
  const { intl } = props;
  const [selectedFrom, setSelectedFrom] = React.useState(new Date());
  const [logout, setLogout] = React.useState(false);
  const [snackShowed, setSnackShowed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  let dateTo = new Date()
  const [selectedTo, setSelectedTo] = React.useState(new Date(dateTo.setDate(dateTo.getDate() + 7)));
  // prueba de redux con hook DEBUG
  const globalState = useSelector(state => state);
  
  const callGuestQty = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_GUEST_QTY");
  const [archivePopUpOpen, setArchivesPopUpOpen] = React.useState(false);

  useEffect(() => {
    //listener will be added on component mounted
    if ((callGuestQty && callGuestQty == '1') && (!globalState.users.users || globalState.users.users.length <= 0)) {
      props.getUsers({allow_guest: 1});
    }
  }, []);

  let locale = pt;
  if (globalState && globalState.auth.lang) {
    switch (globalState.auth.lang) {
      case "es":
        locale = es;
        break;
      case "en":
        locale = enUS;
        break;
      default:
        break;
    }
  }

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }

  useEffect(() => {
    //listener will be added on component mounted
    if (selectedFrom && selectedTo) {
      props.getCallsByDate({ selectedFrom, selectedTo });
      props.analyticsLogEvent('enter Agenda');
    }
    return () => {
      setLogout(false);
      setSnackShowed(false);
    }
  }, []);

  useEffect(() => {
    if (globalState.dashboard.passwordResult === true) {
      setLogout(true);
    }
    setSnackShowed(false);
  }, [globalState.dashboard.passwordResult])


  useEffect(() => {
    if(globalState.auth.selectedCallCode){
      if (globalState.auth.urlTokenResult) {
        window.location.href = `/call/${globalState.auth.urlToken.urlToken.callcode}/${globalState.auth.userid}?token=${globalState.auth.urlToken.urlToken.token}`;
      }else{
        // TODO: poner el texto en i18n
        showToastImportantNotification('Error al obtener token', 'red');
      }
    }
  }, [globalState.auth.urlTokenResult])


  // si llega una notificacion con importancia menor a 2 este flag s epone ne true
  useEffect(() => {
    if (globalState.schedule.importantNotification === true) {

      showToastImportantNotification(intl.formatMessage({ id: "SCHEDULE.NEW_NOTIFICATIONS" }), 'red');
      props.setImportantNotification(false);
    }
  }, [globalState.schedule.importantNotification]);

  useEffect(() => {
    Object.assign(globalState.presentationBar.customerSelected, globalState.presentationBar.customerSelected);
  }, [globalState.presentationBar.customerSelected]);

  useEffect(() => {
    Object.assign(globalState.auth, globalState.auth);
  }, [globalState.auth.userid]);

  const handleDateChangeFrom = (date) => {
    if (date && date < selectedTo) {
      setSelectedFrom(date);
      props.getCallsByDate({ selectedFrom: date, selectedTo });
    } else {
      let dateFrom = new Date();
      let dateTo = new Date();
      setSelectedFrom(dateFrom);
      dateTo = new Date(dateTo.setDate(dateTo.getDate() + 7));
      setSelectedTo(dateTo);
      props.getCallsByDate({ selectedFrom: dateFrom, selectedTo: dateTo });
    }

  }

  const handleDateChangeTo = (date) => {

    if (date && moment(selectedFrom).isSameOrBefore(moment(date), 'day')) {
      setSelectedTo(date);
      props.getCallsByDate({ selectedFrom, selectedTo: date });
    } else {
      let dateFrom = new Date();
      let dateTo = new Date();
      setSelectedFrom(dateFrom);
      dateTo = new Date(dateTo.setDate(dateTo.getDate() + 7));
      setSelectedTo(dateTo);
      props.getCallsByDate({ selectedFrom: dateFrom, selectedTo: dateTo });
    }
  }

  const handleSearch = (item) => event => {
    props.getCallsByDate({ selectedFrom, selectedTo });
    setLoading(true);
  }


  const handleSubmit = (date, selectedObject) => event => {
    if (globalState.users.userSelected && globalState.users.userSelected.uuid && globalState.users.userSelected.uuid == globalState.auth.userid) {
      showToastImportantNotification(intl.formatMessage({id: "CALL.DETAILS.INVALID.SELECTION"}),'red');
    } else {
      if (selectedObject == {}) {
        Object.assign(globalState.presentationBar.callNew, { date: new Date(), open: true, error: true });
        props.updateNewCall(globalState.presentationBar.callNew);
        Object.assign(globalState.presentationBar.calls, globalState.presentationBar.calls);
      } else {
        date = moment(date).add(-1 * globalState.auth.timezone, 'hours').format("YYYY-MM-DDTHH:mm");
        if(globalState.users.userSelected && globalState.users.userSelected.uuid){
          props.createCall({ ...selectedObject, date, selectedFrom: selectedFrom, selectedTo: selectedTo, userId: globalState.auth.userid, guestId: globalState.users.userSelected.uuid });
          props.updateUserSelected(null);
        }else{
          props.createCall({ ...selectedObject, date, selectedFrom: selectedFrom, selectedTo: selectedTo, userId: globalState.auth.userid, type: globalState.presentationBar.visitTypeSelected.code });
        }
        props.analyticsLogEvent('Call Created');
        Object.assign(globalState.presentationBar.callNew, { date: new Date(), open: false, error: false });
        Object.assign(globalState.presentationBar.customerSelected, {});
        props.updateCustomerSelected(globalState.presentationBar.customerSelected)
      }
    }
  }

  const handleCloseSnackBar = () => {
    setSnackShowed(true);
    props.setPasswordResult(null);
  }



  if (logout && snackShowed) {
    return <Redirect to="/logout" />
  }

  return (
    <div className="col-xl-12 d-flex justify-content-center">
      <Snackbar
        open={(globalState.dashboard.passwordResult !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.dashboard.passwordResult) ? 'success' : 'error'}>
          {globalState.dashboard.passwordResult ?
            <FormattedMessage id="AUTH.POPUP.SNACK_SUCCESS" />
            :
            <FormattedMessage id="AUTH.POPUP.SNACK_ERROR" />
          }
        </Alert>
      </Snackbar>
      {globalState.schedule.archivesPopUpOpen && 
        <ArchivePopUp />
      }
      <div className="kt-portlet kt-portlet--height-fluid col-md-6 relative">
        {(globalState.presentationBar.loading || globalState.dashboard.loading) && <LinearProgress className="progressBar" />}
        {(globalState.presentationBar.loading) && <div className="progressBackground"></div>}
        <div style={(isMobile || isIPad13 || isIPhone13 || isSafari) ? { marginTop: '10%' } : null} className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="SCHEDULE.VISIT.TITLE" /></h3>
          </div>
          <NewVisitPopUp handleSubmit={handleSubmit} displayButton={true} />
        </div>
        <div className="kt-portlet__body">
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <Grid container justify="space-around" alignItems="flex-end">
              <KeyboardDatePicker
                className="col-md-5"
                margin="normal"
                id="mui-pickers-date-from"
                label={intl.formatMessage({
                  id: "SCHEDULE.DATE.FROM"
                })}
                value={selectedFrom}
                onChange={handleDateChangeFrom}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                cancelLabel={intl.formatMessage({
                  id: "SCHEDULE.EDIT.DATE_RANGE.CLOSE"
                })}
              />
              <div className="col-md-1 text-center"></div>
              <KeyboardDatePicker
                className="col-md-5"
                margin="normal"
                id="mui-pickers-date-to"
                label={intl.formatMessage({
                  id: "SCHEDULE.DATE.TO"
                })}
                value={selectedTo}
                onChange={handleDateChangeTo}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                cancelLabel={intl.formatMessage({
                  id: "SCHEDULE.EDIT.DATE_RANGE.CLOSE"
                })}
              />
              <div className="col-md-1 text-center">
                <IconButton color="primary" aria-label="upload picture" id="btnAgendaSearch" component="span" onClick={handleSearch()}>
                  <SearchIcon />
                </IconButton>
              </div>
            </Grid>
          </MuiPickersUtilsProvider>
          <div className="kt-widget4">
            <br></br>
            {(selectedFrom && selectedTo) ? <CallIndicators selectedFrom={selectedFrom} selectedTo={selectedTo} /> : null}
            <br></br>
          </div>
          <div className="kt-widget4">
            {<ListOfCallsContainer setLoading={setLoading} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    {
      ...presentationBarStorage.actions,
      ...authStorage.actions,
      ...scheduleStorage.actions,
      ...dashboardStorage.actions,
      ...userStorage.actions
    }
  )(Agenda)
);