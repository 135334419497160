import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { getDeploymentParamValue } from '../SlideList/utils';

const useStyles = makeStyles((theme) => ({
    listItem: {
        flexDirection: 'row'
    },
    listItemAvatar: {
        marginRight: 10
    },
    listItemImg: {
        width: '85px',
        height: '53px',
        borderRadius: 5,
        border: 1
    }
}));

const FlowItem = (props) => {
    const {flow, index, selectedIndex, intl} = props;
    const classes = useStyles();
    const globalState = useSelector(state => state);

    const handleListItemClick = function (e, index) {
        props.updateSelectedIndexFlow(index)
    }
        
    const handleOnImgError = (e) => {
        if(globalState.auth.deployment.parameters){
            var potentialUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "VA_LOGO_URL");
            if(!potentialUrl) {
                potentialUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "LOGO_URL");
            }

            e.currentTarget.src = potentialUrl;
        }
        
    }

    const handleViewClick = function(flow) {
        props.updateCurrentFlow(flow);
    }

    return (
        <ListItem 
            button 
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
            className={classes.listItem}
        >
            <ListItemAvatar className={classes.listItemAvatar}>
                <img className={classes.listItemImg} src={flow.url} onError={handleOnImgError} alt="" />
            </ListItemAvatar>
            <ListItemText primary={flow.name} secondary={`${flow.flow.length} Slides`} />
            <Button size="medium" color='primary' onClick={() => handleViewClick(flow)}>
                { intl.formatMessage({id: "PRESENTATION.BAR.VIEW"}) }
            </Button>
        </ListItem>
    )
}

export default injectIntl(
    connect(
        null,
        presentationBarStorage.actions
    )(FlowItem)
);