import React, {useEffect, useState} from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

// Importando el duck de presentationBar cambiar logica de duck
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
//Importing styles of the component
import './SlideList.scss';

import {
    getDeploymentParamValue,
} from './utils';
import SlideItem from '../SlideItem/SlideItem';
import FlowItemContainer from '../FlowItemContainer/FlowItemContainer';
import SlideListControls from '../SlideListControls/SlideListControls';
import { useGetFlows } from './hooks/useGetFlows';

const SlideList = function (props) {
    const globalState = useSelector(state => state);
    const [previewSrcSetValues, setPreviewSrcSetValues] = useState('');
    const [imgPreview, setImgPreview] = useState('');
    const [totalSlides, setTotalSlides] = useState(0);
    const [counter, setCounter] = React.useState(0);
    const [vaLogoUrl, setValogoUrl] = React.useState('');
    const [slidesArr, setSlidesArr] = React.useState([]);
    const { flows } = useGetFlows(globalState);
    const playSpecificSlide = props.playSpecificSlide;
    const { intl } = props;
    const { 
        presentationSlides: { 
            indexOfSelectedSlide, 
            content 
        }, 
        presentationFlows: { 
            indexOfSelectedFlow, 
            currentFlow 
        } 
    } = globalState.presentationBar;

    useEffect(() => {
        if(globalState.presentationBar.presentationSlides.content &&
            globalState.presentationBar.presentationSlides.content.length > 0
        ){
            globalState.presentationBar.presentationSlides.content.map((s) => {
                s.state = '';
                s.active = '';
            });
            setSlidesArr(globalState.presentationBar.presentationSlides);
        };
    }, []);

    useEffect(() => {
        if (flows && flows.length > 0) {
            props.setFlows(flows);
        }
    }, [flows]);

    useEffect(() => {
        const parameters = globalState.auth.deployment.parameters;
        if (parameters) {
            const vaLogoUrlValue = getDeploymentParamValue(parameters, "VA_LOGO_URL");
            const logoUrlValue = getDeploymentParamValue(parameters, "LOGO_URL");
            
            const url = vaLogoUrlValue || logoUrlValue;
            setValogoUrl(url);
        }
    }, [globalState.auth.deployment.parameters]);

    useEffect(() => {
        // si entro en una presentacion que no es la que se esta mostrando actualmente entonces seleccionar el primer elemento
        if( globalState.presentationBar.responseSlidesDrawer === true &&
            globalState.presentationBar.focusedPresentationSlides.code && // globalState.presentationBar.focusedPresentationSlides es la presentacion que esta siendo reproducida con array de imagenes content
            globalState.presentationBar.presentationSlides.code && // globalState.presentationBar.presentationSlides es la presentacion que esta siendo mostrada en el drawer
            (globalState.presentationBar.presentationSlides.code !== globalState.presentationBar.focusedPresentationSlides.code)
        ) {
            //setSlidesArr(globalState.presentationBar.presentationSlides.content);
            props.fillResponseSlidesDrawer(null);
            setCounter(counter+1);
        }
    }, [globalState.presentationBar.presentationSlides.code, globalState.presentationBar.responseSlidesDrawer]); 

    const isFlowReadyForPreview = () => (
        flows &&
        flows.length > 0 &&
        flows[indexOfSelectedFlow] &&
        Object.keys(currentFlow).length === 0
    );
    
    const isSlideReadyFromCurrentFlow = () => (
        Object.keys(currentFlow).length > 0 &&
        currentFlow.slides &&
        currentFlow.slides.length
    );
    
    const isSlideReadyFromContent = () => (
        content && 
        content.length > 0 && 
        content[indexOfSelectedSlide]
    );
    
    useEffect(() => {
        if(isFlowReadyForPreview()){
            setFlowPreview(flows[indexOfSelectedFlow]);
        } else if(isSlideReadyFromCurrentFlow()){
            setSlidePreviews(currentFlow.slides[indexOfSelectedSlide]);
        } else if(isSlideReadyFromContent()){
            setSlidePreviews(content[indexOfSelectedSlide]);
        } else if(globalState.presentationBar.presentationSlides.content && globalState.presentationBar.presentationSlides.content.length > 0){
            setSlidePreviews(content[0]);
        }
    }, [
        indexOfSelectedSlide, 
        indexOfSelectedFlow, 
        flows, 
        currentFlow, 
        content
    ])

    let baseUrl = '';
    if(globalState.presentationBar.presentationSlides.code == globalState.presentationBar.presentationFocusedSlides.code){
        baseUrl = globalState.presentationBar.presentationFocusedSlides.baseUrl;
    }

    if(globalState.presentationBar.presentationSlides.isOptimized == 1 && baseUrl != ''){
        let count = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_COUNT");
        let resizeCount = count != '' ? parseInt(count) : 0;
        
        if(resizeCount > 0){
            for(var i = 1; i <= resizeCount; i++){
                let sizeName = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_" + i + "_NAME");
                let sizeWidth = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_RESIZE_" + i + "_W");

                for(var j = 0; j < globalState.presentationBar.presentationSlides.content.length; j++){
                    let _slide = globalState.presentationBar.presentationSlides.content[j];
                    if(_slide.srcSetValues === undefined || i == 1){
                        _slide.srcSetValues = '';
                    }

                    if (_slide) {
                        let fileName = _slide.fileName.replace(/ /g, '%20');

                        _slide.srcSetValues += baseUrl + '/' + sizeName + '/' + fileName + ' ' + sizeWidth + 'w, ';

                        if (i >= resizeCount){
                            _slide.srcSetValues = _slide.srcSetValues.substring(0, _slide.srcSetValues.length - 2);
                        }
                    }
                }
            }
        }
    }

    const setSlidePreviews = (slide) => {
        setImgPreview(slide.url);
        setPreviewSrcSetValues(slide.srcSetValues);
    }

    const setFlowPreview = (flow) => {
        setImgPreview(flow.url);
    }

    const handleOnImgError = (e) => {
        e.currentTarget.src = vaLogoUrl;   
    }

    return (
        <div>
            <div className="presentation__preview__container">
                <div className="presentation__preview__main">
                    {
                        (globalState.presentationBar.loading) && <LinearProgress className="progressBar" />
                    }
                    {
                        globalState.presentationBar.presentationSlides.isOptimized == 1 && previewSrcSetValues &&
                        <img srcSet={previewSrcSetValues} src={imgPreview} alt="" sizes="(min-width: 40px) 319px" />
                    }
                    {
                        globalState.presentationBar.presentationSlides.isOptimized == 0 &&
                        <img src={imgPreview} onError={(e) => handleOnImgError(e)} alt="" />
                    }
                </div>
            </div>
            <div className="presentation__slides__container">
                {
                    (globalState.presentationBar.loading) && 
                    <div className="presentation__loading__mask"></div>
                }

                {
                    globalState.presentationBar.presentationSlides.content ?
                    <div className="presentation__controls__container">
                        <SlideListControls 
                            color={props.deploymentStyle.accentColor} 
                            playSpecificSlide={playSpecificSlide}
                            playFlow={props.playFlow}
                        />
                    </div> : null
                }

                <div className="presentation__items__container">
                    {
                        flows && flows.length > 0 && Object.keys(globalState.presentationBar.presentationFlows.currentFlow).length === 0 ?
                        <FlowItemContainer/>
                        :
                        <SlideItem 
                            borderColor={props.deploymentStyle.accentColor} 
                            backgroundColor={props.deploymentStyle.accentColor}
                            vaLogoUrl={vaLogoUrl}
                            setCounter={setCounter}
                            counter={counter}
                            playSpecificSlide={playSpecificSlide}
                        />
                    }
                    
                    <i aria-hidden="true"></i>
                    <i aria-hidden="true"></i>
                    <i aria-hidden="true"></i>
                </div>
            </div>

        </div>
    )
}

export default injectIntl(
    connect(
        null,
        presentationBarStorage.actions
    )(SlideList)
);