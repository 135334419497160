import React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import './CallNotes.scss';
import Drawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const CallNotes = (props) => {
  const [open, setOpen] = useState(false);
  const { deployment } = useSelector(state => state.auth);
  const classes = useStyles();
  const [valueText, setValueText] = React.useState('');

  useEffect(() => {
    try{
      let textStorage = localStorage.getItem("callnotes_" + props.callcode);
      if(textStorage){
        setValueText(textStorage);
      }
    }catch(e){
      console.log(e);
    }
  }, [])

  try{
    let textStorage = localStorage.getItem("callnotes_" + props.callcode);
    if( textStorage && valueText !== textStorage){
      setValueText(textStorage);
    }
  }catch(e){
    console.log(e)
  }

  const toggleCallNotesDrawer = (value) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') && event.type === 'click') {
      return;
    }
    props.setNotesDrawerOpenState(value)
    setOpen(value);
  }

  const handleChangeText = (event) => {
    setValueText(event.target.value);
    try{
      localStorage.setItem("callnotes_" + props.callcode, event.target.value);
    }catch(e){
      console.log(e);
    }

  };

  const accentColor = deployment.parameters.filter((item) => {
    return item.key === "ACCENT_COLOR_1"
  })[0];

  return(
    <>
    <div id="buttonNotes" className={`ots-video-control circle`}>
      <Button onClick={toggleCallNotesDrawer(true)} style={{ height: '100%'}}>
        <EditIcon style={{color: 'white'}} />
      </Button>
    </div>
    <Drawer
        anchor="right"
        open={open}
        onClose={toggleCallNotesDrawer(false)}
        onOpen={toggleCallNotesDrawer(true)}
        disableBackdropTransition={!iOS} 
        disableDiscovery={iOS}
        ModalProps={props.isFull?{
          container: document.getElementById('callContainer'),
          style: { position: 'absolute' }
        }:{
          style: { position: 'absolute' }
        }}
      >
        <div className="callNotes__container">
          <div className="callNotes__header">
            <div className="callNotes__header__text" style={{color: accentColor.value}}>
              <FormattedMessage id="CALL_NOTES.BAR.TITLE" />
            </div>
            <IconButton id="closeCallNotes" onClick={toggleCallNotesDrawer(false)} className="callNotes__header__close" style={{color: accentColor.value}}>
              <CloseIcon />
            </IconButton>
          </div> {/* HEADER_NOTES */}
          <div className="callNotes__body">
            <div className="callNote__text__container">
              <textarea
                id="standard-multiline-flexible"
                value={valueText}
                onChange={handleChangeText}
                style={{ height: '100%', width: '100%', padding: 10 }}
              >
              </textarea>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(CallNotes)
);