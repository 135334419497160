import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as folderStorage from "../../store/ducks/folders.duck";
import './Folders.scss'
import { Box, Button } from '@material-ui/core';
import FolderItem from './FolderItem';

function arrayToTree(folders, files) {
  let hashmap = {};

  // Crea un hashmap con las carpetas y sus hijos (files incluidos)
  folders.forEach((folder) => {
    if(!hashmap[folder.code]) {
      hashmap[folder.code] = {...folder, children: []};
    }
  });

  if(files){
    files.forEach((file) => {
      if(hashmap[file.cod_folder]) {
        hashmap[file.cod_folder].children.push(file);
      }
    });
  }

  // Convierte el hashmap en un tree
  let tree = [];
  folders.forEach((folder) => {
    if(folder.cod_parent === 0) {
      tree.push(hashmap[folder.code]);
    } else if(hashmap[folder.cod_parent]) {
      hashmap[folder.cod_parent].children.unshift(hashmap[folder.code]);
      hashmap[folder.cod_parent].children.sort((a, b) => {
        if (!('mimeType' in a) && !('mimeType' in b)) {
          return a.name.localeCompare(b.name);
        }
        
      });
    }
  });

  tree.sort((a, b) => a.name.localeCompare(b.name));

  return tree;
}

const getFoldersIds = (folders) => {
  return folders?.map((f) => f.code);
}

function isNonEmptyArray(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

const Folders = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);
  const [foldersArr, setFoldersArr] = useState([]);
  const [foldersTree, setFoldersTree] = useState([]);
  const [selected, setSelected] = useState("");
  const [expanded, setExpanded] = useState([]);
  const [arrIds, setArrIds] = useState([]); // Array de los `code` de todos los folders
  const [files, setFiles] = useState([]);
  const { isFromPopUp } = props;

  useEffect (() => {  
    if(!isFromPopUp) {
      props.fillPromotionalPlan(1);
      props.getFileTagsOptions();
    }
    
  }, [])

  useEffect (() => {
    props.getFolders();  
    if(!isFromPopUp) {
      props.getFiles({ stateId: 1, code: -1, intl: intl }); 
    }
    
  }, [globalState.folders.folderResponse, globalState.folders.inactivateFolderResult, globalState.file.editFileResult, globalState.file.inactivateFileResult])

  useEffect(() => {
    if(isNonEmptyArray(globalState.folders.folders)) {
      setFoldersArr([...globalState.folders.folders]);
    }

    if(isNonEmptyArray(globalState.folders.files)) {
      setFiles([...globalState.folders.files]);
    }

  }, [globalState.folders.folders, globalState.folders.files])

  useEffect(() => {
    var tree;
    if(isNonEmptyArray(files) && isNonEmptyArray(foldersArr)) {
      tree = arrayToTree(foldersArr, files);
      setFoldersTree(tree);

    } else if(isNonEmptyArray(foldersArr)) {
      tree = arrayToTree(foldersArr);
      setFoldersTree(tree);

    }

    setArrIds(getFoldersIds(foldersArr));
 
  }, [files, foldersArr])

  const handleOnNodeSelect = (_, newSelected) => {
    const folderSelected = foldersArr.find(folder => folder.code == newSelected);
    const fileSelected = files.find(file => file.code == newSelected);
  
    if (folderSelected) {
      setSelected(newSelected);
      props.setSelectedFolder(folderSelected);
      props.setSelectedFile({});
    } else if (fileSelected) {
      setSelected(newSelected);
      props.setSelectedFile(fileSelected);
      props.setSelectedFolder({});
    }
  }

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded?.length === 0 ? arrIds : [],
    );
  };

  const handleDeselect = () => {
    setSelected("");
    props.setSelectedFolder({});
    props.setSelectedFile({});
  }

  var styles;
  if(props.height) {
    styles = {
      maxHeight: props.height,
      flexGrow: 1, 
      overflowY: 'auto'
    }
  } else {
    styles = { 
      flexGrow: 1,
      maxWidth: 400
    }
  }

  return (
    <Box className={!isFromPopUp ? 'container-scroll' : ''}>
      <Button onClick={handleExpandClick}>
        {expanded?.length === 0 ? <FormattedMessage id="FOLDERS.EXPAND_ALL"/> : <FormattedMessage id="FOLDERS.COLLAPSE_ALL"/>}
      </Button>

      {selected &&
        <Button onClick={handleDeselect}>
          <FormattedMessage id="FOLDERS.FOLDER_DESELECT"/>
        </Button>
      }
      
      <TreeView
        aria-label="foldersTree"
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        style={styles}
        onNodeSelect={handleOnNodeSelect}
        selected={selected}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >

        {
          foldersTree.map((folder) => {
            return(
              <FolderItem key={folder.code} folder={folder} />
            )
          })
        }
      
      </TreeView>
    </Box>
  );
}

export default injectIntl(
    connect(
      null,
      folderStorage.actions
    )(Folders)
  );