import axios from "axios";
import {configUrls} from '../../globalConfig';

export const LIST_BY_CALL_URL = `${configUrls.base_url}/api/visualaid/listbycall/`;
export const SLIDES_URL = `${configUrls.base_url}/api/visualaid/`;
export const CALLS_URL = `${configUrls.base_url}/api/call/search/byDate?`;
export const CREATE_CALL_URL = `${configUrls.base_url}/api/call/create`;
export const GET_CUSTOMER_TYPE_LIST_URL = `${configUrls.base_url}/api/EntityTypes/0`;
export const GET_CUSTOMER_LIST_BY_TYPE_URL = `${configUrls.base_url}/api/customer/search/byType`;
export const GET_CUSTOMER_CALL_HISTORY = `${configUrls.base_url}/api/call/search/byCustomer?`;
export const SEND_EMAIL_URL = `${configUrls.base_url}/api/call/action/sendemail/`;
export const SEND_FILE_EMAIL_URL = `${configUrls.base_url}/api/call/action/sendemailfile/`;
export const SEND_VAID_FILE_EMAIL_URL = `${configUrls.base_url}/api/call/action/sendemailvisualaid/`;
export const CLOSE_CALL_URL = `${configUrls.base_url}/api/call/action/end/`;
export const SAVE_PROPERTY = `${configUrls.base_url}/api/call/properties/save/`;
export const NETWORK_TEST = `${configUrls.base_url}/api/call/action/test/`;
export const FILE_LIST_URL = `${configUrls.base_url}/api/file/listbycall/`;
export const CALL_SESSION_URL = `${configUrls.base_url}/api/callsession/log/`;
export const PRESENTATION_FEEDBACK = `${configUrls.base_url}/api/call/slide/feedback/save/`;
export const CALL_INFO_URL = `${configUrls.base_url}/api/call/info/`;
export const GET_PRODUCTS_BY_CALL_URL = `${configUrls.base_url}/api/product/listbycall`;
export const GET_TERMS_BY_CALL_URL = `${configUrls.base_url}/api/term?state_id=1&allow_request=1&expired=0`;
export const MAKE_PRODUCTS_REQUEST = `${configUrls.base_url}/api/customerrequest/create`;
export const MAKE_TERMS_REQUEST = `${configUrls.base_url}/api/customerrequest/create`;
export const GET_CUSTOMER_REQUEST_DATA_URL = `${configUrls.base_url}/api/customerrequest`;
export const REQUEST_TERMS_URL = `${configUrls.base_url}/api/Term`;
export const SIGNATURE_CREATE_URL = `${configUrls.base_url}/api/signature/create`;
export const REJECT_CUSTOMER_REQUEST_URL = `${configUrls.base_url}/api/customerrequest/action/reject`;
export const ACCEPT_CUSTOMER_WITHOUT_SIGN_URL = `${configUrls.base_url}/api/customerrequest/action/accept`;

export function acceptCustomerRequestWithoutSign(payload, code) {
  return axios.post(`${REJECT_CUSTOMER_REQUEST_URL}/${code}`, payload);
}

export function rejectCustomerRequest(payload, code) {
  return axios.post(`${REJECT_CUSTOMER_REQUEST_URL}/${code}`, payload);
}

export function uploadSignatureCrud(payload) {
  return axios.post(`${SIGNATURE_CREATE_URL}`, {
    cod_request: payload.code,
    signer_Name: payload.signerName,
    log: payload.log,
    signature: payload.signature
  });
}

export function getTermByCode(code = 0) {
  return axios.get(`${REQUEST_TERMS_URL}/${code}`);
}

export function getCustomerRequestData(code = 0) {
  return axios.get(`${GET_CUSTOMER_REQUEST_DATA_URL}/${code}`);
}

export function makeTermsRequest(payload) {
  return axios.post(`${MAKE_TERMS_REQUEST}`, {
    cod_call: payload.callcode,
    uuid: payload.customerId,
    type: payload.type,
    termCode: payload.code,
    products: []
  });
}

export function makeProductsRequest(products, customerUuid, callcode, type) {
  let arrProductsObject = [];
  for (const code in products) {
    arrProductsObject.push({
      code: code,
      quantity: products[code]
    })
  }

  return axios.post(`${MAKE_PRODUCTS_REQUEST}`, {
    cod_call: callcode,
    uuid: customerUuid,
    type: type,
    products: arrProductsObject
  });
}

export function getTermsByCall(callcode = 0) {
  return axios.get(`${GET_TERMS_BY_CALL_URL}`);
}

export function getProductsByCall(callcode = 0) {
  return axios.get(`${GET_PRODUCTS_BY_CALL_URL}/${callcode}`);
}

export function getCustomerListByType(type, name, identification = '') {
  //?type={type seleccionado}&name={filtro de texto}&identification={mandar vacio por ahora} 
  return axios.get(`${GET_CUSTOMER_LIST_BY_TYPE_URL}?type=${type}&name=${name}&identification=${identification}`);
}

export function getCustomerEntireList() {
  //?type={type seleccionado}&name={filtro de texto}&identification={mandar vacio por ahora} 
  return axios.get(`${GET_CUSTOMER_LIST_BY_TYPE_URL}?type=-1&name=&identification=`);
}

export function getCustomerTypeList(code) {
  return axios.get(GET_CUSTOMER_TYPE_LIST_URL);
}

export function getSlides(code) {
  return axios.get(SLIDES_URL + code);
}

export function getPresentationObject(code){
  return axios.get(LIST_BY_CALL_URL + code);
}

export function getCallsByDate(from, to){
  // datefrom=a&dateTo=b
  return axios.get(CALLS_URL + 'dateFrom=' + from + '&dateTo=' + to);
}

export function getCallHistory(customerId){
  return axios.get(GET_CUSTOMER_CALL_HISTORY + 'customerId=' + customerId);
}

export function getNetworkTest(callcode){
  return axios.post(NETWORK_TEST + callcode/*,{
    accesstoken: authtoken
  }*/);
}

export function sendEmail(payload){
  if(payload.email && payload.email != ''){
    return axios.post(SEND_EMAIL_URL + payload.callcode + "/"+ payload.userid + "?email=" + payload.email);
  }
  return axios.post(SEND_EMAIL_URL + payload.callcode + "/"+ payload.userid);
}

export function sendFileEmail(payload){
  if(payload.callcode && 
    payload.callcode != '' &&
    payload.filecode && 
    payload.filecode != '')
  {
    return axios.post(SEND_FILE_EMAIL_URL + payload.callcode + "/"+ payload.subscriberId + "/" + payload.filecode + "?email=" + payload.email);
  }
}

export function sendVaidFileEmail(payload){
  if(payload.callcode && payload.callcode != '' &&
    payload.subscriberId && payload.subscriberId != '' &&
    payload.email && payload.email != '' &&
    payload.asset && payload.asset != '' &&
    payload.file && payload.file != '' &&
    payload.vacode && payload.vacode != '')
  {
    return axios.post(SEND_VAID_FILE_EMAIL_URL + payload.callcode + "/"+ payload.subscriberId + "/" + payload.vacode, 
    {
      email: payload.email,
      asset: payload.asset,
      file: payload.file
    });
  }
}

export function closeCall(payload){
  return axios.post(CLOSE_CALL_URL + payload.callcode);
}

export function createCall(payload){
  if(payload.guestId){
    return axios.post(CREATE_CALL_URL, {
      duedateUTC:payload.date,
      customerId: payload.uuid,
      userId: payload.userId,
      guestId: payload.guestId
    });
  }else{
    return axios.post(CREATE_CALL_URL, {
      duedateUTC:payload.date,
      customerId: payload.uuid,
      userId: payload.userId,
      type: payload.type
    });
  }
}

export function saveNote(payload){
  return axios.post(SAVE_PROPERTY + payload.callcode, {
    properties: [
      {
        key: "NOTES",
        value: payload.valueNotes
      }
    ]
  });
}

export function sendFeedback(callCode, starsValue, message){
  return axios.post(SAVE_PROPERTY + callCode, {
    properties: [
      {
        key: "CUSTOMER_FEEDBACK",
        value: starsValue
      },
      {
        key: "CUSTOMER_COMMENT",
        value: message
      }
    ]
  });
}

export function getFileList(callcode){
  return axios.get(FILE_LIST_URL + callcode);
}

export function callSession(body, callcode, callSession){
  return axios.post(CALL_SESSION_URL + `${callcode}/${callSession}`, body);
}

export function sendSlideFeedback(callcode, body){
  return axios.post(PRESENTATION_FEEDBACK + `${callcode}`, body);
}

export function getCallInfo(callcode){
  return axios.get(CALL_INFO_URL + callcode);
}