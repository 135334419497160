import React from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { LayoutSplashScreen } from "../../../_metronic";

const AuthDirectPresentation = (props) => {
  let params = useParams();
  // prueba de redux con hook DEBUG
  const globalState = useSelector(state => state)
  if(params.callcode && params.userid && !globalState.auth.deploymentCode){
    let token = null;
    if(props.token){
      token = props.token;
    }
    login(params.userid, params.callcode, true, token)
      .then(({data}) => {
        props.login(data.accesstoken, params.callcode, data.deployment, data.role, data.userid, data.timezone, data.expires, data.lang, data.name, data.profileName, data.identification, token);
      }).catch((err) => {
        console.log(err);
        window.location.href = '/auth/login/?err=invalid-code'
        console.log('err');
      });
  }
  return(
    <>
    <LayoutSplashScreen visible={true}/>
    {globalState.auth.deployment &&
      <Redirect to="/presentation2" />
    }
    </>
  )
}


export default injectIntl(
  connect(
    null,
    auth.actions
  )(AuthDirectPresentation)
);
