import React, {useEffect} from 'react';
import RUG, { Card, DragArea, DropArea } from 'react-upload-gallery'
import HeaderProduct from './components/HeaderProduct';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from "axios";
import UploadIcon from '../../components/VisualAids/UploadIcon';
import moment from 'moment';
import LinkIcon from '@material-ui/icons/Link';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';

import * as productStorage from "./products.duck";

import { BlobServiceClient } from "@azure/storage-blob";
import { DropzoneArea } from 'material-ui-dropzone';
import './ProductsCreatePage.scss';


// Add style manually
import 'react-upload-gallery/dist/style.css' // or scss

const ProductsCreatePage = (props) => {
  const {intl} = props;
  const globalState = useSelector(state => state);
  const [images, setImages] = React.useState([]);
  const [initialState, setInitialState] = React.useState([]);
  const [delayed, setDelayed] = React.useState(true);  
  const history = useHistory();

  let typeSelected = { key: '', label: '' };
  const [productSelected, setProductSelected] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState(-1);
  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  if(history && history.location && history.location.pathname){
    if(history.location.pathname.indexOf('slide') != -1){
      typeSelected = { key: 'slide', label: 'Slide' };
    }else if(history.location.pathname.indexOf('sdva') != -1){
      typeSelected = { key: 'sdva', label: 'Micrositio' };
    }else if(history.location.pathname.indexOf('html5animate') != -1){
      typeSelected = { key: 'html5animate', label: 'Html5 - Animate' };
    }
  }

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }
  
  useEffect(() => {
    // llamar por get a /visualaid/{visualaidCode}?edit=true
    props.getEditProduct(globalState.product.productContainerResponse.code);
    return () => {
      props.fillResultProductContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.product.productContainerResponse && 
      globalState.product.productContainerResponse.code){      
        //console.log(globalState.product.productContainerResponse)  
        setDelayed(false);
    }
  }, [globalState.product.productContainerResponse.content])

  useEffect(() => {
    if(globalState.product.editProductResult && globalState.product.editProductResult === true){
      props.setEditProductResult(null);
      history.push("/products");
    }
    if(globalState.product.editFileResult !== null && globalState.product.editProductResult === false){
      showToastImportantNotification(intl.formatMessage({id: "PRODUCTS.DROPZONE.ALERT.ERROR"}), 'red');
    }
  }, [globalState.product.editProductResult])

  const handleProductChosen = (product) => {
    if(isCreateDisabled){
      return;
    }

    if(product){
      setProductSelected(product);
    }
  };

  const handleOnAlert = (msg, type) => {
    if(type == 'success'){
      showToastImportantNotification(intl.formatMessage({id: "PRODUCTS.DROPZONE.ALERT.SUCCESS"}), 'green');
    }else if(type == 'error'){
      showToastImportantNotification(intl.formatMessage({id: "PRODUCTS.DROPZONE.ALERT.ERROR"}), 'red');
    }
  };

  const uploadProductProgress = (progress) => {
    if(!productSelected[0] || productSelected[0].size <= 0){
      setUploadProgress(-1);
      return;
    }

    let productSize = productSelected[0].size;

    setUploadProgress(Math.round(progress.loadedBytes * 100 / productSize));
  };

  const uploudProduct = async (products) => {
    if(!products || products.length <= 0 || !products[0] || products[0].size <= 0){
      return;
    }

    let productUrl = globalState.product.productContainerResponse.upload_Url.replace('{FileName}', '');

    const blobServiceClient = new BlobServiceClient(productUrl);
  
    const containerClient = blobServiceClient.getContainerClient('');
    
    //const blobName = globalState.file.fileContainerResponse.code;
    const blobName = globalState.product.productContainerResponse.code +'.png';
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
    // Retorno el promise del upload, para que al momento de guardar el vaid, se espere a que este subido antes de volver al listado de presentaciones
    return blockBlobClient.upload(
      products[0], 
      products[0].size,
      {
        onProgress: uploadProductProgress,
        blobHTTPHeaders: {
          blobContentType: products[0].type
        }
      });
    
  };

  const saveMetaData = async() => {
    //validar
    if(!globalState.product.productContainerResponse.name){
      props.setErrorValidation('name');
    }else if(!globalState.product.productContainerResponse.externalCode){
      props.setErrorValidation('externalCode');
    }else if(!globalState.product.productContainerResponse.validFrom){
      props.setErrorValidation('validFrom');
    }else if(!globalState.product.productContainerResponse.validTo){
      props.setErrorValidation('validTo');
    }else if(!moment(globalState.product.productContainerResponse.validFrom).isBefore(globalState.product.productContainerResponse.validTo)){
      props.setErrorValidation('datesDifference');
    }else{
      props.setErrorValidation(null);
      setIsCreateDisabled(true);
      
      if(productSelected != null && productSelected.length > 0){
        try{
          await uploudProduct(productSelected);
          await props.saveMetaDataProduct({
            productName:productSelected[0].name, 
            mimeType:productSelected[0].type,
            size:productSelected[0].size
          });
          await props.fillResultProductContainer({});
        }catch(e){
          console.log(e);
          showToastImportantNotification(intl.formatMessage({id: "PRODUCTS.DROPZONE.ALERT.ERROR"}), 'red');
          props.fillResultProductContainer({});
          props.setEditProductResult(null);
          history.push("/products");
        }
      }else if(
        globalState.product.productContainerResponse.status === 3 ||
        globalState.product.productContainerResponse.status === 4
      ){
        try{
          await props.saveMetaDataProduct({edit:true});
          await props.fillResultProductContainer({});
        }catch(e){
          console.log(e);
          showToastImportantNotification(intl.formatMessage({id: "PRODUCT.DROPZONE.ALERT.ERROR"}), 'red');
          props.fillResultProductContainer({});
          props.setEditProductResult(null);
          history.push("/products");
        }
        setIsCreateDisabled(false);
      }
    }



  }

  const backToProductList = () => {
    let messageAlert = intl.formatMessage({
      id: "PRODUCTS.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      props.fillResultProductContainer({});
      props.setEditProductResult(null);
      history.push("/products");
    }
  }
  const productEditEnabled = getDeploymentParamValue(globalState.auth.deployment.parameters, "PRODUCT_UPDATE_ENABLE");

  let isdisabled = false;
  
  if( 
    globalState.product.productContainerResponse.status === 3 || 
    (globalState.product.productContainerResponse.status === 4 && productEditEnabled === '1')
  ){
    isdisabled = false;
  }else{
    isdisabled = true;
  }

  
  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {uploadProgress > 0 && <CircularProgressWithLabel size={68} value={uploadProgress} />}

        {globalState.product.loading && <LinearProgress className="progressBar" />}
        {globalState.product.loading && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="PRODUCTS.EDIT.PAGE.TITLE" /></h3>
          </div>

          <div>
          <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backProduct" onClick={backToProductList}>
            <ArrowBackIcon />
            <FormattedMessage id="PRODUCTS.BACK_BUTTON" />
          </Button>
          <Button size="medium" className="btn btn-sm btn-label-success" id="saveProduct" onClick={saveMetaData} disabled={isCreateDisabled || isdisabled}>
            <SaveIcon />
            <FormattedMessage id="PRODUCTS.EDIT.PAGE.SAVE.BUTTON" />
          </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>

              {(typeSelected.key == 'sdva' || typeSelected.key == 'html5animate') && uploadProgress > 0 &&
                <div className="note warning">
                  <FormattedMessage id="PRODUCTS.CREATE.ALERT.FILE_UPLOADING" />
                </div>
              }

              <div>
                <HeaderProduct />
              </div>

              <hr/>
              {globalState.product.productContainerResponse.status === 3 ?
                <h2 style={{textAlign:"center"}}>
                  <LinkIcon fontSize="large" />
                  <a href={globalState.product.productContainerResponse.url} target="_blank">{globalState.product.productContainerResponse.name}</a> 
                </h2>
              :
              !delayed && 
                <div>
                  <DropzoneArea onChange={handleProductChosen} onAlert={handleOnAlert}
                    acceptedFiles={[".png"]} 
                    filesLimit={1} 
                    showAlerts={false} 
                    showFileNames={true} 
                    maxFileSize={2000000} 
                    dropzoneText={intl.formatMessage({id: "PRODUCTS.DROPZONE.TEXT"})}
                  />
                </div>
              }
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    productStorage.actions
  )(ProductsCreatePage)
);