import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import UserGroupsFilter from '../../components/UserGroups/UserGroupsFilter';
import UserGroups from "../../components/UserGroups";
import CreateUserGroupsButton from '../../components/UserGroups/CreateUserGroupsButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import './UserGroupsPage.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as userGroupsStorage from "../../store/ducks/userGroups.duck";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserGroupsPage = (props) => {
  const globalState = useSelector(state => state);

  const handleCloseSnackBar = () => {
    props.createUserGroupResult(null);
  }

  return (
    <div id="user" style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.userGroups.loading && <LinearProgress className="progressBar" />}
      {globalState.userGroups.loading && <div className="progressBackground"></div>}
      {globalState.userGroups.createUserGroupResult != null &&
      <Snackbar 
        open={(globalState.userGroups.createUserGroupResult !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.userGroups.createUserGroupResult) ? 'success' : 'error'}>
          {globalState.userGroups.createUserGroupResult ? 
            <FormattedMessage id="USERGROUPS.CREATE.SUCCESS" />
            :
            <FormattedMessage id="USERGROUPS.CREATE.ERROR" />
          }
        </Alert>
      </Snackbar>
      }
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="USERGROUPS.TITLE" /></h3>
        </div>
        <div>
          <CreateUserGroupsButton />
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex">
            <div>
              <UserGroupsFilter />
            </div>
          </div>
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <UserGroups/>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    userGroupsStorage.actions
  )(UserGroupsPage)
);