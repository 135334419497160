import React from "react";
import Agenda from "../../components/Agenda";
import Calendar from "../../components/Calendar/Calendar";
import { FormattedMessage } from "react-intl";


export default function Dashboard() {
  const [show, setShow] = React.useState('agenda')

  return (
      <div className="row d-flex justify-content-center" style={{marginRight:0, marginLeft:0}}>
        <nav className="nav nav-tabs">
          <button 
            className={`nav-item nav-link ${show === 'agenda' ? 'active' : ''}`}
            style={{ borderStyle: 'none' }}
            onClick={() => setShow('agenda')}
          >
            <FormattedMessage id="MENU.AGENDA" />
          </button>
          <button 
            className={`nav-item nav-link ${show === 'calendar' ? 'active' : ''}`}
            style={{ borderStyle: 'none' }}
            onClick={() => setShow('calendar')}
          >
            <FormattedMessage id="MENU.CALENDAR" />
          </button>
        </nav>    
        {show === 'agenda' && <Agenda />}
        {show === 'calendar' && <Calendar />}
      </div>
  );
}
