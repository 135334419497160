import axios from "axios";
import {configUrls} from '../../globalConfig';
export const PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan?`;
export const ONE_PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan`;
export const USER_GROUPS_URL = `${configUrls.base_url}/api/usergroup?`;
export const CUSTOMER_GROUPS_URL = `${configUrls.base_url}/api/customergroup?`;
export const VISUAL_AIDS_URL = `${configUrls.base_url}/api/visualaid?`;
export const FILES_URL = `${configUrls.base_url}/api/file?`;
export const SAVE_URL = `${configUrls.base_url}/api/promotionalplan/create`;
export const UPDATE_URL = `${configUrls.base_url}/api/promotionalplan/update`;
export const PRODUCT_GET_URL = `${configUrls.base_url}/api/product?`;

export function getPromotionalPlans(state) {
  return axios.get(PROMOTIONAL_PLAN_URL + "state_Id="+ state);
}

export function getOnePromotionalPlan(uuid) {
  return axios.get(ONE_PROMOTIONAL_PLAN_URL + "/"+ uuid);
}

export function getUserGroups(state = 1) {
  return axios.get(USER_GROUPS_URL + "state_Id="+ state);
}

export function getCustomerGroups(state = 1) {
  return axios.get(CUSTOMER_GROUPS_URL + "state_Id="+ state);
}

export function getVisualAids(state = 1) {
  return axios.get(VISUAL_AIDS_URL + "state_Id="+ state);
}

export function getFiles(state = 1) {
  return axios.get(FILES_URL + "state_Id="+ state);
}

export function getProducts(state = 1, promplan){
  return axios.get(PRODUCT_GET_URL + "state_id="+ state);
}

export function savePromotionalPlans(obj, edit = false) {
  if(edit === true){
    return axios.put(UPDATE_URL + "/" + obj.uuid, obj);
  }else{
    return axios.post(SAVE_URL, obj);
  }
}
