import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container:{
    backgroundColor: '#f5f5f5',
    marginBottom: 10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 8,
    marginRight: 8
  },
  row:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0'
  },
  eye:{
    padding: '5px 10px',
    backgroundColor: '#1976d2',
    color: '#fff',
    borderRadius: 4,
    marginRight: 5,
    cursor: 'pointer'
  },
  downloadButton:{
    display: 'flex',
    width: 85,
    justifyContent: 'space-between',
    marginRight: 5,
    cursor: 'pointer'
  },
  fileName:{
    color: 'gray'
  }
}));
