import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { connect, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';

const SendEmailButton = (props) => {
  const { intl } = props;

  const globalState = useSelector(state => state);
  const [openFillEmail, setOpenFillEmail] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleOpenFillEmail = () => {
    setOpenFillEmail(true);
  };

  const handleCloseFillEmail = () => {
    setOpenFillEmail(false);
    props.reactors[1](null)
  };

  useEffect(() => {
    let msg = ''
    let backgroundColor = 'green';
    if (globalState.presentationBar.emailResult != '') {
      if (globalState.presentationBar.emailResult == 'ok') {
        msg = intl.formatMessage({
          id: "EMAIL.SEND.OK"
        });

      } else {
        msg = intl.formatMessage({
          id: "EMAIL.SEND.ERROR"
        });
        backgroundColor = 'red';
      }
      if (msg != '')
        showPopup(msg, backgroundColor);
    }
  }, [globalState.presentationBar.emailResult]);

  const showPopup = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #aviso {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "aviso");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    if (globalState.presentationBar.emailResult != 'ok') {
      let detail = document.createTextNode(globalState.presentationBar.emailResult);
      advisement.appendChild(detail);
    }
    document.body.appendChild(advisement);
    window.load = setTimeout("if(typeof(aviso) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(aviso)){for (var i = 0; i < aviso.length; i++) {document.body.removeChild(aviso[i])}}else{document.body.removeChild(aviso)}}", 5000);
    props.setEmailResult('');
  }


  const handleSendEmailClick = () => event => {
    if (props.item.subscriberEmail){
      setInputValue(props.item.subscriberEmail);
    }
    handleOpenFillEmail();
  }
  const handleSendEnteredEmail = () => event => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (inputValue != '' && expression.test(String(inputValue).toLowerCase())) {
      props.sendEmail({ callcode: props.item.code, userid: props.item.subscriberId, email: inputValue });
    } else {
      let errorMsg = intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_FIELD"
      });
      window.alert(errorMsg);
    }
  }

  let style={marginLeft:1, width: '100%'};
  let className = "btn btn-sm btn-label-blue";
  if (props.popup) {
    className = "btn btn-sm btn-label-blue";
    style = { width: '80%',marginLeft:'10%',marginRight:'10%',marginTop:'1%'}
  }
  return (
    <div style={{ "display": "inline", "width": "100%" }}>
      {props.popup ?
      <Button size="small" style={style} className={className} id="btnAgendaSendEmail" onClick={handleSendEmailClick()}>
        <EmailIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="SCHEDULE.BUTTON.EMAIL" />
      </Button>
      :
      <div onClick={handleSendEmailClick()} id="menuItem__sendEmail">
        <span>&nbsp;</span>
        <FormattedMessage id="SCHEDULE.BUTTON.EMAIL" />
      </div>
      }
      {openFillEmail ?
        <Dialog 
          open={openFillEmail}
          onClose={handleCloseFillEmail}
          id="popup"
        >
          <DialogTitle id="form-dialog-title">
            EMAIL
          </DialogTitle>
          <DialogContent style={{width: '100%',height: '100%'}}>
            {props.item.subscriberEmail ?
            <FormattedMessage id="DASHBOARD.EMAIL.ENTER_FILLED"/>
            :
            <FormattedMessage id="DASHBOARD.EMAIL.ENTER"/>
            }
            <br></br>
            <br></br>
            <input style={{width: 250}}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={inputValue.length<1} onClick={handleSendEnteredEmail()}>
              <FormattedMessage id="DASHBOARD.EMAIL.SEND" />
            </Button>
            <Button onClick={handleCloseFillEmail}>
              <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
            </Button>
          </DialogActions>
        </Dialog> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    global: state,
    presentationBar: state.presentationBar
  }
}
export default injectIntl(
  connect(
    mapStateToProps,
    { ...presentationBarStorage.actions }
  )(SendEmailButton)
);