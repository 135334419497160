import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, makeStyles } from '@material-ui/core';
import FlowMenu from './FlowMenu';

const FlowDropdown = ({flowName, disabled, isPreview, gotoSlideSDVA}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    return (
        <div>
            <span onClick={handleClick}>
                { flowName && flowName }
            </span>
            <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="inherit"
                id="flowMenuIcon"
                aria-label="flowMenu"
                disabled={disabled}
                onClick={handleClick}
            >
                <ExpandMoreIcon />
            </IconButton>
            <FlowMenu anchorEl={anchorEl} handleClose={handleClose} isPreview={isPreview} gotoSlideSDVA={gotoSlideSDVA}/>
        </div>
    )
}

export default FlowDropdown;