/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import LinkCopyClientButton from '../LinkCopyClientButton/LinkCopyClientButton';
import SendEmailButton from '../SendEmailButton/SendEmailButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { isMobile,isIPad13, isIPhone13, isDesktop } from 'react-device-detect';
import './assets/styles.scss';
import LinkIcon from '@material-ui/icons/Link';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NetworkTest, { ErrorNames } from 'opentok-network-test-js';
import createChart from './chart.js';
import * as ConnectivityUI from './connectivity-ui.js';
import config from './configSample.js';
//import { charts } from "highcharts";
import { getNetworkTest } from "../../crud/presentationBar.crud";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
let sessionInfo = config;
let otNetworkTest;
let audioOnly;
//import startTest from 'opentok-network-test-js/sample/src/js/index';
let OT;// = require('@opentok/client');

if(!window.Highcharts){
  const script = document.createElement('script');
  script.src = "https://code.highcharts.com/highcharts.js";
  script.async = true;
  document.body.appendChild(script);
}


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: '80vh'
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: '70vh',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  checkIconStartCallMask: { marginLeft: '20%', height: 30, width: 30, color: '#ffffff' },
  networkTestButton: { width: '130px' },
  checkIconConnectingMask: { height: 20, width: 20, color: '#ffffff' },
  networkTestSpanText: { color: '#ffffff' },
}));


const ConnectionTestPopUp = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);

  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [testStarted, setTestStarted] = React.useState(false);

  useEffect(() => {
    if (globalState.presentationBar.networkTest && globalState.presentationBar.networkTest != {} && globalState.presentationBar.networkTest.code) {
        sessionInfo.apiKey = globalState.presentationBar.networkTest.OT_API_KEY;
        sessionInfo.sessionId=globalState.presentationBar.networkTest.OT_SESSION_ID;
        sessionInfo.token=globalState.presentationBar.networkTest.OT_TOKEN_ID;
      startTest();
    }
  }, [globalState.presentationBar.networkTest]); 

  const handleOpen = () => {
    if(!props.callEnded){
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const handleEnterClick = (item) => event => {
    //console.log('enter Click', item)
    //console.log(globalState)
    window.location.href = `/call/${item.code}/${globalState.auth.userid}`;
  }
  const truncate = (str) => {
      return str.length > 25 ? str.substring(0, 22) + "..." : str;
  }

  const handleNetworkTestClick = () => event => {
    let messageAlert = intl.formatMessage({
      id: "CALL.NETWORK.TEST.START.TEXT"
    });
    if (window.confirm(messageAlert)) {
      setTestStarted(true);

      props.getNetworkTest(props.auth.callCode);
    }
  }

  function closeTest() {
    let messageAlert = intl.formatMessage({
      id: "CALL.NETWORK.TEST.CLOSE.ALERT"
    });
    if (window.confirm(messageAlert)) {
      if(otNetworkTest){
        otNetworkTest.stop();
      }
      window.location.href = `/call/${props.auth.callCode}/${props.auth.userid}`;
    }

  }

  function resetAll() {
    const statusContainerEl = document.getElementById('quality_status_container');
    const statusMessageEl = statusContainerEl.querySelector('p');
    const statusIconEl = statusContainerEl.querySelector('img');
    statusContainerEl.querySelector('#audio .results').style.display = 'none';
    statusContainerEl.querySelector('#video .results').style.display = 'none';
    statusIconEl.src = require('./assets/spinner.gif');
    statusMessageEl.textContent = intl.formatMessage({
      id: "CALL.NETWORK.TEST.INPROGRESS"
    });
  }

  function startTest() {
    const containerTestEl = document.getElementById('containerTest');

    if(containerTestEl)
    {
      containerTestEl.style.display = 'block';
    }

    let inProgress = intl.formatMessage({
      id: "CALL.NETWORK.TEST.INPROGRESS"
    })
    if (document.getElementById('quality_status_container').querySelector('p').textContent != inProgress) {
      resetAll();
      const precallDiv = document.getElementById('precall');
      document.getElementById('quality_status_container').style.display = 'block';

      var timeout = 10000;
      var options = {
        audioOnly: false,
        timeout: timeout
      };

      OT = window.OT;

      if (OT && sessionInfo != {}) {
        otNetworkTest = new NetworkTest(OT, sessionInfo, options);
        if (otNetworkTest) {
          ConnectivityUI.resetCharts();
          otNetworkTest.testConnectivity()
            .then(results => ConnectivityUI.displayTestConnectivityResults(results))
            .then(testQuality).then()
        }
      }
    }
  }

  function testQuality() {
    createChart('audio', intl);
    createChart('video', intl);
    ConnectivityUI.init(audioOnly);
    document.getElementById('stop_test').addEventListener('click', function stopTestListener() {
      ConnectivityUI.hideStopButton();
      otNetworkTest.stop();
    });
    otNetworkTest.testQuality(function updateCallback(stats) {
      ConnectivityUI.checkToDisplayStopButton();
      ConnectivityUI.graphIntermediateStats('audio', stats,intl);
      ConnectivityUI.graphIntermediateStats('video', stats,intl);
    }).then(results => ConnectivityUI.displayTestQualityResults(null, results, intl, props.log))
      .catch(error => ConnectivityUI.displayTestQualityResults(error, intl, props.log));
  }

  const buttonsContainer = (
    <div className="buttons-container justify-content-center">
      <Button className="buttonClickeable btn-start-test" id="btnConnectionTestStart" onClick={handleNetworkTestClick()}>
        <FormattedMessage id="CALL.NETWORK.TEST.BUTTON.START" />
      </Button>
      <Button className="buttonClickeable btn-close-test" id="btnConnectionTestClose" onClick={handleClose}>
        <FormattedMessage id="CALL.NETWORK.TEST.BUTTON.CLOSE" />
      </Button>
    </div>
  );

  const connectivity_status_container = (
    <div id="connectivity_status_container" className={(isMobile) ? 'prettyBox-PopUp' : 'prettyBox-desktop-PopUp'}>
      <h2>{intl.formatMessage({
        id: "CALL.NETWORK.TEST.BUTTON.TESTING"
      })}</h2>
      <p>{intl.formatMessage({
        id: "CALL.NETWORK.TEST.INPROGRESS"
      })}</p>
      <img src={require('./assets/spinner.gif')} />
    </div>
  );

  const qualityStatusContainer = (
    <div id="quality_status_container" className={(isMobile) ? 'prettyBox-PopUp' : 'prettyBox-desktop-PopUp'}>
      <button onClick={closeTest} className="buttonClickeable close_test" id="close_test">
        {intl.formatMessage({ id: "CALL.NETWORK.TEST.BUTTON.CLOSE" })}
      </button>

      <div id="quality_header">
        <h2 className="quality_header_text">{intl.formatMessage({
          id: "CALL.NETWORK.TEST.HEADER.TEXT"
        })}</h2>
        <p></p>
        <img src={require('./assets/spinner.gif')} />

        <button className="buttonClickeable stop_test" id="stop_test">
          {intl.formatMessage({ id: "CALL.NETWORK.TEST.BUTTON.STOP" })}
        </button>
      </div>
      <div>
        <div id="video">
          <h3 className="quality_header_text">Video</h3>
          <div>
            <div className="results">
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.SUPPORTED"})}:
                <span id="video-supported"></span></p>
              <p id="video-unsupported-reason">{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.REASON"})}:
                <span></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.QUALITY"})}:
                <span id="video-mos"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.BITRATE"})}:
                <span id="video-bitrate"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.PACKETLOSS"})}:
                <span id="video-plr"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.RECOMMENDED.RESOLUTION"})}:
                <span id="video-recommendedResolution"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.RECOMMENDED.FRAME.RATE"})}:
                <span id="video-recommendedFrameRate"></span>
              </p>
            </div>
            <div id="videoGraph" className="graph"></div>
          </div>
        </div>
        <div id="audio">
          <h3 className="quality_header_text">Audio</h3>
          <div>
            <div className="results">
              <p> {intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.SUPPORTED"})}:
                <span id="audio-supported"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.QUALITY"})}:
                <span id="audio-mos"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.BITRATE"})}:
                <span id="audio-bitrate"></span>
              </p>
              <p>{intl.formatMessage({ id: "CALL.NETWORK.TEST.RESULTS.PACKETLOSS"})}:
                <span id="audio-plr"></span>
              </p>
            </div>
            <div id="audioGraph" className="graph"></div>
          </div>
        </div>
      </div>
    </div>
  )

  const dialogContainer = (
    <DialogTitle id="form-dialog-title">
      <FormattedMessage id="CALL.NETWORK.TEST.HEADER.TEXT" />
    </DialogTitle>
  );

  return (
    <div style={{ "display": "inline-block" }}>
      <NetworkCheckIcon onClick={handleOpen} className={classes.checkIconStartCallMask} />
      
      <Dialog
        open={open}
        onClose={closeTest}
        id="popup">

        { !testStarted && dialogContainer }
        
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
          
          {!testStarted && buttonsContainer}

            <div className={classes.inRow}>
              <div id="containerTest" className="justify-content-center">
                {connectivity_status_container}
                {qualityStatusContainer}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    global: state,
    presentationBar: state.presentationBar
  }
}
export default injectIntl(
  connect(
    mapStateToProps,
    { ...presentationBarStorage.actions }
  )(ConnectionTestPopUp)
);