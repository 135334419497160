import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  modifyDateOfCall,
  closeCall,
  inactivateCall,
  updateCall,
  getNotifications,
  getArchivesOfItem,
  generateArchiveToken
} from "../../crud/schedule.crud";
import {
  saveNote
} from '../../crud/presentationBar.crud';
import moment from 'moment';

export const actionTypes = {
  INIT: "INIT",
  MODIFYDATEOFCALL: "MODIFYDATEOFCALL",
  CLOSE_CALL_POPUP: "CLOSE_CALL_POPUP",
  SET_REQUEST_STATE: "SET_REQUEST_STATE",
  SET_REQUEST_STATE_INACTIVATE: "SET_REQUEST_STATE_INACTIVATE",
  SET_REQUEST_STATE_UPDATE: "SET_REQUEST_STATE_UPDATE",
  SET_LOADING_STATE: "SET_LOADING_STATE",
  INACTIVATE_CALL_POPUP: "INACTIVATE_CALL_POPUP",
  SET_REQUEST_STATE_MODIFY: "SET_REQUEST_STATE_MODIFY",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  REMOVE_EXPIRED_NOTIFICATIONS: "REMOVE_EXPIRED_NOTIFICATIONS",
  SET_LAST_CODE: "SET_LAST_CODE",
  SET_IMPORTANT_NOTIFICATION: "SET_IMPORTANT_NOTIFICATION",
  UPDATE_CALL: "UPDATE_CALL",
  SET_ARCHIVES_POPUP_OPEN: "SET_ARCHIVES_POPUP_OPEN",
  GET_ARCHIVES_OF_ITEM: "GET_ARCHIVES_OF_ITEM",
  SET_ARCHIVES_OF_ITEM: "SET_ARCHIVES_OF_ITEM",
  SET_CALL_SELECTED: "SET_CALL_SELECTED",
  GET_PLAYER_LINK: "GET_PLAYER_LINK",
  SET_ARCHIVE_TOKEN: "SET_ARCHIVE_TOKEN",
  SET_ROW_ARCHIVE_ID: "SET_ROW_ARCHIVE_ID",
  RESET_ARCHIVE_VIDEOS: "RESET_ARCHIVE_VIDEOS"
};

const initialAuthState = {
  log_sequence: 0,
  loading: false,
  requestState: null,
  requestStateInactivate: null,
  requestStateModify: null,
  requestStateUpdate: null,
  callcode: null,
  notifications: [],
  lastCode: 0,
  importantNotification: false,
  archivesPopUpOpen: false,
  archives:[],
  callSelected:{},
  playerLink: "",
  archiveToken: "",
  rowArchiveId: ""
};

export const reducer = persistReducer(
    { storage, key: "schedule", whitelist: ["notifications"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.INIT: {
          return { ...state, ...action.payload };
        }
        case actionTypes.CLOSE_CALL_POPUP: {
          return { ...state, loading: true };
        }
        case actionTypes.INACTIVATE_CALL_POPUP: {
          return { ...state, loading: true };
        }
        case actionTypes.SET_REQUEST_STATE: {
          return { ...state, requestState: action.payload.state, callcode: action.payload.callcode };
        }
        case actionTypes.SET_REQUEST_STATE_UPDATE: {
          return { ...state, requestStateUpdate: action.payload.state, callcode: action.payload.callcode };
        }
        case actionTypes.SET_REQUEST_STATE_INACTIVATE: {
          return { ...state, requestStateInactivate: action.payload.state, callcode: action.payload.callcode };
        }
        case actionTypes.SET_REQUEST_STATE_MODIFY: {
          return { ...state, requestStateModify: action.payload.state, callcode: action.payload.callcode };
        }
        case actionTypes.SET_REQUEST_STATE_MODIFY: {
          return { ...state, requestStateModify: action.payload };
        }
        case actionTypes.SET_LOADING_STATE: {
          return { ...state, loading: action.payload };
        }
        case actionTypes.SET_NOTIFICATIONS: {
          // aca lo que hago es comparar todas las notificaciones anteriores con las nuevas y no repetirlas 
          let notificationsToInsert = [];
          if (action.payload.length > 0){
            action.payload.forEach((item) => {
              notificationsToInsert.push(item);
              state.notifications.forEach((notification) => {
                if(notification.code === item.code){
                  notificationsToInsert.pop();
                }
              })
            })
            return { ...state, notifications: [...state.notifications, ...notificationsToInsert] };
          }else{
            return { ...state };
          }
          break;
        }
        case actionTypes.SET_LAST_CODE: {
          return { ...state, lastCode: action.payload };
        }
        case actionTypes.SET_IMPORTANT_NOTIFICATION: {
          return { ...state, importantNotification: action.payload };
        }
        case actionTypes.SET_ARCHIVES_POPUP_OPEN: {
          return { ...state, archivesPopUpOpen: action.payload };
        }
        case actionTypes.GET_ARCHIVES_OF_ITEM: {
          return { ...state, loading: true };
        }
        case actionTypes.SET_ARCHIVES_OF_ITEM: {
          return { 
            ...state, 
            archives: action.payload 
          };
        }
        case actionTypes.SET_CALL_SELECTED: {
          return { 
            ...state, 
            callSelected: action.payload 
          };
        }
        case actionTypes.SET_ARCHIVE_TOKEN: {
          return { 
            ...state, 
            archiveToken: action.payload 
          };
        }
        case actionTypes.SET_ROW_ARCHIVE_ID: {
          return { 
            ...state, 
            rowArchiveId: action.payload 
          };
        }
        case actionTypes.RESET_ARCHIVE_VIDEOS: {
          return { 
            ...state, 
            archiveToken: "",
          };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  init: (value) => ({ type: actionTypes.INIT, payload: value }),
  modifyDateOfCall: (value) => ({ type: actionTypes.MODIFYDATEOFCALL, payload: value }),
  closeCallPopUp: (value) => ({ type: actionTypes.CLOSE_CALL_POPUP, payload: value }),
  inactivateCallPopUp: (value) => ({ type: actionTypes.INACTIVATE_CALL_POPUP, payload: value }),
  updateCall: (value) => ({ type: actionTypes.UPDATE_CALL, payload: value }),
  setRequestState: (value) => ({ type: actionTypes.SET_REQUEST_STATE, payload: value }),
  setRequestStateUpdate: (value) => ({ type: actionTypes.SET_REQUEST_STATE_UPDATE, payload: value }),
  setRequestStateInactivate: (value) => ({ type: actionTypes.SET_REQUEST_STATE_INACTIVATE, payload: value }),
  setRequestStateModify: (value) => ({ type: actionTypes.SET_REQUEST_STATE_MODIFY, payload: value }),
  setLoadingState: (value) => ({ type: actionTypes.SET_LOADING_STATE, payload: value }),
  getNotifications: (value) => ({ type: actionTypes.GET_NOTIFICATIONS, payload: value }),
  setNotifications: (value) => ({ type: actionTypes.SET_NOTIFICATIONS, payload: value }),
  removeExpiredNotifications: () => ({ type: actionTypes.REMOVE_EXPIRED_NOTIFICATIONS, payload: {} }),
  setLastCode: (value) => ({ type: actionTypes.SET_LAST_CODE, payload: value }),
  setImportantNotification: (value) => ({ type: actionTypes.SET_IMPORTANT_NOTIFICATION, payload: value }),
  setArchivesPopUpOpen: (value) => ({ type: actionTypes.SET_ARCHIVES_POPUP_OPEN, payload: value }),
  getArchivesOfItem: (value) => ({ type: actionTypes.GET_ARCHIVES_OF_ITEM, payload: value }),
  setArchivesOfItem: (value) => ({ type: actionTypes.SET_ARCHIVES_OF_ITEM, payload: value }),
  setCallSelected: (value) => ({ type: actionTypes.SET_CALL_SELECTED, payload: value }),
  getPlayerLink: (value) => ({ type: actionTypes.GET_PLAYER_LINK, payload: value }),
  setArchiveToken: (value) => ({ type: actionTypes.SET_ARCHIVE_TOKEN, payload: value }),
  setRowArchiveId: (value) => ({ type: actionTypes.SET_ROW_ARCHIVE_ID, payload: value }),
  resetArchiveVideos: () => ({ type: actionTypes.RESET_ARCHIVE_VIDEOS, payload: null })
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.GET_PLAYER_LINK, function* getPlayerLinkSaga({payload}) {
    const selector = yield select(getState);
    const { data } = yield generateArchiveToken(
      selector.schedule.callSelected.code,
      payload
    );

    if(data.token){
      yield put(actions.setRowArchiveId(payload));
      yield put(actions.setArchiveToken(data.token));
    }
  });

  yield takeLatest(actionTypes.GET_ARCHIVES_OF_ITEM, function* getArchivesOfItemSaga({payload}) {
    const selector = yield select(getState);
    const { data } = yield getArchivesOfItem(payload);

    if(data.list && data.list.length){
      yield put(actions.setArchivesOfItem(data.list));
    }
  });
  
  yield takeLatest(actionTypes.REMOVE_EXPIRED_NOTIFICATIONS, function* RemoveExpiredNotificationsSaga() {
    const selector = yield select(getState);

    let notifications = selector.schedule.notifications;
    if(notifications.length > 0){
      notifications.forEach((notification, index) => {
        if(moment(notification.expiresAtUTC).isAfter(moment(new Date()).add(-(selector.auth.timezone), 'hours')) === false){
          notifications.splice(index, 1);
        }
      });
      yield put(actions.setNotifications(notifications));
    }
    
  });

  yield takeLatest(actionTypes.GET_NOTIFICATIONS, function* GetNotificationsSaga({payload}) {
    const selector = yield select(getState);
    const lastCode = selector.schedule.lastCode;
    yield put(actions.removeExpiredNotifications());
    const response = yield getNotifications(lastCode); 
    let sortedList = []; 

    if(response.status === 200 && 
      response.data && 
      response.data.list &&
      response.data.list.length > 0
    ){
      let setImportant = false;
      response.data.list.forEach((item) => {
        if(item.priority &&
          item.priority < 2){
            setImportant = true;
          }
        }); 
      sortedList = response.data.list.sort((a, b) =>  moment(b.createdAtUTC).add(-(selector.auth.timezone), 'hours').isAfter(moment(a.createdAtUTC).add(-(selector.auth.timezone), 'hours')));
      yield put(actions.setImportantNotification(setImportant));
      yield put(actions.setNotifications(sortedList));
      yield put(actions.setLastCode(response.data.maxCode));
    }
  });

  yield takeLatest(actionTypes.CLOSE_CALL_POPUP, function* CloseCallSaga({payload}) {
    const selector = yield select(getState);
    try{
      let notesIsEnabled = false;
      if(selector.auth.deployment.parameters){
        notesIsEnabled = selector.auth.deployment.parameters.filter((parameter) =>{
          return (parameter.key === "CALL_NOTES_ENABLE")
        });
        if(notesIsEnabled.length > 0){
          notesIsEnabled = notesIsEnabled[0]['value'];
          notesIsEnabled = (notesIsEnabled && notesIsEnabled === "1") ? true : false;
        }
      }

      let response=null;

      if(notesIsEnabled){
        const noteSaved = yield saveNote(payload);
        if(noteSaved.status >= 200 && noteSaved.status <= 299){
          response = yield closeCall(payload.callcode);
          localStorage.removeItem("callnotes_" + payload.callcode);
          if (response && response.data === 'ok'){
            yield put(actions.setRequestState({state: true, callcode: payload.callcode}));
            yield put(actions.setLoadingState(false));
          }else{
            yield put(actions.setRequestState({state: false, callcode: payload.callcode}));
            yield put(actions.setLoadingState(false));
          }
        }
      }else{
        response = yield closeCall(payload.callcode);
        if (response && response.data === 'ok'){
          yield put(actions.setRequestState({state: true, callcode: payload.callcode}));
          yield put(actions.setLoadingState(false));
        }else{
          yield put(actions.setRequestState({state: false, callcode: payload.callcode}));
          yield put(actions.setLoadingState(false));
        }
      }
    }catch(e){
      yield put(actions.setRequestState({state:false, callcode: payload.callcode}));
      yield put(actions.setLoadingState(false));
    }
  });

  yield takeLatest(actionTypes.UPDATE_CALL, function* updateCallSaga({payload}) {
    const selector = yield select(getState);
    try{
      let response = yield updateCall({state_Id: payload.state_Id, duedateUTC: payload.duedateUTC, callcode: payload.callcode, guestid: payload.guestid});
      if (response.status === 200){
        yield put(actions.setRequestStateUpdate({state: true, callcode: payload.callcode}));
        yield put(actions.setLoadingState(false));
      }else{
        yield put(actions.setRequestStateUpdate({state: false, callcode: payload.callcode}));
        yield put(actions.setLoadingState(false));
      }
    }catch(e){
      yield put(actions.setRequestStateUpdate({state:false, callcode: payload.callcode}));
      yield put(actions.setLoadingState(false));
    }
  });

  yield takeLatest(actionTypes.INACTIVATE_CALL_POPUP, function* InactivateCallSaga({payload}) {
    const selector = yield select(getState);
    try{
      let response = yield inactivateCall({duedateUTC: moment(payload.duedateUTC).add(-1 * selector.auth.timezone, 'hours').format("YYYY-MM-DDTHH:mm"), callcode: payload.code});
      if (response.status === 200){
        yield put(actions.setRequestStateInactivate({state: true, callcode: payload.code}));
        yield put(actions.setLoadingState(false));
      }else{
        yield put(actions.setRequestStateInactivate({state: false, callcode: payload.code}));
        yield put(actions.setLoadingState(false));
      }
    }catch(e){
      yield put(actions.setRequestStateInactivate({state:false, callcode: payload.code}));
      yield put(actions.setLoadingState(false));
    }
  });

  yield takeLatest(actionTypes.MODIFYDATEOFCALL, function* ModifyDateOfCallSaga({payload}) {
    const selector = yield select(getState);
    try{
      const response = yield modifyDateOfCall(moment(payload.dueDateUTC).add(-1 * selector.auth.timezone, 'hours').format("YYYY-MM-DDTHH:mm"), payload.callcode, payload.guestid)
      if(response.status === 200){
        yield put(actions.setRequestStateModify({state: true, callcode: payload.callcode}));
        yield put(actions.setLoadingState(false));
      }else{
        yield put(actions.setRequestStateModify({state: false, callcode: payload.callcode}));
        yield put(actions.setLoadingState(false));
      }
    }catch(e){
      console.log(e.message);
      yield put(actions.setRequestStateModify({state: false, callcode: payload.callcode}));
      yield put(actions.setLoadingState(false));
    }
  });
}
