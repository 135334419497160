import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getUserGroupsByStateId,
  createUserGroup,
  getUserGroupAtribute,
  assignByAtribute
} from "../../crud/userGroups.crud";

export const actionTypes = {
  GET_USERGROUPS: "GET_USERGROUPS",
  SET_USERGROUPS_RESULT: "SET_USERGROUPS_RESULT",
  SET_STATE_ID: "SET_STATE_ID",
  UPDATE_USERGROUP_SELECTED: "UPDATE_USERGROUP_SELECTED",
  SET_OPEN_CALL_DETAIL: "SET_OPEN_CALL_DETAIL",
  CREATE_USERGROUP: "CREATE_USERGROUP",
  FILL_USERGROUP_RESULT: "FILL_USERGROUP_RESULT",
  CREATE_USERGROUP_RESULT: "CREATE_USERGROUP_RESULT",
  GET_USERGROUP_ATRIBUTE: "GET_USERGROUP_ATRIBUTE",
  FILL_USERGROUP_ATRIBUTE: "FILL_USERGROUP_ATRIBUTE",
  ASSIGN_BY_ATRIBUTE: "ASSIGN_BY_ATRIBUTE",
  ASSIGN_BY_ATRIBUTE_RESULT: "ASSIGN_BY_ATRIBUTE_RESULT"
};

const initialUsersState = {
  loading: false,
  userGroups: [],
  stateId: 1, // state_id=1
  assignByAtributeResult: null,
  userGroupSelected: {},
  createUserGroupResponse: {},
  createUserGroupResult: null,
  userGroupAtributeList: [],
  openCallDetail: false
};

export const reducer = persistReducer(
  { storage, key: "demo1-userGroups", whitelist: [""] },
  (state = initialUsersState, action) => {
    switch (action.type) {
      case actionTypes.CREATE_USERGROUP_RESULT: {
        return {
          ...state,
          loading: false,
          createUserGroupResult: action.payload
        };
      }
      case actionTypes.CREATE_USERGROUP: {
        return {
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_USERGROUP_RESULT: {
        return {
          ...state,
          loading: false,
          createUserGroupResponse: action.payload
        };
      }
      case actionTypes.GET_USERGROUPS: {
        return { ...state, loading: true, assignByAtributeResult: null };
      }
      case actionTypes.SET_USERGROUPS_RESULT: {
        return {
          ...state,
          loading: false,
          userGroups: action.payload
        };
      }
      case actionTypes.GET_USERGROUP_ATRIBUTE: {
        return { ...state, loading: true };
      }
      case actionTypes.FILL_USERGROUP_ATRIBUTE: {
        return {
          ...state,
          loading: false,
          userGroupAtributeList: action.payload
        };
      }
      case actionTypes.SET_STATE_ID: {
        return {
          ...state,
          stateId: action.payload
        };
      }
      case actionTypes.ASSIGN_BY_ATRIBUTE: {
        return {
          ...state,
          loading: true
        };
      }
      case actionTypes.ASSIGN_BY_ATRIBUTE_RESULT: {
        return {
          ...state,
          loading: false,
          assignByAtributeResult: action.payload
        };
      }
      case actionTypes.UPDATE_USERGROUP_SELECTED: {
        return {
          ...state,
          loading: false,
          userGroupSelected: action.payload
        };
      }

      case actionTypes.SET_OPEN_CALL_DETAIL: {
        return {
          ...state,
          openCallDetail: action.payload,
          loading: true
        };


      }
      default:
        return state;
    }
  }
);

export const actions = {
  getUserGroups: () => ({ type: actionTypes.GET_USERGROUPS, payload: null }),
  setUserGroupsResult: (value) => ({ type: actionTypes.SET_USERGROUPS_RESULT, payload: value }),
  getUserGroupAtribute: () => ({ type: actionTypes.GET_USERGROUP_ATRIBUTE, payload: null }),
  fillUserGroupsAtribute: (value) => ({ type: actionTypes.FILL_USERGROUP_ATRIBUTE, payload: value }),
  setStateId: (value) => ({ type: actionTypes.SET_STATE_ID, payload: value }),
  assignByAtribute: (value) => ({ type: actionTypes.ASSIGN_BY_ATRIBUTE, payload: value }),
  setAssignByAtributeResult: (value) => ({ type: actionTypes.ASSIGN_BY_ATRIBUTE_RESULT, payload: value }),
  updateUserGroupSelected: (value) => ({ type: actionTypes.UPDATE_USERGROUP_SELECTED, payload: value }),
  setOpenCallDetail: (value) => ({ type: actionTypes.SET_OPEN_CALL_DETAIL, payload: value }),
  createUserGroup: (value) => ({ type: actionTypes.CREATE_USERGROUP, payload: value }),
  fillUserGroupResult: (value) => ({ type: actionTypes.FILL_USERGROUP_RESULT, payload: value }),
  createUserGroupResult: (value) => ({ type: actionTypes.CREATE_USERGROUP_RESULT, payload: value }),
};

export const getState = (state) => state;

export function* saga() {

  yield takeLatest(actionTypes.ASSIGN_BY_ATRIBUTE, function* assignByAtributeSaga({ payload }) {
    try {
      const { data, status } = yield assignByAtribute(payload);

      if (status < 300 && data === 'OK') {
        yield put(actions.setAssignByAtributeResult(true));
      } else {
        yield put(actions.setAssignByAtributeResult(false));
      }
    } catch (e) {
      yield put(actions.setAssignByAtributeResult(false));
    }
  });

  yield takeLatest(actionTypes.GET_USERGROUPS, function* getUserGroupsSaga({ }) {
    const selector = yield select(getState);
    try {
      const { data, status } = yield getUserGroupsByStateId(selector.userGroups.stateId);

      if (status < 300) {
        yield put(actions.setUserGroupsResult(data.list));
      } else {
        yield put(actions.setUserGroupsResult([]));
      }
    } catch (e) {
      yield put(actions.setUserGroupsResult([]));
    }

  });

  yield takeLatest(actionTypes.GET_USERGROUP_ATRIBUTE, function* getUserGroupAtributeSaga({ }) {
    const selector = yield select(getState);
    try {
      const { data, status } = yield getUserGroupAtribute();

      if (status < 300) {
        yield put(actions.fillUserGroupsAtribute(data.list));
      } else {
        yield put(actions.fillUserGroupsAtribute([]));
      }
    } catch (e) {
      yield put(actions.fillUserGroupsAtribute([]));
    }

  });

  yield takeLatest(actionTypes.CREATE_USERGROUP, function* createUserGroupSaga({ payload }) {
    try {
      const { data, status } = yield createUserGroup(payload);

      if (status < 300 && data) {
        yield put(actions.fillUserGroupResult({ ...data }));
        yield put(actions.createUserGroupResult(true));
      } else {
        yield put(actions.createUserGroupResult(false));
      }
    } catch (e) {
      yield put(actions.createUserGroupResult(false));
    }
  });

}
