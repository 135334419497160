import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import RateReviewIcon from '@material-ui/icons/RateReview';
import {getDeploymentParamValue} from './utils';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import KTUtil from "../../../_metronic/_assets/js/util";
import { Button, AppBar, Tooltip, Toolbar, makeStyles, Typography, IconButton } from "@material-ui/core";
import FeedbackCommentPopup from './FeedbackCommentPopup';
import LikeButtonMenu from './FeedbackButtonsMenu/LikeButtonMenu';
import DisLikeButtonMenu from './FeedbackButtonsMenu/DisLikeButtonMenu';
import MouseOutlinedIcon from '@material-ui/icons/MouseOutlined';
import MouseIcon from '@material-ui/icons/Mouse';
import { isDesktop, isMobile } from 'react-device-detect';
import {SkipNext as SkipNextIcon, SkipPrevious as SkipPreviousIcon} from '@material-ui/icons';
import FlowDropdown from './FlowDropdown';

const PresentationNavBar = function (props) {
    const globalState = useSelector(state => state);
    const {focusedPresentationFlows} = props;

    const [presentationFeedback, setPresentationFeedback] = useState([]);
    const [currentFeedback, setCurrentFeedback] = useState({ vote: 0, comment: null });
    const [feedbackDialog, setFeedbackDialogOpen] = useState(false);
    const [responsiveMode, setResponsiveMode] = useState(null);
    const [numFlow, setNumFlow] = useState(0);

    useEffect(() => {
        if(focusedPresentationFlows && focusedPresentationFlows.indexOfSelectedFlow) {
            setNumFlow(focusedPresentationFlows.indexOfSelectedFlow);
        }
    }, [focusedPresentationFlows])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        slideCounter: {
            flexGrow: 0,
            marginRight: theme.spacing(2),
            color: 'WHITE',
            fontSize: '16px',
            borderColor: 'WHITE'
        },
        mouseOutline: {
            marginRight: theme.spacing(2)
        }
    }));

    const classes = useStyles();

    const deploymentStyle = {
        'accentColor': getDeploymentParamValue(globalState.auth.deployment.parameters, "ACCENT_COLOR"),
    };

    let isFeedbackEnable = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_FEEDBACK_ENABLE") == '1' ? true : false;

    let publisherDevice = "DESKTOP";
    if (lowerThanTablet) {
        publisherDevice = "PHONE";
    } else if (KTUtil.isInResponsiveRange("tablet")) {
        publisherDevice = "TABLET";
    }

    let isMouseMoveEnable = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_MOUSE_SHARE_ENABLE") == '1' ? true : false;
    let mouseVaDisabled = '';
    if(isMouseMoveEnable){
        mouseVaDisabled = getDeploymentParamValue(globalState.auth.deployment.parameters, "VISUALAID_MOUSE_TYPES_DISABLE");
        mouseVaDisabled = mouseVaDisabled.split(',');
    }
    

    if(globalState.presentationBar.presentationFocused && isMouseMoveEnable && responsiveMode){
       if(globalState.presentationBar.presentationFocused.type == "sdva" && responsiveMode.responsive_mode != "user"){
            isMouseMoveEnable = false;
        }else if(globalState.presentationBar.presentationFocused.type == "html5animate" && responsiveMode.responsive_mode == "scale"){
            isMouseMoveEnable = false;
        }
    }


    useEffect(() => {
        if(globalState.presentationBar.presentationFocused && globalState.presentationBar.presentationFocused.type == 'sdva'){
            fetch(globalState.presentationBar.presentationFocused.baseUrl+'/script.json').then(response => response.json()).then(data => setResponsiveMode(data));
        }else if(globalState.presentationBar.presentationFocused && globalState.presentationBar.presentationFocused.type == 'html5animate'){
            fetch(globalState.presentationBar.presentationFocused.baseUrl+'/config.json').then(response => response.json()).then(data => setResponsiveMode(data));
        }

        fillPresentationFeedBack();
    }, []);

    useEffect(() => {
        fillPresentationFeedBack();
    }, [globalState.presentationBar.slideFeedbackResult]);

    useEffect(() => {
        if(isPresentationFeedbackAllowed(globalState.presentationBar.focusedPresentationSlides.type) && globalState.presentationBar.numSlide >= 0){
            let numSlide = globalState.presentationBar.numSlide;
            let currentSlide = globalState.presentationBar.focusedPresentationSlides.content[numSlide]
    
            if(!presentationFeedback || presentationFeedback.length <= 0){
                setCurrentFeedback(getFeedbackBySlideCodeFromLocalStorage(currentSlide.code));
            }else{
                setCurrentFeedback(getFeedbackBySlideCode(currentSlide.code));
            }
        }
    }, [globalState.presentationBar.numSlide]);
    
    const isPresentationFeedbackAllowed = (type) =>{
        return type == 'slides' || type == 'sdva' || type == 'html5animate' || type == 'video'
    }

    const voteSlide = (voteValue) => {
        let numSlide = globalState.presentationBar.numSlide;
        let currentSlide = globalState.presentationBar.focusedPresentationSlides.content[numSlide];

        addFeedback(currentSlide.code, voteValue);
    }

    const editComment = (newComment) => {
        setFeedbackDialogOpen(false);

        setFeedbackCommnent(newComment);
    }

    const addFeedback = (codeSlide, vote) => {
        let key = 'feedback_' + globalState.auth.deployment.callData?.code;

        let feedBack;
        let feedBackIndex;
        let existFeedback = false;
        if(presentationFeedback && presentationFeedback.length > 0){
            for(feedBackIndex = 0; feedBackIndex < presentationFeedback.length; feedBackIndex++){
                feedBack = presentationFeedback[feedBackIndex];

                if(feedBack.codeSlide == codeSlide){
                    existFeedback = true;
                    break;
                }
            }
        }
        
        let newFeedback = { codeSlide: codeSlide, vote: vote, comment: null, isSaved: false };

        if(existFeedback){
            presentationFeedback.splice(feedBackIndex, 1);
        
            newFeedback.vote = vote;
            newFeedback.comment = vote != 0 ? feedBack.comment : null;
        }

        presentationFeedback.push(newFeedback);

        if(presentationFeedback){
            localStorage.setItem(key, JSON.stringify(presentationFeedback));
        }
        
        if(!presentationFeedback || presentationFeedback.length <= 0){
            setCurrentFeedback(getFeedbackBySlideCodeFromLocalStorage(codeSlide));
        }else{
            setCurrentFeedback(getFeedbackBySlideCode(codeSlide));
        }
    }

    const setFeedbackCommnent = (newComment) => {
        let key = 'feedback_' + globalState.auth.deployment.callData?.code;

        let numSlide = globalState.presentationBar.numSlide;
        let currentSlide = globalState.presentationBar.focusedPresentationSlides.content[numSlide];

        let feedBack;
        let feedBackIndex;
        if(presentationFeedback && presentationFeedback.length > 0){
            for(feedBackIndex = 0; feedBackIndex < presentationFeedback.length; feedBackIndex++){
                feedBack = presentationFeedback[feedBackIndex];

                if(feedBack.codeSlide == currentSlide.code){
                    break;
                }
            }
        }

        presentationFeedback.splice(feedBackIndex, 1);

        feedBack.isSaved = false;
        feedBack.comment = newComment ? newComment.replace(/[|&;"<>(){}+,]/g, "") : null;
        presentationFeedback.push(feedBack);    

        if(presentationFeedback){
            localStorage.setItem(key, JSON.stringify(presentationFeedback));
        }
        
        if(!presentationFeedback || presentationFeedback.length <= 0){
            setCurrentFeedback(getFeedbackBySlideCodeFromLocalStorage(currentSlide.code));
        }else{
            setCurrentFeedback(getFeedbackBySlideCode(currentSlide.code));
        }
    }

    const fillPresentationFeedBack = () => {
        if(!globalState.presentationBar.focusedPresentationSlides || !globalState.presentationBar.focusedPresentationSlides.content 
            || globalState.presentationBar.focusedPresentationSlides.content.length <= 0){
            return;
        }
        
        let codeCall = (globalState.auth.deployment.callData && globalState.auth.deployment.callData.code) ? globalState.auth.deployment.callData?.code : 0
        let key = 'feedback_' + codeCall;
        let _presentationFeedback = localStorage.getItem(key);

        if (_presentationFeedback) {
            _presentationFeedback = JSON.parse(_presentationFeedback);
        }else{
            _presentationFeedback = [];
        }
        
        setPresentationFeedback(_presentationFeedback);

        let numSlide = globalState.presentationBar.numSlide;
        let currentSlide = globalState.presentationBar.focusedPresentationSlides.content[numSlide];

        if(!presentationFeedback || presentationFeedback.length <= 0){
            setCurrentFeedback(getFeedbackBySlideCodeFromLocalStorage(currentSlide.code));
        }else{
            setCurrentFeedback(getFeedbackBySlideCode(currentSlide.code));
        }
    }

    const getFeedbackBySlideCodeFromLocalStorage = function(codeSlide){
        let result = { vote: 0, comment: null };

        let codeCall = (globalState.auth.deployment.callData && globalState.auth.deployment.callData.code) ? globalState.auth.deployment.callData?.code : 0
        let key = 'feedback_' + codeCall;
        let _presentationFeedback = localStorage.getItem(key);

        if (_presentationFeedback) {
            _presentationFeedback = JSON.parse(_presentationFeedback);
        }else{
            _presentationFeedback = [];
        }

        let feedBack;
        for(var feedbackKey in _presentationFeedback){
            feedBack = _presentationFeedback[feedbackKey];

            if(feedBack.codeSlide == codeSlide){
                result = feedBack;
                break;
            }
        }

        return result;
    }

    const getFeedbackBySlideCode = function(codeSlide){
        let result = { vote: 0, comment: null };

        if(!presentationFeedback || presentationFeedback.length <= 0){
            return result;
        }

        let feedBack;
        for(var key in presentationFeedback){
            feedBack = presentationFeedback[key];

            if(feedBack.codeSlide == codeSlide){
                result = feedBack;
                break;
            }
        }

        return result;
    }

    const ControlButtons = () => {
        let mouseEnabledType = true;
        if(props && props.type){
            switch (props.type) {
                case 'sdva':
                case 'html5animate':
                    if(mouseVaDisabled.length > 0){
                        mouseVaDisabled.forEach((item) => {
                            if(item === 'sdva' || item === 'html5animate'){
                                mouseEnabledType = false;
                            }
                        })
                    }
                    return(
                        <>
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.PREVIOUSFLOW" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        color="inherit"
                                        id="gotoPreviousFlow"
                                        aria-label="previousFlow"
                                        disabled={globalState.presentationBar.focusedPresentationFlows.flows.length == 1}
                                        onClick={() => { props.previousFlow() }}
                                    >
                                        <SkipPreviousIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.PREVIOUS" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        color="inherit"
                                        id="gotoPrevious"
                                        aria-label="previous"
                                        disabled={props.numSlide == 0}
                                        onClick={() => { props.goToPrevious() }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            <Button variant="outlined" className={classes.slideCounter} onClick={ () => { if(globalState.auth.deployment.callData?.type !=2)props.showSlideList(globalState.presentationBar.presentationFocused, true) } }>
                                { props.numSlide + 1 }/{props.totalSlides}
                            </Button>

                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.NEXT" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        id="gotoNext"
                                        color="inherit"
                                        aria-label="next"
                                        disabled={props.numSlide >= globalState.presentationBar.focusedPresentationSlides.content.length - 1}
                                        onClick={() => { props.goToNext() }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.NEXTFLOW" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        color="inherit"
                                        id="gotoNextFlow"
                                        aria-label="nextFlow"
                                        disabled={globalState.presentationBar.focusedPresentationFlows.flows.length == 1}
                                        onClick={() => { props.nextFlow() }}
                                    >
                                        <SkipNextIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            {(props.preview) ?
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { window.close() }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { props.closePresentation('manual') }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            }

                            <FeedbackButtons feedback={currentFeedback} />
                            {(publisherDevice == "DESKTOP" && globalState.auth.deployment.callData?.type !=2 && isMouseMoveEnable && !props.preview && mouseEnabledType) ?
                                <MouseMoveButton />
                                : null}
                        </>
                    );
                case 'video':
                    return(
                        <>
                            {(props.preview) ?
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { window.close() }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { props.closePresentation('manual') }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            }

                            <FeedbackButtons feedback={currentFeedback} />
                        </>
                    );
                case 'slides':
                default:
                    if(mouseVaDisabled.length > 0){
                        mouseVaDisabled.forEach((item) => {
                            if(item === 'slides'){
                                mouseEnabledType = false;
                            }
                        })
                    }
                    return(
                        <>
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.PREVIOUS" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        id="gotoPrevious"
                                        color="inherit"
                                        aria-label="previous"
                                        disabled={globalState.presentationBar.numSlide == 0}
                                        onClick={() => { props.goToPrevious() }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            <Button variant="outlined" className={classes.slideCounter} onClick={ () => { if(globalState.auth.deployment.callData?.type !=2)props.showSlideList(globalState.presentationBar.presentationFocused, true) } }>
                                { props.numSlide + 1 }/{props.totalSlides}
                            </Button>

                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.NEXT" />}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        className={classes.menuButton} 
                                        id="gotoNext"
                                        color="inherit"
                                        aria-label="next"
                                        disabled={globalState.presentationBar.numSlide >= globalState.presentationBar.focusedPresentationSlides.content.length - 1}
                                        onClick={() => { props.goToNext() }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            {(props.preview) ?
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { window.close() }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.CLOSE" />}>
                                <IconButton 
                                    edge="start" 
                                    className={classes.menuButton} 
                                    color="inherit"
                                    aria-label="next"
                                    onClick={() => { props.closePresentation('manual') }}
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                            </Tooltip>
                            }
                          
                            <FeedbackButtons feedback={currentFeedback} />
                                {(globalState.auth.deployment.callData?.type !=2 && publisherDevice == "DESKTOP" && isMouseMoveEnable && mouseEnabledType) ?
                                    <MouseMoveButton />
                                    : null
                                }
                            </>
                    );
            }
        }else{
            return('');
        }
    }

    const MouseMoveButton = () => {
        return (
            <>

                {!props.preview && !props.showMouseMove && !isMobile ?
                    <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.MOUSEINDICATOR" />}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="next"
                        >
                            <MouseOutlinedIcon className={classes.mouseOutline} onClick={() => { props.sendCoordinates() }}></MouseOutlinedIcon>
                        </IconButton>
                    </Tooltip>
                    :
                    (!props.preview && !isMobile &&
                    <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.MOUSEINDICATORDISABLE" />}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="next"
                        >
                            <MouseIcon onClick={() => { props.stopMouseMove() }}></MouseIcon>
                        </IconButton>
                    </Tooltip>)
                    
                }

            </>
        )
    }

    const FeedbackButtons = (_props) => {

        if(isFeedbackEnable && !props.preview){
            return(
                <>
                    <LikeButtonMenu vote={currentFeedback.vote} voteSlide={voteSlide}></LikeButtonMenu>

                    <DisLikeButtonMenu vote={currentFeedback.vote} voteSlide={voteSlide}></DisLikeButtonMenu>

                    { currentFeedback && currentFeedback.vote != 0 &&
                        <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.EDITCOMMENT" />}>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="next" onClick={() => { setFeedbackDialogOpen(true) }}>
                                <RateReviewIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </>
            );
        }

        return('');
    }

    return (
        <>
            <AppBar position="static" style={{background: deploymentStyle.accentColor}}>
                <Toolbar variant={props.variant} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {!lowerThanTablet || props.type === 'video' ? (
                        <Typography variant="h6" className={classes.title}>
                            {props.label}
                        </Typography>
                        ) : null}
                    </div>

                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <ControlButtons />
                    </div>

                    {
                        globalState.presentationBar.focusedPresentationFlows && globalState.presentationBar.focusedPresentationFlows.flows && globalState.presentationBar.focusedPresentationFlows.flows.length > 0 &&
                        <div style={{ marginLeft: 'auto', width: "200px" }}>
                            <FlowDropdown
                                isPreview={props.preview}
                                flowName={globalState.presentationBar.focusedPresentationFlows.currentFlow.name}
                                gotoSlideSDVA={props.gotoSlideSDVA}
                            />
                        </div>
                    }  

                    <div>
                        <FeedbackCommentPopup
                        open={feedbackDialog}
                        currentVote={currentFeedback.vote}
                        comment={currentFeedback.comment}
                        saveComment={editComment}
                        voteSlide={voteSlide}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default injectIntl(PresentationNavBar);