import React from 'react';
import { connect, useSelector } from "react-redux";
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage, injectIntl } from "react-intl";
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import './FilterBar.scss';
// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles((theme) => ({
  searchChip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const FilterItems = ({
  open,
  closeFilter,
  props
}) => {

  const globalState = useSelector(state => state);
  const classes = useStyles();
  let tags = "";
  let tagsArray = [];
  let searchFields = [];
  let newListFiltered = []
  let currentAggregations = {};
  if (globalState.presentationBar.presentationsListFiltered && globalState.presentationBar.presentationsListFiltered.length > 0) {
    //console.log(globalState.presentationBar.presentationsListFiltered)
    for (let i = 0; i < globalState.presentationBar.presentationsListFiltered.length; i++) {
      let item = globalState.presentationBar.presentationsListFiltered[i];
      tags = item.tags;
      tagsArray = tags.split(';');

      // Limpio todos los items de cada tag para cada vaid
      for (let j = 0; j < tagsArray.length; j++) {
        const element = tagsArray[j];
        let title = element.substring(0, element.indexOf(":"));
        
        item[title] = [];
      }

      // Agrego un array de value por cada tag
      for (let index = 0; index < tagsArray.length; index++) {
        const element = tagsArray[index];
        let title = element.substring(0, element.indexOf(":"));
        let value = element.substring(element.indexOf(":") + 1, element.length);

        item[title].push(value);

        if(title && !currentAggregations[title]){
          searchFields.push(title);
          
          currentAggregations[title] = { title: title, size: 50 };
        }
      }
      newListFiltered.push(item);
    }
  }

  const [configuration, setConfiguration] = React.useState({
    searchableFields: [...searchFields, 'searchableName'],

    aggregations: currentAggregations
  });

  var newFilters = {};
  Object.keys(configuration.aggregations).map(function (v) {
    newFilters[v] = [];
  })

  if(globalState.presentationBar.selectedTagFilter && globalState.presentationBar.selectedTagFilter.length > 0){
    newFilters = globalState.presentationBar.filters;
  }

  const [itemsjs, setItemsjs] = React.useState(require('itemsjs')(newListFiltered, configuration));
  const [query, setQuery] = React.useState('');
  const [filters, setFilters] = React.useState(newFilters);
  const [result, setResult] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const { intl } = props;

  /*useEffect(() => {
    searchResult();
  }, [query]);*/

  useEffect(() => {
    if (result && result.data && result.data.items && ((globalState.presentationBar.selectedTagFilter && globalState.presentationBar.selectedTagFilter.length > 0) || globalState.presentationBar.filterText != "")){
      props.updateFilteredList(result.data.items)
    }else{
      props.updateFilteredList(globalState.presentationBar.presentationsList.list)
    }
  }, [result]);

  useEffect(() => {
    if(globalState.presentationBar.selectedTagFilter && globalState.presentationBar.selectedTagFilter.length > 0){
      setFilters(globalState.presentationBar.filters);
    }
   searchResult(globalState.presentationBar.filterText);
  }, []);

  useEffect(() => {
    searchResult(globalState.presentationBar.filterText);
   }, [globalState.presentationBar.filterText]);

  const searchResult = (e) => {

    var result = itemsjs.search({
      per_page: 500,
      query: e,
      filters: filters
    })
    
    setResult(result);

  }

  const resetFilter = () => {
    const oldFilters = filters;
    let newFilters = oldFilters;
    newFilters = globalState.presentationBar.filters;
    Object.keys(configuration.aggregations).map(function (v) {
      newFilters[v] = [];
    })
    setSelectedItem(null);
    setSelectedItems([]);
    setFilters(newFilters);
    Object.assign(globalState.presentationBar, {
      filters: newFilters,
      presentationsListFiltered: globalState.presentationBar.presentationsList.list,
      selectedTagFilter:null
    });
    //setQuery('');
    searchResult('');
    //props.updateFilteredList(globalState.presentationBar.presentationsList.list);
    props.filterReset();
    document.getElementById('closePresentationList').click();
    setTimeout(() => {
      document.getElementById('btnPresentationBarShowList').click();
      document.getElementById('filterPresentations').click();
    },600);
  }

  const searchManager = () => event => {
    //const oldQuery = query;
    //let newQuery = oldQuery;
    //newQuery = event.target.value;
    // setQuery(newQuery);
    /* var result = itemsjs.search({
       query: event.target.value,
       //filters: filters
     })*/
    //return result
    setQuery(event.target.value);
    // setResult(result);
    // if(result && result.data && result.data.items && result.data.items.length > 0)
    //   props.updateFilteredList(result.data.items)

  }

  const handleDelete = () => {
    searchResult('');
    Object.assign(globalState.presentationBar, {
      selectedTagFilter:null
    });
    props.filterReset();
  };

  const handleFilter = (filterName, filterValue) => event => {
    const oldFilters = filters;
    let newFilters = oldFilters;
    let check = event.target.checked;

    if (check) {
      newFilters[filterName].push(filterValue)

      setFilters(newFilters);
      searchResult(globalState.presentationBar.filterText);
      setSelectedItem(filterValue);

      let newSelectedItems = selectedItems;
      newSelectedItems.push(filterValue);
      setSelectedItems(newSelectedItems);
      Object.assign(globalState.presentationBar, {
        selectedTagFilter:newSelectedItems,
        filters: newFilters
      });

    } else {
      var index = newFilters[filterName].indexOf(filterValue);
      if (index > -1) {
        newFilters[filterName].splice(index, 1);
        setFilters(newFilters);

        let newSelectedItems = selectedItems;
        newSelectedItems.splice(index, 1);
        setSelectedItems(newSelectedItems);
        Object.assign(globalState.presentationBar, {
          selectedTagFilter:newSelectedItems,
          filters: newFilters
        });
        searchResult(globalState.presentationBar.filterText);
      }
    }
  };

  return (
    <div className="container">
      <Dialog
        open={open}
        onClose={closeFilter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="dialogCloseCall"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: "VAID.FILTERS.TITLE" })} 
          (
            {result && open ? result.pagination.total : null}
            /
            {result && open && globalState.presentationBar.presentationsList && globalState.presentationBar.presentationsList.list ? globalState.presentationBar.presentationsList.list.length : null}
            )</DialogTitle>
        <DialogContent>
          <div >
            {/*<Paper className="rootPaper">
              <InputBase
                className="inputBase"
                placeholder={intl.formatMessage({
                  id: "ECOMMERCE.COMMON.SEARCH"
                })}
                inputProps={{
                  'aria-label': intl.formatMessage({
                    id: "ECOMMERCE.COMMON.SEARCH"
                  })
                }}
                onChange={searchManager()}
                value={query}
              />
              <IconButton type="submit" className="iconButton" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>*/}
            <p >{/*searchResult.timings.search*/}  {/*searchResult.timings.facets*/} </p>
            {/*result && open ? Object.entries(selectedItems).map(([key, value]) => {
                 return (<div className={classes.root} ><Chip label={value}
                  variant="outlined" color="secondary" onDelete={handleDelete} /></div >)
            })
          : null */}
          {result && open && globalState.presentationBar.filterText !='' ? <div className={classes.searchChip} ><Chip label={globalState.presentationBar.filterText}
                  variant="outlined" color="grey" onDelete={handleDelete} /></div >

          : null }
            <div >
              <br></br>
              <div style={{ overflow: "auto" }} >              
                {result && open ?
                  Object.entries(result.data.aggregations).map(([key, value]) => {
                    return (
                      <div key={key}>

                        { value && value.buckets && value.buckets.length > 0 &&
                          <>
                            <h5 id="selectedItemsContainer">{value.title}</h5>

                            {
                              Object.entries(value.buckets).map(([keyB, valueB]) => {
                                return (

                                  <div style={selectedItem == valueB.key || globalState.presentationBar.selectedTagFilter == valueB.key? {} : null}>
                                    <label >
                                      <input type="checkbox" checked={filters[value.name].indexOf(valueB.key) > -1 || false} onChange={handleFilter(value.name, valueB.key)} />
                                      {" " + valueB.key} ({valueB.doc_count})
                                    </label>
                                  </div>

                                )
                              })
                            }
                          </>
                        }
                      </div>
                    )
                  }) : null
                }
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="btnResetFilters" onClick={() => resetFilter()} color="primary" autoFocus>
            <FormattedMessage id="VAID.FILTERS.BUTTON.RESET" />
          </Button>
          
          <Button id="btnCloseFilters" onClick={() => closeFilter()} color="primary" autoFocus>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...scheduleStorage.actions, ...presentationBarStorage.actions }
  )(FilterItems)
);