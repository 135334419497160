import React, { useState, useEffect, useRef } from 'react';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { connect, useSelector } from "react-redux";
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import { FormattedMessage, injectIntl } from "react-intl";
import CheckIcon from '@material-ui/icons/Check';
import ReactHtmlParser from 'react-html-parser';


const Step = (props) => {
  const globalState = useSelector(state => state);
  const [customerRequestIntro, setCustomerRequestIntro] = useState("");

  useEffect(() => {

    if(globalState.presentationBar.customerRequestData.type == 2){
      props.getTermByCode(globalState.presentationBar.customerRequestData.termCode);
    }

  }, [globalState.presentationBar.customerRequestData.type])

  useEffect(() => {
    const isRequestFromCustomer = globalState.presentationBar.isRequestFromCustomer;
    const deploymentParameters = globalState.auth.deployment.parameters;
    const authData = globalState.auth.deployment.callData;
    const callCode = globalState.auth.callCode;
    const deploymentName = globalState.auth.deployment.name;
  
    const requestIntroKey = isRequestFromCustomer ? 'CUSTOMER_REQUEST_INTRO_CUSTOMER' : 'CUSTOMER_REQUEST_INTRO';
    const userNameKey = isRequestFromCustomer ? 'subscriberName' : 'organizerName';
    const replaceKey = isRequestFromCustomer ? '{name}' : '{repname}';
  
    let requestIntroStr = getDeploymentParamValue(deploymentParameters, requestIntroKey);
    const userName = authData[userNameKey];
  
    requestIntroStr = requestIntroStr
      .replace('{callcode}', callCode)
      .replace(replaceKey, userName)
      .replace('{depname}', deploymentName);
  
    const customerRequestIntro = ReactHtmlParser(requestIntroStr);
    setCustomerRequestIntro(customerRequestIntro);
  }, [globalState.presentationBar.isRequestFromCustomer]);

  return(
    <div>
      {props.index === 0 &&
      <div>
        {customerRequestIntro}
      </div>
      }
      {props.index === 1 && globalState.presentationBar.customerRequestData.type === 1 &&
      <div className="step__list-items">
        <div><FormattedMessage id="CUSTOMER.REQUEST.DIALOG.PRODUCTS.LABEL" /></div>
        {globalState.presentationBar.customerRequestData.products.map((item) => {
          return(
            <div key={item.code + "-" + item.name}>
              <CheckIcon />&nbsp;<span>({item.quantity})</span>&nbsp;<span>{item.name}</span>
            </div>
          )
        })}
      </div>
      }
      {props.index === 1 && globalState.presentationBar.customerRequestData.type === 2 &&
      <div className="step__terms">
        <div>{globalState.presentationBar.getTermByCodeResult.title}</div>
        <div>{ReactHtmlParser(globalState.presentationBar.getTermByCodeResult.body)}</div>
      </div>
      }
      {props.index === 2 &&
        props.children
      } 
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(Step)
);