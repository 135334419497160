import React from 'react';
import { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

import * as productStorage from "../products.duck";

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: 280
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  }

}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeaderProduct = (props) => {
  const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [name, setName] = useState('');
  const [folder, setFolder] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [validFrom, setValidFrom] = useState(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
  const [validTo, setValidTo] = useState(moment(new Date()).add(30, 'days').format('YYYY-MM-DDTHH:mm:ss'));
  const [order, setOrder] = useState(0);
  const [planName, setPlanName] = React.useState([]);
  const [allowShare, setAllowShare] = useState(0);

  useEffect(() => {
    return () => {
      props.fillResultProductContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.product.productContainerResponse.name && globalState.product.productContainerResponse.externalCode){
      setName(globalState.product.productContainerResponse.name);
      setFolder(globalState.product.productContainerResponse.folder);
      setAllowShare(globalState.product.productContainerResponse.allow_share);
      setExternalCode(globalState.product.productContainerResponse.externalCode);
      setValidFrom(globalState.product.productContainerResponse.validFrom);
      setValidTo(globalState.product.productContainerResponse.validTo);
      setOrder(globalState.product.productContainerResponse.order);

      if(globalState.product.productContainerResponse.plans &&
        globalState.product.productContainerResponse.plans.length > 0){
          let plansSelected = [];
          globalState.product.productContainerResponse.plans.forEach((plan, index) =>{
            globalState.product.promotionalPlans.list.forEach((item, index) => {
              if(plan === item.code){
                plansSelected.push(item);
              }
            })
          })
          setPlanName(plansSelected);
      }


      
    }
  }, [globalState.product.productContainerResponse.name, globalState.product.productContainerResponse.externalCode]);

  const handleChangePlanName = (event, values) => {
    setPlanName(values);
    props.fillResultProductContainer({...globalState.product.productContainerResponse, plans: values})
  };

  const handleChangeName = (event) => {
    setName(event.target.value)
    props.fillResultProductContainer({...globalState.product.productContainerResponse, name: event.target.value})
  }
  
  const handleChangeFolder = (event) => {
    setFolder(event.target.value)
    props.fillResultProductContainer({...globalState.product.productContainerResponse, folder: event.target.value})
  }

  const handleExternalCode = (event) => {
    setExternalCode(event.target.value);
    props.fillResultProductContainer({...globalState.product.productContainerResponse, externalCode: event.target.value})
  } 

  const handleChangeAllowShare = (event) => {
    setAllowShare(event.target.checked);
    props.fillResultProductContainer({...globalState.product.productContainerResponse, allow_share:Number(event.target.checked)});
  }

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
    props.fillResultProductContainer({...globalState.product.productContainerResponse, validFrom: event.target.value})
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
    props.fillResultProductContainer({...globalState.product.productContainerResponse, validTo: event.target.value})
  }

  const handleOrder = (event) => {
    setOrder(event.target.value);
    props.fillResultProductContainer({...globalState.product.productContainerResponse, order: event.target.value})
  } 

  let statusText = ''; //(1=aprobado, 2=pendiente, 3= Borrador, 4=Temporal)
  if(globalState.product.productContainerResponse.status){
    switch(globalState.product.productContainerResponse.status){
      case 1:
        statusText = intl.formatMessage({ id: "PRODUCTS.STATUS.APPROVED" })
        break;
      case 2:
        statusText = intl.formatMessage({ id: "PRODUCTS.STATUS.PENDING" })
        break;
      case 3:
        statusText = intl.formatMessage({ id: "PRODUCTS.STATUS.DRAFT" })
        break;
      case 4:
        statusText = intl.formatMessage({ id: "PRODUCTS.STATUS.TEMP" })
        break;
      default:
        statusText = ''
    }
  }

  let labelPresentations = intl.formatMessage({
    id: "VAID.EDIT.FORM.PLANOS"
  });

  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id={"PRODUCTS.EDIT.FORM.NAME"} />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'name'}
            value={name}
            onChange={handleChangeName}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id={"PRODUCTS.EDIT.FORM.EXTERNAL_CODE"} />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={externalCode}
            onChange={handleExternalCode}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PRODUCTS.EDIT.FORM.VALID_FROM" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'validFrom' || globalState.file.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validFrom}
            onChange={handleChangeDateFrom}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PRODUCTS.EDIT.FORM.VALID_TO" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.file.errorValidation === 'validTo' || globalState.file.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validTo}
            onChange={handleChangeDateTo}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="PRODUCTS.EDIT.FORM.ORDER" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            value={order}
            onChange={handleOrder}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PRODUCTS.EDIT.FORM.STATUS" />: </span>
        <span className={classes.data}>{statusText}</span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PRODUCTS.EDIT.FORM.PLANOS" />: </span>
        <span className={classes.data}>
          <Autocomplete
            multiple
            id="tags-standard-visual-aids"
            options={globalState.file.promotionalPlans.list}
            disableCloseOnSelect={true}
            onChange={handleChangePlanName}
            value={planName}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
               </React.Fragment>
               )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={labelPresentations}
                  />
              )}
           />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    productStorage.actions
  )(HeaderProduct)
);