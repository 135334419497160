import React, {useEffect} from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import * as authStorage from "../../store/ducks/auth.duck";

const DefaultError = (props) => {
  useEffect(() => {
    props.analyticsLogEvent(
      "screen_view",
      { name: "errorPage"}
    )
  }, [])

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/error/bg1.jpg"
            )})`
          }}
        >
          <div className="kt-error-v1__container">
            <h2 className="kt-error-v1__number">OOPS!</h2>
            <p className="kt-error-v1__desc">Something went wrong here</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default 
  connect(
    null,
    authStorage.actions
  )(DefaultError);