import React, {useEffect, useState} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import MaterialTable from 'material-table';
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './ProductTab.scss';

const ProductTab = (props) => {
  const {intl} = props;
  
  const handleQtyChange = (rowData) => event => {
    rowData.tableData.quantity = parseInt((event.target.value > 9999) ? 9999 : event.target.value, 10);
    rowData.tableData.quantity = parseInt((event.target.value <= 0) ? 1 : event.target.value, 10);
    props.setProducts({...props.products, [rowData.code]: parseInt(parseInt((event.target.value > 9999) ? 9999 : event.target.value, 10), 10)});
  }

  useEffect(() => {
    props.productList.forEach((product) => {
      product.tableData.checked = false;
    })
  }, [props.tabValue]);

  const handleSelectionChange = (rows, rowData) => {
    if(rowData.tableData.checked == false){
      rowData.tableData.quantity = 1;
    }
    let productHash = {};
    rows.forEach((item) => {
      
      if(!props.products[item.code]){
        if(rowData.tableData.quantity){
          productHash[item.code] = rowData.tableData.quantity;
        }else{
          productHash[item.code] = 1;
        }
      }else{
        productHash[item.code] = props.products[item.code];
      }
    })
    props.setProducts(productHash);
  }

  let columns = [
    { title: intl.formatMessage({id: "ARCHIVE.TABLE.IDENTIFICATION"}), field: 'id',cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} },
    { title: intl.formatMessage({id: "ARCHIVE.TABLE.SIZE_READABLE"}), field: 'name', cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} },
    { 
      title: intl.formatMessage({id: "ARCHIVE.TABLE.SIZE_READABLE"}),
      field: 'quantity', 
      cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{},
      render: rowData => <TextField
        id="standard-number"
        type="number"
        InputProps={
          { 
            inputProps: { min: 1, max: 9999 }, 
            ...(!rowData.tableData.checked ? {value: 1} : {value: rowData.tableData.quantity})
          }
        }
        onChange={(rowData.tableData.checked === true) ? handleQtyChange(rowData) : null}
        disabled={Boolean(!rowData.tableData.checked)}
        defaultValue={1}
        InputLabelProps={{
          shrink: true,
        }}
      />
    },
  ];
  let actionsColumnIndex = 1;

  return(
        <MaterialTable
          columns={columns}
          title=""
          data={props.productList}
          components={{
            //Pagination: () => (null),
          }}
          options={
            { 
              actionsColumnIndex: actionsColumnIndex, 
              pageSize: 10, 
              searchFieldAlignment: 'left',
              headerStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{},
              selection: true,
              showTextRowsSelected: false,
            }
          }
          onSelectionChange={handleSelectionChange}
          localization={{
            header: {
              actions: ''
            },
            toolbar: {
              searchPlaceholder: intl.formatMessage({
                id: "ECOMMERCE.COMMON.SEARCH"
              })
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage({
                id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
              })
            },
            pagination: {
              labelDisplayedRows: intl.formatMessage({
                id: "MATERIAL_TABLE.PAGINATION.TEXT"
              }, {
                from: '{from}',
                to: '{to}',
                count: '{count}'
              }),
              labelRowsSelect: intl.formatMessage({
                id: "MATERIAL_TABLE.PAGINATION.LABEL"
              })
            },
            
          }}
        />
  )
}

export default injectIntl(ProductTab);