import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from "react-redux";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
//import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import HeaderDropdownToggle from "../../partials/content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as CompilingIcon } from "../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg";
import NotificationItem from './NotificationItem';

import * as scheduleStorage from "../../../app/store/ducks/schedule.duck";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

const Notifications = (props) => {
  const globalState = useSelector(state => state);

  const [key, setKey] = useState('Alerts');
  const { useSVG, icon } = props;
  const [pulse, setPulse] = useState(false);
  const [notificationViewed, setNotificationViewed] = useState(false);

  useEffect(() => {
    // llama a la funcion que trae las notificaciones
    props.getNotifications(); // si no le mando nada usa un 0 por defecto 
    let theInterval = window.setInterval(() => {
      props.getNotifications();
    }, 60000);
    return () => {
      clearInterval(theInterval);
    }
  }, []);

  const ref = useRef();
  useEffect(() => {
    if(globalState.schedule.notifications.length > 0 && 
      ref.current !== globalState.schedule.notifications.length
    ){
      ref.current = globalState.schedule.notifications.length;
      setPulse(true);
    }else{
      if(globalState.schedule.notifications.length === 0){
        setPulse(false);
      }
    }
  }, [globalState.schedule.notifications.length]);
  

  const getHeaderTopBarCssClassList = () => {
    let result = "kt-header__topbar-icon ";
    if (pulse) {
      result += "kt-pulse kt-pulse--brand ";
    }

    const { iconType } = props;
    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`;
    }

    return result;
  };

  const getSvgCssClassList = () => {
    let result = "kt-svg-icon ";
    const { iconType } = props;
    if (iconType) {
      result += `kt-svg-icon--${iconType}`;
    }

    return result;
  };

  const getHetBackGroundCssClassList = () => {
    let result = "kt-head ";
    if (props.skin) {
      result += `kt-head--skin-${props.skin} `;
    }

    result += "kt-head--fit-x kt-head--fit-b";
    return result;
  };

  const backGroundStyle = () => {
    if (!props.bgImage) {
      return "none";
    }

    return "url(" + props.bgImage + ")";
  };

  const userNotificationsButtonCssClassList = () => {
    let result = "btn ";
    if (props.type) {
      result += `btn-${props.type} `;
    }

    result += "btn-sm btn-bold btn-font-md";
    return result;
  };

  const ulTabsClassList = () => {
    let result = "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ";
    if (props.type) {
      result += `nav-tabs-line-${props.type} `;
    }

    result += "kt-notification-item-padding-x";
    return result;
  };

  return (
    <Dropdown 
      className="kt-header__topbar-item" 
      onClick={() => {
        setPulse(false);
        setNotificationViewed(true);
      }}
      drop="down"
      alignRight
    >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-notifications"
        >
          <span className={getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span className={getSvgCssClassList()}>
                <CompilingIcon />
              </span>
            )}

            <span className="kt-pulse__ring" hidden={!pulse} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className={getHetBackGroundCssClassList()}
              style={{ backgroundImage: backGroundStyle() }}
            >
              <h3 className="kt-head__title">
                <FormattedMessage id="SCHEDULE.NOTIFICATIONS.TITLE" />
              </h3>

              <Tab.Container
                defaultActiveKey={key}
                className={ulTabsClassList()}
              >
                <Nav
                  className={ulTabsClassList()}
                  onSelect={_key => setKey( _key)}
                >
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="Alerts" className="nav-link show">
                      <FormattedMessage id="SCHEDULE.NOTIFICATIONS.TAB_ONE" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="Alerts">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                          data-scroll="true"
                          data-height="300"
                          data-mobile-height="200"
                        >
                          {globalState.schedule.notifications.map((notification, index) => {
                            return (
                              <NotificationItem key={index} category='CALL_ALARM' notification={notification} open={notificationViewed} />
                            )
                          })}
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
  )
}

export default 
  connect(
    null,
    {...scheduleStorage.actions}
  )(Notifications);