import axios from "axios";
import {configUrls} from '../../globalConfig';

export const UPDATE_URL = `${configUrls.base_url}/api/call/update/`;
export const CLOSE_CALL_URL = `${configUrls.base_url}/api/call/action/end/`
export const GET_NOTIFICATIONS_URL = `${configUrls.base_url}/api/usernotification/search/byLastCode?lastCode=`
export const GET_ARCHIVES_OF_ITEMS_URL = `${configUrls.base_url}/api/callarchive/listbycall/`
export const GENERATE_TOKEN_URL = `${configUrls.base_url}/api/callarchive/action/generatetoken/`

export function modifyDateOfCall(duedateUTC, callcode, guestid){
  if(duedateUTC && callcode){
    return axios.put(UPDATE_URL + callcode, {
      duedateUTC:duedateUTC,
      state_Id: 1,
      guestId: guestid
    });
  }
}

export function closeCall(callcode){
  return axios.post(CLOSE_CALL_URL + callcode);
}

export function inactivateCall({duedateUTC, callcode}){
  return axios.put(UPDATE_URL + callcode, {
    duedateUTC: duedateUTC,
    state_Id: 0
  });
}

export function updateCall({state_Id, duedateUTC, callcode, guestid}){
  return axios.put(UPDATE_URL + callcode, {
    state_Id: state_Id,
    duedateUTC: duedateUTC,
    guestId: guestid
  });
}

export function getNotifications(lastCode){
  return axios.get(GET_NOTIFICATIONS_URL + lastCode);
}

export function getArchivesOfItem(callCode){
  return axios.get(GET_ARCHIVES_OF_ITEMS_URL + callCode);
}

export function generateArchiveToken(code, archiveId){
  return axios.post(GENERATE_TOKEN_URL + code + "/" + archiveId);
}