import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import './SearchFile.scss';

// Importando el duck de presentationBar cambiar logica de duck
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const SearchFile = function (props) {
  const { intl } = props;
  const [searchText, setSearchText] = useState('');
 
  useEffect(() => {
    if(searchText.length >= 2){
      props.fileSearch(searchText, true);
    }else{
      props.fileSearch('', false);
    }
  }, [searchText])
 
  const searchManager = () => event => {
    setSearchText(event.target.value)
  }
 
  return (
    <Paper className="rootPaper">
      <InputBase
        className="inputBase"
        placeholder={intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        })}
        inputProps={{ 'aria-label': intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        }) }}
        onChange={searchManager()}
        value={searchText}
      />
      <IconButton type="submit" className="iconButton" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(SearchFile)
);