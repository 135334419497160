import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  changePassword
} from "../../crud/dashboard.crud";

export const actionTypes = {
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SET_PASSWORD_RESULT: "SET_PASSWORD_RESULT"
};

const initialScheduleState = {
  loading: false,
  passwordResult: null
};

export const reducer = persistReducer(
    { storage, key: "demo1-schedule", whitelist: [""] },
    (state = initialScheduleState, action) => {
      switch (action.type) {
        case actionTypes.CHANGE_PASSWORD: {
          return { ...state, loading: true };
        }
        case actionTypes.SET_PASSWORD_RESULT: {
          return { 
            ...state, 
            loading: false,
            passwordResult: action.payload
          };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  changePassword: (value) => ({ type: actionTypes.CHANGE_PASSWORD, payload: value }),
  setPasswordResult: (value) => ({ type: actionTypes.SET_PASSWORD_RESULT, payload: value })
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_PASSWORD, function* changePasswordSaga({payload}) {
    try{
      const data = yield changePassword(payload.oldPassword, payload.newPassword);
      if(data.status < 300){
        yield put(actions.setPasswordResult(true));
      }else{
        yield put(actions.setPasswordResult(false));
      }
     //yield put(actions.setPasswordResult(false));
    }catch(e){
      yield put(actions.setPasswordResult(false));
      //console.log(e)
    }
    
  });


}
