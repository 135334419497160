import React from "react";
import VisitOfflineGrid from '../../components/VisitOfflineGrid';
import PresentationLobby from '../../components/PresentationLobby';
import ReactInterval from 'react-interval';
import { connect } from "react-redux";
import * as loggerStorage from "../../store/ducks/logger.duck";
import {timerLog} from '../../../globalConfig';
                  
const PresentationPageGrid = (props) => {
    return (
        <>
            <ReactInterval 
                timeout={timerLog} 
                enabled={true}
                callback={() => {props.flush(); props.flushChat()}} />
            <VisitOfflineGrid history={props.history}></VisitOfflineGrid>
        </>
    )
}   

export default connect(
    null,
    loggerStorage.actions
)(PresentationPageGrid);