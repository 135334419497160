import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';

const BackToScheduleButton = (props) => {
  // este boton se va a mostrar en la llamada, tiene que volver a la agenda usando la logica
  // ya implementada en el boton endCall


  return(
    <Button onClick={props.endCall()}>
      volver
    </Button>
  )
}

export default injectIntl(BackToScheduleButton);