import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import moment from 'moment';

class Logout extends Component {
  componentDidMount() {

    //const history = useHistory();

    if(this.props.global.auth.deployment.callData){
      if(this.props.global.presentationBar.callTime){
        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_ABORT',
          log_text: ``
        })
      }
      
      this.props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'CALL_LOGOUT',
        log_text: ''
      })
      this.props.flush();
    }
    this.props.resetAll();

    //console.log(this.props.location)
    //console.log(this.props.location.state.fromCall)
    //console.log(this.props.global.auth.role)

    if(this.props.global.auth.role == 'organizer' || this.props.global.auth.role == 'guest'){
      this.props.logoutWithStorage();
      this.props.history.push("/auth/registration");
    }else{
      this.props.logoutWithStorage()
      if(this.props.global.auth.role == 'user'){
        //this.props.history.push("/auth/registration");
        window.location.href="/auth/registration";
      }else{
        this.props.history.push("/auth/login");
      }
    }
    //this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return (<LayoutSplashScreen />);
    //return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

const mapStateToProps = (state) => {
  return {
    hasAuthToken: Boolean(state.auth.authToken),
    global: state
  }
}
export default connect(
  mapStateToProps,
  {...auth.actions, ...presentationBarStorage.actions, ...loggerStorage.actions}
)(Logout);
