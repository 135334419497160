import axios from "axios";
import {configUrls} from '../../../globalConfig';

export const FILE_GET_URL = `${configUrls.base_url}/api/file?`;
export const FILE_ACTIVATE = `${configUrls.base_url}/api/file/action/activate/`;
export const FILE_INACTIVATE = `${configUrls.base_url}/api/file/action/inactivate/`;
export const FILE_APPROVE = `${configUrls.base_url}/api/file/action/approve/`;
export const FILE_PENDING = `${configUrls.base_url}/api/file/action/pending/`;
export const PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan?`;
export const CREATE_FILE_CONTAINER = `${configUrls.base_url}/api/file/create`; 
export const GET_FILE_EDIT = `${configUrls.base_url}/api/file/`; 
export const SAVE_META_DATA_FILE_URL = `${configUrls.base_url}/api/file/update/`; 
export const FILE_GET_TAGS_OPTIONS_URL = `${configUrls.base_url}/api/Filetag`; 
export const FILE_FOLDER_GET_ALL = `${configUrls.base_url}/api/filefolder/`; 

export function getFileTagsOptions(){
  return axios.get(FILE_GET_TAGS_OPTIONS_URL);
}

export function saveMetaDataFile(payload){
  let plansIdsArr = null;
  if(payload.plans && payload.plans.length > 0){
    plansIdsArr = [];
    payload.plans.forEach((plan) => {
      if(typeof plan === 'object'){
        plansIdsArr.push(plan.code);
      }else{
        plansIdsArr.push(plan);
      }
    })
  }

  return axios.put(SAVE_META_DATA_FILE_URL + payload.code + (payload.queryString ? payload.queryString : ""), 
    {
      name: payload.name,
      folder: payload.folder,
      url: payload.fileName,
      mimeType: payload.mimeType,
      size: payload.size,
      externalCode: payload.externalCode,
      validFrom: payload.validFrom,
      validTo: payload.validTo,
      order: payload.order,
      plans: plansIdsArr,
      allow_share: payload.allow_share,
      allow_usershare: payload.allow_usershare,
      automatic_download: payload.automatic_download,
      allow_present: payload.allow_present,
      call_type: payload.call_type,
      tags: payload.tags,
      cod_folder: payload.cod_folder
    }
  );
} 

export function uploadFile(payload){
  let newAxiosInstance = axios.create();
  const options = {
    headers: {
      'x-ms-blob-type': payload.blobType,
      'Content-Type': payload.ContentType
    }
  };

  return newAxiosInstance.put(
    payload.url, 
    payload.body,
    options  
  );
} 

export function getFileEdit(codeFile){
  return axios.get(GET_FILE_EDIT + `${codeFile}?edit=true`);
}

export function createFileContainer(payload){
  return axios.post(CREATE_FILE_CONTAINER, {
    type: payload.type,
    name: payload.name
  });
}

export function getPromotionalPlan(state) {
  return axios.get(PROMOTIONAL_PLAN_URL + "state_id="+ state);
}

export function getFiles(state, promplan){
  return axios.get(FILE_GET_URL + "state_id="+ state + "&prom_plan="+promplan);
}

export function activateFile(code){
  return axios.put(FILE_ACTIVATE + code);
} 

export function inactivateFile(code){
  return axios.put(FILE_INACTIVATE + code);
} 

export function approveFile(code){
  return axios.put(FILE_APPROVE + code);
} 

export function pendingFile(code){
  return axios.put(FILE_PENDING + code);
} 

export function getFolders(){
  return axios.get(FILE_FOLDER_GET_ALL);
}