import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import './FabMenu.scss';
import {lowerThanTablet} from '../../../_metronic/utils/utils';

const CallLogoutButton = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleButtonMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    //props.endCall(true);
  };

  const back = () => event => {
    props.endCall(true, false)
  }
  
  let conditionalRadius = (props.organizer) ? '5px 5px 0 0' : '5px';
  return(
    <div>
      <div className="menu__button" style={(lowerThanTablet) ? {top:'6%'} : {}}>
        <Fab size="medium" color="primary" aria-label="More" onClick={handleButtonMenuClick}>
          <MoreVertIcon />
        </Fab>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          
        >
          <MenuItem id="logoutButtonContainer">
            <div className="header__call-user" style={{backgroundColor: props.accentColor, color: 'white', borderRadius: conditionalRadius}}>
              <div>
                <img alt="Pic" src={props.deployment.parameters[0].value} />
                <span className="kt-header__topbar-username">
                  {props.deployment.name}
                </span>
              </div>
              <div className="kt-notification__custom">
              <button id="btnSignOut" onClick={() => props.endCall(true,false,true)} className="btn btn-label-brand btn-sm btn-bold">
                  <FormattedMessage id="AUTH.LOGOUT" />
                </button>
              </div>
            </div>
          </MenuItem>
          {props.organizer &&
          <MenuItem onClick={back()} id="backButton">
            <Button><FormattedMessage id="SCHEDULE.NAME" /></Button>
          </MenuItem>
          }
        </Menu>
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    null
  )(CallLogoutButton)
);