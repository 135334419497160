import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import moment from 'moment';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableContainer from '@material-ui/core/TableContainer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Paper from '@material-ui/core/Paper';
import MaterialTable from "material-table";
import Button from '@material-ui/core/Button';
import { Chart } from "react-google-charts";
import 'chart.piecelabel.js';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';

const CallIndicators = (props) => {

  const globalState = useSelector(state => state);
  const { intl } = props;

  let callIndicators = [];
  let todayIndicators = [];
  let todayClosed = 0;
  let todayNotInited = 0;
  let todayInitedNotClosed = 0;

  let dateRangeIndicators = [];
  let dateRangeClosed = 0;
  let dateRangeNotInited = 0;
  let dateRangedNotClose = 0;

  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  let fromTime = moment(props.selectedFrom.getTime());
  let toTime = moment(props.selectedTo.getTime());

  for (let byDay in globalState.presentationBar.calls) {

    globalState.presentationBar.calls[byDay].forEach((item, index) => {
      let itemDate = moment(item.duedateUTC).format("YYYY-MM-DD");

      if (todayDate == itemDate) {
        switch (item.status) {
          case 1:
             if (item.last_CallSession == 0) {
               todayNotInited++;
               if(fromTime.diff(toTime,'days') != 0)
                  dateRangeNotInited++;
             } else {
               todayInitedNotClosed++;
               if(fromTime.diff(toTime,'days') != 0)
                  dateRangedNotClose++;
             }
             break;
           case 2:
             todayClosed++;
             if(fromTime.diff(toTime,'days') != 0)
                dateRangeClosed++;
            break;
          default:
            break;
        }
      } else {
        switch (item.status) {
          case 1:
            if (item.last_CallSession == 0) {
              dateRangeNotInited++;
            } else {
              dateRangedNotClose++;
            }
            break;
          case 2:
            dateRangeClosed++;
            break;
          default:
            break;
        }
      }
    });
  }

  let todayColors = {
    0: { color: '#5f5f5f' },
    1: { color: '#2e83aa' },
    2: { color: '#447c4f' }
  }

  if (todayNotInited > 0) {
    todayIndicators.push({
      indicatorKey: "CALL_OPEN_NOT_STARTED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.NOTINITED" }),
      indicatorValue: todayNotInited.toString()
    });
    if(todayClosed == 0 && todayInitedNotClosed == 0){
      todayColors = {
        0: { color: '#5f5f5f' },
      }
    }else if (todayClosed > 0 && todayInitedNotClosed == 0){
      todayColors = {
        0: { color: '#447c4f' },
        1: { color: '#5f5f5f' }
      }
    }else if (todayClosed == 0 && todayInitedNotClosed > 0){
      todayColors = {
        0: { color: '#2e83aa' },
        1: { color: '#5f5f5f' }
      }
    }
  }
  if (todayInitedNotClosed > 0) {
    todayIndicators.push({
      indicatorKey: "CALL_OPEN_STARTED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.INITED" }),
      indicatorValue: todayInitedNotClosed.toString()
    });
    if(todayNotInited == 0 && todayClosed == 0){
      todayColors = {
        0: { color: '#2e83aa' },
      }
    }else if (todayNotInited > 0 && todayClosed == 0){
      todayColors = {
        0: { color: '#5f5f5f' },
        1: { color: '#2e83aa' }
      }
    }else if (todayNotInited == 0 && todayClosed > 0){
      todayColors = {
        0: { color: '#447c4f' },
        1: { color: '#2e83aa' }
      }
    }
  }
  if (todayClosed > 0) {
    todayIndicators.push({
      indicatorKey: "CALL_CLOSED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.CLOSED" }),
      indicatorValue: todayClosed.toString()
    });
    if(todayNotInited == 0 && todayInitedNotClosed == 0){
      todayColors = {
        0: { color: '#447c4f' },
      }
    }else if (todayNotInited > 0 && todayInitedNotClosed == 0){
      todayColors = {
        0: { color: '#5f5f5f' },
        1: { color: '#447c4f' }
      }
    }else if (todayNotInited == 0 && todayInitedNotClosed > 0){
      todayColors = {
        0: { color: '#2e83aa' },
        1: { color: '#447c4f' }
      }
    }
  }

  let callIndicatorsTodayJson;
  let callIndicatorsJson;
  if (todayIndicators.length > 0) {
    let todayIndicatorsJson = JSON.stringify(todayIndicators);
    callIndicatorsTodayJson = '{"groupByName": "TODAY","groupByLabel": "'+intl.formatMessage({ id: "CALL.INDICATORS.TODAY" })+'",' + '"indicators": ' + todayIndicatorsJson + '}';
    callIndicators.push(JSON.parse(callIndicatorsTodayJson));
  }

  let dateRangeColors = {
    0: { color: '#5f5f5f' },
    1: { color: '#2e83aa' },
    2: { color: '#447c4f' }
  }

  if (dateRangeNotInited > 0) {
    dateRangeIndicators.push({
      indicatorKey: "CALL_OPEN_NOT_STARTED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.NOTINITED" }),
      indicatorValue: dateRangeNotInited.toString()
    });
    if(dateRangeClosed == 0 && dateRangedNotClose == 0){
      dateRangeColors = {
        0: { color: '#5f5f5f' },
      }
    }else if (dateRangeClosed > 0 && dateRangedNotClose == 0){
      dateRangeColors = {
        0: { color: '#447c4f' },
        1: { color: '#5f5f5f' }
      }
    }else if (dateRangeClosed == 0 && dateRangedNotClose > 0){
      dateRangeColors = {
        0: { color: '#2e83aa' },
        1: { color: '#5f5f5f' }
      }
    }
  }
  if (dateRangedNotClose > 0) {
    dateRangeIndicators.push({
      indicatorKey: "CALL_OPEN_STARTED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.INITED" }),
      indicatorValue: dateRangedNotClose.toString()
    });
    if(dateRangeNotInited == 0 && dateRangeClosed == 0){
      dateRangeColors = {
        0: { color: '#2e83aa' },
      }
    }else if (dateRangeNotInited > 0 && dateRangeClosed == 0){
      dateRangeColors = {
        0: { color: '#5f5f5f' },
        1: { color: '#2e83aa' }
      }
    }else if (dateRangeNotInited == 0 && dateRangeClosed > 0){
      dateRangeColors = {
        0: { color: '#447c4f' },
        1: { color: '#2e83aa' }
      }
    }
  }
  if (dateRangeClosed > 0) {
    dateRangeIndicators.push({
      indicatorKey: "CALL_CLOSED",
      indicatorLabel: intl.formatMessage({ id: "CALL.INDICATORS.CLOSED" }),
      indicatorValue: dateRangeClosed.toString()
    });
    if(dateRangeNotInited == 0 && dateRangedNotClose == 0){
      dateRangeColors = {
        0: { color: '#447c4f' },
      }
    }else if (dateRangeNotInited > 0 && dateRangedNotClose == 0){
      dateRangeColors = {
        0: { color: '#5f5f5f' },
        1: { color: '#447c4f' }
      }
    }else if (dateRangeNotInited == 0 && dateRangedNotClose > 0){
      dateRangeColors = {
        0: { color: '#2e83aa' },
        1: { color: '#447c4f' }
      }
    }
  }

  let callIndicatorsDateRangeJson;
  if (dateRangeIndicators.length > 0) {
    let dateRangeIndicatorsJson = JSON.stringify(dateRangeIndicators);
    let from = moment(props.selectedFrom.getTime()).format('MMMM DD');
    let to = moment(props.selectedTo.getTime()).format('MMMM DD');
    callIndicatorsDateRangeJson = '{"groupByName": "DATERANGE","groupByLabel": ' + '"' + from + ' - ' + to + '",' + '"indicators": ' + dateRangeIndicatorsJson + '}';
    callIndicators.push(JSON.parse(callIndicatorsDateRangeJson));
  }

  callIndicatorsJson = JSON.stringify(callIndicators);

  let tableData = [];
  let row = {};

  let todayChartData = [];
  let dateChartData = [];

  let chartTitleToday = '';
  let chartTitleDate = '';

  const showTable = 0;
  const showCharts = 1;

  callIndicators.map((group, p) => {
    let icon = '';
    if (group.groupByName == 'TODAY') {
      chartTitleToday = group.groupByLabel;
      todayChartData.push([group.groupByName, group.groupByLabel])
      icon = <Button size="small" >
        <TodayIcon />
        <span>&nbsp;</span>
        {group.groupByLabel}
      </Button>
    } else {
      chartTitleDate = group.groupByLabel;
      dateChartData.push([group.groupByName, group.groupByLabel])
      icon = <Button size="small" >
        <DateRangeIcon />
        <span>&nbsp;</span>
        {group.groupByLabel}
      </Button>
    }

    row = { group: icon, notinited: '', inited: '', closed: '' };
    group.indicators.map((indicator, j) => {
      group.groupByName == 'TODAY' ? todayChartData.push([indicator.indicatorLabel, parseInt(indicator.indicatorValue)]) : dateChartData.push([indicator.indicatorLabel, parseInt(indicator.indicatorValue)])
      switch (indicator.indicatorKey) {
        case 'CALL_OPEN_NOT_STARTED':
          row.notinited = indicator.indicatorValue
          break;
        case 'CALL_OPEN_STARTED':
          row.inited = indicator.indicatorValue
          break;

        case 'CALL_CLOSED':
          row.closed = indicator.indicatorValue
          break;

        default:
          break;
      }
    })
    tableData.push(row);
  })

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
      },
      table: {
        minWidth: 650,
      },
      paper: {
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    }),
  );


  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TrendingUpIcon />&nbsp;<Typography className={classes.heading}>{intl.formatMessage({ id: "CALL.INDICATORS.TITLE" })}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer className={classes.paper} component={Paper}>
            {showTable ?
              <MaterialTable
                options={{
                  paging: false,
                  title: false,
                  toolbar: false
                }}
                columns={[
                  { title: "", field: "group", width: 200 },
                  { title: intl.formatMessage({ id: "CALL.INDICATORS.NOTINITED" }), field: "notinited", cellStyle: { textAlign: 'center' } },
                  { title: intl.formatMessage({ id: "CALL.INDICATORS.INITED" }), field: "inited", cellStyle: { textAlign: 'center' } },
                  { title: intl.formatMessage({ id: "CALL.INDICATORS.CLOSED" }), field: "closed", cellStyle: { textAlign: 'center' } },
                ]}
                data={
                  tableData
                }
              />
              : null}
            {showCharts ?
              <div style={(isMobile || isIPad13 || isIPhone13) ? {} : { display: 'flex', justifyContent: 'space-between', overflow:'hidden' }}>
                {todayChartData && todayChartData.length > 0 ?
                  <div>
                    <Chart
                      width={'300px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>{intl.formatMessage({ id: "CALL.INDICATORS.LOADING.CHART" })}</div>}
                      data={todayChartData}
                      options={{
                        title: chartTitleToday,
                        pieHole: 0.5,
                        is3D: false,
                        pieSliceText: 'value',
                        pieceLabel: {
                          mode: 'value'
                        },
                        chartArea: {
                          left: 10,
                          right: 30
                        },
                        pieSliceTextStyle: {
                          color: 'black',
                        },
                        slices: todayColors
                      }}
                      rootProps={{ 'data-testid': '3' }}
                    />
                  </div>
                  : null}
                {dateChartData && dateChartData.length > 0 ?
                  <div>
                    <Chart
                      width={'270px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>{intl.formatMessage({ id: "CALL.INDICATORS.LOADING.CHART" })}</div>}
                      data={dateChartData}
                      options={{
                        title: chartTitleDate,
                        pieHole: 0.5,
                        is3D: false,
                        pieSliceText: 'value',
                        pieceLabel: {
                          mode: 'value'
                        },
                        chartArea: {
                          left: 10,
                          right: 50
                        },
                        pieSliceTextStyle: {
                          color: 'black',
                        },
                        slices: dateRangeColors
                      }}
                      rootProps={{ 'data-testid': '3' }}
                    />
                  </div> : null}
              </div> : null}
          </TableContainer>

        </ExpansionPanelDetails>
      </ExpansionPanel>

    </div>
  );
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(CallIndicators)
);