import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Presentation from '../../components/Presentation';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { getDeploymentParamValue } from '../../components/VideoChat/utils';

const PreviewVAPage = (props) => {
  const [active, setActive] = React.useState(false);
  const [vaLogoUrl, setVaLogoUrl] = React.useState("");
  const globalState = useSelector(state => state);
  let {code} = useParams();

  useEffect(() => {
    if(globalState.auth.deployment.parameters){
      setVaLogoUrl(getDeploymentParamValue(globalState.auth.deployment.parameters, "VA_LOGO_URL"));
    }
  }, [globalState.auth.deployment.parameters]);

  useEffect(() => {
    props.getPresentationObject(code, 0, vaLogoUrl);
  }, [vaLogoUrl]);

  useEffect(() => {
    if( 
      globalState.presentationBar &&
      globalState.presentationBar.focusedPresentationSlides.code &&
      globalState.presentationBar.numSlide != null || globalState.presentationBar.numSlide != undefined
    ){
      setActive(true);
    }

  }, [globalState.presentationBar.focusedPresentationSlides, globalState.presentationBar.numSlide]);

  //Esta funcion es llamada cuando el player de video esta listo para darle play
  // en este caso no iniciamos un stream
  const onVideoReady = (eventData) => {
    
    const videoPlayerElement = document.querySelector('#videoPresentationPlayer_html5_api');

    if(videoPlayerElement)
    {
      if (!videoPlayerElement.captureStream && !videoPlayerElement.mozCaptureStream) 
      {
        let errorStreamCapture = this.props.intl.formatMessage({
          id: "VIDEOCHAT.ERROR.STREAM.CAPTURE"
        });
        alert(errorStreamCapture);
        return;
      }
    }
    else
    {
      alert("no video player found");
    }
  };

  //Este evento es invocado cuando se cierra la presentacion de tipo video, entonces hay que hacer un unpublish del stream
  const onVideoDisposed = () => {
    console.log('video disposed');
  };

  const onHideMovie = () => {
    console.log('movieHided');
  }

  return (
    <>
    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#2f2f2f'}}>
      <div style={{
        width: '28%', 
        height: '28%',
        maxHeight: 400, 
        minWidth: '250px',
        backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcxIDQ2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSIwIiB4Mj0iMCIgeTE9IjAiIHkyPSIxIj48c3RvcCBvZmZzZXQ9IjY2LjY2JSIgc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNNzkgMzA4YzE0LjI1LTYuNSA1NC4yNS0xOS43NSA3MS0yOSA5LTMuMjUgMjUtMjEgMjUtMjFzMy43NS0xMyAzLTIyYy0xLjc1LTYuNzUtMTUtNDMtMTUtNDMtMi41IDMtNC43NDEgMy4yNTktNyAxLTMuMjUtNy41LTIwLjUtNDQuNS0xNi01NyAxLjI1LTcuNSAxMC02IDEwLTYtMTEuMjUtMzMuNzUtOC02Ny04LTY3cy4wNzMtNy4zNDYgNi0xNWMtMy40OC42MzctOSA0LTkgNCAyLjU2My0xMS43MjcgMTUtMjEgMTUtMjEgLjE0OC0uMzEyLTEuMzIxLTEuNDU0LTEwIDEgMS41LTIuNzggMTYuNjc1LTguNjU0IDMwLTExIDMuNzg3LTkuMzYxIDEyLjc4Mi0xNy4zOTggMjItMjItMi4zNjUgMy4xMzMtMyA2LTMgNnMxNS42NDctOC4wODggNDEtNmMtMTkuNzUgMi0yNCA2LTI0IDZzNzQuNS0xMC43NSAxMDQgMzdjNy41IDkuNSAyNC43NSA1NS43NSAxMCA4OSAzLjc1LTEuNSA0LjUtNC41IDkgMSAuMjUgMTQuNzUtMTEuNSA2My0xOSA2Mi0yLjc1IDEtNC0zLTQtMy0xMC43NSAyOS41LTE0IDM4LTE0IDM4LTIgNC4yNS0zLjc1IDE4LjUtMSAyMiAxLjI1IDQuNSAyMyAyMyAyMyAyM2wxMjcgNTNjMzcgMzUgMjMgMTM1IDIzIDEzNUwwIDQ2NHMtMy05Ni43NSAxNC0xMjBjNS4yNS02LjI1IDIxLjc1LTE5Ljc1IDY1LTM2eiIvPjwvc3ZnPg==)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.25
      }}>
      </div>
      <div style={{height: '100%', width: '100%'}}>
        {active &&
        <Presentation 
          showMouseMove={false} 
          stopMouseMove={false} 
          sendCoordinates={false} 
          isClickable={true} 
          onHideMovie={onHideMovie} 
          onVideoReady={onVideoReady} 
          onVideoDisposed={onVideoDisposed} 
          showLabel={true} 
          preview={true}
        />
        }
      </div>
    </div>
    
    </>
  );
}

export default injectIntl(
  connect(
      null,
      presentationBarStorage.actions
  )(PreviewVAPage)
);