/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { FormattedMessage, injectIntl } from "react-intl";
import './UserProfile.scss';
import Button from '@material-ui/core/Button';
import * as authStorage from '../../store/ducks/auth.duck';
import { defaultStyles } from '../../../globalConfig';
import PasswordPopUp from '../../components/PasswordPopUp';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  getDeploymentParamValue
} from '../../../_metronic/utils/utils';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserProfileMenu = (props) => {
  var {
    auth,
    deployment,
    lobbyState
  } = props;

  const [octadeskChatError, setOctadeskChatError] = React.useState(false);

  let logoUrl = "";
  if(deployment.parameters){
    logoUrl = getDeploymentParamValue(deployment.parameters, "LOGO_URL");    
  }
  
  useEffect(() => {     
    let octadesk_enabled = false;
    let account = '';  
    if(deployment.parameters){      
      octadesk_enabled = getDeploymentParamValue(deployment.parameters, "OCTADESK_ENABLE");
      account = getDeploymentParamValue(deployment.parameters, "OCTADESK_DOMAIN");      
    }
    if(octadesk_enabled && octadesk_enabled === "1" && account !== '') {
      loadOctadeskChat(account, loginOctadekChat);
    }   
    return function cleanup(){
      let octaScript = document.getElementById('octadeskChat');
      let octaIframeContainer = document.getElementById('octadesk-octachat-appchat');
      if(octaScript){
        octaScript.remove();
      }
      if(octaIframeContainer){
        octaIframeContainer.remove();
      }      
    }
  }, []);

  const loadOctadeskChat = (account, callback) => {
    try {           

      const script = document.createElement('script');
  
      script.type = 'text/javascript';
      script.id = 'octadeskChat'
      script.src = `https://chat.octadesk.services/api/widget/${account}?showButton=true&hide=false&openOnMessage=true`;
      script.async = true;
      
      script.onload = () => {         
        if(callback) {
          setTimeout(function() {
            callback(true);
          }, 3000);         
        }
      }
    
      document.body.appendChild(script);      
    }
    catch (err) {        
        setOctadeskChatError(true);
        console.log(err.message);
    }
  }

  const loginOctadekChat = (firstAttempt) => {
    // eslint-disable-next-line no-undef
    var loggedIn = octadesk.chat.login({
      name: auth.name,
      email: auth.email
    });

    if(!loggedIn && firstAttempt) { 
      setTimeout(function() {
        loginOctadekChat(false);
      }, 5000);           
    }

    if(!loggedIn && !firstAttempt) {
      console.log("OCTADESK chat not logged in");
      // BUG - 23412 / se comenta esta linea para no mostrar el snackbar (error de visualizacion en los mobiles)
      //setOctadeskChatError(true);
    }
  }

  const handleCloseSnackBar = () => {
    setOctadeskChatError(false);    
  }

  return (
    <>
      <Dropdown.Menu

        className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">

        <div className="offcanvas-content pr-5 mr-n5 scroll" style={{ height: "643px", overflow: "auto" }}>
          <div id="kt_quick_user" style={{ marginTop: "40px", height: "40px" }} className="offcanvas offcanvas-right p-10 offcanvas-on kt-notification-item-padding-x">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
              <h3 className="font-weight-bold m-0">
                {deployment.name}
              </h3>
              <div className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                <i className="ki ki-close icon-xs text-muted">
                  {/*<CloseIcon/>*/}
                </i>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-5 kt-notification-item-padding-x">
            <div className="symbol symbol-100 mr-4">
              <div className="symbol-label">
                <img alt="Pic" src={logoUrl} />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                {auth.name}
              </div>
              <div className="text-muted mt-1">{auth.profileName}</div>
              <div className="navi mt-2">
                <div className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <EmailIcon />
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {auth.email}
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="navi mt-2" id="flags">
                  {auth.lang ?
                    <img style={{ alignContent: "left", height: '20px' }} src={toAbsoluteUrl("/media/flags/"+auth.lang+".svg")} alt="flag" /> : null
                  }
                </div>&nbsp;
                <div className="navi mt-2">
                  {auth.timezone}
                </div>
              </div>
            </div>

          </div>
          <br />
          <div className="d-flex flex-row kt-notification-item-padding-x" >
            <div className="kt-notification__custom">
              <Link
                id="btnSignOut"
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
                style={{
                  height: '30px',
                  backgroundColor: defaultStyles.accent_color
                }}
              >
                <CloseIcon />
                <FormattedMessage id="AUTH.LOGOUT" />
              </Link>
            </div>&nbsp;
                {!lobbyState &&

              <div className="kt-notification__custom">
                <PasswordPopUp height={"30px"} accentColor={defaultStyles.accent_color} />
              </div>
            }            
          </div>
          
          

        </div>
      </Dropdown.Menu>
      {octadeskChatError && 
        <Snackbar
        open={octadeskChatError}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        >
          <Alert severity='error'>          
              <FormattedMessage id="AUTH.POPUP.SNACK_OCTADESK_CHAT_ERROR" />          
          </Alert>
        </Snackbar>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    deployment: state.auth.deployment,
    lobbyState: state.presentationBar.lobbyState,
    organizer: (state.auth.role === 'organizer')
  }
};

export default injectIntl(
  connect(
    mapStateToProps,
    { ...authStorage.actions }
  )(UserProfileMenu)
);
