import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { LayoutSplashScreen } from "../../../_metronic";

const AuthDirectPresentationGrid = (props) => {
  let params = useParams();
  // prueba de redux con hook DEBUG
  const globalState = useSelector(state => state)

  useEffect(() => {
    const attemptLogin = async () => {
      try {
        let userId;
        let callCode = params.callcode;

        if(callCode.length === 7){ 
          callCode = callCode.slice(0, -1);
          userId = params.callcode.slice(-1);
        } else {
          userId = params.userid;
        }

        if(!globalState.auth.deploymentCode && userId && callCode){
          let token = props.token || null;
          const { data } = await login(userId, callCode, true, token);
          await props.login(data.accesstoken, callCode, data.deployment, data.role, data.userid, data.timezone, data.expires, data.lang, data.name, data.profileName, data.identification, token);
        }
      } catch (err) {
        console.log(err);
        let errorMessage = err.response && err.response.data && err.response.data.error ? err.response.data.error : 'Invalid login';
        window.location.href = '/auth/login/?err=' + errorMessage;
      }
    }

    attemptLogin();
  }, []);
  
  return(
    <>
    <LayoutSplashScreen visible={true}/>
    {
      globalState.auth.deployment && params.callcode.length === 7 &&
      <Redirect to="/p" />
    }

    {
      globalState.auth.deployment && params.callcode.length < 7 &&
      <Redirect to="/presentation" />
    }
    </>
  )
}


export default injectIntl(
  connect(
    null,
    auth.actions
  )(AuthDirectPresentationGrid)
);
