import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { toAbsoluteUrl } from "../../../_metronic";
import {version} from '../../../globalConfig';
import { metronic } from "../../../_metronic";
import {logoName} from '../../../globalConfig';
import {defaultStyles, appName} from '../../../globalConfig'

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
    backgroundColor: defaultStyles.accent_color,
    borderColor: defaultStyles.accent_color
  });
  
  let searchStr;
  //TODO: pasar a i18n
  let errMsg = "Código de visita/usuario inválido";
  if(props.location.search){
    searchStr = props.location.search.split('?');
    if(searchStr.length > 0){
      searchStr = searchStr[1].split('=')[0];
    }
  }

  useEffect(() => {
    if(window.OT.APIKEY != ""){
      window.OT.sessions.forEach(item => item.disconnect())
    }
  }, []);
  
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ 
      paddingRight: "3.5rem",
      backgroundColor: defaultStyles.accent_color,
      borderColor: defaultStyles.accent_color
    });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ 
      paddingRight: "2.5rem",
      backgroundColor: defaultStyles.accent_color,
      borderColor: defaultStyles.accent_color
    });
  };

  const urlLogo = toAbsoluteUrl(`/media/logos/${logoName}`);

  return (
    <>
      <div className="kt-login__head">
        <span className="kt-login__signup-label"></span>        
        <Link 
          id="btnLoginGoToRegistration" 
          to="/auth/registration" 
          className="kt-link kt-login__signup-link" 
          style={{color: defaultStyles.accent_color}}
        >
          <FormattedMessage id="LOGIN.PAGE.ACCESO.ADMINISTRATIVO" />
        </Link>
      </div>

        <div
          className="kt-login__headerForm--logo-centered"
          style={{
            backgroundImage: `url(${urlLogo})`
          }}
        >
        </div>
      <div className="kt-login__body">
        
        <div className="kt-login__form">
          <div className="kt-login__title" style={{marginBottom: "2rem"}}>
            <h4>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h4>
          </div>

          <Formik
            initialValues={{
              email: process.env.REACT_APP_EMAIL,
              callcode: process.env.REACT_APP_CALLCODE
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.callcode) {
                errors.callcode = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }else{
                if(values.callcode.length < 3){
                  errors.callcode = intl.formatMessage({
                    id: "AUTH.VALIDATION.MIN_LENGTH_FIELD"
                  }) + "3";
                }
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              setTimeout(() => {
                login(values.email, values.callcode)
                  .then(({data}) => {
                    disableLoading();
                    props.setLanguage(data.lang)
                    props.login(data.accesstoken, values.callcode, data.deployment, data.role, data.userid, data.timezone, data.expirations, data.lang, data.name, data.profileName, data.identification);
                  })
                  .catch((err) => {
                    searchStr = 'err';
                    setErrorLogin(true);
                    disableLoading();
                    setSubmitting(false);
                    if(
                      err.response && 
                      err.response.data && 
                      err.response.data != ""
                    ){
                      errMsg = err.response.data;
                    }
                    setStatus(errMsg);
                    //setStatus(
                      //intl.formatMessage({
                      //  id: "AUTH.VALIDATION.INVALID_LOGIN"
                      //})
                    //);
                  });
              }, 100);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
                style={{marginTop: "2rem"}}
              >
                {searchStr === 'err' || errorLogin ? (
                  <div role="alert" className="alert alert-danger">
                    <div>{(status != null && status != '') ? status : intl.formatMessage({
                        id: "LOGIN.PAGE.ERROR.CREDENTIALS"
                      })}</div>
                  </div>
                ) : (null)}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    autoFocus
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    margin="normal"
                    label="Codigo Visita"
                    className="kt-width-full"
                    name="callcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.callcode}
                    inputProps={{ minLength: 4, maxLength: 6, style: {textTransform: 'uppercase'}}}
                    helperText={touched.callcode && errors.callcode}
                    error={Boolean(touched.callcode && errors.callcode)}
                  />
                </div>

                <div className="kt-login__actions">
                  
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id="LOGIN.PAGE.LOGIN.BUTTON" />
                  </button>

                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="kt_login_version">{version}</div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    { ...auth.actions , ...metronic.i18n.actions }
  )(Login)
);
