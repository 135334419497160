import React, {useEffect} from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { Button } from '@material-ui/core';
import _, {uniqBy} from 'lodash';

const filterKey = createFilterOptions({
  stringify: option => option.key,
});
const filterValue = createFilterOptions({
  stringify: option => option.value,
});

/**
 * 
 * Este componente necesita para funcionar un metodo manager @setArrTagsGlobal para enviarle el array [{key:'', value:''}]
 * asi lo setea el componente padre en el reducer
 * recibe @tagsItems que es un array de [{key:'', value:''}]
 * 
 */
const TagsInput = ({setArrTagsGlobal, intl, tagsItems, tagOptions}) => {
  const [arrTags, setArrTags] = React.useState([]);
  const [counter, setCounter] = React.useState(0);
  const [keySelected, setKeySelected] = React.useState(); // aca van solo las keys
  const [remoteOptions, setRemoteOptions] = React.useState([]);
  let addTextResource = intl.formatMessage({
    id: "TAGS_INPUT.ADD_TEXT_OPTION"
  })
 
  /**
   * para funcionar las opciones del autocomplete este modulo debe recibir un array de objetos
   * en la variable tagOptions con la siguiente forma:
   *  [
        {
            "name": "xxx",
            "values": [
                "xx",
                "yy",
                "zz",
                "tt",
                "oo"
            ]
        },
        {
            "name": "mmm",
            "values": [
                "aa",
                "bb",
                "cc",
                "dd"
            ]
        },
        {
            "name": "uuu",
            "values": [
                "rr",
                "ss"
            ]
        }
      ]
   */
  useEffect(() => {
    if(arrTags.length <= 1){
      setArrTags(tagsItems);
    }
  }, [tagsItems])

  useEffect(() => {
    let arrKeyValue= [];
    if(tagOptions.length){
      tagOptions.forEach((group) => {
        if(group.values.length){
          group.values.forEach((value) => {
            if(group.name && value){
              arrKeyValue.push({key: group.name, value:value});
            }
          });
        }
      });
    }
    setRemoteOptions(arrKeyValue);
  }, [tagOptions]);

  const handleChangeKey = (index) => (event, newValue) => {
    //let tempArr = arrTags;
    let theKey = '';
    if(newValue){
      if (typeof newValue === 'string') {
        theKey = newValue;
      } else if (newValue && newValue.inputValue) {      
        theKey = newValue.inputValue;
      } else if(newValue && newValue.key) {
        theKey = newValue.key;
      }else{
        theKey = newValue
      }
    }
    if(theKey != ''){
      arrTags[index].key = theKey;
    }

    // a partir de aca corre la validacion
    let lastItem = arrTags[index];
    let duplicated = false;
    const invalidCharacters = invalidCharactersValidation(lastItem);
    arrTags.splice(index, 1);
    arrTags.forEach((item) => {
      if(item.key === lastItem.key && item.value === lastItem.value && !duplicated){
        //setear el error de duplicados
        duplicated = true;
        alert(intl.formatMessage({ id: "TAGS_INPUT.DUPLICATED" }));
      }
    });

    if(duplicated === true || invalidCharacters === true){
      setArrTags(arrTags);
      setArrTagsGlobal(arrTags);
    }else{
      arrTags.splice(index, 0, lastItem);
      setArrTags(arrTags);
      if(arrTags[index].key !== '' && arrTags[index].value !== ''){
        setArrTagsGlobal(arrTags);
      }
    }

    setCounter(counter + 1);
  }

  const handleChangeValue = (index) => (event, newValue) => {
    //let tempArr = arrTags;
    
    let theValue = '';
    if(newValue){
      if (typeof newValue === 'string') {
        theValue = newValue;
      } else if (newValue && newValue.inputValue) {
        theValue = newValue.inputValue;
      } else if(newValue && newValue.value) {
        theValue = newValue.value;
      }else{
        theValue = newValue;
      }
    }
    if(theValue != ''){
      arrTags[index].value = theValue;
    }

    // a partir de aca corre la validacion
    let lastItem = arrTags[index];
    let duplicated = false;
    const invalidCharacters = invalidCharactersValidation(lastItem);
    arrTags.splice(index, 1);
    arrTags.forEach((item) => {
      if(item.key === lastItem.key && item.value === lastItem.value && !duplicated){
        //setear el error de duplicados
        duplicated = true;
        alert(intl.formatMessage({ id: "TAGS_INPUT.DUPLICATED" }));
      }
    });

    if(duplicated === true || invalidCharacters === true){
      setArrTags(arrTags);
      setArrTagsGlobal(arrTags);
    }else{
      arrTags.splice(index, 0, lastItem);
      setArrTags(arrTags);
      if(arrTags[index].key !== '' && arrTags[index].value !== ''){
        setArrTagsGlobal(arrTags);
      }
    }

    setCounter(counter + 1);
  }

  const handleFilterKey = (options, params) => {
    const filtered = filterKey(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        key: `${addTextResource} "${params.inputValue}"`,
      });
    }
    for(let i = 0; i <= filtered.length; i++){
      if(filtered[i] && filtered[i].key === ''){
        filtered.splice(i, 1);
      }
    }

    return _.uniqBy(filtered, 'key');
  }

  const handleFilterValue = (index) => (options, params) => {
    let optionsValues = [];
    let localKeySelected = keySelected;
    if(arrTags[index] && arrTags[index].key){
      localKeySelected = arrTags[index].key;
    }
    options.forEach((option) => {
      if(option.key === localKeySelected){
        optionsValues.push(option);
      }
    });
    options = optionsValues;

    const filtered = filterValue(options, params);
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        value: `${addTextResource} "${params.inputValue}"`,
      });
    }
    for(let i = 0; i <= filtered.length; i++){
      if(filtered[i] && filtered[i].value === ''){
        filtered.splice(i, 1);
      }
    }
    return _.uniqBy(filtered, 'value');
  }

  const getOptionLabelHandlerKey = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    setCounter(counter + 1);
    return option.key;
  }

  const getOptionLabelHandlerValue = (index) => (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    setCounter(counter + 1);
    return option.value;
  }

  const invalidCharactersValidation = (item) => {
    if(
      item.key.includes(';') || 
      item.value.includes(';') ||
      item.key.includes(':') || 
      item.value.includes(':')
    ){
      alert(intl.formatMessage({ id: "TAGS_INPUT.INVALID_CHARACTERS" }));
      return true;
    }
    return false;
  }

  const addNewRow = () => {
    if(arrTags[arrTags.length-1] && arrTags[arrTags.length-1].key != '' && arrTags[arrTags.length-1].value != ''){
      arrTags.push({key:'', value:''});
    }
    if(arrTags.length == 0){
      arrTags.push({key:'', value:''});
    }
    setCounter(counter + 1);
  }

  const deleteTag = (index) => event => {
    if(arrTags[index]){
      arrTags.splice(index, 1);
      setArrTags([...arrTags]);
      setArrTagsGlobal([...arrTags]);
    }
    
  }

  return(
    <>
      <div>
      {arrTags.length && arrTags.map((tag, index) => {
        return(
          <>
          <div style={{display:'flex', marginTop:5, marginBottom:5}} key={index}>
            <Autocomplete
              value={arrTags[index].key}
              onChange={handleChangeKey(index)}
              filterOptions={handleFilterKey}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id={`free-solo-with-text-demo${index}`}
              options={[...arrTags, ...remoteOptions]}
              getOptionLabel={getOptionLabelHandlerKey}
              renderOption={(option) => option.key}
              style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            <Autocomplete
              value={arrTags[index].value}
              onChange={handleChangeValue(index)}
              filterOptions={handleFilterValue(index)}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id={`free-solo-with-text-demo${index}-2`}
              options={[...arrTags, ...remoteOptions]}
              getOptionLabel={getOptionLabelHandlerValue(index)}
              renderOption={(option) => option.value}
              style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            <Button onClick={deleteTag(index)}><FormattedMessage id="TAGS_INPUT.BUTTON_DELETE" /> </Button>
          </div>
          </>
        )
      })}
      <Button onClick={addNewRow}><FormattedMessage id="TAGS_INPUT.BUTTON_ACCEPT" /> </Button>
      </div>
    </>
  );
}

export default injectIntl(TagsInput)
