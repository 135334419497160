import axios from "axios";
import {configUrls} from '../../globalConfig';

export const FOLDERS_GET_URL = `${configUrls.base_url}/api/filefolder`;
export const CREATE_FOLDER = `${configUrls.base_url}/api/filefolder/create`; 
export const INACTIVATE_FOLDER = `${configUrls.base_url}/api/filefolder/action/inactivate/`;
export const UPDATE_FOLDER = `${configUrls.base_url}/api/filefolder/update/`;
export const FILE_GET_URL = `${configUrls.base_url}/api/file?`;
export const PROMOTIONAL_PLAN_URL = `${configUrls.base_url}/api/promotionalplan?`;
export const FILE_GET_TAGS_OPTIONS_URL = `${configUrls.base_url}/api/Filetag`; 

export function getFolders(){
  return axios.get(FOLDERS_GET_URL);
}

export function createFolder(payload){
  return axios.post(CREATE_FOLDER, {
    name: payload.name,
    cod_parent: payload.cod_parent
  });
}

export function inactivateFolder(code){
  return axios.put(INACTIVATE_FOLDER + code);
} 

export function updateFolder(payload){
  return axios.put(UPDATE_FOLDER + payload.code, {
    name: payload.name
  });
} 

export function getFiles(state, promplan){
  return axios.get(FILE_GET_URL + "state_id="+ state + "&prom_plan="+promplan);
}

export function getFileTagsOptions(){
  return axios.get(FILE_GET_TAGS_OPTIONS_URL);
}

export function getPromotionalPlan(state) {
  return axios.get(PROMOTIONAL_PLAN_URL + "state_id="+ state);
}

