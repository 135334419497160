import * as React from 'react';
import './Dropzone.scss'
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import * as fileStorage from "../../../modules/Files/file.duck";
import { DropzoneArea } from 'material-ui-dropzone';
import { injectIntl } from "react-intl";
import DropzoneButtons from './DropzoneButtons';
import CircularProgressWithLabel from '../../CircularProgressWithLabel/CircularProgressWithLabel';
import { useRef } from 'react';
import EditFilePopUp from './EditFilePopUp';
import useFileUpload from './hooks/useFileUpload';

const Dropzone = (props) => {
    const globalState = useSelector(state => state);
    const [fileSelected, setFileSelected] = useState({});
    const { intl } = props;
    const dropzoneRef = useRef(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const { fileCode, fileUrl, uploadProgress} = useFileUpload(props, fileSelected);
   
    useEffect(() => {
        if(globalState.file.fileUploaded) {
            setFileSelected({});
            deleteFileInDropzone();

            props.fillResultFileContainer({code: fileCode, url: fileUrl});
            setDisplayDialog(true); 
        }
    }, [globalState.file.fileUploaded])

    const handleFileChosen = (file) => {
        if(file){
          setFileSelected(file);
        }
    };

    const handleOnAlert = (msg, type) => {
        if(type == 'success'){
          showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.SUCCESS"}), 'green');
        }else if(type == 'error'){
          showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.ERROR.200"}), 'red');
        }
    };

    const deleteFileInDropzone = () => {
        if (dropzoneRef.current) {
            const file = dropzoneRef.current.state.fileObjects[0];
            dropzoneRef.current.deleteFile(file, 0);
        }
    };

    return (
        <>
            {displayDialog &&
                <EditFilePopUp displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} selected={globalState.file.fileContainerResponse}/>
            }
            <div className='container'>
                <div className='row'>
                    {uploadProgress > 0 && <CircularProgressWithLabel size={68} value={uploadProgress} />}
                    <DropzoneArea 
                        onChange={handleFileChosen} 
                        onAlert={handleOnAlert} 
                        ref={dropzoneRef}
                        acceptedFiles={[".jpg, .jpeg, .png, .mp4, .wmv, .avi, .webm, .mkv, .mpg , .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx"]} 
                        filesLimit={1} 
                        showAlerts={false} 
                        showFileNames={true} 
                        maxFileSize={210000000} 
                        dropzoneText={intl.formatMessage({id: "FILE.DROPZONE.TEXT"})}
                    />   
                </div>  
                <div className='row'>          
                    <div className='col-12 d-flex justify-content-center'> 
                        {Object.entries(fileSelected).length !== 0 &&
                            <DropzoneButtons 
                                fileSelected={fileSelected} 
                                setFileSelected={setFileSelected} 
                                deleteFileInDropzone={deleteFileInDropzone}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
}

export default injectIntl(
    connect(
      null,
      fileStorage.actions
    )(Dropzone)
);