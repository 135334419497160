import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import styles from './VideoPlayer.scss';

// Available events to log
// 'pause', 'play', 'ended', 'volumechange', 'timeupdate', 'seeking', 'seeked'
export default class VideoPlayer extends React.Component {

  constructor(){
    super();

    this.state = {
      player: null,
      timeoutDelay: 2000,
      timeUpdateTimeOut: null
    }
  }

  initialize = () => {
    let self = this;

    if(this.props.logdelay != null){
      this.state.timeoutDelay = this.props.logdelay;
    }
    
    // instantiate Video.js
    this.state.player = videojs(this.videoNode, this.props, 
      function onPlayerReady() 
      {
        if(self.state.player)
        {
          if(self.props.onlog)
          {
            self.props.onlog({
              type: 'timeupdate',
              currentTime: self.state.player.currentTime(),
              duration: self.state.player.duration(),
              volume: self.state.player.volume().toFixed(2),
              isMuted: self.state.player.muted(),
              height: self.state.player.currentHeight(),
              width: self.state.player.currentWidth()
            });
          }

          if(self.props.onVideoReady)
          {
            //TODO: pasarle info a este evento ej: id del video element
            self.props.onVideoReady(self.state.player);
          }
        }
      }
    );

    this.setListeners();    
  }

  setListeners = () => {
    if(this.props.events && this.props.events.length > 0){
      this.state.player.on(this.props.events, this.onEventListener);
    }
  }

  onDispose = () => {
    if(this.props.events && this.props.events.length > 0){
      this.state.player.off(this.props.events, this.onEventListener); 
    }

    if (this.state.player) {
      this.state.player.dispose();
      this.state.player = null;
    }

    if(this.state.timeUpdateTimeOut){
      clearTimeout(this.state.timeUpdateTimeOut);
      this.state.timeUpdateTimeOut = null;
      this.state.timeoutDelay = null;
    }

    if(this.props.onVideoDisposed)
    {
      this.props.onVideoDisposed();
    }
  }

  onEventListener = (ev) => {
    let self = this;

    //console.log(ev);
    if(ev.type === 'timeupdate'){
      // cuando esta terminando el video vuelve atras y pausa el video
      // esto es porque al terminar el evento se dispara el evento removetrack
      // y esto ahce que se rompa la transmision, en el rep se ve bien pero el
      // cliente y el guest no funciona
      if(self.state.player.currentTime() >= self.state.player.duration() - 0.2){
        self.state.player.currentTime(0);
        self.state.player.pause();
        //self.state.player.bigPlayButton.show();
      }
      if(!self.state.timeUpdateTimeOut)
      {
        self.state.timeUpdateTimeOut = setTimeout(() => {
          if(self && self.state){
            if(self.state.player && self.props.onlog){
              self.props.onlog({
                type: ev.type,
                currentTime: self.state.player.currentTime(),
                duration: self.state.player.duration(),
                volume: self.state.player.volume().toFixed(2),
                isMuted: self.state.player.muted(),
                height: self.state.player.currentHeight(),
                width: self.state.player.currentWidth()
              });
            }
  
            if(self.state.timeUpdateTimeOut){
              clearTimeout(self.state.timeUpdateTimeOut);
              self.state.timeUpdateTimeOut = null;
            }
          }
        }, this.state.timeoutDelay);
      }
    }else{
      let type = ev.type;
      if(ev.type == 'volumechange'){
        type = self.state.player.muted() ? 'mute' : 'unmute';
      }

      if(type == 'ended'){
        
        ev.stopImmediatePropagation();
        if(self.state.timeUpdateTimeOut){
          clearTimeout(self.state.timeUpdateTimeOut);
          self.state.timeUpdateTimeOut = null;
        }
      }

      if(type == 'play' && this.props.from == 'lobby') {
        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'INVITE_VIDEO',
          log_text: `video played | currentTime: ${self.state.player.currentTime()}`
        })
      }

      if(type == 'pause' && this.props.from == 'lobby') {
        this.props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'INVITE_VIDEO',
          log_text: `video paused | currentTime: ${self.state.player.currentTime()}`
        })
      }

      if(self.props.onlog){
        self.props.onlog({
          type: type,
          currentTime: self.state.player.currentTime(),
          duration: self.state.player.duration(),
          volume: self.state.player.volume().toFixed(2),
          isMuted: self.state.player.muted()
        });
      }
    }
  }
  
  componentDidMount() {
    this.initialize();
  }
  

  componentWillUnmount() {
    this.onDispose();
  }

  
  render() {
    let style = {width:'100%',height:'calc(100% - 48px)'};
    if(this.props.fullScreen === true){
      style = {width:'100%',height:'100%'}
    }
    /*no borrar el atribuo de crossorigin ya que sin el, no permite capturar el stream
      https://stackoverflow.com/questions/56970491/why-is-video-from-canvas-capturestream-empty-when-using-drawimage-with-mediareco
    */
    return (
      <div style={style}>        
        <div data-vjs-player>
            <video id="videoPresentationPlayer" crossOrigin="anonymous" ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered" playsInline={true}></video>
        </div>

        <div id="publisherVideo" className="hidden"></div>        
      </div>
    )
  }
}