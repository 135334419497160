import './Calendar.scss'
import React, { useState, useEffect, useRef } from 'react'
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl"
import FullCalendar from '@fullcalendar/react'
import allLocales from '@fullcalendar/core/locales-all'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import NewVisitPopUp from '../NewVisitPopUp/NewVisitPopUp';
import CallDetailsPopUp from '../CallDetailsPopUp/CallDetailsPopUp';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as authStorage from "../../store/ducks/auth.duck";
import * as dashboardStorage from "../../store/ducks/dashboard.duck";
import * as userStorage from "../../modules/Users/users.duck";
import { LinearProgress, Tooltip } from '@material-ui/core';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { getDeploymentParamValue } from '../../../_metronic/utils/utils';


const showToastImportantNotification = (msg, backgroundColor) => {
  let css = document.createElement("style");
  let style = document.createTextNode(`
    #toastImportantNotification {
      position:fixed; z-index: 9999999; 
      widht: 120px; 
      top:15%;
      right:1%;
      margin-left: -60px;
      padding: 20px; 
      background: ${backgroundColor};
      border-radius: 8px;
      font-size: 14px;
      font-family: sans-serif;
      color: #ffffff;
      text-align: center;
    }
    .toast-white{
      color: #ffffff;
    }
    .toast-white:hover{
      color: #ffffff;
    }
  `);
  css.appendChild(style);
  document.head.appendChild(css);
  let advisement = document.createElement("div");
  advisement.setAttribute("id", "toastImportantNotification");

  let content = document.createTextNode(msg);
  advisement.appendChild(content);
  advisement.appendChild(document.createElement('br'));
  document.body.appendChild(advisement);

  window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000); //eslint-disable-line
}

const selectIconCall = (status, callSesion, hasCustomerStarted) => {
  if (status === 2)
    return (hasCustomerStarted > 0) ? (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.CLOSED_WITH_CLIENT" />}><CheckCircleIcon style={{ fill: "green" }} fontSize="small" /></Tooltip>) : (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.CLOSED_WITHOUT_CLIENT" />}><CancelIcon style={{ fill: "red" }} fontSize="small" /></Tooltip>) //caso de que la llamada este cerrada
  else if (status === 1 && callSesion > 0)
    return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.INITED" />}><SlowMotionVideoIcon style={{ fill: "orange" }} fontSize="small" /></Tooltip>) //caso de que la llamada este iniciada
  else if (status === 1 && callSesion === 0)
    return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.NOTINITED" />}><AccountBoxIcon fontSize="small" /></Tooltip>) //caso de que la llamada este sin iniciar
  return (<Tooltip disableFocusListener title={<FormattedMessage id="CALL.STATUS.NOTINITED" />}><AccountBoxIcon fontSize="small" /></Tooltip>) //caso default
}

const Calendar = (props) => {
    const [events, setEvents] = useState([])
    const [selectedFrom, setSelectedFrom] = useState(new Date());
    const [selectedTo, setSelectedTo] = useState(new Date());
    const [itemToView, setItemToView] = useState()
    const [time, setTime] = useState({ minTime: '00:00:00', maxTime: '24:00:00'})
    
    const globalState = useSelector(state => state);
    const { intl } = props;
    const calendarRef = useRef()
    const detailRef = useRef()

    useEffect(() => {
        props.getCallsByDate({ selectedFrom, selectedTo });
    }, [selectedFrom, selectedTo]) //eslint-disable-line

    useEffect(() => {
      let events = [];
      for (let byDay in globalState.presentationBar.calls) {
        globalState.presentationBar.calls[byDay].forEach((item, index) => {
        events.push(
                  {
                      id: item.code, 
                      title: item.subscriberName, 
                      start: moment(item.duedateUTC).add(globalState.auth.timezone, 'hours').format(),
                      guestId: item.guestId,
                      status: item.status,
                      last_CallSession: item.last_CallSession,
                      hasCustomerStarted: item.hasCustomerStarted

                  }
              )
          });
      }

      setEvents(events)
    }, [globalState.presentationBar.calls, globalState.auth.timezone])
 

    useEffect(() => {
      let minTime = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_CREATE_MIN_TIME')
      let maxTime = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_CREATE_MAX_TIME')

      if(!minTime){
        minTime = '800';
      }
      if(!maxTime){
        maxTime = '2000';
      }

      const formatTimeToCalendar = (time) => {
        let formatedLengthTime = time
  
        if(time.length === 3){
          formatedLengthTime = '0' + time
        }
  
        const splittedTime = formatedLengthTime.match(/.{1,2}/g).concat('00')
        const formattedTime = splittedTime.join(':')
       
        return formattedTime
      }

      setTime({ minTime: formatTimeToCalendar(minTime), maxTime: formatTimeToCalendar(maxTime) })
    }, [globalState.auth.deployment.parameters])

    useEffect(() => {
        let msg = ''
        let backgroundColor = 'green';
        if (globalState.schedule.requestStateModify != null) {
          if (globalState.schedule.requestStateModify === true) {
            msg = intl.formatMessage({
              id: "SCHEDULE.CALL.MODIFY.OK"
            });
    
          } else {
            msg = intl.formatMessage({
              id: "SCHEDULE.CALL.MODIFY.ERROR"
            });
            backgroundColor = 'red';
          }
          if (msg !== ''){
            showToastImportantNotification(msg, backgroundColor);
          }
          props.setRequestStateModify({state: null, callcode: null});
          props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo})
      }
    }, [globalState.schedule.requestStateModify]) //eslint-disable-line

    const openModal = (info) => {
      calendarRef.current.handleOpen(info.start, info.view.type)
    }

    const setMonthsView = (args) => {
        setSelectedFrom(args.start)
        setSelectedTo(args.end)
    }

    const handleEventClick = (info) => {
      let itemToView = {}

      for (let byDay in globalState.presentationBar.calls) {
        for(let i in globalState.presentationBar.calls[byDay]) {
            const item = globalState.presentationBar.calls[byDay][i]
            if(item.code === info.event.id) {
              itemToView = item
              break
            }
        }
      }
 
      setItemToView(itemToView)
      detailRef.current.handleOpen()
    }


    const handleDrop = ({ event }) => {
      props.modifyDateOfCall({dueDateUTC: event.start, callcode: event.id, guestid: event.extendedProps.guestId ? event.extendedProps.guestId : ""});
    };

    const renderEventContent = ({ event, timeText }) => {
      return (
        <div className='fc-event-title'>
          {
            selectIconCall(event.extendedProps.status, event.extendedProps.last_CallSession, event.extendedProps.hasCustomerStarted)
          }
          <b className='fc-hour'>{timeText}</b>
          <i className='fc-title'>{event.title}</i>
        </div>
      )
    }

    const handleSubmit = (date, selectedObject) => event => {
        if (globalState.users.userSelected && globalState.users.userSelected.uuid && globalState.users.userSelected.uuid === globalState.auth.userid) {
          showToastImportantNotification(intl.formatMessage({id: "CALL.DETAILS.INVALID.SELECTION"}),'red');
        } else {
          if (selectedObject === {}) {
            Object.assign(globalState.presentationBar.callNew, { date: new Date(), open: true, error: true });
            props.updateNewCall(globalState.presentationBar.callNew);
            Object.assign(globalState.presentationBar.calls, globalState.presentationBar.calls);
          } else {
            date = moment(date).add(-1 * globalState.auth.timezone, 'hours').format("YYYY-MM-DDTHH:mm");
            if(globalState.users.userSelected){
              props.createCall({ ...selectedObject, date, selectedFrom: selectedFrom, selectedTo: selectedTo, userId: globalState.auth.userid, guestId: globalState.users.userSelected.uuid });
              props.updateUserSelected(null);
            }else{
              props.createCall({ ...selectedObject, date, selectedFrom: selectedFrom, selectedTo: selectedTo, userId: globalState.auth.userid });
            }
            props.analyticsLogEvent('Call Created');
            Object.assign(globalState.presentationBar.callNew, { date: new Date(), open: false, error: false });
            Object.assign(globalState.presentationBar.customerSelected, {});
            props.updateCustomerSelected(globalState.presentationBar.customerSelected)
          }
        }
        props.getCallsByDate({ selectedFrom, selectedTo });
    }

    return (
        <div className="col-xl-12 d-flex justify-content-center">
            <div className="col-s-12 col-md-12 col-lg-8 col-xl-8 d-flex flex-column justify-content-center item-align-center" style={{ padding: '2rem', backgroundColor: 'white', borderRadius: '10px'}}>
                { (globalState.presentationBar.loading || globalState.dashboard.loading) && <LinearProgress className="progressBar" style={{ top: -5 }}/> }
                { (globalState.presentationBar.loading) && <div className="progressBackground" style={{ zIndex: 9999, width: '95%'}}></div> }
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    allDaySlot={false}
                    locales={allLocales}
                    locale={globalState.i18n.lang}
                    slotMinTime={time.minTime}
                    slotMaxTime={time.maxTime}
                    initialView='timeGridWeek'
                    eventDurationEditable={false}                     
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    events={events}
                    select={openModal}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    datesSet={setMonthsView} 
                    eventDrop={handleDrop}
                    aspectRatio={window.screen.width < 800 ? 0.8 : 2}
                    fixedMirrorParent={document.body}
                    contentHeight={'auto'}
                />   
                <NewVisitPopUp handleSubmit={handleSubmit} displayButton={false} innerRef={calendarRef} />
                { itemToView && <CallDetailsPopUp intl={intl} showButton={false} item={itemToView} innerRef={detailRef} /> }       
            </div>             
        </div>
    )
}

export default injectIntl(
    connect(
      null,
      {
        ...presentationBarStorage.actions,
        ...authStorage.actions,
        ...scheduleStorage.actions,
        ...dashboardStorage.actions,
        ...userStorage.actions
      }
    )(Calendar)
  );