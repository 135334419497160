/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useImperativeHandle } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import LinkCopyClientButton from '../LinkCopyClientButton';
import SendEmailButton from '../SendEmailButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CallIcon from '@material-ui/icons/Call';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScheduleModifierPopUp from '../ScheduleModifierPopUp';
import UserSelector from '../Guest/UserSelector';
import { isMobile } from 'react-device-detect';
import WhatsAppButton from  '../WhatsAppButton';
import CallActionEditMenu from '../CallActionEditMenu';
import CallActionShareMenu from '../CallActionShareMenu';
import {defaultStyles} from '../../../globalConfig';
import * as userStorage from "../../modules/Users/users.duck";
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as authStorage from "../../store/ducks/auth.duck";
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import TextField from '@material-ui/core/TextField';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import Rating from '@material-ui/lab/Rating';
import PreviewListPopUp from './PreviewListPopUp';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import ConversationContainer from "../Agenda/ConversationContainer";
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    marginTop: '20%',
    width: 400,
    height: 310
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataButton: {
    width: '50%',
    paddingLeft: 10,
    transition: 'opacity .2s',
    '&:hover':{
      opacity:0.5
    }
  },
  analysisButton: {

  },
  dataFullWidth: {
    width: '70%'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  MuiRatingSizeLarge:{
    fontSize: '2rem'
  }
}));

const CallDetailsPopUp = (props) => {
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const { intl } = props;
  const globalState = useSelector(state => state);

  const [lastCallDate, setLastCallDate] = React.useState(null);
  const [lastNote, setLastNote] = React.useState(null);
  const feedbackEnabled = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_FEEDBACK_ENABLE");
  const [openPopUpPreviewList, setOpenPopUpPreviewList] = React.useState(false);
  const [openConversation, setOpenConversation] = React.useState(false);

  useEffect(() => {
    if(props.fromNotification && 
      props.fromNotification === true){
        handleOpen();
      //setOpen(true)
    }
  }, [props.fromNotification]);

  useEffect(() => {
    if(globalState.presentationBar.callHistoryRequested && props.item.code == globalState.presentationBar.callItemSelected && globalState.presentationBar.callHistoryList && 
      globalState.presentationBar.callHistoryList.length > 0){
      let firstItem = globalState.presentationBar.callHistoryList[0];
      if(!lastCallDate && firstItem.duedateUTC){
        setLastCallDate(firstItem.duedateUTC);
      }
      let properties = firstItem.properties;
      if(properties && properties.length > 0){
        for (let index = 0; index < properties.length; index++) {
          const itemProp = properties[index];
          if(!lastNote && itemProp.key == "NOTES"){
            setLastNote(itemProp.value);
          }
        }
      }
    }
  }, [globalState.presentationBar.callHistoryList]);

  useEffect(() => {
    if(globalState.presentationBar.callHistoryRequested && props.item.code == globalState.presentationBar.callItemSelected){
      setOpen(true);
    }
  }, [globalState.presentationBar.callHistoryRequested]);

  useEffect(() => {
    if(globalState.schedule.callcode && globalState.schedule.callcode === props.item.code){
      let msg = ''
      let backgroundColor = 'green';
      if (globalState.schedule.requestStateUpdate != null) {
        if (globalState.schedule.requestStateUpdate === true) {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.MODIFY.OK"
          });
  
        } else {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.MODIFY.ERROR"
          });
          backgroundColor = 'red';
        }
        if (msg != ''){
          showPopupModifyCall(msg, backgroundColor);
        }
        props.setRequestStateUpdate({state: null, callcode: null});
        props.getUsers({allow_guest: 1});
        props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo})
        handleClose();
      }
    }
  }, [globalState.schedule.requestStateUpdate])

  const showPopupModifyCall = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #avisoModifyCall {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "avisoModifyCall");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);
    
    window.load = setTimeout("if(typeof(avisoModifyCall) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(avisoModifyCall)){for (var i = 0; i < avisoModifyCall.length; i++) {document.body.removeChild(avisoModifyCall[i])}}else{document.body.removeChild(avisoModifyCall)}}", 5000);
  }

  const handleOpen = () => {
    const callHistoryEnable = getDeploymentParamValue(globalState.auth.deployment.parameters, "AGENDA_CALL_HISTORY_ENABLE");
    if(feedbackEnabled){
      props.getCallFeedback(props.item.code)
    }
    if(callHistoryEnable && callHistoryEnable == '1'){
      props.getCallHistory({customerId: props.item.subscriberId, itemSelected: props.item.code});
    }else{
      setOpen(true);
    }
  };

  useImperativeHandle(props.innerRef, () => {
    return {
      handleOpen
    }
  })

  const handleClose = () => {
    if(globalState.users.deleteGuest){
      props.setDeleteGuest(false);
    }
    if (globalState.users.userSelected){
      props.updateUserSelected(null);
    }
    if(globalState.presentationBar.callHistoryRequested){
      props.setCallHistoryRequested(false);
    }
    props.resetCallFeedback();
    setOpen(false);
  };

  const handleSave = (item)  => event => {
    props.setLoading(true);
    if (globalState.users.userSelected && globalState.users.userSelected.uuid && globalState.users.userSelected.uuid == globalState.auth.userid) {
      showPopupModifyCall(intl.formatMessage({id: "CALL.DETAILS.INVALID.SELECTION"}),'red');
      props.setLoading(false);
    } else {
      if(globalState.users.userSelected){
        props.updateCall({state_Id: item.state_Id, duedateUTC: item.duedateUTC, callcode: item.code, guestid: globalState.users.userSelected.uuid});
      }else{
        props.updateCall({state_Id: item.state_Id, duedateUTC: item.duedateUTC, callcode: item.code, guestid: ''});

      }
    }
    handleClose();
  };

  const classes = useStyles();

  const handleEnterClick = (item) => event => {

    if(callGuestQty && callGuestQty == '1' && item.organizerId != globalState.auth.userid && item.guestId == globalState.auth.userid){
      //GET TOKEN
      props.getUrlToken({codeCall: item.code, userId: globalState.auth.userid});
    }else{
      window.location.href = `/call/${item.code}/${globalState.auth.userid}`;
    }
  }

  const truncate = (str) => {
      return str.length > 25 ? str.substring(0, 22) + "..." : str;
  }

  const callGuestQty = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_GUEST_QTY");
  const callHistoryEnable = getDeploymentParamValue(globalState.auth.deployment.parameters, "AGENDA_CALL_HISTORY_ENABLE");
  const feedbackQuestion = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_FEEDBACK_QUESTION");

  const handleFileClick = (item) => event => {
    //props.setFileObjectInStore(props.item);
    props.setArchivesPopUpOpen(true);
    props.setCallSelected(item);
    setOpen(false);
  }

  const handleOpenConversation = (item) => event => {
    setOpenConversation(true);
  }

  const handlePreviewVaid = () => {
    setOpen(false);
    setOpenPopUpPreviewList(true);
  }

  const closePreviewListPopUp = () => {
    setOpenPopUpPreviewList(false);
  }

  const closeConversationPopUp = () => {
    setOpenConversation(false);
  }

  return (
    <div style={{ "display": "inline-block" }}>
      { (!props.fromNotification && props.showButton) &&
      <Button className="btn btn-sm btn-label-dark" id="btnCallDetailsOpen" onClick={handleOpen}>
        <VisibilityIcon/>
        <span>&nbsp;</span>
        <FormattedMessage id="CALL.DETAILS.POPUP.SEE" />
      </Button>
      }
      {openPopUpPreviewList &&
      <PreviewListPopUp 
        open={openPopUpPreviewList} 
        closePreviewListPopUp={closePreviewListPopUp} 
        item={props.item} 
      />
      
      }
      {openConversation &&
        <ConversationContainer 
          open={openConversation} 
          closeConversationContainer={closeConversationPopUp} 
          item={props.item} 
        />
      }

      <Dialog
        open={open || Boolean(!globalState.presentationBar.callNew.open && globalState.users.userSelected && globalState.users.userSelected.itemCode && globalState.users.userSelected.itemCode == props.item.code)}
        onClose={handleClose}
        id="popup"
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="CALL.DETAILS.SCHEDULE" />
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="CALL.DETAILS.CODE" />: </span><span className={classes.data}>{props.item.code}</span>
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="CALL.DETAILS.DATA" />: </span><span className={classes.data}>{moment(props.item.duedateUTC).add(globalState.auth.timezone, 'hours').format('YYYY-MM-DD HH:mm')}hs</span>
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="CALL.DETAILS.CLIENT" />: </span><span className={classes.data}>{props.item.subscriberName}</span>
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}>Email: </span><span className={classes.data}>{truncate(props.item.subscriberEmail)}</span>
            </div>
            {(callHistoryEnable && callHistoryEnable == '1') ?
              <hr/>
            : null}
            {(callHistoryEnable && callHistoryEnable == '1') && lastCallDate ?
              <div className={classes.inRow}>
                <span className={classes.label}><FormattedMessage id="CALL.DETAILS.LAST.CALLDATE" />: </span><span className={classes.data}>{moment(lastCallDate).add(globalState.auth.timezone, 'hours').format('YYYY-MM-DD HH:mm')}hs</span>
              </div>: null
            }
            {(callGuestQty && callGuestQty == '1') && props.item.guestId && props.item.guestId !='' ?
              <div className={classes.inRow}>
                <span className={classes.label}><FormattedMessage id="CALL.DETAILS.ORGANIZER" />: </span><span className={classes.data}>{props.item.organizerName}</span>
              </div>: null}
            {(callGuestQty && callGuestQty == '1' && props.item.type != 2) &&  props.item.status == '1' && globalState.auth.userid != props.item.guestId ?
              <div className={classes.inRow}>
                {props.item.last_CallSession == '0' ?
                <UserSelector intl={props.intl} item={props.item}/>
                : <UserSelector intl={props.intl} item={props.item} readonly={true}/>}
              </div> :  null}
            
            { props.item.archiveCount > 0 &&
              <><div className={classes.inRow}>
                <span className={classes.label}><FormattedMessage id="CALL.DETAILS.ARCHIVES" />: </span><Button className={classes.dataButton} onClick={handleFileClick(props.item)} style={{ cursor: 'pointer', backgroundColor: defaultStyles.accent_color, color: '#fff' }}><OndemandVideoIcon style={{ marginRight: 3 }} /> <FormattedMessage id="CALL.DETAILS.ARCHIVES_SEE_VIDEOS" /> ({props.item.archiveCount}) </Button>
                

              </div>
              
            </>
            }
            {props.item.status != 2 && props.item.type != 2 ?
              <div className={classes.mtop20}>
                <Button size="small" style = {{ marginLeft:'10%',marginRight:'10%', marginTop: '1%', backgroundColor: defaultStyles.accent_color }} className="btn btn-sm btn-label-brand" id="btnAgendaEnterCall" onClick={handleEnterClick(props.item)}>
                  <CallIcon />
                  <span>&nbsp;</span>
                  <FormattedMessage id="CALL.DETAILS.BUTTON.SIGN_IN" />
                </Button>
                <br />
                {props.item.organizerId == globalState.auth.userid ?
                  <div style={{display: 'flex', justifyContent:'start', alignContent:'center', marginLeft: 24}}>
                  <CallActionShareMenu popup={true} item={props.item} />
                  <CallActionEditMenu item={props.item} />
                  </div>
                :null}
              </div> 
              :
              null
            }
            {(props.item.status === 2 && feedbackEnabled === "1") &&
            <>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="CALL.DETAILS.FEEDBACK_STARS" /> </span>
              <span className={classes.data}>
              <Rating
                name="simple-controlled"
                value={parseInt(globalState.presentationBar.feedback.CUSTOMER_FEEDBACK, 10)}
                size="large"
                className={classes.MuiRatingSizeLarge}
                readOnly
              />
              </span>
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}>{feedbackQuestion}: </span>
              <span 
                className={classes.data} 
                >
                  <TextField
                    id="standard-multiline-flexible"
                    multiline
                    rowsMax={6}
                    fullWidth
                    value={globalState.presentationBar.feedback.CUSTOMER_COMMENT}
                    disabled={true}
                    style={{color: 'black'}}
                  />
              </span>
            </div>
            {(callHistoryEnable && callHistoryEnable == '1') && lastNote ?
              <div className={classes.inRow}>
                {/*<span className={classes.label}><FormattedMessage id="CALL.DETAILS.LAST.NOTE" />: </span>*/}
                  <span className={classes.dataFullWidth}>
                  <TextField
                    id="standard-multiline-flexible"
                    multiline
                    rowsMax={4}
                    fullWidth
                    value={lastNote}
                    disabled={true}
                  />
                 </span>
              </div>: null
            }
            {globalState.presentationBar.feedback && globalState.presentationBar.feedback.SYMBL_STATUS == "completed" && globalState.presentationBar.feedback.SYMBL_INSIGHTS_URL!='' ?
                <div className={classes.inRow}>
    
                <button id="btnConversation" style={{ border: 'none', padding: '10px',textAlign: 'center', textDecoration: 'none',display: 'inline-block',fontSize: '12px',margin: '4px 2px',cursor: 'pointer',borderRadius: '12px', backgroundColor: defaultStyles.accent_color, color: '#fff' }}  className={"fas fa-brain"} onClick={handleOpenConversation(props.item)}>
                <span className="kt-font-regular" style={{fontFamily: 'sans-serif'}}>&nbsp;{intl.formatMessage({id:"CALL.DETAILS.ANALYSIS"}).toUpperCase()}</span> 
                </button>
            </div>:null}
            </>
            }
          </div>
        </DialogContent>
        <DialogActions>
          {props.item.status != 2 &&
          <Button className={classes.button} id="btnCallPrecviewVa" onClick={handlePreviewVaid} style={{textTransform:'none'}}>
            <FormattedMessage id="PREVIEW_LIST.PREVIEW.BUTTON" />
          </Button>
          }
          <Button className={classes.button} id="btnCallDetailsClose" onClick={handleClose}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
          {(callGuestQty && 
            callGuestQty == '1' && 
            (
              (
                globalState.users.userSelected && 
                globalState.users.userSelected.itemCode && 
                globalState.users.userSelected.itemCode == props.item.code
              ) || globalState.users.deleteGuest
            ) &&
            props.item.status != 2
           ) ?
          <Button className={classes.button} id="btnCallDetailsSave" onClick={handleSave(props.item)}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.SAVE" />
          </Button>: null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...userStorage.actions,...authStorage.actions,...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(CallDetailsPopUp)
);