import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomerGroupsFilter from '../../components/CustomerGroups/CustomerGroupsFilter';
import CustomerGroups from "../../components/CustomerGroups";
import CreateCustomerGroupsButton from '../../components/CustomerGroups/CreateCustomerGroupsButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import './CustomerGroupsPage.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as customerGroupsStorage from "../../store/ducks/customerGroups.duck";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomerGroupsPage = (props) => {
  const globalState = useSelector(state => state);

  const handleCloseSnackBar = () => {
    props.createCustomerGroupResult(null);
  }

  return (
    <div id="user" style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.customerGroups.loading && <LinearProgress className="progressBar" />}
      {globalState.customerGroups.loading && <div className="progressBackground"></div>}
      {globalState.customerGroups.createCustomerGroupResult != null &&
      <Snackbar 
        open={(globalState.customerGroups.createCustomerGroupResult !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.customerGroups.createCustomerGroupResult) ? 'success' : 'error'}>
          {globalState.customerGroups.createCustomerGroupResult ? 
            <FormattedMessage id="CUSTOMERGROUPS.CREATE.SUCCESS" />
            :
            <FormattedMessage id="CUSTOMERGROUPS.CREATE.ERROR" />
          }
        </Alert>
      </Snackbar>
      }
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="CUSTOMERGROUPS.TITLE" /></h3>
        </div>
        <div>
          <CreateCustomerGroupsButton />
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex">
            <div>
              <CustomerGroupsFilter />
            </div>
          </div>
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <CustomerGroups/>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    customerGroupsStorage.actions
  )(CustomerGroupsPage)
);