import React, { useEffect, useState } from 'react'
import { connect, useSelector } from "react-redux";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { injectIntl } from "react-intl";

const SlideItem = (props) => {
    const {borderColor, backgroundColor, setCounter, playSpecificSlide, counter, vaLogoUrl} = props;
    const globalState = useSelector(state => state);
    const [isOptimized, setIsOptimized] = useState(false);
    const [slideArr, setSlideArr] = useState(false);
    const [indexOfSelectedSlide, setIndexOfSelectedSlide] = useState();

    useEffect(() => {      
        if(globalState.presentationBar.presentationFlows.currentFlow && Object.keys(globalState.presentationBar.presentationFlows.currentFlow).length > 0) {
            setSlideArr(globalState.presentationBar.presentationFlows.currentFlow.slides);
        } else {
            setSlideArr(globalState.presentationBar.presentationSlides.content);
        }
    }, [globalState.presentationBar.presentationSlides.content, globalState.presentationBar.presentationFlows.currentFlow])

    useEffect(() => {
        if(globalState.presentationBar.presentationSlides.isOptimized == 1) {
            setIsOptimized(true);
        }
    }, [globalState.presentationBar.presentationSlides.isOptimized])

    useEffect(() => {
        setIndexOfSelectedSlide(globalState.presentationBar.presentationSlides.indexOfSelectedSlide)
    }, [globalState.presentationBar.presentationSlides.indexOfSelectedSlide])

    const selectSlide = (slideCode) => {
        if(Object.keys(globalState.presentationBar.presentationFlows.currentFlow).length > 0) {
            globalState.presentationBar.presentationFlows.currentFlow.slides.forEach((s, index) => {
                if(s.code == slideCode){
                    props.updateSelectedIndexSlide(index);
                }
            })
        } else {
            globalState.presentationBar.presentationSlides.content.forEach((s, index) => {
                if(s.code == slideCode){
                    props.updateSelectedIndexSlide(index);
                }
            })
        }

        props.setSelectedSlide(slideCode);
    }

    return (
        <>
            {   
                slideArr && slideArr.length > 0 &&
                slideArr.map((slide, index) => (
                    <div style={{ borderColor: borderColor }} className={`presentation__item ${index == indexOfSelectedSlide ? 'selected' : ''}`} key={index} id="btnSlideListSelectSlide" onClick={() => { selectSlide(slide.code)}} onDoubleClick={() => playSpecificSlide()} >
                        <div style={slide.active ? { backgroundColor: backgroundColor } : null} className={`presentation__item__number-slide ${slide.active}`}>{index + 1}</div>
                        <div className="presentation__item__info">
                            <img srcSet={ isOptimized ? slide.srcSetValues : null} src={slide.url} onError={() => { slide.url = vaLogoUrl; setCounter(counter + 1) } } sizes={isOptimized ? "(min-width: 40px) 319px" : null} alt="" />
                        </div>
                    </div>
                ))
            }
            <i aria-hidden="true"></i>
            <i aria-hidden="true"></i>
            <i aria-hidden="true"></i>
        </>
    )
}

export default injectIntl(
    connect(
        null,
        presentationBarStorage.actions
    )(SlideItem)
);