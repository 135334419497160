import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { configUrls } from '../../../globalConfig';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Button from '@material-ui/core/Button';
import KTUtil from "../../../_metronic/_assets/js/util";
import { connect, useSelector } from "react-redux";
import {
  getDeploymentParamValue
} from '../VideoChat/utils';
import moment from 'moment';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import {
  isAndroid,
  isIPad13
} from 'react-device-detect';

const WhatsAppButton = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);

  let className = "btn btn-sm btn-label-success";
  let style = { marginLeft: 1 };
  if (props.popup) {
    style = { width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: '1%' }
  }

  let dateTimezone = moment(props.item.duedateUTC).add(globalState.auth.timezone, 'hours');
  //default
  let dayMonth = moment(dateTimezone).format('D/M');
  let formatDateShort = getDeploymentParamValue(globalState.auth.deployment.parameters, 'DATE_SHORT');
  if(formatDateShort){
    dayMonth = moment(dateTimezone).format(formatDateShort);
  }
  let hours = dateTimezone.format('HH'); //get hours
  let minutes = dateTimezone.format('mm'); //get minutes
  let repName = props.item.organizerName;
  let depName = globalState.auth.deployment.name;
  let callUrl = `${configUrls.web_url}/call/${props.item.code}/${props.item.subscriberId}`;
  if(props.item.type == 2){
    let shortUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_PRESENTATION_SHORT_URL") === "1";
    if(shortUrl){
      callUrl = `${configUrls.web_url}/p/${props.item.code}C`;
    } else {
      callUrl = `${configUrls.web_url}/presentation/${props.item.code}/${props.item.subscriberId}`;
    }
  }
  let callCode = props.item.code;
  let name = props.item.subscriberName;

  let msg = intl.formatMessage({ id: "WHATSAPP.MESSAGE" }, {
    date: dayMonth,
    hour: hours + ":" + minutes,
    repname: repName,
    depname: depName,
    callurl: callUrl,
    callcode: callCode,
    name: name
  });

  let whatsAppMessageParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'WHATSAPP.MESSAGE');
  if(props.item.type == 2){
    whatsAppMessageParam = getDeploymentParamValue(globalState.auth.deployment.parameters, 'WHATSAPP.MESSAGE_PRESENTATION');
  }
  
  if(whatsAppMessageParam && whatsAppMessageParam != ''){
    whatsAppMessageParam = whatsAppMessageParam.replace('{date}' ,dayMonth);
    whatsAppMessageParam = whatsAppMessageParam.replace('{hour}', hours + ":" + minutes);
    whatsAppMessageParam = whatsAppMessageParam.replace('{repname}' ,repName);
    whatsAppMessageParam = whatsAppMessageParam.replace('{depname}' ,depName);
    whatsAppMessageParam = whatsAppMessageParam.replace('{callurl}' ,callUrl);
    whatsAppMessageParam = whatsAppMessageParam.replace('{callcode}' ,callCode);
    whatsAppMessageParam = whatsAppMessageParam.replace('{name}' ,name);
    msg = whatsAppMessageParam;
  }


  //MANTIS 32050: no funciona el link de compartir de whatsapp
  //let whatsappLink = 'https://wa.me/?text=' + msg;  
  //este es el link universal, pero cuando es web pide 2 clicks mas al user
  
  let whatsappLink = 'https://web.whatsapp.com/send?text=' + msg;
  if (lowerThanTablet || isAndroid) //en el ipad14 la variable isIphone13 da true, entonces solo se puede preguntar por el tamaño de pantalla
  {
    whatsappLink = 'whatsapp://send?text=' + msg;
  }
  //TABLET Condition
  /*else if(KTUtil.isInResponsiveRange("tablet") || isTablet || isIPad13){
    whatsappLink = 'https://wa.me/?text=' + msg;
  }*/

  return (
    <div style={{ "display": "inline" }}>
    {props.popup ?
    <Button size="small" style={style} className="btn btn-sm btn-label-warning" id="btnWhatsApp" href={whatsappLink}>
      <WhatsAppIcon />
      <span>&nbsp;</span>
      WhatsApp
    </Button>
    :
    <div id="btnWhatsApp" >
      <span>&nbsp;</span>
      <a target="_blank" href={whatsappLink}>WhatsApp</a>
    </div>
    }
    </div>
  )
}

export default injectIntl(WhatsAppButton);