import React from 'react';
import { connect, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage, injectIntl } from "react-intl";
import { isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import CRMsPopUp from '../CRMsPopup';
import { getDeploymentParamValue } from './utils';

// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";


const CancelCall = ({
    open,
    callStatus,
    endCall,
    signOutClick,
    callcode,
    notesIsEnabled,
    endCallWithoutRedirect,
    callClosedResult,
    deployment
}) => {

  const globalState = useSelector(state => state);
  const [valueText, setValueText] = useState('');
  const [callbackQty, setCallbackQty] = useState(false);
  const [crmPopUpOpen, setCrmPopUpOpen] = useState(false);
  const [closeCallAccepted, setCloseCallAccepted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isWithoutRedirect, setIsWithoutRedirect] = useState(false);

  useEffect(() => {
    let callbackQtyArr;
    callbackQtyArr = globalState.auth.deployment.parameters.filter((param) => {
      return param.key === "CALL_CLOSE_CALLBACK_QTY"
    })
    if(callbackQtyArr.length > 0){ 
      setCallbackQty(parseInt(callbackQtyArr[0].value, 10))
    }
  }, [])

  useEffect(() => {
    if(globalState.auth.role === 'organizer' && globalState.presentationBar.callClosedResult){
      setCrmPopUpOpen(true);
      setCloseCallAccepted(true);
    }
    
  }, [globalState.presentationBar.callClosedResult])
  
  try{
    let textStorage = localStorage.getItem("callnotes_" + callcode);
    if(textStorage && valueText !== textStorage){
      setValueText(textStorage);
    }
  }catch(e){
    console.log(e)
  }

  const handleChangeText = (event) => {
    setValueText(event.target.value);
    try{
      localStorage.setItem("callnotes_" + callcode, event.target.value);
    }catch(e){
      console.log(e);
    }
  };

  let textAreaStyle = { height: 270, width: 360, padding: 10 };

  if(isMobile || isIPad13 || isIPhone13){
    textAreaStyle = { height: '100%', width: '100%', padding: 10 };
  }
  
  const acceptCallEnd = (event) => {
    setIsActive(false);
    if(globalState.auth.deployment.callData.type !=2){
      if(callbackQty > 0){
        setIsWithoutRedirect(true);
        setCrmPopUpOpen(true);
      }else{
        callStatus(false,true,signOutClick);
      }
    }else{
      callStatus(false,true,true);
      callClosedResult(true);
    }
  }

  const rejectEndCall = () => {
    setIsActive(false);
    setIsWithoutRedirect(false);

    let callbackNo = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_CLOSE_CALLBACK_NO");
    setCloseCallAccepted(false);
    // si el parametro callbackNo esta en 1
    if(callbackNo === '1'){
      // pregunto si callBackQty > 0 
      if(callbackQty > 0){
        setCrmPopUpOpen(true);
      }else{
        endCall(signOutClick);
        callClosedResult(true);
      }
    }else{
      endCall(signOutClick);
      callClosedResult(true);
    }
  }

  return (
      <div>
        <Dialog
          open={open}
          onClose={callStatus}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="dialogCloseCall"
        >
          <DialogTitle id="alert-dialog-title"><FormattedMessage id="CALL.CANCEL.TITLE" /></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage id="CALL.CANCEL.DESC" />
            </DialogContentText>
            { callbackQty != 0 &&
            <CRMsPopUp 
              open={crmPopUpOpen} 
              callbackQty={callbackQty} 
              endCallWithoutRedirect={endCallWithoutRedirect} 
              isWithoutRedirect={isWithoutRedirect}
              endCall={endCall} 
              callClosedResult={callClosedResult} 
              closeCallAccepted={closeCallAccepted}
            />
            }
            {notesIsEnabled &&
            <DialogContentText id="callNotes">
              <br />
              <FormattedMessage className="center" id="CALL_NOTES.BAR.TITLE" /> :
              <br />
              <textarea
                id="standard-multiline-flexible"
                value={valueText}
                onChange={handleChangeText}
                style={textAreaStyle}
              >
              </textarea>
            </DialogContentText>
            }
          </DialogContent> 
          <DialogActions>
            <Button id="btnCancelCallEnd" onClick={() => acceptCallEnd()} disabled={!isActive} color="primary">
              <FormattedMessage id="CALL.CANCEL.ACCEPT" />
            </Button>
            <Button id="btnCancelCallClose" onClick={() => rejectEndCall()} disabled={!isActive} color="primary" autoFocus>
              <FormattedMessage id="CALL.CANCEL.REJECT" />
            </Button>
            <Button id="btnCancelCallIgnore" onClick={() => callStatus(false,false)} disabled={!isActive} color="primary" autoFocus>
              <FormattedMessage id="CALL.CANCEL.CANCEL" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
export default injectIntl(
  connect(
    null,
    {...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(CancelCall)
);