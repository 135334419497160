import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";

// Custom Imports
import * as presentationBar from "./ducks/presentationBar.duck";
import * as logger from "./ducks/logger.duck";
import * as schedule from "./ducks/schedule.duck";
import * as dashboard from "./ducks/dashboard.duck";
import * as users from "../modules/Users/users.duck";
import * as userGroups from "./ducks/userGroups.duck";
import * as visualAid from "./ducks/visualAid.duck";
import * as promotionalPlans from "./ducks/promotionalPlans.duck";
import * as customerGroups from "./ducks/customerGroups.duck";
import * as importCustomers from "./ducks/importCustomers.duck";
import * as file from "../modules/Files/file.duck";
import * as product from "../modules/Products/products.duck";
import * as folders from "./ducks/folders.duck";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  presentationBar: presentationBar.reducer,
  logger: logger.reducer,
  schedule: schedule.reducer,
  dashboard: dashboard.reducer,
  users: users.reducer,
  visualAid: visualAid.reducer,
  promotionalPlans: promotionalPlans.reducer,
  userGroups: userGroups.reducer,
  customerGroups: customerGroups.reducer,
  importCustomers: importCustomers.reducer,
  file: file.reducer,
  product: product.reducer,
  folders: folders.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    presentationBar.saga(),
    logger.saga(),
    schedule.saga(),
    dashboard.saga(),
    users.saga(),
    visualAid.saga(),
    promotionalPlans.saga(),
    userGroups.saga(),
    customerGroups.saga(),
    importCustomers.saga(),
    file.saga(),
    product.saga(),
    folders.saga()
  ]);
}
