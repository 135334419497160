import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { sentry_dsn, version, sentry_project, trackApp, appInsightsKey } from './globalConfig';
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import * as Sentry from '@sentry/browser';

const reactPlugin = new ReactPlugin();
let appInsights = null;
switch (trackApp) {
    case 'APP_INSIGHTS':
        const browserHistory = createBrowserHistory({ basename: '' });
        const ai = new ApplicationInsights({
            config: {
                instrumentationKey: appInsightsKey,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                }
            }
        })
        ai.loadAppInsights()
        appInsights = ai.appInsights
        break;
    default:
        Sentry.init({ dsn: sentry_dsn, release: sentry_project + '@' + version })
        break;
}

export default (Component) => withAITracking(reactPlugin, Component)
export { appInsights };
export { reactPlugin };