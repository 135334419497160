import React, {useEffect, useState} from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {isMobile, isIPad13, isIPhone13} from 'react-device-detect';
import MaterialTable from 'material-table'

import * as visualAidStorage from "../../store/ducks/visualAid.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 540,
    height: 310
  },
  paper: {
    
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1)
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataButton: {
    width: '50%',
    paddingLeft: 10,
    transition: 'opacity .2s',
    '&:hover':{
      opacity:0.5
    }
  },
  dataFullWidth: {
    width: '70%'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  MuiRatingSizeLarge:{
    fontSize: '2rem'
  },
  dialogContainer:{
    position: 'relative'
  },
  actionButtons: {
    
  },
  dialogContainer:{
    maxWidth: 600,
  },
  dialogContent: {
    
  }
}));

const PreviewListPopUp = ({
  open,
  closePreviewListPopUp,
  intl,
  listVaidByCall,
  item
}) => {
  const classes = useStyles();
  //const [modalStyle] = useState(getModalStyle);
  const globalState = useSelector(state => state);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    //console.log(item)
    // aca tengo que hacer la llamada al servicio
    listVaidByCall(item.code);
  }, []);

  useEffect(() => {
    if(globalState.visualAid.resultVaidByCall.length > 0){
      setShowTable(true);
    }else{
      setShowTable(false);
    }
  }, [globalState.visualAid.resultVaidByCall])

  const handleClickPreview = rowData => event => {
    window.open(`/visualAids/preview/${rowData.code}`);
  }

  let columns = [
    { 
      title: intl.formatMessage({ id: "VAID.COLUMN.NAME" }), 
      field: 'name', 
      cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {},
      render: rowData => <a href='#' onClick={handleClickPreview(rowData)}> {rowData.name} </a>
    },
    { title: intl.formatMessage({ id: "VAID.COLUMN.TYPE" }), field: 'type', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px', wordWrap: 'break-word' } : {wordWrap: 'break-word'} },
    { title: intl.formatMessage({ id: "VAID.COLUMN.ORDER" }), field: 'order', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px', wordWrap: 'break-word' } : {wordWrap: 'break-word'} },
    { title: intl.formatMessage({ id: "VAID.COLUMN.TAGS" }), field: 'tags', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px', maxWidth:'50px', wordWrap: 'break-word' } : {maxWidth:'50px', wordWrap: 'break-word'} }
  ];

  const title = intl.formatMessage({ id: "PREVIEW_LIST.POPUP.TITLE" }, {callcode: item.code});

  return(
    <>
      <Dialog
        open={open}
        onClose={closePreviewListPopUp}
        id='popupVaid'
      >
        <div className={classes.dialogContainer}>
          <DialogTitle id="form-dialog-title">
            {title}
          </DialogTitle>
          <div className={classes.dialogContent}>
            <DialogContent className={classes.dialogtop}>
              <div className={classes.paper}>
                <MaterialTable
                  columns={columns}
                  title=""
                  data={globalState.visualAid.resultVaidByCall}
                  options={
                    { 
                      pageSize: 10, 
                      searchFieldAlignment: 'left', 
                      headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
                      actionsColumnIndex: -1
                    }
                  }
                  localization={{
                    header: {
                      actions: ''
                    },
                    toolbar: {
                      searchPlaceholder: intl.formatMessage({
                        id: "ECOMMERCE.COMMON.SEARCH"
                      })
                    },
                    body: {
                      emptyDataSourceMessage: intl.formatMessage({
                        id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
                      })
                    },
                    pagination: {
                      labelDisplayedRows: intl.formatMessage({
                        id: "MATERIAL_TABLE.PAGINATION.TEXT"
                      }, {
                        from: '{from}',
                        to: '{to}',
                        count: '{count}'
                      }),
                      labelRowsSelect: intl.formatMessage({
                        id: "MATERIAL_TABLE.PAGINATION.LABEL"
                      })
                    },

                  }}
                />
              </div>
            </DialogContent>
          </div>
          <DialogActions className={classes.actionButtons}>
            <Button className={classes.button} id="btnCallDetailsClose" onClick={closePreviewListPopUp}>
              <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
            </Button>          
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(PreviewListPopUp)
);