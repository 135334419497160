import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {logoName, appName, copyRight, home_bg} from '../../../globalConfig';

const AuthPage = () => {
  console.log('authpage')
  const backgroundImageRoute = toAbsoluteUrl(`/media/bg/${home_bg}`);
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${backgroundImageRoute})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(`/media/logos/${logoName}`)}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title">{appName}</h3>
                  <h4 className="kt-login__subtitle">
                    <FormattedMessage id="AUTH.PAGE.SLOGAN" />
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    {copyRight}
                  </div>
                  <div className="kt-login__menu" style={{display:'none'/*Se deshabilitan hasta definir contentido*/}}>
                    <Link to="/terms" className="kt-link">
                      <FormattedMessage id="AUTH.PAGE.LINK.PRIVACY" />
                    </Link>
                    <Link to="/terms" className="kt-link">
                      <FormattedMessage id="AUTH.PAGE.LINK.LEGAL" />
                    </Link>
                    <Link to="/terms" className="kt-link">
                      <FormattedMessage id="AUTH.PAGE.LINK.CONTACT" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/registration" component={Registration} />
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/resetpassword/:deployment/:token" component={ResetPassword}/>
                <Redirect from="/auth" exact={true} to="/auth/registration" />
                <Redirect to="/auth/registration" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    null
  )(AuthPage)
);
