import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description'; //default
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'; //pdf
import ImageIcon from '@material-ui/icons/Image'; //png - imagen
import TheatersIcon from '@material-ui/icons/Theaters'; // video
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'; // powerpoint - slider
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'; //.zip
import MusicVideoIcon from '@material-ui/icons/MusicVideo'; //audio
import GifIcon from '@material-ui/icons/Gif'; //gif
import * as folderStorage from "../../store/ducks/folders.duck";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, injectIntl } from "react-intl";

function containsWord(str, word) {
  return str.includes(word);
}

//Devuelve un icono segun el tipo de file
const getFileIcon = function(fileType) {
  if (fileType === "application/pdf") {
    return PictureAsPdfIcon;
  } else if (fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
    return ViewCarouselIcon;
  } else if (containsWord(fileType, "image")) {
    return ImageIcon;
  } else if (containsWord(fileType, "audio/")) {
    return MusicVideoIcon;
  } else if (containsWord(fileType, "video/")) {
    return TheatersIcon;
  } else if (containsWord(fileType, "application/zip")) {
    return InsertDriveFileIcon;
  } else {
    return DescriptionIcon;
  }
}

//Devuelve un color segun el tipo de file
const getLabelIconColor = (fileType) => {
  if (fileType === "application/pdf") {
    return "#dc3545"; // red color for PDF files
  } else if (fileType && containsWord(fileType, "image")) {
    return "#28a745"; // green color for image files
  } else if (fileType == "folder"){
    return "#FFF45E"; // yellow color for folders
  } else if(fileType == "application/vnd.openxmlformats-officedocument.presentationml.presentation"){
    return "#FF9361"; // orange color for PowerPoint files
  } else {
    return "#5578eb" //blue color default
  }
};

const FolderItem = (props) => {
  const { folder } = props;

  return (
    <StyledTreeItem fileType={"folder"} key={folder.code} nodeId={folder.code} labelText={folder.name} labelIcon={FolderIcon}>
      {folder.children ? folder.children.map((node) => {
        return node.cod_folder ? (
          <StyledTreeItem key={node.code} fileType={node.mimeType} nodeId={node.code} labelText={node.name} labelIcon={getFileIcon(node.mimeType)} />
        ) : (
          <FolderItem key={node.code} folder={node} />
        );
      }) : null}
    </StyledTreeItem>
  )
};

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&$selected > $content': {
        backgroundColor: 'var(--tree-view-bg-color,#c6e6ff)',
        color: 'var(--tree-view-color)',
      },
      flexGrow: 1
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightRegular
    },
    group: {},
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit'
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
      marginRight: theme.spacing(1),
      color: (props) => getLabelIconColor(props.fileType)
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  }));
  
  function StyledTreeItem(props) {
    const classes = useTreeItemStyles(props);
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
  
    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': "inherit",
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    );
  }
  
  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  export default injectIntl(
    connect(
      null,
      folderStorage.actions
    )(FolderItem)
  );