import React from 'react';
import { connect, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import * as customerGroupsStorage from "../../store/ducks/customerGroups.duck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
    marginTop: 20,
    paddingLeft: 16
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  labelFilterUser: {
    paddingLeft: 16
  }
}));

const CustomerGroupsFilter = (props) => {
  const {intl} = props;
  const [selectedStateId, setSelectedStateId] = useState(1);

  const classes = useStyles();

  let stateIdArr = [
    {
      key: 1,
      name: intl.formatMessage({
        id: "CUSTOMERGROUPS.STATE.ACTIVE"
      })
    },
    {
      key: 0,
      name: intl.formatMessage({
        id: "CUSTOMERGROUPS.STATE.INACTIVE"
      })
    }
  ];

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedStateId(event.target.value);
    props.setStateId(event.target.value);
  }

  return(
    <>
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.labelFilterUser} id="demo-simple-select-helper-label"><FormattedMessage id="CUSTOMERGROUPS.STATE.LABEL" /></InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={selectedStateId}
        onChange={handleChange}
      >
        {stateIdArr.map((state, index) => {
          return (<MenuItem key={index} value={state.key}>{state.name}</MenuItem>);
        })}
      </Select>
    </FormControl>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    {...customerGroupsStorage.actions}
  )(CustomerGroupsFilter)
);