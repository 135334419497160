import React from 'react';
import { useState, useEffect } from 'react';
import { toAbsoluteUrl } from "../../../_metronic";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import classNames from 'classnames';
import '../PresentationLobby/PresentationLobby.scss';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import ScheduleIcon from '@material-ui/icons/Schedule';
import ReplayIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Button from '@material-ui/core/Button';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import ConnectionTestPopUp from '../ConnectionTestPopUp/ConnectionTestPopUp';
import {
  isMobile, browserName, isIPad13, isIPhone13, isIOS, isSafari
} from 'react-device-detect';
import { connect, useSelector } from "react-redux";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { defaultStyles } from '../../../globalConfig';
import ReactHtmlParser from 'react-html-parser';
import Terms from '../Terms/Terms';
import {
  getDeploymentParamValue
} from '../VideoChat/utils';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import isObjectEmptyOrUndefined from './utils';

const PresentationLobby = ({
  callEnded,
  connected,
  tryAgainButton,
  reloadCall,
  organizerNotConnected,
  history,
  nullAuthToken,
  lobbyLogoUrl,
  lobbyVideoOptions,
  lobbyBody,
  intl,
  timezone,
  role,
  callData,
  auth,
  log,
  deploymentStyle,
  start,
  btnClassStartCall,
  disabledStartCallButton,
  imgContact,
  setLobbyState,
  requestCallData,
  startWithAutoJoin,
  disablePublish
}) => {

  const [requiersTerms,setRequiersTerms] = useState(false);
  const [termsTitle,setTermsTitle] = useState(false);
  const [termsBody,setTermsBody] = useState(false);
  const [termCode, setTermCode] = useState(0);
  const globalState = useSelector(state => state);
  const [interval, setInterval] = useState(null);
  const [lobbyContainer, setLobbyContainer] = useState({});
  /*****************************************************/

  useEffect(() => {
    setLobbyState(true);
    return () => {
      setLobbyState(false);
    }
  }, []);
  
  useEffect(() => {
    if(globalState.auth.terms && globalState.auth.terms.terms.requiresTerm && role == 'subscriber'){
      if(!callEnded)
        setRequiersTerms(true);
      else
        setRequiersTerms(false);
      if(globalState.auth.terms.terms.code)
        setTermCode(globalState.auth.terms.terms.code);
      if(globalState.auth.terms.terms.title)
        setTermsTitle(globalState.auth.terms.terms.title);
      if(globalState.auth.terms.terms.body){
        let termsBodyParam = globalState.auth.terms.terms.body;
        termsBodyParam = termsBodyParam.replace(/\\/g,'');
        setTermsBody(termsBodyParam);
      }
    }

    if(isIOS || isIPad13 || isIPhone13 || isSafari){
      if(window.innerHeight > window.innerWidth){
        setLobbyContainer({ marginTop: '10vh' });
      }else{
        setLobbyContainer({ marginTop: '16vh' });
      }
    }

  }, [globalState.auth.terms]);

  // VideoPlayer settings (lobby video)
  const onVideoReady = () => {
    console.log('video ready');
  };

  const onVideoDisposed = () => {
    console.log('video disposed');
  };

  const events = ['pause', 'play', 'ended', 'volumechange', 'timeupdate', 'seeked'];
  /*****************************************************/

  var duedateUTC;
  let isAmPm = getDeploymentParamValue(globalState.auth.deployment.parameters, 'TIME_AMPM');
  if (callData && callData.duedateUTC && typeof timezone === 'number') {
    duedateUTC = moment(callData.duedateUTC).add(timezone, 'hours').format('DD/MM  HH:mm');
    if(isAmPm == 1){
      duedateUTC = moment(callData.duedateUTC).add(timezone, 'hours').format('DD/MM  hh:mm A');
    }
  } else {
    duedateUTC = '';
    let messageAlert = intl.formatMessage({
      id: "CALL.INVALID.DATA"
    });
    window.alert(messageAlert);
    return <Redirect to="/logout" />
  }
  let btnBack;

  if (!connected) {
    if (role == 'organizer') {
      btnBack = [(<div className="connecting-control-buttons">
        <Button
          style={{
            marginTop: '0px',
            height: '30px',
            backgroundColor: defaultStyles.accent_color
          }}
          id="btnBackToSchedule"
          onClick={() => {
            setTimeout(() => {
              nullAuthToken()
              setTimeout(() => {
                history.push("/auth/registration");
              })
            }, 100);
          }}
          className="btn btn-label-brand btn-sm btn-bold">
          <ScheduleIcon />
          <span>&nbsp;</span>
          <FormattedMessage id="SCHEDULE.NAME" />
        </Button>
      </div>
      )];
    } else {
      let lobbyWaitingDisableSignout = getDeploymentParamValue(globalState.auth.deployment.parameters, 'LOBBY_WAITING_DISABLE_SIGNOUT');

      btnBack = [
        (
          <div className="connecting-control-buttons">
            { lobbyWaitingDisableSignout == 0 &&
              <Link style={{ marginTop: '0px', backgroundColor: defaultStyles.accent_color }} id="btnSignOut"
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold">
                <CloseIcon />
                <span>&nbsp;</span>
                <FormattedMessage id="AUTH.LOGOUT" />
              </Link>
            }
          </div>
        )
      ];
    }
  }

  if (lobbyBody == "")
    lobbyBody = intl.formatMessage({id: "CALL.LOBBY.BODY.DEFAULT"});
  if (lobbyLogoUrl == "")
    lobbyLogoUrl = toAbsoluteUrl(`/media/logos/600x260.png`); //esta es la imagen default si no viene definida en el deployment 

  return (

    <center className="wrapper" style={{marginBottom: 10}}>
     {(requiersTerms && role == 'subscriber') ? <Terms requiersTerms={requiersTerms} log={log} history={history} nullAuthToken={nullAuthToken} termCode={termCode} termsTitle={termsTitle} termsBody={termsBody}/>: null}
      <div className="webkit" >
        <table className="wrapper" cellPadding="0" cellSpacing="0" border="0" width="100%" bgcolor="#f2f4fb" style={lobbyContainer} >
          <tbody>
            <tr>
              <td valign="top" bgcolor="#f2f4fb" width="100%">
                <table className="outer" width="100%" align="center" cellPadding="0" cellSpacing="0" border="0">
                  <tbody>
                    <tr>
                      <td width="100%">
                        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                          <tbody>
                            <tr>
                              <td>
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0" align="center" style={{ width: '100%', maxWidth: '600px' }}>
                                  <tbody>
                                    <tr>
                                      <td bgcolor="#f2f4fb" width="100%" align="left">
                                        <table className="wrapper" style={{ tableLayout: 'fixed' }}>
                                          <tbody>
                                            <tr>
                                              <td >
                                                {
                                                  !isObjectEmptyOrUndefined(lobbyVideoOptions) ? 
                                                  <VideoPlayer fullScreen={false} events={events} from={'lobby'} onVideoReady={onVideoReady} logdelay={10000} { ...lobbyVideoOptions } log={log}/> 
                                                  :
                                                  <img className="max-width"
                                                    style={{ display: 'block', maxWidth: '100% !important', maxHeight: '260px', width: '100%', height: 'auto !important' }}
                                                    src={lobbyLogoUrl} alt="" width="600"
                                                  />
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>


                                        <table className="module" width="100%" cellPadding="0" cellSpacing="0" border="0" style={{ tableLayout: 'fixed' }}>
                                          <tbody>
                                            <tr>
                                              <td style={{ padding: '5px 5px 5px 5px', backgroundColor: '#ffffff' }} height="100%" valign="top" >
                                                <div className={(isMobile) ? 'lobby-body-mobile' : 'lobby-body'}>
                                                  {ReactHtmlParser(lobbyBody)}
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table className="module" width="100%" cellPadding="0" cellSpacing="0" border="0" style={{ tableLayout: 'fixed' }}>
                                          <tbody>
                                            <tr>
                                              <td style={{ padding: '5px 5px 5px 5px', backgroundColor: '#f6f6f6' }} height="100%" valign="top">
                                                <div>
                                                  <div style={{ textAlign: 'center' }}>
                                                    <span style={{ fontFamily: 'arial, helvetica, sans-serif', color: '#000000', fontSize: '18px' }}>
                                                      
                                                      <strong>{callData.organizerName}</strong>
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table className="module" style={{ tableLayout: 'fixed' }} width="100%" cellPadding="0" cellSpacing="0" border="0">
                                          <tbody>
                                            {connected ?
                                              <tr>
                                                <td align="center" >
                                                  <table border="0" cellPadding="0" cellSpacing="0" className="wrapper-mobile" >
                                                    <tbody>
                                                      <tr>
                                                        <td className="inner-td" >
                                                          <button id="btnCallStart" style={{ backgroundColor: deploymentStyle.accentColor, marginTop: '10px', marginBottom: '10px' }}
                                                            className={`${btnClassStartCall}`}
                                                            disabled={disabledStartCallButton}
                                                            onClick={start}
                                                          >
                                                            <FormattedMessage id="STARTPRESENTATIONMASK.BUTTON" />
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr> :

                                              tryAgainButton ?
                                              <table style={{display: 'flex',backgroundColor: '#f6f6f6', flexDirection:'column', alignItems:'center', alignContent: 'center'}}>
                                                <tr>
                                                  <td>
                                                    <div className="connecting-control-buttons">
                                                      < Button onClick={() => reloadCall(false, true)} style={{ backgroundColor: deploymentStyle.accentColor }}
                                                        className="buttonClickeable">
                                                        <ReplayIcon style={{ height: 20, width: 20, color: '#ffffff' }} />
                                                        <span style={{ color: '#ffffff' }}>&nbsp;<FormattedMessage id="CALL.RELOAD.ACCEPT" /></span>

                                                      </Button>
                                                    </div>

                                                  </td>
                                                  <td>
                                                    {btnBack}
                                                  </td>
                                                </tr>
                                                
                                               </table>
                                                : organizerNotConnected ?
                                                <table style={{display: 'flex',backgroundColor: '#f6f6f6', flexDirection:'column', alignItems:'center', alignContent: 'center'}}>
                                                <tr>
                                                  <td>
                                                    {btnBack}
                                                  </td>
                                                </tr>

                                               </table> :
                                                <tr>
                                                  <td>
                                                    <div className="connecting-button">
                                                      <button style={{ backgroundColor: deploymentStyle.accentColor }}
                                                        className="buttonClickeable--busy kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light"
                                                        disabled>
                                                        <FormattedMessage id="CONNECTINGMASK.BUTTON" />
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>

                                            }
                                            

                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div >
    </center >
  )
}

export default injectIntl(
  connect(
    null,
    { ...presentationBarStorage.actions },
  )(PresentationLobby)
);
