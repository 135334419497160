import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormattedMessage, injectIntl } from "react-intl";
import {useStyles} from './styles';

const DownloadButton = () => {
  const classes = useStyles();

  return(
    <div className={classes.downloadButton}>
    <GetAppIcon />
    <FormattedMessage id="FILE.DOWNLOAD_BUTTON" />
    </div>
  )
}

export default DownloadButton;