import React, { useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import { defaultStyles } from '../../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";

import * as productStorage from "../products.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const CreateProductButton = (props) => {

  const globalState = useSelector(state => state);
  const classes = useStyles();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [inputText, setInputText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [typeSelected, setTypeSelected] = React.useState(1);
  const {intl} = props;
  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  useEffect(() => {
    if(globalState.product.productContainerResponse.code && 
      globalState.product.productContainerResponse.code != null && 
      globalState.product.productContainerResponse.redirection === true){
      doRedirection(globalState.product.productContainerResponse.code);
      setOpen(false);
    }
  }, [globalState.product.productContainerResponse])
  
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setInputText('');

    setOpen(false);
  }
  
  const handleChangeText = (event) => {
    setInputText(event.target.value);
  }

  const handleAccept = () => {
    //history.push("/files/update/" + 123);
    setIsCreateDisabled(true);
    props.createProductContainer({
      name: inputText
    });
  }

  const doRedirection = (code) => {
    history.push("/products/update/" + code);
  }

  return(
    <>
      <Button className="btn btn-sm btn-label-success" id="btnCreateVisualAid" onClick={handleOpen}>
        <AddCircleIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="PRODUCTS.LIST_PAGE.CREATE_BUTTON" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { setIsDisabled(true) }}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="PRODUCT.POPUP.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            <div className="popup__input__container">
              <FormControl className={classes.formControl}>
                <TextField
                  id="datetime-local"
                  label={intl.formatMessage({
                    id: "PRODUCT.POPUP.INPUT"
                  })}
                  type="text"
                  value={inputText}
                  onChange={handleChangeText}
                  fullWidth
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnProductCreate" onClick={handleAccept} disabled={isCreateDisabled}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnProductClosePopUp" onClick={handleClose}>
            <FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.ACTION.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    productStorage.actions
  )(CreateProductButton)
);