import React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  }
}));


const ScheduleInactivateCallPopUp = (props) => {

  const { intl } = props;
  const [open, setOpen] = React.useState(false);
  const [requestState, setRequestState] = React.useState(null)

  const classes = useStyles();

  const globalState = useSelector(state => state);

  const handleOpen = () => {
    setOpen(true);
  };
  
  useEffect(() => {
    if(globalState.schedule.callcode && globalState.schedule.callcode === props.item.code){
      let msg = ''
      let backgroundColor = 'green';
      if (globalState.schedule.requestStateInactivate != null) {
        if (globalState.schedule.requestStateInactivate === true) {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.INACTIVATE.OK"
          });
        } else {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.INACTIVATE.ERROR"
          });
          backgroundColor = 'red';
        }
        if (msg != ''){
          showPopupInactivateCall(msg, backgroundColor);
        }
        props.setRequestStateInactivate({state: null, callcode: null});
        props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo})
      }
    }
  }, [globalState.schedule.requestStateInactivate])

  const showPopupInactivateCall = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #avisoInactivateCall {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "avisoInactivateCall");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);
    window.load = setTimeout("if(typeof(avisoInactivateCall) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(avisoInactivateCall)){for (var i = 0; i < avisoInactivateCall.length; i++) {document.body.removeChild(avisoInactivateCall[i])}}else{document.body.removeChild(avisoInactivateCall)}}", 5000);
  }

  const handleAccept = () =>{
    props.inactivateCallPopUp(props.item);
    // despues de modificar la fecha dar algo de feedback al respecto
  }
  
  const handleClose = () => {
    setOpen(false);
    props.reactors[1](null)
  };


  return (
    <div style={{width: '100%'}} id="form-dialog-title-schedule-modifier">
      <div onClick={handleOpen}>
        <span>&nbsp;</span>
        {<FormattedMessage id="SCHEDULE.EDIT.BUTTON.INACTIVATE" />}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><FormattedMessage id="SCHEDULE.CALL.INACTIVATE" /></DialogTitle>
        <DialogContent className="schedule__modifier__popup__content">
          <FormattedMessage id="SCHEDULE.CALL.INACTIVATE.TEXT" />
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnModifyCall" onClick={handleAccept}>
            <FormattedMessage id="SCHEDULE.CALL.ACCEPT" />
          </Button>
          <Button className={classes.button} id="btnModifyCallClosePopUp" onClick={handleClose}>
            <FormattedMessage id="SCHEDULE.CALL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(ScheduleInactivateCallPopUp)
);