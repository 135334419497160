import React, { useMemo, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
//import VisualAids from '../../components/VisualAids';
import { FormattedMessage, injectIntl } from "react-intl";
import LinearProgress from '@material-ui/core/LinearProgress';
//import CreateVisualAidButton from '../../components/VisualAids/CreateVisualAidButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from "react-router-dom";

import * as promotionalPlansStorage from "../../store/ducks/promotionalPlans.duck";
import PromotionalPlans from "../../components/PromotionalPlans";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PromotionalPlansPage = (props) => {
  const globalState = useSelector(state => state);
  const history = useHistory();
  
  const handleCloseSnackBar = () => {
    props.saveResultPromotionalPlans(null);
  }

  const handleCreateButtonClick = () => {
    history.push("/promotionalplans/create");
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.promotionalPlans.loading && <LinearProgress className="progressBar" />}
      {globalState.promotionalPlans.loading && <div className="progressBackground"></div>}
      {globalState.promotionalPlans.resultSavePromotionalPlan != null &&
      <Snackbar 
        open={(globalState.promotionalPlans.resultSavePromotionalPlan !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.promotionalPlans.resultSavePromotionalPlan) ? 'success' : 'error'}>
          {globalState.promotionalPlans.resultSavePromotionalPlan ? 
            <FormattedMessage id="PROMOTIONAL_PAGE.CREATE.SUCCESS" />
            :
            <FormattedMessage id="PROMOTIONAL_PAGE.CREATE.ERROR" />
          }
        </Alert>
      </Snackbar>
      }
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="PROMOTIONAL_PLANS.TITLE" /></h3>
        </div>
        <div>
          <Button className="btn btn-sm btn-label-success" id="btnCreateVisualAid" onClick={handleCreateButtonClick}>
            <AddCircleIcon />
            <span>&nbsp;</span>
            <FormattedMessage id="VAID.LIST_PAGE.CREATE_BUTTON" />
          </Button>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <PromotionalPlans />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    promotionalPlansStorage.actions
  )(PromotionalPlansPage)
);