import React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import { 
  toAbsoluteUrl, 
  getDeploymentParamValue 
} from "../../../_metronic/utils/utils";
import {
  isIOS, isAndroid
} from 'react-device-detect';
// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const CRMsPopUp = (props) => {
  const { intl } = props;
  const [open, setOpen] = React.useState(false);
  const [requestState, setRequestState] = React.useState(null);
  const [valueNotes, setValueNotes] = React.useState(null);
  
  const [crmsList, setCrmsList] = React.useState([]);

  const classes = useStyles();

  const globalState = useSelector(state => state);
  let crms=[];

  useEffect(() => {
    if(props.callbackQty > 0){
      // First we should find the names
      for(let i = 1; i<= props.callbackQty; i++){
        crms[i] = new Array();
        let crmsNameArr = globalState.auth.deployment.parameters.filter((param) => {
          return param.key === `CALL_CLOSE_CALLBACK_${i}_NAME`
        });
        if(crmsNameArr.length > 0){
          crms[i][0] = crmsNameArr[0];
        }
        let crmsTypeArr = globalState.auth.deployment.parameters.filter((param) => {
          return param.key === `CALL_CLOSE_CALLBACK_${i}_TYPE`
        });
        if(crmsTypeArr.length > 0){
          crms[i][1] = crmsTypeArr[0];
        }
        let crmsUrlArr = globalState.auth.deployment.parameters.filter((param) => {
          return param.key === `CALL_CLOSE_CALLBACK_${i}_URL`
        });
        if(crmsUrlArr.length > 0){
          crms[i][2] = crmsUrlArr[0];
        }
        if(isIOS){
          if(crms[i][1].value.includes('droid')){
            crms.splice(i, 1);
          }
        }
        if(isAndroid){
          if(crms[i][1].value.includes('ipad') || crms[i][1].value.includes('iphone') || crms[i][1].value.includes('ipod')){
            crms.splice(i, 1);
          }
        }
        if( !isIOS && !isAndroid ){
          if(crms[i] && crms[i][1].value.includes('droid')){
            crms.splice(i, 1);
          }
          if(crms[i] && (crms[i][1].value.includes('ipad') || crms[i][1].value.includes('iphone') || crms[i][1].value.includes('ipod'))){
            crms.splice(i, 1);
          }
        }
      }
      crms = crms.filter(item => {
        return item != null && item != ''
      })
      //console.log(crms);
      setCrmsList(crms);
    
    }
  }, [])


  useEffect(() => {
    if(props.open){
      if(crmsList.length <= 0){
        setOpen(false);
        handleAccept();
      }else{
        setOpen(true);
        try{
          setValueNotes(localStorage.getItem("callnotes_" + globalState.auth.callCode));
          const callbackAutomatic = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_CLOSE_CALLBACK_AUTOMATIC');
          if(callbackAutomatic === '1' && crmsList.length > 0){
            let textUrl = generateUrl(crmsList[0]);
            window.open(textUrl, '_blank');
            handleAccept();
          }
        }catch(e){
          console.log(e);
        }
      }
    }else{
      setOpen(false);
    }
  }, [props.open])

  const handleAccept = () => {
    if (!props.fromDashBoard || props.fromDashBoard !== true) {
      if (props.isWithoutRedirect) {
        props.endCallWithoutRedirect();
      }

      props.endCall(false);
      props.callClosedResult(true);
    } else {
      props.callStatus();
      props.setRequestState({state: null, callcode: null});
      props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo});
    }
  }
  
  const handleClose = () => {
    handleAccept();
    setOpen(false);
  };

  const generateUrl = (crm) => {
    let url = '';

    let callNoteValue = null;
    if(valueNotes != null){
      callNoteValue = valueNotes;
    }else{      
      callNoteValue = localStorage.getItem("callnotes_" + globalState.auth.callCode);
    }
    if(crm && crm[2]){
      url = crm[2].value.replace(/{callcode}/gi, globalState.auth.callCode);
      url = url.replace(/{callnotes}/gi, encodeURI(callNoteValue));
      url = url.replace(/{deployment}/gi, encodeURI(globalState.auth.deployment.code));
      url = url.replace(/{userexternalcode}/gi, encodeURI(globalState.auth.userid)); // aca no recibo ningun parametro llamado userexternalcode en auth. preguntar cual campo va
      url = url.replace(/{status}/gi, encodeURI((props.closeCallAccepted)? '2' : '1'));
    }
    return url;
  }

  return(
    <div style={{width: '100%'}} id="form-dialog-title-schedule-modifier">
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><FormattedMessage id="CRM_POPUP.TITLE" /></DialogTitle>
          
        <DialogContent className="schedule__modifier__popup__content">
          <br />
          <br />
          {crmsList.length > 0 && crmsList.map((crm, index) => {
            let textUrl = '';
            textUrl = generateUrl(crm);
            return (
              <div key={index}>
                <a 
                  key={index} 
                  href={textUrl} 
                  target="_blank"
                  onClick={handleAccept}
                >
                  <img 
                    src={toAbsoluteUrl(`/media/icons/${crm[1].value}.png`)} 
                    width="32px"
                    height="32px"
                  />
                  <span style={{marginLeft: 5}}>
                    {crm[0].value}
                  </span>
                </a>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button id="btnCancelCallIgnore" onClick={handleAccept} color="primary" autoFocus>
            <FormattedMessage id="CALL.CANCEL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(CRMsPopUp)
);