import React, {useEffect} from 'react';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { connect, useSelector } from "react-redux";
import {defaultStyles} from '../../../globalConfig';
import CallDetailsPopUp from '../CallDetailsPopUp';
import moment from 'moment';

const NotificationItem = ({category, color, notification, open}) => {
  const globalState = useSelector(state => state);
  const [notificationItem, setNotificationItem] = React.useState(null);
  const [showNotificationPopUp, setShowNotificationPopUp] = React.useState(null);

  useEffect(() => {
    if(!open){
      setShowNotificationPopUp(false);
    }
  }, [open])

  let ClassNameIcon = {};
  switch(category){
    case 'CALL_ALARM':
      ClassNameIcon = () => (<VideoCallIcon style={{color: defaultStyles.accent_color}} />);
      break;
    case 'CUSTOMER_CONNECTED':
      ClassNameIcon = () => (<VideoCallIcon style={{color: defaultStyles.accent_color}} />);
       break;             
  }


  const showCallPopUp = () => {
    let calls = globalState.presentationBar.calls
    for(let date in calls){
      for(let i = 0; i < calls[date].length; i++){
        if(calls[date][i].code === notification.data_Key){
          setNotificationItem(calls[date][i]);
          setShowNotificationPopUp(true);
          break;
        }
      }
    }
  }

  return(
    <a 
      className="kt-notification__item"
      onClick={showCallPopUp}
    >
      {showNotificationPopUp && 
      <CallDetailsPopUp item={notificationItem} fromNotification={true} />
      }
      <div className="kt-notification__item-icon">
        <i className="kt-font-success" ><ClassNameIcon /></i>
        
      </div>
      <div className="kt-notification__item-details">
        <div className="kt-notification__item-title">
          {notification.title}
        </div>
        <div style={{color:'#b5b5c3'}}>
          {notification.body}
        </div>
        <div style={{color:'#b5b5c3', fontWeight:'bold'}}>
          {moment(notification.createdAtUTC, 'YYYY-MM-DD HH:mm').add(globalState.auth.timezone, 'hours').fromNow()}
        </div>
      </div>
    </a>
  )
}

export default 
  connect(
    null,
    null
  )(NotificationItem);