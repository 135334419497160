import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import VpnKeyIcon from '@material-ui/icons/VpnKey';


import * as dashboardStorage from "../../store/ducks/dashboard.duck";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: '100%',
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20%',
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    width: '50%',
    textAlign: 'right'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10
  },
  input: {
    marginLeft: 5
  },
  errorBox:{
    backgroundColor: '#f44336',
    color: 'white',
    borderRadius: 4,
    letterSpacing: '0.01071em',
    fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif',
    padding: '6px 16px',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    marginBottom: 20
  },
  iconButtonPass:{
    marginRight: 5
  },
  changePasswordButtonText:{
    textTransform: 'none',
    fontFamily: 'Poppins, Helvetica, sans-serif'
  }
}));

const PasswordPopUp = (props) => {
  const [open, setOpen] = React.useState(false);
  const [inputValueOld, setInputValueOld] = React.useState('');
  const [inputValueNew, setInputValueNew] = React.useState('');
  const [inputValueConfirm, setInputValueConfirm] = React.useState('');
  const [validationError, setvalidationError] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const {
    accentColor
  } = props;
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    if(
      inputValueOld && 
      inputValueNew && inputValueNew.length >= 6 &&
      inputValueConfirm && inputValueConfirm.length >= 6
    ){
      // hacer una llamada a la api
      if(inputValueNew === inputValueConfirm){
        setvalidationError(false);
        setInputValueOld('');
        setInputValueNew('');
        setInputValueConfirm('');
        props.changePassword({oldPassword: inputValueOld, newPassword: inputValueNew});
        setOpen(false);
      }else{
        setvalidationError(true);
      }
    }else{
      setvalidationError(true);
    }
  }

  return(
    <>
      <Button 
        id="changePassWord"
        className="btn btn-label-brand btn-sm btn-bold"
        //onClick={() => {console.log('auch')}}
        onClick={handleOpen}
        style={props.height? {height: props.height,backgroundColor: accentColor}
                            :{backgroundColor: accentColor}}
      >
        <VpnKeyIcon className={classes.iconButtonPass} />
        <div className={classes.changePasswordButtonText}>
          <FormattedMessage id="AUTH.BUTTON.CHANGE_PASSWORD" />
        </div>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        id="popup"
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="AUTH.BUTTON.CHANGE_PASSWORD" />
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            {validationError && <div className={classes.errorBox}><FormattedMessage id="AUTH.POPUP.VALIDATION_INVALID" /></div>}
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="AUTH.POPUP.CHANGE_PASSWORD.OLD_PASSWORD" />: </span>
              <input style={{width: '60%', border: '1px solid #767676'}}
                value={inputValueOld}
                onChange={(e) => setInputValueOld(e.target.value)}
                autoFocus
                className={classes.input}
                type='password'
              />
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="AUTH.POPUP.CHANGE_PASSWORD.NEW_PASSWORD" />: </span>
              <input style={{width: '60%', border: '1px solid #767676'}}
                value={inputValueNew}
                onChange={(e) => setInputValueNew(e.target.value)}
                className={classes.input}
                type='password'
              />
            </div>
            <div className={classes.inRow}>
              <span className={classes.label}><FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />: </span>
              <input style={{width: '60%', border: '1px solid #767676'}}
                value={inputValueConfirm}
                onChange={(e) => setInputValueConfirm(e.target.value)}
                className={classes.input}
                type='password'
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            className={classes.button} 
            id="btnPasswordAccept" 
            onClick={handleAccept} 
            disabled={(inputValueNew.length >= 6 && inputValueConfirm.length >= 6 && inputValueNew === inputValueConfirm) ? false : true}
          >
            <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
          </Button>
          <Button className={classes.button} id="btnPasswordClose" onClick={handleClose}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    dashboardStorage.actions
  )(PasswordPopUp)
);
