import React, { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import * as folderStorage from "../../store/ducks/folders.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const DeleteFolderButton = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  
  useEffect(() => {
    if(Object.entries(globalState.folders.folderSelected).length !== 0) {
        setIsDisabled(false);
    } else {
        setIsDisabled(true);
    }

    setIsDeleteDisabled(hasChildrens(globalState.folders.folderSelected));
  }, [globalState.folders.folderSelected]) 

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleAccept = () => {
    if(Object.entries(globalState.folders.folderSelected).length === 0) {
      props.setErrorValidation("folderSelected");
      setOpen(false);
    } else {
      const folderCode = globalState.folders.folderSelected.code;
      props.inactivateFolder({
        code: folderCode
      });
  
      props.setSelectedFolder({});
      setOpen(false);
    }
  }

  const hasChildrens = (folderSelected) => {
    return globalState.folders.folders.some(folder => folder.cod_parent === folderSelected.code);
  }

  return(
    <>
      <Button disabled={isDisabled} className="btn btn-sm btn-label-danger" id="btnDeleteFolder" onClick={handleOpen}>
        <DeleteIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="FOLDERS.DELETE_FOLDER" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
            {
                !isDeleteDisabled ?
                <FormattedMessage id="FOLDERS.DELETE_FOLDER" />
                :
                <FormattedMessage id="FOLDERS.CANNOT_DELETE" />  
            }
          
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            {
                !isDeleteDisabled ?
                <div className="container">
                    <Typography> <FormattedMessage id="FOLDERS.DELETE_CONFIRM" />  </Typography>
                </div>
                :
                <div className="container">
                    <Typography> <FormattedMessage id="FOLDERS.DELETE_ERROR" />  </Typography>
                </div>
            }
           
          </div>
        </DialogContent>
        <DialogActions>
          {
            !isDeleteDisabled && 
            <Button className={classes.button} id="btnDeleteFolderAccept" onClick={handleAccept}>
                <FormattedMessage id="FOLDERS.DELETE_FOLDER" />
            </Button>
          }
          
          <Button className={classes.button} id="btnDeleteFolderClose" onClick={handleClose}>
            <FormattedMessage id="FOLDERS.ACTION_CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    folderStorage.actions
  )(DeleteFolderButton)
);