import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import moment from 'moment';
import { toAbsoluteUrl } from "../../../_metronic";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import './Chat.scss';

// Impoorting action types and methods from local actions file
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";


const Chat = (props) => {
  const { intl } = props;
  const globalState = useSelector(state => state);
  const [message, setMessage] = useState('');
  const [badgeInvisible, setBadgeInvisible] = useState(true);
  const [showNewMessageButton, setShowNewMessageButton] = useState(false);

  useEffect(() => {
    if(
      !globalState.presentationBar.showChatDrawer && 
      globalState.presentationBar.chat.messages.length > 0 && 
      globalState.presentationBar.chat.messages[globalState.presentationBar.chat.messages.length - 1].from !== 'me'
    ){
      setBadgeInvisible(false);
    }
    var messagesContainer = document.getElementsByClassName('chat__body__messages')[0];

    if(globalState.presentationBar.showChatDrawer && 
      globalState.presentationBar.chat.messages.length > 0 &&
      globalState.presentationBar.chat.messages[globalState.presentationBar.chat.messages.length - 1].from !== 'me' && 
      messagesContainer &&
      (document.documentElement.clientHeight - 50 < messagesContainer.scrollHeight && messagesContainer.scrollTop <= messagesContainer.scrollHeight)
      ){
        setShowNewMessageButton(true);
    }
  }, [globalState.presentationBar.chat.messages.length])

  useEffect(() => {
    if(globalState.presentationBar.showChatDrawer){
      setBadgeInvisible(true);
    }
    if(globalState.presentationBar.showChatDrawer){
    setTimeout(() => {
      let messagesContainer = document.getElementsByClassName('chat__body__messages')[0];
      if(globalState.presentationBar.chat.messages.length > 0 && 
        messagesContainer &&
        (document.documentElement.clientHeight - 50 < messagesContainer.scrollHeight && messagesContainer.scrollTop <= messagesContainer.scrollHeight)
        ){
          setShowNewMessageButton(true);
      }
    }, 0)
  }
  }, [globalState.presentationBar.showChatDrawer])


  const toggleChatDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') && event.type === 'click') {
      return;
    }
    Object.assign(globalState.presentationBar, { ...globalState.presentationBar, showChatDrawer: open })
    props.updateStateChatDrawer({ showChatDrawer: open });
  };

  const messageManager = () => event => {
    setMessage(event.target.value)
  }

  const handleKeyDown = () => event => {
    if ((event.key === 'Enter' || event.type === 'click') && message.length > 0) {
      let messageToSend = message.replace(/[|&;"<>()+,]/g, "")
      props.sendChatMessage({from: 'me', message: messageToSend});
      setMessage('');
      setTimeout(() => {
        var messagesContainer = document.getElementsByClassName('chat__body__messages')[0];
        if(messagesContainer){
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      }, 0);
      setShowNewMessageButton(false);
    }
  }

  const inputMessage = intl.formatMessage({
    id: "CHAT.INPUT.MESSAGE"
  });

  const MessageFactory = (props) => {
    switch(props.chatObj.from){
      case 'me':
        return(
          <MessageFromMe message={props.chatObj.message} from={props.chatObj.from} index={props.index} date={props.chatObj.date} name={props.chatObj.name}/>
        )
      case 'other':
        return(
          <MessageFromOther message={props.chatObj.message} from={props.chatObj.from} index={props.index} date={props.chatObj.date} name={props.chatObj.name}/>
        )
    }
  }

  const MessageFromMe = (props) => {
    const date = moment(props.date).format("HH:mm");

    let showDate = true;
    if(props.index > 0){
      if(date == moment(globalState.presentationBar.chat.messages[props.index - 1].date).format("HH:mm")){
        showDate = false;
        //console.log(props)
        if(props.from !== globalState.presentationBar.chat.messages[props.index - 1].from){
          showDate = true;
        }
      }
    }
    return(
      <div className="chat__message__container__me">
        <div className="chat__message__name__me">{props.name}</div>
        <div className="chat__message__me">{props.message}</div>
        {showDate ?
        <div className="chat__message__clock__me">{date}</div>
        :
        <div className="chat__message__clock__me"></div>
        }
      </div>
    )
  }

  const MessageFromOther = (props) => {
    const date = moment(props.date).format("HH:mm");
    let showDate = true;
    if(props.index > 0){
      if(date == moment(globalState.presentationBar.chat.messages[props.index - 1].date).format("HH:mm")){
        showDate = false;
        if(props.from !== globalState.presentationBar.chat.messages[props.index - 1].from){
          showDate = true;
        }
      }
    }
    return(
      <div className="chat__message__container__other">
        <div className="chat__message__name__other">{props.name}</div>
        {showDate ?
        <div className="chat__message__clock__other">{date}</div>
        :
        <div className="chat__message__clock__other"></div>
        }
        <div className="chat__message__other">{props.message}</div>
      </div>
    )
  }

  const accentColor = globalState.auth.deployment.parameters.filter((item) => {
    return item.key === "ACCENT_COLOR_1"
  })[0];

  const newMessagesHandler = () => event => {
    var messagesContainer = document.getElementsByClassName('chat__body__messages')[0];
    if(messagesContainer){
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
    setShowNewMessageButton(false);
  }

  const scrollHandler = () => event => {
    let messagesContainer = document.getElementsByClassName('chat__body__messages')[0];
    if(messagesContainer){
      if(messagesContainer.scrollTop + messagesContainer.clientHeight >= messagesContainer.scrollHeight){
        setShowNewMessageButton(false);
      }
    }
  }
  
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return(
    <>
      <div id="buttonChat" className={`ots-video-control circle`}>
          <Button onClick={toggleChatDrawer(true)} style={{ height: '100%'}}>
            <Badge style={{color: accentColor.value}} badgeContent=" " invisible={badgeInvisible}>
              <img
                alt="chat-icon"
                src={toAbsoluteUrl("/media/icons/icon-chat.png")}
              />
            </Badge>
          </Button>
      </div>
      <SwipeableDrawer
          anchor="right"
          open={globalState.presentationBar.showChatDrawer}
          onClose={toggleChatDrawer(false)}
          onOpen={toggleChatDrawer(true)}
          disableBackdropTransition={!iOS} 
          disableDiscovery={iOS}
          ModalProps={props.isFull?{
            container: document.getElementById('callContainer'),
            style: { position: 'absolute' }
          }:{
            style: { position: 'absolute' }
          }}
        >
          <div className="chat__container">
            <div className="chat__header">
              <div></div>
              <div className="chat__header__text" style={{color: accentColor.value}}>
                <FormattedMessage id="CHAT.BAR.TITLE" />
              </div>
              <IconButton id="closePresentationList" onClick={toggleChatDrawer(false)} className="chat__header__close" style={{color: accentColor.value}}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="chat__body" onScroll={scrollHandler()}> {/* contenedor de todo el chat */}
            {showNewMessageButton &&
              <div className="chat__new_messages__button">
                <ArrowDownwardOutlinedIcon id="btnChatNewMessage" onClick={newMessagesHandler()} style={{color:'#717171', fontSize: 30}}/>
              </div>
            }
              <div className="chat__body__messages"> {/* contenedor de los mensajes del chat */}
                {globalState.presentationBar.chat.messages.length > 0 && globalState.presentationBar.chat.messages.map((item, index) => {
                  return <MessageFactory chatObj={item} index={index} key={index} />
                })}
                
              </div>
              <div className="chat__message_input__container">{/* contenedor del input del chat */}
                <div className="chat_message_input__innerContainer">
                  <div style={{width:'100%'}}>
                    <InputBase
                      className="inputBase"
                      placeholder={inputMessage}
                      inputProps={{ 'aria-label': inputMessage }}
                      value={message}
                      onChange={messageManager()}
                      onKeyDown={handleKeyDown()}
                      style={{width:'96%'}}
                    />
                  </div>
                  <div>
                    <IconButton id="btnChatSend" onClick={handleKeyDown()} type="submit" className="iconButton" aria-label="search" style={{color: accentColor.value}}>
                      <SendIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </SwipeableDrawer>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    {...presentationBarStorage.actions}
  )(Chat)
);