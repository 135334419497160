import React, {useEffect} from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import StickyToolbar from "../../app/partials/layout/StickyToolbar";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import { metronic } from "../../_metronic";
import '../../app/partials/layout/LayoutCall.scss';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";
import * as presentationBarStorage from "../../app/store/ducks/presentationBar.duck";
import * as authStorage from "../../app/store/ducks/auth.duck";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fab from '@material-ui/core/Fab';
import { FormattedMessage, injectIntl } from "react-intl";
import BackToScheduleButton from '../../app/components/BackToScheduleButton';


const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  contentContainerClasses,
  asideLeftDisplay = false,
  setLanguage,
  lang,
  center,
  isOnCall,
  deployment,
  isFromStorage = false,
  deploymentStorage = {},
  fulfillDeployment,
  fillUserData,
  role,
  menu,
  history,
  analyticsLogEvent
}) {
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);
  setLanguage(lang);

  const contentCssClasses = htmlClassService.classes.content.join(" ");

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleButtonMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };
  
  let callClass = 'normalHeightCall';
  if(isOnCall){
    callClass = 'fullScreenHeight';
  }

  if(menu && menu.menu && menu.menu.list && menu.menu.list.length > 0){
    let responseList = menu.menu.list;
    MenuConfig.aside.items = responseList;
  }
  const accentColor = deployment.parameters.filter((item) => {
    return item.key === 'ACCENT_COLOR_1'
  })[0];

  useEffect(() => {
    analyticsLogEvent(
      "screen_view",
      { name: history.location.pathname}
    )
  }, [])

  return (
    <>    
      { !isOnCall &&
        <LayoutInitializer
          menuConfig={MenuConfig}
          layoutConfig={LayoutConfig}
          htmlClassService={htmlClassService}
        >
          {/* <!-- begin:: Header Mobile --> */}
          {<HeaderMobile asideDisplay={asideLeftDisplay} history={history} />}
          {/* <!-- end:: Header Mobile --> */}

          <div className="kt-grid kt-grid--hor kt-grid--root">
            {/* <!-- begin::Body --> */}
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
              {/* <!-- begin:: Aside Left --> */}
              {asideLeftDisplay && (
                <>
                  <AsideLeft />
                </>
              )}
              {/* <!-- end:: Aside Left --> */}
              <div
                className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                id="kt_wrapper"
              >
                {/* <!-- begin:: Header READY --> */}

                <Header displayHeaderLogo={!asideLeftDisplay} center={center} history={history} />
                {/* <!-- end:: Header --> */}                
              </div>
            </div>
            {/* <!-- end:: Body --> */}
          </div>
          <ScrollTop />
        </LayoutInitializer>
      }
      {/* <!-- begin:: Content --> */}
      <div id="kt_content" className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor ${callClass}`}>
        {/* <!-- begin:: Content Head --> */}
        {subheaderDisplay && (
          <SubHeader />
        )}
        {/* <!-- end:: Content Head --> */}

        {/* <!-- begin:: Content Body --> */}
        {/* TODO: add class to animate  kt-grid--animateContent-finished */}
        <KtContent>
          {children}
        </KtContent>
        {/*<!-- end:: Content Body -->*/}
      </div>
      {/* <!-- end:: Content --> */}
    </>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, presentationBar, auth }) => {
  return {
    layoutConfig,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
      layoutConfig,
      "header.self.fixed.desktop"
    ),
    contentContainerClasses: "",
    // contentContainerClasses: builder.selectors.getClasses(store, {
    //   path: "content_container",
    //   toString: true
    // })
    isOnCall : presentationBar.callActive,
    deployment: auth.deployment,
    role: auth.role,
    menu: auth.menu
  }
}
  

export default injectIntl(
  connect(
    mapStateToProps,
    {...metronic.i18n.actions, ...presentationBarStorage.actions, ...authStorage.actions}
  )(Layout)
);
