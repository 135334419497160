import React, {useState} from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './VaidCanvasDialog.scss';
import { CircularProgress, LinearProgress } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 600,
    height: 310
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataFullWidth: {
    width: '70%'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  }
}));


const VaidCanvasDialog = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {intl} = props;
  let msgSelected = intl.formatMessage(
    {id: "VAID.CANVAS.DIALOG.SELECTED"},
    {
      selected: props.selected,
      total: props.total
    }
  );


  return(
    <>
      <Dialog
        open={props.open}
        onClose={props.inputPdfClose}
        id="popup-vaidCanvas"
      >
        {(props.loadingSpinner) && <LinearProgress className="progressBar" />}
        {(props.loadingSpinner) && <div className="progressBackground"></div>}
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="VAID.CANVAS.DIALOG.TITLE" />
        </DialogTitle>
        <div className="vaidCanvasDialog__filename__label">
          {props.fileName}
        </div>
        <div className="vaidCanvasDialog__buttonsActionSelection__container">
          <Button className={classes.button} id="btnCallDetailsClose" onClick={props.selectAll}>
            <FormattedMessage id="VAID.CANVAS.DIALOG.SELECTALL" />
          </Button>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={props.clearSelection}>
            <FormattedMessage id="VAID.CANVAS.DIALOG.CLEAR" />
          </Button>
        </div>
        <DialogContent className={classes.dialogtop}>
          {props.children}
        </DialogContent>
        <div className="vaidCanvasDialog__selected__label">
          {msgSelected}
        </div>
        <DialogActions>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={props.handleAcceptSlides}>
            <FormattedMessage id="VAID.CANVAS.DIALOG.ACCEPT" />
          </Button>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={props.inputPdfClose}>
            <FormattedMessage id="VAID.CANVAS.DIALOG.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(VaidCanvasDialog);