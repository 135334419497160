import React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from "react-intl";
import DialogContentText from '@material-ui/core/DialogContentText';
import { isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import CRMsPopUp from '../CRMsPopup';
// -------- storage
import * as scheduleStorage from "../../store/ducks/schedule.duck";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  }
}));



const ScheduleCloseCallPopUp = (props) => {
  const { intl } = props;
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [crmPopUpOpen, setCrmPopUpOpen] = useState(false);
  const [requestState, setRequestState] = React.useState(null)
  const [valueText, setValueText] = useState('');
  const [callbackQty, setCallbackQty] = useState(false);

  const classes = useStyles();

  const globalState = useSelector(state => state);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    let callbackQtyArr;
    callbackQtyArr = globalState.auth.deployment.parameters.filter((param) => {
      return param.key === "CALL_CLOSE_CALLBACK_QTY"
    })
    if(callbackQtyArr.length > 0){ // harcoded de prueba 
      setCallbackQty(parseInt(callbackQtyArr[0].value, 10))
    }
  }, [])
  
  useEffect(() => {
    if(globalState.schedule.callcode && globalState.schedule.callcode === props.item.code){
      let msg = ''
      let backgroundColor = 'green';
      if (globalState.schedule.requestState != null) {
        if (globalState.schedule.requestState === true) {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.CLOSE.OK"
          });

        } else {
          msg = intl.formatMessage({
            id: "SCHEDULE.CALL.CLOSE.ERROR"
          });
          backgroundColor = 'red';
        }
        if (msg != ''){
          showPopupCloseCall(msg, backgroundColor);
        }
        props.setRequestState({state: null, callcode: null});
        props.getCallsByDate({selectedFrom: globalState.presentationBar.selectedFrom, selectedTo: globalState.presentationBar.selectedTo})
      }
    }
  }, [globalState.schedule.requestState]);

  
  const handleChangeText = (event) => {
    setValueText(event.target.value);
    try{
      localStorage.setItem("callnotes_" + props.item.code, event.target.value);
    }catch(e){
      console.log(e);
    }

  };

  let textAreaStyle = { height: 270, width: 360, padding: 10 };
  if(isMobile || isIPad13 || isIPhone13){
    textAreaStyle = { height: '100%', width: '100%', padding: 10 };
  }

  const showPopupCloseCall = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #avisoCloseCall {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "avisoCloseCall");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);
    window.load = setTimeout("if(typeof(avisoCloseCall) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(avisoCloseCall)){for (var i = 0; i < avisoCloseCall.length; i++) {document.body.removeChild(avisoCloseCall[i])}}else{document.body.removeChild(avisoCloseCall)}}", 5000);
  }

  const getNotesAndCloseCall = () => {
    let valueNotes = '';
    try{
      valueNotes = localStorage.getItem("callnotes_" + props.item.code);
    }catch(e){
      console.log(e);
    }
    props.closeCallPopUp({callcode: props.item.code, valueNotes: valueNotes});
  }

  const handleAccept = () =>{
        
    if(callbackQty > 0){
      setDisabled(true);
      setCrmPopUpOpen(true);
    }else{
      setDisabled(true);
      getNotesAndCloseCall();
    }
  }
  
  const handleClose = () => {
    setOpen(false);
    props.reactors[1](null)
  };

  try{
    let textStorage = localStorage.getItem("callnotes_" + props.item.code);
    if(textStorage && valueText !== textStorage){
      setValueText(textStorage);
    }
  }catch(e){
    console.log(e)
  }

  let notesIsEnabled = false;
  if(globalState.auth.deployment.parameters){
    notesIsEnabled = globalState.auth.deployment.parameters.filter((parameter) =>{
      return (parameter.key === "CALL_NOTES_ENABLE")
    });
    if(notesIsEnabled.length > 0){
      notesIsEnabled = notesIsEnabled[0]['value'];
      notesIsEnabled = (notesIsEnabled && notesIsEnabled === "1") ? true : false;
    }
  }
  return(
    <div style={{width: '100%'}} id="form-dialog-title-schedule-modifier">
      <div onClick={handleOpen}>
        <span>&nbsp;</span>
        {<FormattedMessage id="SCHEDULE.EDIT.BUTTON.CLOSE" />}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><FormattedMessage id="SCHEDULE.CALL.CLOSE" /></DialogTitle>
        <DialogContent className="schedule__modifier__popup__content">
          <FormattedMessage id="SCHEDULE.CALL.CLOSE.TEXT" />
          { callbackQty &&
          <CRMsPopUp open={crmPopUpOpen} callbackQty={callbackQty} callStatus={getNotesAndCloseCall} signOutClick={true} fromDashBoard={true} />
          }
          {notesIsEnabled &&
          <DialogContentText id="callNotes">
            <br />
            <FormattedMessage className="center" id="CALL_NOTES.BAR.TITLE" /> :
            <br />
            <textarea
              id="standard-multiline-flexible"
              value={valueText}
              onChange={handleChangeText}
              style={textAreaStyle}
            >
            </textarea>
          </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnModifyCall" onClick={handleAccept} disabled={disabled}>
            <FormattedMessage id="SCHEDULE.CALL.ACCEPT" />
          </Button>
          <Button className={classes.button} id="btnModifyCallClosePopUp" onClick={handleClose}>
            <FormattedMessage id="SCHEDULE.CALL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...scheduleStorage.actions, ...presentationBarStorage.actions}
  )(ScheduleCloseCallPopUp)
);