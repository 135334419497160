import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import CallPage from "../call/CallPage";
import PresentationPage from "../presentation/PresentationPage";
import { Link, useHistory } from "react-router-dom";
import PresentationPageGrid from "../presentation/PresentationPageGrid";

export default function HomePage(props) {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const history = useHistory();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/call" exact={false} >
          <CallPage history={history} />
        </Route>
        <Route path="/presentation2" exact={false} >
          <PresentationPage history={history} />
        </Route>
        <Route path="/presentation" exact={false} >
          <PresentationPageGrid history={history} />
        </Route>
        <Route path="/p" exact={false} >
          <PresentationPageGrid history={history} />
        </Route>
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}