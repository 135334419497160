import React, {useEffect} from 'react';
import HeaderUser from './components/HeaderUser';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  validateEmail
} from '../../../_metronic/utils/utils';
import {useParams} from 'react-router-dom';

import * as userStorage from "./users.duck";

//import './VisualAidsCreatePage.scss';

const UserCreatePage = (props) => {
  const {intl} = props;
  const globalState = useSelector(state => state);
  const [initialState, setInitialState] = React.useState([]);
  const history = useHistory();
  let params = useParams();

  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  useEffect(() => {
    if(globalState.users.newUserObject.email && 
      globalState.users.newUserObject.name &&
      globalState.users.newUserObject.externalCode
    ){
      setIsCreateDisabled(false);
    }else{
      setIsCreateDisabled(true);
    }
  }, [globalState.users.newUserObject]);

  const backToUserList = () => {
    let messageAlert = intl.formatMessage({
      id: "VAID.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      //props.fillResultVisualAidContainer({});
      props.resetNewUser();
      history.push("/users");
    }
  }

  const saveMetaData = () => {
    if(props.edit === true){
      if(validateEmail(globalState.users.newUserObject.email)){
        props.editUser();
        history.push("/users")
      }else{
        props.setErrorValidation('email')
      }
    }else{
      if(validateEmail(globalState.users.newUserObject.email)){
        props.saveNewUser();
        history.push("/users")
      }else{
        props.setErrorValidation('email')
      }
    }
  }

  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {globalState.users.loading && <LinearProgress className="progressBar" />}
        {globalState.users.loading && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="USER.TITLE" /></h3>
          </div>

          <div>
          <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backVisualAid" onClick={backToUserList}>
            <ArrowBackIcon />
            <FormattedMessage id="VAID.BACK_BUTTON" />
          </Button>
          <Button size="medium" className="btn btn-sm btn-label-success" id="saveVisualAid" onClick={saveMetaData} disabled={isCreateDisabled}>
            <SaveIcon />
            <FormattedMessage id="VAID.EDIT.PAGE.SAVE.BUTTON" />
          </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>
              <div>
                <HeaderUser edit={props.edit} uuid={params.uuid} />
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    userStorage.actions
  )(UserCreatePage)
);