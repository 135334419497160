//-------------------------
export const otCoreOptions = {
  credentials: {
    apiKey: '',
    sessionId: '',
    token: ''
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: '#publisherContainer',
        screen: '#publisherContainer',
      },
      subscriber: {
        camera: '#layoutContainer',
        screen: '#layoutContainer',
        custom: '#layoutContainer'
      },
    }[pubSub][type];
  },
  controlsContainer: '#controls',
  packages: ['screenSharing'],
  communication: {
    autoSubscribe: false,
    connectionLimit: 2,
    callProperties: {
      fitMode: "cover",
      mirror: false //false para tener la misma inclinacion organizer,publisher
    }
  },
  textChat: {
    name: ['David'], // eslint-disable-line no-bitwise
    waitingMessage: '',
    container: '#chat',
  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: false,
    externalWindow: false,
    appendControl: false,
    dev: true,
    controlsContainer: "#shareScrenButton",
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'off',
      },
      videoSource: 'window',
      fitMode: 'contain' // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '.App-video-container',
      subscriber: '.App-video-container'
    }
  },
};
