import axios from "axios";
import {configUrls} from '../../globalConfig';

export const GET_CUSTOMERGROUPS_BY_STATE_ID = `${configUrls.base_url}/api/customergroup?state_id=`;
export const CREATE_CUSTOMERGROUP = `${configUrls.base_url}/api/customergroup/create`;
export const GET_CUSTOMERGROUP_ATRIBUTE = `${configUrls.base_url}/api/customeratribute/0/1`;
export const CUSTOMERGROUP_ASSIGN_BYATRIBUTE = `${configUrls.base_url}/api/customergroup/assign/byAtribute`; 

export function getCustomerGroupAtribute(param){
  return axios.get(GET_CUSTOMERGROUP_ATRIBUTE);
}

export function getCustomerGroupsByStateId(type){
  return axios.get(GET_CUSTOMERGROUPS_BY_STATE_ID + type);
}

export function createCustomerGroup(payload){
  return axios.post(CREATE_CUSTOMERGROUP, {
    name: payload.name,
    externalCode: payload.externalCode
  });
}

export function assignByAtributeCus(payload){
  return axios.put(CUSTOMERGROUP_ASSIGN_BYATRIBUTE, {
    codGroup: payload.codGroup,
    clear: payload.clear,
    insert: payload.insert,
    codEntity: 0,
    atributeIndex: 1,
    atributeList: payload.atributeList.toString()
  })
}