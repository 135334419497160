import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BlobServiceClient } from "@azure/storage-blob";
import moment from 'moment';

const useFileUpload = (props, fileSelected) => {
    const globalState = useSelector(state => state);
    const [fileContainerReceived, setFileContainerReceived] = useState(false);
    const [folderSetted, setFolderSetted] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(-1);
    const [fileCode, setFileCode] = useState(null);
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        if(!fileContainerReceived &&
           !globalState.file.fileUploaded && 
           globalState.file.fileContainerResponse.code && 
           globalState.file.fileContainerResponse.code != null &&
           !folderSetted
           ) 
        {
            // console.log("2")
            setFileContainerReceived(true);
            props.getEditFile(globalState.file.fileContainerResponse.code);
        }
        else if(fileContainerReceived && !folderSetted && !globalState.file.fileUploaded) {
            // console.log("3")
            setFileCode(globalState.file.fileContainerResponse.code);
            setFileUrl(globalState.file.fileContainerResponse.url)

            // setea el cod_folder del file antes de guardar el archivo
            props.fillResultFileContainer({...globalState.file.fileContainerResponse, cod_folder: globalState.folders.folderSelected.code});
            setFolderSetted(true);
        }
        else if(fileContainerReceived && folderSetted && !globalState.file.fileUploaded) {
            // console.log("4")
            saveMetaData();
            setFileContainerReceived(false);
            setFolderSetted(false);
        }
        
    }, [globalState.file.fileContainerResponse])

    useEffect(() => {
        if(uploadProgress == 100 && globalState.file.editFileResult) {
            setUploadProgress(-1);
            props.setFileUploaded(true);
        }
    }, [uploadProgress, globalState.file.editFileResult])

    const uploadFileProgress = (progress) => {
        if(!fileSelected[0] || fileSelected[0].size <= 0){
            setUploadProgress(-1);
            return;
        }
    
        let fileSize = fileSelected[0].size;
    
        setUploadProgress(Math.round(progress.loadedBytes * 100 / fileSize));
    };
      
    const uploadFile = async (files) => {
        if(!files || files.length <= 0 || !files[0] || files[0].size <= 0){
            return;
        }

        let fileUrl = globalState.file.fileContainerResponse.upload_Url.replace('{FileName}', '');

        const blobServiceClient = new BlobServiceClient(fileUrl);
        
        const containerClient = blobServiceClient.getContainerClient('');
        
        //const blobName = globalState.file.fileContainerResponse.code;
        const blobName = files[0].name;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        
        // Retorno el promise del upload, para que al momento de guardar el vaid, se espere a que este subido antes de volver al listado de presentaciones
        return blockBlobClient.upload(
            files[0], 
            files[0].size,
            {
                onProgress: uploadFileProgress,
                blobHTTPHeaders: {
                    blobContentType: files[0].type
                }
            }
        );  
    };
    
    const saveMetaData = async() => {
        //validar
        if(!globalState.file.fileContainerResponse.name){
            props.setErrorValidation('name');
        }else if(!globalState.file.fileContainerResponse.folder && !globalState.file.fileContainerResponse.cod_folder){
            props.setErrorValidation('folder');
        }else if(!globalState.file.fileContainerResponse.externalCode){
            props.setErrorValidation('externalCode');
        }else if(!globalState.file.fileContainerResponse.validFrom){
            props.setErrorValidation('validFrom');
        }else if(!globalState.file.fileContainerResponse.validTo){
            props.setErrorValidation('validTo');
        }else if(!moment(globalState.file.fileContainerResponse.validFrom).isBefore(globalState.file.fileContainerResponse.validTo)){
            props.setErrorValidation('datesDifference');
        }else{
            props.setErrorValidation(null);
            
            if(fileSelected != null && fileSelected.length > 0){
                try{
                    await uploadFile(fileSelected);
                    await props.saveMetaDataFile({
                        fileName:fileSelected[0].name, 
                        mimeType:fileSelected[0].type,
                        size:fileSelected[0].size,
                        queryString: "?firstUpdate=true"
                    });
                    await props.fillResultFileContainer({});
                    // console.log("5")
                }catch(e){
                    console.log(e);
                    props.fillResultFileContainer({});
                    props.setEditFileResult(null);
                }
            }
        }
    }

    return {fileCode, fileUrl, uploadProgress}

}

export default useFileUpload
