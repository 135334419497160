import {
  isMobile, 
  osVersion, 
  osName, 
  browserVersion, 
  browserName, 
  mobileVendor,
  mobileModel, 
  getUA, 
  deviceType,
  isIPad13, 
  isIPhone13
} from 'react-device-detect';
import moment from 'moment';
import { configUrls } from '../../../globalConfig';
import KTUtil from "../../../_metronic/_assets/js/util";
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import { appInsights } from '../../../TelemetryService';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { trackApp } from '../../../globalConfig';
import * as Sentry from '@sentry/browser';

export const trackException = (err) => {
  switch (trackApp) {
    case 'APP_INSIGHTS':

      if (err instanceof Error) {
        appInsights.trackException({
           exception: err
         });
       } else {
        appInsights.trackException({
           exception: new Error(err)
         });
       }
      //appInsights.trackException({ error: new Error(err), severityLevel: SeverityLevel.Error });
      break;
    case 'SENTRY':
      Sentry.captureException(err);
    default:
      break;
  }
}

export const getDeploymentParamValue = (parameters, name) => {
  let value = '';
  for (let index = 0; index < parameters.length; index++) {
    const element = parameters[index];
    if (element.key.includes(name)) {
      value = element.value;
      break;
    }
  }
  return value;
}

export const getPublisherProps = (publisherProperties,parameters) => {
  if (parameters) {
    let publisherDevice = "DESKTOP";
    if (lowerThanTablet) {
      publisherDevice = "PHONE";
    }else if(KTUtil.isInResponsiveRange("tablet")){
      publisherDevice = "TABLET";
    }
    let publisherResolution = getDeploymentParamValue(parameters, "PUBLISHER_" + publisherDevice + "_RESOLUTION");
    const validResolutions = {
      max: '1280x720',
      medium: '640x480',
      min: '320x240'
    };
    if (publisherResolution && publisherResolution != ""
      && (publisherResolution == validResolutions.max || publisherResolution == validResolutions.medium || publisherResolution == validResolutions.min)) {
      publisherProperties.resolution = publisherResolution;
    }
    let publisherFrameRate = getDeploymentParamValue(parameters, "PUBLISHER_" + publisherDevice + "_FRAMERATE");
    const validFrameRate = {
      max: parseInt(30),
      medium: parseInt(15),
      low: parseInt(7),
      min: parseInt(1)
    };
    if (publisherFrameRate && publisherFrameRate != "" && !isNaN(publisherFrameRate)){
      publisherFrameRate = parseInt(publisherFrameRate);
      if(publisherFrameRate == validFrameRate.max || publisherFrameRate == validFrameRate.medium || publisherFrameRate == validFrameRate.low || publisherFrameRate == validFrameRate.min) {
        publisherProperties.frameRate = publisherFrameRate;
      }
    }
  }
  return publisherProperties;
}

export const getDeviceInfo = () => {
  let deviceTypeProp = "device: " + replaceSpecialChars(deviceType);
  
  //MANTIS: 34989, en ios13+, los ipads son detectados como mobile
  if(isIPad13 || isIPhone13) 
  {
    if (lowerThanTablet) 
    {
      deviceTypeProp = "device: mobile"; 
    }
    else
    {
      deviceTypeProp = "device: tablet"; 
    }    
  }
  
  let osProp = "os: " + osName + " " + replaceSpecialChars(osVersion);
  let browserProp = "browser: " + replaceSpecialChars(browserName) + " " + replaceSpecialChars(browserVersion);
  let uaProp = "ua: " + replaceSpecialChars(getUA);

  let deviceInfoText = deviceTypeProp + "| " + osProp + "| " + browserProp + "| " + uaProp;
  if ((isMobile || isIPad13 || isIPhone13) && mobileVendor && mobileModel) 
  {
    let mobileProp = "mobile: " + replaceSpecialChars(mobileVendor) + "| mobilemodel: " + replaceSpecialChars(mobileModel);
    deviceInfoText += "| " + mobileProp;
  }

  deviceInfoText += "| h: " + window.innerHeight.toString();
  deviceInfoText += "| w: " + window.innerWidth.toString();

  return deviceInfoText;
}

export const replaceSpecialChars = (prop) => {
  prop = prop.replace(/\,/g, '');
  prop = prop.replace(/\|/g, '');
  prop = prop.replace(/\;/g, '');
  prop = prop.replace(/\:/g, '');
  prop = prop.replace(/\'/g, '');
  prop = prop.replace(/\r?\n|\r/, " ");
  return prop;
}

export const replaceTags = (msg, callCode, timezone, deploymentName, duedateUTC, organizerName, subscriberName, subscriberId, shortUrl) => {
  let dateTimezone = moment(duedateUTC).add(timezone, 'hours');
  let formatedDate = dateTimezone.format('HH:mm');
  let day = moment(dateTimezone).date();
  let month = moment(dateTimezone).month();
  month = parseInt(month) + 1;
  //let hours = moment(dateTimezone).hours();
  //let minutes = moment(dateTimezone).minutes();
  let repName = organizerName;
  let depName = deploymentName;
  let name = subscriberName;
  let callUrl = shortUrl ? `${configUrls.web_url}/p/${callCode}` : `${configUrls.web_url}/presentation/${callCode}/${subscriberId}`;

  msg = msg.replace(/{date}/g ,day + "/" + month);
  msg = msg.replace(/{hour}/g, formatedDate);
  msg = msg.replace(/{repname}/g ,repName);
  msg = msg.replace(/{depname}/g ,depName);
  msg = msg.replace(/{callcode}/g ,callCode);
  msg = msg.replace(/{callurl}/g ,callUrl);
  msg = msg.replace(/{name}/g ,name);
  return msg;
}