import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkCopyClientButton from '../LinkCopyClientButton';
import SendEmailButton from '../SendEmailButton';
import GuestEmailButton from '../Guest/GuestEmailButton';
import WhatsAppButton from  '../WhatsAppButton';
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import ScheduleModifierPopUp from '../ScheduleModifierPopUp';
import './CallActionShareMenu.scss';
import ShareIcon from '@material-ui/icons/Share';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinkIcon from '@material-ui/icons/Link';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import { FormattedMessage, injectIntl } from "react-intl";
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import { connect, useSelector } from "react-redux";

const CallActionShareMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const globalState = useSelector(state => state);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '.MuiListItemIcon-root': {
        color: '#0000008a !important'
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white !important',
        },
      },
    },
  }))(MenuItem);

  let idOfElement = `call-action-share-menu-${props.item.code}`;
  
  if(props.popup){
    idOfElement = `call-action-share-menu-popup-${props.item.code}`;
  }

  const callGuestQty = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_GUEST_QTY");

  return (
    <div style={{display: 'inline', margin: '0 0 0 10px'}}>
      <Button 
        aria-controls={idOfElement}
        aria-haspopup="true" 
        onClick={handleClick} 
        color="primary" 
        id="callShareMenu"
        className="btn btn-label-success"
      >
        <ShareIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="DASHBOARD.MENU.SHARE" />
      </Button>
      <StyledMenu
        id={idOfElement}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem id="menuItem__linkCopyButton" className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <LinkCopyClientButton popup={props.popup} item={props.item} reactors={[anchorEl, setAnchorEl]} />
        </StyledMenuItem>
        <StyledMenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <SendEmailButton item={props.item} reactors={[anchorEl, setAnchorEl]}/>
        </StyledMenuItem>
        {(callGuestQty && callGuestQty == '1' && props.item.guestEmail) ?
        <StyledMenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <GuestEmailButton item={props.item} reactors={[anchorEl, setAnchorEl]}/>
        </StyledMenuItem>: null}
        <StyledMenuItem onClick={handleClose} className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <WhatsAppButton item={props.item} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

export default injectIntl(CallActionShareMenu);