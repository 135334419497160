import React, {useEffect, useState} from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from "react-intl";
import * as fileStorage from "../../../modules/Files/file.duck"
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { defaultStyles } from '../../../../globalConfig';
import HeaderFile from '../../../modules/Files/components/HeaderFile';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

const EditFilePopUp = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  const [name, setName] = useState('');
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);
  const [fileUpdated, setFileUpdated] = useState(false);

  useEffect(() => {
    props.getEditFile(globalState.file.fileContainerResponse.code);

    return () => {
      props.setEditFileResult(null);
      props.fillResultFileContainer({});
      props.setFileUploaded(false);
    }
  }, [])

  useEffect(() => {
    if(globalState.file.fileContainerResponse.name && globalState.file.fileContainerResponse.externalCode){
      setName(globalState.file.fileContainerResponse.name)
    }
  }, [globalState.file.fileContainerResponse]);

  useEffect(() => {
    if(fileUpdated && globalState.file.editFileResult){
      handleClose();
    }
  }, [fileUpdated, globalState.file.editFileResult])

  const handleClose = () => {
    props.setDisplayDialog(false);
    props.setFileUploaded(false);
    props.fillResultFileContainer({});
    props.setEditFileResult(null);
    setFileUpdated(false);
  }

  const saveMetaData = async() => {
    //validar
    if(!globalState.file.fileContainerResponse.name){
      props.setErrorValidation('name');
    }else if(!globalState.file.fileContainerResponse.folder && !globalState.file.fileContainerResponse.cod_folder){
      props.setErrorValidation('folder');
    }else if(!globalState.file.fileContainerResponse.externalCode){
      props.setErrorValidation('externalCode');
    }else if(!globalState.file.fileContainerResponse.validFrom){
      props.setErrorValidation('validFrom');
    }else if(!globalState.file.fileContainerResponse.validTo){
      props.setErrorValidation('validTo');
    }else if(!moment(globalState.file.fileContainerResponse.validFrom).isBefore(globalState.file.fileContainerResponse.validTo)){
      props.setErrorValidation('datesDifference');
    }else{
      props.setErrorValidation(null);
      setIsCreateDisabled(true)
      
      if(globalState.file.fileContainerResponse.status === 3){
        try{
          await props.saveMetaDataFile({edit:true, editFilePopUp: true});
          await props.fillResultFileContainer({});
          setFileUpdated(true);
        }catch(e){
          console.log(e);
          props.fillResultFileContainer({});
          props.setEditFileResult(null);
        }
      }
    }
  }

  return (
    <Dialog
      open={props.displayDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'lg'}
      classes={{paper: classes.paper}}
    >

      <DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div id='fileName'>{name}</div>
        <Button size="medium" className="btn btn-sm btn-label-success" id="saveFileFolder" onClick={saveMetaData} disabled={isCreateDisabled}>
          <SaveIcon />
          <FormattedMessage id="FILE.EDIT.PAGE.SAVE.BUTTON" />
        </Button>
      </div>
      </DialogTitle>

      <DialogContent>
        <HeaderFile from={"popup"}/>
      </DialogContent>
     
    </Dialog>
  )
}

const showToastImportantNotification = (msg, backgroundColor) => {
  let css = document.createElement("style");
  let style = document.createTextNode(`
    #toastImportantNotification {
      position:fixed; z-index: 9999999; 
      widht: 120px; 
      top:15%;
      right:1%;
      margin-left: -60px;
      padding: 20px; 
      background: ${backgroundColor};
      border-radius: 8px;
      font-size: 14px;
      font-family: sans-serif;
      color: #ffffff;
      text-align: center;
    }
    .toast-white{
      color: #ffffff;
    }
    .toast-white:hover{
      color: #ffffff;
    }
  `);
  css.appendChild(style);
  document.head.appendChild(css);
  let advisement = document.createElement("div");
  advisement.setAttribute("id", "toastImportantNotification");

  let content = document.createTextNode(msg);
  advisement.appendChild(content);
  advisement.appendChild(document.createElement('br'));
  document.body.appendChild(advisement);

  window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: '100%'
  },
  paper: {
    position: 'absolute',
    maxHeight: 800, 
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

export default injectIntl(
    connect(
      null,
      fileStorage.actions
    )(EditFilePopUp)
  );
