import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FolderIcon from '@material-ui/icons/Folder';

import * as folderStorage from "../../store/ducks/folders.duck";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 250
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const EditFolderButton = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  const [inputText, setInputText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const {intl} = props;
  
  const handleOpen = () => {
    if(Object.entries(globalState.folders.folderSelected).length !== 0) {
      setInputText(globalState.folders.folderSelected.name);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setInputText('');
    setOpen(false);
  }
  
  const handleChangeText = (event) => {
    setInputText(event.target.value);
  }

  const handleAccept = () => {
    if(!inputText) {
      props.setErrorValidation("name");
    } else if (Object.entries(globalState.folders.folderSelected).length === 0) {
      props.setErrorValidation("folderSelected");
      setInputText('');
      setOpen(false);
    } else {
      props.updateFolder({
        name: inputText,
        code: globalState.folders.folderSelected.code ? globalState.folders.folderSelected.code : 0
      });
  
      setInputText('');
      setOpen(false);
    }
  }

  return(
    <>
      <Button className="btn btn-sm btn-label-blue" color='primary' id="btnEditFolder" onClick={handleOpen} disabled={Object.entries(globalState.folders.folderSelected).length === 0}>
        <EditIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="FOLDERS.EDIT_BUTTON" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="FOLDERS.EDIT_TITLE" />
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div style={modalStyle} className={classes.paper}>
            {
              Object.entries(globalState.folders.folderSelected).length !== 0 &&
              <div className="popup__parent__container">
              <FolderIcon/> {globalState.folders.folderSelected.name}
            </div>
            }  
            <div className="popup__input__container">
              <FormControl className={classes.formControl}>
                <TextField
                  id="datetime-local"
                  label={intl.formatMessage({
                    id: "FOLDERS.EDIT_INPUT"
                  })}
                  type="text"
                  value={inputText}
                  onChange={handleChangeText}
                  fullWidth
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnEditFolderAccept" onClick={handleAccept}>
            <FormattedMessage id="FOLDERS.EDIT_BUTTON" />
          </Button>
          <Button className={classes.button} id="btnEditFolderClose" onClick={handleClose}>
            <FormattedMessage id="FOLDERS.ACTION_CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    folderStorage.actions
  )(EditFolderButton)
);