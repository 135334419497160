import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';

import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 260
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column'
  },
  MuiRatingSizeLarge:{
    fontSize: '3rem'
  }
}));

const FeedBackPopUp = (props) => {
  const { intl } = props;
  const [open, setOpen] = React.useState(true);
  const [starsValue, setStartsValue] = React.useState(3);
  const [valueText, setValueText] = React.useState('');
  
  const classes = useStyles();

  const globalState = useSelector(state => state);

  useEffect(() => {
    const callFeedbackDefault = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_FEEDBACK_DEFAULT");

    if(callFeedbackDefault) {
      setStartsValue(callFeedbackDefault);
    }
  }, [globalState.auth.deployment.parameters])

  const handleAccept = () =>{
    const customerEndUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_CUSTOMER_END_URL");
    
    props.sendFeedback({
      callCode:globalState.auth.callCode, 
      starsValue:starsValue, 
      valueText: valueText
    })
    setOpen(false);
    //props.endCall(false);

    if(customerEndUrl) {
      window.location.href = customerEndUrl;
    }

    setTimeout(() => {
      props.setLogout();
    }, 2000);
  }
  
  const handleClose = () => {
    const customerEndUrl = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_CUSTOMER_END_URL");
    
    setOpen(false);
    //props.endCall(false);

    if(customerEndUrl) {
      window.location.href = customerEndUrl;
    }
    
    setTimeout(() => {
      props.setLogout();
    }, 2000);
  };

  const handleChangeText = (event) => {
    if(event.target.value.length < 1000){
      setValueText(event.target.value);
    }
  };
  const callFeedbackQuestion = getDeploymentParamValue(globalState.auth.deployment.parameters, "CALL_FEEDBACK_QUESTION");

  return(
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        //disableBackdropClick={true}
      >
        <DialogTitle>
          <FormattedMessage id="CALL.FEEDBACK_POPUP.TITLE" />
        </DialogTitle>
          
        <DialogContent className="schedule__modifier__popup__content">
          <h6>
            {callFeedbackQuestion.length > 0 ?
            callFeedbackQuestion
            :
            <FormattedMessage id="CALL.FEEDBACK_POPUP.CONTENT" />
            }
          </h6>
          <Rating
            name="simple-controlled"
            value={starsValue}
            onChange={(event, newValue) => {
              if(!newValue){
                setStartsValue(0);
              }else{
                setStartsValue(newValue);
              }
            }}
            size="large"
            className={classes.MuiRatingSizeLarge}
          />
          <div>
            <label htmlFor="standard-multiline-flexible">
              <FormattedMessage id="CALL.FEEDBACK_POPUP.LABEL" />
            </label>
          </div>
          <textarea
            id="standard-multiline-flexible"
            value={valueText}
            onChange={handleChangeText}
            style={{ height: '100%', width: '100%', padding: 10 }}
            maxLength="1000"
          >
          </textarea>
        </DialogContent>
        <DialogActions>
          <Button id="btnCancelCallIgnore" onClick={handleAccept} color="primary">
            <FormattedMessage id="CALL.FEEDBACK_POPUP.SUBMIT_BUTTON" />
          </Button>
          <Button id="btnCancelCallIgnore" onClick={handleClose} color="primary">
            <FormattedMessage id="CALL.FEEDBACK_POPUP.CANCEL_BUTTON" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...presentationBarStorage.actions}
  )(FeedBackPopUp)
);