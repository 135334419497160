import { useEffect, useState } from 'react';
import { getDeploymentParamValue, getFlowSlides, getFlowUrl } from '../utils';

export function useGetFlows(globalState) {
    const [flows, setFlows] = useState([]);
    const [assets, setAssets] = useState([]);
    const [vaLogoUrl, setValogoUrl] = useState('');

    useEffect(() => {
        const parameters = globalState.auth.deployment.parameters;
        if (parameters) {
            const vaLogoUrlValue = getDeploymentParamValue(parameters, "VA_LOGO_URL");
            const logoUrlValue = getDeploymentParamValue(parameters, "LOGO_URL");
            
            const url = vaLogoUrlValue || logoUrlValue;
            setValogoUrl(url);
        }
    }, [globalState.auth.deployment.parameters]);

    useEffect(() => {
        let presentationSlides = globalState.presentationBar.presentationSlides;

        if(presentationSlides && !presentationSlides.config) {
            presentationSlides = globalState.presentationBar.focusedPresentationSlides;
        }
        
        if (presentationSlides) {
            const { config } = presentationSlides;
            if (config) {
                if (config.flows) setFlows(config.flows);
                if (config.assets) setAssets(config.assets);
            } else {
                setFlows([]);
                setAssets([]);
            }
        }
    }, [globalState.presentationBar.presentationSlides, globalState.presentationBar.focusedPresentationSlides]);

    useEffect(() => {
        const presentationSlides = globalState.presentationBar.presentationSlides || globalState.presentationBar.focusedPresentationSlides;

        if (
            presentationSlides &&
            presentationSlides.content &&
            presentationSlides.content.length > 0 &&
            presentationSlides.type === 'sdva'
        ) {
            const fetchConfigJSON = async () => {
                try {
                    if (flows && flows.length > 0 && assets && assets.length > 0 && presentationSlides && presentationSlides.content && vaLogoUrl) {
                        flows.forEach((f) => {
                            let flowUrl = getFlowUrl(f, presentationSlides.content, assets);
                            f.url = flowUrl ? flowUrl : vaLogoUrl;
                            f.slides = getFlowSlides(f, presentationSlides.content, assets);
                            
                        });
                        
                        setFlows(flows);
                    }
                } catch (err) {
                    console.log(err);
                }
            };
            
            fetchConfigJSON();
        }
    }, [flows, assets, vaLogoUrl]);

  return { flows };
}
