import {
  isMobileOnly,
  isIPhone13,
  isTablet,
  isIPad13,
  isIOS
} from 'react-device-detect';
import classNames from 'classnames';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import {
  getDeploymentParamValue
} from '../VideoChat/utils';
//-------------------------
//-------------------------
/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
export const containerClasses = (state, presentationBar, presentationSlides, role, deployment, disablePublish) => {
  const { active, meta, localAudioEnabled, localVideoEnabled, subscribers } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  let displayScreenPresentation = presentationBar.displayScreenPresentation || false;
  let activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  let activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  let activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  let slideTypesdva = (presentationBar.focusedPresentationSlides.type == 'sdva');
  let videoStreamVaid = false;
  if(subscribers){
    videoStreamVaid = Object.keys(subscribers.custom).length >= 1;
  }

  //console.log(sharingScreen, 'adadasdad')
  //console.log(state, 'adadasdad')
  const isPhoneDevice = (lowerThanTablet || isMobileOnly || isIPhone13);
  let screenShareEnabled = getDeploymentParamValue(deployment.parameters, 'SCREEN_SHARE_ENABLE');
  //Oculto boton de screensharing para el subscriber o en phones o tablets o si se esta mostrando una presentacion
  if (document.getElementById('startScreenSharing')) 
  {
    if (screenShareEnabled != '1' || role != 'organizer' || displayScreenPresentation || isPhoneDevice || isTablet || isIPad13) 
    {
      document.getElementById('startScreenSharing').classList.add('hidden')
    } 
    else 
    {
      document.getElementById('startScreenSharing').classList.remove('hidden')
    }
  }

  if(document.getElementById('fullScreenButton')){
    if(lowerThanTablet && (isIOS || isIPhone13)){
      document.getElementById('fullScreenButton').classList.add('hidden')
    }else{
      document.getElementById('fullScreenButton').classList.remove('hidden')
    }
  }

  

  let hideCamerasWhenPresentationActive = (displayScreenPresentation && lowerThanTablet);
  
  return {
    controlClass: classNames('App-control-container-offline', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', { hidden: (!active || disablePublish), muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { hidden: (!active || disablePublish), muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { 
      hidden: !active || hideCamerasWhenPresentationActive,
      small: !!activeCameraSubscribers, 
      'small-presentation': displayScreenPresentation || sharingScreen, 
      borderRadius: '15px' 
    }),
    cameraSubscriberClass: classNames('video-container', { 
      hidden: !active || !activeCameraSubscribers || hideCamerasWhenPresentationActive, 
      leftBottom: displayScreenPresentation || sharingScreen, 
      borderRadius: '15px' },
      { 'active-gt2': activeCameraSubscribersGt2 && !displayScreenPresentation },
      { 'active-odd': activeCameraSubscribersOdd && !displayScreenPresentation },
      { 'small-presentation': displayScreenPresentation || sharingScreen }
    ),
    hiddenCameras: classNames({ 'hidden': !active }),
    camerasContainerPresentation: classNames(
      { hidden: !active || hideCamerasWhenPresentationActive },
      { 'video__cameras__container__presentation': displayScreenPresentation || sharingScreen || viewingSharedScreen },
      { 'video__cameras__container': !displayScreenPresentation || !sharingScreen || !viewingSharedScreen }
    ),
    presentationClass: classNames(
      'video-container', 
      { hidden: !displayScreenPresentation || !active || videoStreamVaid },
      { 'OT_bigger': displayScreenPresentation}
    ),
    screenPublisherClass: classNames(
      'video__cameras__container__screen', 
      'hidden'
    ),
    screenSubscriberClass: classNames(
      'video__cameras__container__screen', 
      'hidden'
    ),
    hiddenSharingPresentationClass: classNames({ 'hidden': false }),
    minHeightSdva: classNames({ slideTypesdva })
  };
};