import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as userStorage from "../../modules/Users/users.duck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
    marginTop: 20,
    maxWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const UserSelector = (props) => {
  const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();

  const [userSelected, setUserSelected] = React.useState(null);

  /*useEffect(() => {
    if (!globalState.users.users || globalState.users.users.length <= 0) {
      props.getUsers();
    }
  }, []);*/
  
  useEffect(() => {
      if(globalState.users.users.length > 0){
        globalState.users.users.forEach((user) => {
          if((!globalState.users.userSelected || !globalState.users.userSelected.itemCode) && props.item && props.item.guestId == user.uuid){
            setUserSelected(user);
            //props.updateUserSelected({...user, disabled: true});
          }else{
            if(globalState.users.userSelected && globalState.users.userSelected.itemCode)
              setUserSelected(globalState.users.userSelected);
          }
        });
      }
    }, [globalState.users.users.length]);

    useEffect(() => {
      if(!globalState.users.userSelected && 
        globalState.users.deleteGuest === true){
          setUserSelected(null);
      }
    }, [globalState.users.userSelected]);


  let comboOptions = [];
  if (globalState.users.users && globalState.users.users.length > 0) {
    globalState.users.users.map((user, index) => {
      if(props.item){
        user.itemCode = props.item.code;
      }
      if(user.uuid != globalState.auth.userid)
        comboOptions.push(user);
      //return (<MenuItem key={index} value={user}>{user.Name}</MenuItem>);
    })
  }

  return (
    <FormControl className={classes.formControl}>
      {/*<InputLabel id="demo-simple-select-helper-label"><FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.GUEST" /></InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={userSelected}
        onChange={handleChange}
      >
        {(globalState.users.users && globalState.users.users.length > 0) ?
         globalState.users.users.map((user, index) => {
          return (<MenuItem key={index} value={user}>{user.Name}</MenuItem>);
         }) : null}
        </Select>*/}
      {props.readonly ?
        <div className={classes.inRow}>
          <span className={classes.label}><FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.GUEST" />: </span><span className={classes.data}>{userSelected ? userSelected.Name: null}</span>
        </div>
      :
      <Autocomplete
        id="demo-simple-select-helper-label"
        options={comboOptions}
        getOptionLabel={(option) => option.Name}
        value={userSelected}
        onChange={(event, newValue) => {
          setUserSelected(newValue);
          props.updateUserSelected(newValue);
        }}
       
        renderInput={(params) => <TextField {...params} label={intl.formatMessage({
          id: "DASHBOARD.NEW.VISIT.DIALOG.GUEST"
        })} variant="outlined" />}
      />}
    </FormControl>
  )

}

export default injectIntl(
  connect(
    null,
    { ...userStorage.actions, ...presentationBarStorage.actions }

  )(UserSelector)
)