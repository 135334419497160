import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
    marginTop: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomerSelector = (props) => {
  const globalState = useSelector(state => state);

  const classes = useStyles();
  const [typeSelected, setTypeSelected] = React.useState({
    name:'', 
    code:''
  });

  useEffect(() => {
    //listener will be added on component mounted
    if(globalState.presentationBar.customerList.length <= 0){
      props.getCustomerEntireList();
    }
  }, []);

  useEffect(() => {
    //listener will be added on component mounted
    if(globalState.presentationBar.customerList.length > 0){
      globalState.presentationBar.customerList.forEach((cus) => {
        if(cus.uuid == globalState.presentationBar.customerSelected.uuid){
          setTypeSelected(cus);
          props.updateCustomerSelected({...cus, disabled: true});
        }
      });
    }
  }, [globalState.presentationBar.customerList.length]);
  

  const handleChange = (event) => {
    //props.selectCustomer(event.target.value);
    //Object.assign(globalState.presentationBar.customerSelected, event.target.value);
    //console.log(typeSelected);
    setTypeSelected(event.target.value);
    props.updateCustomerSelected(event.target.value);
  };

  return (
    <FormControl className={classes.formControl} disabled={globalState.presentationBar.customerSelected.disabled}>
      <InputLabel id="demo-simple-select-helper-label"><FormattedMessage id="DASHBOARD.NEW.VISIT.DIALOG.CLIENT" /></InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={typeSelected}
        onChange={handleChange}
      >
        {globalState.presentationBar.customerList.map((customer, index) => {
          return (<MenuItem key={index} value={customer}>{customer.name}</MenuItem>);
        })}
      </Select>
    </FormControl>
  )

}

export default injectIntl(
  connect(
      null,
      presentationBarStorage.actions
  )(CustomerSelector)
)