export const getDeploymentParamValue = (parameters, name) => {
  let value = '';
  for (let index = 0; index < parameters.length; index++) {
    const element = parameters[index];
    if (element.key.includes(name)) {
      value = element.value;
      break;
    }
  }
  return value;
}