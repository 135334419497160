import React, { useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as userStorage from "../users.duck";

const CreateUserButton = (props) => {
  //const globalState = useSelector(state => state);
  const history = useHistory();

  const doRedirection = (code) => {
    history.push("/user/create");
  }

  return(
    <>
      <Button className="btn btn-sm btn-label-success" id="btnCreateUser" onClick={doRedirection}>
        <AddCircleIcon />
        <span>&nbsp;</span>
        <FormattedMessage id="FILE.LIST_PAGE.CREATE_BUTTON" />
      </Button>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    userStorage.actions
  )(CreateUserButton)
);