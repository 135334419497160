import React, {useState, useRef, useEffect} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import Step from './Step';
import SignatureCanvas from 'react-signature-canvas';
import {getDeviceInfo} from '../VideoChat/utils';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './CustomRequestDialog.scss';

import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getModalStyle() {
  const top = 30;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 400,
    height: 350
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonRed: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  buttonGreen: {
    margin: theme.spacing(1),
    color: 'green'
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));


const CustomerRequestDialog = (props) => {
  const globalState = useSelector(state => state);
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [signature, setSignature] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [disabled, setDisabled] = useState(false);
  const [isRequestFromCustomer, setIsRequestFromCustomer] = useState(false);
  const [open, setOpen] = useState(true);
  const {intl} = props;

  const sigPad = useRef();

  useEffect(() => {
    props.getCustomerRequestData(globalState.presentationBar.customerRequestCode);
    setIsRequestFromCustomer(globalState.presentationBar.isRequestFromCustomer);
  }, []);

  const handleClose = () => {
    props.setCustomRequestDialog(false);
    setDisabled(false);
    setOpen(false);
    props.setIsRequestFromCustomer(false);
  }

  useEffect(() => {
    setDisabled(globalState.presentationBar.loading)
  }, [globalState.presentationBar.loading]);
  
  useEffect(() => {
    if(globalState.presentationBar.uploadSignatureResult != null){
      setDisabled(false);
    }
  }, [globalState.presentationBar.uploadSignatureResult]);

  useEffect(() => {
    if(
      globalState.presentationBar.acceptRequestResult !== null
    ){
      setOpenSnack(true);
      if(
        globalState.presentationBar.acceptRequestResult === false
      ){
        setSnackSeverity('error');
      }
      if(
        globalState.presentationBar.acceptRequestResult === true
      ){
        setSnackSeverity('success');
      }
    }
  }, [globalState.presentationBar.acceptRequestResult])

  const getBrowserId = () => {
    let storageItem = undefined;
    let browserId = 0;
    if (typeof (localStorage) !== "undefined") {
      try {
        storageItem = localStorage.getItem('browserid');
        if(storageItem != undefined){
          browserId = JSON.parse(storageItem);
        }
      } catch (e) {
        console.log(e)
      }
    }
    return browserId
  }

  const handleAcceptWithoutSign = () => {
    props.acceptCustomerRequestWithoutSign({
      intl: props.intl,
      callcode: globalState.auth.callCode,
      log: getDeviceInfo() + ' | browserid: ' + getBrowserId()
    });
  }

  const handleAccept = () => {
    if(!sigPad.current.isEmpty()){      
      props.uploadSignature({
        intl: props.intl,
        trimmedDataURL: sigPad.current.getTrimmedCanvas().toDataURL('image/png'),
        log: getDeviceInfo() + ' | browserid: ' + getBrowserId(),
        sendSignal: !isRequestFromCustomer
      });
    }else{
      alert(intl.formatMessage({id: "CUSTOMER.REQUEST.DIALOG.SIGN_ERROR"}));
    }
  }

  const handleTabChange = (newValue) => {
    if(tabValue === 0 || tabValue === 1){
      setTabValue(tabValue + 1);
    }
  };

  const cancelSteps = () => {
    if(window.confirm(intl.formatMessage({id: "CUSTOMER.REQUEST.DIALOG.QUESTION.REJECT"}))){
      let storageItem = undefined;
      let browserId = 0;
      if (typeof (localStorage) !== "undefined") {
        try {
          storageItem = localStorage.getItem('browserid');
          if(storageItem != undefined){
            browserId = JSON.parse(storageItem);
          }
        } catch (e) {
          console.log(e)
        }
      }
      
      props.rejectCustomerRequest({
        intl: props.intl,
        callcode: globalState.auth.callCode,
        description: getDeviceInfo() + ' | browserid: ' + browserId,
        sendSignal: !isRequestFromCustomer
      });
      
      props.setCustomRequestDialog(false);
    }
  }

  const clear = () => {
    sigPad.current.clear();
  }

  return(
    <>
      <Snackbar
        open={(openSnack)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1500}
        onClose={() => { 
          props.setCustomRequestDialog(false);
          props.fillAcceptRequestResult(null);
          setOpen(false);
          setOpenSnack(false);
        }}
      >
        <Alert severity={snackSeverity}>
          <FormattedMessage id={`CUSTOMER.REQUEST.DIALOG.${snackSeverity.toUpperCase()}${isRequestFromCustomer && '.CUSTOMER'}`} />
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { }}
        disableBackdropClick={true}
      >
        <DialogTitle id="form-dialog-title-request"><FormattedMessage id="CUSTOMER.REQUEST.DIALOG.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div className={classes.paper}>
            <div className="popup__request__container">
              {tabValue === 0 &&
              <Step value={tabValue} index={0} />
              }
              {tabValue === 1 &&
              <Step value={tabValue} index={1} />
              }
              {tabValue === 2 && globalState.presentationBar.customerRequestData.requires_signature === 1 &&
              <Step value={tabValue} index={2} signature={signature} setSignature={setSignature}>
                <div>
                  <div id="nameCustomer"> 
                    <TextField 
                      disabled 
                      value={globalState.auth.name}
                      id="name-disabled" 
                      label={intl.formatMessage({id: "CUSTOMER.REQUEST.DIALOG.SIGN.NAME_LABEL"})}
                      className="textField"
                    />
                  </div>
                  <div>
                    <SignatureCanvas 
                      penColor='black'
                      canvasProps={{
                        width: 300, 
                        height: 200, 
                        className: 'sigCanvas'
                      }} 
                      ref={sigPad}
                    />
                  </div>
                  <div>
                    <button onClick={clear}>
                      <DeleteIcon />
                      <FormattedMessage id="CUSTOMER.REQUEST.DIALOG.SIGN.CLEAR" />
                    </button>
                  </div>
                </div>
              </Step>
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.buttonRed} id="btnCustomerRequestReject" onClick={cancelSteps}>
            <FormattedMessage id="CUSTOMER.REQUEST.DIALOG.REJECT" />
          </Button>
          {tabValue === 0 || (tabValue === 1 && globalState.presentationBar.customerRequestData.requires_signature === 1) ?
          <Button className={classes.buttonGreen} id="btnCustomerRequestAccept" onClick={handleTabChange}>
            <FormattedMessage id="CUSTOMER.REQUEST.DIALOG.CONTINUE" />
          </Button>
          : 
          null
          }
          {tabValue === 1 && globalState.presentationBar.customerRequestData.requires_signature !== 1 && 
          <Button className={classes.buttonGreen} id="btnCustomerRequestAccept" onClick={handleAcceptWithoutSign}>
            <FormattedMessage id="CUSTOMER.REQUEST.DIALOG.ACCEPT" />
          </Button>
          }
          {tabValue === 2 && globalState.presentationBar.customerRequestData.requires_signature && 
          <Button className={classes.buttonGreen} id="btnCustomerRequestAccept" onClick={handleAccept} disabled={disabled}>
            <FormattedMessage id="CUSTOMER.REQUEST.DIALOG.ACCEPT" />
          </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(CustomerRequestDialog)
);