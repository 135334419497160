export default {
  header: {
    self: {
      display:false
    },
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },

      {
        title: "Custom",
        root: true,
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "Layout Builder",
            icon: "flaticon2-expand",
            page: "builder"
          },
          {
            title: "Error Pages",
            bullet: "dot",
            icon: "flaticon2-warning",
            submenu: [
              {
                title: "Error 1",
                page: "error/error-v1"
              }
            ]
          }
        ]
      }
    ]
  },
  aside: {
    self: {
      display: false
    },
    items: [
      {
        title: "Agenda", //TODO:RESOURCEAR
        root: true,
        icon: "flaticon2-calendar-9",
        page: "dashboard",
        translate: "MENU.AGENDA",
        bullet: "dot"
      },
      {
        title: "Clientes", //TODO:RESOURCEAR
        root: true,
        icon: "flaticon2-group",
        page: "customers",
        translate: "MENU.CUSTOMERS",
        bullet: "dot"
      },
      {
        title: "Usuarios", //TODO:RESOURCEAR
        root: true,
        icon: "flaticon2-group",
        page: "users",
        translate: "MENU.USERS",
        bullet: "dot"
      }
    ]
  }
};
