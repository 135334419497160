import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Badge from '@material-ui/core/Badge';
import './FilterBar.scss';
import FilterItems from './FilterItems';
import FilterListIcon from '@material-ui/icons/FilterList';

// Importando el duck de presentationBar cambiar logica de duck
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { Button } from '@material-ui/core';

const FilterBar = function (props) {
  const { intl } = props;
  const [open, setOpen] = React.useState(false);
  const globalState = useSelector(state => state);

  const handleOpen = () => {
    props.changeIsFull(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 
  useEffect(() => {
   
  }, [])

 
  return (
    <div>
      <a onClick={() => handleOpen()} id="filterPresentations">
       
        {globalState.presentationBar.selectedTagFilter && globalState.presentationBar.selectedTagFilter.length > 0 ?
        <Badge variant="dot" color="error" overlap="circle" badgeContent={globalState.presentationBar.selectedTagFilter.length}>
             <FilterListIcon style={{ fill: props.accentColor }} />
        </Badge>
        :
        <FilterListIcon style={{ fill: props.accentColor }} />
        }
      </a>
      
      <FilterItems open={open} closeFilter={handleClose} props={props}/>
    </div>
  );
}

/*const CustomizedInputBase = function (props) {
  const { intl } = props;
  const [search, setSearch] = useState("");
  const searchManager = () => event => {
    if(event.target.value.length >= 2){
      setSearch({search: event.target.value });
      props.filterSearch(event.target.value, true);
    }else{
      props.filterSearch('', false);
    }
  }

  return (
    <Paper className="rootPaper">
      <InputBase
        className="inputBase"
        placeholder={intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        })}
        inputProps={{ 'aria-label': intl.formatMessage({
          id: "ECOMMERCE.COMMON.SEARCH"
        }) }}
        onChange={searchManager()}
      />
      <IconButton type="submit" className="iconButton" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}*/

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(FilterBar)
);