import React from "react";
import VisitOffline from '../../components/VisitOffline';
import PresentationLobby from '../../components/PresentationLobby';
import ReactInterval from 'react-interval';
import { connect } from "react-redux";
import * as loggerStorage from "../../store/ducks/logger.duck";
import {timerLog} from '../../../globalConfig';
                  
const PresentationPage = (props) => {
    return (
        <>
            <ReactInterval 
                timeout={timerLog} 
                enabled={true}
                callback={() => {props.flush(); props.flushChat()}} />
            <VisitOffline history={props.history}></VisitOffline>
        </>
    )
}   

export default connect(
    null,
    loggerStorage.actions
)(PresentationPage);