import React, { useMemo, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import CustomerTypeSelector from '../../components/CustomerTypeSelector/CustomerTypeSelector';
import CustomerList from '../../components/CustomerList/CustomerList';
import { FormattedMessage, injectIntl } from "react-intl";
import UserFilter from './components/UserFilter';
import UserList from "./components/UserList";
import LinearProgress from '@material-ui/core/LinearProgress';
import './User.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CreateUserButton from './components/CreateUserButton';
import {
  getDeploymentParamValue,
} from '../../../_metronic/utils/utils';

import * as userStorage from "./users.duck";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Users = (props) => {
  const globalState = useSelector(state => state);
  const [isCreateEnabled, setIsCreateEnabled] = React.useState(false);
  const handleCloseSnackBar = () => {
    props.setSaveUserResult(null);
    props.setErrorMessage("");
    props.resetNewUser();
  }
  useEffect(() => {
    setIsCreateEnabled(getDeploymentParamValue(globalState.auth.deployment.parameters, 'USER_CREATE_ENABLE'));

    return(() => {
      props.setSaveUserResult(null);
      props.setErrorMessage("");
      props.resetNewUser();
    })
  }, [])
  return (
    <div id="user" style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.users.loading && <LinearProgress className="progressBar" />}
      {globalState.users.loading && <div className="progressBackground"></div>}
      {globalState.users.resultSaveNewUser != null &&
      <Snackbar 
        open={(globalState.users.resultSaveNewUser !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.users.resultSaveNewUser) ? 'success' : 'error'}>
          {globalState.users.resultSaveNewUser ? 
            <FormattedMessage id="USER.CREATE.SUCCESS" />
            :
            (globalState.users.errorMessage ? globalState.users.errorMessage : <FormattedMessage id="USER.CREATE.ERROR" />)
          }
        </Alert>
      </Snackbar>
      }

      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="USER.TITLE" /></h3>
        </div>
        <div>
          {isCreateEnabled === '1' &&
          <CreateUserButton />
          }
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-xl-12 d-flex">
            <div>
              <UserFilter />
            </div>
          </div>
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <UserList />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    userStorage.actions
  )(Users)
);