import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import CheckIcon from '@material-ui/icons/Check';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Tooltip, withStyles, makeStyles, IconButton, ListItemIcon, Menu, MenuItem } from "@material-ui/core";

const LikeButtonMenu = (props) => {
  const [menuEl, setMenuEl] = React.useState(null);

  const handleClick = (event) => {
    setMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '.MuiListItemIcon-root': {
        color: '#0000008a !important'
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white !important',
        },
      },
    },
  }))(MenuItem);

  const useStyles = makeStyles((theme) => ({
    menuLabel: {
        marginLeft: '6px',
        marginRight: '6px'
    }
  }));

  const classes = useStyles();

  return (
    <div style={{display: 'inline', margin: '0 0'}}>
        
      <Tooltip disableFocusListener title={<FormattedMessage id="PRESENTATION.NAVBAR.LIKE" />}>
          { props.vote == 1 ?
              <IconButton edge="start" color="inherit" aria-label="next" onClick={handleClick}>
                  <ThumbUpIcon />
              </IconButton>
              :
              <IconButton edge="start" color="inherit" aria-label="next" onClick={() => { props.voteSlide(1) }}>
                  <ThumbUpOutlinedIcon />
              </IconButton>
          }
      </Tooltip>

      <StyledMenu id="like-button-menu" anchorEl={menuEl} keepMounted open={Boolean(menuEl)} onClose={handleClose}>

        <label className={classes.menuLabel}>{<FormattedMessage id="PRESENTATION.NAVBAR.DELETE_FEEDBACK" />}</label>

        <StyledMenuItem className="menuItem__buttons" onClick={ () => { props.voteSlide(0) } }>
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
          {<FormattedMessage id="PRESENTATION.NAVBAR.YES" />}
        </StyledMenuItem>

        <StyledMenuItem className="menuItem__buttons" onClick={handleClose}>
          <ListItemIcon>
            <CloseOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {<FormattedMessage id="PRESENTATION.NAVBAR.NO" />}
        </StyledMenuItem>

      </StyledMenu>
    </div>
  );
}

export default injectIntl(LikeButtonMenu);