export const configUrls = {
  base_url: process.env.REACT_APP_BASE_URL,
  web_url: process.env.REACT_APP_WEB_URL
}

export const defaultStyles = {
  background_color: process.env.REACT_APP_BACKGROUND_COLOR,
  accent_color: process.env.REACT_APP_ACCENT_COLOR
}

export const sentry_project = process.env.REACT_APP_SENTRY_PROJECT;

export const appInsightsKey = process.env.REACT_APP_INSIGHTS_KEY;

export const trackApp = process.env.REACT_APP_TRACK;

export const version = process.env.REACT_APP_VERSION;

export const lang = process.env.REACT_APP_LANG;

export const sentry_dsn = process.env.REACT_APP_SENTRY_DSN

export const timerLog = process.env.REACT_APP_TIMERLOG;

export const logoName = process.env.REACT_APP_LOGO_NAME;

export const appName = process.env.REACT_APP_NAME;

export const copyRight = process.env.REACT_APP_COPY_RIGHT;

export const home_bg = process.env.REACT_APP_BACKGROUND;

export const appNameCode = process.env.REACT_APP_NAME_CODE || 'default';

export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;

console.log('envfile', process.env.REACT_APP_ENVIRONMENT)
