import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { isMobile, isIPad13, isIPhone13, isTablet } from 'react-device-detect';

const ConversationContainer = ({
    open,
    closeConversationContainer,
    item
  }) => {
    const globalState = useSelector(state => state);
    const [insightsUrl, setInsightsUrl] = React.useState("");
    React.useEffect(() => {
      if(item){
        let properties = item.properties;
        let feedbackProps = globalState.presentationBar.feedback;
        if(feedbackProps && feedbackProps.SYMBL_INSIGHTS_URL){
          setInsightsUrl(feedbackProps.SYMBL_INSIGHTS_URL);
           
        }
      }
    }, []);

  const theme = useTheme();

  const handleClose = () => {
    closeConversationContainer();
  };


  return (
    <React.Fragment>
    
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={closeConversationContainer}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{item.name}</DialogTitle>
      
      <DialogContent >
  
        <iframe
            id="inlineFrame"
            title={item.name}
            src={insightsUrl}
            scrolling="yes"
            width='100%'
            height={!isIPad13 && !isTablet && (isMobile || isIPhone13) ? '150px' : '700px'}
            allow="autoplay; fullscreen">
        </iframe>
      
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
  );
}

export default connect(
    null,
    {...presentationBarStorage.actions, ...loggerStorage.actions}
)(ConversationContainer);