import React, {useEffect, useState} from 'react';
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from "react-intl";
import TagsInput from '../TagsInput';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { getDeploymentParamValue } from '../../../_metronic/utils/utils';
import './EditTagsPopUp.scss';

import * as visualAidStorage from "../../store/ducks/visualAid.duck";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: 280
  },
  label: {
    fontSize: 16,
    width: '30%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rowContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCheck: {
    fontSize: 16,
    fontWeight: 'lighter'
  }
}));

const EditTagsPopUp = (props) => {
  const globalState = useSelector(state => state);
  const [planName, setPlanName] = React.useState([]);
  const [arrTags, setArrTags] = React.useState([]);
  const [rawArrTags, setRawArrTags] = React.useState([]);
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [order, setOrder] = useState(0);
  const [type, setType] = useState(null);
  const [vaidResultKeeper, setVaidResultKeeper] = useState(null);
  const [openSnackError, setOpenSnackError] = useState(false);
  const [onlineVisit, setOnlineVisit] = useState(0);
  const [offlineVisit, setOfflineVisit] = useState(0);
  const classes = useStyles();
  
  const CALL_PRESENT_ENABLE = getDeploymentParamValue(globalState.auth.deployment.parameters, 'CALL_PRESENT_ENABLE');
  const showOfflineVisit = CALL_PRESENT_ENABLE === 1 || CALL_PRESENT_ENABLE === '1';
  
  const defaultCallType = getDeploymentParamValue(globalState.auth.deployment.parameters, 'VISUALAID_DEFAULT_CALLTYPE');
  
  const { intl } = props;

  useEffect(() => {
    // llamar por get a /visualaid/{visualaidCode}?edit=true
    props.getEditVisualAid(props.selected.code);
    return () => {
      props.fillResultVisualAidContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.visualAid.visualAidContainerResponse.tags){
      let tagGroup = globalState.visualAid.visualAidContainerResponse.tags.split(';');

      let arrTagsTemp = [];
      if(tagGroup.length){
        tagGroup.forEach((tagPar) => {
          let keyvalue = tagPar.split(':');
          
          if(keyvalue.length == 2){
            arrTagsTemp.push({key:keyvalue[0], value:keyvalue[1]});
          }
        });
        setArrTags([...arrTagsTemp, ...arrTags]);
      }
      setRawArrTags(globalState.visualAid.visualAidContainerResponse.tags);
    }
    if(globalState.visualAid.visualAidContainerResponse.order){
      setOrder(globalState.visualAid.visualAidContainerResponse.order);
    }
    if(globalState.visualAid.visualAidContainerResponse.validFrom){
      setValidFrom(globalState.visualAid.visualAidContainerResponse.validFrom);
    }
    if(globalState.visualAid.visualAidContainerResponse.validTo){
      setValidTo(globalState.visualAid.visualAidContainerResponse.validTo);
    }
    if(globalState.visualAid.visualAidContainerResponse.type){
      setType(globalState.visualAid.visualAidContainerResponse.type);
    }

    let callType = globalState.visualAid.visualAidContainerResponse.call_type || defaultCallType;
    switch (callType) {
      case 1: // Online
        setOnlineVisit(1);
        break;
      case 2: // Offline
        setOfflineVisit(1);
        break;
      case 3: // Las dos
        setOnlineVisit(1);
        setOfflineVisit(1);
        break;
      default:
        break;
    }
  }, [globalState.visualAid.visualAidContainerResponse]);

  useEffect(() => {
    if(globalState.visualAid.editVaidResult != null){
      props.getEditVisualAid(props.selected.code);
      setVaidResultKeeper(true);
    }
    if(
      globalState.visualAid.editVaidResult == null &&
      vaidResultKeeper == true
    ){
      props.setEditVaidResult(null);
      setVaidResultKeeper(false);
      handleClose();  
    }
  }, [globalState.visualAid.editVaidResult])

  const handleClose = () => {
    props.setDisplayDialog(false);
    props.fillResultVisualAidContainer({});

  }

  const handleChangeArrTags = (input) => {
    setArrTags(input);
    
    if(input.length){
      let arrKeyValue = [];
      input.forEach((item) => {
        arrKeyValue.push(`${item.key}:${item.value}`);
      });
      let strtags = arrKeyValue.join(';');
      setRawArrTags(strtags);
    }else{
      setRawArrTags('');
    }
  }

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
  }

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  }

  const handleChangeOnlineVisit = (event) => {
    setOnlineVisit(event.target.checked);
  }

  const handleChangeOfflineVisit = (event) => {
    setOfflineVisit(event.target.checked);
  }

  const checkCallType = () => {
    let callType = 0;

    if(onlineVisit && offlineVisit) {
      callType = 3;
    } else if(onlineVisit) {
      callType = 1;
    } else if(offlineVisit) {
      callType = 2;
    }
    
    return callType;
  }

  const saveChanges = () => {
    if(validateDates()){
      props.saveVaEdition({
        code: props.selected.code,
        name: globalState.visualAid.visualAidContainerResponse.name,
        validFrom: ( validFrom ) ? validFrom : new Date(),
        validTo: ( validTo ) ? validTo : new Date(),
        tags: (rawArrTags && rawArrTags.length) ? rawArrTags : "",
        call_type: checkCallType(),
        order
      });
    }else{
      setOpenSnackError(true);
    }
  }

  const handlePreview = () => {
    window.open(`/visualAids/preview/${props.selected.code}`);
  }

  const validateDates = () => {
    let fromDate = moment(validFrom);
    let toDate = moment(validTo);
    return(toDate.diff(fromDate) > 0);
  }

  return (
    <div style={{width: '100%'}}>
      <Dialog
        open={props.displayDialog}
        onClose={handleClose}
        className="edit-tags-popup__container"
      >
        <Snackbar
          open={(openSnackError)}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={1500}
          onClose={() => { setOpenSnackError(false) }}
        >
          <Alert severity='error'>
            <FormattedMessage id="POPUP_EDIT_VA.ERROR_DATES" />
          </Alert>
        </Snackbar>
        <DialogTitle>{props.selected.name}</DialogTitle>
          
        <DialogContent className="schedule__modifier__popup__content">
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.CODE" />: </span>
            <span className={classes.data}>
              {props.selected.code}
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.TYPE" />: </span>
            <span className={classes.data}>
              {props.selected.type}
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.VALID_FROM" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                id="datetime-local"
                type="datetime-local"
                value={validFrom}
                onChange={handleChangeDateFrom}
              />
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.VALID_TO" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                id="datetime-local"
                type="datetime-local"
                value={validTo}
                onChange={handleChangeDateTo}
              />
            </span>
          </div>
          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.ORDER" />: </span>
            <span className={classes.data}>
              <TextField
                className={classes.inputText}
                value={order}
                onChange={handleChangeOrder}
              />
            </span>
          </div>

          <div className={classes.rowContainer}>
            <FormControlLabel
              value={onlineVisit}
              control={
                <Checkbox 
                icon={icon}
                checkedIcon={checkedIcon} 
                checked={onlineVisit}
                onChange={handleChangeOnlineVisit} 
                />
              }
              label={
                <Typography className={classes.labelCheck}>
                  <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.ONLINE" />:
                </Typography>
              }
              labelPlacement="start"
            />
            
            {
              showOfflineVisit &&
              <FormControlLabel
                value={offlineVisit}
                control={
                  <Checkbox 
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={offlineVisit}
                  onChange={handleChangeOfflineVisit} 
                  />
                }
                label={
                  <Typography className={classes.labelCheck}>
                    <FormattedMessage id="DASHBOARD.NEW.VISIT.SELECT.TYPE.PRESENTATION" />:
                  </Typography>
                }
                labelPlacement="start"
              />
            }
          </div>

          <div className={classes.inRow}>
            <span id="status-label" className={classes.label}><FormattedMessage id="POPUP_EDIT_VA.TAGS" />: </span>
            <span className={classes.data}>
              <TagsInput 
              setArrTagsGlobal={handleChangeArrTags}
              tagsItems={arrTags}
              tagOptions={globalState.visualAid.tagsOptions}
            />
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="btnEditPreview" onClick={handlePreview} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.PREVIEW" />
          </Button>
          <Button id="btnEditAccept" onClick={saveChanges} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.ACCEPT" />
          </Button>
          <Button id="btnEditCancel" onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="POPUP_EDIT_VA.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(EditTagsPopUp)
);