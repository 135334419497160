import React from 'react';
import { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import VisualAidTransferList from './VisualAidTransferList';
import FilesTransferList from './FilesTransferList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

import Autocomplete from '@material-ui/lab/Autocomplete';

import * as PromotionalPlansStorage from "../../store/ducks/promotionalPlans.duck";
import { id } from 'date-fns/locale';
//import { OperationCanceledException } from 'typescript';

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: '50%',
    maxWidth:'100%'
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rootTags: {
    minWidth: '50%',
    maxWidth: '100%',
    paddingLeft: 18,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HeaderPromotionalPlans = (props) => {

//
const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [name, setName] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [validFrom, setValidFrom] = useState(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
  const [validTo, setValidTo] = useState(moment(new Date()).add(30, 'days').format('YYYY-MM-DDTHH:mm:ss'));
  const [userGroups, setUserGroups] = React.useState([]);
  const [customerGroups, setCustomerGroups] = React.useState([]);
  const [visualAids, setVisualAids] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  useEffect(() => {
      props.fillNewPromotionalPlanObject({validFrom:validFrom});
      props.fillNewPromotionalPlanObject({validTo:validTo});
      props.getUserGroups();
      props.getCustomerGroups();
      props.getVisualAids();
      props.getFiles();
      
      if(props.edit === true){
        props.getOnePromotionalPlan(props.uuid);
        props.getProducts(globalState.promotionalPlans.planCodeSelected);
      }else{
        props.getProducts('-1');
      }
  }, []);

  useEffect(() => {
    if(props.edit == true && 
      globalState.promotionalPlans.resultGetOnePromotionalPlan === true &&
      globalState.promotionalPlans.promotionalPlansResult === true &&
      globalState.promotionalPlans.userGroupsResult === true &&
      globalState.promotionalPlans.customerGroupsResult === true &&
      globalState.promotionalPlans.visualAidsResult === true &&
      globalState.promotionalPlans.filesResult === true &&
      globalState.promotionalPlans.productsResult === true
    ){
      setName(globalState.promotionalPlans.newPromotionalPlan.name);
      setExternalCode(globalState.promotionalPlans.newPromotionalPlan.externalCode);
      setValidFrom(globalState.promotionalPlans.newPromotionalPlan.validFrom);
      setValidTo(globalState.promotionalPlans.newPromotionalPlan.validTo);
      setUserGroups(globalState.promotionalPlans.newPromotionalPlan.userGroups);
      setCustomerGroups(globalState.promotionalPlans.newPromotionalPlan.customerGroups);

      if(globalState.promotionalPlans.newPromotionalPlan.visualAids.length > 0){
        let visualAidsArr = [];
        
        globalState.promotionalPlans.newPromotionalPlan.visualAids.forEach((visualAid) => {
          visualAidsArr.push(globalState.promotionalPlans.visualAids.filter((item) => item.code === visualAid)[0])
        })
        
        handleChangeVisualAid(null, visualAidsArr);
      }
      if(globalState.promotionalPlans.newPromotionalPlan.files.length > 0){
        let filesArr = [];
        
        globalState.promotionalPlans.newPromotionalPlan.files.forEach((file) => {
          filesArr.push(globalState.promotionalPlans.files.filter((item) => item.code === file)[0])
        })
        
        handleChangeFile(null, filesArr);
      }
      
      if(globalState.promotionalPlans.newPromotionalPlan.products.length > 0){
        let productsArr = [];
        
        globalState.promotionalPlans.newPromotionalPlan.products.forEach((product) => {
          productsArr.push(globalState.promotionalPlans.products.filter((item) => item.code === product)[0])
        })
        
        handleChangeProduct(null, productsArr);
      }
      
      if(globalState.promotionalPlans.newPromotionalPlan.customerGroups.length > 0){
        let groupsArr = [];
        globalState.promotionalPlans.newPromotionalPlan.customerGroups.forEach((group) => {
          groupsArr.push(globalState.promotionalPlans.customerGroups.filter((item) => item.uuid === group)[0])
        })
        handleChangecustomerGroups(groupsArr);
      }
      if(globalState.promotionalPlans.newPromotionalPlan.userGroups.length > 0){
        let userGroupsArr = [];
        globalState.promotionalPlans.newPromotionalPlan.userGroups.forEach((userGroup) => {
          userGroupsArr.push(globalState.promotionalPlans.userGroups.filter((item) => item.uuid === userGroup)[0])
        })
        handleChangeUserGroups(userGroupsArr);
      }
      
    }
  },[
    globalState.promotionalPlans.resultGetOnePromotionalPlan,
    globalState.promotionalPlans.promotionalPlansResult,
    globalState.promotionalPlans.userGroupsResult,
    globalState.promotionalPlans.customerGroupsResult,
    globalState.promotionalPlans.visualAidsResult,
    globalState.promotionalPlans.filesResult,
    globalState.promotionalPlans.productsResult,
  ])

  const handleChangeName = (event) => {
    setName(event.target.value);
    props.fillNewPromotionalPlanObject({name:event.target.value});
  }

  const handleExternalCode = (event) => {
    setExternalCode(event.target.value);
    props.fillNewPromotionalPlanObject({externalCode:event.target.value})
  } 

  const handleChangeDateFrom = (event) => {
    setValidFrom(event.target.value)
    props.fillNewPromotionalPlanObject({validFrom:event.target.value})
  }

  const handleChangeDateTo = (event) => {
    setValidTo(event.target.value)
    props.fillNewPromotionalPlanObject({validTo:event.target.value})
  }

  const handleChangeUserGroups = (event, values) => {
    let val = (Array.isArray(event)) ? event : values;
    setUserGroups(val);
    props.fillNewPromotionalPlanObject({userGroups:val})
  };

  const handleChangecustomerGroups = (event, values) => {
    let val = (Array.isArray(event)) ? event : values;
    setCustomerGroups(val);
    props.fillNewPromotionalPlanObject({customerGroups:val})
  };

  const handleChangeVisualAid = (event, values) => {
    
    setVisualAids(values);
    props.fillNewPromotionalPlanObject({visualAids:values})
  }

  const handleChangeFile = (event, values) => {
    setFiles(values);
    props.fillNewPromotionalPlanObject({files:values})
  }
  
  const handleChangeProduct = (event, values) => {
    setProducts(values);
    props.fillNewPromotionalPlanObject({products:values})
  }

  let labelPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.PRESENTATION"
  });
  let placeHolderPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.PRESENTATION"
  });
  let labelFiles = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.FILES"
  });
  let placeholderFiles = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.FILES"
  });
  let labelProducts = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.PRODUCTS"
  });
  let placeholderProducts = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.PRODUCTS"
  });
//

  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.NAME" />: </span>
        <span className={classes.data}>
          <TextField
            required
            error={globalState.promotionalPlans.errorValidation === 'name' && !name}
            className={classes.inputText}
            inputProps={{ maxLength: 100}}
            value={name}
            onChange={handleChangeName}
            inputProps={{ maxLength: 100}}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.EXTERNAL_CODE" />: </span>
        <span className={classes.data}>
          <TextField
            error={globalState.promotionalPlans.errorValidation === 'externalCode' && !externalCode}
            className={classes.inputText}
            value={externalCode}
            onChange={handleExternalCode}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_FROM" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.promotionalPlans.errorValidation === 'validFrom' || globalState.promotionalPlans.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validFrom}
            onChange={handleChangeDateFrom}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="VAID.EDIT.FORM.VALID_TO" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.promotionalPlans.errorValidation === 'validTo' || globalState.promotionalPlans.errorValidation === 'datesDifference'}
            id="datetime-local"
            type="datetime-local"
            value={validTo}
            onChange={handleChangeDateTo}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PROMOTIONAL_PLANS.FORM.EDIT.USER_GROUPS" />: </span>
        <span className={classes.data}>
        <Autocomplete
            multiple
            id="tags-standard-user-groups"
            options={globalState.promotionalPlans.userGroups}
            onChange={handleChangeUserGroups}
            value={userGroups}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelPresentations}
                placeholder={placeHolderPresentations}
              />
            )}
          />
        </span>
      </div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PROMOTIONAL_PLANS.FORM.EDIT.CUSTOMER_GROUPS" />: </span>
        <span className={classes.data}>
          <Autocomplete
            multiple
            id="tags-standard-customer-groups"
            options={globalState.promotionalPlans.customerGroups}
            onChange={handleChangecustomerGroups}
            value={customerGroups}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelPresentations}
                placeholder={placeHolderPresentations}
              />
            )}
          />
        </span>
      </div>
      <div id="visualAidTransferListContainer" className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PROMOTIONAL_PLANS.FORM.EDIT.VISUAL_AIDS" />: </span>
        <span className={classes.data, classes.rootTags}>
          <Autocomplete
            multiple
            id="tags-standard-visual-aids"
            options={globalState.promotionalPlans.visualAids}
            onChange={handleChangeVisualAid}
            value={visualAids}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelPresentations}
                placeholder={placeHolderPresentations}
              />
            )}
          />
        </span>
      </div>
      <div id="filesTransferListContainer" className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PROMOTIONAL_PLANS.FORM.EDIT.FILES" />: </span>
        <span className={classes.data, classes.rootTags}>
          <Autocomplete
            multiple
            id="tags-standard2"
            options={globalState.promotionalPlans.files}
            onChange={handleChangeFile}
            value={files}
            getOptionLabel={(optionFiles) => optionFiles.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelFiles}
                placeholder={placeholderFiles}
              />
            )}
          />
        </span>
      </div>
      <div id="productsTransferListContainer" className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="PROMOTIONAL_PLANS.FORM.EDIT.PRODUCTS" />: </span>
        <span className={classes.data, classes.rootTags}>
          <Autocomplete
            multiple
            id="tags-standard2"
            options={globalState.promotionalPlans.products}
            onChange={handleChangeProduct}
            value={products}
            getOptionLabel={(optionProducts) => optionProducts.name}
            disableCloseOnSelect={true}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={labelProducts}
                placeholder={placeholderProducts}
              />
            )}
          />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    PromotionalPlansStorage.actions
  )(HeaderPromotionalPlans)
);