/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import DefaultError from "../pages/errors/DefaultError";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import AuthDirect from "../pages/auth/AuthDirect";
import AuthDirectPresentation from "../pages/auth/AuthDirectPresentation";
import Dashboard from '../pages/home/Dashboard';
import Calendar from '../components/Calendar/Calendar';
import Customers from '../pages/home/Customers';
import Users from '../modules/Users/Users';
import UserCreatePage from '../modules/Users/UserCreatePage';
import UserGroupsPage from '../pages/home/UserGroupsPage';
import CustomerGroupsPage from '../pages/home/CustomerGroupsPage';
import ReportPBIPage from '../pages/home/ReportPBIPage';
import VisualAidsPage from "../pages/home/VisualAidsPage";
import VisualAidsCreatePage from "../pages/home/VisualAidsCreatePage";
import PromotionalPlansPage from "../pages/home/PromotionalPlansPage";
import PromotionalPlansCreatePage from "../pages/home/PromotionalPlansCreatePage";
import ImportCustomersPage from '../pages/home/ImportCustomersPage';
import ImportCustomersCreatePage from '../pages/home/ImportCustomersCreatePage';
import FilePage from "../modules/Files/FilePage";
import FileCreatePage from "../modules/Files/FileCreatePage";
import ProductPage from "../modules/Products/ProductsPage";
import ProductCreatePage from "../modules/Products/ProductsCreatePage";
import VideoPlayerPage from "../pages/home/VideoPlayerPage";
import PreviewVAPage from "../pages/home/PreviewVAPage";
import FoldersPage from "../pages/home/FoldersPage";
import AuthDirectPresentationGrid from "../pages/auth/AuthDirectPresentationGrid";
import Home from "../pages/home/Home";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, lang } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.deployment != null,
      userLastLocation: routerHelpers.getLastLocation(),
      role: auth.role,
      lang: auth.lang
    }),
    shallowEqual
  );

  let token = new URLSearchParams(history.location.search).get("token");
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {/* Redirect from the root path to /home */}
        <Route path="/" exact={true}>
          <Redirect to="/home"/>
        </Route>

        <Route path="/error" exact={true}>
          <DefaultError />
        </Route>

       {!isAuthorized && 
          <Route path="/call/:callcode/:userid">
            <AuthDirect token={token}/>
          </Route>
        }

        {!isAuthorized && 
          <Route path="/presentation2/:callcode/:userid">
            <AuthDirectPresentation token={token}/>
          </Route>
        }

        {!isAuthorized && 
          <Route path="/presentation/:callcode/:userid">
            <AuthDirectPresentationGrid token={token}/>
          </Route>
        }

        {/* Nueva ruta para manejar URLs acortadas */}
        {!isAuthorized && 
          <Route path="/p/:callcode">
            <AuthDirectPresentationGrid token={token}/>
          </Route>
        }
        
        {/*!isAuthorized &&
          <Route path="/call/:callcode/:userid">
            <AuthDirect />
          </Route>
        */}

        {!isAuthorized &&
          <Route path="/dashboard" exact={true}>
            <Redirect to="/auth/registration" />
          </Route>
        }
        
        {!isAuthorized &&
          <Route path="/home" exact={true}>
            <Redirect to="/auth/registration" />
          </Route>
        }

        {!isAuthorized &&
          <Route path="/customers" exact={true}>
            <Redirect to="/auth/registration" />
          </Route>
        }
        
        {!isAuthorized &&
          <Route path="/video/player/:callcode/:archiveId" exact={true}>
            <VideoPlayerPage history={history}/>
          </Route>
        }
        
        {isAuthorized && 
          <Redirect from="/auth/login" exact={true} to="/call" />
        }

        {isAuthorized && //tal vez aca necesite otra validacion de rol o algo asi
          <Route path="/visualAids/preview/:code" exact={true} >
            <PreviewVAPage />
          </Route>
        }

        {isAuthorized &&
          <Route path="/dashboard" exact={true} key={window.location.pathname}>
            <Layout asideLeftDisplay={true} lang={lang} center={false} history={history}>
              <Dashboard />
            </Layout>
          </Route>
        }

        {isAuthorized &&
          <Route path="/home" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} center={false} history={history}>
              <Home />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/folderlist" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <FoldersPage />
            </Layout>
          </Route>
        }

        {isAuthorized &&
          <Route path="/calendar" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} center={false} history={history}>
              <Calendar />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/customers" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <Customers />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/users" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <Users />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/user/create" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <UserCreatePage />
            </Layout>
          </Route>
        }
        {isAuthorized && 
          <Route path="/user/update/:uuid" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <UserCreatePage edit={true} />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/visualaids" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <VisualAidsPage />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/promotionalplans" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <PromotionalPlansPage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/promotionalplans/create" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <PromotionalPlansCreatePage />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/promotionalplans/update/:uuid" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <PromotionalPlansCreatePage edit={true} />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/usergroups" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <UserGroupsPage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/customergroups" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <CustomerGroupsPage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/importlist" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <ImportCustomersPage />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/importlist/create/:entitytype" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <ImportCustomersCreatePage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/visualaid/slide/update/:code" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <VisualAidsCreatePage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/visualaid/sdva/update/:code" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <VisualAidsCreatePage />
            </Layout>
          </Route>
        }

        {isAuthorized &&
          <Route path="/reports/pbiembedded/:guid" exact={true}>
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <ReportPBIPage history={history} />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/visualaid/html5animate/update/:code" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <VisualAidsCreatePage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/visualaid/video/update/:code" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <VisualAidsCreatePage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/files" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <FilePage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/files/update/:filecode" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <FileCreatePage />
            </Layout>
          </Route>
        }
        
        {isAuthorized && 
          <Route path="/products" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <ProductPage />
            </Layout>
          </Route>
        }

        {isAuthorized && 
          <Route path="/products/update/:productcode" exact={true} >
            <Layout asideLeftDisplay={true} lang={lang} history={history}>
              <ProductCreatePage />
            </Layout>
          </Route>
        } 
        
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        
        <Route path="/logout" component={LogoutPage} />
          
          
        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/registration" />
        ) : (
          <Layout lang={lang} asideLeftDisplay={false} center={true} history={history}>
            <HomePage userLastLocation={userLastLocation} history={history} />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
