import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import MaterialTable from 'material-table';
import moment from 'moment';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import * as scheduleStorage from "../../store/ducks/schedule.duck";

const useStyles = makeStyles(theme => ({
  dialogtop: {
    width: 600,
    height: 310
  },
  paper: {
    position: 'absolute',
    width: 400,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataFullWidth: {
    width: '70%'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  }
}));


const ArchivePopUp  = (props) => {
  const classes = useStyles();
  const globalState = useSelector(state => state);
  const {intl} = props;

  useEffect(() => {
    if(globalState.schedule.callSelected.code){
      props.getArchivesOfItem(globalState.schedule.callSelected.code);
    }
  }, [globalState.schedule.callSelected]);

  useEffect(() => {
    if(globalState.schedule.archiveToken && globalState.schedule.rowArchiveId){
      window.open(`/video/player/${globalState.schedule.callSelected.code}/${globalState.schedule.rowArchiveId}?token=${globalState.schedule.archiveToken}&lang=${globalState.auth.lang}`, "_blank");
      props.resetArchiveVideos();
    }
  }, [globalState.schedule.archiveToken])

  const handleClose = () => {
    props.setArchivesPopUpOpen(false);
  }
  let actionsColumnIndex = 0;
  let columns = [
    { title: intl.formatMessage({id: "ARCHIVE.TABLE.IDENTIFICATION"}), field: 'id',cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} },
    { 
      title: intl.formatMessage({id: "ARCHIVE.TABLE.MODIFIED_DATE"}), 
      field: 'modifiedAtUTC',
      cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{},
      render: rowData => moment(rowData.modifiedAtUTC).add(globalState.auth.timezone, 'hours').format("YYYY-MM-DD HH:mm:ss")
    },
    { title: intl.formatMessage({id: "ARCHIVE.TABLE.SIZE_READABLE"}), field: 'sizeReadable',cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} },
  ];
  /*
    a) Archive_Id
    b) Modified_date (mostrar Date convertido al timezone del usuario)
    c) Duracion
    d) Tamaño (sizeReadable)
    e) Boton VER (EL BOTON VER SOLO SI STATUS= 'uploaded')
  */
  return(
    <>
    <Dialog
        open={globalState.schedule.archivesPopUpOpen}
        onClose={handleClose}
        id="popup"
      >
        <DialogTitle id="form-dialog-title">
          {globalState.schedule.callSelected.code}
        </DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div>
            <div style={{marginLeft:15}}>
              <span>
                <FormattedMessage id="ARCHIVE.DETAILS.DATE" /> : 
              </span>
              <b>
                {moment(globalState.schedule.callSelected.duedateUTC).add(globalState.auth.timezone, 'hours').format("YYYY-MM-DD HH:mm:ss")}
              </b>
            </div>
            <MaterialTable
              columns={columns}
              title=""
              data={globalState.schedule.archives}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      height: "0px",
                    }}
                  >
                  </div>
                ),
              }}
              options={
                { 
                  actionsColumnIndex: actionsColumnIndex, 
                  pageSize: 10, 
                  searchFieldAlignment: 'left',
                  headerStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} 
                }
              }
              actions={[
                (rowData) => {
                  return rowData.status === 'uploaded' && rowData.sizeReadable != '0 B'
                    ? {
                        icon: PlayCircleFilledIcon, //ponerle EventAvailable
                        tooltip: intl.formatMessage({
                          id: "ARCHIVE.DETAILS.LINK"
                        }),
                        onClick: async(event, rowData) => {
                          props.getPlayerLink(rowData.id);
                          // TODO: Obtener el enlace del video y Abrir una nueva pestania 
                          // la ruta es de la aplicacion y levanta solo un iframe con el video embedded
                        }
                      }
                    :
                    null
                }
                
              ]}
              localization={{
                header: {
                  actions: ''
                },
                toolbar: {
                  searchPlaceholder: intl.formatMessage({
                    id: "ECOMMERCE.COMMON.SEARCH"
                  })
                },
                body: {
                  emptyDataSourceMessage: intl.formatMessage({
                    id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
                  })
                },
                pagination: {
                  labelDisplayedRows: intl.formatMessage({
                    id: "MATERIAL_TABLE.PAGINATION.TEXT"
                  }, {
                    from: '{from}',
                    to: '{to}',
                    count: '{count}'
                  }),
                  labelRowsSelect: intl.formatMessage({
                    id: "MATERIAL_TABLE.PAGINATION.LABEL"
                  })
                },
                
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={handleClose}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    scheduleStorage.actions
  )(ArchivePopUp)
);