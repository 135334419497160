import axios from "axios";
import {configUrls} from '../../globalConfig';


export function log(url, body) {
  var request = new XMLHttpRequest();
  request.open('PUT', url, false);
  request.send(body);
  if(request.readyState === 4) {
    if(request.status === 200 || request.status === 201) { 
      return request.responseText;
    } else {
      return 'An error occurred during your request: ' +  request.status + ' ' + request.statusText;
    } 
  }
  // TODO: refactorear esto con un fetch pasando el PUT
}

export function chatLog(url, body) {
  var request = new XMLHttpRequest();
  request.open('PUT', url, false);
  request.send(body);
  if(request.readyState === 4) {
    if(request.status === 200 || request.status === 201) { 
      return request.responseText;
    } else {
      return 'An error occurred during your request: ' +  request.status + ' ' + request.statusText;
    } 
  }
  // TODO: refactorear usando fetch pasando PUT
}
