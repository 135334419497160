import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import Folders from '../../components/Folders/Folders'
import { FormattedMessage, injectIntl } from 'react-intl';
import CreateFolderButton from '../../components/Folders/CreateFolderButton';
import DeleteFolderButton from '../../components/Folders/DeleteFolderButton';
import EditFolderButton from '../../components/Folders/EditFolder.Button';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as folderStorage from "../../store/ducks/folders.duck";
import EditTagsPopUp from '../../modules/Files/components/EditTagsPopUp';
import Dropzone from '../../components/Folders/Dropzone/Dropzone';

const FoldersPage = (props) => {
    const globalState = useSelector(state => state);
    const [error, setError] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        return () => {
          props.setSelectedFolder({});
          props.setSelectedFile({});
        };
    }, []); 

    useEffect(() => {
        if(globalState.folders.inactivateFolderResult !== null) {
            setError(!globalState.folders.inactivateFolderResult);
            setTimeout(() => {
                setError(false);
                props.setInactivateFolderResult(null);
            }, 7000)
        }
    }, [globalState.folders.inactivateFolderResult]);

    useEffect(() => {
      if(Object.keys(globalState.folders.fileSelected).length > 0) {
        setSelected(globalState.folders.fileSelected);
        setDisplayDialog(true);
      }
    }, [globalState.folders.fileSelected])

    useEffect(() => {
        if(!displayDialog) {
            props.setSelectedFile({});
        }
    }, [displayDialog])
    

    return (
        <>
            {displayDialog &&
                <EditTagsPopUp displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} selected={selected} />
            }
            
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
                    {globalState.folders.loading && <LinearProgress className="progressBar" />}
                    {globalState.folders.loading && <div className="progressBackground"></div>}
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title"><FormattedMessage id="FOLDERS.TITLE" /></h3>
                        </div>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <DeleteFolderButton />
                            <EditFolderButton />
                            <CreateFolderButton />
                        </div> 
                    </div>
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 d-flex justify-content-left" style={{ width: '100%'}}>
                                <Folders />
                            </div>
                            <div className="col-xl-6 col-md-6 d-flex justify-content-left">
                                {Object.entries(globalState.folders.folderSelected).length !== 0 &&
                                    <Dropzone/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error &&
                <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%', marginBottom: 10 }}>
                    <Collapse in={error} timeout={'auto'}>
                        <Alert severity="error"><FormattedMessage id="FOLDERS.CANNOT_DELETE" /> - <FormattedMessage id="FOLDERS.DELETE_ERROR" /> </Alert>
                    </Collapse>
                </div>
                
            }
        </>
    );
}

export default injectIntl(
    connect(
      null,
      folderStorage.actions
    )(FoldersPage)
);