import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { getDeploymentByToken, getCallDataByToken, getMenu, getReportById, getTerms, getUrlToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import firebase from 'firebase/app';
import 'firebase/analytics';
import { REHYDRATE } from 'redux-persist';
import {
  getDeploymentParamValue,trackException
} from '../../components/VideoChat/utils';
import * as Sentry from '@sentry/browser';
import { appInsights } from '../../../TelemetryService';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { trackApp } from '../../../globalConfig';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  LogoutWithStorage: "[LogoutWithStorage] Action",
  Register: "[Register] Action",
  DeploymentRequested: "[Request Deployment] Action",
  DeploymentLoaded: "[Load Deployment] Auth API",
  CallDataRequested: "[Request CallData] Action",
  CallDataLoaded: "[Load CallData] Action",
  PUBLISH_ANALYTICS_OBJECT: "PUBLISH_ANALYTICS_OBJECT",
  ANALYTICS_LOG_EVENT: "ANALYTICS_LOG_EVENT",
  FILL_USER_DATA: "FILL_USER_DATA",
  NULL_AUTH_TOKEN: "NULL_AUTH_TOKEN",
  GET_MENU: "GET_MENU",
  SET_MENU: "SET_MENU",
  GET_REPORT: "GET_REPORT",
  SET_REPORT: "SET_REPORT",
  SET_TERMS: "SET_TERMS",
  SET_ISREPORT_LOADING: "SET_ISREPORT_LOADING",
  GET_URL_TOKEN: "GET_URL_TOKEN",
  SET_URL_TOKEN: "SET_URL_TOKEN",
  SET_URL_TOKEN_RESULT: "SET_URL_TOKEN_RESULT",
};

const initialAuthState = {
  deployment: undefined,
  authToken: undefined,
  analytics: null,
  menu: undefined,
  report: undefined,
  terms: undefined,
  isReportLoading: true,
  urlToken: undefined,
  urlTokenResult: false,
  selectedCallCode: undefined,
  termsAccepted: false
};


export const reducer = persistReducer(
    { storage, key: "demo1-auth", whitelist: [] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          var { authToken, callCode, deployment, role, userid, timezone, expires, lang, name, profileName, identification, urlToken } = action.payload;
          return { 
            authToken, 
            deploymentCode: deployment,
            callCode,
            role,
            userid,
            timezone,
            expires,
            lang, 
            name, 
            profileName,
            identification,
            urlToken
          };
        }

        case actionTypes.Register: {
          var { authToken, email, deployment, role, userid, timezone, expires, lang, name, profileName, identification } = action.payload;
          return { 
            ...state,
            authToken, 
            deploymentCode: deployment,
            email,
            role, 
            userid,
            timezone,
            expires,
            lang, 
            name, 
            profileName,
            identification
          };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }
        
        case actionTypes.LogoutWithStorage: {
          routerHelpers.forgotLastLocation();
          if (typeof(localStorage) !== "undefined") {  
            try{
              localStorage.removeItem('auth');
              localStorage.removeItem('persist:build-demo1');
              localStorage.removeItem('persist:schedule');
            }catch(e){
              console.log(e);
            }
          }
          return initialAuthState;
        }

        case actionTypes.DeploymentLoaded: {
          var { deployment } = action.payload;
          var code = deployment.code.toString();
          deployment.code = code;
          return { ...state, deployment };
        }

        case actionTypes.PUBLISH_ANALYTICS_OBJECT:
          
          const fireBaseConfig = {
            apiKey: process.env.REACT_APP_apiKey,
            authDomain: process.env.REACT_APP_authDomain,
            databaseURL: process.env.REACT_APP_databaseURL,
            projectId: process.env.REACT_APP_projectId,
            storageBucket: process.env.REACT_APP_storageBucket,
            messagingSenderId: process.env.REACT_APP_messagingSenderId,
            appId: process.env.REACT_APP_appId,
            appVersion: process.env.REACT_APP_VERSION || 0,
            measurementId: process.env.REACT_APP_measurementId
          }
          if(firebase && firebase.apps && firebase.apps.length){
            firebase.app();
          }else{
            firebase.initializeApp(fireBaseConfig);
          }
          const analytics = firebase.analytics();
          //analytics.logEvent('logged');
          analytics.setUserProperties({
            deployment: state.deploymentCode || 0, 
            codcall: state.callCode || 0
          });
          analytics.setUserId((state.userid)?state.userid:0);

          return Object.assign({}, state, { ...state, analytics: analytics })

        case actionTypes.FILL_USER_DATA:
          return {
            ...state, 
            email :action.payload.email,
            role: action.payload.role, 
            userid: action.payload.userid,
            timezone: action.payload.timezone,
            expires: action.payload.expires, 
            lang: action.payload.lang,
            name: action.payload.name
          }
        
        case actionTypes.SET_MENU:
          return Object.assign({}, state, {...state, menu: action.payload});

        case actionTypes.SET_ISREPORT_LOADING:
            return Object.assign({}, state, {isReportLoading: true});

        case actionTypes.SET_REPORT:
          return Object.assign({}, state, {...state, report: action.payload, isReportLoading: false});

        case actionTypes.SET_TERMS:
          return Object.assign({}, state, {...state, terms: action.payload});
          
        case actionTypes.SET_URL_TOKEN:
          return Object.assign({}, state, {...state, urlToken: action.payload, urlTokenResult: true, selectedCallCode: action.payload.urlToken.callcode});
        
        case actionTypes.SET_URL_TOKEN_RESULT:
            return Object.assign({}, state, {...state, urlTokenResult: action.payload});
        case actionTypes.NULL_AUTH_TOKEN:
          return {
            ...state, 
            authToken: undefined, 
            deployment: undefined            
          }
        default:
          return state;
      }
    }
);

export const actions = {
  login: (authToken, callCode, deployment, role, userid, timezone, expires, lang, name, profileName,identification, urlToken) => ({ type: actionTypes.Login, payload: { callCode: callCode, deployment: deployment, authToken: authToken, role: role, userid: userid, timezone: timezone, expires, lang, name:name, profileName:profileName, identification:identification, urlToken: urlToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  logoutWithStorage: () => ({ type: actionTypes.LogoutWithStorage }),
  register: (authToken, email, deployment, role, userid, timezone, expires, lang, name, profileName, identification) => ({ type: actionTypes.Register, payload: { email: email, deployment: deployment, authToken: authToken, role: role, userid: userid, timezone: timezone, expires: expires, lang: lang, name:name, profileName:profileName, identification:identification } }),
  requestDeployment: deployment => ({ type: actionTypes.DeploymentRequested, payload: { deployment } }),
  fulfillDeployment: deployment => ({ type: actionTypes.DeploymentLoaded, payload: { deployment } }),
  requestCallData: (callCode) => ({ type: actionTypes.CallDataRequested, payload: {callCode: callCode}}),
  publishAnalyticsObject:(value) => ({type: actionTypes.PUBLISH_ANALYTICS_OBJECT, payload: value}),
  analyticsLogEvent:(value) => ({type: actionTypes.ANALYTICS_LOG_EVENT, payload: value}),
  fillUserData:(value) => ({type: actionTypes.FILL_USER_DATA, payload: value}),
  nullAuthToken:(value) => ({type: actionTypes.NULL_AUTH_TOKEN, payload: value}),
  getMenuAside: () => ({ type: actionTypes.GET_MENU}),
  setMenu: menu => ({type: actionTypes.SET_MENU, payload: {menu}}),
  getReport: (value) => ({ type: actionTypes.GET_REPORT, payload: value}),
  setReport: report => ({type: actionTypes.SET_REPORT, payload: {report}}),
  setTerms: terms => ({type: actionTypes.SET_TERMS, payload: {terms}}),
  getUrlToken: (value) => ({ type: actionTypes.GET_URL_TOKEN, payload: value}),
  setUrlToken: urlToken => ({type: actionTypes.SET_URL_TOKEN, payload: {urlToken}}),
  setUrlTokenResult: urlTokenResult => ({type: actionTypes.SET_URL_TOKEN_RESULT, payload: {urlTokenResult}}),
  setIsReportLoading: isReportLoading  => ({type: actionTypes.SET_ISREPORT_LOADING, payload: {isReportLoading}})
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(payload) {
    const selector = yield select(getState);
    if(!selector.auth.analytics){
      yield put(actions.publishAnalyticsObject());
    }
    yield put(actions.requestDeployment(payload));
  });

  yield takeLatest(actionTypes.DeploymentRequested, function* deploymentRequested({payload}) {
    const {callCode} = payload.deployment.payload; 
    const { data } = yield getDeploymentByToken();
    const {data: callData} = yield getCallDataByToken(callCode);
    data.callData = callData;
    yield put(actions.fulfillDeployment(data));
    const selector = yield select(getState);
    if(selector && selector.auth && selector.auth.role == 'subscriber'){
      let termscode = getDeploymentParamValue(data.parameters, "TERM_CODE");
      if(termscode && termscode != '' && termscode != 0){
        try{
          const {data: termsData} = yield getTerms(termscode);
          yield put(actions.setTerms(termsData));
        }catch(err){
          trackException("Exception while getting terms. " + "\n  message == " + err.message);          
        }
      }
    }
  });
  
  yield takeLatest(actionTypes.Register, function* deploymentRequested({payload}) {
    const selector = yield select(getState);

    if(!selector.auth.analytics){
      yield put(actions.publishAnalyticsObject());
    }
    const { data } = yield getDeploymentByToken();
    // TODO : no almacenar el objeto deployment, solo el deplotment code -- done
    // TODO : pasarle el lenguaje  y el expires -- done
    if (typeof(localStorage) !== "undefined") {  
      try{
        localStorage.setItem('auth', JSON.stringify({
          authToken: payload.authToken, 
          email: payload.email, 
          deployment: payload.deployment, 
          role: payload.role, 
          userid: payload.userid, 
          timezone: payload.timezone, 
          expires: payload.expires, 
          lang: payload.lang,
          name: payload.name,
          profileName: payload.profileName,
          identification: payload.identification,
          urlToken: payload.urlToken
        }));
      }catch(e){
        console.log(e);
      }
    }
    yield put(actions.fulfillDeployment(data));
  });

  yield takeLatest(actionTypes.CallDataRequested, function* getCallDataByCode({payload}) {
    const { data } = yield getDeploymentByToken();
    const {data: callData} = yield getCallDataByToken(payload.callCode);
    data.callData = callData;
    yield put(actions.fulfillDeployment(data));
  });

  yield takeLatest(actionTypes.GET_URL_TOKEN, function* getUrlTokenSaga({payload}) {
    try{
      const { data } = yield getUrlToken(payload.codeCall, payload.userId);
      yield put(actions.setUrlToken(data));
      yield put(actions.setUrlTokenResult(true));
    }catch(e){
      yield put(actions.setUrlTokenResult(false));
    }
  });

  yield takeLatest(actionTypes.GET_MENU, function* getMenuAside() {
    const { data } = yield getMenu();
    yield put(actions.setMenu(data));
  });
    
  yield takeLatest(actionTypes.GET_REPORT, function* getReportSaga(payload) {
    const { data } = yield getReportById(payload.payload.reportid);
    yield put(actions.setReport(data));
  });

  yield takeLatest(actionTypes.ANALYTICS_LOG_EVENT, function* analyticsLogEventSaga(payload) {
    const selector = yield select(getState);
    //console.log(selector, payload)
    if(selector.auth.analytics){
      selector.auth.analytics.logEvent(payload);
    }
  });
}
