import React from 'react';
import { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
//import VisualAidTransferList from './VisualAidTransferList';
//import FilesTransferList from './FilesTransferList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import ImportCsv from './ImportCsv';

import Autocomplete from '@material-ui/lab/Autocomplete';

//import * as PromotionalPlansStorage from "../../store/ducks/promotionalPlans.duck";
//import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  labelFilterUser: {
    paddingLeft: 16
  },
  inputContainer:{
    display: 'flex',
    alignItems: 'center'
  },
  inputText: {
    border: '1px solid #4c4c4c',
    borderRadius: 4,
    minWidth: '50%',
    maxWidth:'100%'
  },
  label: {
    fontSize: 16,
    width: '45%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataTable: {
    width: '100%',
    paddingLeft: 10
  },
  inRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  rootTags: {
    minWidth: '50%',
    maxWidth: '100%',
    paddingLeft: 18,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  rootTagsTable: {
    minWidth: '100%',
    maxWidth: '100%',
    paddingLeft: 18,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const buttonRef = React.createRef()

const HeaderImportCustomer = (props) => {

//
const globalState = useSelector(state => state);
  const { intl } = props;
  const classes = useStyles();
  const theme = useTheme();

  let labelPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.PRESENTATION"
  });
  let placeHolderPresentations = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.PRESENTATION"
  });
  let labelFiles = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.LABEL.FILES"
  });
  let placeholderFiles = intl.formatMessage({
    id: "PROMOTIONAL_PLANS.PLACEHOLDER.FILES"
  });
//

  return(
    <>
    <div>
      <div className={classes.inRow}>
        <span id="status-label" className={classes.label}><FormattedMessage id="IMPORT_CUSTOMERS.ENTITY_TYPE" />: </span>
        <span className={classes.data}>
          <TextField
            className={classes.inputText}
            error={globalState.promotionalPlans.errorValidation === 'validTo' || globalState.promotionalPlans.errorValidation === 'datesDifference'}
            id="entityType"
            type="text"
            value={globalState.presentationBar.customerTypeSelected.name_Singular}
            disabled={true}
          />
        </span>
      </div>
      <hr style={{margin: '20px 0px 20px 18px'}} />
      <div id="visualAidTransferListContainer" className={classes.inRow}>
        <span className={classes.dataTable, classes.rootTagsTable}>
          <ImportCsv setName={props.setName} setSize={props.setSize} setFile={props.setFile} />
        </span>
      </div>
    </div>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    null
  )(HeaderImportCustomer)
);