import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as loggerStorage from "../../store/ducks/logger.duck";
import { connect, useSelector } from "react-redux";

const StyledMenu = withStyles({
  paper: {
    width: '300px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left', 
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      '& .MuiListItemText-primary': {
        color: theme.palette.common.black + "!important",
      }
    }
  }
}))(MenuItem);

const FlowMenu = ({ anchorEl, handleClose, sendFlowActive, updatePesentationFocusedLength, log, isPreview, gotoSlideSDVA}) => {
  const globalState = useSelector((state) => state);

  const playFlow = (flowIndex) => {
    const { focusedPresentationFlows } = globalState.presentationBar;
    const currentFlowIndex = focusedPresentationFlows.indexOfSelectedFlow;
    const currentSlideIndex = globalState.presentationBar.numSlide;

    if(currentFlowIndex == flowIndex && currentSlideIndex == 0) {
      return;
    }

    log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'VISUALAID_GOTOFLOW',
      log_text: `code: ${globalState.presentationBar.presentationFocused.code} | gotoFlowPosition: ${flowIndex} | goToCode: ${focusedPresentationFlows.flows[flowIndex].slides[0].code}`
    });

    sendFlowActive({
      'numSlide': 0,
      'numFlow': flowIndex,
      code: globalState.presentationBar.presentationFocused.code,
      type: globalState.presentationBar.presentationFocused.type,
      currentFlow: focusedPresentationFlows.flows[flowIndex],
      videoManager: globalState.presentationBar.videoManager,
      isPreview: isPreview
    });
                    
    updatePesentationFocusedLength(focusedPresentationFlows.flows[flowIndex].slides.length);  
    
    if(isPreview) {
      let iframeWin = document.getElementById("inlineFrame").contentWindow;
      let messageToSend = "SDVA_IN:GOTOSLIDE:" + focusedPresentationFlows.flows[flowIndex].slides[0].name;
      iframeWin.postMessage(messageToSend, "*");
    }

    if(gotoSlideSDVA) {
      setTimeout(() => {
        gotoSlideSDVA(focusedPresentationFlows.flows[flowIndex].slides[0]);
      }, [300]);
    }
    
  }

  return (
    <>
      {
        globalState.presentationBar.focusedPresentationFlows &&
        globalState.presentationBar.focusedPresentationFlows.flows &&
        globalState.presentationBar.focusedPresentationFlows.flows.length > 0 && (
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {globalState.presentationBar.focusedPresentationFlows.flows.map((flow, index) => (
              <StyledMenuItem key={flow.id} onClick={() => playFlow(index)} selected={index == globalState.presentationBar.focusedPresentationFlows.indexOfSelectedFlow}>
                <ListItemText primary={flow.name} />
                <img src={flow.url} alt={flow.name} style={{maxWidth: '100px', borderRadius: '4px'}} />
              </StyledMenuItem>
            ))}
          </StyledMenu>
        )
      }
    </>
  );
};
  
export default injectIntl(
  connect(
    null,
    { ...presentationBarStorage.actions, ...loggerStorage.actions }
  )(FlowMenu)
);
