import React from 'react';
import { connect, useSelector } from "react-redux";
import {useStyles} from './styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MovieIcon from '@material-ui/icons/Movie';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DownloadButton from './DownloadButton';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

import * as loggerStorage from "../../store/ducks/logger.duck";

const FileItemClient = (props) => {
  const globalState = useSelector(state => state);
  const classes = useStyles();
  let FileIcon = null;
  switch(props.item.mimeType){
    case 'application/pdf':
      FileIcon = () => (<PictureAsPdfIcon />)
      break;
    case 'video/mp4':
      FileIcon = () => (<MovieIcon />)
      break;
    case 'image/jpeg':
    case 'image/png':
      FileIcon = () => (<ImageIcon />)
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      FileIcon = () => (<ViewCarouselIcon />)
      break;
    default:
      FileIcon = () => (<AttachFileIcon />)
  }

  const handleDownloadClick = () => {
    window.open(props.item.url, '_blank');

    props.log({
      logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
      log_category: 'FILE_SHARED',
      log_text: JSON.stringify({ 
        callcode: globalState.auth.callCode, 
        userId: globalState.auth.userid, 
        filecode: props.item.code,
        type: 'download'
      })
    })
  }

  return(
    <>
    <div className={classes.container}>
      <div className={classes.row}>
        <div><FileIcon />&nbsp;{props.item.name}</div>
        <div onClick={handleDownloadClick}><DownloadButton item={props.item} /></div>
      </div>
    </div>
    </>
  )
}

export default connect(
  null,
  { ...loggerStorage.actions }
)(FileItemClient);