import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '../../components/VisualAids/UploadIcon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HeaderPromotionalPlans from '../../components/PromotionalPlans/HeaderPromotionalPlans';
import {useParams} from 'react-router-dom';
import moment from 'moment';

import * as promotionalPlansStorage from "../../store/ducks/promotionalPlans.duck";

const PromotionalPlansCreatePage = (props) => {
  const {intl} = props;
  const globalState = useSelector(state => state);
  const history = useHistory();
  let params = useParams();

  useEffect(() => {
    return () => {
      props.resetPromotionalPlan()
    }
  }, [])

  const backToPromotionalPlansList = () => {
    let messageAlert = intl.formatMessage({
      id: "VAID.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      history.push("/promotionalplans");
    }
  }

  const savePromotionalPlans = () => {
    //validar
    if(!globalState.promotionalPlans.newPromotionalPlan.name){
      props.setErrorValidation('name');
    }else if(!globalState.promotionalPlans.newPromotionalPlan.externalCode){
      props.setErrorValidation('externalCode');
    }else if(!globalState.promotionalPlans.newPromotionalPlan.validFrom){
      props.setErrorValidation('validFrom');
    }else if(!globalState.promotionalPlans.newPromotionalPlan.validTo){
      props.setErrorValidation('validTo');
    }else if(!moment(globalState.promotionalPlans.newPromotionalPlan.validFrom).isBefore(globalState.promotionalPlans.newPromotionalPlan.validTo)){
      props.setErrorValidation('datesDifference');
    }else{
      props.setErrorValidation(null);
      props.savePromotionalPlans(props.edit);
    }
  }

  useEffect(() => {
    if(globalState.promotionalPlans.resultSavePromotionalPlan !== null){
      history.push("/promotionalplans");
    }
  }, [globalState.promotionalPlans.resultSavePromotionalPlan])

  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {globalState.promotionalPlans.loading && <LinearProgress className="progressBar" />}
        {globalState.promotionalPlans.loading && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="PROMOTIONAL_PLANS.TITLE" /></h3>
          </div>
          <div>
          <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backVisualAid" onClick={backToPromotionalPlansList}>
            <ArrowBackIcon />
            <FormattedMessage id="VAID.BACK_BUTTON" />
          </Button>
          <Button size="medium" className="btn btn-sm btn-label-success" id="saveVisualAid" onClick={savePromotionalPlans}>
            <SaveIcon />
            <FormattedMessage id="VAID.EDIT.PAGE.SAVE.BUTTON" />
          </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>
              <div>
                <HeaderPromotionalPlans edit={props.edit} uuid={params.uuid} />
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    promotionalPlansStorage.actions
  )(PromotionalPlansCreatePage)
);