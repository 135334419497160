import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomerTypeSelector = (props) => {
  const globalState = useSelector(state => state);
  
    const classes = useStyles();
    const [typeSelected, setTypeSelected] = React.useState({});
    
    
    useEffect(() => {
      //listener will be added on component mounted
      if(globalState.presentationBar.customerTypeList.length <= 0){
        props.getCustomerTypeList();
      }
    }, []);

    useEffect(() => {
      if(globalState.presentationBar.customerTypeList.length > 0 && globalState.presentationBar.customerTypeList[0].name != ''){
        handleChange({target:{value: globalState.presentationBar.customerTypeList[0]}});
        //setTypeSelected(globalState.presentationBar.customerTypeList[0]);
        //props.getCustomerListByType(globalState.presentationBar.customerTypeList[0]);
      }
    }, [globalState.presentationBar.customerTypeList.length]);
    
    const handleChange = (event) => {
      //props.selectCustomer(event.target.value);
      //Object.assign(globalState.presentationBar.customerSelected, event.target.value);
      setTypeSelected(event.target.value);
      props.setCustomerTypeSelected(event.target.value);
      props.getCustomerListByType(event.target.value);
    };

    

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label"></InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={typeSelected}
          onChange={handleChange}
        >
          {globalState.presentationBar.customerTypeList.map((customer, index) => {
            return (<MenuItem key={index} value={customer}>{customer.name_Plural}</MenuItem>);
          })}
        </Select>
      </FormControl>
    )
}

export default injectIntl(
  connect(
      null,
      presentationBarStorage.actions
  )(CustomerTypeSelector)
)