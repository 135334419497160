import axios from "axios";
import {configUrls} from '../../globalConfig';

export const CHANGE_PASSWORD_URL = `${configUrls.base_url}/api/user/changepassword/`;

export function changePassword(oldPassword, newPassword){
  if(oldPassword && newPassword){
    return axios.put(CHANGE_PASSWORD_URL, {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }
}
