import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  getPromotionalPlan,
  getFiles,
  activateFile,
  inactivateFile,
  approveFile,
  pendingFile,
  createFileContainer,
  getFileEdit,
  uploadFile,
  saveMetaDataFile,
  getFileTagsOptions,
  getFolders
} from './file.crud';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/es';

export const actionTypes = {
  FULLFILL_PROMOTIONAL_PLANS: "FULLFILL_PROMOTIONAL_PLANS",
  FULLFILL_FILES: "FULLFILL_FILES",
  FILL_PROMOTIONAL_PLAN: "FILL_PROMOTIONAL_PLAN",
  FILL_FILES: "FILL_FILES",
  ACTIVATE_FILE: "ACTIVATE_FILE",
  INACTIVATE_FILE: "INACTIVATE_FILE",
  ACTIVATE_FILE_RESULT: "ACTIVATE_FILE_RESULT",
  INACTIVATE_FILE_RESULT: "INACTIVATE_FILE_RESULT",
  APPROVE_FILE: "APPROVE_FILE",
  PENDING_FILE: "PENDING_FILE",
  APPROVE_FILE_RESULT: "APPROVE_FILE_RESULT",
  PENDING_FILE_RESULT: "PENDING_FILE_RESULT",
  CREATE_FILE_CONTAINER: "CREATE_FILE_CONTAINER",
  FILL_RESULT_FILE_CONTAINER: "FILL_RESULT_FILE_CONTAINER",
  GET_FILE_EDIT: "GET_FILE_EDIT",
  UPLOAD_FILE: "UPLOAD_FILE",
  SAVE_META_DATA_FILE: "SAVE_META_DATA_FILE",
  EDIT_FILE_RESULT: "EDIT_FILE_RESULT",
  SET_ERROR_VALIDATION: "SET_ERROR_VALIDATION",
  SET_ARR_TAGS: "SET_ARR_TAGS",
  GET_FILE_TAGS_OPTIONS: "GET_FILE_TAGS_OPTIONS",
  FILL_FILE_TAGS_OPTIONS: "FILL_FILE_TAGS_OPTIONS",
  GET_FOLDERS: "GET_FOLDERS",
  FILL_FOLDERS: "FILL_FOLDERS",
  SET_FILE_UPLOADED: "SET_FILE_UPLOADED"
};


const initialState = {
  promotionalPlans: [],
  files: [],
  loading: false,
  activateFileResult: null,
  inactivateFileResult: null,
  approveFileResult: null,
  pendingFileResult: null,
  fileContainerResponse: {},
  editFileResult: null,
  errorValidation: null,
  tagsOptions: [],
  folders: [],
  fileUploaded: false
}

export const reducer = persistReducer(
  { storage, key: "file", whitelist: [""] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FULLFILL_PROMOTIONAL_PLANS:
          return Object.assign({}, state,  { promotionalPlans: action.payload, loading: false } );
      case actionTypes.FULLFILL_FILES:
        return Object.assign({}, state,  { files: action.payload.list, loading: false });
      case actionTypes.FILL_PROMOTIONAL_PLAN:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.FILL_FILES:
        return Object.assign({}, state,  { loading: true } );
      case actionTypes.ACTIVATE_FILE: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.INACTIVATE_FILE: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.ACTIVATE_FILE_RESULT: {
          return { 
            ...state,
            loading: false,
            activateFileResult: action.payload
          };
      }
      case actionTypes.INACTIVATE_FILE_RESULT: {
          return { 
            ...state,
            loading: false,
            inactivateFileResult: action.payload
          };
      }
      case actionTypes.APPROVE_FILE: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.PENDING_FILE: {
          return { 
            ...state,
            loading: true,
            fileContainerResponse: {code: action.payload.code}
          };
      }
      case actionTypes.APPROVE_FILE_RESULT: {
          return { 
            ...state,
            loading: false,
            approveFileResult: action.payload
          };
      }
      case actionTypes.PENDING_FILE_RESULT: {
          return { 
            ...state,
            loading: false,
            pendingFileResult: action.payload
          };
      }
      case actionTypes.CREATE_FILE_CONTAINER: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.FILL_RESULT_FILE_CONTAINER: {
          return { 
            ...state,
            loading: false,
            redirection: (action.payload.redirection) ? action.payload.redirection : false,
            fileContainerResponse: action.payload
          };
      }
      case actionTypes.SAVE_META_DATA_FILE: {
          return { 
            ...state,
            loading: true
          };
      }
      case actionTypes.EDIT_FILE_RESULT: {
          return { 
            ...state,
            loading: false,
            editFileResult: action.payload
          };
      }
      case actionTypes.SET_ARR_TAGS: {
        return { 
          ...state,
          fileContainerResponse: { 
            ...state.fileContainerResponse, 
            tags: action.payload
          }
        };
      }
      case actionTypes.GET_FILE_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_FILE_TAGS_OPTIONS: {
        return { 
          ...state,
          loading: false,
          tagsOptions: action.payload
        };
      }
      case actionTypes.SET_ERROR_VALIDATION: {
        return { 
          ...state,  
          errorValidation: action.payload 
        };
      }
      case actionTypes.GET_FOLDERS: {
        return { 
          ...state,
          loading: true
        };
      }
      case actionTypes.FILL_FOLDERS: {
        return { 
          ...state,
          loading: false,
          folders: action.payload
        };
      }
      case actionTypes.SET_FILE_UPLOADED: {
        return { 
          ...state,
          fileUploaded: action.payload
        };
    }
      default:
        return state
    }
  }
);

export const actions = {
  fillPromotionalPlan: (value) => ({ type: actionTypes.FILL_PROMOTIONAL_PLAN, payload: value}),
  fulfillPromotionalPlan: (value) => ({ type: actionTypes.FULLFILL_PROMOTIONAL_PLANS, payload: value}),
  fulfillFiles: (value) => ({ type: actionTypes.FULLFILL_FILES, payload: value}),
  fillFiles: (value) => ({ type: actionTypes.FILL_FILES, payload: value}),
  activateFile: (value) => ({ type: actionTypes.ACTIVATE_FILE, payload: value }),
  inactivateFile: (value) => ({ type: actionTypes.INACTIVATE_FILE, payload: value }),
  setActivateFileResult: (value) => ({ type: actionTypes.ACTIVATE_FILE_RESULT, payload: value }),
  setInactivateFileResult: (value) => ({ type: actionTypes.INACTIVATE_FILE_RESULT, payload: value }),
  approveFile: (value) => ({ type: actionTypes.APPROVE_FILE, payload: value }),
  pendingFile: (value) => ({ type: actionTypes.PENDING_FILE, payload: value }),
  setApproveFileResult: (value) => ({ type: actionTypes.APPROVE_FILE_RESULT, payload: value }),
  setPendingFileResult: (value) => ({ type: actionTypes.PENDING_FILE_RESULT, payload: value }),
  createFileContainer: (value) => ({ type: actionTypes.CREATE_FILE_CONTAINER, payload: value }),
  fillResultFileContainer: (value) => ({ type: actionTypes.FILL_RESULT_FILE_CONTAINER, payload: value }),
  getEditFile: (value) => ({ type: actionTypes.GET_FILE_EDIT, payload: value }),
  uploadFile: (value) => ({type: actionTypes.UPLOAD_FILE, payload: value}),
  saveMetaDataFile: (value) => ({type: actionTypes.SAVE_META_DATA_FILE, payload: value}),
  setEditFileResult: (value) => ({type: actionTypes.EDIT_FILE_RESULT, payload: value}),
  setErrorValidation: (value) => ({ type: actionTypes.SET_ERROR_VALIDATION, payload: value}),
  setArrTags: (value) => ({type: actionTypes.SET_ARR_TAGS, payload: value}),
  getFileTagsOptions: (value) => ({type: actionTypes.GET_FILE_TAGS_OPTIONS, payload: value}),
  fillFileTagsOptions: (value) => ({type: actionTypes.FILL_FILE_TAGS_OPTIONS, payload: value}),
  getFolders: (value) => ({type: actionTypes.GET_FOLDERS, payload: value}),
  fillFolders: (value) => ({type: actionTypes.FILL_FOLDERS, payload: value}),
  setFileUploaded: (value) => ({type: actionTypes.SET_FILE_UPLOADED, payload: value}),
}

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.GET_FILE_TAGS_OPTIONS, function* getFileTagsOptionsSaga({payload}) {
    try{
      const {data, status} = yield getFileTagsOptions();
      if(status < 300){
        yield put(actions.fillFileTagsOptions(data.list));
      }
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.SAVE_META_DATA_FILE, function* saveMetaDataFileSaga({payload}) {
    const selector = yield select(getState);
    try{
      let newObj = JSON.parse(JSON.stringify(selector.file.fileContainerResponse)); // deep copy
      newObj.validFrom = moment(newObj.validFrom).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      newObj.validTo = moment(newObj.validTo).subtract(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      
      if(payload.edit){
        
        let urlStr = newObj.url.split('/');
        newObj.fileName = urlStr[urlStr.length - 1];
      }
      const {data, status} = yield saveMetaDataFile({ ...newObj, ...payload});
      yield put(actions.fillResultFileContainer({}));
      if(status < 300){
        yield put(actions.setEditFileResult(true));
        
        if(payload.editFilePopUp) {
          yield put(actions.setEditFileResult(null));
        }
      }else{
        yield put(actions.setEditFileResult(false));
      }
    }catch(e){
      console.log(e);
      yield put(actions.fillResultFileContainer({}));
      yield put(actions.setEditFileResult(false))
    }    
  });

  yield takeLatest(actionTypes.UPLOAD_FILE, function* uploadFileSaga({payload}) {
    try{
      const {data, status} = yield uploadFile(payload);
    }catch(e){
      console.log(e);
    }    
  });

  yield takeLatest(actionTypes.GET_FILE_EDIT, function* getFileEditSaga({payload}) {
    const selector = yield select(getState);
    try{
      const {data, status} = yield getFileEdit(payload);
      data.validFrom = moment(data.validFrom).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      data.validTo = moment(data.validTo).add(selector.auth.timezone, 'hours').format('YYYY-MM-DDTHH:mm:ss');
      
      if(status < 300 && data.code){
        yield put(actions.fillResultFileContainer(data));
      }else{
        yield put(actions.fillResultFileContainer({}));
      }
    }catch(e){
      yield put(actions.fillResultFileContainer({}));
    }    
  });

  yield takeLatest(actionTypes.CREATE_FILE_CONTAINER, function* createFileContainerSaga({payload}) {
    try{
      const {data, status} = yield createFileContainer(payload);
      
      if(status < 300 && data.code){
        yield put(actions.fillResultFileContainer({...data, redirection: true}));
      }else{
        yield put(actions.fillResultFileContainer({redirection: false}));
      }
    }catch(e){
      yield put(actions.fillResultFileContainer({redirection: false}));
    }    
  });

  yield takeLatest(actionTypes.INACTIVATE_FILE, function* inactivateFileSaga({payload}) {
    try{
      const {data, status} = yield inactivateFile(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setInactivateFileResult(true));
      }else{
        yield put(actions.setInactivateFileResult(false));
      }
      if(!payload.fromEditPopUp) {
        yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
      }
      
    }catch(e){
      yield put(actions.setInactivateFileResult(false));
      if(!payload.fromEditPopUp) {
        yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
      }
    }    
  });

  yield takeLatest(actionTypes.ACTIVATE_FILE, function* activateFileSaga({payload}) {
    try{
      const {data, status} = yield activateFile(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setActivateFileResult(true));
      }else{
        yield put(actions.setActivateFileResult(false));
      }
      yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setActivateFileResult(false));
      yield put(actions.fillfiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.APPROVE_FILE, function* approveFileSaga({payload}) {
    try{
      const {data, status} = yield approveFile(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setApproveFileResult(true));
      }else{
        yield put(actions.setApproveFileResult(false));
      }
      yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setAproveFileResult(false));
      yield put(actions.filliles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.PENDING_FILE, function* pendingFileSaga({payload}) {
    try{
      const {data, status} = yield pendingFile(payload.code);
      
      if(status < 300 && data === 'ok'){
        yield put(actions.setPendingFileResult(true));
      }else{
        yield put(actions.setPendingFileResult(false));
      }
      yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }catch(e){
      yield put(actions.setPendingFileResult(false));
      yield put(actions.fillFiles({ stateId: payload.stateSelected, code: payload.planSelected, intl: payload.intl }));
    }    
  });

  yield takeLatest(actionTypes.FILL_PROMOTIONAL_PLAN, function* fillPromotionalPlanSaga({ payload}) {
    const { data } = yield getPromotionalPlan(payload);
    yield put(actions.fulfillPromotionalPlan(data));
  });

  yield takeLatest(actionTypes.FILL_FILES, function* fillFiles({ payload}) {
    try{
      const { data } = yield getFiles(payload.stateId,payload.code);
      let files = { list:[] };
      if(data && data.list && data.list.length > 0){
        data.list.forEach((fileItem, index) => {
          switch (fileItem.status) {
            case 1:
              fileItem.statusName = payload.intl.formatMessage({ id: "FILE.STATUS.APPROVED" });
              break;
            case 2:
              fileItem.statusName = payload.intl.formatMessage({ id: "FILE.STATUS.PENDING" });
              break;
            case 3:
              fileItem.statusName = payload.intl.formatMessage({ id: "FILE.STATUS.DRAFT" });
              break;
            case 4:
              fileItem.statusName = payload.intl.formatMessage({ id: "FILE.STATUS.TEMP" });
              break;
            default:
              fileItem.statusName = ''
              break;
          }
          files.list.push(fileItem);
        });
      }
      
      yield put(actions.fulfillFiles(files)); 
    }catch(e){
      console.log(e);
    }
  });

  yield takeLatest(actionTypes.GET_FOLDERS, function* getFoldersSaga({payload}) {
    try{
      const {data, status} = yield getFolders();
      if(status < 300){
        yield put(actions.fillFolders(data.list));
      }
    }catch(e){
      console.log(e);
    }    
  });
}
