import React from 'react';
import { useSelector } from "react-redux";
import FileItem from './FileItem';
import FileItemClient from './FileItemClient';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container:{
    width: '100%',
    color: '#2b6eb0'
  },
  title: {
    borderBottom: '1px dotted #2b6eb0',
    padding: 8,
    backgroundColor: '#f5f5f5',
    margin: '8px 8px 0 8px'
  },
}));

const Filefolder = (props) => {
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  let container = [];
    for (let folder in props.fileListFiltered) {
      let children = [];
      if(auth.role === 'organizer'){
        props.fileListFiltered[folder].forEach((item, index) => {
          children.push(<FileItem item={item} key={index} />)
        });
      }else{
        props.fileListFiltered[folder].forEach((item, index) => {
          children.push(<FileItemClient item={item} key={index} />)
        });  
      }
      container.push(
        <div className={classes.container} key={folder}>
          <div className={classes.title}>
            <b>{folder.charAt(0).toUpperCase() + folder.substring(1)}</b>
          </div>
          {children}
        </div>
      );
    }
  return(container);
}

export default Filefolder;
