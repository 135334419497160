import React, { useEffect, useState } from 'react'
import { truncate, getPreviewFromConfig } from './util'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: "10.5px"
    }
}));

const PresentationGridItem = ({item, action, preview, deploymentStyle, intl}) => {
    const [sdvaPreview, setSdvaPreview] = useState(undefined);
    const classes = useStyles();

    useEffect(() => {
        const fetchPreview = async () => {
            if(item.type === 'sdva') {
              const potentialPreview = await getPreviewFromConfig(item);
              if(potentialPreview !== undefined) {
                setSdvaPreview(potentialPreview);
              }
            }
        }
        
        fetchPreview();

        return(() => {
            setSdvaPreview(undefined);
        })
    }, []);

  return (
    <Grid onClick={action} item key={item.code} xs={12} sm={6} md={4}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia
            className="preview_img"
            component="img"
            image={sdvaPreview ? sdvaPreview : preview}
            />
            
            <CardContent sx={{ flexGrow: 1 }}>
            <Tooltip title={item.name} arrow classes={{ tooltip: classes.tooltip }}>
                <Typography gutterBottom variant="h5" component="h2">
                    { truncate(item.name) }
                </Typography>
            </Tooltip>
            </CardContent>

            <CardActions>
            <Button 
                style={{ border: '1px solid #d3d4d5', color: deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor}}  
                id="btnPresentationListStart" 
                onClick={(event) => {
                    event.stopPropagation();
                    action();
                }}
            >
                {intl.formatMessage({id: "PRESENTATION.BAR.VIEW"})}
            </Button>
            </CardActions>
        </Card>
    </Grid>
  )
}

export default PresentationGridItem