import React, {useState, useRef, useEffect} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { connect, useSelector } from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { defaultStyles } from '../../../globalConfig';
import { makeStyles } from '@material-ui/core/styles';
import ProductTab from './ProductTab';
import TermsSelector from './TermsSelector';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import './ProductsDialog.scss';
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';


import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import PresentationGridActionItem from '../PresentationGridActionItem/PresentationGridActionItem';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getModalStyle() {
  const top = 30;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  dialogtop: {
    width: '100%',
    height: 750
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));

const ProductsCallDialog = (props) => {
  const [termsValue, setTermsValue] = React.useState(null);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [productsEnabled, setProductsEnabled] = useState('');
  const [tabProductLabel, setTabProductLabel] = useState('');
  const [termsEnabled, setTermsEnabled] = useState('');
  const [tabTermsLabel, setTabTermsLabel] = useState('');

  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const globalState = useSelector(state => state);
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const {intl} = props;
  const { PUBLIC_URL } = process.env;
  const requestFromCustomer = props.gridItem;
  const dialogStyle = requestFromCustomer ? { maxHeight: '80vh' } : null;

  useEffect(() => {
    props.getProductsByCall(globalState.auth.callCode);
    props.getTermsByCall(globalState.auth.callCode);
  }, []);

  useEffect(() => {
    const productsEnabledParam = (getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_ENABLE_PRODUCTS') === '1');
    
    setProductsEnabled(productsEnabledParam);
    setTabProductLabel(
      (getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_PRODUCTS_LABEL')) ? 
      getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_PRODUCTS_LABEL')
      : 
      intl.formatMessage({id: "PRODUCTS.CALL.DIALOG.PRODUCT.LABEL"})
    );
    
    const termsEnabledParam = (getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_ENABLE_TERMS') === '1');
    
    setTermsEnabled(termsEnabledParam);
    setTabTermsLabel(
      (getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_TERMS_LABEL')) ? 
        getDeploymentParamValue(globalState.auth.deployment.parameters, 'CUSTOMER_REQUEST_TERMS_LABEL')
        : 
        intl.formatMessage({id: "PRODUCTS.CALL.DIALOG.TERMS.LABEL"})
    ) 

    if(!productsEnabledParam && termsEnabledParam ){
      setTabValue(1);
    }
  }, []);

  useEffect(() => {
    if(
      globalState.presentationBar.makeProductsRequestResult.status !== null ||
      globalState.presentationBar.makeTermsRequestResult.status !== null
    ){
      setOpenSnack(true);
      if(
        globalState.presentationBar.makeProductsRequestResult.status === false ||
        globalState.presentationBar.makeTermsRequestResult.status === false
      ){
        setSnackSeverity('error');
      }
      if(
        globalState.presentationBar.makeProductsRequestResult.status === true ||
        globalState.presentationBar.makeTermsRequestResult.status === true
      ){
        setSnackSeverity('success');
      }

    }
  }, [globalState.presentationBar.makeProductsRequestResult, globalState.presentationBar.makeTermsRequestResult])

  const deploymentStyle = {
    'accentColor': getDeploymentParamValue(globalState.auth.deployment.parameters, "ACCENT_COLOR"),
  };

  const handleSnackClose = () => {
    props.makeProductRequestResult({status: null});
    props.makeTermsRequestResult({status: null});
    setProducts([]);
    setSnackSeverity('');
    setOpenSnack(false);
  }

  const handleOpen = () => {
    props.getProductsByCall(globalState.auth.callCode);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleAccept = () => {
    if(tabValue === 0){
      props.makeProductRequest(
        products, 
        globalState.auth.deployment.callData.subscriberId, 
        globalState.auth.deployment.callData.code,
        1,
        intl,
        !requestFromCustomer
      );
      setProducts([]);
      props.fillProductsByCall([]);
    } else{
      props.makeTermsRequest(
        {
          code: termsValue,
          customerId: globalState.auth.deployment.callData.subscriberId, 
          callcode: globalState.auth.deployment.callData.code,
          type: 2,
          intl
        }
      );
    }

    setOpen(false);
  }

  const handleTabChange = (event, newValue) => {
    setProducts([]);
    setTermsValue(null);
    setTabValue(newValue);
  };
 
  return(
    <>
      <Snackbar
        open={(openSnack)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert severity={snackSeverity}>
          <FormattedMessage id={`PRODUCTS.CALL.DIALOG.${snackSeverity.toUpperCase()}`} />
        </Alert>
      </Snackbar>

      {
        requestFromCustomer ?
        <PresentationGridActionItem 
        action={handleOpen} 
        title={<FormattedMessage id="PRODUCTS.CALL.DIALOG.BUTTON.CREATE.CUSTOMER" />} 
        buttonText={<FormattedMessage id="PRODUCTS.CALL.CREATE" />}
        preview={`${PUBLIC_URL}/media/illustrations/sample-request.png`}
        deploymentStyle={deploymentStyle}
        /> :
        <span id="btnCreateCustomerGroup" onClick={handleOpen}>
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          &nbsp;<FormattedMessage id="PRODUCTS.CALL.DIALOG.BUTTON.CREATE" />
        </span>
      }
      
      <Dialog
        open={open}
        onClose={handleClose}
        onExit={() => { }}
        fullWidth={true}
        maxWidth="md"
        container={() => document.getElementById('callContainer')}
        style={dialogStyle}
      >
        <DialogTitle id="form-dialog-title"><FormattedMessage id="PRODUCTS.CALL.DIALOG.CREATE.TITLE" /></DialogTitle>
        <DialogContent className={classes.dialogtop}>
          <div className={classes.paper}>
            <div className="popup__input-products__container">
              <AppBar position="static">
                <Tabs 
                  value={tabValue} 
                  onChange={handleTabChange} 
                  id="tabsBar" 
                  aria-label="simple tabs example" 
                  style={{justifyContent: 'center'}} 
                  centered
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  {productsEnabled &&
                  <Tab label={tabProductLabel} value={0} />
                  }
                  {termsEnabled && !requestFromCustomer &&
                  <Tab label={tabTermsLabel} value={1}/>
                  }
                </Tabs>
              </AppBar>
              {!productsEnabled && !termsEnabled &&
                <FormattedMessage id="PRODUCTS.CALL.DIALOG.CREATE.ERROR_MODELING" />
              }
              {productsEnabled &&
              <TabPanel value={tabValue} index={0}>
                <ProductTab tabValue={tabValue} setProducts={setProducts} products={products} productList={globalState.presentationBar.products} /> 
              </TabPanel>
              }
              {termsEnabled && !requestFromCustomer &&
              <TabPanel value={tabValue} index={1}>
                <TermsSelector termsValue={termsValue} setTermsValue={setTermsValue} terms={globalState.presentationBar.terms}/>
              </TabPanel>
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnNewCustomerGroup" onClick={handleAccept}>
            <FormattedMessage id="PRODUCTS.CALL.DIALOG.ACTION.CREATE" />
          </Button>
          <Button className={classes.button} id="btnCustomerGroupClosePopUp" onClick={handleClose}>
            <FormattedMessage id="PRODUCTS.CALL.DIALOG.ACTION.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default injectIntl(
  connect(
    null,
    presentationBarStorage.actions
  )(ProductsCallDialog)
);