import React, { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';
import './UserList.scss';
import MaterialTable from 'material-table';
import ActionSelector from './ActionsSelector';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import {
  getDeploymentParamValue,
} from '../../../../_metronic/utils/utils';

import * as userStorage from "../users.duck";

const UserList = (props) => {
  const globalState = useSelector(state => state);
  const [userSelected, setUserSelected] = React.useState(null);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(false);
  const { intl } = props;
  const history = useHistory();

  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setUserSelected(row);
    setMenuAnchor(anchorElement);
    setMenuOpen(true);
  };
  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
    setUserSelected(null);
  }
  

  useEffect (() => {
    props.getUsers({allow_guest: -1});
    setIsEditEnabled(getDeploymentParamValue(globalState.auth.deployment.parameters, 'USER_UPDATE_ENABLE') == '1' ? true : false);
  }, [])

  useEffect(() => {
    props.getUsers({allow_guest: -1});
  }, [globalState.users.stateId]);

  let actionsColumnIndex = 2;
  let columns = [
    { 
      title: intl.formatMessage({id: "ECOMMERCE.CUSTOMERS.NAME"}), 
      field: 'Name',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.EMAIL"}), 
      field: 'Email',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.EXTERNAL_CODE"}), 
      field: 'ExternalCode',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.ATRIBUTE_1"}), 
      field: 'Atribute_1',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {},
      hidden: true,
      export: true 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.PROFILE_NAME"}), 
      field: 'ProfileName',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.GROUP_NAMES"}), 
      field: 'GroupNames',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {} 
    },
    { 
      title: intl.formatMessage({id: "USER.LIST.COLUMN.ALLOW_GUEST"}),
      field: 'Allow_Guest',
      cellStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {},
      render: rowData => (rowData.Allow_Guest)?<span>✔</span>:<span>✖</span>
    }
  ];

  const activateAction = () => {
    if(userSelected){
      props.activateUser(userSelected); 
      setMenuAnchor(null);
      setMenuOpen(false);
      setUserSelected(null);
    }
  }

  const deactivateAction = () => {
    
    let msgAlert = intl.formatMessage({ id: "VAID.ACTIONS.CONFIRM" });
    if (window.confirm(msgAlert)) 
    {
      if(userSelected)
      {
        props.deactivateUser(userSelected); 
        setMenuAnchor(null);
        setMenuOpen(false);
        setUserSelected(null);
      }
    }
  }

  const editAction = () => {
    if(userSelected && userSelected.uuid){
      history.push('/user/update/' + userSelected.uuid)
    }
  }

  return(
    <div id="userList" style={{ width: '100%', boxShadow: 'none' }}>
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.users.users}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left',
            headerStyle:(isMobile || isIPad13 || isIPhone13) ? { padding: '3px'} : {},
            actionsColumnIndex: -1,
            exportButton: {
              csv: true
            },
            exportAllData: true,
            exportFileName: `users_${(new Date()).toISOString().split('T')[0]}`
          }
        }
        actions={[
          {
            icon: 'edit', //ponerle 
            tooltip: intl.formatMessage({
              id: "USER.LIST.ACTIONS.BUTTON"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            }
          }
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            }),
            exportCSVName: intl.formatMessage({
              id: 'USER.LIST.ACTIONS.BUTTON.EXPORT.CSV'
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },
          
        }}
      />
      <Menu
        id="menu-users"
        keepMounted
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        
        {(globalState.users.stateId === 0) && <MenuItem onClick={activateAction} ><FormattedMessage id="USER.LIST.ACTIONS.ACTIVATE" /></MenuItem> }
        {(globalState.users.stateId === 1) && <MenuItem onClick={deactivateAction} ><FormattedMessage id="USER.LIST.ACTIONS.DEACTIVATE" /></MenuItem> }
        {(globalState.users.stateId === 1 && isEditEnabled) && <MenuItem onClick={editAction} ><FormattedMessage id="USER.LIST.ACTIONS.BUTTON" /></MenuItem> }
      </Menu>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    {...userStorage.actions}
  )(UserList)
);