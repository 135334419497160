import React from "react";
import { useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import MaterialTable from 'material-table'
import './ProductList.scss';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
//import { activateVisualAid, inactivateVisualAid } from "../../crud/visualAid.crud";
import { useHistory } from "react-router-dom";
import {getDeploymentParamValue} from '../../../../_metronic/utils/utils';

import * as productStorage from "../products.duck";

const useStyles = makeStyles((theme) => ({
  stateControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 10
  },
  planControl: {
    width: '20%',
    minWidth: 120,
    marginTop: 20,
    marginLeft: 30
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProductList = (props) => {
  const classes = useStyles();
  const globalState = useSelector(state => state);
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [stateSelected, setStateSelected] = React.useState(1);
  const [planSelected, setPlanSelected] = React.useState(-1);
  const { intl } = props;
  
  const openStateEdit = (event, row) => {
    let anchorElement = event.currentTarget;
    setSelectedRow(row);
    setMenuAnchor(anchorElement);
    setMenuOpen(true);
  };

  const handleMenuClose = () =>{
    setMenuAnchor(null);
    setMenuOpen(false);
  }

  useEffect (() => {
    props.fillPromotionalPlan(stateSelected); // obtengo los promotional plans
    props.fillProducts({ stateId: stateSelected, code: planSelected, intl: intl }) // obtengo los productos
    props.fillResultProductContainer({}); // inicializo como array vacio el objeto de respuesta
  }, [])
  
  useEffect(() => {
    // cuando se selecciona el filtro de activo o inactivo se busca de nuevo la lista filtrando
    props.fillProducts({ stateId: stateSelected, code: planSelected, intl: intl })
  }, [stateSelected]);

  /*
  useEffect(() => {
    Object.assign(globalState.file.files, globalState.file.files);
  }, [globalState.file.files]);
  */

  // se llama a este metodo cuando se filtra por active / inactive
  const handleChange = (event) => {
    setStateSelected(event.target.value); // se asigna el estado del filtro
    props.fillPromotionalPlan(event.target.value); // se vuelven a llenar los promotionalplans
  };

  // se llama a este metodo cuando se filtra por el segundo select
  const handleChangePlan = (event) => {
    setPlanSelected(event.target.value);
    props.fillProducts({ stateId: stateSelected, code: event.target.value, intl: intl });
  };

  const handleActivate = () => {
      handleMenuClose();
      // activa el producto
      props.activateProduct({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
  }

  const handleDeactivate = () => {
    let msgAlert = intl.formatMessage({ id: "PRODUCTS.ACTIONS.CONFIRM" });
    if (window.confirm(msgAlert)) {
      handleMenuClose();
      props.inactivateProduct({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
    }
  }

  const handleApprove = () => {
    handleMenuClose();
    props.approveProduct({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected , intl: intl});
  }

  const handlePending = () => {
    handleMenuClose();
    props.pendingProduct({ code:selectedRow.code, stateSelected: stateSelected, planSelected: planSelected, intl: intl });
  }

  const handleEdit = () => {
    handleMenuClose();
    props.fillResultProductContainer({code: selectedRow.code})
    doRedirection(selectedRow.code)
  }

  const doRedirection = (code) => {
    history.push("/products/update/" + code);
  }

  let actionsColumnIndex = 6;
  let columns = [
    { title: intl.formatMessage({ id: "PRODUCTS.COLUMN.NAME" }), field: 'name', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "PRODUCTS.COLUMN.ORDER" }), field: 'order', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "PRODUCTS.COLUMN.STATUS" }), field: 'statusName',  cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {}},
    { title: intl.formatMessage({ id: "PRODUCTS.COLUMN.PLANNAMES" }), field: 'planNames', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} }
  ];

  const productEditEnabled = getDeploymentParamValue(globalState.auth.deployment.parameters, "PRODUCT_UPDATE_ENABLE");

  return (
    <div id="filesList" style={{ width: '100%', boxShadow: 'none' }}>
      <FormControl className={classes.stateControl} >
        <InputLabel id="demo-simple-select-helper-label">{intl.formatMessage({ id: "PRODUCTS.SELECTOR.STATE_ID" })}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={stateSelected}
          onChange={handleChange}
        >
          <MenuItem key={0} value={0}>{intl.formatMessage({ id: "PRODUCTS.SELECTOR.STATEID.INACTIVE"})}</MenuItem>
          <MenuItem key={1} value={1}>{intl.formatMessage({ id: "PRODUCTS.SELECTOR.STATEID.ACTIVE"})}</MenuItem>

        </Select>
      </FormControl>
      {/**
       * Revisar con pompi si pongo este selector u otro de este tipo
       */}
      <FormControl className={classes.planControl} >
        <InputLabel id="demo-simple-select-helper-label">{intl.formatMessage({ id: "VAID.SELECTOR.PLAN" })}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="promoPlanSelect"
          value={planSelected}
          onChange={handleChangePlan}
        >
          <MenuItem key={-1} value={-1}>{intl.formatMessage({ id: "FILE.SELECTOR.PLAN.ALL" })}</MenuItem>
          <MenuItem key={0} value={-2}>{intl.formatMessage({ id: "FILE.SELECTOR.PLAN.NOPLAN" })}</MenuItem>
          {globalState.file.promotionalPlans.list ? globalState.file.promotionalPlans.list.map((plan, index) => {
            return (<MenuItem key={index+1} value={plan.code}>{plan.name}</MenuItem>);
          }) : null}
        </Select>
      </FormControl>
      
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.product.products}
        options={
          { 
            actionsColumnIndex: actionsColumnIndex, 
            pageSize: 10, 
            searchFieldAlignment: 'left', 
            headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
            actionsColumnIndex: -1
          }
        }
        actions={[
          {
            icon: 'edit',
            tooltip: intl.formatMessage({
              id: "DASHBOARD.MENU.EDIT"
            }),
            onClick: (event, rowData) => {
              openStateEdit(event, rowData)
            }
          }
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },

        }}
      />
      <Menu
        id="menu-visualaidactions"
        keepMounted
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
      > 
        {(selectedRow && selectedRow.state_id == 0) &&
          <MenuItem onClick={handleActivate} >{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.ACTIVATE"})}</MenuItem>
        }
        {(selectedRow && selectedRow.state_id == 1) &&
          <MenuItem onClick={handleDeactivate} >{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.DEACTIVATE"})}</MenuItem>
          }
        {(selectedRow && selectedRow.status == 2) &&
          <MenuItem onClick={handleApprove} >{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.APPROVE"})}</MenuItem>
        }
        {(selectedRow && selectedRow.status == 3) &&
          <>
          <MenuItem onClick={handlePending}>{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.PENDING"})}</MenuItem>
          {productEditEnabled === '1' &&
          <MenuItem onClick={handleEdit}>{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.EDIT"})}</MenuItem>
          }
          </>
        }
        {(selectedRow && selectedRow.status == 4 && productEditEnabled === '1') &&
          <MenuItem onClick={handleEdit}>{intl.formatMessage({ id: "PRODUCTS.MENU.ACTIONS.EDIT"})}</MenuItem>
        }

      </Menu>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    productStorage.actions
  )(ProductList)
);