/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import MaterialTable from 'material-table';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as importCustomersStorage from "../../store/ducks/importCustomers.duck";
import { isMobile, isIPad13, isIPhone13 } from 'react-device-detect';
import ImportLogHeader from './ImportLogHeader';

const useStyles = makeStyles(theme => ({
  dialogtop: {
    marginTop: '4%',
    width: 800,
    height: 510
  },
  paper: {
    position: 'absolute',
    width: 800,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    width: '50%',
    textAlign: 'right'
  },
  data: {
    width: '50%',
    paddingLeft: 10
  },
  dataFullWidth: {
    width: '70%'
  },
  inRow: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mtop20: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ImportLogPopUp = (props) => {
  const [open, setOpen] = React.useState(false);
  const { intl } = props;
  const globalState = useSelector(state => state);
  
  let actionsColumnIndex = 2;
  let columns = [
    { title: intl.formatMessage({ id: "IMPORT_FILE.COLUMN.NAME" }), field: 'original_Name', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} },
    { title: intl.formatMessage({ id: "IMPORT_FILE.COLUMN.LAST_UPDATE" }), field: 'LastUpdate', cellStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} }
  ];

  if(globalState.importCustomers.importLogDetail.header){
    let statusText = "";
    switch(globalState.importCustomers.importLogDetail.header.status){
      case 1:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_1" });
        break;
      case 2:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_2" });
        break;
      case 3:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_3" });
        break;
      case 4:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_4" });
        break;
      case 8:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_8" });
        break;
      case 9:
        statusText = intl.formatMessage({ id: "IMPORT_CUSTOMERS.STATUS_9" });
        break;
      default:
        break;
    }

    globalState.importCustomers.importLogDetail.header.statusText = statusText;
  }

  const handleDownload = (event, row) =>{
    let a = document.createElement('a');

    a.download = row.Name;
    a.href = row.Url;
    a.click();
  }

  const handleOpen = () => {
    setOpen(true);

    if(props.uuid){
      props.getImportLogDetail({ uuid: props.uuid, showLog: '1' });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div style={{ "display": "inline-block" }}>

      <MenuItem onClick={handleOpen} >{intl.formatMessage({ id: "IMPORT_CUSTOMERS.MENU.ACTIONS.SHOW_LOG"})}</MenuItem>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" id="popup">
        <DialogTitle id="form-dialog-title">
          <FormattedMessage id="IMPORT_FILE_LOG.TITLE" />
        </DialogTitle>

        <DialogContent className={classes.dialogtop}>
          {globalState.importCustomers.importLogDetail.header != null &&
            <div>
              <ImportLogHeader header={globalState.importCustomers.importLogDetail.header}></ImportLogHeader>

              <hr/>
            </div>
          }

        <MaterialTable
          columns={columns}
          title=""
          data={globalState.importCustomers.importLogDetail.loglist}
          options={
            { 
              actionsColumnIndex: actionsColumnIndex, 
              pageSize: 10, 
              maxBodyHeight: 250,
              searchFieldAlignment: 'left', 
              headerStyle: (isMobile || isIPad13 || isIPhone13) ? { padding: '3px' } : {} ,
              actionsColumnIndex: -1
            }
          }
          actions={[
            rowData => ({
              icon: 'save',
              tooltip: intl.formatMessage({
                id: "IMPORT_FILE.DOWNLOAD"
              }),
              onClick: (event, rowData) => {
                handleDownload(event, rowData);
              }
            })
          ]}
          localization={{
            header: {
              actions: ''
            },
            toolbar: {
              searchPlaceholder: intl.formatMessage({
                id: "ECOMMERCE.COMMON.SEARCH"
              })
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage({
                id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
              })
            },
            pagination: {
              labelDisplayedRows: intl.formatMessage({
                id: "MATERIAL_TABLE.PAGINATION.TEXT"
              }, {
                from: '{from}',
                to: '{to}',
                count: '{count}'
              }),
              labelRowsSelect: intl.formatMessage({
                id: "MATERIAL_TABLE.PAGINATION.LABEL"
              })
            },

          }}
        />

      <Menu id="menu-visualaidactions" keepMounte>
        {
          <MenuItem onClick={handleDownload} >{intl.formatMessage({ id: "IMPORT_FILE.DOWNLOAD"})}</MenuItem>
        }
      </Menu>

        </DialogContent>
        <DialogActions>
          <Button className={classes.button} id="btnCallDetailsClose" onClick={handleClose}>
            <FormattedMessage id="CALL.DETAILS.BUTTON.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    { ...importCustomersStorage.actions }
  )(ImportLogPopUp)
);