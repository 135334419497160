import {isMobile,isIPad13, isIPhone13} from 'react-device-detect';

import store from '../../../app/store/store';
import * as presentationBarStorage from "../../../app/store/ducks/presentationBar.duck";

export function getActionsOutside() {
  // grab current state
  const state = store.getState();

  //console.log('desde el actionsssssssssss', state)
  presentationBarStorage.actions.testLog();
  // get the JWT token out of it
  // (obviously depends on how your store is structured)
  //const authToken = state.currentUser.token;

  // Pass the token to the server
  //return fetch('/user/thing', {
  //  method: 'GET',
  //  headers: {
  //    Authorization: `Bearer ${authToken}`
  //  }
  //}).then(res => res.json());
}

export const stopPresentation = (reason) => event => {
  //console.log('entre al presentationaction desde el archivo externo')
  let props = presentationBarStorage.actions;
  let globalState = store.getState();
  if(isMobile || isIPad13 || isIPhone13){
    //unlockOrientation();
  }
  if(event){
    event.stopPropagation();
  }
  props.log({
    logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
    log_category: 'VISUALAID_END',
    log_text: `code: ${globalState.presentationBar.presentationFocused.code} | reason: manual`
  })

  let fnCallbackClosePresentation = () => {
    props.closePresentation(Object.assign(globalState.presentationBar, {
      displayScreenPresentation: false,
      videoManager: globalState.presentationBar.videoManager,
      code: globalState.presentationBar.presentationFocused.code,
      presentationFocused: {},
      focusedPresentationSlides: {},
      presentationsListFiltered: globalState.presentationBar.presentationsList.list,
      reason: reason
    }));
  }
  
  // Si la presentacion es SDVA, tengo que cerrar el video antes de cerrar la presentacion, para evitar que se rompa en iphone
  if(globalState.presentationBar.presentationFocused.type == 'sdva'){
    props.sendGotoSdvaSlide(
        {
            type: 'SDVA',
            command: 'HIDEFULLSCREENMOVIE',
            payload: 'NA'
        }
    );

    setTimeout(() => {
        fnCallbackClosePresentation();
    }, 1200);
  }else{
      fnCallbackClosePresentation();
  }
}