const goBackHandler = (setConfirmGoBackOpen) => {
    try{
        window.history.pushState(null, document.title, window.top.location.pathname + window.top.location.search);
          window.addEventListener('popstate', (e) => {
              e.preventDefault();
              window.history.pushState(null, document.title, window.top.location.pathname + window.top.location.search);

              setConfirmGoBackOpen(true);
          });
      }catch(e){
        console.log(e);
      }
};

export default goBackHandler;