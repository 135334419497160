import axios from "axios";
import {configUrls} from '../../globalConfig';

export const GET_IMPORT_LIST_URL = `${configUrls.base_url}/api/importfile/search/byDate?`;
export const SAVE_OBJECT_URL = `${configUrls.base_url}/api/importfile/create`;
export const UPLOAD_TO_BLOB_COMPLETE_URL = `${configUrls.base_url}/api/importfile/action/uploaded/`;
export const GET_IMPORT_LOG_DETAIL = `${configUrls.base_url}/api/importfile/`;

//getImportList
export function getImportList(valuesSearch){
  return axios.get(GET_IMPORT_LIST_URL + 'type=' + valuesSearch.type + '&datefrom=' + valuesSearch.from + '&dateTo=' + valuesSearch.to);
}

export function saveObject(obj){
  return axios.post(SAVE_OBJECT_URL, obj);
}

export function uploadToBlobComplete(uuid){
  return axios.put(UPLOAD_TO_BLOB_COMPLETE_URL + uuid);
}

export function getImportLogDetail(uuid, showLog){
  return axios.get(GET_IMPORT_LOG_DETAIL + uuid + '?showlog=' + showLog);
}