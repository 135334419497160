import React, {useEffect} from 'react';
import HeaderFile from './components/HeaderFile';
import { connect, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import LinkIcon from '@material-ui/icons/Link';

import * as fileStorage from "./file.duck";

import { BlobServiceClient } from "@azure/storage-blob";
import { DropzoneArea } from 'material-ui-dropzone';
import './FileCreatePage.scss';


// Add style manually
import 'react-upload-gallery/dist/style.css' // or scss

const FileCreatePage = (props) => {
  const isFromFolders = (props.from == "folders");
  const {intl} = props;
  const globalState = useSelector(state => state);
  const [images, setImages] = React.useState([]);
  const [initialState, setInitialState] = React.useState([]);
  const [delayed, setDelayed] = React.useState(true);  
  const history = useHistory();

  let typeSelected = { key: '', label: '' };
  const [fileSelected, setFileSelected] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState(-1);
  const [isCreateDisabled, setIsCreateDisabled] = React.useState(false);

  if(history && history.location && history.location.pathname){
    if(history.location.pathname.indexOf('slide') != -1){
      typeSelected = { key: 'slide', label: 'Slide' };
    }else if(history.location.pathname.indexOf('sdva') != -1){
      typeSelected = { key: 'sdva', label: 'Micrositio' };
    }else if(history.location.pathname.indexOf('html5animate') != -1){
      typeSelected = { key: 'html5animate', label: 'Html5 - Animate' };
    }
  }

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }
  
  useEffect(() => {
    // llamar por get a /visualaid/{visualaidCode}?edit=true
    props.getEditFile(globalState.file.fileContainerResponse.code);
    return () => {
      props.fillResultFileContainer({})
    }
  }, []);

  useEffect(() => {
    if(globalState.file.fileContainerResponse && 
      globalState.file.fileContainerResponse.code){        
        setDelayed(false);
    }
  }, [globalState.file.fileContainerResponse.content])

  useEffect(() => {
    if(globalState.file.editFileResult && globalState.file.editFileResult === true && !isFromFolders){
      props.setEditFileResult(null);
      history.push("/files");
    }
    if(globalState.file.editFileResult !== null && globalState.file.editFileResult === false){
      showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.ERROR"}), 'red');
    }
  }, [globalState.file.editFileResult])

  const handleFileChosen = (file) => {
    if(isCreateDisabled){
      return;
    }

    if(file){
      setFileSelected(file);
    }
  };

  const handleOnAlert = (msg, type) => {
    if(type == 'success'){
      showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.SUCCESS"}), 'green');
    }else if(type == 'error'){
      showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.ERROR"}), 'red');
    }
  };

  const uploadFileProgress = (progress) => {
    if(!fileSelected[0] || fileSelected[0].size <= 0){
      setUploadProgress(-1);
      return;
    }

    let fileSize = fileSelected[0].size;

    setUploadProgress(Math.round(progress.loadedBytes * 100 / fileSize));
  };

  const uploudFile = async (files) => {
    if(!files || files.length <= 0 || !files[0] || files[0].size <= 0){
      return;
    }

    let fileUrl = globalState.file.fileContainerResponse.upload_Url.replace('{FileName}', '');

    const blobServiceClient = new BlobServiceClient(fileUrl);
  
    const containerClient = blobServiceClient.getContainerClient('');
    
    //const blobName = globalState.file.fileContainerResponse.code;
    const blobName = files[0].name;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
    // Retorno el promise del upload, para que al momento de guardar el vaid, se espere a que este subido antes de volver al listado de presentaciones
    return blockBlobClient.upload(
      files[0], 
      files[0].size,
      {
        onProgress: uploadFileProgress,
        blobHTTPHeaders: {
          blobContentType: files[0].type
        }
      });
    
  };

  const saveMetaData = async() => {
    //validar
    if(!globalState.file.fileContainerResponse.name){
      props.setErrorValidation('name');
    }else if(!globalState.file.fileContainerResponse.folder && !globalState.file.fileContainerResponse.cod_folder){
      props.setErrorValidation('folder');
    }else if(!globalState.file.fileContainerResponse.externalCode){
      props.setErrorValidation('externalCode');
    }else if(!globalState.file.fileContainerResponse.validFrom){
      props.setErrorValidation('validFrom');
    }else if(!globalState.file.fileContainerResponse.validTo){
      props.setErrorValidation('validTo');
    }else if(!moment(globalState.file.fileContainerResponse.validFrom).isBefore(globalState.file.fileContainerResponse.validTo)){
      props.setErrorValidation('datesDifference');
    }else{
      props.setErrorValidation(null);
      setIsCreateDisabled(true);
      
      if(fileSelected != null && fileSelected.length > 0){
        try{
          await uploudFile(fileSelected);
          await props.saveMetaDataFile({
            fileName:fileSelected[0].name, 
            mimeType:fileSelected[0].type,
            size:fileSelected[0].size
          });
          await props.fillResultFileContainer({});
        }catch(e){
          console.log(e);
          showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.ERROR"}), 'red');
          props.fillResultFileContainer({});
          props.setEditFileResult(null);
          history.push("/files");
        }
      }
      if(globalState.file.fileContainerResponse.status === 3){
        try{
          await props.saveMetaDataFile({edit:true});
          await props.fillResultFileContainer({});
        }catch(e){
          console.log(e);
          showToastImportantNotification(intl.formatMessage({id: "FILE.DROPZONE.ALERT.ERROR"}), 'red');
          props.fillResultFileContainer({});
          props.setEditFileResult(null);
          history.push("/files");
        }
      }
    }
  }

  const backToVaidList = () => {
    let messageAlert = intl.formatMessage({
      id: "FILE.CREATE.ALERT.BACK"
    });
    if (window.confirm(messageAlert)) {
      props.fillResultFileContainer({});
      props.setEditFileResult(null);
      history.push("/files");
    }
  }

  let isdisabled = false;
  if( globalState.file.fileContainerResponse.status === 3){
    isdisabled = false;
  }else{
    if(fileSelected && fileSelected.length <= 0){
      isdisabled = true;
    }
  }

  
  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
        {uploadProgress > 0 && <CircularProgressWithLabel size={68} value={uploadProgress} />}

        {globalState.file.loading && <LinearProgress className="progressBar" />}
        {globalState.file.loading && <div className="progressBackground"></div>}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"><FormattedMessage id="FILE.EDIT.PAGE.TITLE" /></h3>
          </div>

          <div>
            <Button style={{marginRight: 13}} size="medium" className="btn btn-sm btn" id="backFile" onClick={backToVaidList}>
              <ArrowBackIcon />
              <FormattedMessage id="FILE.BACK_BUTTON" />
            </Button>
            <Button size="medium" className="btn btn-sm btn-label-success" id="saveFile" onClick={saveMetaData} disabled={isCreateDisabled || isdisabled}>
              <SaveIcon />
              <FormattedMessage id="FILE.EDIT.PAGE.SAVE.BUTTON" />
            </Button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-xl-12 justify-content-center" style={{ width: '100%'}}>

              {(typeSelected.key == 'sdva' || typeSelected.key == 'html5animate') && uploadProgress > 0 &&
                <div className="note warning">
                  <FormattedMessage id="FILE.CREATE.ALERT.FILE_UPLOADING" />
                </div>
              }

              <div>
                <HeaderFile />
              </div>

              <hr/>
              {globalState.file.fileContainerResponse.status === 3 ?
                <h2 style={{textAlign:"center"}}>
                  <LinkIcon fontSize="large" />
                  <a href={globalState.file.fileContainerResponse.url} target="_blank">{globalState.file.fileContainerResponse.name}</a> 
                </h2>
              :
              (!delayed && !isFromFolders) && 
                <div>
                  <DropzoneArea onChange={handleFileChosen} onAlert={handleOnAlert}
                    acceptedFiles={[".jpg, .jpeg, .png, .mp4, .wmv, .avi, .webm, .mkv, .mpg , .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx"]} 
                    filesLimit={1} 
                    showAlerts={false} 
                    showFileNames={true} 
                    maxFileSize={53000000} 
                    dropzoneText={intl.formatMessage({id: "FILE.DROPZONE.TEXT"})}
                  />
                </div>
              }
            </div>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    fileStorage.actions
  )(FileCreatePage)
);