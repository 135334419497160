import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    image: {
        width: '55%',
    },
    title: {
        marginTop: 20, 
        textAlign: 'center',
    },
    body: {
        textAlign: 'center',
    },
}));

const EmptyStateGrid = () => {
    const { PUBLIC_URL } = process.env;
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt={4}
            height="90vh"
        >
            <img
                className={classes.image}
                src={`${PUBLIC_URL}/media/illustrations/working.svg`} 
                alt="Working Illustration" 
            />
            <Typography variant='h5' className={classes.title}>
                <FormattedMessage id={"PRESENTATION.EMPTYSTATE.TITLE"}/>
            </Typography>
            <Typography variant='body1' className={classes.body}>
            <FormattedMessage id={"PRESENTATION.EMPTYSTATE.BODY"}/>
            </Typography>
        </Box>
    )
}

export default EmptyStateGrid;
