import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { 
  getImportList,
  saveObject,
  uploadToBlobComplete,
  getImportLogDetail
} from "../../crud/importCustomers.crud";
import moment from 'moment';

export const actionTypes = {
  GET_IMPORT_LIST: "GET_IMPORT_LIST",
  FILL_IMPORT_LIST: "FILL_IMPORT_LIST",
  SAVE_OBJECT: "SAVE_OBJECT",
  FILL_CREATE_RESPONSE: "FILL_CREATE_RESPONSE",
  UPLOAD_FILE_TO_BLOB: "UPLOAD_FILE_TO_BLOB",
  RESET_LOADING: "RESET_LOADING",
  SET_RESULT_UPLOAD_FILE_TO_BLOB: "SET_RESULT_UPLOAD_FILE_TO_BLOB",
  RESET_RESULT_UPLOAD: "RESET_RESULT_UPLOAD",
  UPLOAD_TO_BLOB_COMPLETE: "UPLOAD_TO_BLOB_COMPLETE",
  GET_IMPORT_LOG_DETAIL: "GET_IMPORT_LOG_DETAIL",
  FILL_IMPORT_LOG_DETAIL: "FILL_IMPORT_LOG_DETAIL"
};

const initialImportCustomersState = {
  loading: false,
  importList: [],
  createResponse: null,
  uploadToBlobFilePercent: 0,
  resultUploadToBlobFile: null,
  resultUpload: null,
  importLogDetail: {}
};

export const reducer = persistReducer(
    { storage, key: "demo1-importCustomers", whitelist: [""] },
    (state = initialImportCustomersState, action) => {
      switch (action.type) {
        case actionTypes.GET_IMPORT_LIST: {
          return {
            ...state,
            loading: true
          };
        }
        case actionTypes.FILL_IMPORT_LIST: {
          return {
            ...state,
            loading: false,
            importList : action.payload.list
          };
        }
        case actionTypes.SAVE_OBJECT: {
          return {
            ...state,
            loading: true
          };
        }
        case actionTypes.FILL_CREATE_RESPONSE: {
          return {
            ...state,
            createResponse : action.payload
          };
        }
        case actionTypes.RESET_LOADING: {
          return {
            ...state,
            loading : false
          };
        }
        case actionTypes.RESET_RESULT_UPLOAD: {
          return {
            ...state,
            resultUpload : null,
            createResponse: null
          };
        }
        case actionTypes.SET_RESULT_UPLOAD_FILE_TO_BLOB: {
          let resultUpload = null;
          if(action.payload._response.status < 300){
            resultUpload = true;
          }else{
            resultUpload = false
          }
          return {
            ...state,
            resultUpload: resultUpload
          };
        }
        case actionTypes.GET_IMPORT_LOG_DETAILFILL: {
          return {
            ...state,
            loading: true
          };
        }
        case actionTypes.FILL_IMPORT_LOG_DETAIL: {
          return {
            ...state,
            loading: false,
            importLogDetail : { header: action.payload.header, loglist: action.payload.loglist }
          };
        }
        default:
          return state;
      }
    }
);

export const actions = {
  getImportList: (value) => ({ type: actionTypes.GET_IMPORT_LIST, payload: value }),
  fillImportList: (value) => ({ type: actionTypes.FILL_IMPORT_LIST, payload: value }),
  saveObject: (value) => ({ type: actionTypes.SAVE_OBJECT, payload: value }) ,
  fillCreateResponse: (value) => ({ type: actionTypes.FILL_CREATE_RESPONSE, payload: value }),
  setResultUploadFileToBlob: (value) => ({ type: actionTypes.SET_RESULT_UPLOAD_FILE_TO_BLOB, payload: value }),
  resetLoading: (value) => ({ type: actionTypes.RESET_LOADING, payload: value }),
  resetResultUpload: (value) => ({ type: actionTypes.RESET_RESULT_UPLOAD, payload: value }),
  uploadToBlobComplete: (value) => ({ type: actionTypes.UPLOAD_TO_BLOB_COMPLETE, payload: value }),
  getImportLogDetail: (value) => ({ type: actionTypes.GET_IMPORT_LOG_DETAIL, payload: value }),
  fillImportLogDetail: (value) => ({ type: actionTypes.FILL_IMPORT_LOG_DETAIL, payload: value })
};

export const getState = (state) => state;

export function* saga() {
  
  yield takeLatest(actionTypes.UPLOAD_TO_BLOB_COMPLETE, function* saveObjectaga({ payload }) {
    const selector = yield select(getState);
    try {

      const { data, status } = yield uploadToBlobComplete(selector.importCustomers.createResponse.code);

    } catch (e) {
      //yield put(actions.setAssignByAtributeCusResult(false));
    }
  });

  yield takeLatest(actionTypes.SAVE_OBJECT, function* saveObjectaga({ payload }) {
    const selector = yield select(getState);
    try {

      const { data, status } = yield saveObject(payload);

      if (status < 300) {
        yield put(actions.fillCreateResponse(data));
      } else {
        yield put(actions.fillCreateResponse(false));
      }
    } catch (e) {
      //yield put(actions.setAssignByAtributeCusResult(false));
    }
  });

  yield takeLatest(actionTypes.GET_IMPORT_LIST, function* getImportListaga({ payload }) {
    const selector = yield select(getState);
    try {

      const { data, status } = yield getImportList({
        from : payload.from,
        to: payload.to,
        type: selector.presentationBar.customerTypeSelected.code
      });

      if (status < 300) {
        data.list.map((item) => {
          item.createdAtUTC = moment(item.createdAtUTC).format('YYYY-MM-DD');
          item.lastExecutedAtUTC = moment(item.lastExecutedAtUTC).format('YYYY-MM-DD');
        })
        yield put(actions.fillImportList(data));
      } else {
        //yield put(actions.setAssignByAtributeCusResult(false));
      }
    } catch (e) {
      //yield put(actions.setAssignByAtributeCusResult(false));
    }
  });

  yield takeLatest(actionTypes.GET_IMPORT_LOG_DETAIL, function* getImportLogDetailSaga({ payload}) {
    try {

      const { data, status } = yield getImportLogDetail(payload.uuid, payload.showLog);

      if (status < 300) {
        data.loglist.map((item) => {
          item.LastUpdate = moment(item.LastUpdate).format('YYYY-MM-DD HH:MM');
        })
        
        yield put(actions.fillImportLogDetail(data));
      } else {
        //yield put(actions.setAssignByAtributeCusResult(false));
      }
    } catch (e) {
      //yield put(actions.setAssignByAtributeCusResult(false));
    }
  });

}

