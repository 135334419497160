import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BlurOn from '@material-ui/icons/BlurOn';
import BlurOff from '@material-ui/icons/BlurOff';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinkIcon from '@material-ui/icons/Link';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import ProductsCallDialog from '../ProductsCallDialog';
import './MoreVertexMenu.scss';
import {getDeploymentParamValue} from '../../../_metronic/utils/utils';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import {
  isMobileOnly,
  isIPhone13,
  isTablet,
  isIPad13,
  isIOS,
  isChrome,
  isAndroid,
  isBrowser,
  isEdge
} from 'react-device-detect';
import {lowerThanTablet} from '../../../_metronic/utils/utils';
import {VideoEffectsButton} from '../VideoEffects/VideoEffectsButton';
//import { setSourceMapRange } from 'typescript';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '.MuiListItemIcon-root': {
      color: '#0000008a !important'
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white !important',
      },
    },
  },
}))(MenuItem);

const MoreVertexMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const productCreateEnable = (getDeploymentParamValue(props.params, 'CUSTOMER_REQUEST_ENABLE') == '1');

  const cameraBlurEnabled = (getDeploymentParamValue(props.params, 'PUBLISHER_FILTER_BLUR_ENABLED') === '1');

  const cameraBackgroundEnabled = (getDeploymentParamValue(props.params, 'PUBLISHER_FILTER_BACKGROUND') !== '');
  
  const BlurIcon = props.isBlurEnabled ? BlurOff : BlurOn

  const isPhoneDevice = (lowerThanTablet || isMobileOnly || isIPhone13);

  const sharescreenEnable = (
    getDeploymentParamValue(props.params, 'SCREEN_SHARE_ENABLE') == '1' && 
    props.role == 'organizer' && 
    !props.displayScreenPresentation &&
    !isPhoneDevice && 
    !isTablet && 
    !isIPad13
  );
  
  return(
    <>
      {(productCreateEnable || sharescreenEnable || cameraBlurEnabled) &&
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          id="moreVertIcon"
        >
          <MoreVertIcon htmlColor='#ffffff' />
        </IconButton>
        <StyledMenu
          id="moreItems"
          anchorEl={anchorEl}
          keepMounted={false}
          open={open}
          onClose={handleClose}
          container={ () => document.getElementById('callContainer') }
        >
          {
            ((cameraBlurEnabled || cameraBackgroundEnabled) && (props.role === 'organizer' || props.role == 'guest') && isBrowser && (isChrome || isEdge) && !isIOS && !isAndroid) &&
            <StyledMenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
                <VideoEffectsButton 
                  isBlurEnabled={props.isBlurEnabled} 
                  stopCameraBlur={props.stopCameraBlur} 
                  startCameraBlur={props.startCameraBlur} 
                  startCameraImage={props.startCameraImage} 
                  stopCameraImage={props.stopCameraImage} 
                  isImageEnabled={props.isImageEnabled} 
                  imageSelected={props.imageSelected}
                  closeMenu={handleClose}
                  withBlur={cameraBlurEnabled}
                  withBackground={cameraBackgroundEnabled}
                />
            </StyledMenuItem>
          }
          {
            productCreateEnable && props.role === 'organizer' &&
            <StyledMenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
              <ProductsCallDialog />
            </StyledMenuItem>
          }
          {
            sharescreenEnable && props.role === 'organizer' &&
            <StyledMenuItem className="menuItem__buttons" onKeyDown={e => e.stopPropagation()}>
              <div id="shareScrenButton" onClick={props.startSharingScreen}>
                <span className="ots-video-control share-screen" style={{backgroundImage: toAbsoluteUrl("/media/icons/icon-screen-share.png")}}></span>
                <span><FormattedMessage id="PRODUCT.MENU.SHARE" /></span>
              </div>
            </StyledMenuItem>
          }
        </StyledMenu>
      </>
      }
    </>
    
  )
}

export default injectIntl(MoreVertexMenu);