import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from "react-redux";
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import { FormattedMessage, injectIntl } from "react-intl";
import * as loggerStorage from "../../../app/store/ducks/logger.duck";
import useInterval from "./useInterval";
import {
  getDeploymentParamValue,
  replaceSpecialChars,
  trackException
} from '../VideoChat/utils';
const interval = 1000;

const CallTime = (props) => {
  const [isFirstlog, setIsFirstLog] = useState(true);
  const globalState = useSelector(state => state);
  const prevCallTime = useRef(false)
  const prevTimerTime = useRef(0);

  const [isRunning, setIsRunning] = useState(false);
  const [prevTime, setPrevTime] = useState(null);
  const [timeInMilliseconds, setTimeInMilliseconds] = useState(0);
  const [time, setTime] = useState(null);

  function publishStats(globalState, props, isFirstlog) {
    if (globalState.presentationBar.stats && globalState.presentationBar.stats.length > 0) {
      for (let i = 0; i < globalState.presentationBar.stats.length; i++) {
        const stat = globalState.presentationBar.stats[i];
        if (stat && stat.stats) {
          let logText = "";
          if (stat.connectionId) {
            let connectionId = stat.connectionId;
            logText += "connectionId" + ": " + connectionId;
          }
          if (stat.subscriberId) {
            let subscriberId = stat.subscriberId;
            logText += " | subscriberId" + ": " + subscriberId;
          }

          if (stat && stat.stats && stat.stats.video) {

            Object.keys(stat.stats.video).map((key, index) => {
              let videoProp = stat.stats.video[key];
              if (videoProp) {
                let videoText = replaceSpecialChars(videoProp.toString());
                logText += " | v_" + key + " : " + videoText;
              }
            });
          }

          if (stat && stat.stats && stat.stats.audio) {

            Object.keys(stat.stats.audio).map((key, index) => {
              let audioProp = stat.stats.audio[key];
              if (audioProp) {
                let audioText = replaceSpecialChars(audioProp.toString());
                logText += " | a_" + key + " : " + audioText;
              }
            });
          }

          props.log({
            logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
            log_category: 'PUBLISHER_STATS',
            log_text: logText
          })
        }
      }
      props.setStats(null);
    }
    try {
      if (props.publishers && props.publishers.camera) {
        Object.keys(props.publishers.camera).map((key, index) => {
          let publisher = props.publishers.camera[key];
          if (publisher) {
            publisher.getStats((err, stats) => {
              if (stats && stats.length > 0) {
                props.setStats(stats);
              }
            });
          }
        });
      }
    }
    catch (error) {
      if(isFirstlog){
        console.log(error.message);
        props.log({
          logtype: 1,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_ERROR',
          log_text: error.message
        })
        trackException(error);

      }
      
    }
  }

  useEffect(() => {
    if (prevCallTime.current === true && globalState.presentationBar.callTime === false) {
      props.setCallTime(timeInMilliseconds)
      props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'CALL_ALIVE',
        log_text: `time:${parseInt(timeInMilliseconds / 1000)} | subs:${globalState.presentationBar.currentSubscribersGlobal.length}`
      })
      props.log({
        logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
        log_category: 'CALL_END',
        log_text: `time:${parseInt(timeInMilliseconds / 1000)}|reason: endcall`
      })
    }
    prevCallTime.current = globalState.presentationBar.callTime;
  }, [globalState.presentationBar.callTime])


  useEffect(() => {
    if (!isRunning) {
      handleTime();
    }
  }, [])

  useInterval(
    () => {
      let prev = prevTime ? prevTime : Date.now();
      let diffTime = Date.now() - prev;
      //console.log(diffTime);
      let newMillisecondsTime = timeInMilliseconds + diffTime;
      let newTime = toTime(newMillisecondsTime);
      setPrevTime(Date.now());
      setTimeInMilliseconds(newMillisecondsTime);
      setTime(newTime);
      if ((isFirstlog && timeInMilliseconds > 0) || timeInMilliseconds >= prevTimerTime.current + 30000) {

        props.log({
          logtype: 3,// Critical=0, Error=1, Warning=2, Information=3, Trace=4
          log_category: 'CALL_ALIVE',
          log_text: `time:${parseInt(timeInMilliseconds / 1000)} | subs:${globalState.presentationBar.currentSubscribersGlobal.length}`
        })


        if (props.publisherLogStats) {
          publishStats(globalState, props, isFirstlog);
        }
        if (isFirstlog) {
          setIsFirstLog(false);
        }
        prevTimerTime.current = timeInMilliseconds;
      }

    },
    isRunning ? interval : null
  );

  const handleTime = () => {
    setIsRunning(!isRunning);
    setPrevTime(null);
  };

  const toTime = time => {
    let milliseconds = parseInt(time % 1000),
      seconds = Math.floor((time / 1000) % 60),
      minutes = Math.floor(time / (1000 * 60)),
      hours = Math.floor(time / (1000 * 60 * 60));

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return {
      milliseconds,
      seconds,
      minutes,
      hours
    };
  };

  return (
    <div style={{
      color: '#000000',
      fontSize: 14,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: (globalState.presentationBar.callTime == true && props.isVisible) ? 'flex' : 'none'
    }}
      id="timer"
    >
    
      <React.Fragment>
        <div style={{ color: 'white' }}>
          {time && <p>{`${time.hours}:${time.minutes}:${time.seconds}`}</p>}
        </div>
      </React.Fragment>
    </div>
  )
}

export default injectIntl(
  connect(
    null,
    { ...presentationBarStorage.actions, ...loggerStorage.actions }
  )(CallTime)
);