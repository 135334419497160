import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { ReactComponent as ArrowRight } from "./assets/Arrow-right.svg";
import SlideshowIcon from '@material-ui/icons/Slideshow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StopIcon from '@material-ui/icons/Stop';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {defaultStyles} from '../../../globalConfig';

import { useClickPreventionOnDoubleClick } from "../utils/use-click-prevention-on-double-click";
import { getDeploymentParamValue } from '../VideoChat/utils';

const PresentationList = ({
  deploymentStyle,
  side, 
  toggleDrawer, 
  showSlideList, 
  startPresentation, 
  stopPresentation,
  props
}) => {
  const [vaLogoUrl, setValogoUrl] = useState('');
  const globalState = useSelector(state => state);
  const { intl } = props;

  useEffect(() => {
    const parameters = globalState.auth.deployment.parameters;
    if (parameters) {
      const vaLogoUrlValue = getDeploymentParamValue(parameters, "VA_LOGO_URL");
      const logoUrlValue = getDeploymentParamValue(parameters, "LOGO_URL");
      
      const url = vaLogoUrlValue || logoUrlValue;
      setValogoUrl(url);
    }
  }, [globalState.auth.deployment.parameters]);

  const hasCurrentFlow = () => Object.keys(globalState.presentationBar.focusedPresentationFlows.currentFlow).length > 0 && globalState.presentationBar.focusedPresentationFlows.currentFlow.name;

  const TopPlayedItem = () => {
    if(globalState.presentationBar.presentationFocused && globalState.presentationBar.presentationFocused.code){
      switch(globalState.presentationBar.presentationFocused.type){
          case 'video':
          case 'html5animate':
          case 'sdva':
          default:
            return (
              <div className="presentationList__container--active" id="btnPresentationListShowSlide" onClick={showSlideList(globalState.presentationBar.presentationFocused, true)}>
                <ListItem button 
                  key={globalState.presentationBar.presentationFocused.name}
                  style={{
                    backgroundColor: '#F5F5F5',
                    borderRadius: 5,
                    borderLeft: `2px solid ${defaultStyles.accent_color}`,
                    boxShadow: `-2px 0px 0px 0px ${defaultStyles.accent_color}`
                  }}
                >
                  <div className="presentationList__text-container">
                    <ListItemText
                      primary={globalState.presentationBar.presentationFocused.name} 
                      secondary={hasCurrentFlow() && globalState.presentationBar.focusedPresentationFlows.currentFlow.name}
                    />

                    <ListItemText
                      secondary={`${globalState.presentationBar.numSlide + 1} / ${globalState.presentationBar.presentationFocused.length}` || "" }
                    />
                    
                  </div>
                  <div className="presentationList__icons-container">
                    <div className="icons-with-text__group" id="btnPresentationListStop" onClick={stopPresentation('manual')}>
                      <ListItemIcon><StopIcon style={{ color: deploymentStyle.accentColor }}/></ListItemIcon>
                      <ListItemText style={{ color: deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor }} primary={intl.formatMessage({id: "PRESENTATION.BAR.STOP"})} className="presentationList__icons__text" />
                    </div>
                    
                    { (globalState.auth.deployment.callData.type !=2) &&
                      <div className="icons-with-text__group" id="btnPresentationListShowSlide" onClick={showSlideList(globalState.presentationBar.presentationFocused, true)}>
                        <ListItemIcon><KeyboardArrowRight style={{ color: deploymentStyle.accentColor }} /></ListItemIcon>
        
                        <ListItemText style={{ color: deploymentStyle.accentColor , textDecorationColor: deploymentStyle.accentColor}} primary={intl.formatMessage({id: "PRESENTATION.BAR.VIEW"})} className="presentationList__icons__text" />
                      </div>
                    } 
                  </div>
                </ListItem>
              </div>
            );
      }
    }else{
      return('');
    }
  }

  const SlideItem = ({presentationSlide, onClick, onDoubleClick}) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);
    
    if(presentationSlide.code !== globalState.presentationBar.presentationFocused.code){
      switch(presentationSlide.type){
        case 'video':
          return(
            <div 
              className={`presentationList__container`} 
              key={presentationSlide.code} 
              onClick={handleClick} 
              onDoubleClick={handleDoubleClick} 
            >
              <ListItem button key={presentationSlide.name}>
                <div className="presentationList__text-container">
                  <ListItemText
                    primary={presentationSlide.name} 
                  />
                </div>
                <div className="presentationList__icons-container">
                  <div className="icons-with-text__group" id="btnPresentationListStart" onClick={startPresentation(0, presentationSlide)}>
                    <ListItemIcon><SlideshowIcon style={{ color: deploymentStyle.accentColor }}/></ListItemIcon>
                    <ListItemText style={{color:deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor}} primary={intl.formatMessage({id: "PRESENTATION.BAR.PROJECT"})} className="presentationList__icons__text" />
                  </div>
                </div>
              </ListItem>
            </div>
          )
          case 'html5animate':
          case 'sdva':
          default:
            return(
              <div 
                className={`presentationList__container`} 
                key={presentationSlide.code} 
                onClick={handleClick} 
                onDoubleClick={handleDoubleClick} 
              >
                <ListItem button key={presentationSlide.name}>
                  <div className="presentationList__text-container">
                    <ListItemText
                      primary={presentationSlide.name} 
                    />
                    <ListItemText
                      secondary={`${presentationSlide.length} Slides` || "" }
                    />
                  </div>
                  <div className="presentationList__icons-container">
                    { (presentationSlide.length > 0) &&
                    <div className="icons-with-text__group" id="btnPresentationListStart" onClick={startPresentation(0, presentationSlide, vaLogoUrl)}>
                      <ListItemIcon><SlideshowIcon style={{ color: deploymentStyle.accentColor }}/></ListItemIcon>
                      <ListItemText style={{color:deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor}} primary={intl.formatMessage({id: "PRESENTATION.BAR.PROJECT"})} className="presentationList__icons__text" />
                    </div>
                    }
                    { (globalState.auth.deployment.callData.type !=2  && presentationSlide.length > 0) &&
                    <div className="icons-with-text__group" id="btnPresentationListShowSlide" onClick={showSlideList(presentationSlide, true)}>
                        <ListItemIcon><KeyboardArrowRight style={{ color: deploymentStyle.accentColor }} /></ListItemIcon>
                        <ListItemText style={{color:deploymentStyle.accentColor, textDecorationColor: deploymentStyle.accentColor }} primary={intl.formatMessage({id: "PRESENTATION.BAR.VIEW"})} className="presentationList__icons__text" />  
                    </div>
                    }
                  </div>
                </ListItem>
              </div>
            )
      }   
    }else{
      return (null);
    }
  }

  return (
    <div style={{ height: '50vh' }}
    role="presentation"
    onKeyDown={toggleDrawer(side, false)}
    >
      <List>
          <TopPlayedItem />
        {globalState.presentationBar.presentationsListFiltered ? globalState.presentationBar.presentationsListFiltered.map((presentationSlide, index) => (
          <SlideItem presentationSlide={presentationSlide} key={index} onClick={showSlideList(presentationSlide, true)} onDoubleClick={startPresentation(0, presentationSlide)} />
        )): null}
      </List>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    null
  )(PresentationList)
);