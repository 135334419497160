import React, { useMemo } from "react";
import { useState, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import { FormattedMessage, injectIntl } from "react-intl";
import NewVisitPopUp from '../NewVisitPopUp';
import moment from 'moment';
import * as presentationBarStorage from "../../store/ducks/presentationBar.duck";
import * as userStorage from "../../modules/Users/users.duck";
import MaterialTable from 'material-table'
import './CustomerList.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import {  isMobile,isIPad13, isIPhone13 } from 'react-device-detect';

const CustomerList = (props) => {
  const [reRender, setReRender] = React.useState(false);
  const [reCode, setReCode] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const globalState = useSelector(state => state);
  const { intl } = props;

  useEffect(() => {
    Object.assign(globalState.presentationBar.customerSelected, globalState.presentationBar.customerSelected);
  }, [globalState.presentationBar.customerSelected]);

  const showToastImportantNotification = (msg, backgroundColor) => {
    let css = document.createElement("style");
    let style = document.createTextNode(`
      #toastImportantNotification {
        position:fixed; z-index: 9999999; 
        widht: 120px; 
        top:15%;
        right:1%;
        margin-left: -60px;
        padding: 20px; 
        background: ${backgroundColor};
        border-radius: 8px;
        font-size: 14px;
        font-family: sans-serif;
        color: #ffffff;
        text-align: center;
      }
      .toast-white{
        color: #ffffff;
      }
      .toast-white:hover{
        color: #ffffff;
      }
    `);
    css.appendChild(style);
    document.head.appendChild(css);
    let advisement = document.createElement("div");
    advisement.setAttribute("id", "toastImportantNotification");

    let content = document.createTextNode(msg);
    advisement.appendChild(content);
    advisement.appendChild(document.createElement('br'));
    document.body.appendChild(advisement);

    window.load = setTimeout("if(typeof(toastImportantNotification) != 'undefined'){if(HTMLCollection.prototype.isPrototypeOf(toastImportantNotification)){for (var i = 0; i < toastImportantNotification.length; i++) {document.body.removeChild(toastImportantNotification[i])}}else{document.body.removeChild(toastImportantNotification)}}", 5000);
  }

  const handleSubmit = (date, selectedObject) => event => {
    if (globalState.users.userSelected && globalState.users.userSelected.uuid && globalState.users.userSelected.uuid == globalState.auth.userid) {
      showToastImportantNotification(intl.formatMessage({id: "CALL.DETAILS.INVALID.SELECTION"}),'red');
    } else {
      setLoading(true);
      date = moment(date).add(-1 * globalState.auth.timezone, 'hours').format("YYYY-MM-DDTHH:mm");
      if (!date || !globalState.presentationBar.customerSelected.uuid) {
        Object.assign(globalState.presentationBar.callNew, { ...date, open: true, error: true });
        props.updateNewCall(globalState.presentationBar.callNew);
        Object.assign(globalState.presentationBar.calls, globalState.presentationBar.calls);
      } else {
        if(globalState.users.userSelected && globalState.users.userSelected.uuid){
          props.createCall({ date: date, uuid: selectedObject.uuid, selectedFrom: moment(new Date()), selectedTo: moment(new Date()).add('days', 7), userId: globalState.auth.userid, guestId: globalState.users.userSelected.uuid });
          props.updateUserSelected(null);
        }else{
          props.createCall({ date: date, uuid: selectedObject.uuid, selectedFrom: moment(new Date()), selectedTo: moment(new Date()).add('days', 7), userId: globalState.auth.userid, type: globalState.presentationBar.visitTypeSelected.code });
        }
        props.getCallsByDate({ selectedFrom: moment(new Date()), selectedTo: moment(new Date()).add('days', 7) });
        props.updateCustomerSelected({ disabled: false });
        Object.assign(globalState.presentationBar.callNew, { ...date, name: '', email: '', open: false, error: false });
      }
    }
  }

  let specialization = '';
  let custTypeList = globalState.presentationBar.customerTypeList;
  let custTypeSelected = globalState.presentationBar.customerTypeSelected;
  if (custTypeSelected != null && custTypeList.length > 0) {

    types:
    for (let index = 0; index < custTypeList.length; index++) {
      const custType = custTypeList[index];
      if (custTypeSelected.code == custType.code) {
        let params = custType.parameters;
        for (let j = 0; j < params.length; j++) {
          const param = params[j];
          if (param.key == 'ATRIBUTE_1_NAME') {
            specialization = param.value;
            break types;
          }
        }
      }
    }
  }

  let actionsColumnIndex = 2;
  let columns = [
    { title: intl.formatMessage({id: "ECOMMERCE.CUSTOMERS.NAME"}), field: 'name',cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} },
    { title: intl.formatMessage({id: "ECOMMERCE.CUSTOMERS.IDENTIFICATION"}), field: 'identification',cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} }];

  if (specialization != '') {
    columns.push({ title: specialization, field: 'atribute_1', cellStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{}});
    actionsColumnIndex++;

  }
  return (
    <div style={{ width: '100%', boxShadow: 'none' }}>
      {globalState.presentationBar.loading && <LinearProgress className="progressBar" />}
      {globalState.presentationBar.loading && <div className="progressBackground"></div>}
      <MaterialTable
        columns={columns}
        title=""
        data={globalState.presentationBar.customerListByType}
        options={
          { actionsColumnIndex: actionsColumnIndex, pageSize: 10, searchFieldAlignment: 'left',headerStyle:(isMobile || isIPad13 || isIPhone13)?{ padding: '3px'}:{} }
        }
        actions={[
          {
            icon: 'eventavailable', //ponerle EventAvailable
            tooltip: intl.formatMessage({
              id: "DASHBOARD.NEW.VISIT.TITLE"
            }),
            onClick: (event, rowData) => {
              // Do save operation
              Object.assign(globalState.presentationBar.callNew, { code: rowData.code, open: true, error: false });
              props.updateNewCall(globalState.presentationBar.callNew);
              props.updateCustomerSelected({ ...rowData, disabled: true });
              setReRender(reRender + 1);
              //<NewVisitPopUp handleSubmit={handleSubmit} name={rowData.name} email={rowData.email} />
            }
          }
        ]}
        localization={{
          header: {
            actions: ''
          },
          toolbar: {
            searchPlaceholder: intl.formatMessage({
              id: "ECOMMERCE.COMMON.SEARCH"
            })
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: "ECOMMERCE.CUSTOMERS.SEARCH.EMPTY_LIST"
            })
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.TEXT"
            }, {
              from: '{from}',
              to: '{to}',
              count: '{count}'
            }),
            labelRowsSelect: intl.formatMessage({
              id: "MATERIAL_TABLE.PAGINATION.LABEL"
            })
          },
          
        }}
      />
      <NewVisitPopUp handleSubmit={handleSubmit} displayButton={false} rerender={reRender} />
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    {
      ...presentationBarStorage.actions,
      ...userStorage.actions
    }
  )(CustomerList)
);