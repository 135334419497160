import React, { useMemo, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { metronic } from "../../../_metronic";
import VisualAids from '../../components/VisualAids';
import { FormattedMessage, injectIntl } from "react-intl";
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateVisualAidButton from '../../components/VisualAids/CreateVisualAidButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import * as visualAidStorage from "../../store/ducks/visualAid.duck";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VisualAidsPage = (props) => {
  const globalState = useSelector(state => state);
  
  const handleCloseSnackBar = () => {
    props.setEditVaidResult(null);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div className="kt-portlet kt-portlet--height-fluid col-md-10 relative">
      {globalState.visualAid.loading && <LinearProgress className="progressBar" />}
      {globalState.visualAid.loading && <div className="progressBackground"></div>}
      {globalState.visualAid.editVaidResult != null &&
      <Snackbar 
        open={(globalState.visualAid.editVaidResult !== null)}
        key={'top' + 'right'}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        autoHideDuration={1500}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={(globalState.visualAid.editVaidResult) ? 'success' : 'error'}>
          {globalState.visualAid.editVaidResult ? 
            <FormattedMessage id="VAID.CREATE.SUCCESS" />
            :
            <FormattedMessage id="VAID.CREATE.ERROR" />
          }
        </Alert>
      </Snackbar>
      }
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"><FormattedMessage id="VAID.TITLE" /></h3>
        </div>
        <div>
          <CreateVisualAidButton />
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="row">
          
          <div className="col-xl-12 d-flex justify-content-center" style={{ width: '100%'}}>
            <VisualAids />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    visualAidStorage.actions
  )(VisualAidsPage)
);