import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from "react-intl";
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import { defaultStyles } from '../../../globalConfig';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { VideoEffectsContainer } from './VideoEffectsContainer';
import { getDeploymentParamValue } from '../../../_metronic';
import { useSelector } from 'react-redux';
  
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  dialogtop: {
    width: '100%',
    height: 750
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mobileContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    marginLeft: '30%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    color: defaultStyles.accent_color
  },
  textRed: {
    color: defaultStyles.accent_color
  }
}));


export const VideoEffectsButton = ({isBlurEnabled, stopCameraBlur, startCameraBlur, startCameraImage, stopCameraImage, isImageEnabled, imageSelected, closeMenu, withBlur, withBackground}) => {
  const classes = useStyles();
  const globalState = useSelector(state => state);
  const [open, setOpen] = React.useState(false);
  const filterBackgrounds = getDeploymentParamValue(globalState.auth.deployment.parameters, "PUBLISHER_FILTER_BACKGROUND")?.split(";");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeMenu();
  };
    
  return(
    <>
      <div onClick={handleOpen}>
        <PhotoFilterIcon/>
        &nbsp;<FormattedMessage id={"PRODUCT.MENU.VIDEO.EFFECTS"}/>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        container={() => document.getElementById('callContainer')}
      >
        <DialogTitle id="ve-dialog-title"><FormattedMessage id="PRODUCT.MENU.VIDEO.EFFECTS" /></DialogTitle>

        <DialogContent className={classes.dialogtop}>
          <div className={classes.paper}>
            <div className="popup__input-products__container">
              <VideoEffectsContainer 
                isBlurEnabled={isBlurEnabled} 
                stopCameraBlur={stopCameraBlur} 
                startCameraBlur={startCameraBlur} 
                handleClose={handleClose} 
                startCameraImage={startCameraImage} 
                stopCameraImage={stopCameraImage} 
                isImageEnabled={isImageEnabled} 
                imageSelected={imageSelected}
                filterBackgrounds={filterBackgrounds}
                withBlur={withBlur}
                withBackground={withBackground}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button className={classes.button} id="btnVideoEffectsClosePopUp" onClick={handleClose}>
            <FormattedMessage id="PRODUCTS.CALL.DIALOG.ACTION.CLOSE" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}